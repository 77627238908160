import { Box, Typography, TextField, Chip, Checkbox, FormControlLabel } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { staticRanges } from '../utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExportButton } from '../ExportButton';
import { listPlans as LIST_PLANS } from 'src/apollo/queries/plans';
import { Account, Query, ListSessionsV2FilterInput } from 'src/@types';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
const { spacing } = theme;
import { DateRangeField } from 'src/components/shared/DateRangeField';
import { ChargeLogFilterOptions } from '../';
import { SearchField } from 'src/components/shared/SearchField';

export type Props = {
  account?: Partial<Account>;
  tableFilters: ChargeLogFilterOptions;
  queryFilters: ListSessionsV2FilterInput;
  onSearch?: (val: ChargeLogFilterOptions) => void;
  onFilter: (val: ChargeLogFilterOptions) => void;
  totalResults?: number;
};

const StyledDateRangeField = styled(DateRangeField)`
  margin-left: ${spacing(2)}px;
  .MuiFormControl-root {
    min-width: 230px;
    background-color: ${theme.palette.grey['200']};
    border-radius: 4px;
  }
  .MuiInput-root {
    padding: 0px 4px;
  }
  .MuiFormLabel-root {
    margin-top: 4px;
    margin-left: 4px;
    color: ${theme.palette.grey['600']};
  }
`;

export function ChargeLogFilters({ queryFilters, tableFilters, onFilter, account }: Props) {
  const {
    data: planOptions,
    loading,
    error,
  } = useQuery<Query>(LIST_PLANS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { pageSize: 9999 },
    },
  });
  return (
    <div data-testid="charge-log-filters">
      <Box display="flex" width="100%" p={2} alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Typography variant="h6" component="h2">
            Charge Log
          </Typography>
        </Box>
        <Box>
          <SearchField
            initialValue={tableFilters.vendorId}
            data-testid="search-field"
            onChange={(searchTerm) =>
              onFilter({
                vendorId: searchTerm || undefined,
              })
            }
          />
        </Box>
      </Box>
      <Box display="flex" p={2} alignItems="flex-start">
        <Box mr={3}>
          <StyledDateRangeField
            placeholder="Select date range"
            value={{
              startDate: tableFilters?.startDate ? new Date(tableFilters.startDate) : undefined,
              endDate: tableFilters?.endDate ? new Date(tableFilters.endDate) : undefined,
            }}
            onChange={(dateRangeValue) =>
              onFilter({
                startDate: dateRangeValue?.startDate || undefined,
                endDate: dateRangeValue?.endDate || undefined,
              })
            }
            DateRangePickerProps={{
              inputRanges: [],
              staticRanges,
              startDatePlaceholder: 'Start Date',
              endDatePlaceholder: 'End Date',
            }}
          />
        </Box>
        <Box>
          <Autocomplete
            multiple
            disablePortal
            limitTags={2}
            className="select"
            id="multiple-limit-tags"
            data-test-id="plan-options"
            options={
              !loading && !error
                ? planOptions?.listPlans?.edges?.map((plan) => ({
                    value: plan?.altId || 0,
                    label: `${plan?.planName}` || '',
                  })) || []
                : []
            }
            onChange={(e, value) => {
              onFilter({ plans: value });
            }}
            defaultValue={tableFilters.plans}
            disableCloseOnSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="default"
                  size="small"
                  key={option.value}
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(option) => option.label}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="filled" label="Plan" />}
          />
        </Box>
        <Box ml={3}>
          <Autocomplete
            multiple
            disablePortal
            limitTags={2}
            className="select"
            id="driver-autocomplete"
            data-test-id="driver-options"
            options={
              account?.drivers?.edges?.map((driver) => ({
                value: driver?.id || 0,
                label: `${driver?.firstName} ${driver?.lastName}`,
              })) || []
            }
            onChange={(e, value) => {
              onFilter({ drivers: value });
            }}
            defaultValue={tableFilters.drivers}
            disableCloseOnSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="default"
                  size="small"
                  key={option.value}
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(option) => option.label}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="filled" label="Driver" />}
          />
        </Box>
        <Box ml={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={tableFilters.corrupted}
                data-test-id="corrupted-checkbox"
                onChange={() => onFilter({ corrupted: !tableFilters.corrupted })}
              />
            }
            label="Corrupted"
          />
        </Box>
        <Box mt={1} marginLeft="auto">
          <ExportButton filter={queryFilters} />
        </Box>
      </Box>
    </div>
  );
}
