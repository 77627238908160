/* stylelint-disable color-no-hex */
import styled from 'styled-components';

export const Styled = styled.div`
  > div {
    &.textContainer {
      min-height: 50px;
      span {
        &:first-of-type {
          margin-bottom: 16px;
        }
      }
    }
  }
`;
