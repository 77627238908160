import { useFormikContext } from 'formik';
import { useCallback } from 'react';

/**
 * A hook to generate a function to pass to EVgo Dropdowns
 */
export const useBlurDropdown = (): ((event: React.ChangeEvent<HTMLInputElement>) => void) => {
  const { setFieldTouched } = useFormikContext();

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setFieldTouched(event.target.name, true, false),
    [setFieldTouched],
  );
};
