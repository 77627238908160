/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloQueryResult, QueryResult, useMutation, useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { Card, ListCardsFilterInput, ListCardsInput, Maybe, Query } from 'src/@types';
import {
  activateCards,
  assignCards,
  createCard,
  deactivateCards,
  listCards,
  reserveCards,
  suspendCards,
  unassignCards,
} from '../../../../apollo/queries/cards';
import { sanitizeSearch, titleCase, updateQuery } from '../../../../lib/helpers';
import { ListSearch as CardsListSearch } from '../../../shared/ListSearch';
import { CardActions } from '../CardActions';
import { CardsListFilters } from '../CardsListFilters';
import { Styled as StyledPaper } from './styles';
import { useMappedFalconConstraints } from 'src/lib/hooks/useMappedFalconConstraints';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
import { TableLink } from 'src/components/shared/TableLink';

export interface Props {
  title: string;
  className?: string;
}

const searchFields = ['externalNumber', 'internalNumber', 'driver_firstName', 'driver_lastName', 'accountId'];
const brands = [
  { label: 'EVgo', value: 'EVgo' },
  { label: 'Lyft', value: 'Lyft' },
  { label: 'Maven', value: 'Maven' },
];
const sort = { internalNumber: 'ASC' };

/**
 * Gets list with up-to-date limit info
 */
export const sanitizeFilter = (filter?: Maybe<ListCardsFilterInput>): Partial<ListCardsFilterInput> => {
  const cardStatus = filter?.cardStatus ? _.omit(filter.cardStatus, '__typename') : null;
  const brand = filter?.brand ? _.omit(filter.brand, '__typename') : null;
  let filters = {};

  if (!_.isEmpty(cardStatus) && !_.isEmpty(brand)) {
    filters = { cardStatus, brand };
  } else if (!_.isEmpty(cardStatus)) {
    filters = { cardStatus };
  } else if (!_.isEmpty(brand)) {
    filters = { brand };
  }

  return filters;
};

/**
 * Changes cards list page
 */
export const onPageChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: ListCardsInput,
  event: string,
  page: number,
): Promise<ApolloQueryResult<unknown>> => {
  const { pageSize, search, filter } = metadata;
  let sanitizedSearch = sanitizeSearch(search, searchFields);
  if (_.isEmpty(_.get(sanitizedSearch, 'accountId'))) {
    sanitizedSearch = _.omit(sanitizedSearch, 'accountId');
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page,
        pageSize,
        filter: sanitizeFilter(filter),
        sort,
        search: sanitizedSearch,
      },
    },
  });
};

/**
 * Changes cards list page size
 */
export const onRowsPerPageChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: ListCardsInput,
  event: React.ChangeEvent<HTMLInputElement>,
): Promise<ApolloQueryResult<unknown>> => {
  const { search, filter } = metadata;
  let sanitizedSearch = sanitizeSearch(search, searchFields);
  if (_.isEmpty(_.get(sanitizedSearch, 'accountId'))) {
    sanitizedSearch = _.omit(sanitizedSearch, 'accountId');
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize: event.target.value,
        filter: sanitizeFilter(filter),
        search: sanitizedSearch,
        sort,
      },
    },
  });
};

/**
 * Search cards list
 */
export const onSearchChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: ListCardsInput,
  target: React.ChangeEvent<HTMLInputElement>['target'],
): Promise<ApolloQueryResult<unknown>> => {
  const { pageSize, filter } = metadata;

  let search: Maybe<{ [key: string]: unknown }> = {};
  if (target?.value?.length) {
    searchFields.forEach((field) => {
      search = search || {};
      if (field === 'accountId') {
        if (!_.isNaN(Number(`${target.value}`))) {
          search[field] = { eq: Number(`${target.value}`) };
        }
      } else {
        if (target.value.includes(' ') && (field === 'driver_firstName' || field === 'driver_lastName')) {
          const searchParts = target.value.split(' ');
          search[field] = {
            iLike: field === 'driver_firstName' ? `%${searchParts[0]}%` : `%${_.join(searchParts.slice(1), ' ')}%`,
          };
        } else {
          search[field] = { iLike: `%${target.value}%` };
        }
      }
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize,
        sort,
        filter: sanitizeFilter(filter),
        search,
      },
    },
  });
};

/**
 * Update card statuses
 */
//  (fn: (() => void) | undefined) => void;
export const updateCardsStatus = (checked: unknown, bulkUpdate: any): Promise<ApolloQueryResult<unknown>> => {
  return bulkUpdate({
    updateQuery,
    variables: {
      input: {
        cardIds: checked,
      },
    },
  });
};

/**
 * Assign cards to user
 */
export const assignCardsUpdate = (input: unknown, bulkUpdate: any): Promise<ApolloQueryResult<unknown>> => {
  return bulkUpdate({
    updateQuery,
    variables: {
      input,
    },
  });
};

/**
 * Create Card
 */
export const addCard = (input: unknown, createCardMutation: any): void => {
  createCardMutation({
    updateQuery,
    variables: {
      input,
    },
  });
};

/**
 * Gets list with up-to-date limit info
 */
export const onCheck = (
  event: React.ChangeEvent<HTMLInputElement>,
  checked: string[],
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>,
  cards: Maybe<Card>[],
): void => {
  const cardID = event.target.getAttribute('card-altid');

  if (cardID && checked.indexOf(cardID) === -1) {
    setChecked(_.concat(checked, cardID));
    if (_.concat(checked, cardID).length === cards.length) {
      setCheckAll(true);
    }
  } else if (cardID) {
    setChecked(_.without(checked, cardID));
    setCheckAll(false);
  }
};

/**
 * Gets list with up-to-date limit info
 */
export const onCheckAll = (
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  checkAll: boolean,
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>,
  cards: Maybe<Maybe<Card>[]>,
): void => {
  const allCards = cards ? cards?.map((card) => card?.altId || '') : [];

  if (checkAll) {
    setCheckAll(false);
    setChecked([]);
  } else {
    setCheckAll(true);
    setChecked(allCards);
  }
};

/**
 * Filter Chargers list
 */
const updateFilters = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  meta: ListCardsInput,
  newFilters: ListCardsFilterInput,
  key: string,
) => {
  const { pageSize, filter, search } = meta;
  let brandFilters: Maybe<{
    brand: {
      in: Maybe<any> | never[];
    };
  }> = { brand: { in: newFilters?.brand || [] } };
  let cardStatusFilters: Maybe<{
    cardStatus: {
      in: Maybe<any> | never[];
    };
  }> = { cardStatus: { in: newFilters?.cardStatus || [] } };

  if (key === 'brand') {
    brandFilters = { brand: { in: newFilters?.brand || [] } };
    cardStatusFilters = { cardStatus: { in: filter?.cardStatus?.in || [] } };
  }
  if (key === 'cardStatus') {
    brandFilters = { brand: { in: filter?.brand?.in || [] } };
    cardStatusFilters = { cardStatus: { in: newFilters?.cardStatus || [] } };
  }

  if (_.isEmpty(brandFilters.brand.in)) {
    brandFilters = null;
  }

  if (_.isEmpty(cardStatusFilters.cardStatus.in)) {
    cardStatusFilters = null;
  }

  let sanitizedSearch = sanitizeSearch(search, searchFields);
  if (_.isEmpty(_.get(sanitizedSearch, 'accountId'))) {
    sanitizedSearch = _.omit(sanitizedSearch, 'accountId');
  }

  const shapedFilters = { ...brandFilters, ...cardStatusFilters };
  const inputs = {
    page: 0,
    pageSize,
    sort,
    filter: shapedFilters,
    search: sanitizedSearch,
  };

  return fetchMore({
    updateQuery,
    variables: {
      input: inputs,
    },
  });
};

/**
 * Cards Table component
 */
export const CardsTable: React.FC<Props> = (props) => {
  const id = _.kebabCase('CardsTable');
  const className = id;
  const { title } = props;
  const { constraints: cardTypeContraints } = useMappedFalconConstraints({
    tableName: { eq: 'cards' },
    columnName: { eq: 'card_type' },
    map: (row) => row.columnText as string,
  });

  const { data, fetchMore, loading } = useQuery<Query>(listCards, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page: 0,
        pageSize: 10,
        sort,
      },
    },
  });
  const { edges, ...metadata } = data?.listCards || { edges: [] };

  const debouncedOnSearchChange: any =
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : fetchMore;
  const debouncedFetchMore: any = typeof fetchMore === 'function' ? _.debounce(fetchMore, 500) : fetchMore;
  const [checked, setChecked] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const snackbar = useSnackbar();

  const columns = [
    { id: 'internalNumber', key: 'internalNumber', label: 'Internal', sortable: false },
    { id: 'externalNumber', key: 'externalNumber', label: 'External', sortable: false },
    { id: 'brand', key: 'brand', label: 'Brand', sortable: false },
    { id: 'cardTypeId', key: 'cardTypeId', label: 'Type', sortable: false },
    { id: 'accountNumber', key: 'accountNumber', label: 'Account Number', sortable: false },
    { id: 'name', key: 'name', label: 'Name', sortable: false },
    { id: 'cardStatus', key: 'cardStatus', label: 'Status', sortable: false },
  ];

  const [assignCardsMutation] = useMutation(assignCards, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      snackbar.success('The selected card(s) have been assigned.');
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const [unassignCardsMutation] = useMutation(unassignCards, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
      snackbar.success('The selected card(s) have been unassigned.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const [activateCardsMutation] = useMutation(activateCards, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
      snackbar.success('The selected card(s) have been unassigned.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const [deactivateCardsMutation] = useMutation(deactivateCards, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
      snackbar.success('The selected card(s) have been unassigned.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const [reserveCardsMutation] = useMutation(reserveCards, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
      snackbar.success('The selected card(s) have been unassigned.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const [suspendCardsMutation] = useMutation(suspendCards, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
      snackbar.success('The selected card(s) have been unassigned.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const [createCardMutation] = useMutation(createCard, {
    refetchQueries: () => [{ query: listCards, variables: { input: { page: 0, pageSize: 10, sort } } }],
    onCompleted() {
      setChecked([]);
      setSelectedBrands([]);
      setSelectedStatuses([]);
      setCheckAll(false);
      snackbar.success('The selected card(s) have been unassigned.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });

  if (!edges) {
    return null;
  }

  return (
    <StyledPaper className={className} component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            {title}
          </Typography>
        </div>

        <div className={className}>
          <CardsListSearch
            className={`${className} search`}
            type="card"
            data-testid="cards-table-list-search"
            onSearchChange={(search) => {
              setCheckAll(false);
              setChecked([]);
              debouncedOnSearchChange(fetchMore, metadata, search);
            }}
          />
        </div>
      </header>

      <CardsListFilters
        updateFilters={(newFilters, key) => {
          setCheckAll(false);
          setChecked([]);
          updateFilters(debouncedFetchMore, metadata as any, newFilters as any, key);
        }}
        className={`${className} filters`}
        brands={brands}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
      />

      <Divider />

      <Table className={className} data-testid="cards-table">
        <TableHead>
          <TableRow className={className}>
            <TableCell className={`${className} checkbox`} data-testid={`cards-table-header-checkbox`}>
              <Checkbox
                className={className}
                indeterminate={!!checked && checked.length > 0 && checked.length < edges.length}
                checked={checked.length > 0 && checkAll}
                onChange={() => onCheckAll(setChecked, checkAll, setCheckAll, edges)}
                inputProps={{ id: `${id}-check-all-checkbox` }}
              />
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                className={className}
                data-testid={`cards-table-header-${column.label.replace(/ /gi, '_').toLowerCase()}`}
              >
                <TableSortLabel
                  // active={ !_.isEmpty(_.get(metadata, `sort.${column.id}`)) }
                  // direction={ _.toLower(_.get(metadata, `sort.${column.id}`) || 'asc') }
                  // onClick={
                  //   column.sortable
                  //     ? onSortChange(fetchMore, metadata, column.id)
                  //     : null
                  // }
                  disabled={!column.sortable}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading &&
            edges?.map((card, i) => {
              if (!card) {
                return null;
              }
              return (
                <TableRow key={card.internalNumber}>
                  <TableCell className={`${className} checkbox`} data-testid={`cards-table-cell-checkbox-${i}`}>
                    <Checkbox
                      className={`${className} checkbox`}
                      checked={checked.indexOf(card.altId) !== -1}
                      inputProps={{
                        id: `${id}-row-${i}-checkbox`,
                        // @ts-ignore
                        'card-altid': card.altId,
                      }}
                      onChange={(e) => onCheck(e, checked, setChecked, setCheckAll, edges)}
                    />
                  </TableCell>
                  <Fragment>
                    <TableCell
                      className={`${className} internalNumber`}
                      data-testid={`cards-table-cell-internal-number-${i}`}
                    >
                      {card?.internalNumber}
                    </TableCell>
                    <TableCell
                      className={`${className} externalNumber`}
                      data-testid={`cards-table-cell-external-number-${i}`}
                    >
                      {card?.externalNumber}
                    </TableCell>
                    <TableCell className={`${className} brand`} data-testid={`cards-table-cell-brand-${i}`}>
                      {card?.brand}
                    </TableCell>
                    <TableCell className={`${className} cardType`} data-testid={`cards-table-cell-card-type-${i}`}>
                      {cardTypeContraints.get(card?.cardTypeId as number)}
                    </TableCell>
                    <TableCell
                      className={`${className} accountNumber`}
                      data-testid={`cards-table-cell-account-number-${i}`}
                    >
                      {card?.account?.id && (
                        <TableLink to={`/accounts/${card?.account?.altId}`}>
                          <p data-testid={`account-number-${i}`}>{card?.account?.id}</p>
                        </TableLink>
                      )}
                    </TableCell>
                    <TableCell className={`${className} name`} data-testid={`cards-table-cell-name-${i}`}>{`${
                      card?.driver?.firstName || ''
                    } ${card?.driver?.lastName || ''}`}</TableCell>
                    <TableCell className={`${className} cardStatus`} data-testid={`cards-table-cell-card-status-${i}`}>
                      {titleCase(card?.cardStatus)}
                    </TableCell>
                  </Fragment>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter className={className}>
          <TableRow className={className}>
            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length + 1}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              data-testid="cards-table-pagination"
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={(e, page) => {
                onPageChange(fetchMore, metadata as any, e as any, page);
                setCheckAll(false);
                setChecked([]);
              }}
              onRowsPerPageChange={(e) => {
                onRowsPerPageChange(fetchMore, metadata as any, e as any);
                setCheckAll(false);
                setChecked([]);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <CardActions
        className={className}
        selected={checked}
        data={edges}
        updateCardsStatus={(updateFunction) => updateCardsStatus(checked, updateFunction)}
        assignCardsUpdate={(input, updateFunction) => assignCardsUpdate(input, updateFunction)}
        assignCards={assignCardsMutation}
        unassignCards={unassignCardsMutation}
        activateCards={activateCardsMutation}
        deactivateCards={deactivateCardsMutation}
        reserveCards={reserveCardsMutation}
        suspendCards={suspendCardsMutation}
        brands={brands}
        addCard={(input) => addCard(input, createCardMutation)}
      />
    </StyledPaper>
  );
};
