import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';

const Container: React.FC = ({ children: message }) => (
  <Box p={4} display="flex" justifyContent="center" data-testid="table-empty-message-container">
    <Typography color="textSecondary">{message}</Typography>
  </Box>
);

export const TableEmpty: React.FC = ({ children = 'No Results' }) => {
  const isString = _.isString(children);

  return (
    <tbody data-testid="table-empty">
      <tr>
        <td colSpan={100}>{isString ? <Container>{children}</Container> : children}</td>
      </tr>
    </tbody>
  );
};
