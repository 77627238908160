/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Styled } from './styles';

export interface ChademoIconProps {
  id?: string;
  className?: string;
}

/**
 * CHAdeMo icon component
 */
export const ChademoIcon: React.FC<ChademoIconProps> = ({ id, className: parentClass }) => {
  const className = 'ChademoIcon';
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <Styled
      id={id}
      className={customClass}
      style={{ enableBackground: 'new 0 0 36 36' } as any}
      version="1.1"
      viewBox="0 0 36 36"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        className={customClass}
        d="M18,0C8.1,0,0,8.1,0,18s8.1,18,18,18s18-8.1,18-18S27.9,0,18,0z M18,34.7C8.7,34.7,1.3,27.3,1.3,18
        S8.7,1.3,18,1.3S34.7,8.7,34.7,18S27.3,34.7,18,34.7z M10.3,18c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9
        C9.4,16.1,10.3,16.8,10.3,18L10.3,18z M14.1,17.9c0-3.2-2.6-5.7-5.8-5.7H8.2c-3.2,0-5.8,2.7-5.8,5.8c0,3.2,2.7,5.8,5.8,5.8h0.1
        C11.7,23.8,14.1,21.1,14.1,17.9L14.1,17.9z M8.5,22.5L8.5,22.5c-2.6,0-4.5-1.9-4.6-4.4c0-2.4,1.9-4.5,4.4-4.6l0,0h0.1
        c2.4,0,4.5,1.9,4.5,4.4C12.9,20.4,10.9,22.5,8.5,22.5L8.5,22.5L8.5,22.5z M29.6,18c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9
        c0-1,0.8-1.9,1.9-1.9C28.7,16.1,29.6,16.8,29.6,18L29.6,18z M27.6,12.2L27.6,12.2c-3.3,0-5.9,2.7-5.8,5.8c0,3.2,2.7,5.8,5.8,5.8h0.1
        c3.2,0,5.8-2.7,5.8-5.8C33.4,14.8,30.9,12.2,27.6,12.2z M30.9,21.1c-0.8,0.9-1.9,1.4-3.2,1.4h-0.1c-2.4,0-4.5-1.9-4.5-4.4
        s1.9-4.5,4.4-4.6l0,0h0.1c2.4,0,4.5,1.9,4.5,4.4C32.1,19.2,31.8,20.3,30.9,21.1L30.9,21.1z M18,14.1L18,14.1c3.3,0,5.9-2.7,5.8-5.8
        c0-3.2-2.6-5.8-5.8-5.8l0,0h-0.1c-3.2,0-5.8,2.7-5.8,5.8C12.2,11.6,14.8,14.1,18,14.1z M18.1,12.9L18.1,12.9c-1,0-1.9-0.4-2.7-0.9
        L18,9.3l2.7,2.7C19.9,12.5,19,12.9,18.1,12.9L18.1,12.9z M22.5,8.2c0,1-0.3,1.9-0.9,2.7l-2.7-2.7l2.7-2.7
        C22.1,6.4,22.5,7.3,22.5,8.2L22.5,8.2z M17.9,3.9L17.9,3.9c1,0,1.9,0.4,2.7,0.9L18,7.5l-2.7-2.7C16.1,4.2,17,3.9,17.9,3.9z
        M14.4,5.7l2.7,2.7l-2.7,2.7C13.2,9.5,13.2,7.2,14.4,5.7L14.4,5.7z M18,21.9L18,21.9c-3.3,0-5.9,2.7-5.8,5.8c0,3.2,2.7,5.8,5.8,5.8
        h0.1c3.2,0,5.8-2.7,5.8-5.8C23.8,24.4,21.2,21.9,18,21.9z M17.9,23.1L17.9,23.1c1,0,1.9,0.4,2.7,0.9L18,26.7L15.3,24
        C16.1,23.5,17,23.1,17.9,23.1L17.9,23.1z M13.5,27.8c0-1,0.3-1.9,0.9-2.7l2.7,2.7l-2.7,2.7C13.9,29.6,13.5,28.7,13.5,27.8L13.5,27.8
        z M18.1,32.1L18.1,32.1c-1,0-1.9-0.4-2.7-0.9l2.7-2.7l2.7,2.7C19.9,31.8,19,32.1,18.1,32.1L18.1,32.1z M21.6,30.3l-2.7-2.7l2.7-2.7
        C22.8,26.5,22.8,28.8,21.6,30.3L21.6,30.3z"
      />
    </Styled>
  );
};
