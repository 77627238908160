import { Form } from 'formik';
import styled from 'styled-components';
import config from '../../../../config';

const { colors } = config;

export const Styled = styled(Form)`
  @keyframes spin-clockwise {
    0% {
      transform: rotateZ(0);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  footer {
    > button {
      svg {
        position: absolute;
        animation: spin-clockwise 1s linear infinite;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
`;
