/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  form {
    display: flex;
    flex-direction: column;
    padding: ${`${spacing(2.5)}px ${spacing(3.25)}px`};

    div {
      .half {
        width: calc(50% - ${spacing(2)}px);
        margin: ${spacing()}px;
      }
    }

    .form-wrapper {
      height: 120px;
    }

    .input-feedback {
      margin-right: 10px;
      float: right;
    }

    .checkbox-wrapper {
      flex-grow: 1;
      margin-top: ${spacing(4)}px;

      .checkbox {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .checkbox-input {
          padding-top: ${spacing(1)}px;
        }
        .checkbox-label {
          padding-top: ${spacing(1.5)}px;
        }
      }
    }

    p {
      font-weight: 300;
      margin-bottom: ${spacing(2)}px;
    }

    hr {
      width: calc(100% + ${spacing(6)}px);
      margin-left: -${spacing(3)}px;
    }

    .hidden {
      display: none;
    }
  }
`;
