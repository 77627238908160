import React from 'react';
import styled from 'styled-components';
import { InfoOutlined } from '@material-ui/icons';
import { theme } from '@evgo/react-material-components';
import { Tooltip as MUITooltip, Popper as MUIPopper } from '@material-ui/core';

export type TooltipProps = {
  content: NonNullable<React.ReactNode>;
  fontSize?: 'small' | 'default' | 'inherit' | 'large' | 'medium' | undefined;
  dataTestId?: string;
};

const { typography, palette, spacing } = theme;

const StyledPopper = styled(MUIPopper)`
  .MuiTooltip-tooltip {
    font-weight: 400;
    color: ${palette.common.black};
    font-size: ${typography.fontSize}px;
    background-color: ${palette.common.white};
    padding: ${spacing(2, 2)};
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
  }
`;

const StyledInfoOutlined = styled(InfoOutlined)`
  margin-left: ${spacing(0.5)}px;
  margin-right: ${spacing(0.5)}px;
  user-select: none;
  color: rgba(0, 0, 0, 0.38);
`;

export function Tooltip({ content, fontSize = 'small', dataTestId }: TooltipProps) {
  return (
    <MUITooltip title={content} PopperComponent={StyledPopper} interactive>
      <StyledInfoOutlined className="TableTooltipIcon" fontSize={fontSize} data-testid={dataTestId} />
    </MUITooltip>
  );
}
