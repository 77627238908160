import { useQuery } from '@apollo/client';
import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { listFalconConstraints } from '../../../apollo/queries/options';
import { createPlan, updatePlan } from '../../../apollo/queries/plans';
import { transformPlanResponse, transformPlanValues } from '../../../lib/helpers';
import { useFormikSubmit } from '../../../lib/hooks';
import { PlanForm, statusFields } from './PlanForm';
import { initialValues } from './PlanForm/initialValues';
import { Styled } from './styles';
import { validations as validationSchema } from './PlanForm/validations';

/**
 * Plan view component
 */
export const PlanView: React.FC = () => {
  const className = 'PlanView';
  const { altId } = useParams<{ altId: string }>();
  const handleSubmit = useFormikSubmit('Plan', createPlan, updatePlan, {
    statusFields,
    transformValues: transformPlanValues,
    transformResponse: transformPlanResponse,
    altId,
    noFormReset: true,
  }) as never;

  const { data: planStatusData, loading: planStatusLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'plan_status' },
          columnText: { eq: 'Active' },
        },
      },
    },
  });
  const { data: cardBrandData, loading: cardBrandLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'card_brand' },
          columnText: { eq: 'EVgo' },
        },
      },
    },
  });
  const { data: intervalUnitData, loading: intervalUnitLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'interval_unit' },
          columnText: { eq: 'Month' },
        },
      },
    },
  });
  const { data: terminationBehaviorData, loading: terminationBehaviorLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'termination_behavior' },
          columnText: { eq: 'Expires' },
        },
      },
    },
  });

  const defaultValues = { ...initialValues };
  if (!planStatusLoading)
    defaultValues.planStatusId = Number(_.get(planStatusData, 'listFalconConstraints.edges[0].value'));
  if (!cardBrandLoading)
    defaultValues.cardBrandId = Number(_.get(cardBrandData, 'listFalconConstraints.edges[0].value'));
  if (!intervalUnitLoading)
    defaultValues.intervalUnitId = Number(_.get(intervalUnitData, 'listFalconConstraints.edges[0].value'));
  if (!terminationBehaviorLoading && terminationBehaviorData)
    defaultValues.terminationBehaviorId = Number(
      _.get(terminationBehaviorData, 'listFalconConstraints.edges[0].value'),
    );
  if (!planStatusData || !cardBrandData || !intervalUnitData || !terminationBehaviorData) return null;

  return (
    <Styled className={className}>
      <Formik
        initialStatus={initialValues}
        initialValues={defaultValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <PlanForm className={className} />
      </Formik>
    </Styled>
  );
};
