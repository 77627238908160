import { gql } from '@apollo/client';

export const listChargerModels = gql`
  query listChargerModels($input: ListChargerModelsInput) {
    listChargerModels(input: $input) {
      edges {
        altId
        breakerRating
        currentThd
        efficiency
        inputAcVoltage
        manufacturer
        modelName
        powerFactor
        powerOutput
        simultaneousChargeSupported
        unitType
        evses {
          edges {
            connectors {
              edges {
                altId
              }
            }
          }
        }
      }
      page
      pageSize
      total
      sort {
        modelName
        manufacturer
        unitType
      }
      filter {
        manufacturer {
          in
        }
      }
      search {
        modelName {
          iLike
        }
      }
    }
  }
`;
