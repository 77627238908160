import { theme } from '@evgo/react-material-components';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'src/components/shared/Tooltip';
import { Card, CardHeader } from '../Card';
import { CustomHoursInput } from './CustomHoursInput';
import { PricingTypeInput } from './PricingTypeInput';
import { HmiDisplayInputs } from 'src/components/views/ExtendPlusView/ChargerPrices/Pricing/ChargerPricingForm/PriceForm/HmiDisplayInputs';
import { FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS, useFlag } from 'src/config/featureFlag';

type Props = {
  name: string;
  title?: string;
  scheduleTooltip?: NonNullable<React.ReactNode>;
  readonly?: boolean;
  showHmiDisplay?: boolean;
  index?: number;
  rankOptions?: Array<{ label: string; value: number | null }>;
};

const StyledPrice = styled(Typography)`
  border-bottom: 1px solid ${theme.palette.grey['300']};
`;

const StyledHeading = styled(Typography)`
  display: flex;
  align-items: center;
  padding-left: ${theme.spacing(5)}px;
  border-bottom: 1px solid ${theme.palette.grey['300']};
  white-space: nowrap;
`;

const StyledContent = styled.div`
  border-left: 1px solid ${theme.palette.grey['300']};
  padding: ${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px;
  max-width: 200px;
`;

export const CustomPricingInput: React.FC<Props> = ({
  name,
  title,
  readonly = false,
  scheduleTooltip,
  showHmiDisplay,
  rankOptions,
  index,
}) => {
  const enableHmi = useFlag(FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS);
  return (
    <Card mt={4}>
      {title && <CardHeader title={title} />}
      <Box display="flex">
        <Box>
          <StyledPrice variant="h6">Price</StyledPrice>
          <PricingTypeInput name={`${name}.pricing`} readonly={readonly} />
        </Box>
        <Box>
          <StyledHeading variant="h6">
            Schedule
            <Box pl={0.3} />
            {scheduleTooltip && !readonly && (
              <Tooltip content={scheduleTooltip} dataTestId="price-schedule-tooltip"></Tooltip>
            )}
          </StyledHeading>
          <CustomHoursInput name={`${name}.tariffSchedules`} readonly={readonly} />
        </Box>
        {enableHmi && showHmiDisplay && (
          <Box>
            <StyledHeading variant="h6">HMI Display</StyledHeading>
            <StyledContent>
              <HmiDisplayInputs
                name={'customPricing'}
                id="custom-pricing"
                rankOptions={rankOptions}
                index={index}
                readonly={readonly}
              />
            </StyledContent>
          </Box>
        )}
      </Box>
    </Card>
  );
};
