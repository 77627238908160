import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${spacing(6)}px;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};
  padding-bottom: ${spacing(9)}px;

  section,
  article {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};
  }

  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      button {
        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }

      &:first-child {
        flex: 1;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;

        * {
          margin: 0 ${spacing()}px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }
  }

  table {
    td,
    th {
      padding: ${`${spacing(2.25)}px ${spacing()}px`};

      &.checkbox {
        > span {
          padding: 0;
        }
      }

      &:first-child {
        padding-left: ${spacing(2)}px;
      }

      &:last-child {
        padding-right: ${spacing(2)}px;
      }
    }

    td {
      a {
        color: ${config.colors.accentBlue};
      }

      padding: ${`${spacing() / 2}px ${spacing()}px`};

      &.checkbox {
        width: 1%;
      }

      &.name {
        width: 20%;
      }

      &.description {
        width: 35%;
      }
    }

    th {
      &.checkbox {
        width: 1%;
      }

      &.name {
        width: 55%;
      }

      &.description {
        width: 20%;
      }
    }
  }
`;
