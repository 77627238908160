import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(Accordion)`
  width: 100%;

  div.product {
    display: flex;
    flex-wrap: wrap;
    padding: ${`${spacing(3)}px ${spacing(2)}px ${spacing() / 2}px`};

    > div {
      flex-basis: 20%;
      box-sizing: border-box;
      padding: ${`0 ${spacing()}px`};

      label {
        transform: translate(calc(12px + ${spacing()}px), 20px) scale(1);

        &.prefilled,
        &[data-shrink='true'] {
          transform: translate(${spacing(2)}px, 10px) scale(0.75);
        }
      }

      &.quarter {
        flex-basis: 25%;
      }

      &.half {
        flex-basis: 50%;
        display: flex;
        justify-content: flex-end;
        button {
          width: fit-content;
          height: fit-content;
        }
      }
    }

    > label {
      flex-basis: 40%;
      box-sizing: border-box;
      margin: ${`0 ${spacing(1.75)}px ${spacing(2.5)}px -${spacing(1.75)}px`};
      padding: ${`0 ${spacing()}px`};
    }
  }

  .customDropdown {
    max-height: 142px;

    overflow-y: auto;

    .MuiButton-root {
      text-transform: none;
    }
  }
`;
