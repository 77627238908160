import { MutationTuple, OperationVariables, QueryResult, useMutation } from '@apollo/client';
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { AccountVehicle, Driver, DriversWithMeta, Maybe, Mutation } from 'src/@types';
import { useConfirmationDialog, useSnackbar } from 'src/lib/hooks';
import { getAccount, updateAccountVehicle, cancelAutochargeEnrollment } from 'src/apollo/queries/accounts';
import { updateQuery } from 'src/lib/helpers';
import { useModalContext } from 'src/contexts/ModalContext';

export interface Props {
  accountAltId: string;
  driver: Driver;
  drivers: DriversWithMeta;
  fetchMore: QueryResult<never, never>['fetchMore'];
  vehicle: { vehicle: Maybe<AccountVehicle>; formattedVehicleConnectors: string };
  onRemoveVehicle: () => void;
  className?: string;
}

export const onUpdateAutocharge = (
  confirmationDialog: ReturnType<typeof useConfirmationDialog>,
  onUpdateAccountVehicle: MutationTuple<Mutation, OperationVariables>[0],
  setState: React.Dispatch<React.SetStateAction<{ anchorElement: Maybe<HTMLButtonElement> }>>,
  action: string,
  accountAltId: string,
  vehicle: AccountVehicle,
  driver: Driver,
): void => {
  confirmationDialog.open({
    title: `${action} Autocharge`,
    body: `Are you sure you want to ${action.toLowerCase()} autocharge on this vehicle?`,
    callback: () => {
      onUpdateAccountVehicle({
        refetchQueries: () => [
          {
            query: getAccount,
            variables: {
              accountInput: { altId: accountAltId },
              driversInput: { pageSize: 999, paranoid: false },
              accountVehiclesInput: { pageSize: 999 },
              cardsInput: { pageSize: 999 },
            },
          },
        ],
        variables: {
          input: {
            altId: vehicle.altId || {},
            driverId: driver.altId || {},
          },
        },
      });
    },
  });
  setState({ anchorElement: null });
};

export const VehicleRow: React.FC<Props> = ({
  className,
  accountAltId,
  driver,
  fetchMore,
  vehicle: { vehicle, formattedVehicleConnectors },
  onRemoveVehicle,
}) => {
  const [state, setState] = useState({ anchorElement: null as Maybe<HTMLButtonElement> });

  const snackbar = useSnackbar();
  const { setModalState } = useModalContext();

  const confirmationDialog = useConfirmationDialog();
  let autochargeFlag = false;
  if (vehicle?.autochargeStatus?.columnValue === 'ENROLLED') {
    autochargeFlag = true;
  }
  const [onUpdateAccountVehicle] = useMutation(updateAccountVehicle, {
    onCompleted(res) {
      snackbar.success(
        `Autocharge successfully ${_.get(res, 'updateAccountVehicle.autochargeFlag') ? 'enabled' : 'disabled'}.`,
      );
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const { anchorElement } = state;
  const [cancelAutocharge] = useMutation(cancelAutochargeEnrollment, {
    onCompleted() {
      snackbar.success(`Autocharge successfully disabled.`);
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  const showEditAccountVehiclesModal = async () => {
    const res = await fetchMore({
      updateQuery,
      variables: {
        accountInput: { altId: accountAltId },
        driversInput: { pageSize: 999 },
        accountVehiclesInput: { pageSize: 999 },
        cardsInput: { pageSize: 999 },
      },
    });

    const { getAccount: updatedData } = _.get(res, 'data', {});
    setModalState({
      modalName: 'AccountVehicleModal',
      modalProps: {
        drivers: _.get(updatedData, 'drivers', {}),
        accountId: accountAltId,
        driver,
        fetchMore,
        vehicle,
      },
      modalVisible: true,
    });
  };

  if (!vehicle) {
    return null;
  }

  return (
    <Fragment>
      <TableRow className={className}>
        <TableCell className={className} data-testid="vehicle-vin-cell" component="th">
          {_.get(vehicle, 'vin', '')}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-type-cell">
          {_.get(vehicle, 'vehicle.makeModelYear', '')}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-connectors-cell">
          {formattedVehicleConnectors}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-mac-cell">
          {_.get(vehicle, 'macAddress', false) ? <Done /> : null}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-status-cell">
          {!_.get(vehicle, 'deletedAt') ? 'Active' : 'Inactive'}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-driver-cell">{`${_.get(driver, 'firstName')} ${_.get(
          driver,
          'lastName',
        )}`}</TableCell>
        <TableCell className={className} data-testid="vehicle-nickname-cell">
          {_.get(vehicle, 'nickName', '')}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-autocharge-cell">
          {vehicle?.autochargeStatus?.columnValue}
        </TableCell>
        <TableCell className={className} data-testid="vehicle-action-menu-cell">
          <IconButton
            data-testid="vehicle-action-menu-button"
            disabled={!_.isEmpty(_.get(vehicle, 'deletedAt'))}
            onClick={(e) => setState({ anchorElement: e.currentTarget })}
            size="small"
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        anchorEl={anchorElement}
        className={className}
        disableAutoFocusItem={true}
        open={Boolean(anchorElement)}
        onClose={() => setState({ anchorElement: null })}
      >
        <MenuItem
          className={className}
          onClick={() => {
            showEditAccountVehiclesModal();
            setState({ anchorElement: null });
          }}
        >
          Edit
        </MenuItem>
        {autochargeFlag ? (
          <MenuItem
            className={className}
            onClick={() =>
              onUpdateAutocharge(
                confirmationDialog,
                cancelAutocharge,
                setState,
                'Disable',
                accountAltId,
                vehicle,
                driver,
              )
            }
          >
            Disable Autocharge
          </MenuItem>
        ) : (
          <MenuItem
            className={className}
            disabled={!_.get(vehicle, 'macAddress', false)}
            onClick={() =>
              onUpdateAutocharge(
                confirmationDialog,
                onUpdateAccountVehicle,
                setState,
                'Enable',
                accountAltId,
                vehicle,
                driver,
              )
            }
          >
            Enable Autocharge
          </MenuItem>
        )}
        <MenuItem
          className={className}
          disabled={!!_.get(vehicle, 'deletedAt', false)}
          onClick={() => {
            setState({ anchorElement: null });
            onRemoveVehicle();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
