import { gql } from '@apollo/client';

export const getDriver = gql`
  query getDriver(
    $input: GetDriverInput
    $cardsInput: ListCardsInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    getDriver(input: $input) {
      id
      altId
      firstName
      lastName
      phone
      email
      createdAt
      updatedAt
      deletedAt
      driverStatus {
        id
        columnText
      }
      cards(input: $cardsInput) {
        edges {
          altId
          brand
          internalNumber
          externalNumber
          cardStatus
          driver {
            altId
            firstName
            lastName
          }
        }
        total
      }
      account {
        id
        altId
        accountStatus
        accountType
        contracts {
          total
          edges {
            altId
            plan {
              altId
              planName
            }
            startDate
            endDate
            closureDate
            status {
              columnText
            }
          }
        }
        mailingAddress {
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
      }
      accountVehicles {
        edges {
          altId
          nickName
          vin
          vehicle {
            makeModelYear
          }
        }
      }
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
