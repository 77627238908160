import { css } from 'styled-components';

// Copied from StoryBlock UI Rich Text Field in an Article to clone look for Display in Extend.
// Found via searching with inspector to find style sheet.

export default css`
  .ProseMirror {
    color: rgba(27, 36, 63, 1);
    font-size: 1.4rem;
    font-variant-ligatures: none;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-wrap: break-word;
    font-family: Roboto, sans-serif;
  }
  .ProseMirror h1,
  .ProseMirror h2,
  .ProseMirror h3,
  .ProseMirror h4,
  .ProseMirror h5,
  .ProseMirror h6 {
    font-weight: 600;
    line-height: 1.2;
    margin: 5px 0;
  }
  .ProseMirror p {
    line-height: 25px;
    margin: 15px 0;
  }
  .ProseMirror p:first-child {
    margin-top: 0;
  }
  .ProseMirror p code {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(223, 227, 232, 1);
    border-radius: 5px;
    color: rgba(225, 37, 95, 1);
    padding: 6px 3px;
    white-space: pre-wrap;
  }
  .ProseMirror__codetype {
    font-size: 1.2rem;
    position: absolute;
    right: 2px;
    top: 2px;
  }
  .ProseMirror hr {
    border: 0;
    border-top: 1px solid rgba(223, 227, 232, 1);
    box-sizing: content-box;
    height: 0;
    margin: 15px 0;
  }
  .ProseMirror blockquote {
    border-left: 3px solid rgba(223, 227, 232, 1);
    font-size: 1.4rem;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1em;
  }
  .ProseMirror ol,
  .ProseMirror ul {
    cursor: default;
    margin: 15px 0;
    padding-left: 30px;
  }
  .ProseMirror .field-type-asset-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ProseMirror .inline-blocks {
    box-sizing: border-box;
    margin-left: -11px;
    margin-right: -11px;
    z-index: 5;
  }
  .ProseMirror .inline-blocks .field-type-bloks__list,
  .ProseMirror .inline-blocks .sb-tabs {
    margin: 0;
    padding: 0;
  }
  .ProseMirror .inline-blocks .field-type-bloks__list li,
  .ProseMirror .inline-blocks .sb-tabs li {
    pointer-events: all;
  }
  .ProseMirror .inline-blocks .field-type-bloks {
    margin-left: 0;
    padding-left: 0;
  }
  .ProseMirror .inline-blocks .field-type-bloks__list > li {
    list-style: none;
  }
  .ProseMirror .inline-blocks .sb-select-list {
    margin-top: 8px;
    position: static;
  }
  .ProseMirror .inline-blocks .draggable-container--is-dragging .sortable-item {
    z-index: 4;
  }
  .ProseMirror .inline-blocks .sb-datepicker--active {
    min-height: 450px;
  }
  .ProseMirror .sortable-item {
    margin: 0 10px;
  }
  .ProseMirror .sortable-block-item,
  .ProseMirror .sortable-block-item + .editor-form {
    transition: margin 0.2s ease;
  }
  .ProseMirror .field-type-bloks .editor-form {
    animation: slideDown 1s ease;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    border-width: 0;
    max-height: 0;
    overflow: hidden;
    width: auto;
  }
  @keyframes slideDown {
    0% {
      max-height: 0;
    }
    99% {
      max-height: 100vh;
    }
    to {
      border-width: 1px;
      max-height: none;
      overflow: visible;
    }
  }
  .ProseMirror .sortable-block-item--is-showing-inline,
  .ProseMirror .sortable-block-item--is-showing-inline + .editor-form {
    margin: 0 -26px;
    min-height: auto;
    position: relative;
    z-index: 3;
  }
  .editor--visual-on .ProseMirror .sortable-block-item--is-showing-inline,
  .editor--visual-on .ProseMirror .sortable-block-item--is-showing-inline + .editor-form {
    margin: 0 -25px;
  }
  .ProseMirror .sortable-block-item--is-showing-inline + .editor-form {
    z-index: 2;
    border-radius: 0 0 5px 5px;
  }
  .ProseMirror .editor-form__sidebar {
    height: auto;
    border-left: 0;
  }
  .ProseMirror:after {
    content: ' ';
    display: block;
    height: 12px;
  }
  .ProseMirror ul {
    list-style-type: disc;
  }
  .ProseMirror li p {
    margin: 0;
  }
  .ProseMirror li {
    pointer-events: none;
    position: relative;
  }
  .ProseMirror li > * {
    pointer-events: auto;
  }
  .ProseMirror pre {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(223, 227, 232, 1);
    border-radius: 5px;
    color: rgba(49, 57, 82, 1);
    padding: 10px 16px;
    position: relative;
    tab-size: 4;
    white-space: pre-wrap;
  }

  .ProseMirror .ProseMirror-separator {
    background: none;
    height: 1px;
    min-height: 1px;
    width: 1px;
  }
  .ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;
  }
  .ProseMirror-gapcursor:after {
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
    border-top: 1px solid rgba(16, 21, 37, 1);
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
  }
  .ProseMirror-selectednode {
    outline: 3px solid rgba(104, 206, 248, 1);
  }
  @keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }
  .ProseMirror-hideselection ::-moz-selection {
    background: transparent;
  }
  .ProseMirror-hideselection ::selection {
    background: transparent;
  }
  .ProseMirror-hideselection * {
    caret-color: transparent;
  }
  .ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }
  .ProseMirror__styled {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(223, 227, 232, 1);
    border-radius: 5px;
    padding: 6px 3px;
  }
  .ProseMirror .table-container {
    margin: 0 15px;
  }
`;
