import { styled } from '@material-ui/core/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Alert from '@material-ui/lab/Alert';

export const StyledAlert = styled(Alert)({
  color: '#f44336',
});

export function FormAlert() {
  return (
    <StyledAlert icon={<ReportProblemOutlinedIcon fontSize="inherit" />} variant="outlined" severity="error">
      You currently still have form errors above. Please correct any errors before submitting.
    </StyledAlert>
  );
}
