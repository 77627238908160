import { gql } from '@apollo/client';

export const listSiteOptions = gql`
  query listSiteOptions {
    listSiteOptions {
      altId
      siteName
    }
  }
`;
