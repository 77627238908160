import { useQuery } from '@apollo/client';
import { Dropdown, TextInfo } from '@evgo/react-material-components';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Driver, Query } from 'src/@types';
import { LabelValue, ServerErrors } from 'src/@types/shared';
import { listFalconConstraints } from 'src/apollo/queries/options';
import { StyledModal } from 'src/components/shared/StyledModal';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export interface Props {
  driver?: Driver;
  open?: boolean;
  className?: string;
}

export interface FormValues {
  firstName: Driver['firstName'];
  lastName: Driver['lastName'];
  email: string;
  phone: string;
  driverStatus: {
    id: string;
  };
}

type SetFieldError = (path: string, message: string) => void;

export const getInitialValues = (driver?: Driver): Partial<Driver> => ({
  firstName: driver?.firstName || '',
  lastName: driver?.lastName || '',
  email: driver?.email || '',
  phone: driver?.phone || '',
  driverStatus: {
    id: driver?.driverStatus?.id || '201',
  },
});

export const handleServerErrors = (setFieldError: SetFieldError, serverErrors: ServerErrors[]): void => {
  serverErrors.forEach((err) => {
    if (err.errorMessage) {
      err.errorMessage.forEach((e) => {
        let { path } = e;
        if (path === 'username') path = 'email';
        if (path === 'driver_email') path = 'email';
        return setFieldError(path, e.message);
      });
    } else setFieldError(err.path, err.message);
  });
};

export const AccountDriverModalForm: React.FC<Props> = (props) => {
  const className = 'accountdrivermodal-form';
  const id = _.kebabCase(className);
  const { open, driver: editDriver } = props;
  const { setModalState } = useModalContext();
  const { values, resetForm, errors, touched, handleBlur, handleChange, isSubmitting, isValid, dirty, submitForm } =
    useFormikContext<FormValues>();
  const { data: filteredStatuses, loading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'drivers',
          },
          columnName: {
            eq: 'driver_status',
          },
        },
        sort: {
          id: 'ASC',
          columnText: 'ASC',
        },
      },
    },
    skip: !open,
  });

  let shapedStatusOptions = [] as LabelValue<number>[];
  if (!loading && filteredStatuses) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shapedStatusOptions = (filteredStatuses?.listFalconConstraints?.edges || []).map(({ label, value }: any) => ({
      label,
      value,
    }));
  }

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <Fragment>
      <StyledModal
        className={clsx(customClass, 'modal')}
        onClose={() => {
          setModalState({
            modalName: '',
            modalVisible: false,
            modalProps: {},
          });
          resetForm();
        }}
        title={editDriver ? 'Edit Driver' : 'Add Driver'}
        secondaryButtonText="Cancel"
        secondaryButtonClick={() => {
          setModalState({
            modalName: '',
            modalVisible: false,
            modalProps: {},
          });
          resetForm();
        }}
        open={open}
        onClick={submitForm}
        primaryButtonText={editDriver ? 'SAVE' : 'ADD Driver'}
        primaryButtonDisabled={isSubmitting || !isValid || !dirty}
      >
        <Styled className={className}>
          <Form id={`${id}-form`} className={className}>
            <div className={`${className} content`}>
              <div className={className}>
                <TextInfo
                  className={className}
                  label="First Name *"
                  value={values.firstName}
                  error={errors.firstName && touched.firstName}
                  helpertext={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="add-driver-modal-first-name-input"
                  data-testid="add-driver-modal-first-name-input"
                  name="firstName"
                  variant="filled"
                  disabled={!!editDriver}
                />

                <TextInfo
                  className={className}
                  label="Last Name *"
                  value={values.lastName}
                  error={errors.lastName && touched.lastName}
                  helpertext={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="add-driver-modal-last-name-input"
                  data-testid="add-driver-modal-last-name-input"
                  name="lastName"
                  variant="filled"
                  disabled={!!editDriver}
                />

                <TextInfo
                  className={className}
                  label="Email Address *"
                  value={values.email}
                  error={errors.email && touched.email}
                  helpertext={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="add-driver-modal-email-input"
                  data-testid="add-driver-modal-email-input"
                  name="email"
                  variant="filled"
                  disabled={!!editDriver}
                />

                <TextInfo
                  className={className}
                  label="Phone Number *"
                  value={values.phone}
                  error={errors.phone && touched.phone}
                  helpertext={touched.phone && errors.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="add-driver-modal-phone"
                  data-testid="add-driver-modal-phone"
                  name="phone"
                  variant="filled"
                  disabled={!!editDriver}
                />

                <Dropdown
                  className={`${className} half`}
                  disabled={_.isEmpty(editDriver)}
                  label="Status"
                  name="driverStatus.id"
                  labelProps={{ className }}
                  menuProps={{ className }}
                  options={shapedStatusOptions}
                  value={values ? values?.driverStatus?.id : '201'}
                  required
                  id="add-driver-modal-status"
                  data-testid="add-driver-modal-status"
                  onChange={handleChange}
                />
              </div>
            </div>
          </Form>
        </Styled>
      </StyledModal>
    </Fragment>
  );
};
