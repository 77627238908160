import React from 'react';
import { PageContent } from 'src/components/shared/PageContent';
import { DriversList } from './DriversList';
import { Styled } from './styles';

export const DriversView: React.FC = () => {
  return (
    <PageContent>
      <Styled>
        <DriversList />
      </Styled>
    </PageContent>
  );
};
