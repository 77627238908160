/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Transforms the Formik driver values into Falcon format
 */
export const transformDriverValues = (values: any) => {
  const driverInput = {
    altId: _.get(values, 'altId'),
    email: _.get(values, 'email'),
    firstName: _.get(values, 'firstName'),
    lastName: _.get(values, 'lastName'),
    phone: _.get(values, 'phone'),
    notificationPreferences: values?.notificationPreferences.map((pref: any) => ({
      driverId: _.get(pref, 'driverId'),
      notificationItemId: _.get(pref, 'notificationItemId'),
      emailOn: _.get(pref, 'emailOn'),
      pushOn: _.get(pref, 'pushOn'),
      smsOn: _.get(pref, 'smsOn'),
    })),
  };

  return driverInput;
};
