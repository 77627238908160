import { Box, Typography } from '@material-ui/core';
import { useStoryblok, renderRichText, RichTextSchema } from '@storyblok/react';
import { useParams } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';
import { events } from 'src/lib/utils/analytics-events';
import { theme } from '@evgo/react-material-components';
import { formatDate } from 'src/lib/helpers/formatDate';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { PageContent } from 'src/components/shared/PageContent';
import { Card } from 'src/components/shared/Card';
import { Video } from './Components/Video';
import { extendStoryPath, version } from 'src/storyblok';
import { Fragment } from 'react';
import css from './storyblok-article-style';

const { spacing } = theme;

const testid = (value: string) => ({ 'data-testid': `help-article-${value}` });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customSchema: any = {
  ...RichTextSchema,
  nodes: {
    ...RichTextSchema.nodes,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paragraph: function (node: any) {
      if (!node.content) {
        node.content = [{ type: 'hard_break' }];
      }
      return {
        tag: 'p',
      };
    },
  },
};

// @ts-ignore: material theme typography doesn't completely match html tags
// those that don't match will be ignored, so that's ok
const RichTextDiv = styled('div')({
  ...css,
  hr: {
    border: '0',
    borderTop: '1px solid #dfe3e8',
    boxSizing: 'content-box',
    height: '0',
    margin: '15px 0',
  },
  img: {
    maxWidth: '100%',
    padding: `${spacing(1)}px`,
  },
  video: {
    maxWidth: '100%',
    padding: `${spacing(1)}px`,
  },
  a: {
    textDecoration: `revert`,
    color: `revert`,
  },
});

const TitleBarDiv = styled('div')({
  paddingBottom: `${spacing(6)}px`,
});

// @ts-ignore: material theme typography doesn't completely match html tags
const ArticleWrapper = styled('div')({
  ...css,
  maxWidth: '800px',
  paddingTop: `${spacing(10)}px`,
});

const StyledCard = styled(Card)({
  display: 'flex',
  justifyContent: 'center',
});

export function HelpArticleView() {
  const { slug } = useParams();
  const { track } = useAnalytics();
  const url = `${extendStoryPath}articles/${slug}`;
  const story = useStoryblok(url, { version });
  const html = renderRichText(story?.content?.body, {
    schema: customSchema,
    resolver: (component, blok) => {
      switch (component) {
        case 'video':
          return renderToStaticMarkup(<Video {...testid('video')} link={blok?.video?.filename} />);
        default:
          return `Component ${component} not found`;
      }
    },
  });

  if (!story.content || !html) return <Fragment />;
  return (
    <PageContent
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbLink
            {...testid('help-link')}
            onClick={() => {
              track(events.helpAndSupport.CLICKED_HELP_SUPPORT_BREADCRUMB);
            }}
            to="/extend-plus/help"
          >
            Help & Support
          </BreadcrumbLink>
          <BreadcrumbText>{story.content?.title}</BreadcrumbText>
        </Breadcrumbs>
      }
    >
      <Box>
        <StyledCard>
          <ArticleWrapper>
            <TitleBarDiv>
              <Typography {...testid('title')} variant="h4">
                {story.content?.title}
              </Typography>
              <div className="ProseMirror">
                <Typography {...testid('subtitle')} variant="body1" style={{ color: theme.palette.grey[600] }}>
                  {story.content?.subtitle}
                </Typography>
                <Typography
                  {...testid('updated-at')}
                  variant="subtitle1"
                  style={{ color: theme.palette.grey[600], fontSize: '.75rem' }}
                >
                  updated {formatDate(story.published_at)}
                </Typography>
              </div>
            </TitleBarDiv>
            <RichTextDiv
              className="article-body ProseMirror"
              {...testid('html-content')}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </ArticleWrapper>
        </StyledCard>
      </Box>
    </PageContent>
  );
}
