/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';

const { palette, spacing } = theme;

interface Props {
  isLargeScreen: boolean;
}

export const Styled = styled.article<Props>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${`0 ${spacing(6)}px ${spacing(12)}px`};
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) ${spacing(12)}px ${spacing(6)}px`};

  .header-section {
    position: absolute;
    width: ${(props) => (props.isLargeScreen ? 'calc(100% - 200px)' : '100%')};
    right: 0;
    z-index: 10;
  }

  .content-section {
    position: relative;
    top: ${spacing(22)}px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > section {
      margin: 32px 16px 0px;
      width: calc(100% - ${spacing(4)}px);
    }

    > section:last-of-type {
      margin-bottom: ${spacing(10)}px;
    }
  }

  .snackbar {
    position: fixed;
    z-index: 2000;
    left: calc(50% - ${spacing(8)}px);
    top: ${`${spacing(3)}%`};
    align-self: center;

    span {
      display: flex;
      align-items: center;
      font-weight: 200;

      svg {
        margin-right: ${spacing()}px;
      }
    }

    button {
      /* TODO: use theme colors */
      color: #ffffff;
    }

    &.error {
      /* TODO: use theme colors */
      background-color: #cb2e29;
    }

    &.success {
      /* TODO: use theme colors */
      background-color: #43a047;
    }
  }

  .app-bar {
    position: relative;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    align-items: center;
    /* TODO: use theme colors */
    color: #000000;
    /* TODO: use theme colors */
    background-color: #ffffff;
    z-index: 1;

    div {
      margin: 0;
      width: auto;
    }

    button {
      /* TODO: use theme colors */
      border: 1px solid #daf0fe;
      &[aria-selected='true'] {
        background-color: ${palette.primary[50]};
        transition: all 0.2s ease;
        border-bottom: none;
      }
    }
  }

  .hidden {
    display: none;
  }

  section {
    &.action-section {
      position: absolute;
      z-index: 1000;
      width: 40px;
      overflow: hidden;
      top: ${spacing(26)}px;
      left: 48px;
      white-space: nowrap;
      margin: ${`0 calc(${spacing(-6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
        -6,
      )}px + var(--safe-area-inset-left))`};
      transition: all 0.5s ease;
      border-radius: 0px 4px 4px 0px;

      &.open {
        width: 250px;
      }
    }
  }

  section,
  div {
    box-sizing: border-box;
  }

  .connectors {
    box-sizing: border-box;
    width: calc(100% - ${spacing() * 4}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};

    header {
      h2 {
        color: ${config.colors.accentBlue};
      }

      span {
        color: ${palette.text.secondary};
      }

      div {
        display: flex;
      }
    }
  }

  .action-section {
    width: calc(100% + ${spacing(12)}px);
    margin: 0 ${spacing(-6)}px;
    margin: ${`0 calc(${spacing(-6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
      -6,
    )}px + var(--safe-area-inset-left))`};
  }
`;
