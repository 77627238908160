/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';
import { getFullAddress } from '..';

/**
 * Transforms the Falcon driver response into Formik format
 */
export const transformDriverResponse = (driverData: any) => {
  try {
    const values = {
      id: _.get(driverData, 'id', ''),
      altId: _.get(driverData, 'altId', ''),
      email: _.get(driverData, 'email', ''),
      firstName: _.get(driverData, 'firstName', ''),
      lastName: _.get(driverData, 'lastName', ''),
      phone: _.get(driverData, 'phone', ''),
      createdAt: _.get(driverData, 'createdAt'),
      deletedAt: _.get(driverData, 'deletedAt'),
      updatedAt: _.get(driverData, 'updatedAt'),
      account: {
        id: _.get(driverData, 'account.id', ''),
        altId: _.get(driverData, 'account.altId', ''),
        accountStatus: _.get(driverData, 'account.accountStatus', ''),
        accountType: _.get(driverData, 'account.accountType', ''),
        contracts: {
          edges: (driverData?.contracts?.edges || []).map((edge: any) => ({
            altId: _.get(edge, 'altId'),
            closureDate: _.get(edge, 'closureDate'),
            endDate: _.get(edge, 'endDate'),
            startDate: _.get(edge, 'startDate'),
            plan: {
              altId: _.get(edge, 'plan.altId'),
              planName: _.get(edge, 'plan.planName'),
            },
            status: {
              columnText: _.get(edge, 'status.columnText'),
              id: _.get(edge, 'status.id'),
            },
          })),
          total: _.get(driverData, 'contracts.total', 0),
        },
        fullAddress: getFullAddress({
          streetAddress: _.get(driverData, 'account.mailingAddress.address1'),
          unit: _.get(driverData, 'account.mailingAddress.address2'),
          city: _.get(driverData, 'account.mailingAddress.locality'),
          state: _.get(driverData, 'account.mailingAddress.administrativeArea'),
          postalCode: _.get(driverData, 'account.mailingAddress.postalCode'),
          country: _.get(driverData, 'account.mailingAddress.country'),
        }),
        mailingAddress: {
          address1: _.get(driverData, 'account.mailingAddress.address1'),
          address2: _.get(driverData, 'account.mailingAddress.address2'),
          locality: _.get(driverData, 'account.mailingAddress.locality'),
          administrativeArea: _.get(driverData, 'account.mailingAddress.administrativeArea'),
          postalCode: _.get(driverData, 'account.mailingAddress.postalCode'),
        },
      },
      accountVehicles: {
        edges: (driverData?.accountVehicles?.edges || []).map((edge: any) => ({
          altId: _.get(edge, ''),
          autochargeStatus: _.get(edge, 'autochargeStatus'),
          deletedAt: _.get(edge, 'deletedAt'),
          macAddress: _.get(edge, 'macAddress'),
          nickName: _.get(edge, 'nickName'),
          vin: _.get(edge, 'vin'),
          vehicle: {
            altId: _.get(edge, 'vehicle.altId'),
            makeModelYear: _.get(edge, 'vehicle.makeModelYear'),
            vehicleConnectors: (edge?.vehicle?.vehicleConnectors || []).map((connector: any) => ({
              connectorName: _.get(connector, 'connectorName'),
              id: _.get(connector, 'id'),
            })),
          },
        })),
        total: _.get(driverData, 'accountVehicles.total', 0),
      },
      cards: {
        edges: (driverData?.cards?.edges || []).map((edge: any) => ({
          altId: _.get(edge, 'altId'),
          brand: _.get(edge, 'brand'),
          cardStatus: _.get(edge, 'cardStatus'),
          driver: {
            altId: _.get(edge, 'driver.altId'),
            firstName: _.get(edge, 'driver.firstName'),
            lastName: _.get(edge, 'driver.lastName'),
          },
          externalNumber: _.get(edge, 'externalNumber'),
          internalNumber: _.get(edge, 'internalNumber'),
        })),
        total: _.get(driverData, 'cards.total'),
      },
      driverStatus: {
        columnText: _.get(driverData, 'driverStatus.columnText'),
        id: _.get(driverData, 'driverStatus.id'),
      },
      notificationPreferences: (driverData?.notificationPreferences?.edges || []).map(
        ({ __typename, ...rest }: { __typename: any }) => rest,
      ),
    };

    return values;
  } catch (e) {
    throw e;
  }
};
