import React from 'react';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { Box, Divider } from '@material-ui/core';
import { BoxItem } from 'src/components/shared/BoxItem';
import { CardHeader } from 'src/components/shared/Card';

export type Props = {
  identifier?: string;
  serialNumber?: string;
  model?: string;
  firmwareVersion?: string;
  breakerRating?: number;
  powerSharingCapable?: boolean;
  'data-testid'?: string;
};

const StyledBox = styled(Box)`
  width: 100%;

  h6 {
    font-size: ${theme.spacing(3)}px;
    padding-bottom: ${theme.spacing(2)}px;
  }

  div {
    min-width: 110px;
    hr {
      height: auto;
    }
  }
`;

export const ChargerSpecifications: React.FC<Props> = ({
  identifier = '',
  serialNumber = '',
  model = '',
  firmwareVersion = '',
  breakerRating = '',
  powerSharingCapable = '',
  ...props
}) => {
  return (
    <StyledBox data-testid={props['data-testid']}>
      <CardHeader title="Charger Specifications" />

      <Divider />

      <Box display="flex">
        <BoxItem title="Charger Identifier" value={identifier} />
        <Divider orientation="vertical" />
        <BoxItem title="Serial Number" value={serialNumber} />
        <Divider orientation="vertical" />
        <BoxItem title="Model" value={model} />
      </Box>

      <Divider />

      <Box display="flex">
        <BoxItem title="Firmware Version" value={firmwareVersion} />
        <Divider orientation="vertical" />
        <BoxItem title="Breaker Rating" value={`${breakerRating}`} />
        <Divider orientation="vertical" />
        <BoxItem title="Power Sharing Capable" value={powerSharingCapable ? 'Yes' : 'No'} />
      </Box>
    </StyledBox>
  );
};
