import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    margin: ${`${spacing(5)}px ${spacing(8)}px ${spacing(12)}px`};
  }
`;
