/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, text, typography, spacing } = theme;
const { colors } = config;

export const Styled = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  height: ${spacing(17.5)}px;
  box-sizing: border-box;
  z-index: 10;
  background-color: ${palette.primary[50]};
  padding: 0 ${spacing(8)}px;

  div {
    display: flex;
    flex: 1;
    align-content: space-between;
    flex-direction: column;

    section {
      display: flex;
      flex: 1 1 auto;

      &.back {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        a {
          text-transform: none;
          font-weight: 200;
          font-size: ${typography.fontSize - 2}px;
          color: ${text.labelEmphasis.medium};
        }

        svg {
          margin-right: ${spacing()}px;
          height: ${spacing(1.5)}px;
          width: ${spacing(1.5)}px;
          color: ${palette.secondary[700]};
        }
      }

      &.title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: ${spacing(1.5)}px;

        svg {
          margin-right: ${spacing(1.25)}px;
          height: ${spacing(2.75)}px;
          width: ${spacing(2.75)}px;
          color: ${palette.secondary[700]};
        }

        h5 {
          font-weight: 200;
          font-size: ${typography.fontSize + 8}px;
        }
      }

      &.info {
        display: flex;
        flex-direction: row;
        font-weight: 200;
        padding-top: ${spacing(1.5)}px;

        article {
          display: flex;
          flex-direction: column;

          a {
            color: ${colors.accentBlue};
            text-decoration: underline;
          }

          label {
            font-size: ${typography.fontSize - 2}px;
            color: ${text.labelEmphasis.medium};
          }

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: ${spacing(1.25)}px;

            p {
              flex: 1 1 auto;
              font-size: ${typography.fontSize}px;
              font-weight: ${typography.fontWeightRegular};
              color: ${text.surfaceEmphasis.medium};
              margin-right: ${spacing()}px;
            }
            button {
              padding: ${spacing()}px;
            }

            svg {
              height: ${spacing(1.75)}px;
              width: ${spacing(2)}px;
              color: #666666;
            }
          }
        }
      }
    }
  }
`;
