import React from 'react';
import { Styled } from './styles';

export interface Props {
  error: string;
}

export const ErrorView: React.FC<Props> = ({ error }) => {
  const className = 'ErrorView';

  return (
    <Styled className={className}>
      <h1>Error</h1>
      <p className={className}>{error}</p>
      <br />
    </Styled>
  );
};
