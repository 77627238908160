import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from '@evgo/react-material-components';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

const { palette, typography } = theme;

const ChargerConnectorLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${palette.primary[500]};

  font-size: ${typography.fontSize + 2}px;
  font-weight: bold;
  text-decoration: underline;
`;

type ChargerConnectorProps = {
  chargerId: string;
  chargerName: string;
};

export function ChargerConnector({ chargerId, chargerName }: ChargerConnectorProps) {
  const { track } = useAnalytics();
  return (
    <ChargerConnectorLink
      to={`/extend-plus/chargers/${chargerId}`}
      data-testid={`charger-link-${chargerId}`}
      onClick={() => track(events.siteDetails.CLICKED_CHARGERS_LIST_CONNECTOR_NAME)}
    >
      {chargerName}
    </ChargerConnectorLink>
  );
}
