/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { createChargerModel, listVehicleConnectors, updateChargerModel } from '../../../../apollo/queries/models';
import { transformChargerModelResponse, transformChargerModelValues } from '../../../../lib/helpers';
import { useFormikSubmit } from '../../../../lib/hooks';
import { ChargerModelForm } from './ChargerModelForm';
import { initialValues } from './ChargerModelForm/initialValues';
import { validations as validationSchema } from './ChargerModelForm/validations';

/**
 * Charger Model view component
 */
export const ChargerModelView: React.FC = () => {
  const className = 'ChargerModelView';
  const { data: connectorTypeOptions, loading: connectorTypeOptionsLoading } = useQuery<Query>(listVehicleConnectors);
  const { altId } = useParams<{ altId: string }>();

  let shapedConnectorTypeOptions = [] as LabelValue<number>[];
  if (!connectorTypeOptionsLoading) {
    shapedConnectorTypeOptions = (connectorTypeOptions?.listVehicleConnectors?.edges || []).map((c) => ({
      label: (c as any)?.connectorName,
      value: Number(c?.id),
    }));
  }
  const transformValues = (values: { evses: any; breakerRating: any; powerFactor: any; powerOutput: any }) => {
    return transformChargerModelValues(values, altId || '');
  };
  const transformResponse = (data: any) => {
    return transformChargerModelResponse(data, shapedConnectorTypeOptions);
  };

  const handleSubmit = useFormikSubmit('Model', createChargerModel, updateChargerModel, {
    altId,
    transformValues,
    transformResponse,
  }) as never;

  return (
    <article className={className}>
      <Formik
        initialStatus={initialValues}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <ChargerModelForm
          shapedConnectorTypeOptions={shapedConnectorTypeOptions}
          connectorTypeOptionsLoading={connectorTypeOptionsLoading}
        />
      </Formik>
    </article>
  );
};
