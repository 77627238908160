import React, { createContext, useContext, useState } from 'react';

export type SnackbarType = 'success' | 'error' | 'warning' | 'info' | 'system';

interface SnackbarState {
  snackMessage: string;
  snackVisible: boolean;
  snackbarType: SnackbarType;
  snackbarButtonText?: string;
}

export const SnackbarContext = createContext<
  | {
      snackbarState: SnackbarState;
      setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>;
    }
  | undefined
>(undefined);

export const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    snackMessage: '',
    snackVisible: false,
    snackbarType: 'info',
  });

  return <SnackbarContext.Provider value={{ snackbarState, setSnackbarState }}>{children}</SnackbarContext.Provider>;
};

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbarContext must be used within a SnackbarProvider');
  }
  return context;
};
