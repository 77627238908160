import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  box-sizing: border-box;
  width: calc(100% - ${spacing() * 4}px);

  header {
    padding: ${`0 ${spacing(3)}px`};
  }

  &.charger-hardware-notes {
    margin: 32px 16px 0 !important;
  }

  h2 {
    color: ${colors.accentBlue};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: ${spacing()}px;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: 0 ${`${spacing(2)}`}px;

        .form-control,
        .select {
          width: calc(100% - ${spacing(2)}px);

          &.half {
            width: calc(50% - ${spacing(2)}px);
            &.demo {
              margin-top: 16px;
              &.model {
                margin-left: 8px;
              }
            }
          }

          &.thirds {
            width: calc(33.33% - ${spacing(2)}px);
          }

          &.sixths {
            width: calc(16.67% - ${spacing()}px);
          }

          &.fill {
            flex: 1;
          }
        }

        .select {
          padding: 0;
        }
      }
    }
  }
`;
