import { gql } from '@apollo/client';
import { FullChargerModel } from './FullChargerModel';

export const getChargerModel = gql`
  query getChargerModel($chargerModelInput: GetChargerModelInput) {
    getChargerModel(input: $chargerModelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModel}
`;
