import React, { Fragment } from 'react';
import { AccountStatusModal } from '../../views/AccountView/AccountStatusModal';
import { AccountCardModal } from '../../views/AccountView/AccountCardModal';
import { AccountSyncModal } from '../../views/AccountView/AccountSyncModal';
import { AccountContractModal } from '../../views/AccountView/AccountContractModal';
import { AccountVehicleModal } from '../../views/AccountView/AccountVehicleModal';
import { AddCardsModal } from '../../views/AuthenticatorsView/AddCardsModal';
import { AssignCardsModal } from '../../views/AuthenticatorsView/AssignCardsModal';
import { ConfirmCardsModal } from '../../views/AuthenticatorsView/ConfirmCardsModal';
import { ImportCardsModal } from '../../views/AuthenticatorsView/ImportCardsModal';
import { StartChargeModal } from '../../views/ChargerView/ChargerForm/StartChargeModal';
import { CreateHostModal } from '../../views/HostsAndSitesView/CreateHostModal';
import { useModalContext } from 'src/contexts/ModalContext';

export const Modals: React.FC = () => {
  const { modalName, modalProps, modalVisible } = useModalContext();
  const createHostModalOpen = !!modalVisible && modalName === 'CreateHostModal';
  const startChargeModalOpen = !!modalVisible && modalName === 'StartChargeModal';
  const importCardsModalOpen = !!modalVisible && modalName === 'ImportCardsModal';
  const assignCardsModalOpen = !!modalVisible && modalName === 'AssignCardsModal';
  const confirmCardsModalOpen = !!modalVisible && modalName === 'ConfirmCardsModal';
  const addCardsModalOpen = !!modalVisible && modalName === 'AddCardsModal';
  const accountCardModalOpen = !!modalVisible && modalName === 'AccountCardModal';
  const accountContractModalOpen = !!modalVisible && modalName === 'AccountContractModal';
  const accountVehicleModalOpen = !!modalVisible && modalName === 'AccountVehicleModal';
  const accountSyncModalOpen = !!modalVisible && modalName === 'AccountSyncModal';
  const accountStatusModalOpen = !!modalVisible && modalName === 'AccountStatusModal';

  return (
    <Fragment>
      <CreateHostModal {...(modalProps || {})} open={createHostModalOpen} />
      <StartChargeModal open={startChargeModalOpen} />
      <ImportCardsModal open={importCardsModalOpen} />
      <AssignCardsModal open={assignCardsModalOpen} />
      <ConfirmCardsModal open={confirmCardsModalOpen} />
      <AddCardsModal open={addCardsModalOpen} />
      <AccountCardModal open={accountCardModalOpen} />
      {/* only render modal when needed to avoid unecessary queries/overhead
      TODO: do this for the rest of these when we are sure they don't need to be pre mounted */}
      {accountContractModalOpen && <AccountContractModal open={accountContractModalOpen} />}{' '}
      <AccountVehicleModal open={accountVehicleModalOpen} />
      <AccountSyncModal open={accountSyncModalOpen} />
      <AccountStatusModal modalProps={{ ...modalProps }} open={accountStatusModalOpen} />
    </Fragment>
  );
};
