import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const {
  spacing,
  typography: { fontSize },
} = theme;

export const Styled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > h3 {
    font-size: ${fontSize}px;
  }

  > span {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: ${spacing() / 2}px;
    padding: ${`${fontSize * (6 / 7 / 2)}px 0`};
    line-height: ${fontSize * (6 / 7)}px;

    &.center {
      justify-content: center;
      text-align: center;
    }

    &.column {
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
    }
  }

  &:last-child {
    flex: 0 1 auto;
  }
`;
