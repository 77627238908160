import { TextInfo } from '@evgo/react-material-components';
import _ from 'lodash';
import React from 'react';
import { Connector } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { Styled } from './styles';

export interface Props {
  cableManufacturers: LabelValue<string>[];
  cableLength: LabelValue<string>[];
  cableCooling: LabelValue<string>[];
  connector: Connector;
  index: number;
  className?: string;
}

export const ConnectorRow: React.FC<Props> = (props) => {
  const id = _.kebabCase('ConnectorRow');
  const className = id;
  const { cableManufacturers, cableLength, cableCooling, connector, index } = props;

  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  return (
    <Styled className={`${customClass} panel-details`}>
      <div className={className}>
        <div className={`${className} row`}>
          <TextInfo
            id={`${id}-connector-name-${index}`}
            disabled={true}
            className={`${className} column`}
            label="Connector type"
            value={_.get(connector, 'connectorType.connectorName', '')}
          />
          <TextInfo
            id={`${id}-cable-manufacturer-${index}`}
            disabled={true}
            className={`${className} column`}
            label="Cable Manufacturer"
            value={
              connector?.cableManufacturer
                ? _.find(cableManufacturers, { value: connector?.cableManufacturer })?.label
                : ''
            }
          />
          <TextInfo
            id={`${id}-cable-length-${index}`}
            disabled={true}
            className={`${className} column`}
            label="Cable length"
            value={connector?.cableLength ? _.find(cableLength, { value: connector?.cableLength })?.label : ''}
          />
          <TextInfo
            id={`${id}-connector-max-current-${index}`}
            disabled={true}
            className={`${className} column`}
            label="Connector Max. current"
            adornmentEnd="A"
            value={_.get(connector, 'connectorMaxCurrent', '')}
          />
          <TextInfo
            id={`${id}-output-voltage-range-${index}`}
            disabled={true}
            className={`${className} column`}
            label="Output Voltage Range"
            endAdornment="V"
            value={_.get(connector, 'outputVoltageRange', '')}
          />
          <TextInfo
            id={`${id}-cable-cooling-${index}`}
            disabled={true}
            className={`${className} column`}
            label="Cable cooling"
            value={connector?.cableCooling ? _.find(cableCooling, { value: connector?.cableCooling })?.label : ''}
          />
        </div>
      </div>
    </Styled>
  );
};
