import { useQuery } from '@apollo/client';
import { AppBar, Box, CircularProgress, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core';
import _ from 'lodash';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getAccount } from '../../../apollo/queries/accounts';
import { AccountActions } from './AccountActions';
import { AccountCards } from './AccountCards';
import { AccountChargeLog } from './AccountChargeLog';
import { AccountDriverModal } from './AccountDriverModal';
import { AccountDrivers } from './AccountDrivers';
import { AccountEditHeader } from './AccountEditHeader';
import { AccountProfile } from './AccountProfile';
import { AccountStatus } from './AccountStatus';
import { AccountVehicleModal } from './AccountVehicleModal';
import { AccountVehicles } from './AccountVehicles';
import { AccountTransactions } from './AccountTransactions';
import { useNavigate } from 'react-router-dom';

import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

interface TabPanelProps extends React.PropsWithChildren<unknown> {
  value: number;
  index: number;
  className?: string;
}

/**
 * Toggles panel in edit view
 */
export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...rest } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={index === 0}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...rest}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </Typography>
  );
};

export const AccountView: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const isLargeScreen = useMediaQuery('(min-width:1400px)');
  const { modalName, modalProps, modalVisible } = useModalContext();

  const id = _.kebabCase('AccountView');
  const className = id;

  // Wrap the initialization of 'tabs' in its own useMemo() Hook
  const tabs = useMemo(() => {
    // Define your tabs array here
    return ['', 'transactions', 'charge-log', 'manage'];
  }, []); // Add any dependencies if needed

  const [activeTab, setActiveTab] = useState(params.section ? tabs.indexOf(params.section) : 0);
  const [startCharge, setStartCharge] = useState(false);

  const handleTabClick = (newValue: number) => {
    setActiveTab(newValue);
    navigate(`/accounts/${params.altId}/${tabs[newValue]}`);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setActiveTab(params.section ? tabs.indexOf(params.section) : 0);
  }, [params, tabs]);

  const { data, loading, fetchMore } = useQuery(getAccount, {
    fetchPolicy: 'network-only',
    variables: {
      accountInput: { altId: params.altId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999 },
      cardsInput: { pageSize: 999 },
      preferencesInput: { pageSize: 999 },
    },
    skip: !params.altId,
  });
  if (loading)
    return (
      <Box m="auto">
        <CircularProgress />
      </Box>
    );

  const { ...account } = data?.getAccount ? data.getAccount : {};

  const tabPanels = [
    <TabPanel value={activeTab} index={0} className={`${className} content-section`}>
      {!!params.altId ? <AccountStatus className={className} selected={account} /> : null}
      <AccountProfile className={className} account={account} loading={loading} fetchMore={fetchMore} />
    </TabPanel>,
    <TabPanel value={activeTab} index={1} className={`${className} content-section`}>
      <AccountTransactions account={account} />
    </TabPanel>,
    <TabPanel value={activeTab} index={2} className={`${className} content-section`}>
      <AccountChargeLog account={account} />
    </TabPanel>,
    <TabPanel value={activeTab} index={3} className={`${className} content-section`}>
      <AccountCards className={className} />
      <AccountDrivers className={className} />
      <AccountVehicles className={className} />
    </TabPanel>,
    <TabPanel value={activeTab} index={4} className={`${className} content-section`}></TabPanel>,
    <TabPanel value={activeTab} index={5} className={`${className} content-section`}></TabPanel>,
  ];

  return (
    <Styled className={className} isLargeScreen={isLargeScreen}>
      <div className={`${className} header-section`}>
        <AccountEditHeader className={className} account={account} />

        {params.altId && (
          <AppBar className={`${className} app-bar`}>
            <Tabs className={className} value={activeTab} onChange={(event, value) => handleTabClick(value)}>
              <Tab className={className} id={`${id}-profile-tab`} label="Profile" />
              <Tab className={className} id={`${id}-transactions-tab`} label="Transactions" />
              <Tab className={className} id={`${id}-charge-log-tab`} label="Charge Log" />
              <Tab className={className} id={`${id}-manage-tab`} data-testid={`${id}-manage-tab`} label="Manage" />
              <Tab className={className} id={`${id}-issues-tab`} disabled label="Issues" />
              <Tab className={className} id={`${id}-activity-log-tab`} disabled label="Activity Log" />
            </Tabs>
          </AppBar>
        )}

        {params.altId && (
          <AccountActions
            startCharge={startCharge}
            setStartCharge={setStartCharge}
            selected={account}
            fetchMore={fetchMore}
            className={className}
          />
        )}
      </div>
      {tabPanels}
      <AccountDriverModal
        className={className}
        {...modalProps}
        open={modalName === 'AccountDriverModal' && modalVisible}
      />
      <AccountVehicleModal
        className={className}
        {...modalProps}
        open={modalName === 'AccountVehicleModal' && modalVisible}
      />
    </Styled>
  );
};
