import { gql } from '@apollo/client';

export const createHost = gql`
  mutation createHost($input: CreateHostInput) {
    createHost(input: $input) {
      hid
      altId
      address1
      locality
      administrativeArea
      hostName
      postalCode
      status
    }
  }
`;
