import React, { Fragment } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { PlanDetails } from '../PlanDetails';
import { PlanElements } from '../PlanElements';
import { PlanProducts } from '../PlanProducts';

/**
 * Plan details tab component
 */
export const PlanDetailsTab: React.FC = () => {
  const { altId, url } = useParams<{ altId: string; url: string }>();

  if (url === '/plans/new' && altId) {
    return <Navigate to={`/plans/${altId}/details`} />;
  }

  return (
    <Fragment>
      <PlanDetails />
      <PlanElements />
      <PlanProducts />
    </Fragment>
  );
};
