import { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { buildContextPath, getParentElements, path } from './methods';

const TRAVERSAL_ATTEMPTS = 50;

export const useDataContext = () => {
  const contextRef = useRef(null);
  const [contextPath, setContextPath] = useState('');

  const setData = (id: string) => ({ [path.attr]: `${contextPath}-${_.camelCase(id)}` });
  const currentRef = contextRef.current;

  useEffect(() => {
    (async () => {
      let remainingAttempts = TRAVERSAL_ATTEMPTS;
      const checkInterval = setInterval(() => {
        if (remainingAttempts <= 0) {
          console.warn(
            'Failed to retrieve Data Context for the following element:',
            contextRef.current,
            'It may exist as a dialog or some other element that resides at the top level of the DOM.',
          );
          window.clearInterval(checkInterval);
        }
        const newContextPath = contextRef.current
          ? buildContextPath(getParentElements(contextRef.current).reverse())
          : null;
        if (newContextPath) {
          setContextPath(newContextPath);
          window.clearInterval(checkInterval);
        } else {
          remainingAttempts--;
        }
      }, 100);
    })();
  }, [currentRef]);

  return [contextRef, setData] as [React.MutableRefObject<null>, (id: string) => { [key: typeof path.attr]: string }];
};
