import { Box, Grid } from '@material-ui/core';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { useParams } from 'react-router-dom';
import { PageContent } from 'src/components/shared/PageContent';
import { Card } from 'src/components/shared/Card';
import { Article, ArticleList, ArticlesByCategory, Category } from '../';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { getStoryblokApi, useStoryblok } from '@storyblok/react';
import { extendArticlesCdn, extendStoryPath, version } from 'src/storyblok';
import { Fragment, useEffect, useState } from 'react';
import { groupArticlesByCategory } from '../helpers';

const testid = (value: string) => ({ 'data-testid': `help-category-${value}` });

export function HelpCategoryView() {
  const { slug } = useParams();
  const { track } = useAnalytics();
  const url = `${extendStoryPath}categories/${slug}`;
  const category = useStoryblok(url, { version }) as Category;
  const [categoryData, setCategoryData] = useState({ title: '', articles: {} as ArticlesByCategory });
  useEffect(() => {
    const getArticlesByCategory = async () => {
      const storyblokApi = getStoryblokApi();
      try {
        const { data: articles }: { data: { stories: Article[] } } = await storyblokApi.get(extendArticlesCdn, {
          version,
        });
        const data = groupArticlesByCategory({ stories: [category] }, articles);
        setCategoryData({ title: Object.keys(data)[0], articles: data });
      } catch (e) {
        console.error(e);
      }
    };
    getArticlesByCategory();
  }, [category]);
  const { title, articles } = categoryData;
  if (!title && !Object.keys(articles).length) return <Fragment />;
  return (
    <PageContent
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbLink
            {...testid('help-link')}
            onClick={() => {
              track(events.helpAndSupport.CLICKED_HELP_SUPPORT_BREADCRUMB);
            }}
            to="/extend-plus/help"
          >
            Help & Support
          </BreadcrumbLink>
          <BreadcrumbText>{title}</BreadcrumbText>
        </Breadcrumbs>
      }
    >
      <Box>
        <Card>
          <Grid container {...testid('category-article-list')} style={{ columnGap: '120px', display: 'block' }}>
            <Grid item xs={12} style={{ flexBasis: 'calc(50% - 60px)' }}>
              <ArticleList title={title} articles={articles[title]} />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </PageContent>
  );
}
