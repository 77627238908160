import { gql } from '@apollo/client';
import { FullSite } from './FullSite';

export const getSite = gql`
  query getSite($siteInput: GetSiteInput) {
    getSite(input: $siteInput) {
      ...FullSite
    }
  }
  ${FullSite}
`;
