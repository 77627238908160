import moment from 'moment-timezone';
import { Maybe, GetSessionsConsumptionForExtendResponse } from 'src/@types';

export type HeatMapChartData = {
  id: string;
  data: {
    x: string;
    y: number;
  }[];
};

export const weekDays = moment.weekdaysShort();
weekDays.push(weekDays.shift() || '');

export const getChartData = (data: Maybe<GetSessionsConsumptionForExtendResponse> | undefined): HeatMapChartData[] => {
  const timeSeries = data?.timeSeries || [];
  const heatMapChartData: HeatMapChartData[] = weekDays.map((weekDay) => ({
    id: weekDay,
    data: [],
  }));

  timeSeries.forEach((value) => {
    const weekDay = moment(value?.timestamp).utc().format('ddd');
    const hour = moment(value?.timestamp).utc().format('hh A');

    const indexWeekDay = heatMapChartData.findIndex((weekDayData) => weekDayData.id === weekDay);
    const indexHour = heatMapChartData[indexWeekDay].data.findIndex((hourData) => hourData.x === hour);

    if (indexHour !== -1) {
      heatMapChartData[indexWeekDay].data[indexHour].y += value?.powerValue || 0;
    } else {
      heatMapChartData[indexWeekDay].data.push({
        x: hour,
        y: value?.powerValue || 0,
      });
    }
  });

  return heatMapChartData;
};
