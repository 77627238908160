import { Checkbox, Input, ListItemText, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { CheckBoxOutlineBlank, Face } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';
import React from 'react';
import { Styled } from './styles';

export interface Props {
  groups: string[];
  storeDriverOptions: (v: { groups: string[] }) => void;
}

/** Drivers Header component. */
export const DriversGroupsFilter: React.FC<Props> = (props) => {
  const clearFilters = () => {
    props.storeDriverOptions({ groups: [] });
  };

  /**
   * Returns a string value of selected groups.
   */
  const checkSelected = (selected: string[]) => {
    if (selected.length === 0) {
      return 'Drivers: All';
    }

    return `Drivers: ${selected.join(', ')}`;
  };

  const id = _.kebabCase('DriverGroupsFilter');
  const className = id;
  const groups = [{ marketName: 'Fleet' }, { marketName: 'Retail' }].map((market, i) => (
    <MenuItem key={market.marketName} value={market.marketName} disableRipple>
      <Checkbox
        checked={props.groups.indexOf(market.marketName) > -1}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        disableRipple
        icon={<CheckBoxOutlineBlank fontSize="small" />}
        inputProps={{ id: `${id}-${i}-market-checkbox` }}
      />
      <ListItemText primary={market.marketName} style={{ fontSize: 'small' }} />
    </MenuItem>
  ));

  const clearAll = props.groups.length > 0 && (
    <Typography className={`${className} clear-all`} variant="caption" color="secondary" onClick={clearFilters}>
      Clear
    </Typography>
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  };

  const selectedMarkets = props.groups;

  return (
    <Styled>
      <Paper className={`${className} menu`}>
        <Face color="primary" fontSize="small" />

        <Select
          className={`${className} select`}
          displayEmpty
          input={<Input id="driver-groups-multi-select" />}
          MenuProps={MenuProps}
          multiple
          onChange={({ target }) => {
            props.storeDriverOptions({ groups: target.value as string[] });
          }}
          renderValue={(selected) => checkSelected(selected as string[])}
          value={selectedMarkets}
          data-testid={`${id}-dropdown`}
        >
          <MenuItem className={className} disabled value="">
            All
          </MenuItem>

          {groups}
        </Select>
      </Paper>

      {clearAll}
    </Styled>
  );
};

export default DriversGroupsFilter;
