import { ChargingLevel } from 'src/@types';

export const ConnectorFactory = () => {
  return {
    total: 2,
    edges: [
      {
        altId: '4d088d84-6fdd-410a-a9f5-ce9c544b4ed9',
        chargingLevel: ChargingLevel.Dcfc,
        reservable: true,
      },
      {
        altId: '5a30d64d-6a6b-4a3b-9476-9630053e2168',
        chargingLevel: ChargingLevel.L2,
        reservable: false,
      },
    ],
  };
};
