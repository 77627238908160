import { Divider, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { initialValues } from '../../initialValues';
import { PlanDetailListSection } from './PlanDetailListSection';
import { PlanDetailSessionTimes } from './PlanDetailSessionTimes';
import { Styled as StyledAccordion } from './styles';

export type Props = {
  id?: string;
  className?: string;
};

const defaultListSection = { header: '', items: [] };

export const PlanDetail: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanDetail');
  const className = id;
  const { errors, isSubmitting, setFieldValue, handleBlur, handleChange, touched, values } =
    useFormikContext<typeof initialValues>();

  const handleAddSection = useCallback(() => {
    const value = [...values.detailSections, { ...defaultListSection }];

    setFieldValue('detailSections', value);
  }, [setFieldValue, values.detailSections]);

  return (
    <StyledAccordion id={parentId} className={clsx(className, parentClass)} defaultExpanded={true}>
      <AccordionSummary className={className} component="header" expandIcon={<ExpandMoreIcon />} id={`${id}-header`}>
        <Typography className={className} variant="h6" component="h2">
          Plan Detail
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={clsx(className, 'details')}>
        <div className={className}>
          <TextInfo
            className={clsx(className, 'full')}
            disabled={isSubmitting || !values.displayToCustomer}
            error={errors.pageHeader && touched.pageHeader}
            helpertext={touched.pageHeader && errors.pageHeader}
            id={`${id}-page-header-input`}
            label="Page Header *"
            labelProps={{ className }}
            name="pageHeader"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.pageHeader}
            data-testid={`${id}-page-header-input`}
          />

          <PlanDetailSessionTimes className={className} />

          <div className={className}>
            <Button
              className={className}
              color="secondary"
              disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
              id={`${id}-panel-header-add-button`}
              onClick={handleAddSection}
              variant="contained"
              data-testid={`${id}-panel-header-add-button`}
            >
              Add List Section
            </Button>
          </div>

          {values.detailSections.map((value, i) => (
            <PlanDetailListSection className={className} key={i} index={i} path={`detailSections[${i}]`} />
          ))}
        </div>

        <Divider className={className} />

        <div className={className}>
          <TextInfo
            className={clsx(className, 'full')}
            disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
            error={!!(_.get(touched, 'legalese') && _.get(errors, 'legalese'))}
            helpertext={_.get(touched, 'legalese') && _.get(errors, 'legalese')}
            id={`${id}-legalese-input`}
            label="Legalese"
            labelProps={{ className }}
            multiline
            rows="3"
            name="legalese"
            onBlur={handleBlur}
            onChange={handleChange}
            value={_.get(values, 'legalese')}
            data-testid={`${id}-legalese-input`}
          />
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
