import { gql } from '@apollo/client';

export const createAccountOwner = gql`
  mutation createAccountOwner($input: CreateAccountOwnerInput) {
    createAccountOwner(input: $input) {
      altId
      account {
        altId
      }
    }
  }
`;
