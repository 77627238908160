import { Divider, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  className?: string;
}

/**
 * Driver profile component
 */
export const DriverProfile: React.FC<Props> = ({ className: parentClass }) => {
  const id = _.kebabCase('DriverProfile');
  const className = id;
  const { errors, handleBlur, handleChange, touched, values } = useFormikContext();
  const { altId, url } = useParams<{ altId: string; url: string }>();

  const deletedAt = _.get(values, 'deletedAt', false);

  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;
  if (url === '/drivers/new' && altId) return <Navigate to={`/drivers/${altId}/profile`} />;

  return (
    <StyledAccordion className={`${customClass} driver-profile`} defaultExpanded={true} id={`${id}-expansion-panel`}>
      <AccordionSummary className={className} expandIcon={altId ? <ExpandMoreIcon /> : null} component="header">
        <Typography className={className} variant="h6" component="h2">
          Driver Profile
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <Typography className={className} variant="subtitle1" component="h3">
              General Information
            </Typography>

            <TextInfo
              id={`${id}-first-name`}
              error={_.get(errors, 'firstName') && _.get(touched, 'firstName')}
              helpertext={_.get(errors, 'firstName') && _.get(touched, 'firstName') && _.get(errors, 'firstName')}
              className={`${className} half ${deletedAt ? 'disabled' : ''}`}
              disabled={deletedAt}
              label="First Name*"
              labelProps={{ className }}
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'firstName', '')}
            />

            <TextInfo
              id={`${id}-last-name`}
              error={_.get(errors, 'lastName') && _.get(touched, 'lastName')}
              helpertext={_.get(errors, 'lastName') && _.get(touched, 'lastName') && _.get(errors, 'lastName')}
              className={`${className} half ${deletedAt ? 'disabled' : ''}`}
              disabled={deletedAt}
              label="Last Name*"
              labelProps={{ className }}
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'lastName', '')}
            />

            <TextInfo
              id={`${id}-email`}
              error={_.get(errors, 'email') && _.get(touched, 'email')}
              helpertext={_.get(errors, 'email') && _.get(touched, 'email') && _.get(errors, 'email')}
              className={`${className} ${deletedAt ? 'disabled' : ''}`}
              disabled={deletedAt}
              label="E-mail Address*"
              labelProps={{ className }}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'email', '')}
            />

            <TextInfo
              id={`${id}-phone`}
              error={_.get(errors, 'phone') && _.get(touched, 'phone')}
              helpertext={_.get(errors, 'phone') && _.get(touched, 'phone') && _.get(errors, 'phone')}
              className={`${className} ${deletedAt ? 'disabled' : ''}`}
              disabled={deletedAt}
              label="Phone Number*"
              labelProps={{ className }}
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              value={_.get(values, 'phone', '')}
            />
          </div>

          <Divider vertical />

          <div className={className}>
            <Typography className={className} variant="subtitle1" component="h3">
              Mailing Address
            </Typography>

            <TextInfo
              id={`${id}-mailing-address-street`}
              className={`${className} disabled`}
              label="Address Line 1*"
              labelProps={{ className }}
              value={_.get(values, 'account.mailingAddress.address1', '')}
              disabled
            />

            <TextInfo
              id={`${id}-mailing-address-2`}
              className={`${className} disabled`}
              label="Address Line 2"
              labelProps={{ className }}
              value={_.get(values, 'account.mailingAddress.address2', '')}
              disabled
            />

            <TextInfo
              id={`${id}-mailing-address-city`}
              className={`${className} half disabled`}
              label="City*"
              labelProps={{ className }}
              value={_.get(values, 'account.mailingAddress.locality', '')}
              disabled
            />

            <TextInfo
              id={`${id}-mailing-address-state`}
              className={`${className} half disabled`}
              label="State*"
              labelProps={{ className }}
              value={_.get(values, 'account.mailingAddress.administrativeArea', '')}
              disabled
            />

            <TextInfo
              id={`${id}-mailing-address-postal-code`}
              className={`${className} half disabled`}
              label="ZIP Code*"
              labelProps={{ className }}
              value={_.get(values, 'account.mailingAddress.postalCode', '')}
              disabled
            />
          </div>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
