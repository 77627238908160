import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useListExtendHostsQuery } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import config from 'src/config';
import { ExtendHostModal } from './ExtendHostModal';
import { ExtendHostsList } from './HostsList';
import { Styled } from './styles';

export const variables = {
  input: {
    pageSize: config.maxPageSize,
  },
};

export const HostManagementView: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const { data, loading, error, refetch } = useListExtendHostsQuery({
    fetchPolicy: 'cache-and-network',
    variables,
  });

  return (
    <Styled>
      <Box className="title-wrapper">
        <Title>eXtend+ Hosts</Title>
      </Box>
      <Card className="table-container">
        <Box pb={2} className="header-wrapper">
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            color="secondary"
            size="large"
            data-testid="add-extend-host-button"
          >
            Add eXtend+ Host
          </Button>
          <SubTitle>Total # of hosts: {data?.listExtendHosts?.total || 0}</SubTitle>
        </Box>
        <ExtendHostsList data={data?.listExtendHosts} loading={loading} error={error} />
      </Card>
      <ExtendHostModal
        open={open}
        onClose={() => {
          refetch();
          setOpen(false);
        }}
      />
    </Styled>
  );
};
