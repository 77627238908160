import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'src/@types';
import { DriversGroupsFilter } from './DriversGroupsFilter';
import { Grid } from '@material-ui/core';
import { useFlag, FLAG_OP_CREATE_ACCOUNTS } from 'src/config/featureFlag';

export interface Props {
  data?: Query;
  loading: boolean;
}

/**
 * Drivers Header component
 */
export const DriversHeader: React.FC<Props> = (props) => {
  const { data, loading } = props;
  const { edges, ...metadata } = data?.listDrivers || {};
  const opCreateAccountsFlag = useFlag(FLAG_OP_CREATE_ACCOUNTS);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const driversGroupFilterProps = props as any;

  return (
    <Grid
      item
      data-testid="DriversHeader"
      alignItems="flex-start"
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item xs={6} alignItems="flex-start" container direction="row" spacing={4}>
        <Grid item>
          <Typography variant="h5">Driver Management</Typography>
          <Typography variant="caption" data-testid="totalResults" color="textSecondary">
            {!loading && metadata ? `${metadata.total}  Drivers` : 'loading...'}
          </Typography>
        </Grid>

        <Grid item>
          <DriversGroupsFilter {...driversGroupFilterProps} />
        </Grid>
      </Grid>

      <Grid item container direction="row" xs={6} justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          component={Link}
          to={opCreateAccountsFlag ? '/accounts/create' : '/accounts/new'}
          data-testid="createDriverButton"
        >
          Create Account
        </Button>
      </Grid>
    </Grid>
  );
};
