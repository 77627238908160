/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Transforms the Falcon charger response into Formik format
 */
export const transformChargerResponse = (chargerData: any) => {
  let locationType = 'site';
  let locationId = _.get(chargerData, 'site.altId');
  if (!_.isNull(_.get(chargerData, 'warehouse'))) {
    locationType = 'warehouse';
    locationId = _.get(chargerData, 'warehouse.altId');
  }
  try {
    const values = {
      chargerName: _.get(chargerData, 'chargerName', ''),
      chargerStatusId: _.get(chargerData, 'chargerStatusId', ''),
      additionalEquipment: _.get(chargerData, 'additionalEquipment', ''),
      additionalSerial: _.get(chargerData, 'additionalSerial', ''),
      authenticationModeId: Number(_.get(chargerData, 'authenticationModeId', 214)),
      ccReaderType: _.get(chargerData, 'ccReaderType', ''),
      certified: _.get(chargerData, 'certified', ''),
      chargerIsManaged: _.get(chargerData, 'chargerIsManaged', ''),
      chargePointIdentity: _.get(chargerData, 'chargePointIdentity', ''),
      chargerModel: {
        altId: _.get(chargerData, 'chargerModel.altId', ''),
        modelName: _.get(chargerData, 'chargerModel.modelName', ''),
      },
      cid: _.get(chargerData, 'cid', ''),
      coBranding: _.get(chargerData, 'coBranding', ''),
      communicationMethod: _.get(chargerData, 'communicationMethod', ''),
      connectionDate: _.get(chargerData, 'connectionDate', ''),
      connectionUri: _.get(chargerData, 'connectionUri', ''),
      description: _.get(chargerData, 'description', ''),
      disabled: _.get(chargerData, 'disabled', ''),
      equipmentId: _.get(chargerData, 'equipmentId', ''),
      evcSyncedAt: _.get(chargerData, 'evcSyncedAt', null),
      evseId: _.get(chargerData, 'evseId', ''),
      evses: {
        edges: chargerData?.evses?.edges.map((rawEvse: any) => {
          const { __typename: a, ...evse } = rawEvse;

          evse.connectors = {
            edges: evse?.connectors?.edges.map((rawConnector: any) => {
              const { __typename: b, connectorTariffs, ...connector } = rawConnector;
              const { __typename: c, ...connectorType } = connector.connectorType;

              connector.connectorType = { connectorName: connectorType.connectorName };

              return connector;
            }),
          };

          return evse;
        }),
      },
      excluded: _.get(chargerData, 'excluded', ''),
      excludeFromOcpi: _.get(chargerData, 'excludeFromOcpi', false),
      fieldStationStatus: _.get(chargerData, 'fieldStationStatus', ''),
      fundingSource: _.get(chargerData, 'fundingSource', ''),
      hidden: _.get(chargerData, 'hidden', ''),
      identityKey: _.get(chargerData, 'identityKey', ''),
      ignoreStatusNotifications: _.get(chargerData, 'ignoreStatusNotifications', ''),
      installDate: _.get(chargerData, 'installDate', ''),
      ipAddress: _.get(chargerData, 'ipAddress', ''),
      lastReportedFirmwareVersion: _.get(chargerData, 'lastReportedFirmwareVersion', ''),
      localLoadBalancingEnabled: _.get(chargerData, 'localLoadBalancingEnabled', ''),
      localNetworkMaster: _.get(chargerData, 'localNetworkMaster', ''),
      locationType,
      locationId,
      macAddress: _.get(chargerData, 'macAddress', ''),
      maintenanceComments: _.get(chargerData, 'maintenanceComments', ''),
      maintenanceVendorId: _.get(chargerData, 'maintenanceVendorId', null),
      maxPower: _.get(chargerData, 'maxPower', null),
      meterSigningSupported: _.get(chargerData, 'meterSigningSupported', ''),
      modelNotes: _.get(chargerData, 'modelNotes', ''),
      mountType: _.get(chargerData, 'mountType', ''),
      networkConnectionTypeId: _.get(chargerData, 'networkConnectionTypeId', null),
      nonNetworked: _.get(chargerData, 'nonNetworked', ''),
      nextMaintenanceDate: _.get(chargerData, 'nextMaintenanceDate', ''),
      serialNumber: _.get(chargerData, 'serialNumber', ''),
      showInThirdPartyFilters: _.get(chargerData, 'showInThirdPartyFilters', ''),
      site:
        locationType === 'site'
          ? {
              altId: _.get(chargerData, 'site.altId', ''),
              access: _.get(chargerData, 'site.access', ''),
              address1: _.get(chargerData, 'site.address1', ''),
              address2: _.get(chargerData, 'site.address2', ''),
              administrativeArea: _.get(chargerData, 'site.administrativeArea', ''),
              hoursOfOperation: _.get(chargerData, 'site.hoursOfOperation', null),
              latitude: _.get(chargerData, 'site.latitude', ''),
              locality: _.get(chargerData, 'site.locality', ''),
              locationMarket: _.get(chargerData, 'site.locationMarket', ''),
              longitude: _.get(chargerData, 'site.longitude', ''),
              postalCode: _.get(chargerData, 'site.postalCode', ''),
              sid: _.get(chargerData, 'site.sid', ''),
              siteName: _.get(chargerData, 'site.siteName', ''),
              timeZone: _.get(chargerData, 'site.timeZone', ''),
              type: _.get(chargerData, 'site.type', ''),
            }
          : null,
      smartChargingEnabled: _.get(chargerData, 'smartChargingEnabled', ''),
      simultaneousChargingEnabled: _.get(chargerData, 'simultaneousChargingEnabled', ''),
      tags: {
        edges: chargerData?.tags?.edges.map((tag: any) => _.omit(tag, '__typename')),
        total: _.get(chargerData, 'tags.total', 0),
      },
      teslaAdapter: _.get(chargerData, 'teslaAdapter', ''),
      usageStartDate: _.get(chargerData, 'usageStartDate', ''),
      validateMeterSignature: _.get(chargerData, 'validateMeterSignature', ''),
      warehouse:
        locationType === 'warehouse'
          ? {
              altId: _.get(chargerData, 'warehouse.altId', ''),
              address1: _.get(chargerData, 'warehouse.address1'),
              address2: _.get(chargerData, 'warehouse.address2'),
              administrativeArea: _.get(chargerData, 'warehouse.administrativeArea'),
              latitude: _.get(chargerData, 'warehouse.latitude'),
              locality: _.get(chargerData, 'warehouse.locality'),
              longitude: _.get(chargerData, 'warehouse.longitude'),
              postalCode: _.get(chargerData, 'warehouse.postalCode'),
              warehouseName: _.get(chargerData, 'warehouse.warehouseName'),
            }
          : null,
      warrantyLengthId: _.get(chargerData, 'warrantyLengthId', null),
      warrantyStatusId: _.get(chargerData, 'warrantyStatusId', null),
      warrantyStartDate: _.get(chargerData, 'warrantyStartDate', ''),
      warrantyExpirationDate: _.get(chargerData, 'warrantyExpirationDate', ''),
      wirelessConnectionCarrierId: _.get(chargerData, 'wirelessConnectionCarrierId', null),
    };
    return values;
  } catch (e) {
    throw e;
  }
};
