import { CircularProgress, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { Options } from 'src/lib/helpers';
import { StyledFormControl, StyledLoading } from './styles';

export type SimpleSelectProps = {
  id?: string;
  label: string;
  className?: string;
  options: Array<Options>;
  debounce?: number;
  onChange: (value: string) => void;
  loading?: boolean;
  value: string | number;
  defaultValue?: string | number;
};

export const SimpleSelect: React.FC<SimpleSelectProps> = (props) => {
  const {
    label,
    id = _.kebabCase(label),
    className = 'SimpleSelect',
    options,
    debounce = 200,
    onChange,
    value: selected,
    loading = false,
    defaultValue = '',
  } = props;

  const { run: debouncedOnChange } = useDebounceFn(onChange, { wait: debounce });

  return (
    <StyledFormControl className={className}>
      <InputLabel className={clsx(className, 'label')}>{label}</InputLabel>
      <Select
        className={clsx(className, 'select')}
        displayEmpty
        defaultValue={defaultValue}
        onChange={(e) => {
          const values: string = e.target.value as string;
          debouncedOnChange(values);
        }}
        value={selected}
        data-testid={`${id}-select`}
        MenuProps={{
          variant: 'menu',
          getContentAnchorEl: null,
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option?.label}
              data-testid="option-menu-item"
              className={clsx(className, 'option')}
              value={option?.value}
              disableRipple
            >
              <ListItemText data-testid="option-label" primary={option?.label} style={{ fontSize: 'small' }} />
            </MenuItem>
          );
        })}
      </Select>

      {loading && (
        <StyledLoading>
          <CircularProgress size={20} />
        </StyledLoading>
      )}
    </StyledFormControl>
  );
};
