/* stylelint-disable color-no-hex */
import styled from 'styled-components';

export const Styled = styled.section`
  header {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    z-index: 999;
    margin: 32px 0 0 0;
    padding: 0 18px;
    padding: 0 calc(18px + var(--safe-area-inset-right)) 0 calc(18px + var(--safe-area-inset-left));

    section {
      &.title {
        display: flex;
        flex-direction: column;
        margin: 0px 40px 0 0;

        .subtitle {
          margin-top: 4px;
          font-size: 12px;
          color: #627d87;
        }
      }

      .checkbox {
        padding: 2px 18px 2px 3px;
      }

      &.filter {
        .menu {
          padding: 3px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          box-sizing: border-box;
          min-width: 200px;
          max-width: 250px;

          .select {
            margin: 0px 10px;
            font-size: 14px;
            min-width: 200px;
            max-width: 250px;

            &::before {
              content: '';
              border-bottom: none;
            }
          }
        }
        .clear-tags {
          padding: 4px;
          cursor: pointer;
        }
      }
    }

    .add-charger {
      margin-left: auto;
    }
  }
  hr {
    margin: 0px 16px;
  }
`;
