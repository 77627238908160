import { customPricingSchema } from 'src/components/shared/CustomPricingInput/validation';
import Yup from 'src/yup';
import { ValidationError } from 'yup';
import { FormValues } from '../../ChargerPrices/Pricing/ChargerPricingForm';
import { getOverlappingCustomPrices } from '../../ChargerPrices/Pricing/utils';
import { fieldName } from './PricingForm';
import { isFullyScheduledWeek } from './utils';

const { object, array, string } = Yup;

export const validationSchema = object({
  [fieldName]: array()
    .of(
      object({
        chargerGroupIds: array().of(string()).required('You must select at least one charger price group'),
        customPricing: array().of(customPricingSchema),
      }),
    )
    .test('customPricing', 'Time frames overlapping between prices.', function (values: FormValues[]) {
      if (!values) return true;

      const { path } = this;
      const errors: ValidationError[] = [];

      values.forEach((group, groupIndex) => {
        if (!group.customPricing?.length) {
          return;
        }

        const overlaps = getOverlappingCustomPrices(group.customPricing);
        overlaps.forEach((value, key) => {
          const [pricingIndex, scheduleIndex] = key.split('-');

          value.forEach((overlap) => {
            const [overlapPricingIndex, overlapScheduleIndex] = overlap.split('-');
            errors.push(
              this.createError({
                path: `${path}[${groupIndex}].customPricing[${pricingIndex}].tariffSchedules[${scheduleIndex}].timeFrame`,
                message: 'This time frame overlaps with another. You can define only one unique price per timeframe.',
              }),
              this.createError({
                path: `${path}[${groupIndex}].customPricing[${overlapPricingIndex}].tariffSchedules[${overlapScheduleIndex}].timeFrame`,
                message: 'This time frame overlaps with another. You can define only one unique price per timeframe.',
              }),
            );
          });
        });

        if (!isFullyScheduledWeek(group.customPricing)) {
          errors.push(
            this.createError({
              path: `${path}[${groupIndex}].gaps`,
              message: 'Ensure these charger groups have a price across 24 hour period for each day of the week',
            }),
          );
        }
      });

      if (errors.length) {
        // @ts-ignore
        throw new Yup.ValidationError(errors, values, path);
      }

      return true;
    }),
});
