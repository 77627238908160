/**
 * Copies info value to clipboard.
 */
export const copyToClipBoard = (string: string) => async (): Promise<void> => {
  try {
    await navigator.clipboard.writeText(string);
  } catch (err) {
    const el = document.createElement('input');
    el.value = string;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
};
