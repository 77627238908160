import { Article, ArticlesByCategory, Category } from '.';

export const groupArticlesByCategory = (categories: { stories: Category[] }, articles: { stories: Article[] }) => {
  const data = categories.stories.reduce<ArticlesByCategory>((acc, cv) => {
    const articleIds = cv.content.Articles || [];
    const name = cv.content.title || '';
    const articlesForCategory = articleIds.flatMap((id) => {
      const story = articles.stories.find(({ uuid }) => uuid === id);
      if (story) story.categorySlug = cv.slug;
      return story ? [story] : [];
    });
    if (articlesForCategory.length) acc[name] = articlesForCategory;
    return acc;
  }, {});
  return data;
};
