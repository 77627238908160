import { gql } from '@apollo/client';

export const validateAddress = gql`
  mutation validateAddress($addressInput: ValidateAddressInput) {
    validateAddress(input: $addressInput) {
      address1
      address2
      address3
      address4
      locality
      administrativeArea
      postalCode
      country
      latitude
      longitude
    }
  }
`;
