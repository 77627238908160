import React from 'react';
import styled from 'styled-components';
import BlockIcon from '@material-ui/icons/Block';
import { theme } from '@evgo/react-material-components';

import { useRoles } from 'src/lib/hooks/useRoles';
import { OperatorPortalRole, RoleApp } from 'src/@types/shared';
import { ThemeProvider } from '@material-ui/core';
import { innerExtendPlusTheme } from 'src/theme/extendPlusTheme';

export interface ExtendPlusRouteProps {
  component: React.FC;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    margin-top: 20px;
    width: 50px;
    height: 50px;
    color: ${theme.palette.error.main};
  }
`;

function Restricted() {
  return (
    <Container>
      <span>Only partner admins can access this page</span>
      <BlockIcon />
    </Container>
  );
}

export const ExtendPlusRoute: React.FC<ExtendPlusRouteProps> = ({ component: Component }) => {
  const { hasRole, loading } = useRoles();
  const isPartnerAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.PartnerAdmin);

  if (loading) {
    return null;
  }

  return <ThemeProvider theme={innerExtendPlusTheme}>{isPartnerAdmin ? <Component /> : <Restricted />}</ThemeProvider>;
};
