import { TextInfo } from '@evgo/react-material-components';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Site } from 'src/@types';
import { ModalProps } from 'src/@types/client';
import { StyledModal } from '../../../../../../shared/StyledModal';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export const initialValues = {
  directions: '',
};

interface Props {
  open?: boolean;
  modalProps?: ModalProps;
  className?: string;
  selected?: Site;
}

export const SiteDirectionsForm: React.FC<Props> = (props) => {
  const className = 'site-directions';
  const { open, selected } = props;
  const { setModalState } = useModalContext();
  const { handleBlur, handleChange, setValues, submitForm, values, resetForm } =
    useFormikContext<Record<string, unknown>>();

  useEffect(() => {
    if (selected && selected.altId) {
      const { directions } = selected;
      setValues({ directions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onCancelClick = () => {
    setModalState({
      modalName: '',
      modalProps: {},
      modalVisible: false,
    });

    resetForm();
  };

  return (
    <StyledModal
      className={clsx(className, 'modal')}
      onClose={onCancelClick}
      open={!!open}
      title="Directions to Chargers"
      subtitle="Directions on how to get to the chargers on this site"
      onClick={submitForm}
      primaryButtonText="Save"
      secondaryButtonClick={onCancelClick}
      secondaryButtonText="Cancel"
    >
      <Styled className={clsx(className, 'content')}>
        <Form className={className} data-testid={className}>
          <TextInfo
            className={clsx(className, 'full-width')}
            label="Add directions to help people find this site"
            multiline
            minRows={5}
            maxRows={5}
            labelProps={{ className }}
            name="directions"
            onBlur={handleBlur}
            onChange={handleChange}
            value={_.get(values, 'directions', '')}
            data-testid="sites-charger-directions-textarea"
          />
        </Form>
      </Styled>
    </StyledModal>
  );
};
