import { gql } from '@apollo/client';

export const updateCardOrders = gql`
  mutation updateCardOrders($input: UpdateCardOrdersInput!) {
    updateCardOrders(input: $input) {
      altId
      orderStatus
    }
  }
`;
