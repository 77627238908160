import { useCallback } from 'react';
import { useModalContext } from 'src/contexts/ModalContext';

type ConfirmationDialogOptions = {
  callback?: () => void;
  title?: string;
  body?: string;
  body2?: () => React.ReactNode;
  buttonConfirmText?: string;
  list?: unknown[];
  hideCancel?: boolean;
};

type ConfirmationModalProps = {
  modalName: string;
  visible: boolean;
  options: ConfirmationDialogOptions;
};

export function useConfirmationDialog() {
  const { setModalState } = useModalContext();

  const confirmationDialog = useCallback(
    ({ modalName, visible, options }: ConfirmationModalProps = { modalName: '', visible: false, options: {} }) => {
      setModalState({
        modalName,
        modalProps: {
          ...options,
        },
        modalVisible: visible,
      });
    },
    [setModalState],
  );

  const open = useCallback(
    (options?: ConfirmationDialogOptions) =>
      confirmationDialog({
        modalName: 'ConfirmationDialog',
        visible: true,
        options: {
          title: options?.title || 'Are you sure?',
          buttonConfirmText: options?.buttonConfirmText || 'Yes',
          ...options,
        },
      }),
    [confirmationDialog],
  );

  const close = useCallback(() => confirmationDialog(), [confirmationDialog]);

  return { open, close, confirmationDialog };
}
