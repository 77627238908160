import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';

export const Styled = styled.svg`
  fill: currentColor;
`;

export type Props = {
  id?: string;
  className?: string;
};

export const ChargingIcon: React.FC<Props> = ({ id, className: parentClass }) => {
  const className = 'ChargingIcon';

  return (
    <Styled
      id={id}
      className={clsx(className, parentClass)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      viewBox="0 0 16 21"
    >
      <path
        d="M15.122 7.47478H9.18392L9.72324 0.911678H9.72435C9.74987 0.534375 9.5246 0.184799 9.16949 0.0527531C8.81437 -0.0781964 8.41599 0.0405457 8.1896 0.343493L0.1495 11.6793C-0.0302769 11.9412 -0.0491433 12.2819 0.099562 12.5627C0.249375 12.8435 0.542348 13.0177 0.859736 13.0143H6.93988L6.3151 19.7195C6.27959 20.1079 6.51375 20.4708 6.88329 20.5995L7.16738 20.6561C7.4559 20.6528 7.72335 20.503 7.87761 20.2589L15.8322 8.80965C16.012 8.54775 16.0308 8.20706 15.8821 7.92629C15.7323 7.64553 15.4393 7.47129 15.122 7.47464L15.122 7.47478Z"
        fill="#AFBE00"
      />
    </Styled>
  );
};
