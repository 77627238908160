import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled(Paper)`
  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      &:first-child {
        flex: 1 1 0%;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;

        * {
          margin: 0 ${spacing()}px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }
  }

  table {
    tbody {
      tr {
        th,
        td {
          a {
            color: ${config.colors.accentBlue};
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          padding: ${`${spacing(2)}px ${spacing(3)}px`};
        }
      }
    }

    .select {
      padding-right: 24px;
      text-align: right;
    }
  }
`;
