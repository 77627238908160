import React from 'react';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import inMaintenanceImg from 'src/assets/images/in-maintenance.png';

const { spacing } = theme;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  div {
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  img {
    margin-top: -${spacing(8)}px;
  }
  h4 {
    font-weight: 700;
    padding: ${spacing(4)}px;
  }
`;

export const InMaintenanceView: React.FC = () => {
  return (
    <StyledDiv data-testid="in-maintenance-view">
      <div>
        <img width={450} src={inMaintenanceImg} alt="wrench and screwdriver" />
        <br />
        <h4>This means we're adding additional features, bug fixes, and magic, so check back in a few minutes...</h4>
      </div>
    </StyledDiv>
  );
};
