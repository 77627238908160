import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import {
  Checkbox,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { listFalconConstraints } from '../../../../../apollo/queries/options';
import { useBlurDropdown } from '../../../../../lib/hooks';
import { initialValues } from '../initialValues';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  expanded?: boolean;
  onChange?: () => void;
  id?: string;
  className?: string;
}

/**
 * Charger Maintenance Tab component
 */
export const ChargerMaintenanceTab: React.FC<Props> = (props) => {
  const id = `${props.id}-${_.kebabCase('ChargerMaintenanceTab')}`;
  const className = id;
  const { values, handleBlur, handleChange, isSubmitting, errors, touched, setValues } =
    useFormikContext<typeof initialValues>();
  const { altId } = useParams<{ altId: string }>();
  const handleBlurDropdown = useBlurDropdown();

  const { data: maintenanceVendorOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'chargers',
          },
          columnName: {
            eq: 'maintenance_vendor',
          },
        },
      },
    },
  });
  const { data: warrantyLengthOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'chargers',
          },
          columnName: {
            eq: 'warranty_length',
          },
        },
      },
    },
  });
  const { data: warrantyStatusOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'chargers',
          },
          columnName: {
            eq: 'warranty_status',
          },
        },
      },
    },
  });

  const filteredMaintenanceVendorOptions = maintenanceVendorOptions?.listFalconConstraints?.edges?.map((option) => ({
    label: option?.columnName,
    value: Number(option?.columnValue),
  }));
  const filteredWarrantyLengthOptions = warrantyLengthOptions?.listFalconConstraints?.edges?.map((option) => ({
    label: option?.columnName,
    value: Number(option?.columnValue),
  }));
  const filteredWarrantyStatusOptions = warrantyStatusOptions?.listFalconConstraints?.edges?.map((option) => ({
    label: option?.columnName,
    value: Number(option?.columnValue),
  }));

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  let chargerInMaintenance = false;
  let connectorsInMaintenance = [];
  if (values?.evses?.edges?.[0]?.connectors?.edges) {
    connectorsInMaintenance = values.evses.edges[0].connectors.edges.filter((connector) => !!connector.inMaintenance);
    if (connectorsInMaintenance.length === values.evses.edges[0].connectors.edges.length) {
      chargerInMaintenance = true;
    }
  }

  const handleMaintenanceCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (values?.evses?.edges?.[0]?.connectors?.edges) {
      const newConnectors = values?.evses?.edges[0].connectors.edges.map((connector) => ({
        ...connector,
        inMaintenance: e.target.checked,
      }));
      const newEvses = {
        ...values.evses,
        edges: [
          { ...values.evses.edges[0], connectors: { ...values.evses.edges[0].connectors, edges: newConnectors } },
        ],
      };
      setValues({ ...values, evses: newEvses });
      chargerInMaintenance = e.target.checked;
    }
  };

  const columns = [
    { key: 'changedDate', label: 'Changed Date' },
    { key: 'changedBy', label: 'Changed By' },
    { key: 'changeField', label: 'Changed Field' },
  ];

  const nextMaintenanceDate = _.get(values, 'nextMaintenanceDate')
    ? moment(_.get(values, 'nextMaintenanceDate')).tz('UTC').format('YYYY-MM-DD')
    : '';
  const warrantyStartDate = _.get(values, 'warrantyStartDate')
    ? moment(_.get(values, 'warrantyStartDate')).tz('UTC').format('YYYY-MM-DD')
    : '';
  const warrantyExpirationDate = _.get(values, 'warrantyExpirationDate')
    ? moment(_.get(values, 'warrantyExpirationDate')).tz('UTC').format('YYYY-MM-DD')
    : '';

  return (
    <StyledAccordion
      className={`${customClass} maintenance`}
      expanded={props.expanded}
      onChange={props.onChange}
      defaultExpanded={true}
    >
      <AccordionSummary className={className} expandIcon={altId ? <ExpandMore /> : null} component="header">
        <Typography className={className} variant="h6" component="h2">
          Maintenance
        </Typography>
      </AccordionSummary>

      <Divider className={className} />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <div className={`${className} checkbox-container`}>
              <Checkbox
                className={className}
                id={`${id}-charger-in-maintenance-checkbox`}
                checked={chargerInMaintenance}
                onChange={(e) => handleMaintenanceCheck(e)}
                color="secondary"
              />
              <Typography className={className} variant="subtitle1" component="h3">
                Charger in Maintenance
              </Typography>
            </div>
            <TextInfo
              className={`${className} third`}
              disabled={true}
              label="Number of Connectors in Maintenance"
              labelProps={{ className: `${className} prefilled` }}
              id={`${id}-number-of-connectors-in-maintenance`}
              value={connectorsInMaintenance.length || '0'}
            />
            <TextInfo
              className={`${className} fourth`}
              disabled={isSubmitting}
              error={!!_.get(errors, 'nextMaintenanceDate')}
              helpertext={_.get(errors, 'nextMaintenanceDate', '')}
              id={`${id}-next-maintenance-date`}
              label="Next Maintenance Date"
              labelProps={{ className: `${className} prefilled` }}
              name="nextMaintenanceDate"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
              type="date"
              value={nextMaintenanceDate}
            />
            <Dropdown
              className={`${className} fourth dropdown`}
              disabled={isSubmitting}
              id={`${id}-maintenance-vendor`}
              label="Maintenance Vendor"
              labelProps={{ className }}
              name="maintenanceVendorId"
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={filteredMaintenanceVendorOptions}
              value={values.maintenanceVendorId}
            />
            <TextInfo
              className={className}
              label="Maintenance Comments"
              multiline
              rows="2"
              name="maintenanceComments"
              id={`${id}-maintenance-comments`}
              onChange={handleChange}
              value={values.maintenanceComments}
            />
            <Typography className={className} variant="subtitle1" component="h3">
              Maintenance History
            </Typography>
            <Table className={className}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.key} className={`${className} ${column.key}`} component="th">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody></TableBody>
            </Table>
            <Divider className={className} />
            <Typography className={className} variant="subtitle1" component="h3">
              Warranty
            </Typography>
            <Dropdown
              className={`${className} fourth dropdown`}
              disabled={isSubmitting}
              id={`${id}-warranty-length`}
              label="Warranty Length"
              labelProps={{ className }}
              name="warrantyLengthId"
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={filteredWarrantyLengthOptions}
              value={_.get(values, 'warrantyLengthId', null)}
            />
            <Dropdown
              className={`${className} fourth dropdown`}
              disabled={isSubmitting}
              id={`${id}-warranty-status`}
              label="Warranty Status"
              labelProps={{ className }}
              name="warrantyStatusId"
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={filteredWarrantyStatusOptions}
              value={_.get(values, 'warrantyStatusId', null)}
            />
            <TextInfo
              className={`${className} fourth`}
              disabled={isSubmitting}
              error={!!_.get(errors, 'warrantyStartDate')}
              helpertext={_.get(errors, 'warrantyStartDate', '')}
              id={`${id}-warranty-start-date`}
              label="Warranty Start Date"
              labelProps={{ className: `${className} prefilled` }}
              name="warrantyStartDate"
              onBlur={handleBlur}
              onChange={handleChange}
              type="date"
              value={warrantyStartDate}
            />
            <TextInfo
              className={`${className} fourth`}
              disabled={isSubmitting}
              error={
                _.get(errors, 'warrantyExpirationDate', false) &&
                (_.get(touched, 'warrantyExpirationDate', false) || _.get(touched, 'warrantyStartDate', false))
              }
              helpertext={
                _.get(errors, 'warrantyExpirationDate', false) &&
                (_.get(touched, 'warrantyExpirationDate', false) || _.get(touched, 'warrantyStartDate', false))
                  ? _.get(errors, 'warrantyExpirationDate', '')
                  : ''
              }
              id={`${id}-warranty-expiration-date`}
              label="Warranty Expiration Date"
              labelProps={{ className: `${className} prefilled` }}
              name="warrantyExpirationDate"
              onBlur={handleBlur}
              onChange={handleChange}
              type="date"
              value={warrantyExpirationDate}
            />
          </div>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
