import { gql } from '@apollo/client';

export const updatedHost = gql`
  subscription updatedHost {
    updatedHost {
      altId
      evcId
      evcSyncedAt
    }
  }
`;
