import { Fragment, useState, useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useListSessionsForMapQuery } from 'src/@types';
import ReactMapboxGl, { Marker, Popup } from 'react-mapbox-gl';
import mapStyle from './mapStyle.json';
import config from 'src/config';

import { theme } from '@evgo/react-material-components';
import { CardHeader } from 'src/components/shared/Card';
import { MetricCardGroup } from 'src/components/shared/MetricCard';

import { getSummaryBySite } from './helpers';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_CLIENT_KEY || '',
});

type LiveSessionMapProps = {
  siteIds?: string[];
};

type SiteSummary = {
  administrativeArea: string;
  longitude: number;
  latitude: number;
  totalSessions: number;
  siteName: string;
};

type PopupProps = {
  coordinates?: number[];
  site?: SiteSummary;
};

export type SummaryBySiteName = {
  [key: string]: SiteSummary;
};

const testid = (value: string) => ({ 'data-testid': `live-session-card-map-${value}` });

const MAP_CENTER: [number, number] = [-95, 38];
const MAP_ZOOM: [number] = [2.8];

export const LiveSessionMap = ({ siteIds }: LiveSessionMapProps) => {
  const [popup, setPopup] = useState<PopupProps>({});

  const { data, loading } = useListSessionsForMapQuery({
    variables: {
      input: {
        filter: {
          endTime: { eq: null },
          siteId: { in: siteIds },
        },
        page: 0,
        pageSize: config.maxPageSize,
      },
    },
    skip: !siteIds?.length,
    pollInterval: 5000,
  });

  const summaryBySiteName = useMemo(() => getSummaryBySite(data), [data]);

  return (
    <MetricCardGroup id="live-session-card">
      <Box p={4}>
        <CardHeader title="Active Sessions Map" subtitle="Current active sessions across all sites" />
        <Box flex={1} {...testid('container')}>
          {loading ? (
            <Box display="flex" justifyContent="center" {...testid('network-loading')}>
              <CircularProgress />
            </Box>
          ) : (
            <Map
              style={mapStyle}
              center={MAP_CENTER}
              zoom={MAP_ZOOM}
              containerStyle={{
                height: '420px',
              }}
            >
              <Fragment>
                {Object.keys(summaryBySiteName).map((key, i) => {
                  const summary = summaryBySiteName[key];
                  const coordinates = [summary.longitude, summary.latitude];

                  return (
                    <Marker key={`map-marker-${i}`} coordinates={coordinates}>
                      <div
                        {...testid(`map-marker-${i}`)}
                        className="custom-pin"
                        onMouseEnter={() => {
                          setPopup({
                            coordinates,
                            site: summary,
                          });
                        }}
                        onMouseLeave={() => {
                          setPopup({});
                        }}
                      >
                        <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                          <circle
                            cx="20"
                            cy="20"
                            fill="none"
                            r="5"
                            stroke={theme.palette.primary[500]}
                            stroke-width="2"
                          >
                            <animate
                              attributeName="r"
                              from="8"
                              to="20"
                              dur="1.5s"
                              begin="0s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="opacity"
                              from="1"
                              to="0"
                              dur="1.5s"
                              begin="0s"
                              repeatCount="indefinite"
                            />
                          </circle>
                          <circle cx="20" cy="20" fill={theme.palette.primary[500]} r="5" />
                        </svg>
                      </div>
                    </Marker>
                  );
                })}
                {popup.site && popup.coordinates ? (
                  <Popup offset={[0, -20]} coordinates={popup.coordinates}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <Typography variant="subtitle2">
                        {popup.site.siteName}
                        {popup.site?.administrativeArea && `, ${popup.site.administrativeArea}`}
                      </Typography>
                      <Typography variant="caption">{popup.site.totalSessions} live sessions</Typography>
                    </Box>
                  </Popup>
                ) : (
                  <></>
                )}
              </Fragment>
            </Map>
          )}
        </Box>
      </Box>
    </MetricCardGroup>
  );
};
