/* stylelint-disable color-no-hex */
import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  section {
    header {
      padding: ${`0 ${spacing(3)}px`};
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  h3 {
    width: calc(100% - ${spacing(2)}px);
    padding: ${`${spacing(2)}px ${spacing()}px 0`};
    color: ${palette.text.secondary};
  }

  h6 {
    width: calc(100% - ${spacing(2)}px);
    padding: ${`0 ${spacing()}px 0`};
    color: ${palette.text.secondary};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: ${`0 ${spacing(2)}px ${spacing(2)}px`};

        &.hours {
          > div {
            padding: ${`${spacing(3)}px ${spacing()}px`};
          }
          div {
            width: 100%;
            display: grid;
            align-items: start;
            margin-bottom: 15px;

            h3.timeslot-error {
              color: ${palette.error.main};
            }

            &.timezone {
              padding: 0;
              label {
                transform: translate(${`${spacing()}px, ${spacing()}px`}) scale(1);

                &[data-shrink='true'] {
                  transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
                }
              }
            }

            &.hours-wrapper {
              display: flex;
              align-items: center;

              .hours-label {
                width: ${spacing(10)}px;
              }
            }

            &.hours-container {
              grid-template-columns: auto 0px;
              margin-bottom: 0px;
            }
            &.checkbox-container {
              display: flex;
              flex-direction: row;
              margin-right: ${spacing(3)}px;
              h3 {
                font-weight: 300;
                padding: ${`${spacing() + 2}px 0`};
              }
              span {
                padding: ${`${spacing(1.5)}px ${spacing() / 2}px`};
              }
            }
            &.custom-hours-container {
              animation: fadeIn 1s ease;
              div {
                display: flex;
                margin-bottom: 0px;

                &.root {
                  height: ${spacing(8)}px;
                }
                &.date-range {
                  align-items: center;
                }
              }
              hr {
                margin-left: ${spacing(1)}px;
                width: ${spacing(2)}px;
              }
            }
          }
          h3 {
            padding: ${spacing()}px;
            width: auto;
          }
        }

        .map-container {
          width: calc(100% - 16px);

          .map {
            width: 100%;
            &.disabled {
              filter: saturate(0);
            }
          }
        }

        > button {
          color: #fff;
          background-color: #0073dd;
          position: absolute;
          bottom: ${`${spacing(3)}px`};
          right: ${`${spacing(3)}px`};
          padding: ${`${spacing()}px ${spacing(4)}px`};

          &.add-hours {
            position: relative;
            height: ${spacing(3)}px;
            width: ${spacing(3)}px;
            padding: 0;
            margin-left: ${spacing(3)}px;
          }
        }

        hr {
          height: 1px;
          width: calc(100% + ${spacing(4)}px);
          margin-left: -${spacing(2)}px;
        }

        img {
          width: 33.33%;
          padding: ${`0 ${spacing()}px`};
        }

        .img-placeholder {
          width: 33.33%;
          height: ${`${spacing(15)}px`};
          background: lightgrey;
          padding: ${`0 ${spacing()}px ${spacing(9)}px`};
          margin: ${`0 ${spacing()}px ${spacing(9)}px`};
        }

        h3 {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }

        .charger-image {
          margin: ${`${spacing(2)}px ${spacing()}px 0`};

          button {
            position: absolute;
            top: 0;
            left: 0;
            width: ${spacing(2.5)}px;
            height: ${spacing(2.5)}px;
            border-radius: ${spacing(1.25)}px;
          }

          img {
            width: 92px;
            height: 92px;
            object-fit: cover;
          }

          svg {
            width: 0.75rem;
            height: 0.75rem;
          }
        }

        .form-control,
        .select {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing() / 2}px ${spacing()}px 0`};
          &.half {
            width: calc(50% - ${spacing(2)}px);
          }
        }

        .form-control {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing() / 2}px ${spacing()}px 0`};

          .select {
            width: calc(100% - ${spacing(2)}px);
            padding: ${`${spacing() / 2}px ${spacing()}px 0`};

            &.half {
              width: calc(50% - ${spacing(2)}px);
              padding: ${`${spacing() / 2}px ${spacing()}px 0`};
            }
          }

          label {
            transform: translate(${`${spacing(2)}px, ${spacing(1.5)}px`}) scale(1);

            &[data-shrink='true'] {
              transform: translate(${`${spacing(2)}px, ${spacing(1)}px`}) scale(0.75);
            }
          }
        }
        .select {
          padding-bottom: 12px;
        }
      }
    }
  }

  svg {
    &.left {
      margin-right: ${spacing()}px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
