import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2'; // requires chart.js
import { Styled as StyledTableRow } from './styles';

export interface Props {
  sessionInfo: {
    startkWh: string;
    endkWh: string;
    meterReadings: { timeStamp: string; kW: string }[];
    open: boolean;
    index: number;
    sessionId: string;
    totalTime: string;
  };
  className?: string;
}

/**
 * Charge Session Details component
 */
export const ChargeSessionDetails: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargeSessionDetails');
  const className = id;

  const {
    sessionInfo: { startkWh, endkWh, meterReadings, open, index, sessionId, totalTime },
  } = props;

  const data = {
    labels: meterReadings.map((reading) => {
      return moment(reading.timeStamp).utc().format('hh:mm') || '';
    }),
    datasets: [
      {
        label: 'kW',
        data: meterReadings.map((reading) => {
          return reading.kW || '';
        }),
        fill: true,
        backgroundColor: '#B8D8E5',
        borderColor: 'rgba(0, 0, 0, 0)',
        radius: 2,
        hitRadius: 4,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      x: {
        beginAtZero: false,
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        beginAtZero: false,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    layout: {
      padding: {
        top: 50,
      },
    },
  };

  const headers = [
    { id: 'readOnDate', label: 'Meter Read On Date' },
    { id: 'meterValue', label: 'Meter Value (kW)' },
  ];

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <>
      <StyledTableRow className={`${customClass} ${open ? 'open' : ''}`} id={`${id}-table-session-details-${index}`}>
        <TableCell colSpan={12} className={`${className} session-details`}>
          <div className={className}>
            <Typography
              className={className}
              variant="h6"
              color="secondary"
              component="h2"
              id={`${id}-session-details-session-id-${index}`}
            >
              Session Details: {sessionId}
            </Typography>

            <Typography className={className} variant="subtitle1">
              Transaction:
              {/* <Link id={`${id}-session-details-transaction-id-${index}`} to={ transactionId }></Link> */}
            </Typography>
          </div>

          <div className={className}>
            <Typography className={className} variant="caption" id={`${id}-session-details-total-time-${index}`}>
              Billed Time: {totalTime}
            </Typography>

            <Typography className={className} variant="caption" id={`${id}-session-details-start-kWh-${index}`}>
              Start kWh: {startkWh}
            </Typography>

            <Typography className={className} variant="caption" id={`${id}-session-details-end-kWh-${index}`}>
              End kWh: {endkWh}
            </Typography>
          </div>
        </TableCell>
      </StyledTableRow>

      <StyledTableRow className={`${className} ${!open ? 'open' : ''}`} id={`${id}-table-meter-details-${index}`}>
        <TableCell colSpan={12} className={`${className} meter-readings`}>
          <article className={className}>
            <div className={className}>
              <Typography className={className} variant="body1">
                Meter Values Received
              </Typography>

              <Line id={`${id}-chart-${index}`} data={data} options={options} />
            </div>

            <div className={className}>
              <Typography className={className} variant="body1">
                Charging
                <span id={`${id}-charging-list-total-${index}`}>
                  {`(${meterReadings && meterReadings.length ? meterReadings.length : 0})`}
                </span>
              </Typography>

              <div className={`${className} meter-list`}>
                <Table className={className}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableCell className={className} key={header.id}>
                          {header.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                </Table>

                <div className={className}>
                  <Table className={className}>
                    <TableBody>
                      {meterReadings?.map((reading, j) => {
                        return (
                          <TableRow className={className} key={j}>
                            <TableCell className={className} id={`${id}-charging-list-meter-timestamp-${index}`}>
                              {moment(reading.timeStamp).utc().format('MM/DD/YYYY hh:mm:ss')}
                            </TableCell>

                            <TableCell className={className} id={`${id}-charging-list-meter-value-${index}`}>
                              {reading.kW}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </article>
        </TableCell>
      </StyledTableRow>
    </>
  );
};
