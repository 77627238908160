import React from 'react';
import { Styled } from './styles';
import { ReservationPricing } from 'src/lib/helpers/transformReservationPricingForExtend';
import { formatPrice } from '../helpers';
import { Card, CardHeader } from 'src/components/shared/Card';

export interface Props {
  pricing?: ReservationPricing;
}

export const ReservationPriceSection: React.FC<Props> = ({ pricing }) => {
  const hasPricing = pricing?.fee || pricing?.fee === 0 || pricing?.noShowFee || pricing?.noShowFee === 0;

  return (
    <Styled>
      <Card>
        <CardHeader
          title="Reservation Fees"
          subtitle={'Applies to charger connectors that are marked as reservable in this charger group.'}
        />
        {hasPricing ? (
          <>
            {(Boolean(pricing?.fee) || pricing?.fee === 0) && <p>Reservation fee - ${formatPrice(pricing.fee)}</p>}
            {(Boolean(pricing?.noShowFee) || pricing?.noShowFee === 0) && (
              <p>No show fee - ${formatPrice(pricing.noShowFee)}</p>
            )}
          </>
        ) : (
          'N/A'
        )}
      </Card>
    </Styled>
  );
};
