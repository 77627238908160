import { useCallback } from 'react';
import { useSnackbarContext } from 'src/contexts/SnackBarContext';

import { SnackbarType } from 'src/@types/shared';

type SnackbarOptions = {
  buttonText?: string;
};

export function useSnackbar() {
  const { setSnackbarState } = useSnackbarContext();

  const snackbar = useCallback(
    (message: string, type: SnackbarType, visible = true, options: SnackbarOptions = {}) => {
      const newState = {
        snackMessage: message,
        snackVisible: visible,
        snackbarType: type,
        ...(options?.buttonText ? { snackbarButtonText: options.buttonText } : {}),
      };

      setSnackbarState(newState);
    },
    [setSnackbarState],
  );

  const close = useCallback(() => snackbar('', 'success', false), [snackbar]);

  const success = useCallback(
    (message: string, options?: SnackbarOptions) => snackbar(message, 'success', true, options),
    [snackbar],
  );

  const error = useCallback(
    (message: string, options?: SnackbarOptions) => snackbar(message, 'error', true, options),
    [snackbar],
  );

  const warning = useCallback(
    (message: string, options?: SnackbarOptions) => snackbar(message, 'warning', true, options),
    [snackbar],
  );

  const info = useCallback(
    (message: string, options?: SnackbarOptions) => snackbar(message, 'info', true, options),
    [snackbar],
  );

  const system = useCallback(
    (message: string, options?: SnackbarOptions) => snackbar(message, 'system', true, options),
    [snackbar],
  );

  return { close, success, error, warning, info, system, snackbar };
}
