import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Driver, Query } from 'src/@types';
import { useMappedFalconConstraints } from 'src/lib/hooks/useMappedFalconConstraints';
import { getAccount } from '../../../../apollo/queries/accounts';
import { updateQuery } from '../../../../lib/helpers';
import { CardRow } from './CardRow';
import { Styled as StyledAccordion } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
export interface Props {
  className?: string;
}

const columns = [
  { key: 'internal', label: 'Internal' },
  { key: 'external', label: 'External' },
  { key: 'brand', label: 'Brand' },
  { key: 'cardType', label: 'Type' },
  { key: 'assignee', label: 'Assignee' },
  { key: 'cardStatus', label: 'Status' },
  { key: 'updatedAt', label: 'Status Updated' },
  { key: 'actions', label: '' },
];

/** Cards List view component. */
export const AccountCards: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountCards');
  const { altId } = useParams();

  const className = 'account-cards-component';

  const { constraints: cardTypeContraints } = useMappedFalconConstraints({
    tableName: { eq: 'cards' },
    columnName: { eq: 'card_type' },
    map: (row) => row.columnText as string,
  });
  const { setModalState } = useModalContext();
  const [disableAddCard, setDisableAddCard] = useState(false);
  const { data, loading, fetchMore } = useQuery<Query>(getAccount, {
    variables: {
      accountInput: { altId },
      driversInput: { pageSize: 999 },
      accountVehiclesInput: { pageSize: 999 },
      cardsInput: { pageSize: 999 },
    },
    skip: !altId,
  });

  const { edges: cards, total: cardsTotal } = data?.getAccount?.cards || {};
  const { edges: drivers } = data?.getAccount?.drivers || {};
  const transformedCards = cards
    ? cards.map((card) => {
        const transformedCard: Card = {
          ...card,
          cardType: card?.cardTypeId ? cardTypeContraints.get(card.cardTypeId) : '',
        } as Card;

        return transformedCard;
      })
    : [];

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  const showAddCardModal = async () => {
    setDisableAddCard(true);

    const res = await fetchMore({
      updateQuery,
      variables: {
        accountInput: { altId },
        driversInput: { pageSize: 999 },
        accountVehiclesInput: { pageSize: 999 },
        cardsInput: { pageSize: 999 },
      },
    });
    const { getAccount: updatedData } = res?.data || {};
    const { edges: updatedDrivers } = updatedData?.drivers || {};

    setModalState({
      modalName: 'AccountCardModal',
      modalProps: {
        fetchMore,
        drivers: updatedDrivers?.map((driver: Driver) => ({
          value: driver.altId,
          label: `${driver.firstName} ${driver.lastName}`,
        })),
        accountOwnerId: (_.find(drivers, { driverType: 'OWNER' }) as Driver).altId,
        accountId: altId || '',
      },
      modalVisible: true,
    });
    setDisableAddCard(false);
  };

  return (
    <StyledAccordion className={customClass} data-testid={id}>
      <Accordion>
        <AccordionSummary
          className={className}
          expandIcon={<ExpandMore data-testid="cards-expand-collapse" />}
          component="header"
          data-testid={`${id}-header`}
        >
          <div className={`${className} container`}>
            <div className={`${className} title`}>
              <Typography className={className} variant="h6" component="h2" data-testid="cards-title">
                Cards
              </Typography>
              <Typography className={className} variant="caption" data-testid="cards-number-of-cards">
                {!loading && cardsTotal ? `Currently viewing ${cardsTotal}` : '0 Cards'}
              </Typography>
            </div>
            <div className={`${className} controls`}>
              <Button
                id="add-account-card-button"
                disabled={loading || disableAddCard}
                className={className}
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  showAddCardModal();
                }}
                variant="contained"
              >
                Add Card
              </Button>
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={`${className} vehicle-list`}>
          <Table className={className} data-testid="cards-table">
            <TableHead className={className}>
              <TableRow className={className}>
                {columns.map((column) => (
                  <TableCell
                    key={column.key}
                    className={`${className} ${column.key}`}
                    component="th"
                    data-testid={`cards-table-header-${column.label.replace(/ /gi, '_').toLowerCase()}`}
                  >
                    <TableSortLabel disabled={true}>{column.label}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className={className}>
              {!loading &&
                transformedCards?.map((card, i) => {
                  return (
                    <CardRow
                      key={i}
                      index={i}
                      className={className}
                      accountAltId={altId || ''}
                      card={card || (card as unknown as Card)}
                      fetchMore={fetchMore}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </StyledAccordion>
  );
};
