import { gql } from '@apollo/client';

export const listVehicleConnectors = gql`
  query listVehicleConnectors($input: ListVehicleConnectorsInput) {
    listVehicleConnectors(input: $input) {
      edges {
        id
        connectorName: name
      }
    }
  }
`;
