import { Checkbox, AccordionDetails, AccordionSummary, Typography, Hidden } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { initialValues } from '../../initialValues';
import { Styled as StyledAccordion } from './styles';
import { Divider, TextInfo } from '@evgo/react-material-components';

export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plan Display Settings component
 */
export const PlanDisplaySettings: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanDisplaySettings');
  const className = id;
  const { errors, handleBlur, isSubmitting, values, setFieldValue, touched } = useFormikContext<typeof initialValues>();

  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledAccordion id={parentId} className={`${customClass} plan-details`} defaultExpanded={true}>
      <AccordionSummary className={className} component="header" id={`${id}-header`}>
        <div className={`${className} container`}>
          <div className={`${className} title`}>
            <Typography className={className} variant="h6" component="h2">
              Plan Display Settings
            </Typography>
          </div>
        </div>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} details`}>
        <div className={`${className} checkbox-container`}>
          <Checkbox
            checked={_.get(values, 'displayToCustomer')}
            className={className}
            disabled={isSubmitting}
            inputProps={{ id: `${id}-display-to-customers-checkbox` }}
            name="displayToCustomer"
            onBlur={handleBlur}
            onClick={(e) => {
              e.stopPropagation();
              setFieldValue('displayToCustomer', !_.get(values, 'displayToCustomer'));
            }}
            data-testid={`${id}-display-to-customers-checkbox`}
          />
          <Typography className={className} variant="subtitle1" component="h3">
            Display to Customers
          </Typography>
        </div>
        <Hidden xsUp>
          <TextInfo
            className={`${className} fourth`}
            disabled={true}
            hidden
            error={!!(_.get(touched, 'driverRank') && _.get(errors, 'driverRank'))}
            helpertext={_.get(touched, 'driverRank') && _.get(errors, 'driverRank')}
            id={`${id}-plan-code-input`}
            label="Driver Rank"
            labelProps={{ className }}
            name="driverRank"
            onBlur={handleBlur}
            value={_.get(values, 'driverRank')}
            data-testid={`${id}-plan-code-input`}
          />
        </Hidden>
        <div className="divider" />
        <div className={`${className} checkbox-container`}>
          <Checkbox
            checked={_.get(values, 'displayToLoggedOutUser')}
            className={className}
            disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
            inputProps={{ id: `${id}-display-to-logged-out-users-checkbox` }}
            name="displayToLoggedOutUser"
            onBlur={handleBlur}
            onClick={(e) => {
              e.stopPropagation();
              setFieldValue('displayToLoggedOutUser', !_.get(values, 'displayToLoggedOutUser'));
            }}
            data-testid={`${id}-display-to-logged-out-users-checkbox`}
          />
          <Typography className={className} variant="subtitle1" component="h3">
            Display Plan to Logged Out Users
          </Typography>
        </div>
        <div className={`${className} checkbox-container`}>
          <Checkbox
            checked={_.get(values, 'displayPlanCard')}
            className={className}
            disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
            inputProps={{ id: `${id}-display-plan-card` }}
            name="displayPlanCard"
            onBlur={handleBlur}
            onClick={(e) => {
              e.stopPropagation();
              setFieldValue('displayPlanCard', !_.get(values, 'displayPlanCard'));
            }}
            data-testid={`${className} checkbox-container`}
          />
          <Typography className={className} variant="subtitle1" component="h3">
            Display Plan Card
          </Typography>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
