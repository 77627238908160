import { useQuery } from '@apollo/client';
import { Dropdown } from '@evgo/react-material-components';
import { Button } from '@material-ui/core';
import { Delete as DeleteIcon, PlaylistAdd as DuplicateIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listVehicleConnectors } from '../../../apollo/queries/models';
import defaultPlanElementHour from '../../../lib/fixtures/defaultPlanElementHour.json';
import { useBlurDropdown } from '../../../lib/hooks';
import { ElementHours } from './ElementHours';
import { Styled } from './styles';

export interface Props {
  className?: string;
  id?: string;
  path: string;
  readOnly?: boolean;
  showDuplicate: boolean;
}

/** Default element rate */
export const defaultPlanElement = {
  connectorType: '',
  discount: '0',
  maxDuration: '0',
  hours: [defaultPlanElementHour],
};

export const PlanElement: React.FC<Props> = ({
  className: parentClass,
  id: parentId,
  path,
  readOnly,
  showDuplicate,
}) => {
  const i = Number(_.join(_.initial(_.head(_.reverse(_.split(path, '[')))), ''));
  // const id = _.kebabCase('PlanElement');
  const elementPath = _.join(_.initial(_.split(path, '[')), '[');
  const className = 'plan-element-component';

  const { errors, handleChange, isSubmitting, setFieldValue, touched, values } = useFormikContext();
  const handleBlurDropdown = useBlurDropdown();

  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  const { data: connectorTypeOptions, loading: connectorTypeOptionsLoading } = useQuery<Query>(listVehicleConnectors);

  let shapedConnectorTypeOptions = [] as LabelValue<number>[];
  if (!connectorTypeOptionsLoading) {
    shapedConnectorTypeOptions = (connectorTypeOptions?.listVehicleConnectors?.edges || []).map((c) => ({
      // TODO: fix this
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      label: (c as any)?.connectorName,
      value: Number(c?.id),
    }));
  }

  const handleRemoveElement = useCallback(() => {
    const elements = _.get(values, elementPath);
    const value = [...elements];

    _.pullAt(value, [i]);
    setFieldValue(elementPath, value);
  }, [i, elementPath, setFieldValue, values]);

  const handleDuplicateElement = useCallback(() => {
    const elements = _.get(values, elementPath);
    const value = [...elements, _.omit(elements[i], 'connectorType')];
    setFieldValue(elementPath, value);
  }, [i, elementPath, setFieldValue, values]);

  const elementsLength = _.get(values, `${elementPath}.length`);

  return (
    <Styled id={parentId} className={customClass}>
      <header className={className}>
        <Dropdown
          className={className}
          disabled={isSubmitting || readOnly}
          error={!!(_.get(touched, `${path}.connectorType`) && _.get(errors, `${path}.connectorType`))}
          helpertext={_.get(touched, `${path}.connectorType`) && _.get(errors, `${path}.connectorType`)}
          // id={ `${id}-${i}-connector-type-input` }
          id={`uy-${i}-connectorType-input`}
          label="Connector Type *"
          labelProps={{ className }}
          name={`${path}.connectorType`}
          onBlur={handleBlurDropdown}
          onChange={handleChange}
          options={shapedConnectorTypeOptions}
          value={_.get(values, `${path}.connectorType`)}
          data-testid={`plan-elements-uy-${i}-connectorType-input`}
        />
        <div className={`${className} controls`}>
          {!readOnly && showDuplicate && (
            <Button
              className={className}
              disabled={isSubmitting}
              // id={ `${id}-${i}-header-duplicate-button` }
              id={`uy-${i}-header-duplicate-button`}
              onClick={handleDuplicateElement}
              data-testid={`plan-elements-uy-${i}-header-duplicate-button`}
            >
              <DuplicateIcon className={className} color={isSubmitting ? 'inherit' : 'primary'} />
              &nbsp;Duplicate
            </Button>
          )}

          {!readOnly && elementsLength > 1 && (
            <Button
              className={className}
              disabled={isSubmitting}
              // id={ `${id}-${i}-header-remove-button` }
              id={`uy-${i}-header-remove-button`}
              onClick={handleRemoveElement}
              data-testid={`plan-elements-uy-${i}-header-remove-button`}
            >
              <DeleteIcon className={className} color={isSubmitting ? 'inherit' : 'primary'} />
              &nbsp;Delete
            </Button>
          )}
        </div>
      </header>
      <ElementHours className={className} path={path} readOnly={!!readOnly} />
    </Styled>
  );
};
