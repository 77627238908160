/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Transforms the Formik property values into Falcon format
 */
export const transformPropertyValues = (property: any) => {
  const propertyInput = {
    address1: _.get(property, 'address1'),
    address2: _.get(property, 'address2'),
    administrativeArea: _.get(property, 'administrativeArea'),
    country: _.get(property, 'country', 'US'),
    evcId: _.get(property, 'evcId'),
    hostId: _.get(property, 'hostId'),
    locality: _.get(property, 'locality'),
    pid: _.get(property, 'pid'),
    postalCode: _.get(property, 'postalCode'),
    propertyName: _.get(property, 'propertyName'),
    status: _.get(property, 'status', 'ACTIVE'),
  };

  return propertyInput;
};
