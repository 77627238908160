import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Styled, StyledHeading, StyledPriceContent, StyledHmiDisplayContent } from './styles';
import { EditSection } from '../EditSection';
import { Box } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { theme } from '@evgo/react-material-components';
import { Card, CardHeader } from 'src/components/shared/Card';
import { events } from 'src/lib/utils/analytics-events';
import { FormPricing } from 'src/lib/helpers/transformTariffPricingForExtend';
import { formatPrice } from '../helpers';
import { FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS, useFlag } from 'src/config/featureFlag';

export interface Props {
  pricing?: FormPricing;
  showEditButton?: boolean;
}

export const DefaultPriceSection: React.FC<Props> = ({ pricing, showEditButton = true }) => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  const enableHmi = useFlag(FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS);

  const prices = pricing?.pricing;

  const hasPricing =
    prices?.perKwh ||
    prices?.perKwh === 0 ||
    prices?.perSession ||
    prices?.perSession === 0 ||
    prices?.perMinute ||
    prices?.perMinute === 0;

  const Header: React.FC<object> = (props) => {
    const title = 'Default Price';
    const subtitle = hasPricing ? 'The default price the users will pay on these chargers' : '';
    return (
      <>
        {showEditButton ? (
          <EditSection
            title={title}
            subtitle={subtitle}
            route={`/extend-plus/charger-prices/${params.altId}/pricing${isNew ? '?new=true' : ''}`}
            track={events.chargerPriceSummary.CLICKED_EDIT_DEFAULT_PRICE_BUTTON}
          >
            {props.children}
          </EditSection>
        ) : (
          <Card>
            <CardHeader title={title} subtitle={subtitle} />
            {props.children}
          </Card>
        )}
      </>
    );
  };

  return (
    <Styled>
      <Header>
        {hasPricing ? (
          <Box display="flex">
            <Box>
              <StyledHeading variant="h6">Price</StyledHeading>
              <StyledPriceContent>
                {(Boolean(prices.perKwh) || prices.perKwh === 0) && <p>${formatPrice(prices.perKwh)} per kWh</p>}
                {(Boolean(prices.perSession) || prices.perSession === 0) && (
                  <p>${formatPrice(prices.perSession)} per session</p>
                )}
                {(Boolean(prices.perMinute) || prices.perMinute === 0) && (
                  <p>${formatPrice(prices.perMinute)} per min</p>
                )}
              </StyledPriceContent>
            </Box>
            {enableHmi && (
              <Box>
                <StyledHeading variant="h6">
                  <Box pl={3}>HMI Display</Box>
                </StyledHeading>
                <StyledHmiDisplayContent>
                  <Box pl={2}>
                    {pricing?.displayName && <p>Name: {pricing?.displayName}</p>}
                    {pricing?.displayDescription1 && <p>Description 1: {pricing?.displayDescription1}</p>}
                    {pricing?.displayDescription2 && <p>Description 2: {pricing?.displayDescription2}</p>}
                    <p>
                      Rank:{' '}
                      {!pricing?.displayRank || pricing?.displayRank === 99 ? 'Not Displayed' : pricing.displayRank}
                    </p>
                  </Box>
                </StyledHmiDisplayContent>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            padding={2}
            bgcolor={theme.palette.grey['200']}
            width={520}
          >
            <Warning />
            <span>No price defined, Please add a default price for these chargers.</span>
          </Box>
        )}
      </Header>
    </Styled>
  );
};
