/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing } = theme;

export const Styled = styled(Paper)`
  section {
    .snackbar {
      position: absolute;
      z-index: 2000;
      left: calc(50% - ${spacing(8)}px);
      align-self: center;

      span {
        display: flex;
        align-items: center;
        font-weight: 200;

        svg {
          margin-right: ${spacing()}px;
        }
      }

      button {
        color: #ffffff;
      }

      &.error {
        background-color: #cb2e29;
      }

      &.success {
        background-color: #43a047;
      }
    }
  }

  .card-action-section {
    position: fixed;
    z-index: 1000;
    width: 40px;
    overflow: hidden;
    top: ${spacing(17.5)}px;
    left: ${spacing(31.125)}px;
    white-space: nowrap;
    margin: ${`0 calc(${spacing(-6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
      -6,
    )}px + var(--safe-area-inset-left))`};
    transition: all 0.5s ease;

    &.open {
      width: ${spacing(22)}px;
    }

    border-radius: 0px 4px 4px 0px;
  }

  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      .deactivate {
        color: black;
      }
      &:first-child {
        flex: 1;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;

        * {
          /* margin: 0 ${spacing()}px; */

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }

    span {
      color: ${palette.text.secondary};
    }
  }

  table {
    tbody {
      tr {
        th,
        td {
          padding: ${`${spacing() / 2}px ${spacing(2)}px`};
          a {
            color: ${config.colors.accentBlue};
            text-decoration: none;
            font-weight: 400;
          }
        }

        &.filler-row {
          :not(:last-of-type) {
            td {
              border-bottom-color: transparent;
            }
          }
        }
      }
    }

    tfoot {
      tr {
        position: relative;
        height: ${spacing(8.5)}px;

        td {
          padding: ${`${spacing() / 2}px ${spacing(2)}px`};

          .select {
            padding-right: 24px;
            text-align: right;
          }
        }
      }
    }
  }
`;
