import { FormHelperText, InputAdornment, Switch, TextField, Typography } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { removeThirdDecimal } from 'src/lib/helpers';
import { Tooltip } from '../Tooltip';
import { Styled, StyledBox, StyledFormControl, StyledFormControlLabel, StyledInputContainer } from './styles';

enum ReservationFee {
  Reservation = 'fee',
  NoShow = 'noShowFee',
  Enabled = 'enabled',
}

export type ReservationFeeInputProps = {
  id?: string;
  name?: string;
  className?: string;
  readonly?: boolean;
};

const types = [
  {
    type: ReservationFee.Reservation,
    label: 'Reservation Fee',
  },
  {
    type: ReservationFee.NoShow,
    label: 'No Show Fee',
  },
];
export const ReservationFeeInput: React.FC<ReservationFeeInputProps> = (props) => {
  const { id = 'reservation-term', className = 'ReservationFeeInput', name = 'reservationTerm' } = props;
  const [field, meta, helpers] = useField({ name, type: 'number' });

  const { value, onBlur, onChange } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;
  const hasError = Boolean(error && touched);

  const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setValue({ ...value, [ReservationFee.Enabled]: e.target.checked });
  };

  // Format price input to only have 2 decimals, no alpha characters, and set formatted value
  const handlePriceFormatAndChange = (type: ReservationFee) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueAlphaRemoved = e.target.value.replace(/[^\d.-]/g, '');

    if (valueAlphaRemoved === '.') {
      setValue({ ...value, [type]: '0.' });
      return;
    }

    if ((valueAlphaRemoved.match(/\./g) || []).length > 1) {
      setValue({ ...value, [type]: valueAlphaRemoved.slice(0, -1) });
      return;
    }

    const updatedValue =
      valueAlphaRemoved.indexOf('.') === valueAlphaRemoved.length - 1 ||
      valueAlphaRemoved.substring(valueAlphaRemoved.indexOf('.'), valueAlphaRemoved.length).length <= 2
        ? valueAlphaRemoved
        : removeThirdDecimal(valueAlphaRemoved);

    setValue({ ...value, [type]: updatedValue });
  };

  const handlePriceBlur = (type: ReservationFee) => (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    if (e.target.value === '') return;
    const zeroPaddedValue = Number(e.target.value).toFixed(2);
    setValue({ ...value, [type]: zeroPaddedValue });
  };

  const ReservationInput = ({ type = ReservationFee.Reservation, label = '' }) => {
    const { setFieldValue } = useFormikContext<object>();
    useEffect(() => {
      const displayValue = Number(value?.[type]).toFixed(2);
      setFieldValue(`${name}.${type}`, displayValue);
    }, [type, setFieldValue]);

    if (!value?.[ReservationFee.Enabled]) return null;

    if (props.readonly) {
      return (
        (value?.[type] || value?.[type] === '') &&
        typeof value[type] !== 'boolean' && <Typography> - ${value[type]}</Typography>
      );
    }
    return (
      <TextField
        id={`${id}-${type}-input`}
        type="text"
        label={label}
        name={`${name}.${type}`}
        onBlur={handlePriceBlur(type)}
        onChange={handlePriceFormatAndChange(type)}
        value={typeof value[type] !== 'boolean' ? value[type] : ''}
        data-testid={`${id}-${type}-input`}
        variant="outlined"
        error={touched && (!value?.[type] || value?.[type] === true)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    );
  };

  const EnableFeesSwitch = () => {
    const switchComponent = (
      <Switch
        onChange={handleSwitch}
        checked={value?.[ReservationFee.Enabled] ?? false}
        value={value?.[ReservationFee.Enabled] ?? false}
        id={`${id}-${ReservationFee.Enabled}`}
        name={`${name}.${ReservationFee.Enabled}`}
        data-testid={`${id}-${ReservationFee.Enabled}-input`}
      />
    );

    return switchComponent;
  };

  const tooltipText = (
    <>
      {'• Reservation Fee - fee users will pay when reserving this charger in advance (20 mins max.)'}
      <br />
      {'• No Show Fee - fee if the user does not show up for their reservation.'}
    </>
  );

  return (
    <Styled>
      <StyledFormControl className={className} error={hasError}>
        <StyledBox>
          <StyledFormControlLabel control={EnableFeesSwitch()} label="Add Reservation Fees" />
          <Tooltip content={tooltipText} />
        </StyledBox>
        <StyledInputContainer readonly={props.readonly}>
          {types.map(({ type, label }) => {
            if (props.readonly) return null;

            return (
              <StyledBox key={type} data-testid={`${id}-${type}`}>
                {ReservationInput({ type, label })}
              </StyledBox>
            );
          })}
        </StyledInputContainer>
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </StyledFormControl>
    </Styled>
  );
};
