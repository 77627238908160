import { theme } from '@evgo/react-material-components';
import { AppBar } from '@material-ui/core';
import styled from 'styled-components';

export const Styled = styled(AppBar)`
  &.app-bar {
    position: relative;
    width: 100%;
    color: black;
    background: white;
    z-index: 1;

    .MuiTabs-flexContainer {
      gap: ${theme.spacing(2)}px;
    }

    > div {
      margin: 0;
    }

    button {
      border: none !important;
      &[aria-selected='true'] {
        background: white !important;
        transition: all 0.2s ease;
        border-bottom: none;
      }
    }
  }
`;
