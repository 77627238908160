import Yup from 'src/yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('You must enter a pricing name')
    .max(100, 'Name may only contain a maximum of 100 characters'),
  displayName: Yup.string().trim().max(14, 'Display name may only contain a maximum of 14 characters').nullable(),
  chargerGroupIds: Yup.array().required('You must select at least one charger group'),
});
