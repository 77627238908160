import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRoles } from 'src/lib/hooks/useRoles';
import { OperatorPortalRole, RoleApp } from 'src/@types/shared';

export const DefaultRedirect = () => {
  const { hasRole, loading } = useRoles();
  const isPartnerAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.PartnerAdmin);

  if (loading) {
    return null;
  }

  const defaultRoute = isPartnerAdmin ? '/extend-plus/dashboard' : '/chargers/list';

  return <Navigate to={defaultRoute} replace />;
};
