import { Dropdown, TextInfo } from '@evgo/react-material-components';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Connector } from 'src/@types';
import { useBlurDropdown } from '../../../../../../../lib/hooks';
import { initialValues } from '../../../initialValues';
import { Styled } from './styles';

export interface Props {
  connectorIndex: number;
  portIndex: number;
  id?: string;
  className?: string;
}

/**
 * Charger connector specs tab component
 */
export const ChargerConnectorSpecsTab: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerConnectorSpecsTab');
  const className = id;
  const { className: parentClass, id: parentId, connectorIndex: j, portIndex: i } = props;
  const { handleChange, values, handleBlur, errors } = useFormikContext<typeof initialValues>();
  const handleBlurDropdown = useBlurDropdown();
  let customClass = className;

  const connectorPlacements = [
    { label: 'Left', value: 'LEFT' },
    { label: 'Right', value: 'RIGHT' },
  ];
  const connectorTypes = [
    { label: 'Sidekick', value: 'SIDEKICK' },
    { label: 'Adapter', value: 'ADAPTER' },
  ];

  let maxConnectorPowerError = '';
  let teslaInstallationDateError = '';

  const evsesEdges = errors?.evses?.edges;

  type Err = Exclude<typeof evsesEdges, undefined | string | string[]>;
  if ((evsesEdges as Err)?.[i]?.connectors?.edges?.[j]) {
    teslaInstallationDateError = ((evsesEdges as Err)?.[i]?.connectors?.edges?.[j] as FormikErrors<Connector>)
      ?.teslaInstallationDate as string;
    maxConnectorPowerError = ((evsesEdges as Err)?.[i]?.connectors?.edges?.[j] as FormikErrors<Connector>)
      ?.maxConnectorPower as string;
  }

  const teslaInstallationDate = !_.isEmpty(
    _.get(values.evses.edges[i].connectors.edges[j], 'teslaInstallationDate', ''),
  )
    ? moment(_.get(values.evses.edges[i].connectors.edges[j], 'teslaInstallationDate', ''))
        .tz('UTC')
        .format('YYYY-MM-DD')
    : '';

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <Styled id={parentId} className={`${customClass} connector-specs-container`}>
      <Dropdown
        className={`${className}`}
        displayValue={_.get(values, `evses.edges[${i}].connectors.edges[${j}].connectorPlacement`)}
        id={`${id}-${i}-${j}-connector-placement`}
        label="Connector Placement"
        labelProps={{ className }}
        name={`evses.edges[${i}].connectors.edges[${j}].connectorPlacement`}
        options={connectorPlacements}
        onBlur={handleBlurDropdown}
        onChange={handleChange}
        value={_.get(values, `evses.edges[${i}].connectors.edges[${j}].connectorPlacement`, '')}
      />
      <TextInfo
        id={`${id}-${i}-${j}-max-connector-power`}
        className={`${className} form-control`}
        adornmentEnd={_.get(values.evses.edges[i].connectors.edges[j], 'maxConnectorPower', '') ? 'kW' : null}
        label="Max Connector Power"
        name={`evses.edges[${i}].connectors.edges[${j}].maxConnectorPower`}
        onChange={handleChange}
        labelProps={{ className }}
        value={_.get(values.evses.edges[i].connectors.edges[j], 'maxConnectorPower', '')}
        onBlur={handleBlur}
        error={!!maxConnectorPowerError}
        helpertext={maxConnectorPowerError}
      />
      <Dropdown
        className={`${className}`}
        displayValue={_.get(values.evses.edges[i].connectors.edges[j], 'warehouse.warehouseName')}
        id={`${id}-${i}-${j}-tesla-connector-type`}
        label="Tesla Connector Type"
        labelProps={{ className }}
        name={`evses.edges[${i}].connectors.edges[${j}].teslaConnectorType`}
        options={connectorTypes}
        onBlur={handleBlurDropdown}
        onChange={handleChange}
        value={_.get(values.evses.edges[i].connectors.edges[j], 'teslaConnectorType', '')}
      />
      <TextInfo
        id={`${id}-${i}-${j}-unique-connector-id`}
        className={`${className} form-control`}
        label="Unique Connector ID"
        name={`evses.edges[${i}].connectors.edges[${j}].uniqueConnectorId`}
        onChange={handleChange}
        labelProps={{ className }}
        value={_.get(values.evses.edges[i].connectors.edges[j], 'uniqueConnectorId', '')}
        onBlur={handleBlur}
      />
      <TextInfo
        id={`${id}-${i}-${j}-legacy-connector-id`}
        className={`${className} form-control`}
        label="Legacy Connector ID"
        name={`evses.edges[${i}].connectors.edges[${j}].legacyConnectorId`}
        onChange={handleChange}
        labelProps={{ className }}
        value={_.get(values.evses.edges[i].connectors.edges[j], 'legacyConnectorId', '')}
        onBlur={handleBlur}
      />
      <TextInfo
        id={`${id}-${i}-${j}-tesla-serial-number`}
        className={`${className} form-control`}
        label="Tesla Serial #"
        name={`evses.edges[${i}].connectors.edges[${j}].teslaSerialNumber`}
        onChange={handleChange}
        labelProps={{ className }}
        value={_.get(values.evses.edges[i].connectors.edges[j], 'teslaSerialNumber', '')}
        onBlur={handleBlur}
      />
      <TextInfo
        id={`${id}-${i}-${j}-evse-connector-number`}
        className={`${className} form-control`}
        label="EVSE #"
        name={`evses.edges[${i}].evseId`}
        disabled={true}
        labelProps={{ className }}
        value={_.get(values.evses.edges[i], 'evseId', '')}
      />
      <Dropdown
        className={`${className}`}
        displayValue={_.get(values.evses.edges[i].connectors.edges[j], 'reservable')}
        id={`${id}-${i}-${j}-reservable`}
        label="Reservable"
        labelProps={{ className }}
        name={`evses.edges[${i}].connectors.edges[${j}].reservable`}
        options={[]}
        onBlur={handleBlurDropdown}
        onChange={handleChange}
        value={_.get(values.evses.edges[i].connectors.edges[j], 'reservable', '')}
      />
      <TextInfo
        className={className}
        error={!!teslaInstallationDateError}
        helpertext={teslaInstallationDateError}
        id={`${id}-tesla-installation-date`}
        label="Tesla Installation Date"
        labelProps={{ className: `${className} prefilled` }}
        name={`evses.edges[${i}].connectors.edges[${j}].teslaInstallationDate`}
        onBlur={handleBlur}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(e);
        }}
        type="date"
        value={teslaInstallationDate}
      />
    </Styled>
  );
};
