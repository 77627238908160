import { ApolloError } from '@apollo/client';
import { Table, TableHead, TableRow, TableCell as MUITableCell, TableBody, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { SubTitle } from 'src/components/shared/SubTitle';
import { PriceCardGroup, PriceCardGroupProps } from './PriceCardGroup';
import { EmptyRows, rowHeight, dayHours, weekDays } from './helpers';

type Props = {
  id?: string;
  'data-testid'?: string;
  className?: string;
  title?: string;
  subTitle?: string;
  stickyHeader?: boolean;
  data: PriceCardGroupProps[];
  loading: boolean;
  error?: ApolloError;
};

export const TableCell = styled(MUITableCell)`
  border: 1px solid ${theme.palette.grey[300]};
`;

const TableSpan = styled.span`
  display: block;
  text-align: center;
  margin: 0 auto;
`;

export const WeekView = ({ id, className, title, subTitle, loading, error, data, ...props }: Props) => {
  return (
    <>
      <Typography variant="h6" data-testid="page-title">
        {title}
      </Typography>
      <SubTitle>{subTitle}</SubTitle>
      {loading || error ? (
        <EmptyRows loading={loading} error={error} />
      ) : (
        <Box position="relative">
          {data.map((priceGroup, index) => (
            <PriceCardGroup key={`price-group-${index}`} {...priceGroup}></PriceCardGroup>
          ))}
          <Table {...props} className={clsx('week-table', className)}>
            <TableHead>
              <TableRow>
                <TableCell padding="none" width="12.5%" height={`${theme.spacing(rowHeight)}px`}></TableCell>
                {weekDays.map((column, index) => (
                  <TableCell
                    padding="none"
                    align="left"
                    key={`day-of-week-${index}`}
                    data-testid={`${id}-day-of-week-${index}`}
                    className={clsx(`day-of-week`, className)}
                    component="th"
                    width="12.5%"
                    height={`${theme.spacing(rowHeight)}px`}
                  >
                    <TableSpan>{column.substring(0, 3)}</TableSpan>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dayHours.map((row, indexHour) => (
                <TableRow key={`hour-of-day-${indexHour}`}>
                  <TableCell
                    padding="none"
                    data-testid={`${id}-hour-of-day-${indexHour}`}
                    width="12.5%"
                    height={`${theme.spacing(rowHeight)}px`}
                  >
                    <TableSpan>{indexHour ? row : '00:00 AM'}</TableSpan>
                  </TableCell>
                  {weekDays.map((__, indexDay) => (
                    <TableCell
                      padding="none"
                      align="left"
                      key={`hour-of-day-${indexHour}-day-of-week-${indexDay}`}
                      data-testid={`${id}-hour-of-day-${indexHour}-day-of-week-${indexDay}`}
                      className={clsx(`hour-of-day-day-of-week`, className)}
                      width="12.5%"
                      height={`${theme.spacing(rowHeight)}px`}
                    ></TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
};
