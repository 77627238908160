import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { HostsWithMeta, Query } from 'src/@types';
import { FetchMore, MatOnChangePageEvent } from 'src/@types/shared';
import { listHosts } from '../../../../apollo/queries/hosts';
import { sanitizeSearch, titleCase, updateQuery } from '../../../../lib/helpers';
import { ListSearch as HostsListSearch } from '../../../shared/ListSearch';
import { HostsListFilters } from '../HostsListFilters';
import { Styled as StyledPaper } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
export interface Props {
  className?: string;
}

const searchFields = ['hid', 'hostName', 'address1', 'locality', 'administrativeArea', 'postalCode'];

/**
 * Changes hosts list page
 */
export const onChangePage =
  (fetchMore: FetchMore, metadata: HostsWithMeta) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    const { pageSize, sort, filter, search } = metadata;
    return fetchMore({
      updateQuery,
      variables: {
        hostsInput: {
          page,
          pageSize,
          filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
          sort: _.pickBy(_.pick(sort, ['hostName', 'status', 'hid', 'altId']), (i) => i !== null),
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Changes hosts list page size
 */
export const onRowsPerPageChange =
  (fetchMore: FetchMore, metadata: HostsWithMeta) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ReturnType<FetchMore> => {
    const { sort, filter, search } = metadata;
    return fetchMore({
      updateQuery,
      variables: {
        hostsInput: {
          page: 0,
          pageSize: event.target.value,
          filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
          sort: _.pickBy(_.pick(sort, ['hostName', 'status', 'hid', 'altId']), (i) => i !== null),
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Sorts hosts list
 */
export const onSortClick = (fetchMore: FetchMore, metadata: HostsWithMeta, id: string) => (): ReturnType<FetchMore> => {
  const { pageSize, filter, search } = metadata;
  let sort = { [id]: 'ASC' };

  if (_.get(metadata, `sort.${id}`, 'ASC') === 'ASC') sort = { [id]: 'DESC' };

  return fetchMore({
    updateQuery,
    variables: {
      hostsInput: {
        page: 0,
        pageSize,
        sort,
        filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
        search: sanitizeSearch(search, searchFields),
      },
    },
  });
};

/**
 * Filters hosts list
 */
export const onFilterChange = (
  fetchMore: FetchMore,
  metadata: HostsWithMeta,
  updatedFilters: string[],
  type: string,
): ReturnType<FetchMore> => {
  const { pageSize, sort, search } = metadata;

  const filter = updatedFilters.length
    ? {
        [type]: {
          in: updatedFilters,
        },
      }
    : null;

  return fetchMore({
    updateQuery,
    variables: {
      hostsInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        search: sanitizeSearch(search, searchFields),
        filter,
      },
    },
  });
};

/**
 * Search hosts list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: HostsWithMeta,
  target: EventTarget & HTMLInputElement,
): ReturnType<FetchMore> => {
  const { pageSize, sort, filter } = metadata;

  let search: Record<string, Record<string, string>> | null = {};
  if (target.value.length) {
    searchFields.forEach((field) => {
      search = search || {};
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      hostsInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
        search,
      },
    },
  });
};

/**
 * Hosts List view component
 */
export const HostsList: React.FC<Props> = (props) => {
  const className = 'HostsList';
  const { data, fetchMore, loading } = useQuery<Query>(listHosts, {
    fetchPolicy: 'network-only',
    variables: {
      propertiesInput: { page: 0, pageSize: 999 },
      sitesInput: { page: 0, pageSize: 999 },
      hostsInput: { sort: { hostName: 'ASC' } },
    },
  });
  const { setModalState } = useModalContext();
  const { edges, ...metadata } = data?.listHosts || {};
  const debouncedSearchChange = (
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : onSearchChange
  ) as typeof onSearchChange;
  const debouncedFetchMore = (
    typeof fetchMore === 'function' ? _.debounce(fetchMore, 500) : fetchMore
  ) as typeof fetchMore;

  const columns = [
    { id: 'hostName', label: 'Host Name' },
    { id: 'status', label: 'Status' },
    { id: 'hid', label: 'Host ID' },
    { id: 'totalSites', label: 'Sites Owned' },
    { id: 'totalChargers', label: 'Total Chargers' },
  ];
  const rows = edges?.map((edge) => ({
    hostName: {
      value: _.get(edge, 'hostName', ''),
      to: `/hosts/${_.get(edge, 'altId')}/profile`,
    },
    hid: { value: _.toUpper(edge?.hid || '') },
    status: { value: titleCase(_.get(edge, 'status', 'UNKNOWN')) },
    totalSites: {
      value: _.reduce(
        _.get(edge, 'properties.edges', []),
        (siteTotal, property) => {
          return siteTotal + _.get(property, 'sites.total', 0);
        },
        0,
      ),
    },
    totalChargers: {
      value: _.reduce(
        _.get(edge, 'properties.edges', []),
        (siteTotal, property) => {
          return (
            siteTotal +
            _.reduce(
              _.get(property, 'sites.edges', []),
              (chargerTotal, site) => {
                return chargerTotal + _.get(site, 'chargers.total', 0);
              },
              0,
            )
          );
        },
        0,
      ),
    },
  }));

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            Hosts
          </Typography>

          <Typography className={className} variant="caption">
            Currently viewing {metadata.total}
          </Typography>
        </div>

        <div className={className}>
          <Button
            className={className}
            color="secondary"
            onClick={() =>
              setModalState({
                modalName: 'CreateHostModal',
                modalVisible: true,
                modalProps: {},
              })
            }
            variant="contained"
            data-testid="create-extend-host-modal-component modal-primary-button"
          >
            Add Host
          </Button>
          <HostsListSearch
            className={`${className} search`}
            type="host"
            onSearchChange={(search) => debouncedSearchChange(fetchMore, metadata, search)}
          />
        </div>
      </header>

      <HostsListFilters
        className={`${className} filters`}
        fetchMore={debouncedFetchMore}
        metadata={metadata}
        onFilterChange={(parentFetchMore: FetchMore, parentMetadata: HostsWithMeta, filters: string[], type: string) =>
          onFilterChange(parentFetchMore, parentMetadata, filters, type)
        }
      />

      <Divider />

      <Table className={className} data-testid="host-management-table">
        <TableHead className={className}>
          <TableRow className={className}>
            {columns.map((column) => (
              <TableCell key={column.id} className={className}>
                <TableSortLabel
                  active={_.isString(_.get(metadata, `sort.${column.id}`))}
                  direction={(_.toLower(_.get(metadata, `sort.${column.id}`, 'asc')) || 'asc') as 'asc'}
                  onClick={onSortClick(fetchMore, metadata, column.id)}
                  disabled={!['hid', 'hostName', 'status'].includes(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={className}>
          {!loading &&
            rows?.map((row, key) => (
              <TableRow key={key} className={className}>
                {columns.map((column, i) => {
                  const rowCol = row[column.id as keyof typeof row] as unknown as { to: string; value: string };

                  return (
                    <TableCell key={i} className={className}>
                      {rowCol?.to ? (
                        <Link className={className} to={rowCol?.to}>
                          {rowCol.value}
                        </Link>
                      ) : (
                        rowCol.value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>

        <TableFooter className={className}>
          <TableRow className={className}>
            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={onChangePage(fetchMore, metadata)}
              onRowsPerPageChange={onRowsPerPageChange(fetchMore, metadata)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};
