import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, spacing } = theme;

export const Styled = styled.div`
  display: flex;
  width: calc(100% - ${spacing(2)}px);
  padding: ${`${spacing(2)}px ${spacing()}px 0`};

  h3,
  h6 {
    color: ${palette.text.secondary};
  }

  button {
    height: ${spacing(5)}px;
    margin-left: auto;

    svg {
      &.left {
        margin-right: ${spacing()}px;
      }
    }
  }
`;
