/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;

export const Styled = styled.div`
  .panel-actions {
    padding: ${spacing(3)}px;
    background-color: #ffffff;
    flex-direction: row;

    button {
      svg {
        &.left {
          margin-right: ${spacing()}px;
        }
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: ${`${spacing(2.5)}px ${spacing(3.25)}px`};

    h2 {
      color: ${config.colors.accentBlue};
    }

    button {
      margin: -${spacing()}px;
    }
  }

  .select {
    margin: 0px 10px;
    font-size: 14px;
    min-width: 200px;
    max-width: 250px;

    &::before {
      content: '';
      border-bottom: none;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: ${`${spacing(2.5)}px ${spacing(3.25)}px`};

    div {
      .half {
        width: calc(50% - ${spacing(2)}px);
        margin: ${spacing()}px;
      }
    }

    p {
      font-weight: 300;
      margin-bottom: ${spacing(2)}px;
    }

    hr {
      width: calc(100% + ${spacing(6)}px);
      margin-left: -${spacing(3)}px;
    }

    .hidden {
      display: none;
    }
  }
`;
