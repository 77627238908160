import React from 'react';
import { Card } from 'src/components/shared/Card';
import { Box, CircularProgress } from '@material-ui/core';

type MetricCardPlaceholderProps = {
  height?: number;
  'data-testid'?: string;
};

export function MetricCardPlaceholder({ height = 60, ...props }: MetricCardPlaceholderProps) {
  return (
    <Card className="MetricCardPlaceholder-root" {...props}>
      <Box height={height} display="flex" justifyContent="center" alignItems="center" m="auto">
        <CircularProgress />
      </Box>
    </Card>
  );
}
