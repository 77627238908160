import { gql } from '@apollo/client';

export const createCard = gql`
  mutation createCard($input: CreateCardInput!) {
    createCard(input: $input) {
      altId
      cardStatus
      activatedAt
    }
  }
`;
