import React from 'react';
import { Grid } from '@material-ui/core';
import { EvStation, OfflineBolt } from '@material-ui/icons';

import { MetricCard } from 'src/components/shared/MetricCard';
import { Evse } from '../helpers';

export type StatusMetricCardsProps = {
  data: Array<Evse>;
};

export const StatusMetricCards: React.FC<StatusMetricCardsProps> = ({ data }) => {
  let evsesInUse = 0;
  let evsesOnline = 0;
  data?.forEach((evse) => {
    if (evse?.connectorStatus?.some((connStatus) => connStatus === 'Charging')) {
      evsesInUse += 1;
    }
    if (
      evse?.connectorStatus?.some(
        (connStatus) => connStatus === 'Available' || connStatus === 'Charging' || connStatus === 'Occupied',
      )
    ) {
      evsesOnline += 1;
    }
  });
  const totalEvses = data?.length || 0;

  return (
    <>
      <Grid item>
        <MetricCard
          className="metric-card"
          icon={<OfflineBolt />}
          primaryText={evsesInUse.toString()}
          secondaryText={`out of ${totalEvses}`}
          label="Stalls In Use"
          data-testid="MetricCard-evses-in-use"
        />
      </Grid>
      <Grid item>
        <MetricCard
          className="metric-card"
          icon={<EvStation />}
          primaryText={evsesOnline.toString()}
          secondaryText={`out of ${totalEvses}`}
          label="Stalls Online"
          data-testid="MetricCard-evses-online"
        />
      </Grid>
    </>
  );
};
