import moment from 'moment-timezone';

/**
 * A helper to format only the date from a timestamp
 */
export const formatDate = (timestamp: string | null, format = 'L', tz = '') => {
  let localTime = '';
  if (tz)
    localTime = moment(timestamp)
      .tz(tz)
      .format(format || 'L');
  return localTime ? localTime : !timestamp ? '' : moment(timestamp.split('T')[0]).format(format);
};
