import { theme } from '@evgo/react-material-components';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const { spacing } = theme;

export const Subtitle = styled(Typography)`
  opacity: 0.6;
`;

export const StyledMenuItem = styled(MenuItem)`
  opacity: 1;
  cursor: default !important;
  pointer-events: auto !important;

  input {
    padding: ${spacing(2)}px;
    font-size: ${theme.typography.fontSize}px;
  }

  svg {
    padding-right: ${spacing(1)}px;
    color: ${theme.palette.grey[600]};
  }
`;

export const StyledFormControl = styled(FormControl)`
  > div {
    margin-bottom: ${spacing()}px;
  }
`;

export const ChipWrapper = styled(Box)`
  margin-top: ${spacing()}px;
  > div {
    margin: 0 ${spacing()}px ${spacing()}px 0;
  }
`;

type Options = {
  value: string;
  label: string;
  secondaryValue?: string;
};

export type MultiSelectWithChipsInputProps = {
  name: string;
  label: string;
  options: Options[];
  loading?: boolean;
  selectedOptions?: string[];
  readonly?: boolean;
};

export const MultiSelectWithChipsInput: React.FC<MultiSelectWithChipsInputProps> = ({
  label,
  name,
  loading,
  options,
  selectedOptions = [],
  readonly = false,
}) => {
  const id = 'mswc-input';

  const [typed, setTyped] = useState<string>('');
  const [field, meta, helpers] = useField(name);
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { value = [], onBlur, onChange } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;
  const hasError = Boolean(error && touched);

  const getTotalSelected = () =>
    options
      .filter((group) => value.includes(group.value))
      .reduce((total, group) => total + Number(group.secondaryValue), 0);

  const handleDelete = (toDelete: string) => (e: React.MouseEvent) => {
    e.preventDefault();

    const values = value.filter((v: string) => v !== toDelete);
    setValue(values);
  };

  return (
    <div>
      <div>
        <StyledFormControl error={hasError}>
          {!readonly && (
            <Select
              data-testid={`${id}-select`}
              multiple
              name={name}
              variant="outlined"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onClose={() => setTyped('')}
              renderValue={() => label}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
            >
              <StyledMenuItem value="" disabled disableRipple>
                <SearchIcon />
                <input
                  data-testid={`${id}-options-search`}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  autoFocus
                  type="text"
                  value={typed}
                  placeholder="Search"
                  onChange={(e) => {
                    setTyped(e.target.value);
                    setTimeout(() => {
                      inputRef?.current?.focus();
                    }, 100);
                  }}
                  ref={inputRef}
                ></input>
              </StyledMenuItem>
              {options
                .filter((option) => option.label.toLocaleLowerCase().includes(typed.toLocaleLowerCase()))
                .map((option, index) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-testid={`${id}-menu-item-${index}`}
                    disabled={selectedOptions.includes(option.value)}
                  >
                    <Checkbox
                      className={'option-checkbox'}
                      data-testid={`${id}-checkbox-${index}`}
                      checked={selectedOptions.includes(option.value) || value.includes(option.value)}
                      disabled={selectedOptions.includes(option.value)}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
            </Select>
          )}
          {loading && (
            <Box>
              <CircularProgress size={20} />
            </Box>
          )}

          <FormHelperText data-testid={`${id}-helper-text`}>
            {hasError ? (
              error
            ) : (
              <Typography variant="caption">Total Selected Chargers - {getTotalSelected()}</Typography>
            )}
          </FormHelperText>
        </StyledFormControl>
      </div>

      <ChipWrapper>
        {options
          .filter((option) => value.includes(option.value))
          .map((option, index) => (
            <Chip
              data-testid={`${id}-chip-${index}`}
              key={option.value}
              label={option.secondaryValue ? `${option.label} (${option.secondaryValue})` : option.label}
              clickable={!readonly}
              onDelete={!readonly ? handleDelete(option.value) : undefined}
              size="small"
            />
          ))}
      </ChipWrapper>
    </div>
  );
};
