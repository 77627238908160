/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};
  padding-bottom: ${spacing(9)}px;

  .title-wrapper {
    padding: ${spacing(9)}px 0px;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-container > h6 {
    text-align: right;
  }
`;
