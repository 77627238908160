import { Typography } from '@material-ui/core';
import React from 'react';
import { Styled } from './styles';

export interface Props {
  children: React.ReactNode;
  title: string;
  center?: boolean;
  column?: boolean;
  id?: string;
  className?: string;
}

/**
 * Charger connector info component
 */
export const ChargerConnectorInfo: React.FC<Props> = (props) => {
  const { center, children, className: parentClass, column, id: parentId, title } = props;
  const className = 'ChargerConnectorInfo';
  let customClass = className;
  let spanClass = className;

  if (center) spanClass += ' center';
  if (column) spanClass += ' column';
  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <Styled className={customClass}>
      <Typography className={className} variant="h6" component="h3">
        {title}
      </Typography>
      <span id={parentId} className={spanClass}>
        {children}
      </span>
    </Styled>
  );
};
