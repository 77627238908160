import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  section {
    header {
      padding: ${`0 ${spacing(3)}px`};
    }
  }

  div {
    &.container {
      display: flex;
      flex: 1 1 100%;

      .controls {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 20px;
      }
    }

    &.vehicle-list {
      max-height: 534px;
      overflow: auto;
    }
  }

  .vehicle-list {
    padding: 0;
  }

  h2 {
    color: ${colors.accentBlue};
  }

  table {
    td,
    th {
      padding: ${spacing(2)}px;

      &:first-child {
        padding-left: ${spacing(3)}px;
      }

      &:last-child {
        padding-right: ${spacing(3)}px;
      }
    }

    td {
      position: relative;
    }

    th {
      &.internal {
        width: 10%;
      }

      &.external {
        width: 10%;
      }

      &.brand {
        width: 10%;
      }

      &.assignee {
        width: 10%;
      }

      &.cardStatus {
        width: 10%;
      }

      &.updatedAt {
        width: 10%;
      }

      &.actions {
        width: 1%;
      }
    }
  }
`;
