import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, spacing, typography, text } = theme;
const { fontSize } = typography;

export const Styled = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: ${`${spacing(4)}px ${spacing(8)}px 0px`};
  background-color: ${palette.primary[50]};
  color: ${config.colors.accentBlue};
  font-size: ${fontSize * (6 / 7)}px;
  z-index: 10;

  .info {
    display: flex;
    flex-direction: row;
    padding-top: ${spacing(1.5)}px;
    article {
      display: flex;
      flex-direction: column;

      label {
        font-size: ${typography.fontSize - 2}px;
        color: ${text.labelEmphasis.medium};
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: ${spacing(1.25)}px;
        width: auto;

        p {
          flex: 1 1 auto;
          font-size: ${typography.fontSize}px;
          font-weight: ${typography.fontWeightRegular};
          color: ${text.surfaceEmphasis.medium};
          margin-right: ${spacing()}px;
        }
      }
    }
  }

  a {
    position: absolute;
    top: 0;
    left: ${spacing(7)}px;
    color: ${config.brandColor};
    font-size: inherit;
    text-transform: none;

    svg {
      width: 1rem;
      height: 1rem;
      fill: ${palette.secondary.main};
    }
  }

  h5 {
    display: flex;
    align-content: center;
    align-items: center;
    color: ${config.brandColor};

    svg {
      height: 1.5rem;
    }
  }
`;
