import clsx from 'clsx';
import { TextInfo } from '@evgo/react-material-components';
import { Checkbox, InputLabel } from '@material-ui/core';
import { Form, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { StyledModal } from '../../../../shared/StyledModal';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import { Driver } from 'src/@types';

interface Props {
  open?: boolean;
  className?: string;
  shouldVerifyPhone: boolean;
  setShouldVerifyPhone: (value: boolean) => void;
}

export interface FormValues {
  email: string;
  setPhoneNumber?: string | undefined;
}

export const initialValues = {
  email: '',
  setPhoneNumber: undefined,
};

export const AccountSyncModalForm: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountSyncModal');
  const className = id;
  const { shouldVerifyPhone, setShouldVerifyPhone } = props;
  const {
    isValid,
    values,
    handleChange,
    isSubmitting,
    setValues,
    errors,
    submitCount,
    resetForm,
    validateForm,
    setFieldValue,
    submitForm,
  } = useFormikContext<FormValues>();

  const { modalName, modalProps, modalVisible, setModalState } = useModalContext();
  const [phoneField, phoneFieldMeta] = useField('setPhoneNumber');
  const valuePhone = values.setPhoneNumber;

  useEffect(() => {
    const email = modalProps && modalProps.driver && (modalProps.driver as Driver).email;
    setValues({ email: email as string, setPhoneNumber: submitCount > 0 ? valuePhone : undefined }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps && modalProps.driver && (modalProps.driver as Driver).email]);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldVerifyPhone]);

  const handleCheckbox = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    setShouldVerifyPhone(!value);
    setFieldValue('setPhoneNumber', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelClick = useCallback(() => {
    setModalState({ modalName: '', modalProps: {}, modalVisible: false });
    resetForm({ status: initialValues, values: initialValues });
    setShouldVerifyPhone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldModalOpen = modalProps && modalVisible && modalName === 'AccountSyncModal';
  if (!shouldModalOpen) {
    return null;
  }
  const isSubmitDisabled =
    isSubmitting || (!!shouldVerifyPhone && (!isValid || valuePhone === undefined || !!errors.setPhoneNumber));

  return (
    <StyledModal
      className={clsx(className, 'modal')}
      onClose={onCancelClick}
      title="Pull latest Driivz data into Falcon"
      primaryButtonText="PULL DATA"
      primaryButtonDisabled={isSubmitDisabled}
      onClick={submitForm}
      secondaryButtonClick={onCancelClick}
      secondaryButtonText="Cancel"
      open={!!props.open}
    >
      <Styled className={className}>
        <Form id={`${id}-form`} className={className}>
          <div className={className}>
            <div className={className}>
              <TextInfo
                className={clsx(className, 'half', 'disabled')}
                id={`${id}-sync-account-driver-email`}
                data-testid={`${className}-email-input`}
                disabled
                value={_.get(modalProps, 'driver.email')}
                label="Driver Email Address"
                labelProps={{ className }}
                onChange={handleChange}
              />
              {!!shouldVerifyPhone ? (
                <TextInfo
                  className={`${className} half`}
                  data-testid={`${className}-phone-input`}
                  id={`${id}-sync-account-driver-phone`}
                  {...phoneField}
                  value={values.setPhoneNumber}
                  label="* Driver Phone Number"
                  labelProps={{ className }}
                  disabled={isSubmitting}
                  error={!!phoneFieldMeta.error && valuePhone !== undefined}
                />
              ) : (
                <div className={`${className} half`}></div>
              )}
              {shouldVerifyPhone && (
                <div
                  id={`${id}-feedback`}
                  className={`${className} input-feedback`}
                  style={{
                    float: 'right',
                    marginRight: '10px',
                    color: phoneFieldMeta.error ? '#F54E42' : '#44A047',
                  }}
                >
                  {phoneFieldMeta.error ? phoneFieldMeta.error : '✓'}
                </div>
              )}
            </div>
            <div className={`${className} checkbox-wrapper`}>
              <InputLabel className={`${className} checkbox`}>
                <Checkbox
                  className={`${className} checkbox-input`}
                  data-testid={`${className}-verify-phone-checkbox`}
                  value={shouldVerifyPhone}
                  checked={!!shouldVerifyPhone}
                  onChange={handleCheckbox}
                  disabled={isSubmitting}
                />
                <div className={`${className} checkbox-label`}>Perform Mobile Verification</div>
              </InputLabel>
            </div>
          </div>
        </Form>
      </Styled>
    </StyledModal>
  );
};
