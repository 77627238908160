/**
 * A helper to format balances correctly
 */
export const formatCurrency = (total: number | string): string => parseFloat(`${total}`).toFixed(2);

/**
 * A helper to always ensure cents to currency
 */
export const formatCurrencyInput = (total: number | string): string =>
  `${total}`.replace(/[^\d]+/g, '').replace(/\B(?=((\d{2})$))/g, '.');

export const removeThirdDecimal = (value: string) =>
  value.indexOf('.') >= 0 ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3) : value;
