import { theme } from '@evgo/react-material-components';
import { Table } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled(Table)`
  td,
  th {
    padding: ${`${spacing(2.25)}px ${spacing()}px`};

    &.checkbox {
      > span {
        padding: 0;
      }
    }

    &:first-child {
      padding-left: ${spacing(2)}px;
    }

    &:last-child {
      padding-right: ${spacing(2)}px;
    }
  }

  td {
    font-size: 12px;
    a {
      font-size: 12px;
      color: ${config.colors.accentBlue};
      text-decoration: none;
      font-weight: 400;
    }
  }
  .content-section {
    position: relative;
    top: ${spacing(22)}px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > section {
      margin: 32px 16px 0px;
      width: calc(100% - ${spacing(4)}px);
    }

    > section:last-of-type {
      margin-bottom: ${spacing(10)}px;
    }
  }
`;
