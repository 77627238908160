/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, text, typography, spacing } = theme;

export const Styled = styled.div`
  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    height: ${spacing(17.5)}px;
    box-sizing: border-box;
    z-index: 999;
    background-color: ${palette.primary[50]};
    padding: 0 ${spacing(8)}px;

    div {
      display: flex;
      flex: 1;
      align-content: space-between;
      flex-direction: column;

      section {
        display: flex;
        flex: 1 1 auto;

        &.back {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          a {
            text-transform: none;
            font-weight: 200;
            font-size: ${typography.fontSize - 2}px;
            color: ${text.labelEmphasis.medium};
          }

          svg {
            margin-right: ${spacing()}px;
            height: ${spacing(1.5)}px;
            width: ${spacing(1.5)}px;
            color: ${palette.secondary[700]};
          }
        }

        &.title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: ${spacing(1.5)}px;

          svg {
            margin-right: ${spacing(1.25)}px;
            height: ${spacing(2.75)}px;
            width: ${spacing(2.75)}px;
            color: ${palette.secondary[700]};
          }

          h5 {
            font-weight: 200;
            font-size: ${typography.fontSize + 8}px;
          }
        }

        &.info {
          display: flex;
          flex-direction: row;
          font-weight: 200;
          padding-top: ${spacing(1.5)}px;

          article {
            display: flex;
            flex-direction: column;

            label {
              font-size: ${typography.fontSize - 2}px;
              color: ${text.labelEmphasis.medium};
            }

            div {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-right: ${spacing(1.25)}px;

              &.charger-id,
              &.address,
              &.brand,
              &.speed,
              &.warranty {
                width: auto;
              }

              p {
                flex: 1 1 auto;
                font-size: ${typography.fontSize}px;
                font-weight: ${typography.fontWeightRegular};
                color: ${text.surfaceEmphasis.medium};
                margin-right: ${spacing()}px;
              }
              button {
                padding: ${spacing()}px;
              }

              svg {
                height: ${spacing(1.75)}px;
                width: ${spacing(2)}px;
                /* TODO: use theme colors */
                color: #666666;
              }
            }
          }
        }
      }
    }
  }

  hr {
    margin: 0 ${spacing(2)}px;
  }

  .gmnoprint {
    display: none;
  }

  a[title='Open this area in Google Maps (opens a new window)'] {
    display: none !important;
  }

  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google4.png"]
  {
    display: none;
  }
`;
