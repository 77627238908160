import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { PlaylistAdd as PlaylistAddIcon } from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ChargerModelsWithMeta, Query } from 'src/@types';
import { FetchMore, MatOnChangePageEvent } from 'src/@types/shared';
import { listChargerModels } from '../../../../apollo/queries/models';
import { sanitizeSearch, titleCase, updateQuery } from '../../../../lib/helpers';
import { ListSearch as ChargerModelsListSearch } from '../../../shared/ListSearch';
import { ChargerModelsListFilters } from './ChargerModelsListFilters';
import { Styled } from './styles';

const searchFields = ['modelName'];

/**
 * Gets list with up-to-date sort info
 */
export const onSort =
  (fetchMore: FetchMore, metadata: ChargerModelsWithMeta, key: string) => (): ReturnType<FetchMore> => {
    const { pageSize, search, filter } = metadata;
    let sort = { [key]: 'ASC' };

    if (_.get(metadata, `sort.${key}`, 'ASC') === 'ASC') sort = { [key]: 'DESC' };

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          page: 0,
          pageSize,
          sort,
          filter:
            !_.isEmpty(filter) && filter?.manufacturer
              ? { manufacturer: _.omit(filter?.manufacturer, '__typename') }
              : null,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Gets list with up-to-date offset info
 */
export const onChangePage =
  (fetchMore: FetchMore, metadata: ChargerModelsWithMeta) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    const { pageSize, sort, search, filter } = metadata;

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          page,
          pageSize,
          filter:
            !_.isEmpty(filter) && filter?.manufacturer
              ? { manufacturer: _.omit(filter?.manufacturer, '__typename') }
              : null,
          sort: _.pickBy(_.pick(sort, ['manufacturer', 'modelName', 'unitType']), (i) => i !== null),
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Gets list with up-to-date limit info
 */
export const onChangeRowsPerPage =
  (fetchMore: FetchMore, metadata: ChargerModelsWithMeta) =>
  (event: React.ChangeEvent<HTMLInputElement>): ReturnType<FetchMore> => {
    const { sort, search, filter } = metadata;

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          page: 0,
          pageSize: event.target.value,
          filter:
            !_.isEmpty(filter) && filter?.manufacturer
              ? { manufacturer: _.omit(filter?.manufacturer, '__typename') }
              : null,
          sort: _.pickBy(_.pick(sort, ['manufacturer', 'modelName', 'unitType']), (i) => i !== null),
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Search models list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: ChargerModelsWithMeta,
  target: { value: string },
): ReturnType<FetchMore> => {
  const { pageSize, sort, filter } = metadata;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let search = {} as any;
  if (target.value.length) {
    searchFields.forEach((field) => {
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize,
        filter:
          !_.isEmpty(filter) && filter?.manufacturer
            ? { manufacturer: _.omit(filter?.manufacturer, '__typename') }
            : null,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        search,
      },
    },
  });
};

/**
 * Filters charger models list
 */
export const onFilterChange = (
  fetchMore: FetchMore,
  metadata: ChargerModelsWithMeta,
  updatedFilters: string[],
  type: string,
): ReturnType<FetchMore> => {
  const { pageSize, sort, search } = metadata;

  const filter = updatedFilters.length
    ? {
        [type]: {
          in: updatedFilters,
        },
      }
    : null;

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        search: sanitizeSearch(search, searchFields),
        filter,
      },
    },
  });
};

/**
 * Models view component.
 */
export const ChargerModelsView: React.FC = () => {
  const id = _.kebabCase('ChargerModelsView');
  const className = id;
  const { data, fetchMore, loading } = useQuery<Query>(listChargerModels, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page: 0,
        pageSize: 10,
        sort: { manufacturer: 'ASC' },
      },
    },
  });
  const { edges, ...metadata } = data?.listChargerModels || {};
  const currentCount = edges?.length || 0;
  const modelsTotal = metadata.total ? metadata.total : 0;

  const debouncedOnSearchChange =
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : onSearchChange;

  const debouncedFetchMore = typeof fetchMore === 'function' ? _.debounce(fetchMore, 500) : fetchMore;

  const columns = [
    { key: 'manufacturer', label: 'Manufacturer' },
    { key: 'modelName', label: 'Model Name' },
    { key: 'unitType', label: 'Unit Type' },
    { key: 'powerOutput', label: 'Power Output' },
    { key: 'connectors', label: '# of Connectors' },
    { key: 'duplicate', label: '' },
  ];

  return (
    <Styled className={className}>
      <Fragment>
        <header className={className}>
          <section className={`${className} title`}>
            <Typography className={className} variant="h5">
              Model Management
            </Typography>
            <div className={`${className} subtitle`}>{`${modelsTotal} Models`}</div>
          </section>
        </header>

        <Divider className={className} />
      </Fragment>

      <Paper className={className} component="section">
        <header className={className}>
          <div className={`${className} container`}>
            <div className={`${className} title`}>
              <Typography className={className} variant="h6" component="h2">
                Models
              </Typography>
              <Typography className={className} variant="caption">{`Currently viewing ${currentCount}`}</Typography>
            </div>
          </div>

          <div className={`${className} actions`}>
            <Button className={className} color="secondary" component={Link} to="/models/new" variant="contained">
              Add Model
            </Button>

            <ChargerModelsListSearch
              className={className}
              type="model"
              onSearchChange={(search) => debouncedOnSearchChange(fetchMore, metadata, search)}
            />
          </div>
        </header>

        <ChargerModelsListFilters
          metadata={metadata}
          fetchMore={debouncedFetchMore}
          onFilterChange={onFilterChange}
          className={`${className} filters`}
        />

        <Divider />

        <Table className={className} data-testid="model-management-table">
          <TableHead className={className}>
            <TableRow className={className}>
              {columns.map((column) => (
                <TableCell key={column.key} className={`${className} ${column.key}`} component="th">
                  <TableSortLabel
                    active={_.isString(_.get(metadata, `sort.${column.key}`))}
                    direction={(_.toLower(_.get(metadata, `sort.${column.key}`, 'asc')) || 'asc') as 'asc'}
                    onClick={onSort(fetchMore, metadata, column.key)}
                    disabled={!['manufacturer', 'modelName', 'unitType'].includes(column.key)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading &&
              edges?.map((model, i) => {
                let connectorCount = 0;
                if (!_.isEmpty(_.get(model, 'evses.edges'))) {
                  _.forEach(_.get(model, 'evses.edges'), (evse) => {
                    connectorCount += evse.connectors.edges.length;
                  });
                }
                return (
                  <TableRow key={model?.altId}>
                    <TableCell className={className}>{_.get(model, 'manufacturer', '')}</TableCell>

                    <TableCell className={className}>
                      <Link className={className} to={`/models/${model?.altId}/profile`}>
                        {_.get(model, 'modelName', '')}
                      </Link>
                    </TableCell>

                    <TableCell className={className}>{titleCase(_.get(model, 'unitType', ''))}</TableCell>
                    <TableCell className={className}>
                      {_.get(model, 'powerOutput', '') ? `${_.get(model, 'powerOutput', '')}kW` : ''}
                    </TableCell>
                    <TableCell className={className}>{connectorCount}</TableCell>

                    <TableCell
                      className={className}
                      align="right"
                      id={`${id}-charger-model-${i}-duplicate-button-container`}
                    >
                      <Tooltip
                        title="Duplicate"
                        enterDelay={200}
                        leaveDelay={200}
                        id={`${id}-charger-model-${i}-duplicate-tooltip`}
                      >
                        <Link to="/models/new" state={{ chargerModelId: model?.altId }}>
                          <IconButton
                            className={className}
                            id={`${id}-charger-model-${i}-duplicate-button`}
                            color="secondary"
                          >
                            <PlaylistAddIcon color="primary" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>

          <TableFooter className={className}>
            <TableRow className={className}>
              <TablePagination
                className={className}
                count={metadata.total || 0}
                onPageChange={onChangePage(fetchMore, metadata)}
                onRowsPerPageChange={onChangeRowsPerPage(fetchMore, metadata)}
                page={metadata.page || 0}
                rowsPerPage={metadata.pageSize || 10}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Styled>
  );
};
