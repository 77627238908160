/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * A helper to update an Apollo query
 */
export const updateQuery = (prevResult: any, { fetchMoreResult }: any) => {
  if (!fetchMoreResult) {
    return prevResult;
  }

  return fetchMoreResult;
};
