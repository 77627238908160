import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Account, CardsWithMeta, Driver, DriversWithMeta, Maybe } from 'src/@types';
import { formatUSNumber, titleCase } from 'src/lib/helpers';
import { useModalContext } from 'src/contexts/ModalContext';

export interface Props {
  accountAltId: string;
  account: Maybe<Account>;
  driver: Maybe<Driver>;
  drivers: DriversWithMeta;
  cards?: Maybe<CardsWithMeta>;
  className?: string;
}

export const DriverRow: React.FC<Props> = ({ accountAltId, className, account, driver, drivers, cards }) => {
  const [state, setState] = useState({ anchorElement: null as HTMLButtonElement | null });
  const id = _.kebabCase('DriverRow');
  const { anchorElement } = state;
  const { setModalState } = useModalContext();
  const hasCard = !_.isEmpty(
    _.find(_.get(cards, 'edges', []), { cardStatus: 'ACTIVE', driver: { altId: _.get(driver, 'altId') } }),
  );
  const driverLink =
    driver?.driverType === 'OWNER' ? (
      <Link
        className={`${className} driver-link`}
        to={`/accounts/${accountAltId}/profile`}
      >{`${driver?.firstName} ${driver?.lastName}`}</Link>
    ) : (
      <Link
        className={`${className} driver-link`}
        to={`/drivers/${driver?.altId}/profile`}
      >{`${driver?.firstName} ${driver?.lastName}`}</Link>
    );
  let status = '';
  let creationDate = '';
  let editDisabled = false;
  if (_.get(driver, 'driverType') === 'OWNER') {
    status = titleCase(_.get(account, 'accountStatus', 'Unknown'));
    creationDate = moment(_.get(account, 'enrolledOn', '')).format('MM/DD/YY');
    editDisabled = true;
  } else {
    status = _.get(driver, 'driverStatus.columnText');
    creationDate = moment(moment.utc(_.get(driver, 'createdAt')).toDate())
      .local()
      .format('MM/DD/YYYY');
  }

  return (
    <Fragment>
      <TableRow className={className}>
        <TableCell className={className} component="th">
          {driverLink}
        </TableCell>
        <TableCell className={className}>{creationDate}</TableCell>
        <TableCell className={className}>{formatUSNumber(_.get(driver, 'phone', ''))}</TableCell>
        <TableCell className={className}>{_.get(driver, 'email', '')}</TableCell>
        <TableCell className={className}>{status}</TableCell>
        <TableCell className={className}>{hasCard ? <Done /> : null}</TableCell>
        <TableCell className={className} id={`${id}-menu-button-cell`}>
          <IconButton
            onClick={(e) => setState({ anchorElement: e.currentTarget })}
            size="small"
            id={`${id}-menu-button-cell`}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        anchorEl={anchorElement}
        className={className}
        disableAutoFocusItem={true}
        open={Boolean(anchorElement)}
        onClose={() => setState({ anchorElement: null })}
        variant="menu"
      >
        <MenuItem
          className={className}
          id={`${id}-menu-edit-option`}
          disabled={editDisabled}
          onClick={() => {
            setState({ anchorElement: null });
            setModalState({
              modalName: 'AccountDriverModal',
              modalProps: {
                driver,
                drivers,
              },
              modalVisible: true,
            });
          }}
        >
          Edit
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
