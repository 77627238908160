import { Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { events } from 'src/lib/utils/analytics-events';
import { EditSection } from '../EditSection';
import { Styled } from './styles';

export interface Props {
  chargerGroupName?: string;
  description?: string;
  disabled?: boolean;
  planDuration?: number | null;
}

export const DescriptionSection: React.FC<Props> = (props) => {
  const params = useParams();
  return (
    <Styled>
      <EditSection
        disabled={props.disabled}
        title="Description"
        route={`/extend-plus/custom-plans/${params.altId}/edit`}
        trackEvent={events.customPlanSummary.CLICKED_EDIT_DESCRIPTION_BUTTON}
      >
        <Typography variant="subtitle1">{props.chargerGroupName}</Typography>
        <Typography variant="subtitle1">{props.description}</Typography>
        <Typography variant="subtitle1">
          Plan Duration:{' '}
          {props.planDuration ? `${props.planDuration} ${props.planDuration > 1 ? 'Months' : 'Month'}` : 'N/A'}
        </Typography>
      </EditSection>
    </Styled>
  );
};
