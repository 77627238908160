/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import { ApolloError } from '@apollo/client';
import clsx from 'clsx';
import { EmptyRows } from 'src/components/shared/Table/helpers';
import { theme } from '@evgo/react-material-components';

export type SimpleColumn<T = any> = {
  key: string;
  label: string;
  format?: (value: T) => string;
  formatWithRowData?: (data: T) => string;
};

const StyledTable = styled(Table)`
  margin-top: ${theme.spacing(2)}px;
  thead,
  thead th {
    background: none !important;
    color: rgba(0, 0, 0, 0.87);
  }
`;

type Props = {
  label: string;
  className?: string;
  data: unknown[];
  loading: boolean;
  error?: ApolloError | undefined;
  columns: SimpleColumn[];
};

export const BasicTable: React.FC<Props> = ({ label, data, columns, className, loading, error }) => {
  return (
    <StyledTable className={clsx('basic-table', className)} size="small" aria-label={label}>
      <TableHead>
        <TableRow>
          {columns.map(({ label: columnLabel }) => {
            return (
              <TableCell width={`${100 / columns.length}%`} align="left">
                {columnLabel}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length ? (
          data.map((row, index) => (
            <TableRow data-testid="base-row" key={index}>
              {columns.map(({ key, format, formatWithRowData }) => {
                return (
                  <TableCell align="left">
                    {format ? format(_.get(row, key)) : formatWithRowData ? formatWithRowData(row) : _.get(row, key)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
        ) : (
          <EmptyRows loading={loading} error={error} message="No Data" />
        )}
      </TableBody>
    </StyledTable>
  );
};
