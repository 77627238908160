import { theme } from '@evgo/react-material-components';
import { ArrowForward, TrendingDown, TrendingUp } from '@material-ui/icons';
import { colors } from '@material-ui/core';
import { formatNumber } from '../formatNumber';

export const getConsumptionMetricCardDetails = (trendValue: number, isPower = false) => {
  const iconDiv =
    trendValue === 0 ? (
      <div style={{ color: theme.palette.text.primary }}>
        <ArrowForward />
      </div>
    ) : trendValue > 0 ? (
      <div style={{ color: colors.green[600] }}>
        <TrendingUp />
      </div>
    ) : (
      <div style={{ color: colors.red[600] }}>
        <TrendingDown />
      </div>
    );

  const trendText =
    trendValue === 0
      ? 'No change'
      : trendValue > 0
      ? `+${formatNumber(trendValue)}% ${isPower ? 'kWh' : ''} increase`
      : `${formatNumber(trendValue)}% ${isPower ? 'kWh' : ''} decrease`;

  return { iconDiv, trendText };
};
