import { IconButton, TableCell } from '@material-ui/core';
import { Done, ExpandLess, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { ConnectorIcon } from '../../../../../svgs/ConnectorIcon';
import { ChargeSessionDetails } from './ChargeSessionDetails';
import { Styled as StyledTableRow } from './styles';

export interface Props {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  row: {
    sessionId: string;
    connectorType: string;
    startSource: string;
    stopSource: string;
    startTime: string;
    endTime: string;
    totalTime: string;
    startkWh: string;
    endkWh: string;
    totalKwh: string;
    billingPlan: string;
    corrupt: string;
    extraData: string;
    meterReadings: { timeStamp: string; kW: string }[];
  };
  className?: string;
}

/**
 * Charge Session component
 */
export const ChargeSession: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargeSession');
  const className = id;
  const {
    index,
    row: {
      sessionId,
      connectorType,
      startSource,
      stopSource,
      startTime,
      endTime,
      totalTime,
      startkWh,
      endkWh,
      totalKwh,
      billingPlan,
      corrupt,
      extraData,
      meterReadings,
    },
  } = props;
  const [metadata, setMetadata] = useState(props.metadata);
  const [open, setOpen] = useState(false);

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  if (!_.isEqual(metadata, props.metadata)) {
    setMetadata(props.metadata);
    setOpen(false);
  }

  return (
    <Fragment>
      <StyledTableRow className={`${customClass} ${open ? 'open' : ''}`}>
        <TableCell className={className} id={`${id}-table-sessionId-${index}`}>
          {sessionId}
        </TableCell>
        <TableCell className={className} id={`${id}-table-connectorType-${index}`}>
          <ConnectorIcon className={className} name={connectorType} status={''} />
        </TableCell>
        <TableCell className={className} id={`${id}-table-startSource-${index}`}>
          {startSource}
        </TableCell>
        <TableCell className={className} id={`${id}-table-stopSource-${index}`}>
          {stopSource}
        </TableCell>
        <TableCell className={className} id={`${id}-table-startTime-${index}`}>
          {startTime}
        </TableCell>
        <TableCell className={className} id={`${id}-table-endTime-${index}`}>
          {endTime}
        </TableCell>
        <TableCell className={className} id={`${id}-table-totalTime-${index}`}>
          {totalTime}
        </TableCell>
        <TableCell className={className} id={`${id}-table-totalKwh-${index}`}>
          {totalKwh}
        </TableCell>
        <TableCell className={className} id={`${id}-table-billingPlan-${index}`}>
          {billingPlan}
        </TableCell>
        <TableCell className={className} id={`${id}-table-corrupt-${index}`}>
          {corrupt ? <Done /> : null}
        </TableCell>
        <TableCell className={className} id={`${id}-table-extraData-${index}`}>
          {extraData}
        </TableCell>
        <TableCell className={className} id={`${id}-table-action-row-${index}`}>
          <IconButton id={`${id}-expand-button-${index}`} size="small" onClick={() => setOpen((prevOpen) => !prevOpen)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
      </StyledTableRow>

      {open ? (
        <ChargeSessionDetails
          className={className}
          sessionInfo={{ startkWh, endkWh, meterReadings, open, index, sessionId, totalTime }}
        />
      ) : null}
    </Fragment>
  );
};
