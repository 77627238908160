import { theme } from '@evgo/react-material-components';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import { FieldArray, FieldHookConfig, useField } from 'formik';
import moment from 'moment';
import React from 'react';
import { Day } from 'src/@types';
import styled from 'styled-components';
import { StyledFormControlLabel, StyledTimeRangeBox } from './styles';

export interface CustomHours {
  daysOfWeek: string[];
  startTime: string;
  endTime: string;
}

type Props = FieldHookConfig<CustomHours> & {
  id?: string;
  name?: string;
  readonly?: boolean;
};

export const daysOfWeek = [Day.Sunday, Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday];

const { spacing, palette } = theme;

const Styled = styled.div<{ readonly?: boolean }>`
  padding: ${spacing(1.5)}px ${spacing(1.5)}px ${spacing(1.5)}px ${spacing(5)}px;
  border-left: 1px solid ${palette.grey['300']};
  min-height: ${({ readonly }) => (readonly ? 0 : spacing(26))}px;

  > div {
    gap: ${spacing(2)}px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .root {
    display: flex;
    flex-direction: row;

    .date-range {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: ${spacing(1)}px;
    }

    .dotw-helper-text {
      margin-left: ${spacing(1.5)}px;
    }

    label {
      margin: 0;
    }

    hr {
      width: ${spacing(2)}px;
      margin: ${spacing(1)}px;
      background-color: ${palette.grey['600']};
    }

    input[type='checkbox'] {
      padding: 0;
    }

    span {
      font-size: 0.85rem;
    }

    .days-of-week {
      padding: 0 ${spacing(1)}px;
    }

    .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
      margin-top: 0;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  align-self: center;
`;

const newTimeFrame: CustomHours = {
  daysOfWeek: [],
  startTime: '',
  endTime: '',
};

type Errors = { [key: string]: string };

const getFormattedTime = (time: string): string => {
  const [hour, minute, second] = time.split(':').map((v) => Number(v));
  const formattedStartTime = moment().utcOffset(0).set({ hour, minute, second });
  return formattedStartTime.format('hh:mm A');
};

export const CustomHoursInput: React.FC<Props> = ({ name, id = 'custom-hours', readonly = false }) => {
  const [field, meta, helpers] = useField<CustomHours[]>(name);

  const { value = [newTimeFrame], onBlur, onChange } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;
  const errors = (error || []) as unknown as Errors[];

  const handleDaysOfTheWeekChange =
    (i: number) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const currentDaysOfTheWeek = [...(value[i]?.daysOfWeek || [])];
      const targetWeekday = daysOfWeek.find((weekday) => weekday === target.value);

      const newValues =
        target.checked && targetWeekday
          ? [...currentDaysOfTheWeek, targetWeekday]
          : [...currentDaysOfTheWeek].filter((weekday) => weekday !== target.value);

      value[i] = { ...value[i], daysOfWeek: newValues };

      setValue(value);
    };

  const hasTimeFrameError = (i: number) =>
    Boolean(
      touched && (errors?.[i]?.timeFrame || errors?.[i]?.startTime || errors?.[i]?.endTime || errors?.[i]?.daysOfWeek),
    );
  const hasStartTimeError = (i: number) => Boolean(touched && (errors?.[i]?.startTime || errors?.[i]?.timeFrame));
  const hasEndTimeError = (i: number) => Boolean(touched && (errors?.[i]?.endTime || errors?.[i]?.timeFrame));
  const hasDaysOfTheWeekError = (i: number) => Boolean(touched && errors?.[i]?.daysOfWeek);

  return (
    <Styled readonly={readonly}>
      <FieldArray name={name} data-testid={`${id}-schedules`}>
        {({ remove, push }) => (
          <div>
            {value.map((customSchedule, index) => {
              return (
                <Box key={index} display="flex">
                  <FormControl error={hasTimeFrameError(index)}>
                    <StyledTimeRangeBox className="root">
                      <FormControl error={hasTimeFrameError(index)}>
                        <Box className="date-range" data-testid={`${id}-date-range-${index}`}>
                          {!readonly && (
                            <TextField
                              type="time"
                              label="Start Time"
                              name={`${name}.${index}.startTime`}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value?.[index].startTime}
                              data-testid={`${id}-start-time-input-${index}`}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              error={hasStartTimeError(index)}
                              disabled={readonly}
                            />
                          )}
                          {readonly && <Typography>{getFormattedTime(value?.[index].startTime)}</Typography>}
                          <Divider />
                          {readonly && <Typography>{getFormattedTime(value?.[index].endTime)}</Typography>}
                          {!readonly && (
                            <TextField
                              type="time"
                              label="End Time"
                              name={`${name}.${index}.endTime`}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value?.[index].endTime}
                              data-testid={`${id}-end-time-input-${index}`}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              error={hasEndTimeError(index)}
                              disabled={readonly}
                            />
                          )}
                        </Box>
                      </FormControl>
                      <StyledFormControl
                        error={hasDaysOfTheWeekError(index)}
                        data-testid={`${id}-days-of-week-checkboxes-${index}`}
                      >
                        <FormGroup row>
                          {daysOfWeek.map((day) => (
                            <StyledFormControlLabel
                              key={day}
                              control={
                                <Checkbox
                                  name={`${name}.${index}.daysOfWeek`}
                                  className="days-of-week"
                                  value={day}
                                  checked={value?.[index].daysOfWeek?.includes(day) ?? false}
                                  onBlur={onBlur}
                                  onChange={handleDaysOfTheWeekChange(index)}
                                  data-testid={`${id}-checkbox-${day.toLowerCase()}-${index}`}
                                  disabled={readonly}
                                />
                              }
                              label={day[0]}
                              labelPlacement="bottom"
                            />
                          ))}
                          {!readonly && (
                            <IconButton
                              role="button"
                              size="small"
                              color="primary"
                              disabled={value.length === 1}
                              onClick={() => remove(index)}
                              data-testid={`${id}-remove-schedule-button-${index}`}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </FormGroup>
                      </StyledFormControl>
                    </StyledTimeRangeBox>
                    {hasTimeFrameError(index) && (
                      <FormHelperText className="dotw-helper-text">
                        {errors?.[index]?.startTime ||
                          errors?.[index]?.endTime ||
                          errors?.[index]?.daysOfWeek ||
                          errors?.[index]?.timeFrame}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              );
            })}
            {!readonly && (
              <IconButton
                role="button"
                color="secondary"
                size="small"
                onClick={() => push(newTimeFrame)}
                data-testid={`${id}-add-schedule-button`}
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        )}
      </FieldArray>
    </Styled>
  );
};
