/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Transforms the Formik contact values into Falcon format
 */
export const transformContactValues = (value: any) => {
  const contactInput = {
    hostId: _.get(value, 'altId'),
    contactName: _.get(value, 'contactName'),
    workTitle: _.get(value, 'title'),
    email: _.get(value, 'email'),
    phone: _.get(value, 'phone'),
  };

  return contactInput;
};
