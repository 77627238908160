import { useQuery } from '@apollo/client';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Query } from 'src/@types';
import { getExtendUser } from 'src/apollo/queries/extendPlus';
import { auth } from 'src/firebase';

export function useGetExtendUser() {
  const [user, authLoading] = useAuthState(auth);
  const variables = {
    extenduserInput: {
      firebaseId: user?.uid,
    },
  };

  const { data, loading: requestLoading } = useQuery<Query>(getExtendUser, {
    variables,
    skip: !user?.uid,
  });

  return {
    loading: requestLoading || authLoading,
    extendUser: data?.getExtendUser,
    host: data?.getExtendUser?.hosts?.edges?.[0] || undefined,
  };
}

export function useGetFirstUserHost() {
  const [user] = useAuthState(auth);

  const { data: extendUser } = useQuery<Query>(getExtendUser, {
    variables: {
      extenduserInput: {
        firebaseId: user?.uid,
      },
    },
    skip: !user?.uid,
  });

  const [host] = extendUser?.getExtendUser?.hosts?.edges || [];

  return host;
}
