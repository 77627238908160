import { Box, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTimeRangeBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & span.MuiFormControlLabel-label.Mui-disabled {
    color: inherit;
  }
`;
