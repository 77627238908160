import { useCreateExtendHostMutation } from 'src/@types';
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { StyledModal } from 'src/components/shared/StyledModal';
import { useSnackbar } from 'src/lib/hooks';
import { ExtendHostForm, FormValues, initialValues } from './ExtendHostForm';
import { validations } from './ExtendHostForm/validations';
interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
}

export const ExtendHostModal: React.FC<Props> = ({ open, onClose }) => {
  const className = 'ExtendHostModal-component';
  const snackbar = useSnackbar();

  const [createExtendHost, { loading }] = useCreateExtendHostMutation();

  const handleSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    createExtendHost({
      variables: {
        input: values,
      },
      onCompleted: () => {
        snackbar.success('eXtend+ host created.');
        onClose();
      },
      onError: (e) => {
        snackbar.error(e?.message || 'An unknown error has occurred.');
      },
    });
    resetForm();
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validations}>
      {({ submitForm, resetForm }) => (
        <StyledModal
          className={clsx(className, 'modal')}
          open={open}
          onClose={() => {
            resetForm();
            onClose();
          }}
          data-testid="add-host-modal"
          title="Add eXtend+ Host"
          subtitle="Look up and add an existing host to be an eXtend+ host"
          primaryButtonText="Add Host"
          primaryButtonLoading={loading}
          onClick={submitForm}
          secondaryButtonClick={() => {
            resetForm();
            onClose();
          }}
          secondaryButtonText="Cancel"
        >
          <ExtendHostForm data-testid="add-host-form" className={className} />
        </StyledModal>
      )}
    </Formik>
  );
};
