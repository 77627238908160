import { gql } from '@apollo/client';

export const getPlanEnrollmentsForExtend = gql`
  query getPlanEnrollmentsForExtend {
    getPlanEnrollmentsForExtend {
      planName
      totalEnrollments
    }
  }
`;
