import { Checkbox, FormHelperText, InputAdornment, TextField, Typography } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Tooltip } from 'src/components/shared/Tooltip';
import { removeThirdDecimal } from 'src/lib/helpers';
import { Styled, StyledBox, StyledCheckboxContainer, StyledFormControl, StyledFormControlLabel } from './styles';

enum PricingType {
  PerKwh = 'perKwh',
  PerSession = 'perSession',
  PerMinute = 'perMinute',
}

export type PricingTypeInputProps = {
  id?: string;
  name?: string;
  className?: string;
  readonly?: boolean;
};

const types = [
  {
    type: PricingType.PerKwh,
    label: 'per kWh',
    tip: 'The price users will pay per kWh of consumption of their charging session',
  },
  {
    type: PricingType.PerSession,
    label: 'per Session',
    tip: 'The price users will pay per session they use the charger',
  },
  {
    type: PricingType.PerMinute,
    label: 'per Minute',
    tip: 'The price users will pay per min duration of their charging session',
  },
];
export const PricingTypeInput: React.FC<PricingTypeInputProps> = (props) => {
  const { id = 'pricing-type', className = 'pricingTypeInput', name = 'pricing' } = props;
  const [field, meta, helpers] = useField({ name, type: 'number' });

  const { value, onBlur, onChange } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;
  const hasError = Boolean(error && touched);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) onBlur(e);
    onChange(e);
  };

  // Format price input to only have 2 decimals, no alpha characters, and set formatted value
  const handlePriceFormatAndChange = (type: PricingType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueAlphaRemoved = e.target.value.replace(/[^\d.-]/g, '');

    if (valueAlphaRemoved === '.') {
      setValue({ ...value, [type]: '0.' });
      return;
    }

    if ((valueAlphaRemoved.match(/\./g) || []).length > 1) {
      setValue({ ...value, [type]: valueAlphaRemoved.slice(0, -1) });
      return;
    }

    const updatedValue =
      valueAlphaRemoved.indexOf('.') === valueAlphaRemoved.length - 1 ||
      valueAlphaRemoved.substring(valueAlphaRemoved.indexOf('.'), valueAlphaRemoved.length).length <= 2
        ? valueAlphaRemoved
        : removeThirdDecimal(valueAlphaRemoved);

    setValue({ ...value, [type]: updatedValue });
  };

  const handlePriceBlur = (type: PricingType) => (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    if (e.target.value === '') return;
    const zeroPaddedValue = Number(e.target.value).toFixed(2);
    setValue({ ...value, [type]: zeroPaddedValue });
  };

  const PricingCheckbox = ({ type = PricingType.PerKwh, label = '', checked = false }) => {
    return (
      <StyledFormControlLabel
        control={
          <Checkbox
            id={`${id}-${type}-checkbox`}
            className={clsx(className, `pricing-${type}-checkbox`)}
            name={`${name}.${type}`}
            checked={checked}
            checkedIcon={<CheckBox fontSize="small" />}
            onChange={handleChange}
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            inputProps={{ id: `${id}-pricing-${type}-checkbox-input` }}
            disableRipple
            data-testid={`${id}-${type}-checkbox`}
            disabled={props.readonly}
          />
        }
        label={label}
      />
    );
  };

  const PricingInput = ({ type = PricingType.PerKwh }) => {
    const { setFieldValue } = useFormikContext<object>();
    useEffect(() => {
      if (typeof value?.[type] === 'boolean') return;
      const displayValue = Number(value?.[type]).toFixed(2);
      setFieldValue(`${name}.${type}`, displayValue);
    }, [type, setFieldValue]);

    if (props.readonly) {
      return (
        (value?.[type] || value?.[type] === '') &&
        typeof value[type] !== 'boolean' && <Typography> - ${value[type]}</Typography>
      );
    }
    return (
      (value?.[type] || value?.[type] === '' || value?.[type] === 0) && (
        <TextField
          id={`${id}-${type}-input`}
          type="text"
          label="Price amount"
          name={`${name}.${type}`}
          onBlur={handlePriceBlur(type)}
          onChange={handlePriceFormatAndChange(type)}
          value={typeof value[type] !== 'boolean' ? value[type] : ''}
          data-testid={`${id}-${type}-input`}
          variant="outlined"
          error={touched && (!value?.[type] || value?.[type] === true)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      )
    );
  };

  return (
    <Styled>
      <StyledFormControl className={className} error={hasError}>
        {types.map(({ type, label, tip }) => {
          const checked = Boolean(value?.[type]) || value?.[type] === '' || value?.[type] === 0;
          if (props.readonly && !checked) return null;

          return (
            <StyledBox key={type} data-testid={`${id}-${type}`}>
              <StyledCheckboxContainer readonly={props.readonly}>
                {PricingCheckbox({ type, label, checked })}
                {!props.readonly && <Tooltip content={tip} />}
              </StyledCheckboxContainer>
              {PricingInput({ type })}
            </StyledBox>
          );
        })}
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </StyledFormControl>
    </Styled>
  );
};
