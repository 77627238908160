import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  .step-0 {
    display: flex;

    div {
      margin-left: auto;
      margin-right: auto;

      > div {
        > * {
          margin: 0 ${spacing()}px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
