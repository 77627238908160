import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { ListCardOrdersFilterInput } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { Styled } from './styles';

export interface Props {
  updateFilters: (p: ListCardOrdersFilterInput, orderStatus: string) => void;
  className?: string;
}

const statusList = [
  { label: 'Ordered', value: 'ORDERED' },
  { label: 'Sent', value: 'SENT' },
];

/**
 * Returns a string value of selected markets.
 */
export const checkSelected = (selected: string[], list: LabelValue<string>[]): string => {
  if (selected.length === 0 || !list) {
    return 'All';
  }

  return selected.map((item) => _.find(list, { value: item })?.label).join(', ');
};

/**
 * OrdersListFilters component.
 */
export const OrdersListFilters: React.FC<Props> = (props) => {
  const id = _.kebabCase('CardOrdersListFilters');
  const className = id;
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  return (
    <Styled className={className}>
      <FormControl className={className}>
        <InputLabel className={`${className} label`} htmlFor="order-status">
          Card Status
        </InputLabel>
        <Select
          className={`${className} select`}
          displayEmpty
          input={<Input id="select-multiple-checkbox" />}
          data-testid="orders-list-filters-order-status-select"
          multiple
          onChange={({ target }: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
            props.updateFilters({ orderStatus: target.value as string }, 'orderStatus');
            setSelectedStatuses(target.value as unknown as string[]);
          }}
          renderValue={(selected) => checkSelected(selected as unknown as string[], statusList)}
          value={selectedStatuses}
        >
          <MenuItem className={className} disabled value="">
            All
          </MenuItem>
          {statusList.map((status, i) => {
            return (
              <MenuItem key={status.value} value={status.value} disableRipple>
                <Checkbox
                  checked={selectedStatuses.includes(status.value)}
                  checkedIcon={<CheckBox fontSize="small" />}
                  inputProps={{ id: `${id}-${i}-card-status-checkbox` }}
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  disableRipple
                />
                <ListItemText primary={status.label} style={{ fontSize: 'small' }} />
              </MenuItem>
            );
          })}
        </Select>
        {!_.isEmpty(selectedStatuses) ? (
          <Typography
            className={`${className} clear-status`}
            variant="caption"
            color="secondary"
            data-testid="orders-list-filters-order-status-clear"
            onClick={() => {
              props.updateFilters({ orderStatus: {} }, 'status');
              setSelectedStatuses([]);
            }}
          >
            Clear
          </Typography>
        ) : null}
      </FormControl>
    </Styled>
  );
};
