import _ from 'lodash';
import { SessionAggregation, SessionDatePreset } from 'src/@types';
import { useGetSessionsConsumptionForExtend } from 'src/lib/hooks/useGetSessionsConsumptionForExtend';

export function useTotalConsumption() {
  const input = {
    aggregateBy: SessionAggregation.Day,
    datePreset: SessionDatePreset.Last_30d,
  };

  const { data, loading } = useGetSessionsConsumptionForExtend({ input });

  const totalConsumption = _.sum(data?.timeSeries?.map((v) => v?.powerValue));

  return {
    loading: loading,
    totalConsumption,
  };
}

export function ghgAvoided(consumption: number) {
  return Math.round(((consumption / 0.32) * 227) / 1000000);
}

export function galOfGasAvoided(consumption: number) {
  return parseFloat((consumption / 0.32 / 24.3).toFixed(1))
    .toLocaleString()
    .replace(/\.([0-9])$/, '.$1');
}
