import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${`0 ${spacing(6)}px ${spacing(12)}px`};
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) ${spacing(12)}px ${spacing(6)}px`};

  &:not(.last-sync) {
    height: 100%;
  }

  section {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};
  }
`;
