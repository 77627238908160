import { gql } from '@apollo/client';

export const reassignCard = gql`
  mutation ReassignCard($input: ReassignCardInput!) {
    reassignCard(input: $input) {
      altId
      cardStatus
      driver {
        altId
      }
    }
  }
`;
