import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';

const { spacing } = theme;

export const Styled = styled.article`
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing(0, 5.75)};
  padding: 0 calc(${spacing(5.75)}px + var(--safe-area-inset-right)) 0
    calc(${spacing(5.75)}px + var(--safe-area-inset-left));
  margin-bottom: 50px;

  h1 {
    padding: ${spacing(2.5, 3.75, 0)};
  }

  .example-viewer {
    width: calc(100% - 30px);
    margin: ${spacing(3.75, 2, 0)};

    div.expansion-panel-details-wrapper {
      width: 100%;
    }
  }
`;
