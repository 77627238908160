/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';

// TODO: the styles should probably come from Material UI
// instead of being overwritten here. Use out of the box design.
export const Styled = styled(Modal)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  > div {
    min-width: 60%;

    header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: ${theme.spacing(4)}px;
      position: relative;

      h3 {
        color: #707070;
      }

      .MuiTypography-h4 {
        font-weight: 500;
      }

      button {
        position: absolute;
        margin-right: ${`-${theme.spacing()}px`};
        margin-bottom: ${`-${theme.spacing(2)}px`};
        align-self: end;
      }
    }

    > div {
      padding: 0px ${theme.spacing(4)}px ${theme.spacing(2)}px;

      .body-container {
        margin-bottom: ${theme.spacing(8)}px;
      }

      > div {
        > * {
          font-size: 16px;
        }
      }

      .button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: ${theme.spacing(4, 0, 2)};
        button {
          font-size: 14px;
          margin: ${theme.spacing(0, 1)};
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .loading {
    color: ${theme.palette.common.white};
  }
`;
