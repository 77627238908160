import React, { useState, MouseEvent } from 'react';
import Menu from '@material-ui/core/Menu';
import { PopoverOrigin } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';

export { MenuItem } from '@material-ui/core';

type Children = (options: { close: () => void }) => React.ReactNode;

export type KebabMenuProps = {
  orientation?: 'horizontal' | 'vertical';
  children?: Children;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  size?: 'small' | 'medium';
  'data-testid'?: string;
};

export const KebabMenu: React.FC<KebabMenuProps> = ({
  orientation = 'horizontal',
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
  children = () => null,
  size,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const onClose = () => setAnchorEl(null);
  const Icon = orientation === 'horizontal' ? MoreHoriz : MoreVert;

  const testid = rest?.['data-testid'] || 'kebab-menu';

  return (
    <>
      <IconButton className="kebab-menu-button" onClick={onClick} size={size} data-testid={`${testid}-button`}>
        <Icon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        data-testid={testid}
      >
        <div>{children({ close: onClose })}</div>
      </Menu>
    </>
  );
};
