import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Contract } from 'src/@types';
import { createContract, updateContract } from 'src/apollo/queries/contracts';
import { updateQuery } from 'src/lib/helpers';
import { useFormikSubmit } from 'src/lib/hooks';
import { AccountContractModalForm, initialValues } from './AccountContractModalForm';
import validationSchema from './AccountContractModalForm/validations';
import { useModalContext } from 'src/contexts/ModalContext';

interface Props {
  open: boolean;
}

export const AccountContractModal: React.FC<Props> = (props) => {
  const className = 'accountcontractmodal-component';

  const { modalProps } = useModalContext();
  const { accountId, fetchMore: callback, contract } = modalProps || {};
  const { altId } = (contract as Contract) || {};
  const sanitizedAltId = altId ?? undefined;

  const callbackArgs = {
    updateQuery,
    variables: {
      accountInput: { altId: accountId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999, paranoid: false },
      cardsInput: { pageSize: 999 },
    },
  };
  const omitFields = !_.isEmpty(contract) ? ['planId'] : [];
  const transformValues = (values: Contract) => ({
    ...{
      ..._.omit(values, omitFields),
      statusId: Number(_.get(values, 'statusId')),
      startDate: moment.utc(_.get(values, 'startDate')).format('MM/DD/YYYY'),
      endDate: moment.utc(_.get(values, 'endDate')).format('MM/DD/YYYY'),
    },
    ...(sanitizedAltId ? { sanitizedAltId } : {}),
  });
  const handleSubmit = useFormikSubmit('Contract', createContract, updateContract, {
    noRedirect: true,
    transformValues,
    altId: sanitizedAltId,
    callback,
    callbackArgs,
    initialValues,
  }) as never;

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AccountContractModalForm className={className} open={props.open} />
    </Formik>
  );
};
