import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete, PlaylistAdd } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Maybe, Plan, PlanWithMeta } from 'src/@types';
import { FetchMore, MatOnChangePageEvent, SetCheckAll, SetChecked } from 'src/@types/shared';
import { listPlans } from '../../../apollo/queries/plans';
import { labelDisplayedRows, sanitizeSearch, updateQuery } from '../../../lib/helpers';
import { ListSearch as PlansListSearch } from '../../shared/ListSearch';
import { PlansHeader } from './PlansHeader';
import { Styled } from './styles';

export interface Props {
  className?: string;
}

const searchFields = ['planName'];

const sortableFields = ['planName', 'planDisplayName', 'createdAt'];

const getSortValue = (metadata: PlanWithMeta) => _.pickBy(_.pick(metadata.sort, sortableFields), (i) => i !== null);

/**
 * Gets list with up-to-date sort info
 */
export const onSort =
  (fetchMore: FetchMore, metadata: PlanWithMeta, key: string, setChecked: SetChecked, setCheckAll: SetCheckAll) =>
  (): ReturnType<FetchMore> => {
    const { pageSize, search } = metadata;
    let sort = { [key]: 'ASC' };
    setCheckAll(false);

    setChecked([]);

    if (_.get(metadata, `sort.${key}`, 'ASC') === 'ASC') sort = { [key]: 'DESC' };

    return fetchMore({
      updateQuery,
      variables: { input: { page: 0, pageSize, sort, search: sanitizeSearch(search, searchFields) } },
    });
  };

/**
 * Gets list with up-to-date offset info
 */
export const onChangePage =
  (fetchMore: FetchMore, metadata: PlanWithMeta, setChecked: SetChecked, setCheckAll: SetCheckAll) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    const { pageSize, search } = metadata;
    setChecked([]);
    setCheckAll(false);

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          page,
          pageSize,
          search: sanitizeSearch(search, searchFields),
          sort: getSortValue(metadata),
        },
      },
    });
  };

/**
 * Gets list with up-to-date limit info
 */
export const onChangeRowsPerPage =
  (fetchMore: FetchMore, metadata: PlanWithMeta, setChecked: SetChecked, setCheckAll: SetCheckAll) =>
  (event: React.ChangeEvent<HTMLInputElement>): ReturnType<FetchMore> => {
    const { search } = metadata;
    setChecked([]);
    setCheckAll(false);

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          page: 0,
          pageSize: event.target.value,
          search: sanitizeSearch(search, searchFields),
          sort: getSortValue(metadata),
        },
      },
    });
  };

/**
 * Gets list with up-to-date limit info
 */
export const onCheck = (
  event: React.ChangeEvent<HTMLInputElement>,
  checked: string[],
  setChecked: SetChecked,
  setCheckAll: SetCheckAll,
  plans: Plan[],
): void => {
  const planId = event.target.getAttribute('plan-altid') as string;

  if (checked.indexOf(planId) === -1) {
    setChecked(_.concat(checked, planId));
    if (_.concat(checked, planId).length === plans.length) {
      setCheckAll(true);
    }
  } else {
    setChecked(_.without(checked, planId));
    setCheckAll(false);
  }
};

/**
 * Gets list with up-to-date limit info
 */
export const onCheckAll = (
  setChecked: SetChecked,
  checkAll: boolean,
  setCheckAll: SetCheckAll,
  plans: Plan[] | undefined,
): void => {
  const allPlans = plans ? plans.map((plan) => plan?.altId || '').filter(Boolean) : [];

  if (checkAll) {
    setCheckAll(false);
    setChecked([]);
  } else {
    setCheckAll(true);
    setChecked(allPlans);
  }
};

/**
 * Search plans list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: PlanWithMeta,
  target: EventTarget & HTMLInputElement,
): ReturnType<FetchMore> => {
  // TODO: filter does not exist with PlanWithMeta
  const { pageSize, sort, filter } = metadata as PlanWithMeta & { filter: { status: Record<string, string> } };

  let search: Maybe<{ [key: string]: unknown }> = {};
  if (target.value.length) {
    searchFields.forEach((field) => {
      search = search || {};
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        filter: !_.isEmpty(filter) && filter.status ? { status: _.omit(filter.status, '__typename') } : null,
        search,
      },
    },
  });
};

/** Plans List view component. */
export const PlansView: React.FC<Props> = (props) => {
  const id = _.kebabCase('PlansList');
  const className = id;
  const [checked, setChecked] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const { data, fetchMore, loading } = useQuery(listPlans, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page: 0,
        pageSize: 10,
        sort: { planName: 'ASC' },
      },
    },
  });
  const debouncedFetchMore = (typeof fetchMore === 'function'
    ? _.debounce(fetchMore, 500)
    : fetchMore) as unknown as typeof fetchMore;
  const { edges, ...metadata } = data?.listPlans || {};

  const plans: Plan[] = edges?.map((edge: Plan) => {
    return { ...edge, altId: edge?.altId };
  });

  const columns = [
    { key: 'planName', label: 'Plan Name' },
    { key: 'planDescription', label: 'Description' },
    { key: 'planDisplayName', label: 'Display Name' },
    { key: 'createdAt', label: 'Creation Date' },
    { key: 'subscriptions', label: 'Number of subscribers' },
    { key: 'duplicate', label: '' },
  ];

  let customClass = '';
  if (props.className) customClass += ` ${props.className}`;

  return (
    <Styled className={className}>
      <PlansHeader className={className} total={metadata.total || 0} loading={loading} />
      <Paper className={customClass} component="section">
        <header className={className}>
          <div className={className}>
            <Typography className={className} variant="h6" component="h2">
              Plans
            </Typography>
            {/* <Typography className={ className } variant="caption" id={ `${id}-table-subtitle-card-total` }>Currently viewing { metadata.total }</Typography> */}
            <Typography className={className} variant="caption" id={'ay-table-subtitle-card-total'}>
              Currently viewing {metadata.total}
            </Typography>
          </div>

          <div className={className}>
            <Button
              className={className}
              disabled={!checked.length}
              // id={ `${id}-delete-plans-button` }
              id={'ay-delete-plans-button'}
            >
              <Delete color={checked.length ? 'secondary' : 'disabled'} />
              DELETE
            </Button>
            <Button
              className={className}
              color="secondary"
              component={Link}
              to="/plans/new"
              variant="contained"
              // id={ `${id}-add-plan-button` }
              id={'ay-add-plan-button'}
            >
              Add Plan
            </Button>
            <PlansListSearch
              className={`${className} search`}
              type="plan"
              id={`${id}-search-box-container`}
              onSearchChange={(search) => {
                setCheckAll(false);
                setChecked([]);
                onSearchChange(debouncedFetchMore, metadata, search);
              }}
            />
          </div>
        </header>

        <Divider />

        {/* <Table className={ className } id={ `${id}-list-table` }> */}
        <Table className={className} id={'ay-list-table'} data-testid="plan-management-table">
          <TableHead className={className}>
            <TableRow className={className}>
              {/* <TableCell className={ `${className} checkbox` } id={ `${id}-checkbox-check-all-in-list-container` }> */}
              <TableCell className={`${className} checkbox`} id={'ay-checkbox-check-all-in-list-container'}>
                <Checkbox
                  className={className}
                  indeterminate={checked.length > 0 && plans && checked.length < plans.length}
                  checked={
                    (checked.length > 0 && checkAll) || (plans && checked.length === plans.length && checked.length > 0)
                  }
                  onChange={() => onCheckAll(setChecked, checkAll, setCheckAll, plans)}
                  // id={ `${id}-checkbox-check-all-in-list` }
                  id={'ay-checkbox-check-all-in-list'}
                />
              </TableCell>

              {columns.map((column) => (
                // <TableCell key={ column.key } className={ `${className} ${column.key}` } component="th" id={ `${id}-${column.label}-column-header` }>
                <TableCell
                  key={column.key}
                  className={`${className} ${column.key}`}
                  component="th"
                  id={`ay-${column.label}-column-header`}
                >
                  <TableSortLabel
                    active={_.isString(_.get(metadata, `sort.${column.key}`))}
                    direction={(_.toLower(_.get(metadata, `sort.${column.key}`, 'asc')) || 'asc') as 'asc'}
                    onClick={onSort(fetchMore, metadata, column.key, setChecked, setCheckAll)}
                    disabled={!sortableFields.includes(column.key)}
                    // id={ `${id}-${column.label}-column-header-sort-label` }
                    id={`ay-${column.label}-column-header-sort-label`}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={className}>
            {!loading &&
              plans?.map((plan, index) => {
                return (
                  <TableRow key={plan.altId}>
                    {/* <TableCell className={ `${className} checkbox` } id={ `${id}-checkbox-check-plan-${index}-container` }> */}
                    <TableCell className={`${className} checkbox`} id={`ay-checkbox-check-plan-${index}-container`}>
                      <Checkbox
                        className={`${className} checkbox`}
                        checked={checked.indexOf(plan?.altId || '') !== -1}
                        inputProps={{
                          // @ts-ignore
                          'plan-altid': plan.altId,
                        }}
                        onChange={(e) => onCheck(e, checked, setChecked, setCheckAll, plans)}
                        // id={ `${id}-checkbox-check-plan-${index}` }
                        id={`ay-checkbox-check-plan-${index}`}
                      />
                    </TableCell>

                    {/* <TableCell className={ `${className} name` } id={ `${id}-plan-${index}-name-link-container` }> */}
                    <TableCell className={`${className} name`} id={`ay-plan-${index}-name-link-container`}>
                      {/* <Link className={ className } id={ `${id}-plan-${index}-name-link` } to={`/plans/${plan.altId}/`}>{_.get(plan, 'planName')}</Link> */}
                      <Link className={className} id={`ay-plan-${index}-name-link`} to={`/plans/${plan.altId}/`}>
                        {_.get(plan, 'planName')}
                      </Link>
                    </TableCell>
                    <TableCell
                      className={`${className} description`}
                      // id={ `${id}-plan-${index}-description` }
                      id={`ay-plan-${index}-description`}
                    >
                      {_.get(plan, 'planDescription')}
                    </TableCell>
                    <TableCell
                      className={`${className} display-name`}
                      // id={ `${id}-plan-${index}-display-name` }
                      id={`ay-plan-${index}-display-name`}
                    >
                      {_.get(plan, 'planDisplayName')}
                    </TableCell>
                    <TableCell
                      className={`${className} created-at`}
                      // id={ `${id}-plan-${index}-created-at` }
                      id={`ay-plan-${index}-created-at`}
                    >
                      {moment(_.get(plan, 'createdAt', '')).format('MM/DD/YY')}
                    </TableCell>
                    <TableCell
                      className={`${className} subscriptions`}
                      // id={ `${id}-plan-${index}-subscriptions` }
                      id={`ay-plan-${index}-subscriptions`}
                    >
                      {_.get(plan, 'subscriptions')}
                    </TableCell>
                    {/* <TableCell className={ className } align="right" id={ `${id}-plan-${index}-duplicate-button-container` }> */}
                    <TableCell className={className} align="right" id={`ay-plan-${index}-duplicate-button-container`}>
                      {/* <Tooltip title="Duplicate" enterDelay={ 200 } leaveDelay={ 200 } id={ `${id}-plan-${index}-duplicate-tooltip` }> */}
                      <Tooltip
                        title="Duplicate"
                        enterDelay={200}
                        leaveDelay={200}
                        id={`ay-plan-${index}-duplicate-tooltip`}
                      >
                        <Link
                          to="/plans/new"
                          state={{
                            planId: plan.altId,
                          }}
                        >
                          <IconButton className={className} id={`ay-plan-${index}-duplicate-button`} color="secondary">
                            <PlaylistAdd color="primary" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>

          {/* <TableFooter className={ className } id={ `${id}-plan-table-footer` }> */}
          <TableFooter className={className} id={'ay-plan-table-footer'}>
            {/* <TableRow className={ className } id={ `${id}-plan-table-footer-row` }> */}
            <TableRow className={className} id={'ay-plan-table-footer-row'}>
              <TablePagination
                backIconButtonProps={{ id: `${id}-table-footer-back-button` }}
                className={className}
                count={metadata.total || 0}
                onPageChange={onChangePage(fetchMore, metadata, setChecked, setCheckAll)}
                onRowsPerPageChange={onChangeRowsPerPage(fetchMore, metadata, setChecked, setCheckAll)}
                labelDisplayedRows={labelDisplayedRows(`${id}-table-footer-count`)}
                nextIconButtonProps={{ id: `${id}-table-footer-next-button` }}
                page={metadata.page || 0}
                rowsPerPage={metadata.pageSize || 10}
                rowsPerPageOptions={[5, 10, 25]}
                SelectProps={{ id: `${id}-table-footer-select` }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Styled>
  );
};
