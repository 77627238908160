import { gql } from '@apollo/client';

export const listMappedSites = gql`
  query listMappedSites($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
        chargers {
          edges {
            altId
            chargerName
            cid
            fieldStationStatus
            chargerModel {
              altId
              modelName
            }
          }
          total
          search {
            cid {
              iLike
            }
            chargerName {
              iLike
            }
            site_address1 {
              iLike
            }
            site_locality {
              iLike
            }
            site_administrativeArea {
              iLike
            }
            site_postalCode {
              iLike
            }
            site_property_propertyName {
              iLike
            }
          }
          sort {
            cid
            chargerName
            fieldStationStatus
            propertyName
            chargerModel_modelName
          }
          filter {
            chargerModel_altId {
              in
            }
            fieldStationStatus {
              in
            }
            tags {
              or {
                contains
              }
            }
          }
        }
        altId
        latitude
        longitude
        siteName
        property {
          altId
          host {
            altId
          }
        }
        sid
        siteStatusId
      }
      page
      pageSize
      sort {
        sid
        siteName
        hostName
      }
      filter {
        siteStatusId {
          in
        }
      }
      search {
        siteName {
          iLike
        }
        sid {
          iLike
        }
        property_host_hostName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
      total
    }
  }
`;
