import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import { Button, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listVehicleConnectors } from '../../../../../apollo/queries/models';
import { defaultPlanElement, PlanElement } from '../../../../shared/PlanElement';
import { initialValues } from '../initialValues';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plan details component
 */
export const PlanElements: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanElements');
  const className = id;
  const { isSubmitting, setFieldValue, values } = useFormikContext<typeof initialValues>();

  const { data: connectorTypeOptions, loading: connectorTypeOptionsLoading } = useQuery<Query>(listVehicleConnectors);

  const shapedConnectorTypeOptions: LabelValue<number>[] = useMemo(() => {
    if (!connectorTypeOptionsLoading) {
      return (connectorTypeOptions?.listVehicleConnectors?.edges || []).map((c) => ({
        label: c?.name || '',
        value: Number(c?.id),
      }));
    }

    return [];
  }, [connectorTypeOptions?.listVehicleConnectors?.edges, connectorTypeOptionsLoading]);

  const elementsLength = _.get(values, 'elements', []).length ? values.elements.length : 0;
  const maxConnectorTypes = shapedConnectorTypeOptions.length;

  const hasMaxPlanElements = elementsLength === maxConnectorTypes;

  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  const handleAddElement = useCallback(() => {
    const [{ value: connectorType }] = _.filter(
      shapedConnectorTypeOptions,
      // TODO: unsure if this is right
      (option) => !_.find(values.elements, (element) => +option.value === +element.connectorType),
    );
    const value = [...values.elements, { ...defaultPlanElement, connectorType }];

    setFieldValue('elements', value);
  }, [setFieldValue, shapedConnectorTypeOptions, values.elements]);

  return (
    <StyledAccordion id={parentId} className={customClass} defaultExpanded={true}>
      <AccordionSummary className={className} component="header" expandIcon={<ExpandMoreIcon />} id={`${id}-header`}>
        <Typography className={className} variant="h6" component="h2">
          Plan Elements
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} details`}>
        <header className={className}>
          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting || hasMaxPlanElements}
            id={`${id}-panel-header-add-button`}
            onClick={handleAddElement}
            variant="contained"
            data-testid={`${id}-panel-header-add-button`}
          >
            Add Element
          </Button>
        </header>
        <section className={className}>
          {values?.elements?.map((value, i) => (
            <PlanElement className={className} key={i} path={`elements[${i}]`} showDuplicate={!hasMaxPlanElements} />
          ))}
        </section>
      </AccordionDetails>
    </StyledAccordion>
  );
};
