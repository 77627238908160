/* stylelint-disable color-no-hex */
import React from 'react';
import clsx from 'clsx';
import { ValueFormat } from '@nivo/core';
import { ResponsivePie } from '@nivo/pie';
import { Box } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';

export type PieChartData = {
  label: React.ReactNode | string;
  value: number;
  color: string;
  [x: string | number | symbol]: unknown;
};

export type Props = {
  id?: string;
  'data-testid'?: string;
  caption?: React.ReactNode;
  data: PieChartData[];
  height?: number;
  className?: string;
  valueFormat?: ValueFormat<number>;
};

export const PieChart: React.FC<Props> = ({
  data,
  height = 400,
  caption,
  className: parentClass,
  id: parentId,
  valueFormat,
  ...props
}) => {
  const className = 'pie-chart-component';

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="relative"
      className={clsx(className, parentClass)}
      id={parentId}
      {...props}
    >
      <Box height={height} width={height * 0.7} display="flex" flexDirection="row" position="relative">
        {caption && (
          <Box
            display="flex"
            position="absolute"
            width="100px"
            height="100px"
            alignItems="center"
            justifyContent="center"
            top={'calc(50% - 50px)'}
            left={'calc(50% - 50px)'}
            zIndex={'2'}
          >
            {caption}
          </Box>
        )}
        <ResponsivePie
          data={data}
          innerRadius={0.5}
          isInteractive={false}
          padAngle={0.5}
          borderWidth={0}
          enableArcLinkLabels={false}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['brighter', 4]],
          }}
          colors={{ datum: 'data.color' }}
          legends={[]}
          valueFormat={valueFormat}
        />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" marginLeft={theme.spacing(1 / 2)}>
        {data?.map((value) => (
          <Box
            display="flex"
            flexDirection="row"
            marginTop={theme.spacing(1 / 4)}
            borderColor={theme.palette.grey[300]}
            fontSize={theme.typography.fontSize * 0.8}
          >
            <Box
              bgcolor={value?.color || '#ba9730'}
              marginRight={theme.spacing(1 / 8)}
              width={theme.spacing(2)}
              height={theme.spacing(2)}
            />
            {value.label || <></>}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
