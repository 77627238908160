import { gql } from '@apollo/client';

export const createAccountVehicle = gql`
  mutation createAccountVehicle($accountVehicleInput: CreateAccountVehicleInput) {
    createAccountVehicle(input: $accountVehicleInput) {
      altId
      vehicle {
        altId
        makeModelYear
        vehicleConnectors {
          id
          connectorName: name
        }
      }
      nickName
      vin
      macAddress
      deletedAt
    }
  }
`;
