import React from 'react';
import { Styled } from './styles';
import { Card, CardHeader } from 'src/components/shared/Card';

export interface Props {
  description?: string;
}

export const DescriptionSection: React.FC<Props> = (props) => {
  return (
    <Styled>
      <Card>
        <CardHeader title="Description" />
        <p>{props.description}</p>
      </Card>
    </Styled>
  );
};
