import { QueryResult } from '@apollo/client';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Account, Driver } from 'src/@types';
import { ServerErrors } from 'src/@types/shared';
import { createAccountOwner, getAccount, updateAccountOwner } from 'src/apollo/queries/accounts';
import { TransformErrorOptions, useFormikSubmit } from 'src/lib/hooks';
import { AccountProfileForm, FormValues } from './AccountProfileForm';
import { validations as validationSchema } from './AccountProfileForm/validations';

export type Props = {
  loading: boolean;
  fetchMore: QueryResult<never, never>['fetchMore'];
  className?: string;
  account: Account;
};

const initialAdressValue = {
  altId: '',
  address1: '',
  address2: '',
  locality: '',
  postalCode: '',
  administrativeArea: '',
  country: 'USA',
};

const initialValues: FormValues = {
  isBillingAddressSameAsMailing: false,
  altId: '',
  contracts: '',
  email: '',
  enrolledOn: '',
  firstName: '',
  lastName: '',
  phone: '',
  mailingAddress: initialAdressValue,
  billingAddress: initialAdressValue,
};

export const AccountProfile: React.FC<Props> = (props) => {
  const { account, fetchMore: callback } = props;
  const { altId } = useParams();

  const callbackArgs = {
    getAccount,
    variables: {
      accountInput: { altId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999 },
      cardsInput: { pageSize: 999 },
    },
  };

  const omitFields = [
    'contracts',
    'isBillingAddressSameAsMailing',
    'mailingAddress.__typename',
    'billingAddress.__typename',
  ];
  const transformValues = (values: FormValues) => {
    const { altId: accountId, isBillingAddressSameAsMailing } = values;

    const input = _.omit(values, omitFields);

    if (altId) {
      // will used as input to call updateAccountOwner
      const updatedBillingAddress = isBillingAddressSameAsMailing
        ? { ...input.mailingAddress, altId: input?.billingAddress?.altId }
        : input.billingAddress;

      return {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        accountId,
        vendorId: account.vendorId,
        mailingAddress: input.mailingAddress,
        billingAddress: updatedBillingAddress,
        notificationPreferences: values.notificationPreferences?.edges?.map((notif) => _.omit(notif, ['__typename'])),
      };
    } else {
      // will used as input to call createAccountOwner
      return _.omit(input, ['altId', 'enrolledOn', 'billingAddress.altId', 'mailingAddress.altId']);
    }
  };

  const transformResponse = (updateAccountResponseData: Driver) => ({
    ...updateAccountResponseData,
    driverAltId: updateAccountResponseData.altId,
    ...updateAccountResponseData.account,
  });

  const transformError = ({ error, setFieldError }: TransformErrorOptions) => {
    let snackMessage = error.message || 'A problem has occurred. Please try again.';

    error.graphQLErrors?.forEach((graphQLError: ServerErrors) => {
      const fields = graphQLError.extensions.exception.fields || [];

      if (
        graphQLError.message &&
        !/Mutation.createAccountOwner|Mutation.updateAccountOwner/.test(graphQLError.message)
      ) {
        snackMessage = graphQLError.message;
        if (graphQLError.message === 'The user with the provided phone number already exists.') {
          snackMessage = 'Please correct the errors and try again.';
          setFieldError('phone', 'A user with the provided phone number already exists.');
        }
      }

      if (!_.isEmpty(fields)) {
        fields.forEach(({ message, field }) => {
          snackMessage = 'Please correct the errors and try again.';
          const fieldName = field === 'driver_email' ? 'email' : _.camelCase(field);
          message = field === 'driver_email' ? 'A user with the provided email address already exists.' : message;
          setFieldError(fieldName, message);
        });
      }

      graphQLError.errorMessage?.forEach(({ message, path }) => {
        snackMessage = 'Please correct the errors and try again.';
        setFieldError(_.camelCase(path), message);
      });
    });

    return snackMessage;
  };

  const handleSubmit = useFormikSubmit('AccountOwner', createAccountOwner, updateAccountOwner, {
    transformValues,
    altId,
    callback,
    callbackArgs,
    initialValues,
    transformResponse,
    transformError,
    noFormReset: true,
  });

  useEffect(() => {
    const drivers = account?.drivers;
    const accountOwner: Driver = _.find(drivers?.edges, { driverType: 'OWNER' }) as Driver;
    initialValues.id = accountOwner?.id || undefined;
    initialValues.altId = account?.altId || '';
    initialValues.firstName = accountOwner?.firstName || '';
    initialValues.lastName = accountOwner?.lastName || '';
    initialValues.email = accountOwner?.email || '';
    initialValues.phone = accountOwner?.phone || '';
    initialValues.driverAltId = accountOwner?.altId || undefined;
    initialValues.notificationPreferences = accountOwner?.notificationPreferencesForOp || undefined;

    initialValues.mailingAddress = (account?.mailingAddress || initialAdressValue) as FormValues['mailingAddress'];
    initialValues.billingAddress = (account?.billingAddress || initialAdressValue) as FormValues['billingAddress'];

    initialValues.isBillingAddressSameAsMailing =
      _.isEqual(_.omit(initialValues.mailingAddress, 'altId'), _.omit(initialValues.billingAddress, 'altId')) &&
      !!account.mailingAddress;
  }, [account]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      <AccountProfileForm {...props} />
    </Formik>
  );
};
