import { useFormikContext } from 'formik';
import React from 'react';
import { initialValues } from '../initialValues';
import { ChargerConnector } from './ChargerConnector';
import { Styled } from './styles';

/**
 * Charger manage tab component
 */
export const ChargerManageTab: React.FC = () => {
  const className = 'chargermanagetab-component';
  const { values } = useFormikContext<typeof initialValues>();

  return (
    <>
      {values?.evses?.edges?.map((evse, i) =>
        evse?.connectors?.edges?.map((connector, j) => (
          <Styled key={`${i}_${j}`}>
            <ChargerConnector className={className} connectorIndex={j} evseIndex={i} />
          </Styled>
        )),
      )}
    </>
  );
};
