import { TextInfo, theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { DataContext } from 'src/components/shared/DataContext';
import { useDataContext } from 'src/components/shared/DataContext/useDataContext';
import { PageActions } from 'src/components/shared/PageActions';
import { SubTitle } from 'src/components/shared/SubTitle';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';

export type FormValues = {
  chargerGroupName: string;
  description?: string | null;
};

export type ChargerGroupFormProps = {
  isNew: boolean;
  altId?: string;
};

const StyledForm = styled(Form)`
  .half {
    width: 100%;
    max-width: 500px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)}px;
  }

  .loading {
    color: ${theme.palette.common.white};
  }

  .subtitle {
    margin-bottom: ${theme.spacing(2)}px;
  }
`;

export const ChargerGroupForm = ({ isNew, altId }: ChargerGroupFormProps) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const className = 'ChargerGroupForm';
  const id = _.kebabCase(className);
  const [dataRef, setData] = useDataContext();
  const { values, touched, errors, handleBlur, handleChange, isSubmitting } = useFormikContext<FormValues>();

  return (
    <DataContext context={id}>
      <StyledForm ref={dataRef}>
        <Typography variant="h6">Description</Typography>
        <SubTitle>Provide a name and description for this charger group</SubTitle>

        <Box className="fields">
          <TextInfo
            className={clsx(className, 'half')}
            disabled={isSubmitting}
            error={errors.chargerGroupName && touched.chargerGroupName}
            helpertext={touched.chargerGroupName && errors.chargerGroupName}
            id={`${id}-name-input`}
            label="Name *"
            labelProps={{ className }}
            name="chargerGroupName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.chargerGroupName}
            {...setData('name-input')}
          />
          <TextInfo
            className={clsx(className, 'half')}
            disabled={isSubmitting}
            error={errors.description && touched.description}
            helpertext={touched.description && errors.description}
            id={`${id}-description-input`}
            label="Description"
            labelProps={{ className }}
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            {...setData('description-input')}
          />
        </Box>

        <PageActions>
          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-cancel-button`}
            {...setData('cancel-button')}
            size="large"
            onClick={() => {
              navigate(`/extend-plus/charger-groups/${isNew ? '' : `${altId}/details`}`);
              track(events.chargerGroupDetailsPage.CLICKED_CHARGER_GROUP_SAVE_DESCRIPTIONS_BUTTON);
            }}
          >
            Back
          </Button>

          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-save-button`}
            size="large"
            type="submit"
            variant="contained"
            {...setData('save-button')}
            onClick={() => track(events.chargerGroupDetailsPage.CLICKED_CHARGER_GROUP_CANCEL_DESCRIPTION_EDIT_BUTTON)}
          >
            {isSubmitting ? <CircularProgress size={theme.spacing(2.5)} className="loading" /> : 'Save'}
          </Button>
        </PageActions>
      </StyledForm>
    </DataContext>
  );
};
