import { QueryResult } from '@apollo/client';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { ExtendUser } from 'src/@types';
import { useModalContext } from 'src/contexts/ModalContext';
type UpdateProps = {
  data: ExtendUser;
  fetchMore?: QueryResult<never, never>['fetchMore'];
};

export const EditButton: React.FC<UpdateProps> = ({ data, fetchMore }) => {
  const { setModalState } = useModalContext();
  const hostAltId = _.get(data, 'hosts.edges[0].altId') || null;

  const onClick = () => {
    setModalState({
      modalProps: {
        data,
        fetchMore,
      },
      modalName: 'AssignUserModal',
      modalVisible: true,
    });
  };

  return (
    <>
      {!!hostAltId ? (
        <Button color="secondary" size="large" data-testid="edit-user-button" onClick={onClick}>
          Edit
        </Button>
      ) : (
        <Button color="primary" size="large" data-testid="reassign-user-button" onClick={onClick}>
          Reassign
        </Button>
      )}
    </>
  );
};
