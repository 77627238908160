/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

export const transformChargerModelResponse = (data: any, shapedConnectorTypeOptions?: any): any => {
  const {
    __typename: modelType,
    evses: { edges: evses },
    ...model
  } = data || {};
  if (evses && evses.length) {
    const transformedEvses = evses.map((evse: any) => {
      const {
        altId: evseId,
        connectors: { edges },
      } = evse;
      const connectors = edges.map(({ __typename: connectorType, ...rest }: any) => {
        return {
          ...rest,
          connectorType: _.find(shapedConnectorTypeOptions, { label: _.get(rest, 'connectorType.connectorName') })
            ? _.find(shapedConnectorTypeOptions, { label: _.get(rest, 'connectorType.connectorName') }).value
            : null,
        };
      });
      while (connectors.length < 1) {
        connectors.push({ evseId });
      }
      return { altId: evseId, connectors };
    });
    return {
      ...model,
      evses: transformedEvses.reverse(),
      powerOutput: !_.isNull(_.get(model, 'powerOutput', '')) ? _.get(model, 'powerOutput', '').toString() : '',
      breakerRating: !_.isNull(_.get(model, 'breakerRating', '')) ? _.get(model, 'breakerRating', '').toString() : '',
      powerFactor: !_.isNull(_.get(model, 'powerFactor', '')) ? _.get(model, 'powerFactor', '').toString() : '',
      efficiency: !_.isNull(_.get(model, 'efficiency', '')) ? _.get(model, 'efficiency', '').toString() : '',
      weight: !_.isNull(_.get(model, 'weight', '')) ? _.get(model, 'weight', '').toString() : '',
    };
  }
  return { ...model };
};
