import { gql } from '@apollo/client';
import { FullPlan } from './FullPlan';

export const createPlan = gql`
  mutation createPlan($planInput: CreatePlanInput!) {
    createPlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlan}
`;
