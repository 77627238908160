/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Table } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(Table)`
  box-sizing: border-box;
  /* TODO: use color from theme */
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  overflow: hidden;

  thead > tr {
    border-radius: 8px 8px 0 0;
    /* TODO: use color from theme */
    background-color: #d4f0ff;

    th {
      border-bottom: none;
      font-size: 10px;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }

  th {
    padding: ${`${spacing(2) / 2}px ${spacing(3) / 2}px`};

    &:first-child {
      padding-left: ${spacing(3)}px;
    }
  }
`;
