import { ApolloError } from '@apollo/client';
import { Dropdown } from '@evgo/react-material-components';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ExtendUser,
  DeleteExtendUserHostsMutationFn,
  useListExtendHostsQuery,
  useGetFirebaseUserForExtendLazyQuery,
  GetFirebaseUserForExtendQuery,
  useDeleteExtendUserHostsMutation,
} from 'src/@types';
import { ModalProps } from 'src/@types/client';
import { listExtendUsers as LIST_EXTEND_USERS_QUERY } from 'src/apollo/queries/extendPlus';
import config from 'src/config';
import { useConfirmationDialog, useSnackbar } from 'src/lib/hooks';
import { StyledModal } from '../../../../../../shared/StyledModal';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export const initialValues = {
  username: '',
  firebaseId: '',
  hostIds: '',
};

interface Props {
  open?: boolean;
  modalProps?: ModalProps;
  className?: string;
  selected?: ExtendUser;
}

export const unassignCallBack = (selected: ExtendUser | undefined, onDeleteHosts: DeleteExtendUserHostsMutationFn) => {
  const hostAltId = _.get(selected, 'hosts.edges[0].altId');
  if (selected?.altId)
    onDeleteHosts({ variables: { deleteExtendUserHostsInput: { altId: selected.altId, hostIds: [hostAltId] } } });
};

export const onUnassignClick = (
  selected: ExtendUser | undefined,
  confirmationDialog: ReturnType<typeof useConfirmationDialog>,
  onDeleteHosts: DeleteExtendUserHostsMutationFn,
) => {
  const hostName = _.get(selected, 'hosts.edges[0].hostName', '');
  confirmationDialog.open({
    title: 'Unassign User',
    body: `Are you sure you want to remove access for this user from the host ${hostName}?`,
    buttonConfirmText: 'Unassign User',
    callback: () => unassignCallBack(selected, onDeleteHosts),
  });
};

export const getHelperTextAndEmailAdornment = (
  getFirebaseUser: () => void,
  errors: Record<string, unknown> | undefined,
  userLoading: boolean,
  foundUser: GetFirebaseUserForExtendQuery['getFirebaseUserForExtend'] | undefined,
  values: Record<string, unknown>,
  setValues: (values: Record<string, string>) => void,
  userError: ApolloError | undefined,
  userEmail: string,
) => {
  let emailAdornment = (
    <InputAdornment position="end">
      <Button color="secondary" onClick={getFirebaseUser} data-testid="create-extend-user-modal-check-button">
        Check
      </Button>
    </InputAdornment>
  );
  let helpertext = !!_.get(errors, 'username') ? 'Required' : '';

  if (userLoading) {
    emailAdornment = <img src="https://i.stack.imgur.com/kOnzy.gif" alt="loading..." style={{ height: '25px' }} />;
  }
  if (foundUser && foundUser.username && values.username === foundUser.username && userEmail === values.username) {
    helpertext = 'Email matches';
    emailAdornment = <Check style={{ color: '#66BB6A' }} />;
    if (!values.firebaseId || values.firebaseId !== foundUser.firebaseId) {
      setValues({ ...values, firebaseId: foundUser.firebaseId });
    }
  }
  if (userError && userEmail && userEmail === values.username) {
    helpertext = 'Email does not exist in our system';
  }
  return {
    helpertext,
    emailAdornment,
  };
};

export const AssignUserForm: React.FC<Props> = (props) => {
  const className = 'create-extend-users-modal-component';
  const confirmationDialog = useConfirmationDialog();
  const snackBar = useSnackbar();
  const { open, selected } = props;
  const { setModalState } = useModalContext();
  const [userEmail, setUserEmail] = useState('');
  const { data, loading } = useListExtendHostsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { input: { page: 0, pageSize: config.maxPageSize } },
  });
  const { handleChange, setFieldValue, setValues, submitForm, values, errors } =
    useFormikContext<Record<string, string>>();

  const [getUser, { data: userData, loading: userLoading, error: userError }] = useGetFirebaseUserForExtendLazyQuery({
    onCompleted: ({ getFirebaseUserForExtend }) => {
      setValues({ ...values, firebaseId: getFirebaseUserForExtend.firebaseId });
    },
    onError: () => setValues({ ...values, firebaseId: '' }),
  });

  const { getFirebaseUserForExtend: foundUser } = userData || {};
  const getFirebaseUser = () => {
    getUser({ variables: { input: { email: values.username } } });
    setUserEmail(`${values.username}`);
  };

  useEffect(() => {
    if (selected && selected.altId) {
      const { hosts, username, firebaseId } = selected;
      setValues({
        username,
        firebaseId,
        hostIds: _.get(hosts, 'edges[0].altId', ''),
      });
    } else {
      setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (!values.username) {
      setUserEmail('');
    }
  }, [values.username]);

  const [onDeleteHosts] = useDeleteExtendUserHostsMutation({
    refetchQueries: () => [
      {
        query: LIST_EXTEND_USERS_QUERY,
        variables: {
          input: {
            pageSize: 9999,
          },
        },
      },
    ],
    onCompleted: () => {
      snackBar.success('User unassigned');
    },
    onError: () => {
      snackBar.error('Error unassigning user');
    },
  });

  const getFirebaseUserOnSubmit = async () => {
    getFirebaseUser();
    if (foundUser && foundUser.username && values.username === foundUser.username && userEmail === values.username) {
      if (!values.firebaseId || values.firebaseId !== foundUser.firebaseId) {
        setValues({ ...values, firebaseId: foundUser.firebaseId });
      }
      return true;
    }
    return false;
  };
  const onSubmit = async () => {
    if (values.username && !values.firebaseId) await getFirebaseUserOnSubmit();
    return submitForm();
  };

  return (
    <StyledModal
      className={clsx(className, 'modal')}
      onClose={() => {
        setValues(initialValues);
        setModalState({
          modalName: '',
          modalProps: {},
          modalVisible: false,
        });
      }}
      open={!!open}
      title="Assign User"
      subtitle="Assign a Firebase user to an eXtend+ host account"
      onClick={onSubmit}
      primaryButtonText={!!selected?.altId ? 'Update User' : 'Add user'}
      secondaryButtonClick={() => {
        setValues(initialValues);
        setModalState({
          modalName: '',
          modalProps: {},
          modalVisible: false,
        });
      }}
      secondaryButtonText="Cancel"
    >
      <Styled className={clsx(className, 'content')}>
        <Form className={className}>
          {selected ? (
            <TextField
              className={clsx(className, 'form-control')}
              disabled
              label="Email *"
              name="username"
              value={values.username || 'N/A'}
              data-testid="create-extend-user-modal-username"
              id="create-extend-user-modal-username"
              InputProps={{
                disableUnderline: true,
              }}
            />
          ) : (
            <TextField
              className={clsx(className, 'form-control')}
              label="Email *"
              name="username"
              value={values.username}
              onChange={(e) => {
                handleChange(e);
                if (values.firebaseId) setFieldValue('firebaseId', '');
              }}
              data-testid="create-extend-user-modal-username-input"
              helperText={
                getHelperTextAndEmailAdornment(
                  getFirebaseUser,
                  errors,
                  userLoading,
                  foundUser,
                  values,
                  setValues,
                  userError,
                  userEmail,
                ).helpertext
              }
              error={!!errors.username || !!(userError && userEmail && userEmail === values.username)}
              focused
              placeholder="Input the exact email address"
              InputProps={{
                endAdornment: getHelperTextAndEmailAdornment(
                  getFirebaseUser,
                  errors,
                  userLoading,
                  foundUser,
                  values,
                  setValues,
                  userError,
                  userEmail,
                ).emailAdornment,
              }}
            />
          )}
          <TextField
            className={clsx(className, 'form-control')}
            disabled
            label="Firebase ID *"
            name="firebaseId"
            value={values.firebaseId || 'N/A'}
            helperText={!values.firebaseId && !!errors.firebaseId && 'Required'}
            error={!values.firebaseId && !!errors.firebaseId}
            data-testid="create-extend-user-modal-firebaseid"
            id="create-extend-user-modal-firebaseid"
            InputProps={{
              disableUnderline: true,
            }}
          />
          <Dropdown
            className={clsx(className, 'form-control')}
            error={!!errors.hostIds}
            loading={loading}
            data-testid="create-extend-user-modal-firebaseid-dropdown"
            label="eXtend+ Host *"
            name="hostIds"
            isSearchable={true}
            helpertext={errors.hostIds}
            onChange={handleChange}
            options={data?.listExtendHosts?.edges
              ?.filter((host) => host?.host?.hid !== 'H253')
              .map((host) => ({ value: host?.host?.altId, label: host?.host?.hostName }))}
            value={_.get(values, 'hostIds')}
          />
          <br />
          {!!selected?.hosts?.edges?.length && (
            <Button
              color="secondary"
              size="small"
              data-testid="extend-user-form-unassign-user-button"
              onClick={() => onUnassignClick(selected, confirmationDialog, onDeleteHosts)}
            >
              UNASSIGN USER
            </Button>
          )}
        </Form>
      </Styled>
    </StyledModal>
  );
};
