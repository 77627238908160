/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { createGlobalStyle } from 'styled-components';
import robotoLight from '../assets/fonts/Roboto-Light.ttf';
import robotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import robotoRegular from '../assets/fonts/Roboto-Regular.ttf';

const { spacing, palette } = theme;

// TODO: remove global style and configure material ui theme override
const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(${robotoLight}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(${robotoRegular}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(${robotoMedium}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

* {
  margin: 0px;
  outline: 0px;
  border: 0px;
  padding: 0px;
}

html,
body,
#root {
  overflow: hidden;
  height: 100%;
}

body,
#root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

body {
  background-color: ${theme.palette.background.default};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0px;
}

h2 {
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

h3 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
}

h4 {
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 0.5px;
}

.panel-details {
  display: flex;
  flex-direction: column;
  padding: 0;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
  }

  > div {
    flex: 1;
    display: flex;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      width: 100%;
      padding: ${`0 ${spacing(6)}px ${spacing(2)}px`};

      .form-control,
      .select,
      .label {
        width: calc(100% - ${spacing(2)}px);
        padding: ${`${spacing(2)}px ${spacing()}px 0`};

        &.half {
          width: calc(50% - ${spacing(2)}px);
        }

        &.thirds {
          width: calc(33.33% - ${spacing(2)}px);
        }

        &.two-thirds {
          width: calc(66.66% - ${spacing(2)}px);
        }
      }
      .form-control {
        label {
          transform: translate(${`${spacing(2)}px, ${spacing(3)}px`}) scale(1);

          &[data-shrink='true'] {
            transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
          }
        }
      }
    }
  }
}

.panel-actions {
  padding: ${spacing(3)}px;
  background-color: #ffffff;

  button {
    svg {
      &.left {
        margin-right: ${spacing()}px;
      }
    }
  }
}

.MuiTable-root {
  .MuiTableHead-root th {
    background-color: ${palette.grey[100]};
    font-weight: 500;
    font-size: 16px;
    border: none;
  }

  .MuiTableHead-root th,
  .MuiButtonBase-root.MuiTableSortLabel-root {
    color: ${palette.grey[600]};
  }
}
`;

export default GlobalStyle;
