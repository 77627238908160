/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from '@material-ui/core';
import { ArrowBack, FilterTiltShift } from '@material-ui/icons';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Styled } from './styles';

export interface Props {
  modelName?: string;
}

/**
 * Model Header component
 */
export const DetailPageHeader: React.FC<Props> = ({ modelName }) => {
  const location = useLocation();

  const className = 'detailpageheader-component';
  const { chargerModelId = '' } = location.state ? (location.state as any) : {};
  let title = modelName || 'Create Model';

  if (chargerModelId) {
    title = 'Create Model';
  }

  return (
    <Styled className={className}>
      <div className={className}>
        <section className={`${className} back`}>
          <Button className={className} size="small" to="/models" component={Link}>
            <ArrowBack className={`${className} arrow`} />
            All Models
          </Button>
        </section>

        <section className={`${className} title`}>
          <FilterTiltShift className={className} />
          <Typography className={className} variant="h5">
            {title}
          </Typography>
        </section>
      </div>
    </Styled>
  );
};
