import * as Yup from 'yup';

export const validations = Yup.object().shape({
  manufacturer: Yup.string().required('Required'),
  unitType: Yup.string().required('Required'),
  inputAcVoltage: Yup.string().required('Required'),
  modelName: Yup.string().trim().required('Required').label('Model name'),
  modelSku: Yup.string().typeError('Invalid Model SKU').trim().required('Required').label('Model SKU'),
  powerOutput: Yup.string()
    .label('Power output')
    .required('Required')
    .test(
      'validateNumberMax',
      'Must be a number between 0 and 1,000,000',
      (value = '') => Number(value) >= 0 && Number(value) < 1000000 && value.trim() !== '',
    ),
  breakerRating: Yup.string()
    .label('Breaker rating')
    .nullable(true)
    .test(
      'validateNumberMax',
      'Must be a number between 0 and 1,000,000',
      (value = '') => Number(value) >= 0 && Number(value) < 1000000,
    ),
  powerFactor: Yup.string()
    .label('Power factor')
    .nullable(true)
    .test(
      'validateNumberMax',
      'Must be a number between 0 and 1,000,000',
      (value = '') => Number(value) >= 0 && Number(value) < 1000000,
    ),
  efficiency: Yup.string()
    .nullable(true)
    .label('Efficiency')
    .test(
      'validateNumberRange',
      'Must be a number between 0 and 100',
      (value = '') => Number(value) >= 0 && Number(value) <= 100,
    ),
  weight: Yup.number().typeError('Must be a positive number').label('Weight').positive().nullable(true),
  evses: Yup.array().of(
    Yup.object().shape({
      connectors: Yup.array().of(
        Yup.object().shape({
          connectorType: Yup.string().required('Required'),
          connectorMaxCurrent: Yup.string()
            .label('Connector Max. current')
            .required('Required')
            .typeError('Invalid connector max. current')
            .test(
              'validateNumberMax',
              'Must be a number between 0 and 1,000,000',
              (value = '') => Number(value) >= 0 && Number(value) < 1000000 && value.trim() !== '',
            ),
          outputVoltageRange: Yup.string()
            .nullable(true)
            .test('hasValidRangeValues', 'Invalid range', (value) => {
              const num = value ? value.replace(/\D/g, '') : '';
              if (num.length > 1 && num.length < 6) {
                return false;
              }
              return true;
            })
            .test('validRange', 'First value in range must be less than second number', (value) => {
              if (!value || value.includes('_')) {
                return true;
              }
              const [first, last] = value.split('-');
              return Number(first) < Number(last);
            }),
        }),
      ),
    }),
  ),
  simultaneousChargeEnabled: Yup.boolean().nullable(),
});
