import { useMemo } from 'react';
import { format, differenceInDays } from 'date-fns';
import { DateRangeValue } from 'src/components/shared/DateRangeField';
import { SessionAggregation } from 'src/@types';

export function useUTCDateRangeValue({ startDate, endDate }: Required<DateRangeValue>) {
  const fmt = 'yyyy-MM-dd';

  return useMemo(
    () => ({
      startDate: new Date(`${format(startDate, fmt)}T00:00:00.000Z`),
      endDate: new Date(`${format(endDate, fmt)}T23:59:59.999Z`),
    }),
    [startDate, endDate],
  );
}

export function useUtilizationBarChartAggregateBy({ startDate, endDate }: Required<DateRangeValue>) {
  return useMemo(() => {
    const diffDays = differenceInDays(endDate, startDate) + 1;
    if (diffDays < 2) {
      return SessionAggregation.Hour;
    }
    if (diffDays < 32) {
      return SessionAggregation.Day;
    }
    return SessionAggregation.Month;
  }, [startDate, endDate]);
}
