import { Box, Typography } from '@material-ui/core';
import { CancelOutlined } from '@material-ui/icons';
import React from 'react';

export const AccessDenied: React.FC = () => {
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      data-testid="access-denied-view"
    >
      <CancelOutlined fontSize="large" color="error" />
      <Typography>
        Your user does not have access to this page. Please contact your support manager for further assistance.
      </Typography>
    </Box>
  );
};
