/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  margin-bottom: ${spacing(3)}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  p {
    padding: ${spacing()}px 0;
  }
`;
