/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(TableRow)`
  .session-details {
    padding: ${`${spacing()}px ${spacing(3)}px ${spacing(2)}px`};
    border-bottom: 1px solid #d4f0ff;

    h2,
    h6 {
      color: #23758d;
    }

    > :last-child {
      display: flex;
      margin-top: ${spacing(2)}px;

      span {
        margin-right: ${spacing(3)}px;
        color: #6f878f;
      }
    }
  }

  .meter-readings {
    article {
      display: flex;

      > div {
        flex-basis: 50%;
        padding: 25px 100px;

        .meter-list {
          border: 1px solid #e0e0e0;
          margin: ${`${spacing(4)}px 0 0`};

          table {
            th {
              width: 50%;
            }
          }

          div {
            overflow: auto;
            height: 144px;

            table {
              table-layout: fixed;
            }
          }
        }
      }
    }
  }
`;
