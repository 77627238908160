/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import { ApolloError } from '@apollo/client';
import clsx from 'clsx';
import { EmptyRows } from 'src/components/shared/Table/helpers';
import { theme } from '@evgo/react-material-components';

export type SimpleColumn<T = any> = {
  key: string;
  label: string;
  format?: (value: T) => string;
  formatWithRowData?: (data: T) => string;
  component?: React.FC<{ data: T; parentData?: T }>;
};

const StyledTable = styled(Table)`
  margin-top: ${theme.spacing(2)}px;
  thead,
  thead tr td {
    font-size: 14px !important;
    font-weight: 400 !important;
    background: none !important;
    padding: 0px !important;
    padding-bottom: 8px !important;
    color: ${theme.palette.grey[600]} !important;
    border-bottom: 1px solid rgba(215, 215, 215, 1) !important;
  }
  ,
  td {
    color: ${theme.palette.grey[600]} !important;
    font-size: 12px !important;
    padding: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-weight: 400 !important;
    background: none !important;
    border-bottom: 1px solid rgba(215, 215, 215, 1) !important;
  }
`;

type Props<T = any> = {
  label: string;
  className?: string;
  data: unknown[];
  loading: boolean;
  error: ApolloError | undefined;
  columns: SimpleColumn[];
  parentData?: T;
};

export const BasicTable: React.FC<Props> = ({
  label,
  data,
  columns,
  className,
  loading,
  error,
  parentData,
  ...props
}) => {
  return (
    <StyledTable className={clsx('basic-table', className)} {...props} size="small" aria-label={label}>
      <thead>
        <tr>
          {columns.map(({ label: columnLabel }) => {
            return (
              <td width={`${100 / columns.length}%`} align="left" style={{ borderBottom: '1px solid grey' }}>
                {columnLabel}
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.length ? (
          data.map((row, index) => (
            <tr data-testid="base-row" key={index}>
              {columns.map(({ key, format, formatWithRowData, component: Component }) => {
                return (
                  <td align="left" className="basic-cell">
                    {Component ? (
                      <Component data={{ row, key }} parentData={parentData} />
                    ) : format ? (
                      format(_.get(row, key))
                    ) : formatWithRowData ? (
                      formatWithRowData(row)
                    ) : (
                      _.get(row, key)
                    )}
                  </td>
                );
              })}
            </tr>
          ))
        ) : (
          <EmptyRows loading={loading} error={error} message="No Data" />
        )}
      </tbody>
    </StyledTable>
  );
};
