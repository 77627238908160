import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Checkbox, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listFalconConstraints } from '../../../../../apollo/queries/options';
import { useBlurDropdown } from '../../../../../lib/hooks';
import { Styled as StyledAccordion } from './styles';
import { listPlans as LIST_PLANS } from 'src/apollo/queries/plans';
import config from 'src/config';
export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plan details component
 */
export const PlanDetails: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanDetails');
  const className = id;
  const { errors, handleBlur, handleChange, isSubmitting, touched, values, setFieldValue } = useFormikContext();
  const handleBlurDropdown = useBlurDropdown();

  const { data: planStatusOptions, loading: statusOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'plans',
          },
          columnName: {
            eq: 'plan_status',
          },
        },
      },
    },
  });
  const { data: planCardBrandOptions, loading: planCardBrandOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'plans',
          },
          columnName: {
            eq: 'card_brand',
          },
        },
      },
    },
  });
  const { data: planTerminationBehaviorOptions, loading: planTerminationBehaviorOptionsLoading } = useQuery<Query>(
    listFalconConstraints,
    {
      variables: {
        optionsInput: {
          filter: {
            tableName: {
              eq: 'plans',
            },
            columnName: {
              eq: 'termination_behavior',
            },
          },
        },
      },
    },
  );
  const { data: balanceTypeOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'plans',
          },
          columnName: {
            eq: 'balance_type',
          },
        },
      },
    },
  });

  let statusOptions: LabelValue<number>[] = [];
  let cardBrandOptions: LabelValue<number>[] = [];
  let terminationBehaviorOptions: LabelValue<number>[] = [];
  const { data: planOptions, loading: planOptionsLoading } = useQuery<Query>(LIST_PLANS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { pageSize: config.maxPageSize },
    },
  });
  if (!statusOptionsLoading)
    statusOptions = planStatusOptions?.listFalconConstraints?.edges?.map((option) => ({
      label: option?.columnText,
      value: Number(option?.id),
    })) as LabelValue<number>[];
  if (!planCardBrandOptionsLoading)
    cardBrandOptions = planCardBrandOptions?.listFalconConstraints?.edges?.map((option) => ({
      label: option?.columnText,
      value: Number(option?.id),
    })) as LabelValue<number>[];
  if (!planTerminationBehaviorOptionsLoading)
    terminationBehaviorOptions = planTerminationBehaviorOptions?.listFalconConstraints?.edges?.map((option) => ({
      label: option?.columnText,
      value: Number(option?.id),
    })) as LabelValue<number>[];

  const [enrollmentClosedError, setEnrollmentClosedError] = useState('');
  const [enrollmentOpenError, setEnrollmentOpenError] = useState('');
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  const handleChangeEnrollmentClosed = useCallback(
    (event) => {
      handleChange(event);
      setEnrollmentClosedError('');
      if (!_.isEmpty(event.target.validationMessage)) setEnrollmentClosedError('Invalid date');
    },
    [handleChange, setEnrollmentClosedError],
  );

  const handleChangeEnrollmentOpen = useCallback(
    (event) => {
      handleChange(event);
      setEnrollmentOpenError('');
      if (!_.isEmpty(event.target.validationMessage)) {
        setEnrollmentOpenError('Invalid date');
      }
    },
    [handleChange, setEnrollmentOpenError],
  );

  return (
    <StyledAccordion id={parentId} className={`${customClass} plan-details`} defaultExpanded={true}>
      <AccordionSummary className={className} component="header" expandIcon={<ExpandMoreIcon />} id={`${id}-header`}>
        <Typography className={className} variant="h6" component="h2">
          Plan Details
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={`${className} details`}>
        <TextInfo
          className={`${className} half`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'planName') && _.get(errors, 'planName'))}
          helpertext={_.get(touched, 'planName') && _.get(errors, 'planName')}
          id={`${id}-plan-name-input`}
          label="Plan Name *"
          labelProps={{ className }}
          name="planName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'planName')}
          data-testid={`${id}-plan-name-input`}
        />
        <TextInfo
          className={`${className} half`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'description') && _.get(errors, 'description'))}
          helpertext={_.get(touched, 'description') && _.get(errors, 'description')}
          id={`${id}-description-input`}
          label="Plan Description *"
          labelProps={{ className }}
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'description')}
          data-testid={`${id}-description-input`}
        />
        <TextInfo
          className={`${className} half`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'displayName') && _.get(errors, 'displayName'))}
          helpertext={_.get(touched, 'displayName') && _.get(errors, 'displayName')}
          id={`${id}-display-name-input`}
          label="Display Name"
          labelProps={{ className }}
          name="displayName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'displayName')}
          data-testid={`${id}-display-name-input`}
        />
        <Dropdown
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'planStatusId') && _.get(errors, 'planStatusId'))}
          helpertext={_.get(touched, 'planStatusId') && _.get(errors, 'planStatusId')}
          id={`${id}-status-input`}
          label="Plan Status *"
          labelProps={{ className }}
          name="planStatusId"
          onBlur={handleBlurDropdown}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('planStatusId', target.value);
          }}
          options={statusOptions}
          value={_.get(values, 'planStatusId')}
          data-testid={`${id}-status-input`}
        />
        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'planCode') && _.get(errors, 'planCode'))}
          helpertext={_.get(touched, 'planCode') && _.get(errors, 'planCode')}
          id={`${id}-plan-code-input`}
          label="Plan Code *"
          labelProps={{ className }}
          name="planCode"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'planCode')}
          data-testid={`${id}-plan-code-input`}
        />
        <div className="divider" />
        <div className={`${className} checkbox-container`}>
          <Checkbox
            checked={_.get(values, 'isAutoEnrolledInRewards')}
            className={className}
            disabled={isSubmitting}
            inputProps={{ id: `${id}-is_auto_enrolled-in-rewards-checkbox` }}
            name="isAutoEnrolledInRewards"
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid={`${id}-is-auto-enrolled-in-rewards`}
          />
          <Typography className={className} variant="subtitle1" component="h3">
            Auto Enroll in Rewards
          </Typography>
        </div>
        <div className="divider" />
        <Dropdown
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'balanceTypeId') && _.get(errors, 'balanceTypeId'))}
          helpertext={_.get(touched, 'balanceTypeId') && _.get(errors, 'balanceTypeId')}
          id={`${id}-balance-type`}
          label="Balance Type *"
          labelProps={{ className }}
          name="balanceTypeId"
          onBlur={handleBlurDropdown}
          onChange={handleChange}
          options={balanceTypeOptions?.listFalconConstraints?.edges?.map((option) => ({
            label: option?.columnText,
            value: option?.id ? parseInt(option?.id) : null,
          }))}
          value={_.get(values, 'balanceTypeId')}
          data-testid={`${id}-balance-type-input`}
        />
        <Dropdown
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'terminationBehaviorId') && _.get(errors, 'terminationBehaviorId'))}
          helpertext={_.get(touched, 'terminationBehaviorId') && _.get(errors, 'terminationBehaviorId')}
          id={`${id}-termination-behavior-input`}
          label="Termination Behavior *"
          labelProps={{ className }}
          name="terminationBehaviorId"
          onBlur={handleBlurDropdown}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const selectedBehaviorValueOption = terminationBehaviorOptions.find(
              (x) => x.value === Number.parseInt(e.target.value),
            );
            const terminationBehaviorValue = selectedBehaviorValueOption?.label
              ? selectedBehaviorValueOption?.label?.toUpperCase()
              : null;

            setFieldValue('rolloverPlanId', '');
            if (terminationBehaviorValue === 'REOCCURS') {
              setFieldValue('duration', null);
            }
            setFieldValue('terminationBehavior', terminationBehaviorValue);
            setFieldValue('terminationBehaviorId', e.target.value);
          }}
          options={terminationBehaviorOptions}
          value={_.get(values, 'terminationBehaviorId')}
          data-testid={`${id}-termination-behavior-input`}
        />
        <Dropdown
          className={`${className} fourth`}
          disabled={isSubmitting || _.get(values, 'terminationBehavior') !== 'ROLLOVER'}
          error={!!(_.get(touched, 'rolloverPlanId') && _.get(errors, 'rolloverPlanId'))}
          helpertext={_.get(touched, 'rolloverPlanId') && _.get(errors, 'rolloverPlanId')}
          id={`${id}-rollover-plan-input`}
          label="Rollover Plan"
          labelProps={{ className }}
          name="rolloverPlanId"
          onBlur={handleBlurDropdown}
          onChange={handleChange}
          options={
            !planOptionsLoading
              ? planOptions?.listPlans?.edges?.map((plan) => ({
                  value: plan?.altId || 0,
                  label: `${plan?.planName}` || '',
                })) || []
              : []
          }
          value={_.get(values, 'rolloverPlanId')}
          data-testid={`${id}-rollover-plan-input`}
        />
        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(enrollmentOpenError || (_.get(touched, 'enrollmentOpen') && _.get(errors, 'enrollmentOpen')))}
          helpertext={enrollmentOpenError || (_.get(touched, 'enrollmentOpen') && _.get(errors, 'enrollmentOpen'))}
          id={`${id}-enrollment-open-input`}
          label="Enrollment Open"
          labelProps={{ className: `${className} prefilled`, shrink: true }}
          name="enrollmentOpen"
          onBlur={handleBlur}
          onChange={handleChangeEnrollmentOpen}
          type="date"
          value={_.get(values, 'enrollmentOpen')}
          data-testid={`${id}-enrollment-open-input`}
        />
        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={
            !!(
              enrollmentClosedError ||
              ((_.get(touched, 'enrollmentClosed') || _.get(touched, 'enrollmentOpen')) &&
                _.get(errors, 'enrollmentClosed'))
            )
          }
          helpertext={
            enrollmentClosedError ||
            ((_.get(touched, 'enrollmentClosed') || _.get(touched, 'enrollmentOpen')) &&
              _.get(errors, 'enrollmentClosed'))
          }
          id={`${id}-enrollment-closed-input`}
          label="Enrollment Closed"
          labelProps={{ className: `${className} prefilled`, shrink: true }}
          name="enrollmentClosed"
          onBlur={handleBlur}
          onChange={handleChangeEnrollmentClosed}
          type="date"
          value={_.get(values, 'enrollmentClosed')}
          data-testid={`${id}-enrollment-closed-input`}
        />
        <TextInfo
          className={className}
          disabled={isSubmitting || _.get(values, 'terminationBehavior') === 'REOCCURS'}
          error={!!(_.get(touched, 'duration') && _.get(errors, 'duration'))}
          helpertext={_.get(touched, 'duration') && _.get(errors, 'duration')}
          id={`${id}-duration-input`}
          label="Plan Duration in Months"
          labelProps={{ className }}
          name="duration"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'duration')}
          data-testid={`${id}-duration-input`}
        />
        <div className="divider" />
        <TextInfo
          className={`${className} half`}
          disabled
          error={!!(_.get(touched, 'displayOrdinal') && _.get(errors, 'displayOrdinal'))}
          helpertext={_.get(touched, 'displayOrdinal') && _.get(errors, 'displayOrdinal')}
          id={`${id}-cost-factor-input`}
          label="Display Ordinal"
          name="displayOrdinal"
          onBlur={handleBlur}
          onChange={handleChange}
          value=""
          data-testid={`${id}-cost-factor-input`}
        />
        <Dropdown
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'cardBrandId') && _.get(errors, 'cardBrandId'))}
          helpertext={_.get(touched, 'cardBrandId') && _.get(errors, 'cardBrandId')}
          id={`${id}-card-brand-input`}
          label="Card Brand"
          labelProps={{ className }}
          name="cardBrandId"
          onBlur={handleBlurDropdown}
          onChange={handleChange}
          options={cardBrandOptions}
          value={_.get(values, 'cardBrandId')}
          data-testid={`${id}-card-brand-input`}
        />
        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting}
          error={!!(_.get(touched, 'financeCode') && _.get(errors, 'financeCode'))}
          helpertext={_.get(touched, 'financeCode') && _.get(errors, 'financeCode')}
          id={`${id}-finance-code-input`}
          label="Finance Code"
          labelProps={{ className }}
          name="financeCode"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'financeCode')}
          data-testid={`${id}-finance-code-input`}
        />
        <TextInfo
          className={`${className} half`}
          error={!!(_.get(touched, 'costFactor') && _.get(errors, 'costFactor'))}
          helpertext={_.get(touched, 'costFactor') && _.get(errors, 'costFactor')}
          id={`${id}-cost-factor-input`}
          label="Cost Factor"
          labelProps={{ className }}
          name="costFactor"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'costFactor')}
          data-testid={`${id}-cost-factor-input`}
        />
        <div className={`${className} checkbox-container`}>
          <Checkbox
            checked={_.get(values, 'requireVin')}
            className={className}
            disabled={isSubmitting}
            inputProps={{ id: `${id}-require-vin-checkbox` }}
            name="requireVin"
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid={`${id}-require-vin-checkbox`}
          />
          <Typography className={className} variant="subtitle1" component="h3">
            Require Vin
          </Typography>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
