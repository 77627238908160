import { theme } from '@evgo/react-material-components';
import { Box, FormControl, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  padding: ${spacing(1.5)}px ${spacing(5)}px ${spacing(1.5)}px ${spacing(1.5)}px;
`;

export const StyledFormControl = styled(FormControl)`
  border-right: 1px solid ${theme.palette.grey['300']};
  min-height: ${spacing(10)}px;
  width: ${spacing(35)}px;
  gap: ${spacing(1)}px;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: ${spacing(0.25)}px 0;
  height: ${spacing(7.5)}px;
  align-items: center;

  label {
    white-space: nowrap;
    margin-right: 0;
  }
`;

export const StyledCheckboxContainer = styled.div<{ readonly?: boolean }>`
  display: flex;
  min-width: ${(props) => (props.readonly ? 'auto' : '160px')};
  margin-right: ${({ readonly }) => (readonly ? '5px' : '')};
  align-items: center;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & span.MuiFormControlLabel-label.Mui-disabled {
    color: inherit;
  }
`;
