export enum ChargerGroupTypes {
  CustomPlans = 'CUSTOM_PLANS',
  ReservationFees = 'RESERVATION_FEES',
  ChargerPrice = 'CHARGER_PRICE',
}

export const chargerGroupTypes = [
  {
    chargerGroupTypeName: 'Charger Price',
    chargerGroupType: ChargerGroupTypes.ChargerPrice,
  },
  {
    chargerGroupTypeName: 'Custom Plans',
    chargerGroupType: ChargerGroupTypes.CustomPlans,
  },
  {
    chargerGroupTypeName: 'Reservation Fees',
    chargerGroupType: ChargerGroupTypes.ReservationFees,
  },
];

export type ChargerGroupType = {
  chargerGroupType: ChargerGroupTypes;
  chargerGroupTypeName: string;
};

export type State = {
  initial: {
    chargerGroupTypes: ChargerGroupType[];
  };
  filter: {
    chargerGroupTypes: string[];
  };
};

export const initialState: State = {
  initial: {
    chargerGroupTypes: [],
  },
  filter: {
    chargerGroupTypes: [],
  },
};

export type Action =
  | { type: 'setInitialChargerGroupTypes'; chargerGroupTypes: ChargerGroupType[] }
  | { type: 'setChargerGroupTypes'; chargerGroupTypes: string[] };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setInitialChargerGroupTypes':
      return {
        ...state,
        initial: {
          ...state.initial,
          chargerGroupTypes: action.chargerGroupTypes,
        },
      };
    case 'setChargerGroupTypes':
      return {
        ...state,
        filter: {
          ...state.filter,
          chargerGroupTypes: action.chargerGroupTypes,
        },
      };
    default:
      throw new Error();
  }
};
