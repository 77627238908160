import { gql } from '@apollo/client';

export const getContract = gql`
  query getContract($input: GetContractInput!) {
    getContract(input: $input) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
