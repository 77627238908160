import { useMutation } from '@apollo/client';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Site, SiteImage } from 'src/@types';
import { createUpload } from '../../../../../../apollo/queries/media';
import { Image } from './Image';
import { Styled } from './styles';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';

export interface Props {
  selected: SiteImage[];
  setSelected: (images: SiteImage[]) => void;
  className?: string;
}

/**
 * Image Grid component
 */
export const ImageGrid: React.FC<Props> = (props) => {
  const id = _.kebabCase('ImageGrid');
  const className = id;
  const { errors, values, setValues, setFieldError } = useFormikContext<Site>();
  const { selected, setSelected } = props;
  const images = values?.siteImages?.edges || ([] as SiteImage[]);

  const snackbar = useSnackbar();
  const selectImage = (newImage: SiteImage) => setSelected(_.xor(selected, [newImage]));
  const moveImage = (dragIndex: number, hoverIndex: number) => {
    const draggedImage = images[dragIndex];
    images.splice(dragIndex, 1);
    images.splice(hoverIndex, 0, draggedImage);

    setValues({ ...values, siteImages: { edges: images } });
  };

  const [onCreateUpload, { loading }] = useMutation(createUpload, {
    onCompleted: ({ createUpload: { altId, createdAt, url } }) => {
      const newImages = [
        ...images,
        { media: { altId, createdAt, url, __typename: 'Media' }, __typename: 'SiteImage' },
      ] as SiteImage[];
      setValues({ ...values, siteImages: { edges: newImages } });
    },
    onError: (error) => {
      setFieldError('siteImages', error.message);
      snackbar.error(error.message);
    },
  });

  const onChangeFiles = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>): void => {
    const file = files?.[0];
    const input = document.getElementById('contained-button-file') as HTMLInputElement;

    if (file?.size && file.size / 1024 / 1024 > 2) {
      setFieldError('siteImages', 'Upload must be smaller than 2MB');
      snackbar.error('Upload must be smaller than 2MB');
      return;
    }

    if (file?.type?.includes('image')) {
      onCreateUpload({ variables: { input: file } });

      // reset file input after adding
      if (input) {
        input.value = '';
      }
    }
  };

  return (
    <Styled className={className}>
      <div className={`${className} grid`}>
        {images.map((image, i) => {
          return (
            <Image
              id={`${id}-dnd-image-${i}`}
              className={className}
              key={i}
              index={i}
              image={image}
              moveImage={moveImage}
              selected={selected}
              selectImage={selectImage}
            />
          );
        })}
        {loading && (
          <div
            id="loading-site-image-placeholder"
            className={`${className} image-placeholder`}
            style={{ height: '90px' }}
          >
            <LinearProgress className={`${className} progress-bar`} />
          </div>
        )}
      </div>

      <div className={`${className} site-images-footer`}>
        <div className={`${className} image-error`}>
          {_.get(errors, 'siteImages', false) && (
            <Typography id={`${id}-image-upload-error`} className={className} color="error" variant="caption">
              {errors.siteImages}
            </Typography>
          )}
        </div>
        <div className={`${className} add-image`}>
          <input
            id="contained-button-file"
            accept="image/*"
            className={className}
            disabled={images.length >= 6 || loading}
            onChange={onChangeFiles}
            style={{ display: 'none' }}
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button
              id={`${id}-add-image-button`}
              color="secondary"
              component="span"
              disabled={images.length >= 6 || loading}
              variant="contained"
            >
              Add
            </Button>
          </label>
        </div>
      </div>
    </Styled>
  );
};
