import { gql } from '@apollo/client';

export const deactivateCards = gql`
  mutation deactivateCards($input: DeactivateCardsInput!) {
    deactivateCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
