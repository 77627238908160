import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette } = theme;

export const Styled = styled.nav`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: -36px;

  a {
    position: relative;
    top: -50%;
    background-color: ${palette.background.paper};
    border-color: ${palette.divider};
    color: ${palette.text.secondary};

    &.active {
      background-color: ${palette.primary[50]};
      color: ${palette.text.primary};
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }

    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
