import { gql } from '@apollo/client';

export const reservableConnector = gql`
  mutation UpdateConnectorReservable($input: UpdateConnectorReservableInput!) {
    updateConnectorReservable(input: $input) {
      altId
      reservable
    }
  }
`;
