import { Checkbox, Input, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { Business, CheckBoxOutlineBlank } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';
import React, { useState } from 'react';
import { PropertiesWithMeta } from 'src/@types';
import { FetchMore } from 'src/@types/shared';
import { Styled as StyledPaper } from './styles';

export interface Props {
  onFilterChange: (fetchMore: FetchMore, metadata: PropertiesWithMeta, value: string[], status: string) => void;
  fetchMore: FetchMore;
  metadata: PropertiesWithMeta;
}

const statusOptions = [
  { value: 'ACTIVE', name: 'Active' },
  { value: 'INACTIVE', name: 'Inactive' },
];

/**
 * Returns a string value of selected statuses.
 */
export const checkSelected = (selected: string[]): string => {
  if (selected.length === 0) {
    return 'Properties: All';
  }

  const selectedToRender = [] as string[];

  selected.forEach((value) => {
    selectedToRender.push(statusOptions.find((option) => option.value === value)?.name || '');
  });

  return `Properties: ${selectedToRender.join(', ')}`;
};

/**
 * Renders component.
 */
export const PropertiesListFilters: React.FC<Props> = (props) => {
  const id = _.kebabCase('PropertiesListFilters');
  const className = id;
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const statuses = statusOptions.map((status, i) => (
    <MenuItem key={status.name} value={status.value} disableRipple>
      <Checkbox
        inputProps={{ id: `${id}-${i}-status-checkbox` }}
        checked={selectedStatuses.indexOf(status.value) > -1}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        disableRipple
        icon={<CheckBoxOutlineBlank fontSize="small" />}
      />
      <ListItemText primary={status.name} style={{ fontSize: 'small' }} />
    </MenuItem>
  ));

  const clearAll = selectedStatuses.length > 0 && (
    <Typography
      className={`${className} clear-all`}
      variant="caption"
      color="secondary"
      onClick={() => {
        setSelectedStatuses([]);
        props.onFilterChange(props.fetchMore, props.metadata, [], 'status');
      }}
    >
      Clear
    </Typography>
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  };

  return (
    <StyledPaper className={`${className} menu`}>
      <Business fontSize="small" />

      <Select
        className={`${className} select`}
        displayEmpty
        input={<Input id="select-multiple-checkbox" />}
        MenuProps={MenuProps}
        multiple
        onChange={({ target }: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
          setSelectedStatuses(target.value as unknown as string[]);
          props.onFilterChange(props.fetchMore, props.metadata, target.value as string[], 'status');
        }}
        renderValue={(selected) => checkSelected(selected as string[])}
        value={selectedStatuses}
      >
        {statuses}
      </Select>
      {clearAll}
    </StyledPaper>
  );
};
