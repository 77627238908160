import { useLazyQuery } from '@apollo/client';
import { Form, useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Query } from 'src/@types';
import { ModalProps } from 'src/@types/client';
import styled from 'styled-components';
import { listHosts as LIST_HOSTS } from 'src/apollo/queries/hosts';
import { SearchFilterDropdown } from 'src/components/shared/SearchFilterDropdown';
import config from 'src/config';
import { theme } from '@evgo/react-material-components';

const StyledForm = styled(Form)`
  display: flex;

  .form-control {
    margin-left: ${theme.spacing(3)}px;
  }
`;

export type FormValues = {
  hostId: string;
  prefix: string;
};

export const initialValues: FormValues = {
  hostId: '',
  prefix: '',
};

interface Props {
  modalProps?: ModalProps;
  className?: string;
}

export const ExtendHostForm: React.FC<Props> = () => {
  const className = 'create-extend-host-modal-component';
  const { setFieldValue, values, touched, errors, handleBlur, handleChange } = useFormikContext<FormValues>();
  const [hostOptions, setHostOptions] = useState<{ value: string; label: string }[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const hostActiveStatus = [146];

  const [listHostsQuery, { loading }] = useLazyQuery<Query>(LIST_HOSTS, {
    onCompleted: (data) => {
      if (data?.listHosts?.edges && searchString) {
        const newHostOptions = data?.listHosts?.edges.map((host) => ({
          label: host?.hostName || '',
          value: host?.altId || '',
        }));
        setHostOptions(newHostOptions);
      }
      if (!searchString && hostOptions.length) {
        setHostOptions([]);
      }
    },
  });

  return (
    <StyledForm className={className}>
      <SearchFilterDropdown
        label="Select an existing host*"
        value={values.hostId}
        options={hostOptions}
        loading={loading}
        onChange={(hostId) => setFieldValue('hostId', hostId)}
        onSearchChange={(hostName) => {
          setSearchString(hostName);
          if (hostName) {
            listHostsQuery({
              variables: {
                hostsInput: {
                  page: 0,
                  pageSize: config.maxPageSize,
                  search: {
                    hostName: { iLike: `%${hostName}%` },
                  },
                  filter: {
                    hostStatusId: { in: hostActiveStatus },
                  },
                },
              },
            });
          }
        }}
        debounce={500}
      />
      <TextField
        className={clsx('form-control')}
        data-testid={'host-prefix-input'}
        label="Host Prefix"
        name="prefix"
        error={!!errors.prefix && touched.prefix}
        helperText={touched.prefix && errors.prefix}
        onBlur={handleBlur}
        onChange={handleChange}
        InputProps={{}}
      />
    </StyledForm>
  );
};
