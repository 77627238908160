import React from 'react';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';
import { ChargersList } from './ChargersList';

export const ChargersListView: React.FC = () => {
  return (
    <PageContent pageHeader={<PageHeader childrenLeft={<Title>Chargers</Title>} />}>
      <ChargersList />
    </PageContent>
  );
};
