import { Chip, Tooltip } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon, Check as CheckIcon } from '@material-ui/icons';
import React from 'react';
import { TariffSyncStatus } from 'src/@types';
import { ListChargersV2Charger } from 'src/@types/shared';

type ChargerV2SyncStatusProps = {
  data: NonNullable<ListChargersV2Charger>;
  customStyle?: Record<string, unknown>;
};

type StatusData = {
  text: string;
  icon: React.ReactElement;
  color: string;
  tooltip: string;
};

const useStatus = ({ setTariffSynced, displayTariffSynced, tariffSyncStatus }: NonNullable<ListChargersV2Charger>) => {
  return React.useMemo<StatusData>(() => {
    const typeLabel = 'Charger';
    let tooltip = '';
    if (!setTariffSynced) tooltip += 'Set Tariff';
    if (!displayTariffSynced) {
      if (tooltip.length) tooltip += ' and ';
      tooltip += 'Display Tariff';
    }
    if (tariffSyncStatus && tariffSyncStatus !== TariffSyncStatus.Synced) {
      if (tooltip.length) tooltip += ' and ';
      tooltip += 'Charger Price';
    }

    if (tooltip.length) {
      return {
        text: 'Not Synced',
        icon: <ErrorOutlineIcon />,
        color: 'rgb(211, 47, 47)',
        tooltip: `${tooltip} not synced`,
      };
    }
    return {
      text: 'Active',
      icon: <CheckIcon />,
      color: 'rgb(46, 125, 50)',
      tooltip: `${typeLabel} is synced`,
    };
  }, [setTariffSynced, displayTariffSynced, tariffSyncStatus]);
};

export default function ChargerV2SyncStatus({ data, customStyle }: ChargerV2SyncStatusProps) {
  const { text, icon, color, tooltip } = useStatus(data);

  return (
    <Tooltip title={tooltip}>
      <Chip
        variant="outlined"
        icon={React.cloneElement(icon, { style: { color: color } })}
        label={text}
        style={{ color: color, borderColor: color, ...customStyle }}
        size="small"
      />
    </Tooltip>
  );
}
