import { Divider } from '@evgo/react-material-components';
import {
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { Contact } from 'src/@types';
import { ContactRow } from './ContactRow';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  contacts: Contact[];
  className?: string;
}

const columns = [
  { key: 'contactName', label: 'Name' },
  { key: 'workTitle', label: 'Title' },
  { key: 'email', label: 'Email' },
  { key: 'phone', label: 'Phone Number' },
];

/** Host Contacts List view component. */
export const HostContacts: React.FC<Props> = (props) => {
  const id = _.kebabCase('HostContacts');
  const className = id;
  const { contacts } = props;

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledAccordion className={customClass} id={`${id}-expand-panel-toggle`}>
      <AccordionSummary className={className} expandIcon={<ExpandMore />} id={`${id}-panel-header`} component="header">
        <div className={`${className} container`}>
          <div className={`${className} title`}>
            <Typography className={className} variant="h6" component="h2">
              Contacts
            </Typography>
            <Typography className={className} variant="caption" id={`${id}-total-contacts`}>
              {contacts ? `Currently viewing ${contacts.length}` : '0 Contacts'}
            </Typography>
          </div>
        </div>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} host-contacts-list`}>
        <Table className={className} id={`${id}-table`}>
          <TableHead className={className} id={`${id}-table-header`}>
            <TableRow className={className} id={`${id}-table-column-row`}>
              {columns.map((column, index) => (
                <TableCell
                  id={`${id}-column-${index}-table-column-cell`}
                  key={column.key}
                  className={`${className} ${column.key}`}
                  component="th"
                >
                  <TableSortLabel disabled={true} id={`${id}-column-${index}-table-column-sort-label`}>
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={className}>
            {contacts?.map((contact, i) => (
              <ContactRow key={i} index={i} className={className} contact={contact} />
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </StyledAccordion>
  );
};
