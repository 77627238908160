import React from 'react';
import { useBoolean } from 'ahooks';
import { useLocation } from 'react-router-dom';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';

import useAnalytics from 'src/lib/hooks/useAnalytics';
import { NestedNavigationItem } from '../types';
import {
  hasActiveSubItem as hasActiveSubItemHelper,
  generateMenuItemTestId,
  generateMenuContainerTestId,
} from '../helpers';
import { NestedListItem, NavLink, IconContainer, SubItemText } from './styles';

export type NestedItemProps = NestedNavigationItem & {
  handleDrawerToggle?: () => void;
};

export function NestedItem({
  name,
  icon,
  subItems,
  isActive: isActiveFn = hasActiveSubItemHelper,
  handleDrawerToggle,
}: NestedItemProps) {
  const { pathname } = useLocation();
  const { track } = useAnalytics();
  const isActive = isActiveFn(pathname, subItems);
  const [isOpen, { toggle }] = useBoolean(isActive);
  return (
    <>
      <NestedListItem button onClick={toggle} data-testid={generateMenuItemTestId(name)}>
        <IconContainer>{icon}</IconContainer>
        <ListItemText primary={name} />
        {isOpen || isActive ? <ArrowDropUp /> : <ArrowDropDown />}
      </NestedListItem>

      {subItems.length && (
        <Collapse in={isOpen || isActive} timeout="auto" data-testid={generateMenuContainerTestId(name)}>
          {subItems.map((subItem, key) => (
            <ListItem
              key={key}
              button
              component={NavLink}
              onClick={() => {
                if (subItem.analyticsEventName) track(subItem.analyticsEventName);
                if (handleDrawerToggle) handleDrawerToggle();
              }}
              to={subItem.to}
              data-testid={generateMenuItemTestId(subItem.name, { parent: name })}
            >
              <SubItemText primary={subItem.name} />
            </ListItem>
          ))}
        </Collapse>
      )}
    </>
  );
}
