import { useQuery } from '@apollo/client';
import { AppBar, Button, Tab, Tabs } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDriver } from '../../../../apollo/queries/drivers';
import { transformDriverResponse } from '../../../../lib/helpers';
import { Footer } from '../../../shared/Footer';
import { DriverActions } from './DriverActions';
import { DriverHeader } from './DriverHeader';
import { DriverProfileTab } from './DriverProfileTab';
import { DriverStatus } from './DriverStatus';
import { Styled } from './styles';

export interface Props {
  className?: string;
}

/** Initial Formik values */
export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

/**
 * Driver view component
 */
export const DriverForm: React.FC<Props> = ({ className: parentClass }) => {
  const id = _.kebabCase('DriverForm');
  const className = id;
  const navigate = useNavigate();
  const { altId, tab } = useParams<{ altId: string; tab: string }>();
  const { isSubmitting, resetForm } = useFormikContext();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [startCharge, setStartCharge] = useState(false);

  const { data: driverData, loading: driverLoading } = useQuery(getDriver, {
    fetchPolicy: 'network-only',
    variables: {
      input: { altId, paranoid: false },
      cardsInput: { pageSize: 999 },
      preferencesInput: { pageSize: 999 },
    },
    skip: !altId,
  });

  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;

  let activeTab: JSX.Element | null = <DriverProfileTab />;
  if (tab === 'profile') activeTab = <DriverProfileTab />;
  if (tab === 'transactions') activeTab = null;
  if (tab === 'issues') activeTab = null;
  if (tab === 'activity-log') activeTab = null;

  const handleChangeTab = (event: React.ChangeEvent<Record<string, never>>, newTab: string) =>
    navigate(`/drivers/${altId}/${newTab}`, { replace: true });

  // Handles populating the form with values from the server once everyting is loaded
  useEffect(() => {
    if (!driverLoading && driverData) {
      const driverValues = transformDriverResponse(_.get(driverData, 'getDriver', {}));

      resetForm({ status: driverValues, values: driverValues });
    }
  }, [driverData, driverLoading, resetForm]);

  return (
    <Styled>
      <div className={`${className} header-section`} id={`${id}-header-parent`}>
        <DriverHeader />

        {altId && (
          <AppBar className={`${className} app-bar`} id={`${id}-tab-bar`}>
            <Tabs className={className} value={tab || 'profile'} onChange={handleChangeTab} id={`${id}-tabs-parent`}>
              <Tab className={className} id={`${id}-profile-tab`} label="Profile" value="profile" />
              <Tab className={className} id={`${id}-transactions-tab`} label="Transactions" value="transactions" />
              <Tab className={className} id={`${id}-manage-tab`} disabled label="Manage" value="manage" />
              <Tab className={className} id={`${id}-issues-tab`} label="Issues" value="issues" />
              <Tab className={className} id={`${id}-activity-log-tab`} label="Activity Log" value="activity-log" />
            </Tabs>
          </AppBar>
        )}

        {altId && (
          <DriverActions
            className={className}
            driverData={_.get(driverData, 'getDriver', {})}
            startCharge={startCharge}
            setStartCharge={setStartCharge}
          />
        )}
      </div>

      <Form id={`${id}-form`} className={customClass}>
        <article className={className}>
          {altId && tab === 'profile' ? <DriverStatus className={className} /> : null}
          {activeTab}
        </article>

        <Footer className={className}>
          <Button
            className={className}
            color="primary"
            disabled={isSubmitting}
            id={`${id}-cancel-button`}
            onClick={goBack}
            size="large"
          >
            Cancel
          </Button>

          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-save-button`}
            size="large"
            type="submit"
            variant="contained"
          >
            <RefreshIcon className={`${className}${isSubmitting ? '' : ' hidden'}`} />
            <span className={`${className}${isSubmitting ? ' hidden' : ''}`}>Save</span>
          </Button>
        </Footer>
      </Form>
    </Styled>
  );
};
