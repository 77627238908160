import { useLazyQuery, useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ListSitesSortType, Query, SitesWithMeta } from 'src/@types';
import { FetchMore, MatOnChangePageEvent } from 'src/@types/shared';
import { listFalconConstraints } from '../../../../apollo/queries/options';
import { listSites } from '../../../../apollo/queries/sites';
import { getFullAddress, sanitizeSearch, titleCase, updateQuery } from '../../../../lib/helpers';
import { ListSearch as SitesListSearch } from '../../../shared/ListSearch';
import { SitesListFilters } from '../SitesListFilters';
import { Styled as StyledPaper } from './styles';

export type Props = {
  className?: string;
};

const searchFields = [
  'siteName',
  'sid',
  'address1',
  'locality',
  'administrativeArea',
  'postalCode',
  'property_host_hostName',
];

/**
 * Changes sites list page
 */
export const onPageChange =
  (fetchMore: FetchMore, metadata: SitesWithMeta) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    const { filter, pageSize, sort, search } = metadata;
    const fields = ['sid', 'siteName', 'siteStatusId', 'hostName'];
    const shouldIncludeSort = _.reduce(fields, (acc, i) => !!sort?.[i as keyof ListSitesSortType] || acc, false);

    const variables = {
      sitesInput: {
        filter:
          !_.isEmpty(filter) && filter?.siteStatusId
            ? { siteStatusId: _.omit(filter?.siteStatusId, '__typename') }
            : null,
        page,
        pageSize,
        ...(shouldIncludeSort ? { sort: _.pickBy(_.omit(sort, '__typename'), _.identity) } : {}),
        search: sanitizeSearch(search, searchFields),
      },
    };

    return fetchMore({
      updateQuery,
      variables,
    });
  };

/**
 * Changes sites list page size
 */
export const onRowsPerPageChange =
  (fetchMore: FetchMore, metadata: SitesWithMeta) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ReturnType<FetchMore> => {
    const { filter, sort, search } = metadata;
    const fields = ['sid', 'siteName', 'siteStatusId', 'hostName'];
    const shouldIncludeSort = _.reduce(fields, (acc, i) => !!sort?.[i as keyof ListSitesSortType] || acc, false);

    const variables = {
      sitesInput: {
        filter:
          !_.isEmpty(filter) && filter?.siteStatusId
            ? { siteStatusId: _.omit(filter?.siteStatusId, '__typename') }
            : null,
        page: 0,
        pageSize: event.target.value,
        ...(shouldIncludeSort ? { sort: _.pickBy(_.omit(sort, '__typename'), _.identity) } : {}),
        search: sanitizeSearch(search, searchFields),
      },
    };

    return fetchMore({
      updateQuery,
      variables,
    });
  };

/**
 * Sorts sites list
 */
export const onSortChange =
  (fetchMore: FetchMore, metadata: SitesWithMeta, field: string) => (): ReturnType<FetchMore> => {
    const { filter, pageSize, search } = metadata;
    const prevSort = _.get(metadata, `sort.${field}`, 'DESC');
    const sort = { [field]: prevSort === 'ASC' ? 'DESC' : 'ASC' };

    return fetchMore({
      updateQuery,
      variables: {
        sitesInput: {
          filter:
            !_.isEmpty(filter) && filter?.siteStatusId
              ? { siteStatusId: _.omit(filter?.siteStatusId, '__typename') }
              : null,
          page: 0,
          pageSize,
          sort,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Filters sites list
 */
export const onFilterChange = (
  fetchMore: FetchMore,
  metadata: SitesWithMeta,
  updatedFilters: number[],
  type: string,
): ReturnType<FetchMore> => {
  const { pageSize, sort, search } = metadata;

  const filter = updatedFilters.length
    ? {
        [type]: {
          in: updatedFilters,
        },
      }
    : undefined;

  return fetchMore({
    updateQuery,
    variables: {
      sitesInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        search: sanitizeSearch(search, searchFields),
        filter,
      },
    },
  });
};

/**
 * Search sites list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: SitesWithMeta,
  target: EventTarget & HTMLInputElement,
): ReturnType<FetchMore> => {
  const { pageSize, sort, filter } = metadata;

  let search: Record<string, Record<string, string>> | null = {};
  if (target.value.length) {
    searchFields.forEach((field) => {
      search = search || {};
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      sitesInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        filter:
          !_.isEmpty(filter) && filter?.siteStatusId
            ? {
                siteStatusId: {
                  in: filter?.siteStatusId.in?.map((id) => Number(id)),
                },
              }
            : null,
        search,
      },
    },
  });
};

const columns = [
  { id: 'siteName', label: 'Site Name', sortable: true },
  { id: 'sid', label: 'Site ID', sortable: true },
  { id: 'hostName', label: 'Host', sortable: true },
  { id: 'totalChargers', label: 'Chargers', sortable: false },
  { id: 'siteStatusId', label: 'Status', sortable: true },
  { id: 'address', label: 'Address', sortable: false },
];

export const SitesList: React.FC<Props> = memo((props) => {
  const id = _.kebabCase('SitesList');
  const className = id;

  const [getListSites, { data, error, fetchMore, loading }] = useLazyQuery<Query>(listSites, {
    fetchPolicy: 'network-only',
    // there seems to be a bug with "fetchMore" and "useLazyQuery" that will call the original query here,
    // in addition to the updated query, unless this policy is provided. no caching is actually happening.
    // TODO: factor out fetchMore for simple state values in the input.
    nextFetchPolicy: 'cache-first',
    variables: {
      sitesInput: {
        page: 0,
        pageSize: 10,
        sort: { siteName: 'ASC' },
      },
    },
  });

  useEffect(() => {
    getListSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { run: debouncedOnSearchChange } = useDebounceFn(onSearchChange, { wait: 500 });
  const { run: debouncedFetchMore } = useDebounceFn(getListSites, { wait: 500 });

  const { data: statusOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'status',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });

  const { edges, ...metadata } = data?.listSites || {};

  const rows = edges?.map((edge) => {
    let siteStatus = _.find(
      _.get(statusOptions, 'listFalconConstraints.edges', []),
      (options) => Number(options.value) === _.get(edge, 'siteStatusId', ''),
    );

    if (siteStatus) siteStatus = siteStatus.label;
    else siteStatus = 'UNKNOWN';

    return {
      siteName: {
        value: edge?.siteName || '',
        to: `/sites/${edge?.altId}/profile`,
      },
      sid: { value: _.toUpper(edge?.sid || '') },
      hostName: {
        value: _.get(edge, 'property.host.hostName', ''),
        to: `/hosts/${_.get(edge, 'property.host.altId')}/profile`,
      },
      totalChargers: { value: _.get(edge, 'chargers.total', 0) },
      siteStatusId: { value: titleCase(siteStatus) },
      address: {
        value: getFullAddress({
          streetAddress: edge?.address1 || '',
          unit: edge?.address2 || '',
          city: edge?.locality || '',
          state: edge?.administrativeArea || '',
          postalCode: edge?.postalCode || '',
          country: edge?.country || '',
        }),
      },
    };
  });

  return (
    <StyledPaper className={clsx(className, props.className)} component="section">
      <header className={className}>
        <div>
          <Typography className={className} variant="h6" component="h2">
            Sites
          </Typography>
          <Typography className={className} variant="caption" id={`${id}-header-caption`}>
            {loading ? 'Loading ...' : error ? <br /> : `Currently viewing ${metadata.total}`}
          </Typography>
        </div>

        <div className={className}>
          <Button
            id={`${id}-new-site-button`}
            className={className}
            color="secondary"
            component={Link}
            to="/sites/new"
            variant="contained"
          >
            New Site
          </Button>
          <SitesListSearch
            type="site"
            className={clsx(className, 'search')}
            onSearchChange={(search) => debouncedOnSearchChange(getListSites as unknown as FetchMore, metadata, search)}
          />
        </div>
      </header>

      <SitesListFilters
        fetchMore={debouncedFetchMore as unknown as FetchMore}
        metadata={metadata}
        onFilterChange={onFilterChange}
      />

      <Divider />

      <Table className={className} data-testid="site-management-table">
        <TableHead className={className}>
          <TableRow className={className}>
            {columns.map((column) => (
              <TableCell key={column.id} className={className} id={`${id}-${_.kebabCase(column.id)}-header-column`}>
                <TableSortLabel
                  id={`${id}-${column.id}-label`}
                  active={!_.isEmpty(_.get(metadata, `sort.${column.id}`))}
                  direction={_.toLower(_.get(metadata, `sort.${column.id}`) || 'asc') as 'asc'}
                  onClick={column.sortable ? onSortChange(fetchMore, metadata, column.id) : () => {}}
                  disabled={!column.sortable}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={className}>
          {(rows || []).map((row, key) => (
            <TableRow key={key} className={className}>
              {columns.map((column, i) => {
                const rowCol = row[column.id as keyof typeof row] as unknown as { to: string; value: string };

                return (
                  <TableCell
                    key={i}
                    className={className}
                    data-testId={`${id}-${_.kebabCase(column.id)}-cell-${key}`}
                    id={`${id}-${_.kebabCase(column.id)}-cell-${key}`}
                  >
                    {rowCol?.to ? (
                      <Link
                        className={className}
                        to={rowCol?.to}
                        data-testId={`${id}-${_.kebabCase(column.id)}-link-${key}`}
                        id={`${id}-${_.kebabCase(column.id)}-link-${key}`}
                      >
                        {rowCol?.value}
                      </Link>
                    ) : (
                      rowCol?.value
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>

        <TableFooter className={className}>
          <TableRow className={className}>
            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={onPageChange(fetchMore, metadata)}
              onRowsPerPageChange={onRowsPerPageChange(fetchMore, metadata)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
});
