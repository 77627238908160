import React, { createContext, useContext, useState } from 'react';

export interface ModalProps {
  callback?: () => void;
  title?: string;
  body?: string;
  buttonConfirmText?: string;
  [key: string]: unknown;
}

export type ModalState = {
  modalName: string;
  modalProps: ModalProps;
  modalVisible: boolean;
};

type Context = {
  modalName: string;
  modalProps: ModalProps;
  modalVisible: boolean;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
};

const ModalContext = createContext<Context | null>(null);

const initialState: ModalState = {
  modalName: '',
  modalProps: {},
  modalVisible: false,
};

export interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalState, setModalState] = useState(initialState);
  return <ModalContext.Provider value={{ ...modalState, setModalState }}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error('ModalContext must be called from within the ModalContextProvider');

  return context;
};
