import { useQuery } from '@apollo/client';
import { Dropdown, TextInfo } from '@evgo/react-material-components';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { listTariffOptions } from '../../../apollo/queries/options';
import defaultTariffHour from '../../../lib/fixtures/defaultTariffHour.json';
import { useBlurDropdown } from '../../../lib/hooks';
import { Styled } from './styles';
import { TariffHours } from './TariffHours';

export interface Props {
  className?: string;
  id?: string;
  path: string;
  readOnly?: boolean;
}

/** Default tariff rate */
export const defaultRate = {
  customizeHours: false,
  rate: '00.00',
  type: 'KWH',
  hours: [defaultTariffHour],
};

export const TariffRate: React.FC<Props> = ({ className: parentClass, id: parentId, path, readOnly }) => {
  const iConnector = Number(_.join(_.initial(_.head(_.reverse(_.split(_.head(_.split(path, '.')), '[')))), ''));
  const iRate = Number(_.join(_.initial(_.head(_.reverse(_.split(path, '[')))), ''));
  const i = `${iConnector}-${iRate}`;

  const id = _.kebabCase('TariffRate');
  const ratesPath = _.join(_.initial(_.split(path, '[')), '[');
  const className = id;
  const { data: tariffOptionsData } = useQuery(listTariffOptions);
  const { errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values } = useFormikContext();
  const handleBlurDropdown = useBlurDropdown();
  const rateTypeOptions = _.get(tariffOptionsData, 'listRateTypeOptions.edges', []);
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  // Handles removing the current rate from the tariff
  const handleRemoveRate = useCallback(() => {
    const rates = _.get(values, ratesPath);
    const value = [...rates];

    _.pullAt(value, [iRate]);
    setFieldValue(ratesPath, value);
  }, [iRate, ratesPath, setFieldValue, values]);

  return (
    <Styled id={parentId} className={customClass}>
      <header className={className}>
        <Dropdown
          className={className}
          disabled={isSubmitting || readOnly}
          error={!!(_.get(touched, `${path}.type`) && _.get(errors, `${path}.type`))}
          helpertext={_.get(touched, `${path}.type`) && _.get(errors, `${path}.type`)}
          id={`${id}-${i}-rate-type-input`}
          label="Type *"
          labelProps={{ className }}
          name={`${path}.type`}
          onBlur={handleBlurDropdown}
          onChange={handleChange}
          options={rateTypeOptions}
          value={_.get(values, `${path}.type`)}
        />

        {!_.get(values, `${path}.customizeHours`) && (
          <TextInfo
            className={className}
            disabled={isSubmitting || readOnly}
            error={!!(_.get(touched, `${path}.rate`) && _.get(errors, `${path}.rate`))}
            helpertext={_.get(touched, `${path}.rate`) && _.get(errors, `${path}.rate`)}
            id={`${id}-${i}-rate-rate-input`}
            label="Rate *"
            labelProps={{ className }}
            name={`${path}.rate`}
            onBlur={handleBlurDropdown}
            onChange={handleChange}
            adornmentStart={<>$</>}
            value={_.get(values, `${path}.rate`)}
          />
        )}

        {!readOnly && _.get(values, `${ratesPath}.length`) > 1 && (
          <Button
            className={className}
            disabled={isSubmitting}
            id={`${id}-${i}-header-remove-button`}
            onClick={handleRemoveRate}
          >
            <DeleteIcon className={className} color={isSubmitting ? 'inherit' : 'secondary'} />
            &nbsp;Delete
          </Button>
        )}
      </header>
      {!readOnly && (
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              checked={_.get(values, `${path}.customizeHours`)}
              className={className}
              disabled={isSubmitting}
              inputProps={{ id: `${id}-${i}-rate-customize-checkbox` }}
              name={`${path}.customizeHours`}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          }
          label="Customize Hours"
        />
      )}
      {_.get(values, `${path}.customizeHours`) && (
        <TariffHours className={className} path={path} readOnly={!!readOnly} />
      )}
    </Styled>
  );
};
