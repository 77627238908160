import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';
const { brandColor } = config;

export const Styled = styled.div`
  header {
    color: white;
    background-color: ${brandColor};
    > div {
      height: ${theme.spacing(11)}px;
    }

    img {
      height: ${theme.spacing(6)}px;
    }
  }
`;
