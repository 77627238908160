import { QueryResult } from '@apollo/client';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { Query, SalesforceHost, SalesforceHostContactsWithMeta } from '../../../../../@types';
import { Styled } from './styles';

export interface Props {
  contactsFetchMore: QueryResult<never, never>['fetchMore'];
  contactOptions: Array<ContactOptions | undefined> | undefined;
  contactsMetaData: Omit<SalesforceHostContactsWithMeta, 'edges'>;
  onChangePage: (
    contactsFetchMore: QueryResult<never, never>['fetchMore'],
    contactsMetaData: Omit<SalesforceHostContactsWithMeta, 'edges'>,
    type: string,
    queryData: Query,
  ) => (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  setSelectedContacts: (contacts: ContactOptions[]) => void;
  selectedContacts: Array<ContactOptions | undefined> | undefined;
  values: SalesforceHostWithInfo;
  setValues: (values: SalesforceHostWithInfo) => void;
  queryData: Query;
  className?: string;
}

export interface ContactOptions {
  altId: string;
  contactName: string;
  phone: string;
  email: string;
  title: string;
  disabled?: boolean;
}

export type SalesforceHostWithInfo = Array<SalesforceHost & { contacts?: ContactOptions[] }> & {
  contacts?: ContactOptions[];
};

export const SelectContactStep: React.FC<Props> = (props) => {
  const id = _.kebabCase('SelectContactStep');
  const className = id;
  const {
    contactsFetchMore,
    contactOptions,
    contactsMetaData,
    onChangePage,
    setSelectedContacts,
    selectedContacts,
    values,
    setValues,
    queryData,
  } = props;

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <Styled className={clsx(customClass, 'search')}>
      <Paper className={clsx(className, 'search-table')}>
        <Table className={className} id={`${id}-contact-list`}>
          <TableHead className={className}>
            <TableRow className={className}>
              <TableCell className={className}></TableCell>
              <TableCell className={className} id={`${id}-contact-list-name-column-header`}>
                Name
              </TableCell>
              <TableCell className={className} id={`${id}-contact-list-email-column-header`}>
                Email Address
              </TableCell>
              <TableCell className={className} id={`${id}-contact-list-phone-column-header`}>
                Phone Number
              </TableCell>
              <TableCell className={className} id={`${id}-contact-list-title-column-header`}>
                Title
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={className}>
            {!!contactOptions ? (
              contactOptions.map((row, key) => (
                <TableRow key={key}>
                  <TableCell className={className}>
                    <Checkbox
                      inputProps={{ id: `${id}-${key}-contact-option-checkbox` }}
                      checked={
                        !!selectedContacts?.length && !!selectedContacts?.find((contact) => _.isEqual(contact, row))
                      }
                      onChange={() => {
                        if (selectedContacts?.length && selectedContacts?.find((contact) => _.isEqual(contact, row))) {
                          const idx = selectedContacts?.findIndex((contact) => _.isEqual(contact, row));
                          selectedContacts?.splice(idx, 1);
                          setSelectedContacts([...selectedContacts] as ContactOptions[]);
                          setValues({ ...values, contacts: [...selectedContacts] } as SalesforceHostWithInfo);
                        } else {
                          setSelectedContacts([...(selectedContacts || []), row] as ContactOptions[]);
                          setValues({
                            ...values,
                            contacts: [...(selectedContacts || []), row],
                          } as SalesforceHostWithInfo);
                        }
                      }}
                      value={row?.altId}
                      disabled={row?.disabled}
                      color="default"
                    />
                  </TableCell>
                  <TableCell id={`${id}-contact-name-${key}`} className={className}>
                    {row?.contactName}
                  </TableCell>
                  <TableCell id={`${id}-contact-email-${key}`} className={className}>
                    {row?.email}
                  </TableCell>
                  <TableCell id={`${id}-contact-phone-${key}`} className={className}>
                    {row?.phone}
                  </TableCell>
                  <TableCell id={`${id}-contact-title-${key}`} className={className}>
                    {row?.title}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell id={`${id}-no-contacts-cell`}>
                  <Typography className={className} variant="body2">
                    No contacts found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={className}
                count={contactsMetaData.total || 0}
                onPageChange={onChangePage(contactsFetchMore, contactsMetaData, 'input', queryData)}
                rowsPerPage={contactsMetaData.pageSize || 0}
                rowsPerPageOptions={[5]}
                page={contactsMetaData.page || 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Styled>
  );
};
