import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled(Paper)`
  header {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};
  }

  div {
    display: flex;
  }

  h2 {
    color: ${config.colors.accentBlue};
  }
`;
