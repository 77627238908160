/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${`188px calc(${spacing(6)}px + var(--safe-area-inset-right)) ${spacing(12)}px ${spacing(6)}px`};

  .header-section {
    position: absolute;
    top: 0;
    left: 200px;
    width: calc(100% + -200px);
    z-index: 10;
  }

  .charger-profile {
    margin-bottom: ${spacing(10)}px;
  }

  .app-bar {
    position: relative;
    width: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    align-items: center;
    color: #000;
    background: #fff;
    z-index: 1;

    > div {
      margin: 0;
    }

    button {
      border: 1px solid #daf0fe;
      &[aria-selected='true'] {
        background-color: ${palette.primary[50]};
        transition: all 0.2s ease;
        border-bottom: none;
      }
    }
  }

  > div {
    position: relative;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
  }

  > section,
  > article,
  .connectors {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};

    &.new-charger {
      margin: ${`${spacing(4)}px ${spacing(2)}px 150px`};
    }

    header {
      h2 {
        color: ${config.colors.accentBlue};
      }

      span {
        color: ${palette.text.secondary};
      }

      div {
        display: flex;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .connectors,
  .charger-notes {
    margin: ${`${spacing(4)}px ${spacing(2)}px ${spacing(9.5)}px`};
  }

  .action-section {
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    top: calc(188px + ${spacing(4)}px);
    left: 200px;
    width: 40px;
    margin: 0;
    border-radius: 0px 4px 4px 0px;
    white-space: nowrap;
    transition: width 0.5s ease;

    &.open {
      width: 250px;
    }
  }

  div {
    box-sizing: border-box;

    &.snackbar {
      display: flex;
      flex-direction: row;
      position: absolute;
      z-index: 2000;
      top: 100px;
      align-self: center;

      span {
        display: flex;
        align-items: center;
        font-weight: 200;

        svg {
          margin-right: ${spacing()}px;
        }
      }

      button {
        color: #ffffff;
      }

      &.error {
        background-color: #cb2e29;
      }

      &.success {
        background-color: #43a047;
      }
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: 0 ${`${spacing(2)}`}px;

        > button {
          position: relative;
          width: 92px;
          height: 92px;
          margin: ${`${spacing(2)}px ${spacing()}px 0`};
          border: ${`${spacing() / 4}px dashed ${palette.text.secondary}`};
          color: ${palette.text.secondary};

          input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        h3 {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }

        .charger-image {
          margin: ${`${spacing(2)}px ${spacing()}px 0`};

          button {
            position: absolute;
            top: 0;
            left: 0;
            width: ${spacing(2.5)}px;
            height: ${spacing(2.5)}px;
            border-radius: ${spacing(1.25)}px;
          }

          img {
            width: 92px;
            height: 92px;
            object-fit: cover;
          }

          svg {
            width: 0.75rem;
            height: 0.75rem;
          }
        }

        .form-control,
        .select {
          width: calc(100% - ${spacing(2)}px);

          &.half {
            width: calc(50% - ${spacing(2)}px);
            &.demo {
              /* margin-top: ${spacing(2)}px; */
              &.model {
                margin-bottom: ${spacing(2)}px;
              }
            }
          }

          &.thirds {
            width: calc(33.33% - ${spacing(2)}px);
          }

          &.fourth {
            width: calc(25% - ${spacing()}px);
          }
        }

        .form-control {
          label {
            transform: translate(${`${spacing(2)}px, ${spacing(3)}px`}) scale(1);

            &[data-shrink='true'] {
              transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
            }
          }
        }

        .menu {
          width: 100%;
        }

        .flags {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`0px ${spacing()}px 0`};
          color: ${palette.text.secondary};

          div {
            display: flex;
            flex-direction: row;
          }
        }

        h2 {
          width: 100%;
          padding: ${`${spacing(3)}px ${spacing()}px ${spacing(2)}px`};
        }

        hr {
          width: calc(100% + ${`${spacing(4)}px`});
          margin-left: ${`-${spacing(2)}px`};
          margin-bottom: ${`${spacing(2)}px`};
        }
      }
    }

    .additionalDetails {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      width: 100%;

      h3 {
        width: calc(100% - ${spacing(2)}px);
        padding: ${`${spacing(2)}px ${spacing()}px 0`};
        color: ${palette.text.secondary};
      }

      .additionalCheckboxes {
        color: ${palette.text.secondary};
        width: calc(100% - ${spacing(2)}px);

        label {
          width: 100%;
        }
      }

      .fundingContainer,
      .equipmentClass {
        width: calc(100% - ${spacing(2)}px);
        padding: 0px;
      }
      .form-control,
      .select {
        width: calc(100% - ${spacing(2)}px);

        &.third {
          width: calc(33.33% - ${spacing(2)}px);
        }

        &.description {
          width: calc(60.66% - ${spacing(2)}px);
        }
      }

      .form-control {
        label {
          transform: translate(${`${spacing(2)}px, ${spacing(3)}px`}) scale(1);

          &[data-shrink='true'] {
            transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
          }
        }
      }
    }
  }

  svg {
    &.left {
      margin-right: ${spacing()}px;
    }
  }

  .chargerTags {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    padding: ${`0 ${spacing() * 6}px ${spacing(2)}px`};

    h3 {
      width: calc(100% - ${spacing(2)}px);
      padding: ${`${spacing(2)}px ${spacing()}px 0`};
      color: ${palette.text.secondary};
    }

    .tagContainer {
      max-height: 175px;
      overflow-y: auto;
    }

    .tagPaper {
      padding: ${`${spacing()}px ${spacing(1.5)}px`};
      background-color: ${colors.botticelli};
      margin: ${`${spacing(0.75)}px 0`};
    }

    h4 {
      width: calc(100% - ${spacing(2)}px);
      color: ${palette.text.secondary};
    }
  }
`;
