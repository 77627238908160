/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import days from '../../fixtures/days.json';

/**
 * Transforms the Formik plan values into Falcon format
 */
export const transformPlanValues = (values: any, altId?: string) => {
  const planInput = {
    planName: _.get(values, 'planName'),
    planSubheading: _.get(values, 'subheading'),
    planDescription: _.get(values, 'description'),
    planDisplayName: _.get(values, 'displayName'),
    planStatus: _.get(values, 'status'),
    planStatusId: _.get(values, 'planStatusId'),
    planCode: _.get(values, 'planCode'),
    planDiscount: Number(_.get(values, 'discount')),
    detailHeading: _.get(values, 'pageHeader'),
    detailSections: (values?.detailSections || []).map(({ __typename, ...rest }: { __typename: any }) => ({ ...rest })),
    detailSessionTimes: (values?.detailSessionTimes || []).map(
      ({ duration, __typename, ...rest }: { duration: string | number; __typename: any }) => ({
        ...rest,
        duration: Number(duration),
      }),
    ),
    legalese: _.get(values, 'legalese'),
    driverRank: _.get(values, 'driverRank'),
    displayToCustomer: _.get(values, 'displayToCustomer'),
    displayPlanCard: _.get(values, 'displayPlanCard'),
    displayToLoggedOutUser: _.get(values, 'displayToLoggedOutUser'),
    badge: _.get(values, 'badge'),
    balanceTypeId: _.get(values, 'balanceTypeId'),
    ctaLabel: _.get(values, 'ctaLabel'),
    costFactor: _.get(values, 'costFactor') ? Number(_.get(values, 'costFactor')) : null,
    financeCode: _.get(values, 'financeCode'),
    intervalUnitId: 104,
    intervalLength: Number(_.get(values, 'duration')),
    planDuration: Number(_.get(values, 'duration')),
    enrollmentOpen: _.get(values, 'enrollmentOpen') || null,
    enrollmentClose: _.get(values, 'enrollmentClosed') || null,
    terminationBehaviorId: _.get(values, 'terminationBehaviorId'),
    templateId: _.get(values, 'templateId') || null,
    rolloverPlanId: _.get(values, 'rolloverPlanId') || null,
    cardBrandId: _.get(values, 'cardBrandId') || null,
    vinRequiredFlag: _.get(values, 'requireVin'),
    isAutoEnrolledInRewards: _.get(values, 'isAutoEnrolledInRewards'),
    elements: _.flatten(
      (values?.elements || []).map((element: any) => {
        const { hours, connectorType } = element;
        return _.flatten(
          hours.map((hour: any) => {
            const { endTime, discount, startTime, maxDuration } = hour;
            const newElements: any = [];
            _.forEach(days, (day) => {
              if (hour[day]) {
                newElements.push({
                  connectorTypeId: Number(connectorType),
                  discount: Number(discount),
                  startTime,
                  endTime,
                  maxDuration: Number(maxDuration),
                  day: _.toUpper(day),
                });
              }
            });
            return newElements;
          }),
        );
      }),
    ),
    products: _.flatten(
      (values?.products || []).map((product: any) => {
        let newProduct = {};
        const productCopy = _.omit(_.cloneDeep(product), ['productType', '__typename']);
        if (_.get(product, 'productType', '') === 'SUBSCRIPTION') {
          newProduct = {
            productTypeId: _.get(product, 'productTypeId'),
            subscription: {
              subscriptionReoccurrenceId: productCopy.subscriptionReoccurrenceId,
              subscriptionFee: Number(productCopy.subscriptionFee),
            },
          };
        } else {
          newProduct = {
            productTypeId: _.get(product, 'productTypeId'),
            credit: {
              creditAmount: Number(productCopy.creditAmount),
              oneTimeCreditAmount: Number(productCopy.creditOneTimeAmount),
              creditExpPolicyId: productCopy.creditExpPolicyId,
              creditReoccurrenceId: productCopy.creditReoccurrenceId,
            },
          };
        }
        return newProduct;
      }),
    ),
  } as any;

  if (altId) {
    planInput.altId = altId;
    planInput.driverRank = 1;
  }

  if (altId && !planInput.planDiscount) {
    const elements = _.get(values, 'elements', []);
    planInput.planDiscount = Number(_.max(elements.map(({ discount }: { discount: any }) => discount)));
  }

  return planInput;
};
