import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

export type CustomLoaderProps = {
  // array containing text or component variations for each of the interval
  variations?: Array<string | React.ReactNode>;
  // the interval in seconds
  interval?: number;
};

export default function CustomLoader({ variations = ['Loading...'], interval = 5 }: CustomLoaderProps) {
  const [currentVariationIndex, setCurrentVariationIndex] = React.useState<number>(0);
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  useEffect(() => {
    const nextVariationIndex = currentVariationIndex + 1;
    if (nextVariationIndex >= variations.length) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setCurrentVariationIndex(nextVariationIndex), interval * 1000);
  }, [currentVariationIndex, variations, interval]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={5}>
        <CircularProgress color="secondary" />
      </Box>
      {typeof variations[currentVariationIndex] === 'string' ? (
        <Typography variant="body1">{variations[currentVariationIndex]}</Typography>
      ) : (
        <Box>{variations[currentVariationIndex]}</Box>
      )}
    </Box>
  );
}
