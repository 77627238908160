import { theme } from '@evgo/react-material-components';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../../config';

const { spacing } = theme;

export const Styled = styled(TableRow)`
  &.open {
    td {
      border-bottom: transparent;
    }
  }

  td {
    padding: ${`${spacing() / 2}px ${spacing()}px`};

    &:first-child {
      padding-left: ${spacing(3)}px;
    }

    &:last-child {
      padding-right: ${spacing(3)}px;
    }

    h2,
    h6 {
      color: ${config.colors.accentBlue};
    }

    svg {
      height: 29px;
    }
  }
`;
