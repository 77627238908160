import { Button, IconButton, InputLabel, Typography } from '@material-ui/core';
import { ArrowBack, FilterNone, Layers } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { copyToClipBoard, getFullAddress } from '../../../../../lib/helpers';
import { Styled } from './styles';

export interface Props {
  loading: boolean;
  className?: string;
}

/**
 * Site Edit Header component
 */
export const SiteEditHeader: React.FC<Props> = (props) => {
  const id = _.kebabCase('SiteEditHeader');
  const { loading } = props;
  const className = id;
  const { status } = useFormikContext();

  const fields = [
    {
      label: 'Address',
      value: getFullAddress({
        streetAddress: _.get(status, 'address1', ''),
        unit: _.get(status, 'address2', ''),
        city: _.get(status, 'locality', ''),
        state: _.get(status, 'administrativeArea', ''),
        postalCode: _.get(status, 'postalCode', ''),
      }),
    },
    {
      label: 'Site ID',
      value: _.get(status, 'sid'),
    },
  ];

  return (
    <Styled className={className}>
      <div className={className}>
        <section className={`${className} back`}>
          <Button
            id={`${id}-back-to-sites-list-button`}
            className={className}
            component={Link}
            size="small"
            to="/sites/list"
          >
            <ArrowBack className={className} /> All Sites
          </Button>
        </section>

        <section className={`${className} title`}>
          <Layers className={className} />
          <Typography className={className} variant="h5" id={`${id}-siteName`}>
            {loading ? 'Loading...' : _.get(status, 'siteName', 'Create Site')}
          </Typography>
        </section>

        <section className={`${className} info`}>
          {fields.map((field, i) => (
            <article key={i} className={className}>
              <InputLabel className={className} shrink id={`${id}-${field.label}-label`}>
                {field.label}
              </InputLabel>

              <div className={className}>
                <Typography className={className} id={`${id}-${field.label}-value`}>
                  {field.value}
                </Typography>
                {field.value && (
                  <IconButton
                    className={className}
                    onClick={copyToClipBoard(field.value)}
                    id={`${id}-${field.label}-copy-button`}
                  >
                    <FilterNone className={className} />
                  </IconButton>
                )}
              </div>
            </article>
          ))}
        </section>
      </div>
    </Styled>
  );
};
