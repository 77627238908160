/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import FilterNone from '@material-ui/icons/FilterNone';
import { Styled } from './styles';
import { copyToClipBoard } from '../../../lib/helpers';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
export interface Props {
  title: string;
  content: string;
  contentMaxWidth?: number;
  className?: string;
  allowCopyToClipboard?: boolean;
  eventName?: string;
}

export const PropertyDetail: React.FC<Props> = ({
  title,
  content,
  className,
  contentMaxWidth,
  allowCopyToClipboard = true,
  eventName,
}) => {
  const eventType = eventName || events.propertyDetail.COPY_ADDRESS;
  const { track } = useAnalytics();
  return (
    <Styled className={`property-detail-root ${className || ''}`} data-testid="property-detail-root">
      <div className="property-detail-title">{title}</div>
      <Box display="flex">
        <Box className="property-detail-content" maxWidth={contentMaxWidth} pt={0.5}>
          {content}
        </Box>
        {allowCopyToClipboard && (
          <Box ml={1}>
            <IconButton
              onClick={() => {
                copyToClipBoard(content)();
                track(eventType);
              }}
              aria-label="copy text"
              size="small"
              classes={{ root: 'property-detail-copy-button' }}
              data-testid="property-detail-copy-button"
            >
              <FilterNone classes={{ root: 'property-detail-copy-icon' }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Styled>
  );
};
