import { TextInfo, theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress, FormHelperText, Switch, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { DataContext } from 'src/components/shared/DataContext';
import { useDataContext } from 'src/components/shared/DataContext/useDataContext';
import { PageActions } from 'src/components/shared/PageActions';
import { SubTitle } from 'src/components/shared/SubTitle';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';

export type FormValues = {
  chargerGroupName: string;
  description: string;
  hasDuration: boolean;
  planDuration: number;
};

export type CustomPlanPriceFormProps = {
  isNew: boolean;
  altId?: string;
};

const StyledForm = styled(Form)`
  .half {
    width: 100%;
    max-width: 500px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)}px;
  }

  .loading {
    color: ${theme.palette.common.white};
  }

  .subtitle {
    margin-bottom: ${theme.spacing(2)}px;
  }
`;

export const CustomPlanPriceForm = ({ isNew, altId }: CustomPlanPriceFormProps) => {
  const navigate = useNavigate();
  const className = 'CustomPlanPriceForm';
  const id = _.kebabCase(className);
  const [dataRef, setData] = useDataContext();
  const { values, touched, errors, handleBlur, handleChange, isSubmitting, setFieldValue } =
    useFormikContext<FormValues>();
  const { track } = useAnalytics();
  const hasPlanDurationError = Boolean(touched.planDuration && errors.planDuration);

  const handleChangeHasDuration = () => {
    if (values.hasDuration) setFieldValue('planDuration', null);

    setFieldValue('hasDuration', !values.hasDuration);
  };

  return (
    <DataContext context={id}>
      <StyledForm ref={dataRef}>
        <Typography variant="h6">Description</Typography>
        <SubTitle>Provide a name and description for this plan</SubTitle>

        <Box className="fields">
          <TextInfo
            className={clsx(className, 'half')}
            disabled={isSubmitting}
            error={errors.chargerGroupName && touched.chargerGroupName}
            helpertext={touched.chargerGroupName && errors.chargerGroupName}
            id={`${id}-pricing-name-input`}
            label="Custom Plan Name *"
            labelProps={{ className }}
            name="chargerGroupName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.chargerGroupName}
            {...setData('pricing-name-input')}
          />
          <TextInfo
            className={clsx(className, 'half')}
            disabled={isSubmitting}
            error={errors.description && touched.description}
            helpertext={touched.description && errors.description}
            id={`${id}-description-input`}
            label="Description *"
            labelProps={{ className }}
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            {...setData('description-input')}
          />
        </Box>

        <Box className="fields" mt="10px">
          <Typography>How long should this plan last?</Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Switch checked={values.hasDuration} onChange={handleChangeHasDuration} />
            <Typography>add a duration</Typography>
            <Box ml="10px" display="flex" alignItems="center" justifyContent="center">
              <Tooltip
                placement="right"
                title="This will be how long a user can use this plan once it is added to their account.  If there is no duration, the plan will not have an end date."
              >
                <InfoIcon />
              </Tooltip>
            </Box>
          </Box>
          {values.hasDuration && (
            <>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box width="100px" mr="5px">
                  <TextInfo
                    className={clsx(className)}
                    disabled={isSubmitting}
                    error={hasPlanDurationError}
                    label="e.g., 12"
                    id={`${id}-pricing-duration-input`}
                    name="planDuration"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.planDuration}
                    {...setData('pricing-duration-input')}
                    type="number"
                  />
                </Box>
                <Typography>Months</Typography>
              </Box>
              {hasPlanDurationError && <FormHelperText error={true}>{errors.planDuration}</FormHelperText>}
            </>
          )}
        </Box>

        <PageActions>
          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-cancel-button`}
            {...setData('cancel-button')}
            size="large"
            onClick={() => navigate(`/extend-plus/custom-plans/${isNew ? '' : `${altId}/details`}`)}
          >
            Back
          </Button>

          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-save-button`}
            size="large"
            type="submit"
            variant="contained"
            {...setData('save-button')}
            onClick={() => track(events.customPlanDescription.SAVED_CUSTOM_PLAN_PRICE_DETAILS)}
          >
            {isSubmitting ? (
              <CircularProgress size={theme.spacing(2.5)} className="loading" />
            ) : isNew ? (
              'Next'
            ) : (
              'Save'
            )}
          </Button>
        </PageActions>
      </StyledForm>
    </DataContext>
  );
};
