import { QueryResult, useQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Input, ListItemText, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { CheckBoxOutlineBlank, FilterTiltShift } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChargersWithMeta, Query } from 'src/@types';
import { listTags } from '../../../../apollo/queries/tags';
import { Styled } from './styles';

export interface Props {
  fetchmore: QueryResult<never, never>['fetchMore'];
  metadata: ChargersWithMeta;
  updateFilters: ({ tags }: { tags: string[] }, type: string) => void;
}

/** Chargers Header component. */
export const ChargersHeader: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargersHeader');
  const className = id;
  const { metadata, updateFilters } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data, loading } = useQuery<Query>(listTags, {
    fetchPolicy: 'network-only',
    variables: {
      tagsInput: {
        page: 0,
        pageSize: 10,
      },
    },
  });

  const { edges = [] } = data?.listTags || { edges: [] };

  /**
   * Returns a string value of selected markets.
   */
  const checkSelected = (selected: string[]) => {
    if (!selected.length) {
      return 'Tags: All';
    }

    return `Tags: ${selected.map((item) => _.find(edges, { altId: item })?.tagName).join(', ')}`;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  };

  return (
    <Styled>
      <header className={className}>
        <section className={`${className} title`}>
          <Typography className={className} variant="h5">
            Charger Management
          </Typography>
          <div className={`${className} subtitle`}>{metadata.total} Chargers</div>
        </section>

        <section className={`${className} filter`}>
          <Paper className={`${className} menu`}>
            <FilterTiltShift color="primary" fontSize="small" />
            <Select
              className={`${className} select`}
              displayEmpty
              input={<Input id="chargers-header-tag-multi-select-filter" disableUnderline={true} />}
              MenuProps={MenuProps}
              multiple
              onChange={({ target }) => {
                updateFilters({ tags: target.value as unknown as string[] }, 'tags');
                setSelectedTags(target.value as unknown as string[]);
              }}
              renderValue={(selected) => checkSelected(selected as string[])}
              value={selectedTags}
            >
              <MenuItem className={className} disabled value="">
                All
              </MenuItem>
              {!loading &&
                edges?.map((tag, i) => (
                  <MenuItem key={tag?.tagName} value={tag?.altId || ''} disableRipple>
                    <Checkbox
                      checked={selectedTags.includes(tag?.altId || '')}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      disableRipple
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      inputProps={{ id: `${id}-${i}-tag-checkbox` }}
                    />
                    <ListItemText primary={tag?.tagName} style={{ fontSize: 'small' }} />
                  </MenuItem>
                ))}
            </Select>
          </Paper>
          {!_.isEmpty(selectedTags) ? (
            <Typography
              className={`${className} clear-tags`}
              variant="caption"
              color="secondary"
              onClick={() => {
                updateFilters({ tags: [] }, 'tags');
                setSelectedTags([]);
              }}
            >
              Clear
            </Typography>
          ) : null}
        </section>

        <div className={`${className} add-charger`}>
          <Button
            className={className}
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/chargers/new"
          >
            Add Charger
          </Button>
        </div>
      </header>

      <Divider className={className} />
    </Styled>
  );
};
