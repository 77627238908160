import { Dropdown, TextInfo } from '@evgo/react-material-components';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Error as ErrorIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useBlurDropdown } from '../../../../../../../lib/hooks';
import { initialValues } from '../../../initialValues';
import { Styled } from './styles';

export interface Props {
  id?: string;
  className?: string;
}

const columns = [
  { key: 'sessionTimes', label: 'Session Times' },
  { key: 'interval', label: '' },
  { key: 'startTime', label: 'Start Time' },
  { key: 'endTime', label: 'End Time' },
  { key: 'removeButton', label: '' },
];

const intervalOptions = [
  { label: 'Hours', value: 'HOURS' },
  { label: 'Minutes', value: 'MINUTES' },
  { label: 'Seconds', value: 'SECONDS' },
];

const defaultSessionTime = {
  duration: '',
  interval: '',
  startTime: null,
  endTime: null,
};

export const PlanDetailSessionTimes: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanDetailSessionTimes');
  const className = id;
  const { errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values } =
    useFormikContext<typeof initialValues>();
  const handleBlurDropdown = useBlurDropdown();

  const disabledClass = !_.get(values, 'displayToCustomer') ? 'disabled' : '';

  const handleAddSessionTime = useCallback(() => {
    const value = [...values.detailSessionTimes, { ...defaultSessionTime }];

    setFieldValue('detailSessionTimes', value);
  }, [values.detailSessionTimes, setFieldValue]);

  const handleRemoveSessionTime = useCallback(
    (index) => {
      const detailSessionTimes = _.get(values, 'detailSessionTimes');
      const value = [...detailSessionTimes];

      _.pullAt(value, [index]);
      setFieldValue('detailSessionTimes', value);
    },
    [values, setFieldValue],
  );

  const handleChangeEndTime = useCallback(
    (event) => {
      const {
        target: { name, value: oldValue },
      } = event;
      const value = `${oldValue}:59`;

      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleChangeStartTime = useCallback(
    (event) => {
      const {
        target: { name, value: oldValue },
      } = event;
      const value = `${oldValue}:00`;

      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  const [err] = _.filter(_.get(errors, 'detailSessionTimes'), (t) => !_.isUndefined(t)) as never[];

  return (
    <Styled id={parentId} className={customClass}>
      <Table className={className}>
        <TableHead>
          <TableRow>
            {columns?.map(({ key, label }) => (
              <TableCell key={key} className={`${className} ${_.kebabCase(key)}`} component="th">
                <Typography className={className} variant="subtitle1" component="h3" id={`${id}-${key}-table-header`}>
                  {label}
                  {key === 'startTime' && err && err[key] && (
                    <Tooltip
                      className={className}
                      title={err[key]}
                      enterDelay={200}
                      leaveDelay={200}
                      placement="top"
                      id={`${id}-column-${key}-error-tooltip`}
                    >
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={className}>
          {values.detailSessionTimes.map((sessionTime, i) => {
            return (
              <TableRow className={className} key={i}>
                <TableCell className={className}>
                  <TextInfo
                    className={`${className} full`}
                    disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
                    error={
                      !!(
                        _.get(touched, `detailSessionTimes[${i}].duration`) &&
                        _.get(errors, `detailSessionTimes[${i}].duration`)
                      )
                    }
                    helpertext={
                      _.get(touched, `detailSessionTimes[${i}].duration`) &&
                      _.get(errors, `detailSessionTimes[${i}].duration`)
                    }
                    id={`${id}-${i}-plan-duration-input`}
                    label="Duration *"
                    labelProps={{ className }}
                    name={`detailSessionTimes[${i}].duration`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={_.get(values, `detailSessionTimes[${i}].duration`)}
                    data-testid={`${id}-${i}-plan-duration-input`}
                  />
                </TableCell>
                <TableCell className={className}>
                  <Dropdown
                    className={`${className} full`}
                    disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
                    error={
                      !!(
                        _.get(touched, `detailSessionTimes[${i}].interval`) &&
                        _.get(errors, `detailSessionTimes[${i}].interval`)
                      )
                    }
                    helpertext={
                      _.get(touched, `detailSessionTimes[${i}].interval`) &&
                      _.get(errors, `detailSessionTimes[${i}].interval`)
                    }
                    id={`${id}-${i}-plan-interval-input`}
                    label="Interval *"
                    labelProps={{ className }}
                    name={`detailSessionTimes[${i}].interval`}
                    options={intervalOptions}
                    onBlur={handleBlurDropdown}
                    onChange={handleChange}
                    value={_.get(values, `detailSessionTimes[${i}].interval`)}
                    data-testid={`${id}-${i}-plan-interval-input`}
                  />
                </TableCell>
                <TableCell className={className}>
                  <TextInfo
                    className={className}
                    disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
                    id={`${id}-${i}-hour-end-time-input`}
                    inputProps={{ className, step: 60 }}
                    name={`detailSessionTimes[${i}].startTime`}
                    onBlur={handleBlur}
                    onChange={handleChangeStartTime}
                    type="time"
                    error={
                      !!_.get(touched, `detailSessionTimes[${i}].startTime`) &&
                      !!_.get(errors, `detailSessionTimes[${i}].startTime`)
                    }
                    value={_.join(_.split(_.get(values, `detailSessionTimes[${i}].startTime`), ':', 2), ':')}
                    data-testid={`${id}-${i}-hour-start-time-input`}
                  />
                </TableCell>
                <TableCell className={`${className} remove-button`}>
                  <TextInfo
                    className={className}
                    disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
                    id={`${id}-${i}-hour-end-time-input`}
                    inputProps={{ className, step: 60 }}
                    name={`detailSessionTimes[${i}].endTime`}
                    onBlur={handleBlur}
                    onChange={handleChangeEndTime}
                    type="time"
                    error={
                      !!_.get(touched, `detailSessionTimes[${i}].endTime`) &&
                      !!_.get(errors, `detailSessionTimes[${i}].endTime`)
                    }
                    value={_.join(_.split(_.get(values, `detailSessionTimes[${i}].endTime`), ':', 2), ':')}
                    data-testid={`${id}-${i}-hour-end-time-input`}
                  />
                </TableCell>
                <TableCell className={className}>
                  <IconButton
                    className={`${className} ${disabledClass}`}
                    disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
                    id={`${id}-${i}-session-time-remove-button`}
                    onClick={() => handleRemoveSessionTime(i)}
                    data-testid={`${id}-${i}-session-time-remove-button`}
                  >
                    <RemoveCircleOutlineIcon className={className} color={isSubmitting ? 'inherit' : 'primary'} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div className={`${className} controls`}>
        <Button
          className={`${className} ${disabledClass}`}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          id={`${id}-session-times-add-button`}
          onClick={handleAddSessionTime}
          data-testid={`${id}-session-times-add-button`}
        >
          <AddCircleOutlineIcon
            className={`${className} add-button-icon`}
            color={isSubmitting ? 'inherit' : 'primary'}
          />
          Add Session Times
        </Button>
      </div>
    </Styled>
  );
};
