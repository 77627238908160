import { ApolloError, useQuery } from '@apollo/client';
import { listFalconConstraints } from 'src/apollo/queries/options';
import {
  FalconConstraint,
  ListFalconConstraintsSortInput,
  Maybe,
  Query,
  TableSortInput,
  TableStringFilterInput,
} from 'src/@types';

export type UseFalconConstraintsResults<V> = {
  constraints: Map<number | string, V>;
  loading: boolean;
  error?: ApolloError;
};

type Props<R> = {
  tableName: TableStringFilterInput;
  columnName: TableStringFilterInput;
  columnText?: TableStringFilterInput;
  columnValue?: TableStringFilterInput;
  sort?: Maybe<ListFalconConstraintsSortInput>;
  keyBy?: 'id' | 'columnName' | 'columnValue' | 'columnText';
  map?: (row: FalconConstraint) => R;
};

export function useMappedFalconConstraints<R = FalconConstraint>(props: Props<R>): UseFalconConstraintsResults<R> {
  const {
    tableName,
    columnName,
    columnText,
    columnValue,
    keyBy = 'id',
    map = (row: FalconConstraint) => ({
      columnName: row?.columnName,
      columnValue: row?.columnValue,
      columnText: row?.columnText,
    }),
    sort = { id: TableSortInput.Asc },
  } = props;

  const { data, loading, error } = useQuery<Query>(listFalconConstraints, {
    fetchPolicy: 'cache-first',
    variables: {
      optionsInput: {
        filter: {
          tableName,
          columnName,
          columnText,
          columnValue,
        },
        page: 0,
        pageSize: 1000,
        sort,
      },
    },
  });

  const edges = data?.listFalconConstraints?.edges || [];

  const constraints = new Map<number | string, R>(
    // @ts-ignore
    edges.map((row) => {
      // @ts-ignore
      const key = keyBy === 'id' ? +row?.id : row?.[keyBy];
      return [key, map(row as FalconConstraint)];
    }),
  );

  return { constraints, loading, error };
}
