import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, spacing } = theme;

export const Styled = styled(Paper)`
  box-sizing: border-box;
  width: calc(100% - ${spacing(4)}px);
  margin: 32px 16px 0;

  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      &:first-child {
        flex: 1;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;

        * {
          margin: 0 ${spacing()}px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }

    span {
      color: ${palette.text.secondary};
    }
  }

  div {
    padding: 0;

    table {
      tr {
        &.filler-row {
          :not(:last-of-type) {
            td {
              border-bottom-color: transparent;
            }
          }
        }

        td,
        th {
          padding: ${`${spacing() / 2}px ${spacing()}px`};

          &:first-child {
            padding-left: ${spacing(3)}px;
          }

          &:last-child {
            padding-right: ${spacing(3)}px;
          }
        }
      }

      .select {
        padding-right: 24px;
        text-align: right;
      }
    }
  }
`;
