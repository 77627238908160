import { Divider } from '@evgo/react-material-components';
import { Button, CircularProgress, IconButton, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { Styled } from './styles';

export interface Props {
  className?: string;
  id?: string;
  title?: string;
  subtitle?: string;
  primaryButtonLoading?: boolean;
  primaryButtonText?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonText?: string;
  secondaryButtonClick?: () => void;
  tertiaryButtonClick?: () => void;
  tertiaryButtonDisabled?: boolean;
  tertiaryButtonText?: string;
  open?: boolean;
  onClick?: () => void;
  onClose?: () => void;
}

export const StyledModal: React.FC<Props> = (props) => {
  const {
    children,
    className,
    id,
    onClick,
    onClose,
    primaryButtonDisabled,
    primaryButtonText,
    primaryButtonLoading,
    secondaryButtonClick,
    secondaryButtonText,
    tertiaryButtonClick,
    tertiaryButtonText,
    tertiaryButtonDisabled,
    subtitle,
    title,
    open,
  } = props;

  if (!open) {
    return null;
  }

  return (
    <Styled className={className} data-testid={id} onClose={onClose} open={open}>
      <Paper className={className}>
        <header className={className}>
          <IconButton className={className} onClick={onClose} data-testid={`${className}-close-button`}>
            <Close />
          </IconButton>
          {title && (
            <Typography className={className} variant="h5" component="h5">
              {title || ''}
            </Typography>
          )}
          {subtitle && (
            <Typography className={className} variant="subtitle1" component="h3">
              {subtitle || ''}
            </Typography>
          )}
        </header>
        <div className={className}>
          <div className={`${className} body-container`}>{children}</div>
          <Divider />
          <div className={`${className} button-container`}>
            {!!(tertiaryButtonClick && tertiaryButtonText) && (
              <Button
                className={`${className}
                tertiary`}
                color="primary"
                variant="text"
                onClick={tertiaryButtonClick}
                disabled={tertiaryButtonDisabled}
                data-testid={`${className}-tertiary-button`}
              >
                {tertiaryButtonText}
              </Button>
            )}
            {!!(secondaryButtonClick && secondaryButtonText) && (
              <Button
                className={`${className}
                secondary`}
                color="secondary"
                onClick={secondaryButtonClick}
                disabled={primaryButtonLoading}
                data-testid={id ? `${id}-secondary-button` : `${className}-secondary-button`}
              >
                {secondaryButtonText}
              </Button>
            )}
            <Button
              className={className}
              color="secondary"
              variant="contained"
              onClick={onClick}
              disabled={primaryButtonDisabled || primaryButtonLoading}
              data-testid={id ? `${id}-primary-button` : `${className}-primary-button`}
            >
              {primaryButtonLoading ? <CircularProgress size={20} className="loading" /> : primaryButtonText || 'Save'}
            </Button>
          </div>
        </div>
      </Paper>
    </Styled>
  );
};
