import { QueryResult, useMutation } from '@apollo/client';
import { Button, Paper } from '@material-ui/core';
import { AddBox, Create, PlayCircleOutline, Refresh, CloudDownload } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Driver, Query } from 'src/@types';
import { resetDriverPassword } from '../../../../apollo/queries/drivers';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';

export interface Props {
  className?: string;
  selected: Query['getAccount'];
  startCharge: boolean;
  setStartCharge: (val: boolean) => void;
  fetchMore: QueryResult<never, never>['fetchMore'];
}

export const AccountActions: React.FC<Props> = (props) => {
  const { altId } = useParams();

  const className = 'accountactions-component';
  const { selected, startCharge, setStartCharge, fetchMore } = props;
  const actionBarRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);

  const { setModalState } = useModalContext();
  const snackbar = useSnackbar();

  const [onResetPassword] = useMutation(resetDriverPassword, {
    onCompleted() {
      snackbar.success('Password reset email sent to driver.');
    },
    onError() {
      snackbar.error('Failed to send password reset email to driver.');
    },
  });

  const showSyncAccountModal = () => {
    const { edges: drivers } = selected?.drivers || {};

    setModalState({
      modalName: 'AccountSyncModal',
      modalProps: {
        driver: drivers?.map((driver) => ({
          phone: driver?.phone,
          email: driver?.email,
        }))[0],
      },
      modalVisible: true,
    });
  };

  const showAddCardModal = () => {
    const { edges: drivers } = selected?.drivers || {};

    setModalState({
      modalName: 'AccountCardModal',
      modalProps: {
        fetchMore,
        drivers: drivers?.map((driver) => ({
          value: driver?.altId,
          label: `${driver?.firstName} ${driver?.lastName}`,
        })),
        accountOwnerId: (_.find(drivers, { driverType: 'OWNER' }) as Driver)?.altId,
        accountId: altId || '',
      },
      modalVisible: true,
    });
  };

  const sendPasswordResetEmail = () => {
    const accountDrivers = _.get(selected, 'drivers');
    const accountOwner = _.find(_.get(accountDrivers, 'edges', []), { driverType: 'OWNER' });
    const driverAltId = _.get(accountOwner, 'altId', '');
    onResetPassword({
      variables: {
        driverInput: {
          altId: driverAltId,
        },
      },
    });
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (!actionBarRef?.current?.contains(e.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  if (expanded) customClass += ' open';

  return (
    <Styled>
      <Paper className={`${customClass} action-section`} component="section">
        <div className={className}>
          <div ref={actionBarRef} className={`${className} actions`} onClick={() => setExpanded(!expanded)}>
            <Button
              id="sync-user-button"
              data-testid="sync-user-button"
              className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''} ${startCharge ? 'selected' : ''}`}
              size="large"
              disabled={_.isEmpty(selected) || !expanded}
              onClick={() => {
                showSyncAccountModal();
                setExpanded(!expanded);
              }}
            >
              <CloudDownload className={`${className} left`} />
              Pull Data From Driivz
            </Button>

            <Button
              id="change-status-button"
              data-testid="change-status-button"
              className={`${className} ${_.isEmpty(selected) ? 'disabled' : 'active'}`}
              size="large"
              disabled={_.isEmpty(selected) || !expanded}
              onClick={() => {
                setModalState({ modalName: 'AccountStatusModal', modalProps: { ...selected }, modalVisible: true });
              }}
            >
              <Create className={`${className} left`} />
              Change Status
            </Button>

            <Button
              id="reset-password-button"
              className={`${className} ${_.isEmpty(selected) ? 'disabled' : 'active'}`}
              size="large"
              disabled={_.isEmpty(selected) || !expanded}
              onClick={sendPasswordResetEmail}
            >
              <Refresh className={`${className} left`} />
              Reset Password
            </Button>

            <Button
              id="add-card-button"
              data-testid="add-card-button"
              className={`${className} ${_.isEmpty(selected) ? 'disabled' : 'active'}`}
              size="large"
              onClick={showAddCardModal}
              disabled={_.isEmpty(selected) || !expanded}
            >
              <AddBox className={`${className} left`} />
              Add Card
            </Button>

            <Button
              id="start-charge-button"
              data-testid="start-charge-button"
              className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''} ${startCharge ? 'selected' : ''}`}
              size="large"
              disabled={_.isEmpty(selected) || !expanded}
              onClick={() => {
                setStartCharge(!startCharge);
                setExpanded(!expanded);
              }}
            >
              <PlayCircleOutline className={`${className} left`} />
              Start Charge
            </Button>
          </div>
        </div>
      </Paper>
    </Styled>
  );
};
