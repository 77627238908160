import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import React from 'react';
import { Query } from 'src/@types';
import { getSitesTotal } from '../../../../apollo/queries/sites';
import { Styled } from './styles';

/**
 * Sites Header component
 */
export const SitesHeader: React.FC = () => {
  const className = 'SitesHeader';
  const { data, error, loading } = useQuery<Query>(getSitesTotal);
  const total = data?.listSites?.total || 0;
  const s = total === 1 ? '' : 's';
  let caption: JSX.Element | string = `${total} Site${s}`;

  if (loading) {
    caption = 'Loading ...';
  }

  if (error) {
    caption = <br />;
  }

  return (
    <Styled className={className}>
      <section className={`${className} title`}>
        <Typography className={className} variant="h5" component="h1">
          Sites Management
        </Typography>

        <Typography className={className} variant="caption">
          {caption}
        </Typography>
      </section>
    </Styled>
  );
};
