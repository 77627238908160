import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { useRoles } from 'src/lib/hooks/useRoles';
import { OperatorPortalRole, RoleApp } from 'src/@types/shared';
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import BlockIcon from '@material-ui/icons/Block';

export interface Props {
  component: React.FC;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    margin-top: 20px;
    width: 50px;
    height: 50px;
    color: ${theme.palette.error.main};
  }
`;

function Restricted() {
  return (
    <Container>
      <span data-testid="access-denied">Partner admins can not access this page</span>
      <BlockIcon />
    </Container>
  );
}

export const PrivateRoute: React.FC<Props> = ({ component: Component }) => {
  const [currentUser, loading] = useAuthState(auth);
  const { hasRole } = useRoles();
  const isPartnerAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.PartnerAdmin);
  if (isPartnerAdmin) {
    return <Restricted />;
  }
  if (loading) {
    return null;
  }

  return currentUser ? <Component /> : <Navigate to="/login" replace />;
};
