import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Styled = styled(Box)`
  display: flex;

  > div {
    flex-grow: 1;
  }
`;
