import { gql } from '@apollo/client';

export const listCards = gql`
  query listCards($input: ListCardsInput) {
    listCards(input: $input) {
      total
      page
      pageSize
      filter {
        cardStatus {
          in
        }
        brand {
          in
        }
      }
      search {
        externalNumber {
          iLike
        }
        internalNumber {
          iLike
        }
        driver_firstName {
          iLike
        }
        driver_lastName {
          iLike
        }
        accountId {
          eq
        }
      }
      edges {
        altId
        internalNumber
        externalNumber
        brand
        cardStatus
        cardTypeId
        account {
          id
          altId
        }
        driver {
          firstName
          lastName
        }
      }
    }
  }
`;
