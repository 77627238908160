/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

interface Props {
  editing: boolean;
}

const { palette, spacing } = theme;

export const Styled = styled.article<Props>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${`0 ${spacing(6)}px ${spacing(12)}px`};
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) ${spacing(12)}px ${spacing(6)}px`};

  > form {
    display: flex;
    flex-direction: column;
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};
    gap: 1em;

    header {
      padding: ${`${spacing(2)}px ${spacing(3)}px`};
    }
  }

  .error {
    display: flex;
    align-self: center;
    position: absolute;
    top: 80px;
    margin-top: 0;
    z-index: 2000;
    background-color: #cb2e29;

    span {
      display: flex;
      align-items: center;
      font-weight: 200;

      svg {
        margin-right: ${spacing()}px;
      }
    }

    button {
      color: #ffffff;
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    margin-right: ${spacing(3)}px;
    h3 {
      font-weight: 300;
      padding: ${`${spacing() + 2}px 0`};
    }
    span {
      padding: ${`${spacing(1.5)}px ${spacing() / 2}px`};
    }
  }

  .connectors {
    header {
      display: flex;
      button {
        margin-left: auto;
      }
    }
  }

  .charger-model-form > section {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);

    .panel-details {
      div {
        .row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          padding: ${spacing(2)}px;

          .sub-power {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
      }
    }

    section {
      box-sizing: border-box;
      width: calc(100% - ${spacing(4)}px);
      margin: ${`${spacing(4)}px ${spacing(2)}px ${spacing(4)}px`};
    }

    header {
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      h2 {
        color: ${config.colors.accentBlue};
      }

      div {
        display: flex;
      }
    }
  }

  .full-width {
    width: 100%;
  }

  .check-container {
    margin-left: -${spacing(0.75)}px;
  }

  .form-control {
    label {
      ${({ editing }) => !editing && `color: ${palette.text.secondary};`}
      transform: translate(${`${spacing(2)}px, ${spacing(3)}px`}) scale(1);

      &[data-shrink='true'] {
        transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
      }
    }

    > div {
      ${({ editing }) => !editing && 'border-bottom: 1px solid rgba(0, 0, 0, 0.42);'}
    }

    > input {
      padding-bottom: 9px;
    }
  }

  .panel-actions {
    padding: 10px 32px;
    background-color: #ffffff;

    button {
      svg {
        &.left {
          margin-right: ${spacing()}px;
        }
      }
    }
  }
`;
