import { Divider, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  expanded?: boolean;
  onChange?: () => void;
  className?: string;
}

/**
 * Charger Hardware Notes component
 */
export const ChargerHardwareNotes: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerHardwareNotes');
  const className = id;
  const { altId } = useParams<{ altId: string }>();
  const { handleChange, values } = useFormikContext();

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledAccordion
      className={`${customClass} charger-hardware-notes`}
      expanded={props.expanded}
      onChange={props.onChange}
      defaultExpanded={true}
    >
      <AccordionSummary className={className} expandIcon={altId ? <ExpandMore /> : null} component="header">
        <Typography className={className} variant="h6" component="h2">
          Charger Hardware Notes
        </Typography>
      </AccordionSummary>

      <Divider className={className} />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <TextInfo
              id={`${id}-cc-reader`}
              className={`${className} sixths`}
              label="CC Reader"
              name="ccReaderType"
              onChange={handleChange}
              value={_.get(values, 'ccReaderType', '')}
            />
            <TextInfo
              id={`${id}-co-branding`}
              className={`${className} fill`}
              label="Co-Branding"
              name="coBranding"
              onChange={handleChange}
              value={_.get(values, 'coBranding', '')}
            />
            <TextInfo
              id={`${id}-additional-equipment`}
              className={className}
              label="Add. Equipment"
              name="additionalEquipment"
              onChange={handleChange}
              value={_.get(values, 'additionalEquipment', '')}
            />
            <TextInfo
              id={`${id}-model-notes`}
              className={className}
              label="Charger Notes"
              multiline
              name="modelNotes"
              onChange={handleChange}
              rows="4"
              value={_.get(values, 'modelNotes', '')}
            />
          </div>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
