import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing } = theme;

export const Styled = styled(Paper)`
  section {
    margin: ${`${spacing(10)}px ${spacing(2)}px ${spacing(2)}px`};
  }

  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      .deactivate {
        color: black;
      }

      &:first-child {
        flex: 1;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1em;
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }

    span {
      color: ${palette.text.secondary};
    }
  }

  div {
    padding: 0;

    table {
      tr {
        th,
        td {
          a {
            color: ${config.colors.accentBlue};
          }
        }

        &.filler-row {
          :not(:last-of-type) {
            td {
              border-bottom-color: transparent;
            }
          }
        }
      }

      .select {
        padding-right: 24px;
        text-align: right;
      }
    }
  }
`;
