/**
 * A helper to check if a string is an email
 */
export const isEmail = (string: string): boolean => {
  const matcher =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (string.length > 320) return false;
  return matcher.test(string);
};
