/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, text, typography, spacing } = theme;

export const Styled = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  width: 100%;
  height: ${spacing(17.5)}px;
  box-sizing: border-box;
  z-index: 999;
  background-color: ${palette.primary[50]};
  padding: 0 ${spacing(8)}px;

  div {
    display: flex;
    flex: 1;
    align-content: space-between;
    flex-direction: column;

    section {
      display: flex;
      flex: 1 1 auto;

      &.back {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        a {
          text-transform: none;
          font-weight: 200;
          font-size: ${typography.fontSize - 2};
          color: ${text.labelEmphasis.medium};
        }

        svg {
          margin-right: ${spacing()}px;
          height: ${spacing(1.5)}px;
          width: ${spacing(1.5)}px;
          color: ${palette.secondary[700]};
        }
      }

      &.title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: ${spacing(1.5)}px;

        .connector-icon-container {
          max-width: ${spacing(4)}px;
        }

        .connectors {
          padding: 0px ${spacing(2)}px;
          flex-direction: row;

          .active {
            color: green;
          }

          .unknown,
          .reserved {
            color: grey;
          }

          .preparing,
          .charging,
          .finishing,
          .suspended_ev,
          .suspended_evse {
            color: blue;
          }

          .faulted,
          .unavailable {
            color: red;
          }

          .icon-connector {
            display: inline-block;
            width: ${spacing(4)}px;
            height: ${spacing(4)}px;
            border-radius: 10px;
            margin: 1px;
            fill: currentColor;
          }
        }

        svg {
          margin-right: ${spacing(1.25)}px;
          height: ${spacing(2.75)}px;
          width: ${spacing(2.75)}px;
          color: ${palette.secondary[700]};
        }

        h5 {
          font-weight: 200;
          font-size: ${typography.fontSize + 8};
          margin-right: ${spacing()}px;
        }
      }

      &.info {
        display: flex;
        flex-direction: row;
        font-weight: 200;
        padding-top: ${spacing(1.5)}px;

        article {
          display: flex;
          flex-direction: column;

          label {
            font-size: ${typography.fontSize - 2}px;
            color: ${text.labelEmphasis.medium};
          }

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: ${spacing(1.25)}px;

            &.charger-id,
            &.address,
            &.brand,
            &.speed,
            &.warranty {
              width: auto;
            }

            p {
              flex: 1 1 auto;
              font-size: ${typography.fontSize}px;
              font-weight: ${typography.fontWeightRegular};
              color: ${text.surfaceEmphasis.medium};
              margin-right: ${spacing()}px;
            }
            button {
              padding: ${spacing()}px;
            }

            svg {
              height: ${spacing(1.75)}px;
              width: ${spacing(2)}px;
              color: #666666;
            }
          }
        }
      }
    }
  }

  article {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    position: relative;

    &.map {
      height: 100px;
      width: 100px;
      object-fit: cover;
    }

    .open-map {
      z-index: 1300;
      position: absolute;
      left: ${spacing(10)}px;
      bottom: ${spacing(0.5)}px;
      min-height: 0;
      height: ${spacing(3)}px;
      width: ${spacing(3)}px;
      box-shadow: 0px 3px 5px -1px #1fa3ff40, 0px 6px 10px 0px #1fa3ff40, 0px 1px 18px 0px #1fa3ff40;

      svg {
        height: ${spacing(1.875)}px;
        width: ${spacing(1.875)}px;
        color: ${palette.common.white};
      }
    }

    .border {
      border: 2px solid ${palette.common.white};
      border-radius: 50%;
      overflow: hidden;
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        position: relative;
        top: 10px;
      }
    }
  }

  hr {
    margin: 0 ${spacing(2)}px;
  }

  .gmnoprint {
    display: none;
  }

  a[title='Open this area in Google Maps (opens a new window)'] {
    display: none !important;
  }

  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google4.png"]
  {
    display: none;
  }
`;
