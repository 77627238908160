import React, { Fragment } from 'react';
import { ChargerConnectors } from './ChargerConnectors';
import { ChargerHardware } from './ChargerHardware';
import { ChargerHardwareNotes } from './ChargerHardwareNotes';

/**
 * Charger Network & Hardware Tab component
 */
export const ChargerNetworkHardwareTab: React.FC = () => {
  return (
    <Fragment>
      <ChargerConnectors />
      <ChargerHardware />
      <ChargerHardwareNotes />
    </Fragment>
  );
};
