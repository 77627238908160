import styled from 'styled-components';

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding: 20px calc(20px + var(--safe-area-inset-right)) 20px calc(20px + var(--safe-area-inset-left));
`;
