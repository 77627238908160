import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Maybe } from 'src/@types';
import { DriverProfileNotification } from './DriverProfileNotification';
import { Styled as StyledTable } from './styles';

export interface Props {
  className?: string;
  id?: string;
  index: number;
  label?: Maybe<string>;
  items: Array<{
    id: string;
    onePrefRequired: boolean;
    notificationName: string;
    notificationDesc: string;
  }>;
}

export const DriverProfileNotificationGroup: React.FC<Props> = ({
  className: parentClass,
  id: parentId,
  index: i,
  label,
  items,
}) => {
  const id = _.kebabCase('DriverProfileNotificationGroup');

  const columns = [
    { key: _.camelCase(label || ''), label, width: '97%' },
    { key: 'email', label: 'Email', width: '1%' },
    { key: 'text', label: 'Text', width: '1%' },
    { key: 'push', label: 'Push', width: '1%' },
  ];

  const className = 'driver-profile-notification-group';
  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledTable id={parentId || `${id}-${i}`} className={customClass}>
      <colgroup className={className}>
        {columns.map(({ key, width }) => (
          <col key={key} style={{ width }} />
        ))}
      </colgroup>

      <TableHead className={className}>
        <TableRow className={className}>
          {columns.map((column) => (
            <TableCell id={`${id}-${i}-label`} key={column.key} className={className} component="th">
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody className={className}>
        {items.map((item, j) => (
          <DriverProfileNotification key={item.id} className={className} index={j} item={item} />
        ))}
      </TableBody>
    </StyledTable>
  );
};
