import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.section`
  display: flex;
  margin-top: ${spacing(2.5)}px;
  width: 100%;
  flex-wrap: wrap;

  div {
    &:not(.select) {
      flex: 0 1 15%;
    }

    label {
      font-size: ${theme.typography.fontSize}px;
      transform: scale(1);
    }

    .select {
      font-size: ${theme.typography.fontSize}px;
      margin-top: ${spacing(2.5)}px;
      margin-right: ${spacing(1.625)}px;
    }

    .clear-models,
    .clear-status {
      position: absolute;
      top: 45px;
      padding: 4px;
      cursor: pointer;
    }
  }
`;
