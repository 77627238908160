import moment from 'moment-timezone';
import { BarChartData } from 'src/components/shared/UtilizationBarGraph';
import { Maybe, GetSessionsConsumptionForExtendResponse, SessionsConsumptionForExtendData } from 'src/@types';

const getDailyRange = (timeSeries: Maybe<Array<Maybe<SessionsConsumptionForExtendData>>>): BarChartData[] => {
  const barGraphData: BarChartData[] = [];

  timeSeries?.forEach((value, index) => {
    const weekDay = moment(value?.timestamp?.split('T')[0]).format('DD ddd');
    barGraphData[index] = { label: weekDay, value: value?.powerValue || 0 };
  });

  return barGraphData;
};

const getRange30 = (timeSeries: Maybe<Array<Maybe<SessionsConsumptionForExtendData>>>): BarChartData[] => {
  const barGraphData: BarChartData[] = [];

  timeSeries?.forEach((value, index) => {
    const day = moment(value?.timestamp?.split('T')[0]).format('DD ddd').substring(0, 4);
    barGraphData[index] = { label: day, value: value?.powerValue || 0 };
  });

  return barGraphData;
};

const getRange90 = (
  timeSeries: Maybe<Array<Maybe<SessionsConsumptionForExtendData>>>,
  timePeriod: number,
): BarChartData[] => {
  const barGraphData: BarChartData[] = [];
  const dateStart = moment().subtract(timePeriod, 'day');
  const dateEnd = moment();

  while (dateEnd.diff(dateStart, 'months') >= 0) {
    barGraphData.unshift({ label: dateEnd.format('MM MMM'), value: 0 });
    dateEnd.subtract(1, 'month');
  }

  timeSeries?.forEach((value) => {
    const month = moment(value?.timestamp?.split('T')[0]).format('MM MMM');
    const index = barGraphData.findIndex((monthValue) => monthValue.label === month);
    if (index !== -1) {
      barGraphData[index].value += value?.powerValue || 0;
    }
  });

  return barGraphData;
};

export const getGraphData = (
  data: Maybe<GetSessionsConsumptionForExtendResponse> | undefined,
  timePeriod: number,
): BarChartData[] => {
  const timeSeries = data?.timeSeries || [];

  switch (timePeriod) {
    case 1:
      return getDailyRange(timeSeries);
    case 7:
      return getDailyRange(timeSeries);
    case 30:
      return getRange30(timeSeries);
    default:
      return getRange90(timeSeries, timePeriod);
  }
};
