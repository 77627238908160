/* stylelint-disable color-no-hex */

import { theme } from '@evgo/react-material-components';
import { CheckCircle, HelpOutlined, OfflineBolt, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { Tooltip as MUITooltip, Popper as MUIPopper, colors } from '@material-ui/core';
import styled from 'styled-components';

const { typography, palette, spacing } = theme;

type ConnectorStatusIconProps = {
  connectorStatus: string;
  displayStatus?: boolean;
  displayTooltip?: boolean;
};

const icons = new Map([
  ['Available', <CheckCircle className="available" data-testid="available-connector-status" />],
  ['Charging', <OfflineBolt className="charging" data-testid="charging-connector-status" />],
  ['Discharging', <OfflineBolt className="discharging" data-testid="discharging-connector-status" />],
  ['Faulted', <RadioButtonUncheckedOutlined className="faulted" data-testid="faulted-connector-status" />],
  ['Finishing', <OfflineBolt className="finishing" data-testid="finishing-connector-status" />],
  ['New', <HelpOutlined className="unknown" data-testid="new-connector-status" />],
  ['Paused', <OfflineBolt className="paused" data-testid="paused-connector-status" />],
  ['Preparing', <OfflineBolt className="preparing" data-testid="preparing-connector-status" />],
  ['Reserved', <OfflineBolt className="reserved" data-testid="reserved-connector-status" />],
  ['Unavailable', <RadioButtonUncheckedOutlined className="unavailable" data-testid="unavailable-connector-status" />],
  ['Unknown', <HelpOutlined className="unknown" data-testid="unknown-connector-status" />],
]);

const Container = styled.span`
  align-items: inherit;

  svg {
    margin-bottom: -${spacing(0.9)}px;
    margin-right: ${spacing()}px;
    color: #3c897c;
    font-size: 1.3rem;

    &.charging {
      color: ${colors.lightGreen[400]};
    }

    &.available,
    &.preparing,
    &.finishing,
    &.reserved,
    &.paused,
    &.discharging {
      color: #32657e;
    }

    &.faulted,
    &.unavailable,
    &.unknown {
      color: #bbb8b8;
    }
  }
`;

const StyledPopper = styled(MUIPopper)`
  .MuiTooltip-tooltip {
    font-weight: 400;
    color: ${palette.common.black};
    font-size: ${typography.fontSize}px;
    background-color: ${palette.common.white};
    padding: ${spacing(2, 2)};
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
  }
`;

export function ConnectorStatusIcon({ connectorStatus, displayStatus, displayTooltip }: ConnectorStatusIconProps) {
  if (!connectorStatus) {
    return null;
  }

  const icon = icons.get(connectorStatus);

  return (
    <Container>
      {displayTooltip ? (
        <MUITooltip title={connectorStatus} PopperComponent={StyledPopper}>
          {icon || <></>}
        </MUITooltip>
      ) : (
        icon
      )}
      {displayStatus && connectorStatus}
    </Container>
  );
}
