import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing } = theme;

export const Styled = styled(Paper)`
  section {
    margin: ${`${spacing(10)}px ${spacing(2)}px ${spacing(2)}px`};
  }
  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      .deactivate {
        color: black;
      }

      &:first-child {
        flex: 1;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;

        * {
          margin: 0 ${spacing()}px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }

    span {
      color: ${palette.text.secondary};
    }
  }

  table {
    tbody {
      tr {
        th,
        td {
          a {
            color: ${config.colors.accentBlue};
          }
        }
      }
    }

    .select {
      padding-right: 24px;
      text-align: right;
    }
  }

  tfoot {
    tr {
      position: relative;
      height: ${spacing(8.5)}px;

      td {
        padding: ${`${spacing() / 2}px ${spacing(2)}px`};

        .select {
          padding-right: 24px;
          text-align: right;
        }
      }
    }
  }
`;
