import { Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Maybe, SiteImage } from 'src/@types';
import { Styled } from './styles';

export interface Props {
  image: Maybe<SiteImage>;
  index: number;
  moveImage: (dIndex: number, hIndex: number) => void;
  selectImage: (img: SiteImage) => void;
  selected: SiteImage[];
  id?: string;
  className?: string;
}

export const Image: React.FC<Props> = (props) => {
  const { image, index, moveImage, selectImage, selected } = props;
  const className = 'Image';
  const ref = useRef(null);
  const type = 'Image';

  const [, drop] = useDrop(() => ({
    accept: type,
    hover(item) {
      if (!ref.current) return;
      // TODO: index doesn't exist here?
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dragIndex = (item as any).index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveImage(dragIndex, hoverIndex);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item as any).index = hoverIndex; // eslint-disable-line no-param-reassign
    },
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: () => ({ altId: image?.media?.altId, index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  drag(drop(ref));

  const createdAt = _.get(image, 'media.createdAt', '');
  const formatedDate = moment(createdAt).format('MMM DD, YYYY, h:mm A');

  return (
    <Styled className={className} ref={ref} style={{ opacity: isDragging ? '0.5' : '1' }}>
      <CheckCircle className={`${className} ${_.includes(selected, image?.media?.altId) ? 'selected' : ''}`} />

      <img
        id={`site-image-${index}`}
        alt={`site-image-${image?.media?.altId}`}
        className={`${className} ${_.includes(selected, image?.media?.altId) ? 'selected' : ''}`}
        height={90}
        onClick={() => selectImage(image?.media?.altId)}
        src={_.get(image, 'media.url', '')}
      />

      <div className={`${className} img-data`}>
        <Typography id={`site-image-date-${index}`} className={`${className} date`} variant="caption">
          {formatedDate || ''}
        </Typography>
      </div>
    </Styled>
  );
};
