import { theme } from '@evgo/react-material-components';
import { AccordionDetails } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: ${`0 ${spacing(1.5)}px ${spacing(3)}px`};

  div {
    margin: ${`${spacing(3)}px ${spacing(1.5)}px 0`};

    &.half {
      width: calc(50% - ${spacing(2)}px);
    }
  }
`;
