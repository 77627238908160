import { gql } from '@apollo/client';
import { FullCharger } from './FullCharger';

export const updateCharger = gql`
  mutation UpdateCharger($chargerInput: UpdateChargerInput) {
    updateCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullCharger}
`;
