import { gql } from '@apollo/client';
import { FullSite } from './FullSite';

export const updateSite = gql`
  mutation UpdateSite($siteInput: UpdateSiteInput) {
    updateSite(input: $siteInput) {
      ...FullSite
    }
  }
  ${FullSite}
`;
