import React from 'react';
import styled from 'styled-components';
import { Paper as MuiPaper } from '@material-ui/core';

type MetricCardGroupProps = {
  children: React.ReactNode;
  id?: string;
};

const Paper = styled(MuiPaper)`
  .MuiPaper-root {
    border-color: transparent;
  }
`;

export function MetricCardGroup({ children, id }: MetricCardGroupProps) {
  return (
    <Paper data-testid={id} variant="outlined">
      {children}
    </Paper>
  );
}
