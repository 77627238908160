import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { createDriver, updateDriver } from '../../../apollo/queries/drivers';
import { transformDriverResponse, transformDriverValues } from '../../../lib/helpers';
import { useFormikSubmit } from '../../../lib/hooks';
import { DriverForm, initialValues } from './DriverForm';
import { validations as validationSchema } from './DriverForm/validations';
import { Styled } from './styles';

/**
 * Driver view component
 */
export const DriverView: React.FC = () => {
  const { altId } = useParams<{ altId: string }>();
  const className = 'DriverView';
  const isLargeScreen = useMediaQuery('(min-width:1400px)');
  const handleSubmit = useFormikSubmit('Driver', createDriver, updateDriver, {
    transformValues: transformDriverValues,
    transformResponse: transformDriverResponse,
    altId,
  }) as never;

  return (
    <Styled className={className} isLargeScreen={isLargeScreen}>
      <Formik
        initialStatus={initialValues}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <DriverForm className={className} />
      </Formik>
    </Styled>
  );
};
