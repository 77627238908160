import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { FetchMore, LabelValue } from 'src/@types/shared';
import { Styled } from './styles';

export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  fetchmore: FetchMore;
  updateFilters: (
    search: {
      startSource?: string[];
      billingPlan?: string[];
      corrupt?: string[];
      from?: string | number;
      to?: string | number;
    },
    key: string,
  ) => void;
  className?: string;
}

export interface InputComponentProps {
  defaultValue?: string | number | readonly string[];
  inputRef?: React.RefObject<HTMLInputElement>;
  term: string;
  onChange?: (
    event: Partial<
      | React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    >,
  ) => void;
  onBlur?: (
    event: Partial<
      | React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    >,
  ) => void;
  className?: string;
}

const sourceList = [
  { label: 'Charger', value: 'Charger' },
  { label: 'Mobile App', value: 'Mobile app' },
  { label: 'Program Card', value: 'Program Card' },
];
const planList: LabelValue<string>[] = [];
const corruptList: LabelValue<number>[] = [];

/**
 * Returns a string value of selected filters.
 */
export const checkSelected = (selected: string[], list: LabelValue<string>[]): string => {
  if (selected.length === 0) {
    return 'All';
  }

  return selected.map((item) => _.find(list, { value: item })?.label).join(', ');
};

/**
 * ChargersListFilters component.
 */
export const TransactionsListFilters: React.FC<Props> = (props) => {
  const id = _.kebabCase('TransactionsListFilters');
  const className = id;
  const { updateFilters } = props;
  const [selectedStartSources, setSelectedStartSources] = useState<string[]>([]);
  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);
  const [selectedCorrupt, setSelectedCorrupt] = useState<string[]>([]);
  const today = moment().tz('UTC').utc().format('YYYY-MM-DD');
  const thirtyDaysPrior = moment().tz('UTC').utc().subtract(30, 'days').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(thirtyDaysPrior);
  const [endDate, setEndDate] = useState(today);
  const [rangeError, setRangeError] = useState('');

  const formatDate = (date: string, endOf = false) => {
    if (endOf) {
      return moment(date).tz('UTC').endOf('day').utc().valueOf();
    }
    return moment(date).tz('UTC').startOf('day').utc().valueOf();
  };

  const InputComponent: React.FC<InputComponentProps> = ({ defaultValue, inputRef, term, ...inputProps }) => {
    const [value, setValue] = useState(defaultValue);

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setValue(target.value);
      if (inputProps.onChange) inputProps.onChange({ target });
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (term === 'start') {
        if (formatDate(e.target.value) > formatDate(endDate, true)) {
          setRangeError('Invalid date range');
        } else {
          setRangeError('');
          updateFilters({ from: formatDate(e.target.value), to: formatDate(endDate, true) }, 'sessionStartOn');
        }

        setStartDate(e.target.value);
      } else {
        if (formatDate(startDate) > formatDate(e.target.value)) {
          setRangeError('Invalid date range');
        } else {
          setRangeError('');
          updateFilters({ from: formatDate(startDate), to: formatDate(e.target.value, true) }, 'sessionStartOn');
        }

        setEndDate(e.target.value);
      }

      inputProps.onBlur?.(e);
    };

    return (
      <Styled className={`${className} date-input-container`}>
        <input
          className={`${className} date-input-local`}
          ref={inputRef}
          {...inputProps}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
        />
      </Styled>
    );
  };

  return (
    <Styled>
      <div className={`${className} error`}>
        {!_.isEmpty(rangeError) && <span className={className}>{rangeError}</span>}
      </div>

      <section className={className}>
        <FormControl className={className}>
          <InputLabel className={`${className} label`} htmlFor={`${id}-start-date`}>
            Start Date
          </InputLabel>

          <TextField
            id={`${id}-start-date`}
            type="date"
            defaultValue={startDate}
            className={`${className} date-picker`}
            inputProps={{ className: `${className} date-picker-input` }}
            InputProps={{
              inputComponent: (inputProps) => (
                <InputComponent
                  className={`${className} date-input-parent`}
                  term="start"
                  {...(inputProps as Record<string, unknown>)}
                />
              ),
            }}
          />
          <div className={`${className} clear-container`}></div>
          {formatDate(startDate) !== formatDate(thirtyDaysPrior) && (
            <Typography
              className={`${className} clear-start-date`}
              variant="caption"
              color="secondary"
              id={`${id}-start-date-clear`}
              onClick={() => {
                setStartDate(thirtyDaysPrior);
                if (formatDate(thirtyDaysPrior) > formatDate(endDate, true)) {
                  setRangeError('Invalid date range');
                } else {
                  setRangeError('');
                  updateFilters({ from: formatDate(thirtyDaysPrior), to: formatDate(endDate, true) }, 'sessionStartOn');
                }
              }}
            >
              Clear
            </Typography>
          )}
        </FormControl>

        <FormControl className={className}>
          <InputLabel className={`${className} label`} htmlFor={`${id}-end-date`}>
            End Date
          </InputLabel>

          <TextField
            id={`${id}-end-date`}
            type="date"
            defaultValue={endDate}
            className={`${className} date-picker`}
            inputProps={{ className }}
            InputProps={{
              inputComponent: (inputProps) => (
                <InputComponent term="end" {...(inputProps as Record<string, unknown>)} />
              ),
            }}
          />
          {formatDate(endDate, true) !== formatDate(today, true) ? (
            <Typography
              className={`${className} clear-end-date`}
              variant="caption"
              id={`${id}-end-date-clear`}
              color="secondary"
              onClick={() => {
                setEndDate(today);
                if (formatDate(startDate) > formatDate(today, true)) {
                  setRangeError('Invalid date range');
                } else {
                  setRangeError('');
                  updateFilters({ from: formatDate(startDate), to: formatDate(today, true) }, 'sessionStartOn');
                }
              }}
            >
              Clear
            </Typography>
          ) : null}
        </FormControl>

        <FormControl className={className}>
          <InputLabel className={`${className} label`} htmlFor={`${id}-start-source`}>
            Start Source
          </InputLabel>
          <Select
            className={`${className} select`}
            displayEmpty
            input={<Input id="select-multiple-checkbox" />}
            multiple
            id={`${id}-start-source`}
            onChange={({ target }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
              updateFilters({ startSource: target.value as string[] }, 'startSource');
              setSelectedStartSources(target.value as string[]);
            }}
            renderValue={(selected) => checkSelected(selected as string[], sourceList)}
            value={selectedStartSources}
          >
            <MenuItem className={className} disabled value="">
              All
            </MenuItem>
            {sourceList.map((source, i) => {
              return (
                <MenuItem key={source.value} value={source.value} disableRipple>
                  <Checkbox
                    checked={selectedStartSources.includes(source.value)}
                    checkedIcon={<CheckBox fontSize="small" />}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    inputProps={{ id: `${id}-${i}-source-checkbox` }}
                    disableRipple
                  />
                  <ListItemText primary={source.label} style={{ fontSize: 'small' }} />
                </MenuItem>
              );
            })}
          </Select>
          {!_.isEmpty(selectedStartSources) ? (
            <Typography
              className={`${className} clear-source`}
              id={`${id}-start-sources-clear`}
              variant="caption"
              color="secondary"
              onClick={() => {
                updateFilters({ startSource: [] as string[] }, 'startSource');
                setSelectedStartSources([]);
              }}
            >
              Clear
            </Typography>
          ) : null}
        </FormControl>

        <FormControl className={className}>
          <InputLabel className={`${className} label`} htmlFor={`${id}-billing-plan`}>
            Billing Plan
          </InputLabel>
          <Select
            className={`${className} select`}
            displayEmpty
            input={<Input id="select-multiple-checkbox" />}
            multiple
            id={`${id}-billing-plan`}
            onChange={({ target }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
              updateFilters({ billingPlan: target.value as string[] }, 'billingPlan');
              setSelectedPlans(target.value as string[]);
            }}
            renderValue={(selected) => checkSelected(selected as string[], planList)}
            value={selectedPlans}
          >
            <MenuItem className={className} disabled value="">
              All
            </MenuItem>
            {planList?.map((plan, i) => {
              return (
                <MenuItem key={plan.value} value={plan.value} disableRipple>
                  <Checkbox
                    checked={selectedPlans.includes(`${plan.value}`)}
                    checkedIcon={<CheckBox fontSize="small" />}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    inputProps={{ id: `${id}-${i}-plan-checkbox` }}
                    disableRipple
                  />
                  <ListItemText primary={plan.label} style={{ fontSize: 'small' }} />
                </MenuItem>
              );
            })}
          </Select>
          {!_.isEmpty(selectedPlans) ? (
            <Typography
              className={`${className} clear-plan`}
              id={`${id}-billing-plans-clear`}
              variant="caption"
              color="secondary"
              onClick={() => {
                updateFilters({ billingPlan: [] }, 'billingPlan');
                setSelectedPlans([]);
              }}
            >
              Clear
            </Typography>
          ) : null}
        </FormControl>

        <FormControl className={className}>
          <InputLabel className={`${className} label`} htmlFor={`${id}-corrupt`}>
            Corrupt
          </InputLabel>
          <Select
            className={`${className} select`}
            displayEmpty
            input={<Input id="select-multiple-checkbox" />}
            multiple
            id={`${id}-corrupt`}
            onChange={({ target }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
              updateFilters({ corrupt: target.value as string[] }, 'corrupt');
              setSelectedCorrupt(target.value as string[]);
            }}
            renderValue={(selected) => checkSelected(selected as string[], planList)}
            value={selectedCorrupt}
          >
            <MenuItem className={className} disabled value="">
              All
            </MenuItem>
            {corruptList.map((corrupt, i) => {
              return (
                <MenuItem key={corrupt.value} value={corrupt.value} disableRipple>
                  <Checkbox
                    checked={selectedCorrupt.includes(`${corrupt.value}`)}
                    checkedIcon={<CheckBox fontSize="small" />}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    inputProps={{ id: `${id}-${i}-corrupt-checkbox` }}
                    disableRipple
                  />
                  <ListItemText primary={corrupt.label} style={{ fontSize: 'small' }} />
                </MenuItem>
              );
            })}
          </Select>
          {!_.isEmpty(selectedCorrupt) ? (
            <Typography
              className={`${className} clear-corrupt`}
              id={`${id}-corrupt-clear`}
              variant="caption"
              color="secondary"
              onClick={() => {
                updateFilters({ corrupt: [] }, 'corrupt');
                setSelectedCorrupt([]);
              }}
            >
              Clear
            </Typography>
          ) : null}
        </FormControl>
      </section>
    </Styled>
  );
};
