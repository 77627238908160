import { FormValues } from '.';

export const validations = (selected?: Record<string, unknown>) => (values: FormValues) => {
  const errors: Partial<FormValues> = {};

  if (!selected && !values.internalNumber) {
    errors.internalNumber = 'Required';
  }
  if (!selected && !values.externalNumber) {
    errors.externalNumber = 'Required';
  }

  return errors;
};
