/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

/**
 * Gets list with up-to-date limit info
 */
export const checkAll = (
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  isCheckAll: boolean,
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>,
  edges?: any[] | null,
): void => {
  const allEdges = edges?.map((edge) => edge?.altId);

  if (isCheckAll) {
    setCheckAll(false);
    setChecked([]);
  } else {
    setCheckAll(true);
    setChecked(allEdges as string[]);
  }
};
