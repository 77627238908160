import { Fragment } from 'react';
import { List } from '@material-ui/core';

import { isNested } from '../helpers';
import { FlatItem } from './FlatItem';
import { NestedItem } from './NestedItem';
import { NavigationItem } from '../types';
import DrawerDivider from './DrawerDivider';

type DrawerContentProps = {
  children: NavigationItem[];
  handleDrawerToggle?: () => void;
};

export function DrawerContent({ children, handleDrawerToggle }: DrawerContentProps) {
  return (
    <List data-testid="Sidenav-drawer-content">
      {children.map((item, index) => {
        const ammendedItem = { ...item, handleDrawerToggle };
        const isolated = Boolean(ammendedItem.isolated);
        return (
          <Fragment key={index}>
            {isolated && <DrawerDivider />}
            {isNested(ammendedItem) ? <NestedItem {...ammendedItem} /> : <FlatItem {...ammendedItem} />}
            {isolated && index !== children.length - 1 && <DrawerDivider />}
          </Fragment>
        );
      })}
    </List>
  );
}
