import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  section {
    header {
      padding: ${`0 ${spacing(3)}px`};
    }
  }

  div {
    &.container {
      display: flex;
      flex: 1 1 100%;

      .controls {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 20px;
      }
    }

    &.driver-list {
      max-height: 534px;
      overflow: auto;
    }
  }

  .driver-list {
    padding: 0;
  }

  .status-menu {
    position: absolute;
  }

  h2 {
    color: ${colors.accentBlue};
  }

  table {
    td,
    th {
      padding: ${spacing(2)}px;

      &.checkbox {
        > span {
          padding: 0;
        }
      }

      &:first-child {
        padding-left: ${spacing(3)}px;
      }

      &:last-child {
        padding-right: ${spacing(3)}px;
      }
    }

    td {
      position: relative;
    }

    a {
      &.driver-link {
        color: ${colors.accentBlue};
      }
    }
  }
`;
