import { QueryResult } from '@apollo/client';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Query, SalesforcePropertiesWithMeta, SalesforceProperty } from '../../../../../@types';
import { Styled as StyledPaper } from './styles';

export interface Props {
  propertyOptions: Array<PropertyOptions | undefined> | undefined;
  selectedProperties: Array<PropertyOptions | undefined> | undefined;
  setSelectedProperties: (selected: PropertyOptions[]) => void;
  setValues: (values: SalesforcePropertyWithInfo) => void;
  values: SalesforcePropertyWithInfo;
  propertiesMetaData: Omit<SalesforcePropertiesWithMeta, 'edges'>;
  onChangePage: (
    propertiesFetchMore: QueryResult<never, never>['fetchMore'],
    propertiesMetaData: Omit<SalesforcePropertiesWithMeta, 'edges'>,
    type: string,
    queryData: Query,
  ) => (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  propertiesFetchMore: QueryResult<never, never>['fetchMore'];
  queryData: Query;
  className?: string;
}

export interface PropertyOptions {
  altId: string;
  address1: string;
  address2: string;
  locality: string;
  administrativeArea: string;
  postalCode: string;
  pid: string;
  propertyName: string;
  disabled: boolean;
}

export type SalesforcePropertyWithInfo =
  | Array<SalesforceProperty & { properties?: PropertyOptions[] }>
  | { properties?: PropertyOptions[] };

export const SelectPropertiesStep: React.FC<Props> = (props) => {
  const id = _.kebabCase('SelectPropertiesStep');
  const className = id;
  const {
    propertyOptions,
    selectedProperties,
    setSelectedProperties,
    setValues,
    values,
    propertiesMetaData,
    onChangePage,
    propertiesFetchMore,
    queryData,
  } = props;
  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledPaper className={customClass}>
      <Table className={className} id={`${id}-property-list`}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell id={`${id}-name-column-header`}>Name</TableCell>
            <TableCell id={`${id}-pid-column-header`}>Property ID</TableCell>
            <TableCell id={`${id}-address-column-header`}>Address</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!!propertyOptions ? (
            propertyOptions.map((row, key) => (
              <TableRow key={key}>
                <TableCell>
                  <Checkbox
                    inputProps={{ id: `${id}-${key}-property-option-checkbox` }}
                    checked={
                      !!selectedProperties?.length && !!selectedProperties?.find((property) => _.isEqual(property, row))
                    }
                    onChange={() => {
                      if (
                        selectedProperties?.length &&
                        selectedProperties?.find((property) => _.isEqual(property, row))
                      ) {
                        const idx = selectedProperties?.findIndex((property) => _.isEqual(property, row));
                        selectedProperties?.splice(idx, 1);
                        setSelectedProperties([...selectedProperties] as PropertyOptions[]);
                        setValues({ ...values, properties: [...selectedProperties] } as never);
                      } else {
                        setSelectedProperties([...(selectedProperties || []), row] as PropertyOptions[]);
                        setValues({
                          ...values,
                          properties: [...(selectedProperties || []), row],
                        } as never);
                      }
                    }}
                    value={row?.altId}
                    disabled={row?.disabled}
                    color="default"
                  />
                </TableCell>
                <TableCell id={`${id}-property-name`}>{row?.propertyName}</TableCell>
                <TableCell id={`${id}-pid`}>{row?.pid}</TableCell>
                <TableCell id={`${id}-address`}>
                  {`${row?.address1}${row?.address2 ? ` ${row?.address2}` : ''} ${row?.locality}, ${
                    row?.administrativeArea
                  } ${row?.postalCode}`}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell id={`${id}-no-properties-cell`}>
                <Typography className={className} variant="body2">
                  No properties found.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={className}
              count={propertiesMetaData.total || 0}
              onPageChange={onChangePage(propertiesFetchMore, propertiesMetaData, 'input', queryData)}
              rowsPerPage={propertiesMetaData.pageSize || 0}
              rowsPerPageOptions={[5]}
              page={propertiesMetaData.page || 0}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};
