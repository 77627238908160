import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { GetChargerExtendQuery, SessionAggregation, SessionDatePreset } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { ChargerSpecifications } from './ChargerSpecifications';
import ConnectorsList from './ConnectorsList';
import { UtilizationBarChartCardWithQuery } from 'src/components/shared/UtilizationBarChartCardWithQuery';

type ProfileProps = {
  refetch: () => void;
  charger: GetChargerExtendQuery['getCharger'];
  evses: NonNullable<NonNullable<GetChargerExtendQuery['getCharger']>['evses']>;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(5)}px;
`;

export default function Profile({ refetch, charger, evses }: ProfileProps) {
  const { chargerName = '', serialNumber = '', chargerId = '', chargerModel, site, altId } = charger || {};

  return (
    <>
      <StyledCard>
        <UtilizationBarChartCardWithQuery
          title="Charger Utilization"
          siteName={site?.siteName || ''}
          chargerName={chargerName || ''}
          aggregateBy={SessionAggregation.Day}
          datePreset={SessionDatePreset.Last_7d}
          chargerIds={[altId as string]}
        />
      </StyledCard>
      <ConnectorsList
        refetch={refetch}
        chargerName={chargerName || ''}
        evses={evses}
        datePreset={SessionDatePreset.Last_30d}
        chargerIds={[altId as string]}
      />
      <StyledCard>
        <ChargerSpecifications
          data-testid="charger-spec-card"
          identifier={chargerId || ''}
          serialNumber={serialNumber || ''}
          model={chargerModel?.modelName || ''}
          firmwareVersion={chargerModel?.firmwareVersion || ''}
          breakerRating={chargerModel?.breakerRating || undefined}
          powerSharingCapable={chargerModel?.simultaneousChargeSupported || false}
        ></ChargerSpecifications>
      </StyledCard>
    </>
  );
}
