import moment from 'moment-timezone';
import { BarChartData } from 'src/components/shared/UtilizationBarGraph';
import { Maybe, GetSessionsConsumptionForExtendResponse } from 'src/@types';

const array = moment.weekdays();
array.push(array.shift() || '');
export const weekDays = array;

export const getGraphData = (data: Maybe<GetSessionsConsumptionForExtendResponse> | undefined): BarChartData[] => {
  const timeSeries = data?.timeSeries || [];
  const barGraphData: BarChartData[] = weekDays.map((day) => ({ label: day.substring(0, 3), value: 0 }));
  weekDays.map((day, index) => {
    let totalSessions = 0;
    let count = 0;
    timeSeries.forEach((value) => {
      const weekDay = moment(value?.timestamp?.split('T')[0]).isoWeekday() - 1;
      if (weekDay === index) {
        totalSessions += value?.totalSessions || 0;
        count += 1;
      }
    });
    const averageSessions = totalSessions / count;
    barGraphData[index].value = averageSessions || 0;
  });

  return barGraphData;
};
