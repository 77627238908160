import { gql } from '@apollo/client';

export const getAccount = gql`
  query getAccount(
    $accountInput: GetAccountInput
    $accountVehiclesInput: ListAccountVehiclesInput
    $cardsInput: ListCardsInput
    $driversInput: ListDriversInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    getAccount(input: $accountInput) {
      altId
      id
      enrolledOn
      accountStatus
      accountType
      vendorId
      updatedAt
      drivers(input: $driversInput) {
        total
        edges {
          accountId
          id
          altId
          accountId
          firstName
          lastName
          email
          phone
          deletedAt
          driverType
          driverStatus {
            id
            columnText
          }
          firebaseId
          createdAt
          accountVehicles(input: $accountVehiclesInput) {
            total
            edges {
              altId
              nickName
              vin
              macAddress
              autochargeStatus {
                id
                columnText
                columnValue
              }
              deletedAt
              vehicle {
                altId
                makeModelYear
                vehicleConnectors {
                  id
                  connectorName: name
                }
              }
            }
          }
          notificationPreferencesForOp(input: $preferencesInput) {
            edges {
              driverId
              notificationItemId
              emailOn
              smsOn
              pushOn
            }
          }
        }
      }
      cards(input: $cardsInput) {
        total
        edges {
          altId
          brand
          cardStatus
          cardTypeId
          externalNumber
          internalNumber
          updatedAt
          createdAt
          driver {
            altId
            firstName
            lastName
          }
          account {
            accountStatus
          }
        }
      }
      cardOrders {
        total
        edges {
          orderStatus
          orderedAt
          fulfilledAt
          driver {
            email
          }
        }
      }
      contracts(input: { pageSize: 999 }) {
        total
        edges {
          altId
          plan {
            altId
            planName
            terminationBehavior
          }
          startDate
          endDate
          closureDate
          status {
            id
            columnText
          }
        }
      }
      mailingAddress {
        altId
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      billingAddress {
        altId
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
    }
  }
`;
