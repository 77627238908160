import { theme } from '@evgo/react-material-components';
import { AccordionDetails } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../../../config';

const {
  palette,
  spacing,
  typography: { fontSize },
} = theme;

export const Styled = styled(AccordionDetails)`
  padding: ${spacing(3)}px;
  color: ${palette.text.secondary};
  font-size: ${fontSize * (6 / 7)}px;

  a {
    color: ${config.colors.accentBlue};
  }
`;
