import { gql } from '@apollo/client';

export const listExtendUsers = gql`
  query listExtendUsers($input: ListExtendUsersInput) {
    listExtendUsers(input: $input) {
      total
      edges {
        altId
        username
        firebaseId
        createdAt
        hosts {
          total
          edges {
            altId
            hostName
          }
        }
      }
    }
  }
`;
