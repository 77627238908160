import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Query } from '../../../../../../@types';
import { listGeographicalRegions } from '../../../../../../apollo/queries/geographicalRegions';
import { geographicalRegionsInput, shapeGeographicalRegions } from '../../../../../../lib/helpers';
import { useBlurDropdown } from '../../../../../../lib/hooks';
import { Styled as StyledForm } from './styles';

export const initialValues = {
  hostName: '',
  hid: '',
  address1: '',
  address2: null,
  country: '',
  locality: '',
  administrativeArea: '',
  postalCode: '',
};

export interface Props {
  hostLoading: boolean;
  hostData: Query;
  setHostFormIsValid: (valid: boolean) => void;
  setValues: (values: Record<string, unknown>) => void;
  className?: string;
}

export const CreateHostForm: React.FC<Props> = (props) => {
  const id = _.kebabCase('CreateHostForm');
  const className = id;

  const { resetForm, values, errors, handleChange, handleBlur, status, touched, isValid, validateForm } =
    useFormikContext<Record<string, unknown>>();
  const { hostLoading, hostData, setHostFormIsValid, setValues } = props;
  const handleBlurDropdown = useBlurDropdown();

  const { data: geographicalRegions } = useQuery(listGeographicalRegions, {
    variables: geographicalRegionsInput,
  });
  const { shapedStateOptions, shapedProvinceOptions } = shapeGeographicalRegions(geographicalRegions);
  const stateOptions = [...shapedStateOptions, ...shapedProvinceOptions];

  useEffect(() => {
    if (!hostLoading && hostData) {
      const hostValues = _.pick(_.get(hostData, 'getSalesforceHost', {}), Object.keys(initialValues));

      resetForm({ status: { ...hostValues }, values: { ...hostValues } });
      validateForm();
    }
  }, [hostLoading, resetForm, hostData, validateForm]);

  useEffect(() => {
    if (!isValid) {
      setHostFormIsValid(true);
    } else {
      setHostFormIsValid(false);
    }
  }, [isValid, setHostFormIsValid]);

  useEffect(() => {
    setValues({ ...values });
  }, [setValues, values]);

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledForm className={className}>
      <article className={customClass}>
        <div className={className}>
          <Typography className={className} variant="subtitle1" component="h3">
            Account Information
          </Typography>

          <TextInfo
            id={`${id}-host-name`}
            className={clsx(className, 'full', _.get(status, 'hostName') ? 'disabled' : '')}
            disabled={!!_.get(status, 'hostName')}
            error={errors.hostName && touched.hostName}
            helpertext={errors.hostName && touched.hostName && errors.hostName}
            label="Host Name *"
            labelProps={{ className }}
            name="hostName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={_.get(values, 'hostName', '')}
          />

          <TextInfo
            id={`${id}-hid`}
            className={clsx(className, 'full', _.get(status, 'hid') ? 'disabled' : '')}
            disabled={true}
            label="Host ID *"
            labelProps={{ className }}
            value={_.get(values, 'hid', '')}
          />
        </div>

        <Divider vertical />

        <div className={className}>
          <Typography className={className} variant="subtitle1" component="h3">
            Host Address
          </Typography>

          <TextInfo
            id={`${id}-address-1`}
            className={clsx(className, 'full', _.get(status, 'address1') ? 'disabled' : '')}
            disabled={!!_.get(status, 'address1')}
            error={errors.address1 && touched.address1}
            helpertext={errors.address1 && touched.address1 && errors.address1}
            label="Address 1 *"
            labelProps={{ className }}
            name="address1"
            onBlur={handleBlur}
            onChange={handleChange}
            value={_.get(values, 'address1', '')}
          />

          <TextInfo
            id={`${id}-address-2`}
            className={clsx(className, 'full', _.get(status, 'address2') ? 'disabled' : '')}
            disabled={!!_.get(status, 'address2')}
            error={errors.address2 && touched.address2}
            helpertext={errors.address2 && touched.address2 && errors.address2}
            label="Address 2"
            labelProps={{ className }}
            name="address2"
            onBlur={handleBlur}
            onChange={handleChange}
            value={_.get(values, 'address2', '')}
          />

          <TextInfo
            id={`${id}-locality`}
            className={clsx(className, 'half', _.get(status, 'locality') ? 'disabled' : '')}
            disabled={!!_.get(status, 'locality')}
            error={errors.locality && touched.locality}
            helpertext={errors.locality && touched.locality && errors.locality}
            label="City *"
            labelProps={{ className }}
            name="locality"
            onBlur={handleBlur}
            onChange={handleChange}
            value={_.get(values, 'locality', '')}
          />

          <Dropdown
            id={`${id}-administrative-area`}
            name="administrativeArea"
            className={clsx(className, 'half', _.get(status, 'administrativeArea') ? 'disabled' : '')}
            disabled={!!_.get(status, 'administrativeArea')}
            error={errors.administrativeArea && touched.administrativeArea}
            helpertext={errors.administrativeArea && touched.administrativeArea && errors.administrativeArea}
            label="State *"
            onBlur={handleBlurDropdown}
            onChange={handleChange}
            options={stateOptions}
            value={_.get(values, 'administrativeArea', '')}
          />

          <TextInfo
            id={`${id}-postal-code`}
            className={clsx(className, 'half')}
            disabled={!!_.get(status, 'postalCode')}
            error={errors.postalCode && touched.postalCode}
            helpertext={errors.postalCode && touched.postalCode && errors.postalCode}
            label="Zip Code *"
            labelProps={{ className }}
            name="postalCode"
            onBlur={handleBlur}
            onChange={handleChange}
            value={_.get(values, 'postalCode', '')}
          />

          <TextInfo
            id={`${id}-country`}
            className={clsx(className, 'half', 'disabled')}
            disabled
            error={errors.country && touched.country}
            helpertext={errors.country && touched.country && errors.country}
            label="Country"
            labelProps={{ className }}
            value={'USA'}
          />
        </div>
      </article>
    </StyledForm>
  );
};
