import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { PropertiesWithMeta, Query } from 'src/@types';
import { FetchMore, MatOnChangePageEvent } from 'src/@types/shared';
import { getHostById } from '../../../../apollo/queries/hosts';
import { sanitizeSearch, titleCase, updateQuery } from '../../../../lib/helpers';
import { ListSearch as PropertiesListSearch } from '../../../shared/ListSearch';
import { PropertyRow } from './PropertyRow';
import { Styled as StyledPaper } from './styles';
import { Row } from './types';

export interface Props {
  className?: string;
}

const searchFields = [
  'pid',
  'propertyName',
  'host_salesforceHost_hostName',
  'address1',
  'locality',
  'administrativeArea',
  'postalCode',
];

/**
 * Changes host properties page
 */
export const onPageChange =
  (fetchMore: FetchMore, metadata: PropertiesWithMeta, altId: string) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    // TODO: there are no filters here?
    const { filters, pageSize, sort, search } = metadata as PropertiesWithMeta & { filters: unknown };

    return fetchMore({
      updateQuery,
      variables: {
        hostInput: { altId },
        propertiesInput: {
          filters,
          page,
          pageSize,
          search: sanitizeSearch(search, searchFields),
          sort: _.pickBy(_.pick(sort, ['pid', 'altId', 'status', 'hostName', 'propertyName']), (i) => i !== null),
        },
      },
    });
  };

/**
 * Changes host properties page size
 */
export const onRowsPerPageChange =
  (fetchMore: FetchMore, metadata: PropertiesWithMeta, altId: string) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ReturnType<FetchMore> => {
    // TODO: there are no filters here?
    const { filters, sort, search } = metadata as PropertiesWithMeta & { filters: unknown };

    return fetchMore({
      updateQuery,
      variables: {
        hostInput: { altId },
        propertiesInput: {
          filters,
          page: 0,
          pageSize: event.target.value,
          search: sanitizeSearch(search, searchFields),
          sort: _.pickBy(_.pick(sort, ['pid', 'altId', 'status', 'hostName', 'propertyName']), (i) => i !== null),
        },
      },
    });
  };

/**
 * Sorts host properties
 */
export const onSortClick =
  (fetchMore: FetchMore, metadata: PropertiesWithMeta, field: string, altId: string) => (): ReturnType<FetchMore> => {
    // TODO: there are no filters here?
    const { filters, pageSize, search } = metadata as PropertiesWithMeta & { filters: unknown };
    let sort = { [field]: 'DESC' };

    if (_.get(metadata, `sort.${field}`, 'DESC') === 'DESC') sort = { [field]: 'ASC' };

    return fetchMore({
      updateQuery,
      variables: {
        hostInput: { altId },
        propertiesInput: {
          filters,
          page: 0,
          pageSize,
          search: sanitizeSearch(search, searchFields),
          sort,
        },
      },
    });
  };

/**
 * Search properties list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: PropertiesWithMeta,
  target: EventTarget & HTMLInputElement,
): ReturnType<FetchMore> => {
  const { page, pageSize, sort, filter } = metadata;

  let search: Record<string, Record<string, string>> | null = {};
  if (target.value.length) {
    searchFields.forEach((field) => {
      search = search || {};
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      propertiesInput: {
        page,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
        search,
      },
    },
  });
};

/**
 * Host Properties component
 */
export const HostProperties: React.FC<Props> = (props) => {
  const className = 'HostProperties';
  const altId = _.get(props, 'match.params.altId', 0);

  const { fetchMore, ...host } = useQuery<Query>(getHostById, {
    fetchPolicy: 'network-only',
    variables: {
      hostInput: { altId },
      propertiesInput: {
        page: 0,
        pageSize: 10,
      },
    },
    skip: !altId,
  });
  const selected = host?.data?.getHost || {};
  const { edges, ...metadata } = selected?.properties || {};
  const debouncedOnSearchChange = (typeof onSearchChange === 'function'
    ? _.debounce(onSearchChange, 500)
    : onSearchChange) as unknown as typeof onSearchChange;

  const headers = [
    { id: 'propertyName', label: 'Name' },
    { id: 'status', label: 'Status' },
    { id: 'address', label: 'Address' },
    { id: 'pid', label: 'ID' },
    { id: 'totalChargers', label: 'Chargers' },
    { id: 'totalSites', label: 'Sites' },
    { id: 'expand', label: '' },
  ];

  const rows: Row[] =
    edges?.map((edge) => ({
      altId: edge?.altId || '',
      propertyName: edge?.propertyName || '',
      status: titleCase(_.get(edge, 'status', 'UNKNOWN')),
      address: `${_.get(edge, 'address1', '')}${_.get(edge, 'address2') ? ` ${_.get(edge, 'address2')}` : ''}, ${_.get(
        edge,
        'locality',
        '',
      )}, ${_.get(edge, 'administrativeArea', '')} ${_.get(edge, 'postalCode', '')}`,
      pid: _.toUpper(edge?.pid || ''),
      totalChargers: _.reduce(
        _.get(edge, 'sites.edges', []),
        (chargerTotal, site) => {
          return chargerTotal + _.get(site, 'chargers.total', 0);
        },
        0,
      ),
      totalSites: _.get(edge, 'sites.total', 0),
      sites: _.get(edge, 'sites.edges', []),
    })) || [];

  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            Properties
          </Typography>

          <Typography className={className} variant="caption">
            Currently viewing {metadata.total}
          </Typography>
        </div>

        <div className={className}>
          <Link
            to="/sites/new"
            state={{
              hid: selected.hid,
            }}
          >
            <Button className={className} color="secondary" variant="contained">
              New Site
            </Button>
          </Link>

          <div className={className}>
            <PropertiesListSearch
              className={`${className} search`}
              type="property"
              onSearchChange={(search) => debouncedOnSearchChange(fetchMore, metadata, search)}
            />
          </div>
        </div>
      </header>

      <Divider />

      <Table className={className}>
        <TableHead>
          <TableRow className={className}>
            {headers.map((header) => (
              <TableCell key={header.id} className={`${className} ${header.id}`}>
                <TableSortLabel
                  active={_.isString(_.get(metadata, `sort.${header.id}`))}
                  direction={(_.toLower(_.get(metadata, `sort.${header.id}`, 'asc')) || 'asc') as 'asc'}
                  onClick={onSortClick(fetchMore, metadata, header.id, selected?.altId || '')}
                  disabled={!['propertyName', 'status', 'pid'].includes(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => {
            return (
              <PropertyRow
                className={row.altId}
                colSpan={headers.length}
                host={{ altId, hid: selected.hid as string }}
                key={i}
                row={row}
                metadata={metadata}
              />
            );
          })}
        </TableBody>

        <TableFooter className={className}>
          <TableRow className={className}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={headers.length}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={onPageChange(fetchMore, metadata, selected?.altId || '')}
              onRowsPerPageChange={onRowsPerPageChange(fetchMore, metadata, selected?.altId || '')}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};
