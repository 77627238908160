import React from 'react';
import { IconButton, Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader } from 'src/components/shared/Card';
import styled from 'styled-components';
import useAnalytics from 'src/lib/hooks/useAnalytics';

type Props = {
  title: string;
  subtitle?: string;
  route: string;
  disabled?: boolean;
  trackEvent?: string;
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const EditSection: React.FC<Props> = ({ title, subtitle, route, children, disabled, trackEvent }) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();

  return (
    <Card>
      <HeaderWrapper>
        <CardHeader title={title} titleVariant="h6" subtitle={subtitle} />
        {!disabled && (
          <IconButton
            data-testid="edit-link-btn"
            onClick={() => {
              navigate(route);
              if (trackEvent) track(trackEvent);
            }}
          >
            <Edit />
          </IconButton>
        )}
      </HeaderWrapper>
      <Box pt={1}>{children}</Box>
    </Card>
  );
};
