import { gql } from '@apollo/client';

export const createExtendUser = gql`
  mutation createExtendUser($extenduserInput: CreateExtendUserInput) {
    createExtendUser(input: $extenduserInput) {
      altId
      hosts {
        edges {
          hostName
          hid
        }
      }
    }
  }
`;
