/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing, text } = theme;
const { colors } = config;

export const Styled = styled.div`
  section {
    margin: 0;

    div {
      display: flex;
      flex-wrap: wrap;
      padding: 0;

      .actions {
        flex-basis: 100%;
        /* TODO: use theme colors */
        background: #3b748b;
        border-radius: 0;

        button {
          color: ${text.primaryEmphasis.high};
          border-radius: 0;
          padding-left: ${spacing()}px;
          width: 100%;
          justify-content: start;

          &.selected {
            background-color: ${palette.action.active};
          }

          &.disabled {
            opacity: 0.5;
          }

          svg {
            &.left {
              margin-right: ${spacing()}px;
            }
          }
        }
      }
    }
  }
  h2 {
    color: ${colors.accentBlue};
  }
`;
