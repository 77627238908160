/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import React from 'react';

/**
 * Gets list with up-to-date limit info
 */
export const checkOne = (
  event: { target: EventTarget & HTMLElement },
  checked: string[],
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>,
  chargers: any[],
): void => {
  const chargerId = event.target.getAttribute('edge-altid');

  if (!chargerId) {
    return;
  }

  if (checked.indexOf(chargerId) === -1) {
    setChecked([...checked, chargerId]);
    if (_.concat(checked, chargerId).length === chargers.length) {
      setCheckAll(true);
    }
  } else {
    setChecked(_.without(checked, chargerId));
    setCheckAll(false);
  }
};
