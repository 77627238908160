import { useQuery } from '@apollo/client';
import { Divider, Dropdown } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { listChargerModelOptions } from '../../../../../../apollo/queries/models';
import { ChargerModelView } from '../../ChargerModelView';
import { initialValues } from '../../initialValues';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  expanded?: boolean;
  onChange?: () => void;
  className?: string;
}

/**
 * Charger Hardware component
 */
export const ChargerHardware: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerHardware');
  const className = id;
  const { altId } = useParams<{ altId: string }>();

  const { errors, setFieldValue, touched, values } = useFormikContext<typeof initialValues>();

  const { data: chargerModelsList } = useQuery<Query>(listChargerModelOptions);

  const modelOptions = chargerModelsList?.listChargerModelOptions;
  const onUpdateChargerModel = useCallback(
    (value) => {
      const [newChargerModel] = _.filter(modelOptions, (option) => option?.altId === value);
      setFieldValue('chargerModel', newChargerModel);
      setFieldValue('simultaneousChargingEnabled', false);
    },
    [setFieldValue, modelOptions],
  );

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledAccordion
      className={`${customClass} charger-hardware`}
      expanded={props.expanded}
      onChange={props.onChange}
      defaultExpanded={true}
    >
      <AccordionSummary className={className} expandIcon={altId ? <ExpandMore /> : null} component="header">
        <Typography className={className} variant="h6" component="h2">
          Hardware and Communications
        </Typography>
      </AccordionSummary>

      <Divider className={className} />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <Dropdown
              className={`${className} half model`}
              id={`${id}-charger-model-dropdown`}
              error={!!(_.get(touched, 'chargerModel') && _.get(errors, 'chargerModel'))}
              helpertext={_.get(touched, 'chargerModel') && _.get(errors, 'chargerModel')}
              label="Model *"
              labelProps={{ className }}
              options={
                modelOptions?.map((model) => ({ label: model?.modelName, value: model?.altId, evses: model?.evses })) ||
                []
              }
              isDisabled={true}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onUpdateChargerModel(target.value)}
              value={values?.chargerModel?.altId}
            />

            <Divider className={className} />

            <ChargerModelView powerOnly={true} interfaceOnly={true} />
          </div>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
