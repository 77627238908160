import { Divider, StatBlock, theme } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import config from '../../../../../config';
import { titleCase } from '../../../../../lib/helpers';
import { Styled as StyledPaper } from './styles';

export interface Props {
  className?: string;
}

/**
 * Drivers status component.
 */
export const DriverStatus: React.FC<Props> = ({ className: parentClass }) => {
  const className = 'DriverStatus';
  const { status } = useFormikContext();

  let accountStatusColor = theme.palette.text.secondary;
  const driverStatusColor =
    _.get(status, 'driverStatus.columnText', '') === 'Removed' ? theme.palette.error.main : '#44A047';

  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;
  if (_.get(status, 'account.accountStatus') === 'ACTIVE') accountStatusColor = '#44A047';
  else if (_.get(status, 'account.accountStatus') === 'REGISTERED') accountStatusColor = '#44A047';
  else if (_.get(status, 'account.accountStatus') === 'SUSPENDED') accountStatusColor = theme.palette.error.main;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={`${className} panel-summary`}>
        <Typography className={className} variant="h6" component="h2">
          Driver Status
        </Typography>
        <Typography className={className} variant="caption" data-testid="driver-status-last-updated-value">
          {_.get(status, 'updatedAt') ? moment(_.get(status, 'updatedAt', '')).format('MMM DD/YY') : 'Not updated'}
        </Typography>
      </header>

      <Divider />

      <div className={className}>
        <StatBlock color={driverStatusColor} title="Driver Status" data-testid="driver-status-stat-block">
          {_.get(status, 'driverStatus.columnText', 'Unknown')}
        </StatBlock>

        <Divider vertical />

        <StatBlock color={theme.palette.error.main} title="Issue(s)" data-testid="driver-status-issues-block">
          2
        </StatBlock>

        <Divider vertical />

        <StatBlock color={theme.palette.error.main} title="Plan(s)" data-testid="driver-status-plans-block">
          3
        </StatBlock>

        <Divider vertical />

        <StatBlock color={accountStatusColor} title="Account Status" data-testid="driver-status-account-stat-block">
          {titleCase(_.get(status, 'account.accountStatus', 'Unknown'))}
        </StatBlock>

        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} title="Creation Date" data-testid="driver-status-created-at-block">
          {moment(_.get(status, 'createdAt', '')).format('MMM DD/YY')}
        </StatBlock>
      </div>
    </StyledPaper>
  );
};
