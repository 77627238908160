// @ts-nocheck
import { createStaticRanges } from 'react-date-range';
import { subDays, endOfDay, startOfDay, subMonths } from 'date-fns';

const defineds = {
  startOfYesterday: startOfDay(subDays(new Date(), 1)),
  endOfYesterday: endOfDay(subDays(new Date(), 1)),
  endOfToday: endOfDay(new Date()),
};

export const staticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: startOfDay(subDays(new Date(), 1)),
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: endOfDay(subDays(new Date(), 7)),
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: endOfDay(subDays(new Date(), 30)),
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: 'Last 6 months',
    range: () => ({
      startDate: subMonths(defineds.startOfYesterday, 6),
      endDate: defineds.endOfYesterday,
    }),
  },
]);
