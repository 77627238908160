import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { SessionAggregation, useGetSessionsConsumptionForExtendQuery } from 'src/@types';
import { MetricCard } from 'src/components/shared/MetricCard';
import { formatNumber } from 'src/lib/helpers/formatNumber';
import { getPercentageChange } from 'src/lib/helpers/getPercentageChange';
import { getConsumptionMetricCardDetails } from 'src/lib/helpers/getConsumptionMetricCardDetails';

type Props = {
  siteIds: string[];
};

export const yesterday = moment().tz('UTC').utc().subtract(1, 'days').endOf('day').toISOString();
export const last30days = moment().tz('UTC').utc().subtract(30, 'days').startOf('day').toISOString();
export const last60days = moment().tz('UTC').utc().subtract(60, 'days').startOf('day').toISOString();

export const ConsumptionMetricCards: React.FC<Props> = ({ siteIds }) => {
  const monthlyData = {
    powerValue: 0,
    previousPowerValue: 0,
    powerCostValue: 0,
    previousPowerCostValue: 0,
  };

  const { data: dailyData } = useGetSessionsConsumptionForExtendQuery({
    variables: {
      input: {
        aggregateBy: SessionAggregation.Day,
        startDate: last60days,
        endDate: yesterday,
        siteIds,
      },
    },
    skip: !siteIds.length,
  });

  const timeSeries = dailyData?.getSessionsConsumptionForExtend?.timeSeries || [];

  timeSeries.forEach((day) => {
    if (moment(day?.timestamp).isAfter(last30days)) {
      monthlyData.powerValue += day?.powerValue || 0;
      monthlyData.powerCostValue += day?.powerCostValue || 0;
    } else {
      monthlyData.previousPowerValue += day?.powerValue || 0;
      monthlyData.previousPowerCostValue += day?.powerCostValue || 0;
    }
  });

  // calculations for monthly utilization metric card
  const powerPercentageChange = getPercentageChange(
    monthlyData.powerValue - monthlyData.previousPowerValue,
    monthlyData.previousPowerValue,
  );
  const powerMetrics = getConsumptionMetricCardDetails(powerPercentageChange, true);

  // calculations for monthly income metric card
  const powerCostPercentageChange = getPercentageChange(
    monthlyData.powerCostValue - monthlyData.previousPowerCostValue,
    monthlyData.previousPowerCostValue,
  );
  const powerCostMetrics = getConsumptionMetricCardDetails(powerCostPercentageChange);

  return (
    <>
      <Grid item>
        <MetricCard
          className="metric-card"
          icon={powerMetrics.iconDiv}
          primaryText={`${formatNumber(monthlyData.powerValue)}`}
          secondaryText="kWh"
          label="Monthly Utilization"
          tertiaryText={powerMetrics.trendText}
          tertiaryFeedbackText=" in the past 30 days versus the previous 30 days"
          data-testid="metricCard-monthly-utilization"
        />
      </Grid>
      <Grid item>
        <MetricCard
          className="metric-card"
          icon={powerCostMetrics.iconDiv}
          primaryText={`$${formatNumber(monthlyData.powerCostValue)}`}
          tertiaryText={powerCostMetrics.trendText}
          tertiaryFeedbackText=" in the past 30 days versus the previous 30 days"
          label="Monthly Income"
          data-testid="metricCard-monthly-income"
        />
      </Grid>
    </>
  );
};
