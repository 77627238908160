import { gql } from '@apollo/client';

export const listSessionsV2 = gql`
  query listSessionsV2Extend($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total

      edges {
        altId
        vendorId
        connector {
          connectorType {
            columnText
          }
          evse {
            charger {
              chargerName
              site {
                siteName
                timeZone
              }
            }
          }
        }
        plan {
          planName
          chargerGroup {
            chargerGroupName
            altId
          }
        }
        stopSource {
          columnText
        }
        startSource {
          columnText
        }
        startTime
        endTime
        meterStart
        lastMeterValue
        transaction {
          altId
          tax
          type {
            columnText
          }
        }
        cost
      }
    }
  }
`;
