import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { createTableFiltersVar } from 'src/apollo/cache/tableFilters';
import { ReactiveVar } from '@apollo/client';
import { cache } from 'src/apollo';
import { TableFilters, TableFilterValue } from 'src/@types/client';

interface TableFilterOptions<T> {
  queryName: string;
  defaultFilters: T;
}

export const createClientTableFilters = <T extends TableFilters<Record<string, TableFilterValue>>>({
  queryName,
  defaultFilters,
}: TableFilterOptions<T>) => {
  const tableFiltersVar: ReactiveVar<T> = createTableFiltersVar(defaultFilters);

  const GET_TABLE_FILTERS = gql`
    query GetTableFilters {
      ${queryName} @client
    }
  `;

  cache.policies.addTypePolicies({
    Query: {
      fields: {
        [queryName]: {
          read() {
            return tableFiltersVar();
          },
        },
      },
    },
  });

  return () => {
    const { data } = useQuery(GET_TABLE_FILTERS);
    const [tableFilters, setTableFilters] = useState<T>(data?.[queryName]);
    const filters = tableFiltersVar();

    useEffect(() => {
      setTableFilters(tableFiltersVar());
    }, [filters]);

    const updateTableFilters = (newFilters: Partial<T>) => {
      tableFiltersVar({ ...tableFiltersVar(), ...newFilters });
    };

    return { tableFilters, updateTableFilters };
  };
};
