import { gql } from '@apollo/client';
import { FullPlan } from './FullPlan';

export const getPlan = gql`
  query getPlan($planInput: GetPlanInput) {
    getPlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlan}
`;
