import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, spacing } = theme;

interface Props {
  isLargeScreen: boolean;
}

export const Styled = styled.footer<Props>`
  position: fixed;
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  height: ${spacing(8)}px;
  width: calc(100% - 200px);
  width: ${(props) => (props.isLargeScreen ? 'calc(100% - 200px)' : '100%')};
  width: ${(props) =>
    props.isLargeScreen
      ? 'calc(100% - calc(200px + var(--safe-area-inset-left)))'
      : 'calc(100% - var(--safe-area-inset-left))'};
  background-color: ${palette.primary[50]};
  left: ${(props) => (props.isLargeScreen ? '200px' : '0')};
  left: ${(props) =>
    props.isLargeScreen ? 'calc(200px + var(--safe-area-inset-left))' : 'var(--safe-area-inset-left)'};
  bottom: 0;
  padding: ${`${spacing()}px ${spacing(8)}px`};
  padding: ${`${spacing()}px calc(${spacing(
    8,
  )}px + var(--safe-area-inset-right)) calc(${spacing()}px + var(--safe-area-inset-bottom)) ${spacing(8)}px`};
  z-index: 90;

  > a,
  > button {
    margin: 0 ${spacing()}px;

    &:last-child {
      margin-right: 0;
    }
  }

  > span {
    flex: 1;
    margin-right: ${spacing()}px;
    color: ${palette.text.secondary};
  }
`;
