import { Divider, StatBlock, theme } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React from 'react';
import config from '../../../../../config';
import { titleCase } from '../../../../../lib/helpers';
import { Styled as StyledPaper } from './styles';

export interface Props {
  status: string;
  className?: string;
}

/**
 * Chargers network status component.
 */
export const ChargerStatus: React.FC<Props> = (props) => {
  const className = 'ChargerStatus';
  const status = props.status || 'Unknown';
  let customClass = className;
  let statusColor = config.colors.accentBlue;

  if (props.className) {
    customClass += ` ${props.className}`;
  }
  if (status === 'Available') {
    statusColor = 'green';
  } else if (status === 'Unavailable') {
    statusColor = theme.palette.error.main;
  } else if (status === 'Unknown') {
    statusColor = theme.palette.text.secondary;
  }

  // TODO: hardcoded 2 hours ago
  return (
    <StyledPaper className={`${customClass} wrapper`} component="section">
      <header className={`${className} panel-summary`}>
        <Typography className={className} variant="h6" component="h2">
          Charger Status
        </Typography>
        <Typography className={className} variant="caption">
          Last updated 2 hours ago
        </Typography>
      </header>

      <Divider />

      <div className={className}>
        <StatBlock color={statusColor} title="Status">
          {titleCase(status) || 'Unknown'}
        </StatBlock>
        <Divider vertical />

        <StatBlock color={theme.palette.error.main} title="Issue(s)">
          2
        </StatBlock>

        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} suffix="hours ago" title="Last Heartbeat">
          12
        </StatBlock>

        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} icon={<ArrowDropDown />} stat="3%" title="Uptime">
          82%
        </StatBlock>

        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} icon={<ArrowDropUp />} stat="8%" title="Utilization">
          48%
        </StatBlock>
      </div>
    </StyledPaper>
  );
};
