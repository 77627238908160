import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../../config';

const {
  palette,
  spacing,
  typography: { fontSize },
} = theme;

export const Styled = styled(Paper)`
  section {
    > header {
      display: flex;
      color: ${palette.text.secondary};
      font-size: ${fontSize * (6 / 7)}px;
      background-color: ${palette.divider};
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      div {
        a {
          color: ${config.colors.accentBlue};
        }

        span {
          padding: 0;
        }

        svg {
          height: ${fontSize * ((6 / 7) * 2)}px;
          margin: ${`-${fontSize * (6 / 7 / 2)}px ${spacing()}px -${fontSize * (6 / 7 / 2)}px 0`};
        }
      }
    }

    label {
      height: ${spacing(3)}px;
    }

    > div {
      &:first-of-type {
        margin-bottom: -1px;

        button {
          flex: 1;
        }

        span {
          background-color: ${palette.primary.main};
        }
      }
    }
  }
`;
