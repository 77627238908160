/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Styled } from './styles';

export interface CcsIconProps {
  id?: string;
  className?: string;
}

/**
 * CCS icon component
 */
export const CcsIcon: React.FC<CcsIconProps> = ({ id, className: parentClass }) => {
  const className = 'CcsIcon';
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <Styled
      id={id}
      className={customClass}
      style={{ enableBackground: 'new 0 0 36 36' } as any}
      version="1.1"
      viewBox="0 0 36 36"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <g className={customClass} transform="translate(-782 -77)">
        <path
          className={customClass}
          d="M795.2,107.6c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-1.7,1.3-3,3-3S795.2,105.9,795.2,107.6z M794,107.6
          c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8C793.2,109.4,794,108.6,794,107.6z M810.8,107.6c0,1.7-1.3,3-3,3
          s-3-1.3-3-3c0-1.7,1.3-3,3-3S810.8,105.9,810.8,107.6z M809.6,107.6c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
          C808.8,109.4,809.6,108.6,809.6,107.6z M795.2,92.6c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4
          C794.1,90.2,795.2,91.3,795.2,92.6z M794,92.6c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2
          C793.5,93.8,794,93.3,794,92.6z M807.2,90.2c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4S805.9,90.2,807.2,90.2
          z M807.2,91.4c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S807.9,91.4,807.2,91.4z M800,99.8
          c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6S802,99.8,800,99.8z M800,98.6c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4
          s-2.4,1.1-2.4,2.4S798.7,98.6,800,98.6z M801.2,85.4c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.6,3.6
          C802.8,89,801.2,87.5,801.2,85.4z M802.4,85.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4
          C803.5,83,802.4,84.1,802.4,85.4z M795.2,89c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6S797.2,89,795.2,89z
          M797.6,85.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4C796.5,87.8,797.6,86.7,797.6,85.4z M813.2,107.6
          c0,3-2.4,5.4-5.4,5.4h-15.6c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4h2.3c-6.6-3-9.6-10.8-6.5-17.5c3-6.6,10.8-9.6,17.5-6.5
          c6.6,3,9.6,10.8,6.5,17.5c-1.3,2.9-3.6,5.2-6.5,6.5h2.3C810.8,102.2,813.2,104.6,813.2,107.6z M800,102.2c6.6,0,12-5.4,12-12
          c0-6.6-5.4-12-12-12s-12,5.4-12,12C788,96.8,793.4,102.2,800,102.2z M812,107.6c0-2.3-1.9-4.2-4.2-4.2h-15.6
          c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2h15.6C810.1,111.8,812,109.9,812,107.6z"
        />
      </g>
    </Styled>
  );
};
