import { gql } from '@apollo/client';

export const listPlanOptions = gql`
  query listPlanOptions {
    listCardBrandOptions: __type(name: "CardBrand") {
      edges: enumValues {
        label: description
        value: name
      }
    }

    listIntervalUnitOptions: __type(name: "IntervalUnit") {
      edges: enumValues {
        label: description
        value: name
      }
    }

    listRolloverPlanOptions: listPlans(input: { pageSize: 9999 }) {
      edges {
        label: planName
        value: altId
      }
    }

    listPlanStatusOptions: __type(name: "PlanStatus") {
      edges: enumValues {
        label: description
        value: name
      }
    }

    listTerminationBehaviorOptions: __type(name: "TerminationBehavior") {
      edges: enumValues {
        label: description
        value: name
      }
    }
  }
`;
