/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloQueryResult, QueryResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { CardOrder, CardOrdersWithMeta, ListCardOrdersFilterInput, ListCardsInput, Maybe, Query } from 'src/@types';
import { listCardOrders, updateCardOrders } from '../../../../apollo/queries/cards';
import { sanitizeSearch, titleCase, updateQuery } from '../../../../lib/helpers';
import { ListSearch as RequestsListSearch } from '../../../shared/ListSearch';
import { ConfirmCardsModal } from '../ConfirmCardsModal';
import { OrdersListFilters } from '../OrdersListFilters';
import { Styled as StyledPaper } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export interface Props {
  title: string;
  buttonAction: string;
  className?: string;
}

const searchFields = [
  'driver_firstName',
  'driver_lastName',
  'account_billingAddress_address1',
  'account_billingAddress_locality',
  'account_billingAddress_administrativeArea',
  'account_billingAddress_postalCode',
  'accountId',
];
const sort = { driver_lastName: 'ASC' };

/**
 * Gets list with up-to-date limit info
 */
export const sanitizeFilter = (filter: CardOrdersWithMeta['filter']) => {
  const orderStatus = filter?.orderStatus ? _.omit(filter.orderStatus, '__typename') : null;

  let filters = {};
  if (!_.isEmpty(orderStatus)) {
    filters = { orderStatus };
  }

  return filters;
};

/**
 * Changes cards list page
 */
export const onPageChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: CardOrdersWithMeta,
  event: React.ChangeEvent<HTMLInputElement>,
  page: number,
): Promise<ApolloQueryResult<unknown>> => {
  const { pageSize, search, filter } = metadata;

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page,
        pageSize,
        search: sanitizeSearch(search, searchFields),
        filter: sanitizeFilter(filter),
        sort,
      },
    },
  });
};

/**
 * Changes cards list page size
 */
export const onRowsPerPageChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: CardOrdersWithMeta,
  event: React.ChangeEvent<HTMLInputElement>,
): Promise<ApolloQueryResult<unknown>> => {
  const { search, filter } = metadata;

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize: event.target.value,
        search: sanitizeSearch(search, searchFields),
        filter: sanitizeFilter(filter),
        sort,
      },
    },
  });
};

/**
 * Update card statuses
 */
export const updateCardsStatus = (checked: string[], bulkUpdate: any): Promise<ApolloQueryResult<unknown>> => {
  return bulkUpdate({
    updateQuery,
    variables: {
      input: {
        cardOrderIds: checked,
      },
    },
  });
};

/**
 * Gets list with up-to-date limit info
 */
export const onCheck = (
  event: React.ChangeEvent<HTMLInputElement>,
  checked: string[],
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>,
  orders: Maybe<CardOrder>[],
): void => {
  const orderID = event?.target?.getAttribute?.('order-altid') || '';

  if (checked.indexOf(orderID) === -1) {
    setChecked(_.concat(checked, orderID));
    if (_.concat(checked, orderID).length === orders.length) {
      setCheckAll(true);
    }
  } else {
    setChecked(_.without(checked, orderID));
    setCheckAll(false);
  }
};

/**
 * Gets list with up-to-date limit info
 */
export const onCheckAll = (
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  checkAll: boolean,
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>,
  orders: Maybe<Maybe<CardOrder>[]>,
): void => {
  const allOrders = orders?.map((order) => order?.altId) as string[];

  if (checkAll) {
    setCheckAll(false);
    setChecked([]);
  } else {
    setCheckAll(true);
    setChecked(allOrders);
  }
};

/**
 * Filter Chargers list
 */
const updateFilters = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  meta: CardOrdersWithMeta,
  newFilters: ListCardOrdersFilterInput,
) => {
  const { pageSize, search } = meta;
  let orderStatusFilters = null;

  if (newFilters?.orderStatus) {
    orderStatusFilters = { orderStatus: { in: newFilters?.orderStatus || [] } };
  }

  const shapedFilters = { ...orderStatusFilters };
  const inputs = {
    page: 0,
    pageSize,
    sort,
    filter: shapedFilters,
    search: sanitizeSearch(search, searchFields),
  };

  return fetchMore({
    updateQuery,
    variables: {
      input: inputs,
    },
  });
};

/**
 * Search cards list
 */
export const onSearchChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: ListCardsInput,
  target: React.ChangeEvent<HTMLInputElement>['target'],
): Promise<ApolloQueryResult<unknown>> => {
  const { pageSize, filter } = metadata;
  let search: Maybe<{ [key: string]: unknown }> = {};

  if (target.value.length) {
    searchFields.forEach((field) => {
      search = search || {};

      if (field === 'accountId') {
        search[field] = { eq: Number(`${target.value}`) };
      } else {
        search[field] = { iLike: `%${target.value}%` };
      }
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize,
        search,
        filter: sanitizeFilter(filter),
        sort,
      },
    },
  });
};

/**
 * Requests Table component
 */
export const RequestsTable: React.FC<Props> = (props) => {
  const id = _.kebabCase('RequestsTable');
  const className = id;

  const { title, buttonAction } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { data, fetchMore, loading } = useQuery<Query>(listCardOrders, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page: 0,
        pageSize: 10,
        sort,
      },
    },
  });

  const { modalProps } = useModalContext();
  const [onUpdateCardOrders] = useMutation(updateCardOrders, {
    refetchQueries: () => [
      {
        query: listCardOrders,
        variables: {
          input: {
            page: 0,
            pageSize: 10,
            sort,
          },
        },
      },
    ],
  });
  const [checked, setChecked] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const debouncedFetchMore: any = typeof fetchMore === 'function' ? _.debounce(fetchMore, 500) : fetchMore;
  const { edges, ...metadata } = data?.listCardOrders || {};
  const debouncedOnSearchChange: any =
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : fetchMore;

  const columns = [
    { id: 'customerName', label: 'Customer Name', sortable: false },
    { id: 'dateRequested', label: 'Date Requested', sortable: false },
    { id: 'accountNumber', label: 'Account Number', sortable: false },
    { id: 'plan', label: 'Plan', sortable: false },
    { id: 'address', label: 'Address', sortable: false },
    { id: 'orderStatus', label: 'Status', sortable: false },
    { id: 'fulfilledAt', label: 'Date Sent', sortable: false },
  ];
  const [getExportData, { data: exportData }] = useLazyQuery<Query>(listCardOrders, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      const button = document.getElementById('export-link');
      if (!button) {
        return;
      }

      button.click();
    },
  });

  const endDateFormatted = moment(new Date()).format('YYYY-MM-DD');
  let markSentDisabled = false;
  if (edges && !_.isEmpty(edges) && !_.isEmpty(checked)) {
    edges.forEach((edge) => {
      if (!!edge?.altId && checked.includes(edge?.altId)) {
        switch (edge?.orderStatus) {
          case 'SENT':
            markSentDisabled = true;
            break;
          default:
            break;
        }
      }
    });
  }

  if (_.isEmpty(checked)) {
    markSentDisabled = true;
  }

  return (
    <StyledPaper className={className} data-testid="requests-table-parent" component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            {title}
          </Typography>
        </div>

        <div className={className}>
          <Button
            className={className}
            color="secondary"
            variant="contained"
            disabled={markSentDisabled}
            data-testid="requests-table-mark-as-sent-button"
            onClick={() => {
              setShowConfirmationModal(true);
            }}
          >
            {buttonAction}
          </Button>
          <RequestsListSearch
            className={`${className} search`}
            type="card"
            data-testid="requests-table-list-search"
            onSearchChange={(search) => {
              setChecked([]);
              setCheckAll(false);
              debouncedOnSearchChange(fetchMore, metadata, search);
            }}
          />
        </div>
      </header>
      <OrdersListFilters
        updateFilters={(newFilters) => {
          setChecked([]);
          setCheckAll(false);
          updateFilters(debouncedFetchMore, metadata, newFilters);
        }}
        data-testid="requests-table-list-filters"
        className={`${className} filters`}
      />

      <Divider />

      <Table className={className} data-testid="requests-table">
        <TableHead>
          <TableRow className={className}>
            <TableCell className={`${className} checkbox`}>
              <Checkbox
                className={className}
                indeterminate={checked.length > 0 && !!edges && checked.length < edges?.length}
                checked={!!edges && checked.length === edges.length && checkAll}
                onChange={() => onCheckAll(setChecked, checkAll, setCheckAll, edges || [])}
                inputProps={{ id: `${id}-check-all-checkbox` }}
              />
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.id} className={className}>
                <TableSortLabel
                  // active={ !_.isEmpty(_.get(metadata, `sort.${column.id}`)) }
                  // direction={ _.toLower(_.get(metadata, `sort.${column.id}`) || 'asc') }
                  // onClick={
                  //   column.sortable
                  //     ? onSortChange(fetchMore, metadata, column.id)
                  //     : null
                  // }
                  disabled={!column.sortable}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading &&
            edges?.map((order, i) => {
              if (!order) {
                return null;
              }

              return (
                <TableRow key={order.altId}>
                  <TableCell className={`${className} checkbox`}>
                    <Checkbox
                      className={`${className} checkbox`}
                      checked={checked.indexOf(order?.altId || '') !== -1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onCheck(e, checked, setChecked, setCheckAll, edges)
                      }
                      inputProps={{
                        id: `${id}-row-${i}-checkbox`,
                        // @ts-ignore
                        'order-altid': order.altId,
                      }}
                    />
                  </TableCell>

                  <TableCell className={`${className} customerName`}>
                    {_.get(order, 'driver.firstName')} {_.get(order, 'driver.lastName')}
                  </TableCell>
                  <TableCell className={`${className} dateRequested`}>
                    {moment(moment.utc(_.get(order, 'orderedAt')).toDate())
                      .local()
                      .format('LLLL')}
                  </TableCell>
                  <TableCell className={`${className} accountNumber`}>{_.get(order, 'account.id')}</TableCell>
                  <TableCell className={`${className} plan`}>Membership</TableCell>
                  <TableCell className={`${className} address`}>{`${
                    _.get(order, 'account.billingAddress.address1') || ''
                  } ${_.get(order, 'account.billingAddress.address2') || ''} ${
                    _.get(order, 'account.billingAddress.locality') || ''
                  } ${_.get(order, 'account.billingAddress.administrativeArea') || ''} ${
                    _.get(order, 'account.billingAddress.postalCode') || ''
                  }`}</TableCell>
                  <TableCell className={`${className} orderStatus`}>{titleCase(_.get(order, 'orderStatus'))}</TableCell>
                  <TableCell className={`${className} fulfilledAt`}>
                    {!_.isEmpty(_.get(order, 'fulfilledAt'))
                      ? moment(moment.utc(_.get(order, 'fulfilledAt')).toDate())
                          .local()
                          .format('LLLL')
                      : ''}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter className={className}>
          <TableRow className={className}>
            <TableCell className={`${className} export-button`}>
              <IconButton
                color="secondary"
                data-testid="requests-table-export-button"
                disabled={_.isEmpty(edges)}
                onClick={() => {
                  getExportData({
                    variables: {
                      input: {
                        page: 0,
                        pageSize: 100000,
                        sort,
                        search: sanitizeSearch(metadata.search, searchFields),
                        filter: sanitizeFilter(metadata.filter),
                      },
                    },
                  });
                }}
              >
                <GetApp fontSize="large" />
              </IconButton>

              <CSVLink
                data={
                  exportData?.listCardOrders?.edges?.map((order) => {
                    return {
                      CustomerName: `${order?.driver?.firstName} ${order?.driver?.lastName}`,
                      DateRequested: moment(moment.utc(_.get(order, 'orderedAt')).toDate())
                        .local()
                        .format('LLLL'),
                      AccountNumber: order?.account?.id,
                      // TODO: .plan does not exist
                      Plan: (order?.account as any)?.plan,
                      Address: order?.account?.billingAddress?.address1,
                      Status: order?.orderStatus,
                      DateSent: order?.fulfilledAt,
                    };
                  }) || []
                }
                filename={`${endDateFormatted}_requests.csv`}
                data-testid="requests-table-export-link"
                id="export-link"
              />
            </TableCell>

            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              data-testid="requests-table-pagination"
              onPageChange={(e, page) => {
                onPageChange(fetchMore, metadata, e as unknown as React.ChangeEvent<HTMLInputElement>, page);
                setCheckAll(false);
                setChecked([]);
              }}
              onRowsPerPageChange={(e) => {
                onRowsPerPageChange(fetchMore, metadata, e as React.ChangeEvent<HTMLInputElement>);
                setCheckAll(false);
                setChecked([]);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <ConfirmCardsModal
        className={className}
        open={showConfirmationModal}
        updateCardsStatus={(bulkUpdate) => {
          updateCardsStatus(checked, bulkUpdate);
        }}
        modalProps={modalProps}
        cards={checked}
        action="sent"
        markCardsAsSent={onUpdateCardOrders}
        closeModal={() => {
          setShowConfirmationModal(false);
        }}
      />
    </StyledPaper>
  );
};
