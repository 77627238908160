import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import { Button, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { listFalconConstraints } from '../../../../../apollo/queries/options';
import { initialValues } from '../initialValues';
import { PlanProduct } from './PlanProduct';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plan products component
 */
export const PlanProducts: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanProducts');
  const className = id;
  const { isSubmitting, values, setFieldValue } = useFormikContext<typeof initialValues>();
  const { products } = values;

  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  const { data: defaultProductTypeId, loading: productTypeIdLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'product_type' },
          columnText: { eq: 'Credit' },
        },
      },
    },
  });
  const { data: defaultCredExpPolicyId, loading: credExpPolicyIdLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'credit_exp_policy' },
          columnText: { eq: 'Expires' },
        },
      },
    },
  });
  const { data: defaultCredReoccurrenceId, loading: credReoccurrenceIdLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'credit_reoccurrence' },
          columnText: { eq: 'One Time' },
        },
      },
    },
  });
  const { data: defaultSubReoccurrenceId, loading: subReoccurrenceIdLoading } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'plans' },
          columnName: { eq: 'subscription_reoccurrence' },
          columnText: { eq: 'One Time' },
        },
      },
    },
  });

  const loading =
    productTypeIdLoading || credExpPolicyIdLoading || credReoccurrenceIdLoading || subReoccurrenceIdLoading;

  const onAddPlanProduct = () => {
    const productsCopy = [
      ...products,
      {
        productType: 'CREDIT',
        productTypeId: Number(_.get(defaultProductTypeId, 'listFalconConstraints.edges[0].id')),
        creditAmount: '00.00',
        creditReoccurrenceId: Number(_.get(defaultCredReoccurrenceId, 'listFalconConstraints.edges[0].id')),
        creditExpPolicyId: Number(_.get(defaultCredExpPolicyId, 'listFalconConstraints.edges[0].id')),
        creditOneTimeAmount: '00.00',
        subscriptionFee: '00.00',
        subscriptionReoccurrenceId: Number(_.get(defaultSubReoccurrenceId, 'listFalconConstraints.edges[0].id')),
      },
    ];
    setFieldValue('products', productsCopy);
  };
  const onDeletePlanProduct = (i: number) => {
    const productsCopy = [...products];
    productsCopy.splice(i, 1);
    setFieldValue('products', productsCopy);
  };

  return (
    <StyledAccordion id={parentId} className={customClass} defaultExpanded={true}>
      <AccordionSummary className={className} component="header" expandIcon={<ExpandMoreIcon />} id={`${id}-header`}>
        <Typography className={className} variant="h6" component="h2">
          Plan Products
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} products`}>
        <Button
          id={`${id}-add-product-button`}
          disabled={loading || isSubmitting}
          className={className}
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            onAddPlanProduct();
          }}
          variant="contained"
        >
          Add Product
        </Button>
        {products?.map((product, index) => (
          <PlanProduct
            className={className}
            key={index}
            index={index}
            onDeletePlanProduct={() => onDeletePlanProduct(index)}
          />
        ))}
      </AccordionDetails>
    </StyledAccordion>
  );
};
