import { gql } from '@apollo/client';

export const createDriver = gql`
  mutation createDriver($input: CreateDriverInput) {
    createDriver(input: $input) {
      firstName
      lastName
      driverType
      phone
      email
    }
  }
`;
