/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

interface GeographicalRegionsShape {
  shapedStateOptions: any[];
  shapedProvinceOptions: any[];
}

export const geographicalRegionsInput = {
  usaInput: {
    pageSize: 100,
    filter: {
      country: {
        eq: 'USA',
      },
    },
  },
  canadaInput: {
    pageSize: 100,
    filter: {
      country: {
        eq: 'CAN',
      },
    },
    sort: {
      name: 'ASC',
    },
  },
};

/**
 * A helper to get USA and Canada state/province lists for options
 * @param geographicalRegions - Formatted object of geographicRegions.
 * @return Formatted object of list options.
 */
export const shapeGeographicalRegions = (geographicalRegions: any): GeographicalRegionsShape => ({
  shapedStateOptions: _.get(geographicalRegions, 'listGeographicalRegionsUsa.edges', []),
  shapedProvinceOptions: _.get(geographicalRegions, 'listGeographicalRegionsCanada.edges', []),
});
