import { theme } from '@evgo/react-material-components';
import styled, { css } from 'styled-components';

const { palette, spacing } = theme;

interface Props {
  isLargeScreen: boolean;
  sticky: boolean;
}

const sidenavWidth = '200px';

export const PageActionsContainer = styled.footer<Props>`
  width: 100%;
  box-sizing: border-box;
  margin-top: ${spacing(4)}px;

  ${({ sticky, isLargeScreen }) =>
    sticky &&
    css`
      background-color: ${theme.palette.background.default};
      position: fixed;
      bottom: 0;
      z-index: 90;
      padding: ${spacing(0, 5)};
      left: ${isLargeScreen ? sidenavWidth : '0'};
      left: ${isLargeScreen ? `calc(${sidenavWidth} + var(--safe-area-inset-left))` : 'var(--safe-area-inset-left)'};
      width: ${isLargeScreen ? `calc(100% - ${sidenavWidth})` : '100%'};
      width: ${isLargeScreen
        ? `calc(100% - calc(${sidenavWidth} + var(--safe-area-inset-left)))`
        : 'calc(100% - var(--safe-area-inset-left))'};
    `}
`;

export const PageActionsContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  padding: ${spacing(3, 0, 8)};
  border-top: 1px solid ${palette.action.focus};

  .page-actions-message {
    flex: 1;
    margin-right: ${spacing()}px;
    color: ${palette.text.secondary};
  }

  a,
  button {
    margin: ${spacing(0, 1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;
