import { Divider } from '@evgo/react-material-components';
import {
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CardRow } from './CardRow';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  className?: string;
}

const columns = [
  { key: 'internal', label: 'Internal' },
  { key: 'external', label: 'External' },
  { key: 'brand', label: 'Brand' },
  { key: 'assignee', label: 'Assignee' },
  { key: 'cardStatus', label: 'Status' },
  { key: 'updatedAt', label: 'Status Updated' },
  { key: 'actions', label: '' },
];

/** Cards List view component. */
export const DriverCards: React.FC<Props> = ({ className: parentClass }) => {
  const className = 'DriverCards';
  const { status } = useFormikContext();
  const { altId } = useParams<{ altId: string }>();

  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;

  const driverCards = _.filter(_.get(status, 'cards.edges', []), ({ driver }) => driver.altId === altId);

  return (
    <StyledAccordion className={`${customClass} driver-cards`} data-testid="driver-cards-expand-panel-toggle">
      <AccordionSummary
        className={className}
        expandIcon={<ExpandMore />}
        data-testid="driver-cards-panel-header"
        component="header"
      >
        <div className={`${className} container`}>
          <div className={`${className} title`}>
            <Typography className={className} variant="h6" component="h2">
              Cards
            </Typography>
            <Typography className={className} variant="caption">
              {driverCards ? `Currently viewing ${driverCards.length}` : '0 Cards'}
            </Typography>
          </div>
        </div>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} vehicle-list`}>
        <Table className={className} data-testid="driver-cards-table">
          <TableHead className={className} data-testid="driver-cards-table-header">
            <TableRow className={className} data-testid="driver-cards-table-column-row">
              {columns.map((column) => (
                <TableCell
                  data-testid="driver-cards-table-column-cell"
                  key={column.key}
                  className={`${className} ${column.key}`}
                  component="th"
                >
                  <TableSortLabel disabled={true} data-testid="driver-cards-table-column-sort-label">
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={className}>
            {driverCards.map((card, i) => (
              <CardRow key={i} className={className} card={card} />
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </StyledAccordion>
  );
};
