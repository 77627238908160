import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Paper)`
  margin-bottom: ${spacing(4)}px;

  .panel-summary {
    display: flex;
    flex-direction: column;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};
    align-items: start;
  }

  div {
    display: flex;
  }

  h2 {
    color: ${colors.accentBlue};
  }

  h5 {
    font-size: 16px;
    color: ${colors.sherpaBlue};
  }
`;
