import { gql } from '@apollo/client';

export const listExtendHosts = gql`
  query listExtendHosts($input: ListExtendHostsInput) {
    listExtendHosts(input: $input) {
      total
      edges {
        altId
        createdAt
        prefix
        host {
          altId
          hostName
          hid
          status
          vendorId
        }
      }
    }
  }
`;
