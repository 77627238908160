import _ from 'lodash';
import moment from 'moment-timezone';
import { ApolloError } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { Day } from 'src/@types';

type EmptyProps = {
  loading: boolean;
  error?: ApolloError;
};

export const weekDays = [Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday, Day.Sunday];

export const dayHours = _.times(24, (index) =>
  moment({
    hour: index,
    minute: 0,
  }).format('hh:mm A'),
);

export const mininumCardWidth = 24;
export const mininumCardHeight = 16;
export const cardMargin = theme.spacing(1);
export const cardPadding = theme.spacing(1);
export const rowHeight = 7;
export const rowHeightPixels = theme.spacing(rowHeight) + 1; //height of cell + border of cell

export const EmptyRows = ({ loading, error }: EmptyProps) => {
  if (loading) {
    return (
      <div>
        <Box py={3} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
          <Box component="span" px={2}>
            Loading...
          </Box>
        </Box>
      </div>
    );
  }
  return <div>{error && 'Something went wrong!'}</div>;
};
