import { gql } from '@apollo/client';

export const createContact = gql`
  mutation createContact($contactInput: CreateContactInput) {
    createContact(input: $contactInput) {
      altId
      contactName
      workTitle
      email
      phone
    }
  }
`;
