/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const { spacing } = theme;

export const StyledHeading = styled(Typography)`
  border-bottom: 1px solid ${theme.palette.grey['300']};
`;

export const StyledPriceContent = styled.div`
  padding: ${spacing(1.5)}px ${spacing(5)}px ${spacing(1.5)}px ${spacing(1.5)}px;
`;

export const StyledHmiDisplayContent = styled.div`
  padding: ${spacing(1.5)}px ${spacing(5)}px ${spacing(1.5)}px ${spacing(1.5)}px;
  border-left: 1px solid ${theme.palette.grey['300']};
`;

export const Styled = styled.article`
  margin-bottom: ${spacing(3)}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  h2 {
    font-size: ${spacing(3)}px;
    font-weight: 700;
  }
  p {
    padding: ${spacing()}px 0;
  }
`;
