import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { initialValues } from '../../../initialValues';
import { Styled as StyledAccordionDetails } from './styles';

export interface Props {
  connectorIndex: number;
  portIndex: number;
  id?: string;
  className?: string;
}

/**
 * Charger connector tags tab component
 */
export const ChargerConnectorTagsTab: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerConnectorTagsTab');
  const className = id;
  const { className: parentClass, connectorIndex: j, id: parentId, portIndex: i } = props;
  const { values } = useFormikContext<typeof initialValues>();
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledAccordionDetails id={parentId} className={customClass}>
      {values?.tags?.edges?.map((tag, k, tags) => (
        <Fragment key={tag.altId}>
          <Link className={className} id={`${id}-${i}-${j}-connector-tag-${k}`} to={`/tags/${tag.altId}`}>
            {tag.tagName}
          </Link>

          {k !== tags.length - 1 && ', '}
        </Fragment>
      ))}
    </StyledAccordionDetails>
  );
};
