import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { GreenChargingLeafIcon, EnvironmentIcon } from 'src/components/svgs';
import { MetricCardGroup, MetricCard, MetricCardPlaceholder } from 'src/components/shared/MetricCard';

import { useTotalConsumption, ghgAvoided, galOfGasAvoided } from './utils';

const id = 'dashboard-environmental-stats';

export function EnvironmentalStatsMetrics() {
  const { loading, totalConsumption } = useTotalConsumption();

  return (
    <Box mt={4}>
      <MetricCardGroup id="environmetal-stats">
        <Box pt={4} pl={4}>
          <Typography color="textSecondary" variant="body2" data-testid="environmental-stats-text">
            Environmental Stats
          </Typography>
        </Box>

        {loading ? (
          <>
            <MetricCardPlaceholder data-testid={`${id}-gas-avoided-loading`} />
            <MetricCardPlaceholder data-testid={`${id}-ghg-avoided-loading`} />
          </>
        ) : (
          <>
            <MetricCard
              icon={<GreenChargingLeafIcon />}
              primaryText={galOfGasAvoided(totalConsumption)}
              secondaryText="Gallons"
              label="Gas Avoided Last 30 Days"
              data-testid={`${id}-gas-avoided`}
            />

            <MetricCard
              icon={<EnvironmentIcon />}
              primaryText={ghgAvoided(totalConsumption)}
              secondaryText="Metric Tons"
              label="GHG Avoided Last 30 Days"
              data-testid={`${id}-ghg-avoided`}
            />
          </>
        )}
      </MetricCardGroup>
    </Box>
  );
}
