import { gql } from '@apollo/client';

export const updateAccountVehicle = gql`
  mutation updateAccountVehicle($accountVehicleInput: UpdateAccountVehicleInput) {
    updateAccountVehicle(input: $accountVehicleInput) {
      altId
      vehicle {
        altId
        makeModelYear
      }
      nickName
      vin
    }
  }
`;
