import { theme } from '@evgo/react-material-components';
import { Table } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled(Table)`
  td,
  th {
    padding: ${`${spacing(2.25)}px ${spacing()}px`};

    &.checkbox {
      > span {
        padding: 0;
      }
    }

    &:first-child {
      padding-left: ${spacing(2)}px;
    }

    &:last-child {
      padding-right: ${spacing(2)}px;
    }
  }

  td {
    font-size: 12px;
    a {
      color: ${config.colors.accentBlue};
    }
  }

  th {
    &.checkbox {
      width: 1%;
    }

    &.name {
      width: 20%;
    }

    &.cid {
      width: 10%;
    }

    &.model {
      width: 10%;
    }

    &.status {
      width: 10%;
    }

    &.address {
      width: 25%;
    }

    &.property-name {
      width: 10%;
    }
  }
`;
