import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

interface Props {
  editing: boolean;
}

const { palette, spacing } = theme;
const color = palette.text.secondary;

export const Styled = styled.div<Props>`
  .connector-opts {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
  .row {
    padding: ${spacing(2)}px;
    width: 100%;

    .row-header {
      display: flex;
      width: 100%;

      button {
        justify-self: end;
      }
    }
  }
  .form-control {
    label {
      ${(editing) => !editing && `color: ${color};`}
      &.error {
        color: ${palette.error.main};
      }
    }
    > div {
      ${(editing) => !editing && 'border-bottom: 1px solid rgba(0, 0, 0, 0.42);'}
      > input {
        padding-bottom: 9px;
      }
      &.error {
        border-bottom: 2px solid ${palette.error.main};
        > input {
          padding-bottom: 8px;
        }
      }
    }
  }
  button {
    margin-top: 16px;
  }
`;
