import { FieldStationStatus, ChargerLevel, ChargingLevel, TariffSyncStatus } from 'src/@types';
import { ListChargersV2Charger } from 'src/@types/shared';
import { ConnectorFactory } from './connector';
import { SiteFactory } from './site';

export const ChargerFactory = () => {
  return {
    altId: 'some-alt-id',
    chargerName: 'Charger 1',
    chargerModel: {
      altId: 'some-charger-model-alt-id',
      modelName: 'Charger Model Name',
      chargerLevel: ChargerLevel.L2,
    },
    evses: {
      edges: [
        {
          connectors: ConnectorFactory(),
        },
      ],
    },
    nonNetworked: false,
    showInThirdPartyFilters: false,
    simultaneousChargingEnabled: false,
    smartChargingEnabled: false,
    teslaAdapter: false,
    validateMeterSignature: false,
    fieldStationStatus: FieldStationStatus.Connected,
    site: SiteFactory(),
    cabinets: [],
    displayTariffSynced: true,
    setTariffSynced: true,
    tariffSyncStatus: TariffSyncStatus.Synced,
  };
};

export const ListChargersV2ChargerFactory = (): NonNullable<ListChargersV2Charger> => {
  return {
    altId: 'aa1d7e7f-a9be-4992-bcbb-91e22c3bca3a',
    evses: [
      {
        connectors: [
          {
            reservable: false,
            chargingLevel: ChargingLevel.Dcfc,
            connectorStatus: {
              columnText: 'Unknown',
            },
          },
        ],
      },
      {
        connectors: [
          {
            reservable: false,
            chargingLevel: ChargingLevel.Dcfc,
            connectorStatus: {
              columnText: 'Unknown',
            },
          },
        ],
      },
    ],
    chargerName: 'CBMSGTEST1',
    chargerModel: {
      modelName: 'Delta HP 350 KW CCS CCS',
    },
    site: {
      address1: '100 W 14th Avenue Pkwy',
      address2: '',
      postalCode: '80204',
      administrativeArea: 'CO',
      siteName: 'EXT-Site-Denver',
      locality: 'Denver',
      country: 'United States',
    },
  };
};

export const ListChargersV2Factory = () => {
  return {
    total: 1,
    edges: [ListChargersV2ChargerFactory()],
  };
};
