/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { Checkbox, Input, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { CheckBoxOutlineBlank, LocationOn } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';
import React, { useState } from 'react';
import { LabelValue, ModelOptions } from 'src/@types/shared';
import { getModelOptions } from '../../../../../apollo/queries/models';
import { Styled } from './styles';

export interface Props {
  onFilterChange: (fetchMore: any, metadata: any, f: string[], n: string) => void;
  fetchMore: any;
  metadata: any;
  className?: string;
}

/**
 * Returns a string value of selected manufacturers.
 */
export const checkSelected = (selected: string[], manufacturers: { enumValues: LabelValue<string>[] }): string => {
  const selectedToRender = [] as string[];
  selected.forEach((value) => {
    selectedToRender.push(manufacturers.enumValues.find((option) => option.value === value)?.label || '');
  });

  if (!selected.length) {
    return 'Manufacturers: All';
  }

  return `Manufacturers: ${selectedToRender.join(', ')}`;
};

/**
 * Renders component.
 */
export const ChargerModelsListFilters: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerModelsListFilters');
  const className = id;

  const { onFilterChange, fetchMore, metadata } = props;

  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>([]);
  const { data, loading } = useQuery<ModelOptions>(getModelOptions);
  const { manufacturers } = data || ({ manufacturers: { enumValues: [] } } as ModelOptions);
  const listmanufacturers =
    !loading && !_.isEmpty(manufacturers)
      ? manufacturers.enumValues.map((manufacturer, i) => (
          <MenuItem key={manufacturer.label} value={manufacturer.value} disableRipple className={`${className}`}>
            <Checkbox
              checked={selectedManufacturers.indexOf(manufacturer.value) > -1}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              disableRipple
              inputProps={{ id: `${id}-${i}-manufacturer-checkbox` }}
              icon={<CheckBoxOutlineBlank fontSize="small" />}
            />
            <ListItemText primary={manufacturer.label} style={{ fontSize: 'small' }} />
          </MenuItem>
        ))
      : '';

  const clearAll = selectedManufacturers.length > 0 && (
    <Typography
      className={`${className} clear-all`}
      variant="caption"
      color="secondary"
      onClick={() => {
        setSelectedManufacturers([]);
        props.onFilterChange(props.fetchMore, props.metadata, [], 'manufacturer');
      }}
    >
      Clear
    </Typography>
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 350,
      },
    },
  };

  return (
    <Styled className={`${className} menu`}>
      <LocationOn fontSize="small" />
      <Select
        className={`${className} select`}
        displayEmpty
        input={<Input id="select-multiple-checkbox" />}
        MenuProps={MenuProps}
        multiple
        onChange={({ target }: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
          setSelectedManufacturers(target.value as unknown as string[]);
          onFilterChange(fetchMore, metadata, target.value as unknown as string[], 'manufacturer');
        }}
        renderValue={(selected) => checkSelected(selected as string[], manufacturers)}
        value={selectedManufacturers}
      >
        {listmanufacturers}
      </Select>
      {clearAll}
    </Styled>
  );
};
