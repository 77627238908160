import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;

export const Styled = styled(Accordion)`
  margin-top: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  > header {
    padding: ${`0 ${spacing(3)}px`};
    h2 {
      color: ${config.colors.accentBlue};
    }
  }

  div.details {
    display: flex;
    flex-wrap: wrap;
    padding: ${`${spacing(3)}px ${spacing(2)}px ${spacing() / 2}px`};

    > header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: ${`0 ${spacing()}px`};
    }

    > section {
      width: 100%;
      margin: ${`0 0 ${spacing() * 3}px 0`};
    }

    > div {
      flex-basis: 20%;
      box-sizing: border-box;
      padding: ${`0 ${spacing()}px`};

      label {
        transform: translate(calc(12px + ${spacing()}px), 20px) scale(1);

        &.prefilled,
        &[data-shrink='true'] {
          transform: translate(${spacing(2)}px, 10px) scale(0.75);
        }
      }

      &.half {
        flex-basis: 50%;
      }

      &.sixth {
        flex-basis: calc(100% / 6);
      }
    }

    > label {
      flex-basis: 40%;
      box-sizing: border-box;
      margin: ${`0 ${spacing(1.75)}px ${spacing(2.5)}px -${spacing(1.75)}px`};
      padding: ${`0 ${spacing()}px`};
    }
  }
`;
