import { Formik } from 'formik';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Driver } from 'src/@types';
import { ServerErrors } from 'src/@types/shared';
import { TransformErrorOptions, useFormikSubmit } from 'src/lib/hooks';
import { createDriver, updateDriver } from '../../../../apollo/queries/drivers';
import { AccountDriverModalForm, getInitialValues } from './AccountDriverModalForm';
import { validations as validationSchema } from './AccountDriverModalForm/validations';

export interface Props {
  driver?: Driver;
  open?: boolean;
  className?: string;
  onSaved?: () => void;
}

export const AccountDriverModal: React.FC<Props> = (props) => {
  const className = 'accountdrivermodal-component';
  const params = useParams();
  const { driver } = props;

  const omitFields = ['driverStatus'];
  const transformValues = (values: Driver) => ({
    ..._.omit(values, omitFields),
    ...(!driver
      ? { accountId: params.altId }
      : { altId: driver.altId || '', driverStatusId: Number(values?.driverStatus?.id) }),
  });

  const transformError = ({ error, setFieldError }: TransformErrorOptions) => {
    let snackMessage = error.message || 'A problem has occurred. Please try again.';

    error.graphQLErrors?.forEach((graphQLError: ServerErrors) => {
      const fields = graphQLError.extensions.exception.fields || [];

      if (graphQLError.message && !/Mutation.createDriver|Mutation.updateDriver/.test(graphQLError.message)) {
        snackMessage = graphQLError.message;
        if (graphQLError.message === 'The user with the provided phone number already exists.') {
          snackMessage = 'Please correct the errors and try again.';
          setFieldError('phone', 'A user with the provided phone number already exists.');
        }
      }

      if (!_.isEmpty(fields)) {
        fields.forEach(({ message, field }) => {
          snackMessage = 'Please correct the errors and try again.';
          const fieldName = field === 'driver_email' ? 'email' : _.camelCase(field);
          message = field === 'driver_email' ? 'A user with the provided email address already exists.' : message;
          setFieldError(fieldName, message);
        });
      }

      graphQLError.errorMessage?.forEach(({ message, path }) => {
        snackMessage = 'Please correct the errors and try again.';
        setFieldError(_.camelCase(path), message);
      });
    });

    return snackMessage;
  };

  const handleSubmit = useFormikSubmit('Driver', createDriver, updateDriver, {
    noRedirect: true,
    altId: driver?.altId || '',
    transformValues,
    callback: () => props?.onSaved?.(),
    transformError,
  });

  const initialValues = useMemo(() => getInitialValues(props.driver), [props.driver]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AccountDriverModalForm className={className} open={props.open} driver={props.driver} />
    </Formik>
  );
};
