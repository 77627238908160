import { theme } from '@evgo/react-material-components';
import { Box, Tab, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useResetChargerForExtendMutation,
  useGetChargerExtendQuery,
  ResetChargerForExtendMutationFn,
} from 'src/@types';
import { getCharger as GET_CHARGER_QUERY } from 'src/apollo/queries/extendPlus';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { Card } from 'src/components/shared/Card';
import { KebabMenu, MenuItem } from 'src/components/shared/KebabMenu';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { StyledTabs } from 'src/components/shared/StyledTabs';
import { useConfirmationDialog, useSnackbar } from 'src/lib/hooks';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';
import { Title } from '../../../../shared/Title';
import Profile from './Profile';
import Transactions from './Transactions';
import ActiveSessions from './ActiveSessions';
import ChargerV2SyncStatus from 'src/components/shared/ChargerSyncStatus';
import { ListChargersV2Charger } from 'src/@types/shared';

enum TabValue {
  profile = 'profile',
  transactions = 'transactions',
}

type Params = {
  altId: string;
  tab: TabValue;
};

export const Styled = styled(Box)`
  .header-wrapper {
    display: flex;
    align-items: center;
  }
`;

const onResetCharger = (
  confirmationDialog: ReturnType<typeof useConfirmationDialog>,
  track: (eventName: string) => void,
  resetCharger: ResetChargerForExtendMutationFn,
  altId: string,
) => {
  confirmationDialog.open({
    title: 'Reset Charger?',
    body: 'Are you sure you would like to reset this charger? Any ongoing sessions will be terminated.',
    body2: () => {
      return (
        <div
          style={{
            padding: `${theme.spacing()}px`,
            display: 'flex',
            background: '#ececec',
            margin: `${theme.spacing(3)}px auto 0`,
          }}
        >
          <Warning />
          <Typography style={{ marginLeft: `${theme.spacing()}px` }}>
            If the charger is still not working after resetting, please contact your EVgo support manager.
          </Typography>
        </div>
      );
    },
    buttonConfirmText: 'Confirm',
    callback: () => {
      resetCharger({
        variables: { chargerInput: { chargerId: altId } },
      });
      track(events.chargerView.START_CHARGER_RESET);
    },
  });
};

export const ChargersDetailsView = () => {
  const id = 'charger-details-view';
  const navigate = useNavigate();
  const { altId, tab = TabValue.profile } = useParams<Params>();
  const confirmationDialog = useConfirmationDialog();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newTab: string) => {
    navigate(`/extend-plus/chargers/${altId}/${newTab}`, { replace: true });
  };

  const { refetch, data, loading, error } = useGetChargerExtendQuery({
    skip: !altId,
    variables: { chargerInput: { altId: altId || '' } },
    pollInterval: 30000,
  });

  const [resetCharger] = useResetChargerForExtendMutation({
    refetchQueries: () => [
      {
        query: GET_CHARGER_QUERY,
        variables: { chargerInput: { altId } },
      },
    ],
    onCompleted() {
      snackbar.success(
        `${data?.getCharger?.chargerName} charger has been reset. It may take a few seconds for the connector statuses to update accordingly.`,
      );
    },
    onError() {
      snackbar.error('Something went wrong, please try again.');
    },
  });

  if (!altId || error) {
    return (
      <Styled>
        <Typography>Something went wrong!</Typography>
      </Styled>
    );
  }

  return (
    <PageContent
      loading={loading}
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbLink data-testid={`${id}-home-link`} to="/extend-plus/sites">
            Sites
          </BreadcrumbLink>
          <BreadcrumbLink data-testid={`${id}-edit-link`} to={`/extend-plus/sites/${data?.getCharger?.site?.altId}`}>
            {data?.getCharger?.site?.siteName}
          </BreadcrumbLink>
          <BreadcrumbText>{data?.getCharger?.chargerName}</BreadcrumbText>
        </Breadcrumbs>
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <Styled>
              <div className="header-wrapper">
                <Title>{data?.getCharger?.chargerName}</Title>
                <KebabMenu>
                  {({ close }) => (
                    <>
                      <MenuItem
                        data-testid="reset-charger"
                        onClick={() => {
                          onResetCharger(confirmationDialog, track, resetCharger, altId);
                          track('Clicked Charger Reset Link');
                          close();
                        }}
                      >
                        Reset Charger
                      </MenuItem>
                    </>
                  )}
                </KebabMenu>
                <ChargerV2SyncStatus data={data?.getCharger as NonNullable<ListChargersV2Charger>} />
              </div>
            </Styled>
          }
        />
      }
    >
      <Card py={0} px={0} mb={4}>
        <StyledTabs tab={tab} handleChangeTab={handleChangeTab}>
          <Tab
            label="Profile"
            value={TabValue.profile}
            data-testid="tab-button-profile"
            onClick={() => track(events.chargerView.CLICKED_PROFILE_TAB)}
          />
          <Tab
            label="Transactions"
            value={TabValue.transactions}
            data-testid="tab-button-sessions"
            onClick={() => track(events.chargerView.CLICKED_SESSIONS_TAB)}
          />
        </StyledTabs>
      </Card>

      <div>
        {TabValue.profile === tab && data?.getCharger && data?.getCharger?.evses && (
          <Profile refetch={refetch} charger={data?.getCharger} evses={data?.getCharger?.evses} />
        )}
        {TabValue.transactions === tab && (
          <Fragment>
            <ActiveSessions chargerId={altId} />
            <Transactions chargerId={altId} />
          </Fragment>
        )}
      </div>
    </PageContent>
  );
};
