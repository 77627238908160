import { gql } from '@apollo/client';

export const updateExtendUserHosts = gql`
  mutation updateExtendUserHosts($extenduserInput: UpdateExtendUserHostsInput) {
    updateExtendUserHosts(input: $extenduserInput) {
      altId
      hosts {
        edges {
          hostName
          hid
        }
      }
    }
  }
`;
