import { OperationVariables, QueryLazyOptions, QueryResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Step, StepLabel, Stepper } from '@material-ui/core';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Host, Query, SalesforceHostContactsWithMeta, SalesforcePropertiesWithMeta } from '../../../../@types';
import { HostError, Snackbar } from '../../../../@types/shared';
import { createContact } from '../../../../apollo/queries/contacts';
import {
  getHostByName,
  getSalesforceHostByHid,
  importHost,
  listSalesforceHostContacts,
  listSalesforceProperties,
} from '../../../../apollo/queries/hosts';
import { importProperty } from '../../../../apollo/queries/properties';
import {
  transformContactValues,
  transformHostValues,
  transformPropertyValues,
  updateQuery,
} from '../../../../lib/helpers';
import { CreateHostConfirmation } from './CreateHostConfirmation';
import { FindHostStep } from './FindHostStep';
import { ContactOptions, SalesforceHostWithInfo, SelectContactStep } from './SelectContactStep';
import { PropertyOptions, SalesforcePropertyWithInfo, SelectPropertiesStep } from './SelectPropertiesStep';
import { StyledModal } from '../../../shared/StyledModal';
import { Styled } from './styles';
import { ValidateHostStep } from './ValidateHostStep';
import clsx from 'clsx';
import { useSnackbar } from 'src/lib/hooks';
import { useModalContext, ModalState } from 'src/contexts/ModalContext';

const steps = ['Find a Host', 'Validate Host Information', 'Select Properties', 'Choose Contacts', 'Confirm'];

export const onCloseModal =
  (
    setModalState: (state: ModalState) => void,
    findSalesforceHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void,
    findLocalHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void,
    setValues: (values: Values) => void,
    setHost: (host: HostError) => void,
  ) =>
  (): void => {
    setValues({});
    setModalState({
      modalName: 'CreateHostModal',
      modalVisible: false,
      modalProps: {},
    });
    setHost({ error: null });
    findSalesforceHost({
      variables: {
        input: {
          hid: '',
        },
      },
    });
    findLocalHost({
      variables: {
        input: {
          hid: '',
        },
      },
    });
  };

export const onFindHost =
  (
    findSalesforceHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void,
    findLocalHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void,
    inputRef: React.RefObject<HTMLInputElement>,
  ): (() => void) =>
  () => {
    findSalesforceHost({
      variables: {
        input: {
          hid: _.toUpper(inputRef?.current?.value),
        },
      },
    });
    findLocalHost({
      variables: {
        input: {
          hid: _.toUpper(inputRef?.current?.value),
        },
      },
    });
  };

type OnChangePageReturn = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;

export const onChangePage =
  (
    fetchMore: QueryResult<never, never>['fetchMore'],
    metadata: Omit<SalesforceHostContactsWithMeta, 'edges'> | Omit<SalesforcePropertiesWithMeta, 'edges'>,
    type: string,
    queryData: Query,
  ): OnChangePageReturn =>
  (_event, page) => {
    const { pageSize } = metadata;
    return fetchMore({
      updateQuery,
      variables: {
        [type]: {
          page,
          pageSize,
          filter: {
            hid: {
              iLike: _.get(queryData, 'getSalesforceHost.hid'),
            },
          },
        },
      },
    });
  };

export interface Props {
  open: boolean;
  className?: string;
}

type Values = SalesforcePropertyWithInfo | SalesforceHostWithInfo | Host;

export const CreateHostModal: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const id = _.kebabCase('CreateHostModal');
  const className = id;
  const inputRef = useRef(null);
  const snackbar = {} as Snackbar;

  const [values, setValues] = useState<Values>({});
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedProperties, setSelectedProperties] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [host, setHost] = useState<HostError>({ error: null });
  const [hostFormIsValid, setHostFormIsValid] = useState(false);
  const snackbarUi = useSnackbar();
  const { setModalState } = useModalContext();

  const [findSalesforceHost, { data: queryData, loading: isLoadedLazyQuery }] = useLazyQuery<Query>(
    getSalesforceHostByHid,
    {
      fetchPolicy: 'cache-and-network', // onComplete evaluates cache with default option. gotta evalaute both cache and network to reliably call.
      onCompleted: (res) => {
        setValues((res?.getSalesforceHost || {}) as never);
      },
      onError: (err) => {
        setHost({
          error: _.get(err, 'graphQLErrors[0].extensions.exception.errors[0]'),
        });
      },
    },
  );

  const [findLocalHost, { data }] = useLazyQuery<Query>(getHostByName, {
    fetchPolicy: 'cache-and-network', // onComplete evaluates cache with default option. gotta evalaute both cache and network to reliably call.
    onError: (err) => {
      setHost({
        error: _.get(err, 'graphQLErrors[0].extensions.exception.errors[0]'),
      });
    },
  });

  const contactsList = useQuery<Query>(listSalesforceHostContacts, {
    fetchPolicy: 'network-only',
    skip: !_.get(queryData, 'getSalesforceHost.hid'),
    variables: {
      input: {
        page: 0,
        pageSize: 5,
        filter: {
          hid: {
            iLike: _.get(queryData, 'getSalesforceHost.hid'),
          },
        },
      },
    },
  });

  let contactOptions = [] as Array<ContactOptions | undefined> | undefined;
  let contactsMetaData = {};
  let contactsFetchMore: QueryResult<never, never>['fetchMore'] = null as never;
  if (!_.isEmpty(_.get(contactsList, 'data.listSalesforceHostContacts'))) {
    contactsFetchMore = _.get(contactsList, 'fetchMore');
    contactsMetaData = _.omit(_.get(contactsList, 'data.listSalesforceHostContacts'), 'edges');
    contactOptions = contactsList?.data?.listSalesforceHostContacts?.edges?.map((contact) => {
      if (!contact) {
        return undefined;
      }

      const { contactName, phone, email, workTitle: title } = contact;
      if (_.isEmpty(contactName) || _.isEmpty(email)) {
        return { contactName, phone, email, title, disabled: true } as ContactOptions;
      }
      return { contactName, phone, email, title } as ContactOptions;
    });
  }

  const propertiesList = useQuery<Query>(listSalesforceProperties, {
    fetchPolicy: 'network-only',
    skip: !_.get(queryData, 'getSalesforceHost.hid'),
    variables: {
      input: {
        page: 0,
        pageSize: 5,
        filter: {
          hid: {
            iLike: _.get(queryData, 'getSalesforceHost.hid'),
          },
        },
      },
    },
  });

  let propertyOptions = [] as Array<PropertyOptions | undefined> | undefined;
  let propertiesMetaData = {};
  let propertiesFetchMore: QueryResult<never, never>['fetchMore'] = null as never;
  if (!_.isEmpty(_.get(propertiesList, 'data.listSalesforceProperties'))) {
    propertiesFetchMore = _.get(propertiesList, 'fetchMore');
    propertiesMetaData = _.omit(_.get(propertiesList, 'data.listSalesforceProperties'), 'edges');
    propertyOptions = propertiesList?.data?.listSalesforceProperties?.edges?.map((property) => {
      if (!property) {
        return undefined;
      }

      const { address1, address2, locality, administrativeArea, postalCode, pid, propertyName } = property;
      if (
        _.isEmpty(address1) ||
        _.isEmpty(locality) ||
        _.isEmpty(administrativeArea) ||
        _.isEmpty(postalCode) ||
        _.isEmpty(pid) ||
        _.isEmpty(propertyName)
      ) {
        return {
          address1,
          address2,
          locality,
          administrativeArea,
          postalCode,
          pid,
          propertyName,
          disabled: true,
        } as PropertyOptions;
      }
      return { address1, address2, locality, administrativeArea, postalCode, pid, propertyName } as PropertyOptions;
    });
  }

  const onCancelClick = () => {
    onFindHost(findSalesforceHost, findLocalHost, { current: { value: '' } } as never)();
    onCloseModal(setModalState, findSalesforceHost, findLocalHost, setValues, setHost)();
    setActiveStep(0);
    setSelectedProperties([]);
    setSelectedContacts([]);
  };

  const [onImportHost, { loading }] = useMutation(importHost, {
    onCompleted: (result) => {
      setValues({ ...values, ...result.importHost });
      snackbarUi.success('Host Created');
    },
  });

  const [onCreateContact] = useMutation(createContact, {
    onCompleted: (result) => {
      setValues({ ...values, contact: result.createContact } as never);
      snackbarUi.success('Contact Added');
    },
  });

  const [onImportProperty] = useMutation(importProperty);

  if (!!queryData && !_.get(queryData, 'getSalesforceHost') && _.get(inputRef, 'current.value') && !isLoadedLazyQuery) {
    snackbar.message = 'That Host ID was not found in Salesforce.';
  }

  const modalError = host.error;
  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;
  if (modalError) {
    if (modalError.type === 'Invalid string') snackbar.message = 'This is not a valid host ID.';
    if (modalError.type === 'creation failed') snackbar.message = 'Unable to add host. Please try again.';
  }

  if (_.get(data, 'getHost.altId')) {
    snackbar.action = (
      <Button
        id={`${id}-host-profile-button`}
        color="secondary"
        component={Link}
        size="small"
        to={`/hosts/${_.get(data, 'getHost.altId', '')}/profile`}
        onClick={() => onCancelClick()}
      >
        Go to Host
      </Button>
    );
    snackbar.message = 'This host has already been added';
  }

  const onUpdate = (newValues: Values) => setValues(newValues);

  const handleNextStep = async () => {
    if (activeStep === 1) {
      const hostInput = transformHostValues(values);
      onImportHost({ variables: { hostInput } });
      setActiveStep(activeStep + 1);
    } else if (activeStep === 2) {
      if (selectedProperties?.length) {
        const propertyErrors = [];
        try {
          const hostId = _.get(values, 'altId');

          await Promise.all(
            selectedProperties.map((property: Record<string, unknown>) => {
              const propertyInput = transformPropertyValues({ hostId, ...property } as never);
              return onImportProperty({ variables: { propertyInput } });
            }),
          );
        } catch (error) {
          propertyErrors.push(error);
        } finally {
          if (propertyErrors.length) {
            snackbarUi.error(_.get(_.head(propertyErrors), 'graphQLErrors[0].description'));
          } else {
            snackbarUi.success(
              `${selectedProperties.length > 1 ? `${selectedProperties.length} Properties` : 'Property'} Created`,
            );
            setActiveStep(activeStep + 1);
          }
        }
      }
      setActiveStep(activeStep + 1);
    } else if (activeStep === 3) {
      if (selectedContacts.length) {
        const contactErrors = [];

        try {
          await Promise.all(
            selectedContacts.map((contact: Record<string, unknown>) => {
              const contactInput = transformContactValues({ altId: (values as Host).altId, ...contact } as never);
              return onCreateContact({ variables: { contactInput } });
            }),
          );
        } catch (error) {
          contactErrors.push(error);
        } finally {
          if (contactErrors.length) {
            snackbarUi.error(_.get(_.head(contactErrors), 'graphQLErrors[0].description'));
          } else {
            snackbarUi.success(
              `${selectedContacts.length > 1 ? `${selectedContacts.length} Contacts` : 'Contact'} Created`,
            );
            setActiveStep(activeStep + 1);
          }
        }
      }
      setActiveStep(activeStep + 1);
    } else if (activeStep === 4) {
      navigate(`/hosts/${_.get(values, 'altId')}/profile`);
      onCancelClick();
    } else {
      setActiveStep(activeStep + 1);
      if (_.isEmpty(values)) onUpdate(queryData?.getSalesforceHost as never);
    }
  };

  const shouldNextButtonDisabled = () => {
    if (activeStep === 0) {
      if (
        !!_.get(queryData, 'getSalesforceHost.host') ||
        Boolean(loading) ||
        Boolean(isLoadedLazyQuery) ||
        Boolean(host.error) ||
        Boolean(snackbar.message) ||
        !_.get(inputRef, 'current.value')
      ) {
        return true;
      }
      return false;
    }

    if (activeStep === 1) {
      if (hostFormIsValid) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <StyledModal
      className={clsx(customClass, 'modal')}
      onClose={() => onCancelClick()}
      open={props.open}
      primaryButtonText={activeStep === 4 ? 'DONE' : 'NEXT'}
      onClick={handleNextStep}
      primaryButtonDisabled={shouldNextButtonDisabled()}
      secondaryButtonText="Cancel"
      secondaryButtonClick={() => onCancelClick()}
      title={steps[activeStep]}
      tertiaryButtonText="Back"
      tertiaryButtonClick={() => {
        if (activeStep === 1) {
          onFindHost(findSalesforceHost, findLocalHost, { current: { value: '' } } as never)();
        }
        setActiveStep(activeStep - 1);
      }}
      tertiaryButtonDisabled={activeStep === 0}
    >
      <Styled className={className}>
        <div className={className}>
          <div className={clsx(className, 'steps')}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={clsx(className, 'stepper')}
              id={`${id}-stepper`}
            >
              {steps.map((label, step) => (
                <Step
                  key={label}
                  className={`${className} ${step > activeStep ? '' : 'active'}`}
                  id={`${id}-step-label-${label}`}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          {activeStep === 0 && (
            <FindHostStep
              host={host}
              setHost={setHost}
              inputRef={inputRef}
              snackbar={snackbar}
              onFindHost={onFindHost}
              findSalesforceHost={findSalesforceHost}
              findLocalHost={findLocalHost}
              queryData={queryData as Query}
            />
          )}

          {activeStep === 1 && (
            <ValidateHostStep
              queryData={queryData as Query}
              isLoadedLazyQuery={isLoadedLazyQuery}
              setHostFormIsValid={setHostFormIsValid}
              setValues={setValues}
            />
          )}

          {activeStep === 2 && (
            <SelectPropertiesStep
              propertyOptions={propertyOptions}
              selectedProperties={selectedProperties}
              setSelectedProperties={setSelectedProperties}
              values={values as SalesforcePropertyWithInfo}
              setValues={setValues}
              propertiesMetaData={propertiesMetaData}
              onChangePage={onChangePage}
              propertiesFetchMore={propertiesFetchMore}
              queryData={queryData as Query}
            />
          )}

          {activeStep === 3 && (
            <SelectContactStep
              contactsFetchMore={contactsFetchMore}
              contactOptions={contactOptions}
              contactsMetaData={contactsMetaData}
              onChangePage={onChangePage}
              setSelectedContacts={setSelectedContacts}
              selectedContacts={selectedContacts}
              values={values as SalesforceHostWithInfo}
              setValues={setValues}
              queryData={queryData as Query}
            />
          )}

          {activeStep === 4 && (
            <CreateHostConfirmation
              selectedProperties={selectedProperties as never}
              selectedContacts={selectedContacts}
              values={values as Host}
            />
          )}
        </div>
      </Styled>
    </StyledModal>
  );
};
