import { useMutation, useSubscription, useQuery, MutationTuple, OperationVariables } from '@apollo/client';
import { Divider, Dropdown, Accordion, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Host, HostStatus, Query } from 'src/@types';
import { useConfirmationDialog } from 'src/lib/hooks';
import { createdHost, getHostById, updatedHost, updateHost } from '../../../../apollo/queries/hosts';
import { Footer } from '../../../shared/Footer';
import { Styled } from './styles';

export interface Props {
  expanded?: boolean;
  onChange?: (event: never, expanded: boolean) => void;
  className?: string;
  altId?: string;
}

/**
 * Updates host status
 */
export const onStatusSelect =
  (
    confirmationDialog: ReturnType<typeof useConfirmationDialog>,
    updateHostStatus: MutationTuple<HostStatus, OperationVariables>[0],
    selected: Host,
  ) =>
  (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (_.lowerCase(event.target.value) !== _.lowerCase(selected?.status as string)) {
      confirmationDialog.open({
        title: 'Host Status Change',
        body: 'Are you sure you want to change the status of this host?',
        callback: () =>
          updateHostStatus({
            variables: { hostInput: { altId: selected.altId, status: _.toUpper(event.target.value) } },
          }),
      });
    }
  };

/**
 * Hosts Profile component
 */
export const HostProfile: React.FC<Props> = (props) => {
  const className = 'HostProfile';
  const { altId } = props;
  const host = useQuery<Query>(getHostById, {
    variables: { hostInput: { altId } },
    skip: !altId,
  });
  const confirmationDialog = useConfirmationDialog();
  const selected = _.get(host, 'data.getHost', {});
  const [lastSynced, setLastSynced] = useState<string>();
  const onSubscriptionData = () => {
    const date = moment(new Date()).format('MM/DD/YY, h:mm:ss A');
    setLastSynced(date);
  };

  useSubscription(updatedHost, {
    variables: { altId },
    onSubscriptionData,
  });
  useSubscription(createdHost, {
    variables: { altId },
    onSubscriptionData,
  });
  const [updateHostStatus] = useMutation(updateHost, {
    refetchQueries: () => [
      {
        query: getHostById,
        variables: { hostInput: { altId } },
      },
    ],
  });

  const address1 = _.get(selected, 'address1', '');
  const address2 = _.get(selected, 'address2', '');
  const data = {
    address1: address1 && address1.length ? address1.replace(/(\r\n|\n|\r)/g, ' ') : '', // remove new line symbols like "↵"
    address2: address2 && address2.length ? address2.replace(/(\r\n|\n|\r)/g, ' ') : '', // remove new line symbols like "↵"
    city: _.get(selected, 'locality', ''),
    state: _.get(selected, 'administrativeArea', ''),
    country: _.get(selected, 'country', ''),
    status: _.toLower(_.get(selected, 'status', 'active')),
    hid: _.get(selected, 'hid', ''),
    hostName: _.get(selected, 'hostName', ''),
    postalCode: _.get(selected, 'postalCode', ''),
    contactName: _.get(selected, 'contacts.edges[0].contactName', ''),
    contactEmail: _.get(selected, 'contacts.edges[0].email', ''),
  };
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];
  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  const statusOption = statusOptions.find((element) => element.value === data.status);

  return (
    <Styled>
      <Accordion className={customClass} expanded={props.expanded} onChange={props.onChange}>
        <AccordionSummary expandIcon={<ExpandMore />} component="header" className={className}>
          <Typography className={className} variant="h6" component="h2">
            Host Profile
          </Typography>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={`${className} panel-details`}>
          <div className={className}>
            <div className={className}>
              <Typography className={className} variant="subtitle1" component="h3">
                Account Information
              </Typography>

              <TextInfo
                className={`${className} disabled`}
                disabled
                id="hostName"
                label="Host Name"
                labelProps={{ className }}
                value={data.hostName}
              />

              <Dropdown
                className={`${className} half`}
                displayValue={statusOption?.label || ''}
                id="customer-market"
                label="Host Status"
                labelProps={{ className }}
                menuProps={{ className }}
                onChange={onStatusSelect(confirmationDialog, updateHostStatus, selected)}
                options={statusOptions}
                value={data.status}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="hostID"
                label="Host ID"
                labelProps={{ className }}
                value={data.hid}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="contactName"
                label="Contact Name"
                labelProps={{ className }}
                value={data.contactName}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="contactEmail"
                label="Contact Email"
                labelProps={{ className }}
                value={data.contactEmail}
              />
            </div>

            <Divider vertical />

            <div className={className}>
              <Typography className={className} variant="subtitle1" component="h3">
                Host Address
              </Typography>

              <TextInfo
                className={`${className} disabled`}
                disabled
                id="address1"
                label="Address 1"
                labelProps={{ className }}
                value={data.address1}
              />

              <TextInfo
                className={`${className} disabled`}
                disabled
                id="address2"
                label="Address 2"
                labelProps={{ className }}
                value={data.address2}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="city"
                label="City"
                labelProps={{ className }}
                value={data.city}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="State"
                label="State"
                labelProps={{ className }}
                value={data.state}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="zipCode"
                label="Zip Code"
                labelProps={{ className }}
                value={data.postalCode}
              />

              <TextInfo
                className={`${className} half disabled`}
                disabled
                id="country"
                label="Country"
                labelProps={{ className }}
                value={data.country}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Footer
        message={
          lastSynced
            ? `Last Synced: ${moment(lastSynced).format('MM/DD/YY, h:mm:ss A')}`
            : 'This host has not been synced.'
        }
      />
    </Styled>
  );
};
