const config = {
  name: 'Operator Application',
  shortName: 'Operator App',
  description: 'A client application for operators to manage the entire EVgo EV Connect infrastructure.',
  brandColor: '#002B39',
  apiHost: process.env.REACT_APP_API_HOST || '//localhost:4000/',
  basename: process.env.REACT_APP_BASENAME || '/',
  noscript: 'You need to enable JavaScript to run this application.',
  maxPageSize: 9999,
  colors: {
    accentBlue: '#23758D',
    lightBlue: '#D4F0FF',
    accentYellow: '#F8E71C',
    accentOrange: '#F5A623',
    botticelli: '#CAD6E1',
    grey: '#F5F8FA',
    red: '#F20606',
    scienceBlue: '#0073E3',
    sherpaBlue: '#004151',
    white: '#FFFFFF',
    mediumGrey: '#E6E6E6',
  },
  env: {
    api: {
      url: process.env.REACT_APP_API_HOST || '//localhost:4000/',
    },
    app: {
      basename: process.env.REACT_APP_BASENAME || '/',
      port: process.env.PORT || 8080,
      hostPrefixMaxLength: parseInt(process.env.REACT_APP_HOST_PREFIX_LENGTH || '2'),
    },
    firebase: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_PROCESS_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
    google: {
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    },
    reportals: {
      url: process.env.REACT_APP_REPORTALS_URL || '/',
    },
    amplitude: {
      apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY || '',
    },
    unleash: {
      url: process.env.REACT_APP_UNLEASH_URL,
      clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
      refreshInterval: process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL,
      appName: process.env.REACT_APP_UNLEASH_APP_NAME,
    },
    storyblok: {
      accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN || '',
      version: process.env.REACT_APP_STORYBLOK_VERSION,
    },
  },
};

export default config;
