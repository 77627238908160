import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  margin-bottom: ${spacing(4)}px;

  header {
    padding: ${`0 ${spacing(3)}px`};
  }

  h2 {
    color: ${colors.accentBlue};
  }

  .notifications-container {
    flex-wrap: wrap;
  }
`;
