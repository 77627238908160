import { Divider, StatBlock, theme } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React from 'react';
import config from '../../../../config';
import { Styled as StyledPaper } from './styles';

export interface Props {
  className?: string;
}

// TODO: there's hardcoded values

/**
 * Chargers network status component.
 */
export const ChargersNetworkStatus: React.FC<Props> = (props) => {
  const className = 'chargersnetworkstatus-component';
  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={`${className} panel-summary`}>
        <Typography className={className} variant="h6" component="h2">
          Network Status
        </Typography>
        <Typography className={className} variant="caption">
          Last updated 2 hours ago
        </Typography>
      </header>

      <Divider />

      <div className={className}>
        <StatBlock color="green" icon={<ArrowDropUp />} stat="5%" title="Online">
          2,553
        </StatBlock>

        <Divider vertical />

        <StatBlock
          color={theme.palette.error.main}
          icon={<ArrowDropUp />}
          stat="6%"
          subtitle="More than 48hrs"
          title="Offline"
        >
          182
        </StatBlock>

        <Divider vertical />

        <StatBlock
          color={theme.palette.error.main}
          icon={<ArrowDropDown />}
          stat="6%"
          subtitle="Less than 48hrs"
          title="Offline"
        >
          21
        </StatBlock>

        <Divider vertical />

        <StatBlock
          color={config.colors.accentBlue}
          icon={<ArrowDropUp />}
          stat="8%"
          subtitle="More than 48hrs"
          title="Maintenance"
        >
          54
        </StatBlock>

        <Divider vertical />

        <StatBlock
          color={config.colors.accentBlue}
          icon={<ArrowDropDown />}
          stat="1%"
          subtitle="Less than 48hrs"
          title="Maintenance"
        >
          14
        </StatBlock>
      </div>
    </StyledPaper>
  );
};
