import clsx from 'clsx';
import React from 'react';
import { StyledModal } from 'src/components/shared/StyledModal';
import { useModalContext } from 'src/contexts/ModalContext';
interface Props {
  open: boolean;
  className?: string;
}

export const ImportCardsModal: React.FC<Props> = (props) => {
  const className = 'ImportCardsModal';

  const { setModalState } = useModalContext();

  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledModal
      className={clsx(customClass, 'modal')}
      data-testid="import-cards-modal"
      onClose={() =>
        setModalState({
          modalName: '',
          modalVisible: false,
          modalProps: {},
        })
      }
      onClick={() =>
        setModalState({
          modalName: '',
          modalVisible: false,
          modalProps: {},
        })
      }
      open={props.open}
      title="Import Cards"
    />
  );
};
