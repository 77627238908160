import { List, ListItem } from '@material-ui/core';
import { Place } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Site } from 'src/@types';
import { Styled } from './styles';

export interface Props {
  site: Site;
}

/**
 * Charger marker info component.
 */
export const ChargerMarkerInfo: React.FC<Props> = (props) => {
  const className = 'chargermarkerinfo-component';
  const { site } = props;
  const chargers = site?.chargers?.edges;
  const status = 'available';

  if (!site) {
    return (
      <article className={className}>
        <div className={className}>This site could not be found.</div>
      </article>
    );
  }

  return (
    <Styled className={className}>
      <div className={className}>
        <Link className={className} to={`/sites/${props.site.altId}`}>
          <strong>{props.site.siteName}</strong>
        </Link>
      </div>

      <List className={className} dense={true}>
        {chargers?.map((charger, i) => (
          <ListItem key={i} className={className}>
            <Link className={className} to={`/chargers/${charger?.altId}/profile`}>
              {charger?.chargerName}
            </Link>
            <Place color="primary" className={`${className} ${status}/profile`} />
          </ListItem>
        ))}
      </List>
    </Styled>
  );
};
