import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${`${spacing(2.5)}px ${spacing(3.25)}px`};

  p {
    font-weight: 300;
    margin-bottom: ${spacing(2)}px;
  }

  .label {
    font-size: 12px;
    margin-bottom: 16px;
    font-weight: 300;
    padding: ${spacing() / 2}px;
  }

  .confirm-header {
    font-size: ${spacing(1.5)}px;
    margin: ${`${spacing(2)}px 0px`};
    span {
      padding: ${`0px ${spacing(20)}px 0px ${spacing(2.75)}px `};
    }
  }

  .confirm-table {
    max-height: 228px;
    overflow-y: auto;
    table {
      tbody {
        td {
          padding: ${`${spacing() / 2}px ${spacing(3)}px`};
        }
      }
    }
  }
`;
