import { gql } from '@apollo/client';

export const listPlanNames = gql`
  query listPlanNames($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      edges {
        altId
        planName
      }
    }
  }
`;
