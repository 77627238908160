import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import styled from 'styled-components';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { useGetPlanEnrollmentsForExtendQuery } from 'src/@types';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';
import { Card, CardHeader } from 'src/components/shared/Card';
import { splitStringIntoLines } from './helpers';

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

const StyledGraph = styled(Box)`
  height: 400px;
`;

const secondaryTextHex = '#0000008a';
const graphTheme = {
  axis: {
    ticks: {
      text: {
        fill: secondaryTextHex,
      },
    },
  },
};

const testid = (value: string) => ({ 'data-testid': `custom-plan-enrollments-${value}` });

// nivo does not handle wrapping of labels. to prevent overlap on plan name, we limit the length of
// each line here
export const xAxisRenderTick = ({ opacity, textAnchor, textX, textY, x, y, value }: AxisTickProps<string>) => {
  const linesToShow = splitStringIntoLines(value, 16, 2) || [];
  return (
    <g transform={`translate(${x},${y + 10})`} style={{ opacity }}>
      <line stroke={secondaryTextHex} strokeWidth={1} y1={-10} y2={-5} />
      <text
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY})`}
        style={{
          fill: secondaryTextHex,
          fontSize: 11,
        }}
      >
        {linesToShow.map((line, i) => {
          const yPos = i * 15;
          return (
            <tspan x={0} dy={yPos} key={i}>
              {line}
            </tspan>
          );
        })}
      </text>
    </g>
  );
};

export function CustomPlanEnrollments() {
  const { data, loading } = useGetPlanEnrollmentsForExtendQuery({});

  const planEnrollmentData = data?.getPlanEnrollmentsForExtend || [];
  return (
    <StyledCard {...testid('card')}>
      <CardHeader
        title="Custom Plan Enrollments"
        subtitle={
          <Box display="flex" flexDirection="column">
            Total number of customer enrollments by custom plan
          </Box>
        }
      />

      {loading ? (
        <Box display="flex" justifyContent="center" {...testid('loading')}>
          <CircularProgress />
        </Box>
      ) : planEnrollmentData.length === 0 ? (
        <Box
          {...testid('no-data')}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="372px"
          width="100%"
        >
          <Box>
            <Typography variant="subtitle2">No data to display</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <StyledGraph>
            <ResponsiveBar
              theme={graphTheme}
              keys={['totalEnrollments']}
              data={planEnrollmentData as BarDatum[]}
              indexBy="planName"
              isInteractive={false}
              gridYValues={5}
              margin={{ top: 35, bottom: 50, left: 60 }}
              padding={0.5}
              colors={Object.values(extendPlusTheme.palette.categorical)}
              colorBy={'indexValue'}
              enableLabel={false}
              axisBottom={{
                renderTick: xAxisRenderTick,
              }}
              axisLeft={{
                tickPadding: 25,
                tickSize: 0,
                tickValues: 5,
              }}
              ariaLabel="Custom Plan Enrollments"
            />
          </StyledGraph>
        </Box>
      )}
    </StyledCard>
  );
}
