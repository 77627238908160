/* stylelint-disable color-no-hex */
import React from 'react';
import { ValueFormat } from '@nivo/core';
import { ResponsiveBar } from '@nivo/bar';
import clsx from 'clsx';
import styled from 'styled-components';

export type BarChartData = {
  label: string;
  value: number;
};

export type Props = {
  data: BarChartData[];
  captionText?: string;
  verticalCaptionText?: string;
  height?: number;
  color?: string;
  className?: string;
  id?: string;
  'data-testid'?: string;
  valueFormat?: ValueFormat<number>;
};

type StyledProps = {
  height?: number;
  color?: string;
};

const StyledDiv = styled.div<StyledProps>`
  height: ${(props) => props.height}px;

  .label tspan {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: ${(props) => props.color};
  }
`;

export const BarChart: React.FC<Props> = ({
  data,
  color,
  height = 400,
  captionText,
  verticalCaptionText,
  className: parentClass,
  id: parentId,
  valueFormat,
  ...props
}) => {
  const className = 'bar-chart-component';

  const theme = {
    axis: {
      ticks: {
        text: {
          fill: color,
        },
      },
    },
  };

  return (
    <StyledDiv height={height} color={color} className={clsx(className, parentClass)} id={parentId} {...props}>
      <ResponsiveBar
        key={parentId}
        data={data}
        indexBy="label"
        isInteractive={false}
        margin={{ top: 30, bottom: captionText ? 100 : 50, left: 100 }}
        padding={0.5}
        valueScale={{ type: 'linear' }}
        colors={color}
        theme={theme}
        enableLabel={false}
        gridYValues={5}
        axisBottom={{
          tickSize: 5,
          tickPadding: 12,
          tickRotation: 0,
          legend: captionText,
          legendPosition: 'middle',
          legendOffset: 75,
          renderTick: ({ opacity, textAnchor, value, x, y }) => {
            return (
              <g transform={`translate(${x},${y + 16})`} style={{ opacity }}>
                <text textAnchor={textAnchor} className={'label'}>
                  <tspan x={-2} dy={12}>
                    {value.substring(0, 3)}
                  </tspan>
                  <tspan x={-2} dy={18}>
                    {value.substring(3)}
                  </tspan>
                </text>
              </g>
            );
          },
        }}
        valueFormat={valueFormat}
        axisLeft={{
          tickPadding: 36,
          tickSize: 0,
          tickValues: 5,
          format: valueFormat,
          legend: verticalCaptionText,
          legendPosition: 'middle',
          legendOffset: -80,
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [['brighter', 1.6]],
        }}
        ariaLabel="Bar Chart"
      />
    </StyledDiv>
  );
};
