/* stylelint-disable color-no-hex */
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';

export const ChartContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  margin-top: ${theme.spacing(3)}px;
  margin-bottom: ${theme.spacing(3)}px;

  text[text-anchor='end'] {
    font-weight: 900;
  }

  text[text-anchor='start'] {
    font-weight: 900;
    fill: ${theme.palette.primary[600]} !important;
  }

  > div {
    overflow: hidden;

    > span {
      display: flex;
      align-items: center;
      margin-top: ${theme.spacing(3)}px;
      font-size: 12px;
      color: ${theme.palette.text.secondary};

      > div {
        display: flex;
        width: 10px;
        height: 12px;

        &:first-child {
          margin-left: 4px;
        }

        &:last-child {
          margin-right: 4px;
        }

        &.blue1 {
          background-color: ${extendPlusTheme.palette.sequential[200]};
        }

        &.blue2 {
          background-color: ${extendPlusTheme.palette.sequential[300]};
        }

        &.blue3 {
          background-color: ${extendPlusTheme.palette.sequential[400]};
        }

        &.blue4 {
          background-color: ${extendPlusTheme.palette.sequential[500]};
        }

        &.blue5 {
          background-color: ${extendPlusTheme.palette.sequential[600]};
        }

        &.blue6 {
          background-color: ${extendPlusTheme.palette.sequential[700]};
        }

        &.blue7 {
          background-color: ${extendPlusTheme.palette.sequential[800]};
        }

        &.blue8 {
          background-color: ${extendPlusTheme.palette.sequential[900]};
        }
      }
    }
  }
`;
