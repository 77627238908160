import { useQuery } from '@apollo/client';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { listNotificationGroups } from '../../../../../../apollo/queries/notifications';
import { DriverProfileNotificationGroup } from '../../../../../shared/DriverProfileNotificationGroup';
import { Styled as StyledAccordion } from './styles';

/**
 * Driver notifications component
 */
export const DriverNotifications: React.FC = () => {
  const { altId } = useParams<{ altId: string }>();
  const { values } = useFormikContext();
  const className = 'DriverNotifications';

  const { data: { listNotificationGroups: notificationGroups } = {} } = useQuery<Query>(listNotificationGroups, {
    variables: {
      notificationGroupsInput: {
        page: 0,
        pageSize: 999,
        sort: {
          sortOrder: 'ASC',
        },
      },
      notificationItemsInput: {
        page: 0,
        pageSize: 999,
        sort: {
          sortOrder: 'ASC',
        },
      },
    },
  });

  return (
    <StyledAccordion className={className} defaultExpanded={false}>
      <AccordionSummary expandIcon={altId ? <ExpandMoreIcon /> : null} className={className} component="header">
        <Typography className={className} variant="h6" component="h2">
          Notifications
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={`${className} notifications-container`}>
        {!_.get(values, 'notificationPreferences')
          ? null
          : (notificationGroups?.edges || []).map((notificationGroup, i) => (
              <DriverProfileNotificationGroup
                key={i}
                index={i}
                label={notificationGroup?.groupName}
                items={_.get(notificationGroup, 'notificationItems.edges', [])}
              />
            ))}
      </AccordionDetails>
    </StyledAccordion>
  );
};
