import { LabelValue } from 'src/@types/shared';

/**
 * A helper for Dropdown, which tests if all of the userInput words are
 * found in an option.label, in any order, case insensitive.
 * If all of the userInput words are found, it returns true.
 * If not all of them are found, it returns false.
 * This helper method is passed into a React Select filterOption prop
 * which then filters the available options based on the results.
 * @param option - The option being tested to see if it is a match.
 * @param userInput - The input to filter by.
 * @return The validation.
 */
export const fuzzyFilterOption = (option: LabelValue<string>, userInput: string): boolean => {
  const words = userInput.split(' ');

  return words.reduce<boolean>((acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()), true);
};
