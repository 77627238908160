import { useQuery } from '@apollo/client';
import { GetSessionsConsumptionForExtendInput, Query } from 'src/@types';
import { getSessionsConsumptionForExtend } from 'src/apollo/queries/extendPlus';
export function useGetSessionsConsumptionForExtend({
  input,
}: {
  input: Partial<GetSessionsConsumptionForExtendInput>;
}) {
  const variables = {
    input: {
      ...input,
    },
  };
  const { loading, data } = useQuery<Query>(getSessionsConsumptionForExtend, {
    variables,
    fetchPolicy: 'network-only',
  });
  return { data: data?.getSessionsConsumptionForExtend, loading };
}
