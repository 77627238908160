import { useLazyQuery, useQuery } from '@apollo/client';
import { Map } from '@evgo/react-material-components';
import { Divider, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Query, Site } from 'src/@types';
import config from 'src/config';
import { getSite, listSiteOptions } from '../../../../../apollo/queries/sites';
import { ChargerMarkerInfo } from './ChargerMarkerInfo';
import { Styled as StyledModal } from './styles';

export interface Props {
  altId: string;
  open: boolean;
  position: {
    latitude: number;
    longitude: number;
  };
  hideModal: (reason: string) => void;
}

/**
 * Charger Map Modal component
 */
export const ChargerMapModal: React.FC<Props> = (props) => {
  const className = 'ChargerMapModal';
  const { altId } = props;

  /** Local state object containing map information. */
  const [state, setState] = useState({
    radius: 40,
    center: {
      // Los Angeles
      latitude: 34.0522,
      longitude: -118.2437,
    },
  });

  /** Map ref */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapRef = useRef<{ state: { map: { getBounds: () => any; getCenter: () => any } } }>();

  /**
   * Takes latitude and longitude of two locations and returns the radius between them in miles.
   */
  const calcDistance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
    const rad = (num: number) => (Math.PI * num) / 180;
    const theta = lng1 - lng2;
    const radtheta = (Math.PI * theta) / 180;
    const dist =
      Math.sin(rad(lat1)) * Math.sin(rad(lat2)) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.cos(radtheta);

    return Math.floor(((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515) || 1;
  };

  /**
   * This function saves a reference of it to be used to get center and bounds of the map.
   */
  const onMount = () => {
    if (mapRef && mapRef.current) {
      const bounds = mapRef.current.state.map.getBounds();
      const center = {
        latitude: Number(props.position.latitude),
        longitude: Number(props.position.longitude),
      };

      if (bounds) {
        const ne = bounds.getNorthEast();
        const radius = calcDistance(center.latitude, center.longitude, parseFloat(ne.lat()), parseFloat(ne.lng()));

        if (state.radius !== radius) setState({ center, radius });
      }
    }
  };

  /** This function listens for zoom event on the map and updates radius. */
  const onZoom = () => {
    if (mapRef && mapRef.current) {
      const bounds = mapRef.current.state.map.getBounds();
      const center = mapRef.current.state.map.getCenter();

      if (bounds && center) {
        const ne = bounds.getNorthEast();
        const latitude = parseFloat(center.lat());
        const longitude = parseFloat(center.lng());
        const radius = calcDistance(latitude, longitude, parseFloat(ne.lat()), parseFloat(ne.lng()));

        if (state.radius !== radius) {
          setState({ center: { latitude, longitude }, radius });
        }
      }
    }
  };

  /** This function listens for center change events on the map and updates center and chargers. */
  const onDrag = () => {
    if (mapRef && mapRef.current) {
      const center = mapRef.current.state.map.getCenter();

      if (center) {
        const latitude = parseFloat(center.lat());
        const longitude = parseFloat(center.lng());

        if (state.center.latitude !== latitude || state.center.longitude !== longitude) {
          setState((prevState) => ({ ...prevState, center: { latitude, longitude } }));
        }
      }
    }
  };

  const [getSiteInfo, { data: siteData }] = useLazyQuery<Query>(getSite, {
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading } = useQuery<Query>(listSiteOptions, {
    skip: !altId,
  });

  const [mapSelected, setMapSelected] = useState<string[]>([]);
  const mapList =
    !loading &&
    data?.listSiteOptions?.map((marker) => {
      // const stationsAvailable = [];
      let onMarkerClick = (id?: string) => {
        getSiteInfo({
          variables: {
            siteInput: {
              altId: id,
            },
          },
        });
        setMapSelected([altId]);
      };
      const icon = 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-b.png';

      // marker.stations.forEach((station) => {
      //   if (!station.stationPorts.filter(port => port.stationPortStatus !== 'AVAILABLE').length) {
      //     stationsAvailable.push(station);
      //   }
      // });

      if (mapSelected[0] === marker?.altId) {
        onMarkerClick = () => {
          setMapSelected([]);
        };
      }
      // if (stationsAvailable.length) icon = 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-a.png';

      return {
        icon,
        infoComponent: <ChargerMarkerInfo site={siteData?.getSite as Site} />,
        open: mapSelected[0] === marker?.altId,
        onClick: () => onMarkerClick(marker?.altId),
        onClose: () => onMarkerClick(),
        lat: marker?.latitude,
        lng: marker?.longitude,
      };
    });

  useEffect(() => {
    setState({
      center: {
        latitude: Number(props.position.latitude),
        longitude: Number(props.position.longitude),
      },
      radius: 40,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledModal className={`${className} modal`} open={props.open} onClose={() => props.hideModal('')}>
      <div className={`${className} container`}>
        <Toolbar className={`${className} header`}>
          <Typography className={className} variant="h6">
            Station Map
          </Typography>

          <IconButton className={`${className} close`} onClick={() => props.hideModal('')} id="closeModal1">
            <Close />
          </IconButton>
        </Toolbar>

        <Divider />

        {/* <section className={ `${className} content` }>
            <Place className={ `${className} available` } />
            <Typography className={ className } variant="body1">
              Available
            </Typography>
            <Place className={ `${className} in-use` } />
            <Typography className={ className } variant="body1">
              In Use
            </Typography>
            <Place className={ `${className} unavailable` } />
            <Typography className={ className } variant="body1">
              Unavailable
            </Typography>
          </section> */}

        <Divider />

        <section className={`${className} map`}>
          <Map
            googleMapURL={config.env.google.apiKey}
            markers={mapList || []}
            onDrag={onDrag}
            onMount={onMount}
            onZoom={onZoom}
            position={state.center}
            ref={mapRef}
            styles={{ display: 'flex', flexDirection: 'column', flex: '1', width: '100%', height: '594px' }}
            defaultZoom={20}
          />
        </section>
      </div>
    </StyledModal>
  );
};
