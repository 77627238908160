import _ from 'lodash';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDebounceFn } from 'ahooks';
import { InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography, CircularProgress } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { StyledFormControl, StyledBox } from './styles';
import { Options } from 'src/lib/helpers';

export type MultiSelectFilterProps = {
  id?: string;
  label: string;
  className?: string;
  options: Array<Options>;
  debounce?: number;
  onChange: (value: Array<string>) => void;
  initialValue?: Array<string>;
  loading?: boolean;
};

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = (props) => {
  const {
    label,
    id = _.kebabCase(label),
    className = 'MultiSelect',
    options,
    debounce = 200,
    onChange,
    initialValue = [],
    loading = false,
  } = props;

  const [selected, setSelected] = useState<string[]>(initialValue);
  const { run: debouncedOnChange } = useDebounceFn(onChange, { wait: debounce });

  const selectedLabels = (): string => {
    if (!selected.length) {
      return 'All';
    }
    return selected?.map((item) => options.find((option) => option.value === item)?.label).join(', ');
  };

  return (
    <StyledFormControl className={className}>
      <InputLabel className={clsx(className, 'label')}>{label}</InputLabel>
      <Select
        className={clsx(className, 'select')}
        displayEmpty
        multiple
        onChange={(e) => {
          const values: string[] = e.target.value as string[];
          setSelected(values);
          debouncedOnChange(values);
        }}
        renderValue={selectedLabels}
        value={selected}
        data-testid={`${id}-select`}
      >
        <MenuItem className={clsx(className, 'option-all')} value="" disabled>
          All
        </MenuItem>
        {options.map((option, i) => {
          return (
            <MenuItem key={option?.value} className={clsx(className, 'option')} value={option?.value} disableRipple>
              <Checkbox
                checked={!!selected.includes(option?.value)}
                checkedIcon={<CheckBox fontSize="small" />}
                disableRipple
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                inputProps={{ id: `${id}-${i}-option-checkbox` }}
                className={clsx(className, 'option-checkbox')}
                data-testid="option-checkbox"
              />
              <ListItemText data-testid="option-label" primary={option?.label} style={{ fontSize: 'small' }} />
            </MenuItem>
          );
        })}
      </Select>

      {loading && (
        <StyledBox>
          <CircularProgress size={20} />
        </StyledBox>
      )}

      {!_.isEmpty(selected) && (
        <Typography
          className={clsx(className, 'clear-options')}
          variant="caption"
          color="secondary"
          onClick={() => {
            setSelected([]);
            debouncedOnChange([]);
          }}
        >
          Clear
        </Typography>
      )}
    </StyledFormControl>
  );
};
