/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Modal)`
  position: relative;
  width: auto;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    margin-top: 0 !important;
    position: absolute;
    width: 60%;
    z-index: 999;

    .deets {
      display: inline;
    }

    .steps {
      display: flex;
      align-items: center;
      margin: ${spacing(2)}px;
      padding: ${spacing(2)}px;

      .stepper {
        flex: 4;

        .active {
          :global(svg) {
            color: #5d9d52;
          }
        }

        :global(text) {
          display: none;
        }
      }
    }

    header {
      display: flex;
      justify-content: space-between;
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      h2 {
        color: ${colors.accentBlue};
      }

      button {
        margin: -${spacing()}px;
      }
    }

    .connector-list {
      width: calc(66.66% - ${spacing(2)}px);
      margin: 0 auto ${spacing(4)}px;
      justify-content: space-between;
      height: 320px;
      text-align: center;
    }

    table {
      outline: 1px solid #e0e0e0;

      .connector-button {
        width: 1%;
      }
    }

    .confirmation {
      width: 66.66%;
      margin: 0 auto ${spacing(4)}px;
      background-color: #f8f8f8;
      height: 320px;
      display: flex;

      > div {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding: ${spacing(3)}px;
        > div {
          margin: 0 auto ${spacing(2)}px;
          white-space: nowrap;

          span {
            font-size: 14px;
            font-weight: bold;
          }

          p {
            font-size: 12px;
            color: #707070;
          }
        }
      }
    }

    .panel-actions {
      background-color: #ffffff;
      padding: ${spacing(3)}px;

      button {
        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }
    }

    .modal {
      background-color: red;
    }
  }
`;
