import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const ExampleViewer: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <Accordion defaultExpanded classes={{ root: 'example-viewer' }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails>
        <div className="expansion-panel-details-wrapper">{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExampleViewer;
