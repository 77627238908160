import React from 'react';
import { Styled } from './styles';

export interface StopCircleOutlineProps {
  id?: string;
  className?: string;
}

/**
 * Stop Circle Outline icon component
 */
export const StopCircleOutline: React.FC<StopCircleOutlineProps> = ({ id, className: parentClass }) => {
  const className = 'StopCircleOutline';
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <Styled
      id={id}
      className={customClass}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20.57 20.57"
    >
      <path
        d="M638.027,406.9a8.285,8.285,0,1,1,8.285-8.285A8.294,8.294,0,0,1,638.027,406.9Zm0-14.913a6.628,6.628,0,1,0,6.628,6.628A6.636,6.636,0,0,0,638.027,391.984Zm-3.314,3.314h6.628v6.628h-6.628Z"
        transform="translate(-627.742 -388.327)"
      />
      <path
        d="M638.027,406.9a8.285,8.285,0,1,1,8.285-8.285A8.294,8.294,0,0,1,638.027,406.9Zm0-14.913a6.628,6.628,0,1,0,6.628,6.628A6.636,6.636,0,0,0,638.027,391.984Zm-3.314,3.314h6.628v6.628h-6.628Z"
        transform="translate(-627.742 -388.327)"
      />
    </Styled>
  );
};
