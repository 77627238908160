import { gql } from '@apollo/client';

export const listPlans = gql`
  query listPlans($input: ListPlansInput) {
    listPlans(input: $input) {
      total
      page
      pageSize
      sort {
        planName
        planDisplayName
        createdAt
      }
      search {
        planName {
          iLike
        }
      }
      edges {
        altId
        createdAt
        planName
        planCode
        planStatus
        planDuration
        planDescription
        planDisplayName
        driverRank
      }
    }
  }
`;
