/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { getHostById } from '../../../apollo/queries/hosts';
import { HostContacts } from './HostContacts';
import { HostHeader } from './HostHeader';
import { HostProfile } from './HostProfile';
import { HostProperties } from './HostProperties';
import { Styled } from './styles';

/**
 * Toggles panel in edit view
 */
export const onPanelChange =
  (navigate: NavigateFunction, altId: string, section: string) =>
  (event: never, expanded: boolean): void => {
    if (altId) {
      if (expanded) navigate(`/hosts/${altId}/${section}`, { replace: true });
      else navigate(`/hosts/${altId}`, { replace: true });
    }
  };

/**
 * Host view component
 */
export const HostView: React.FC = () => {
  const params = useParams<{ altId: string; section: string }>();
  const navigate = useNavigate();

  const className = 'HostView';
  const { data: host } = useQuery<Query>(getHostById, {
    variables: { hostInput: { altId: params.altId } },
    skip: !params.altId,
  });

  return (
    <Styled className={className}>
      <HostHeader className={className} altId={params.altId} />

      <HostProfile
        className={className}
        expanded={params.section === 'profile'}
        onChange={onPanelChange(navigate, params.altId as string, 'profile')}
        altId={params.altId}
      />

      <HostContacts className={className} contacts={_.get(host, 'getHost.contacts.edges', [])} />

      <HostProperties className={className} />
    </Styled>
  );
};
