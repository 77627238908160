import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useConfirmationDialog } from 'src/lib/hooks';
import { useModalContext } from 'src/contexts/ModalContext';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import config from 'src/config';

export type Props = {
  className?: string;
};

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing(2)}px !important;
  position: relative;
  color: ${config.colors.accentBlue};
  button {
    position: absolute;
    margin-right: ${`-${theme.spacing()}px`};
    margin-bottom: ${`-${theme.spacing(2)}px`};
    align-self: end;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  margin-bottom: ${theme.spacing(8)}px;
  padding: ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(0)}px !important;
`;

const StyledFooterContainer = styled.div`
  padding: ${theme.spacing(0, 4, 2)};
`;

const StyledDialogActions = styled(DialogActions)`
  &.dialog-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: ${theme.spacing(4, 0, 2)};
    button {
      font-size: 14px;
      margin: ${theme.spacing(0, 1)};
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ConfirmationDialog: React.FC<Props> = ({ className }) => {
  const confirmationDialog = useConfirmationDialog();
  const { modalName, modalProps, modalVisible } = useModalContext();

  const customClass = clsx('confirmation-modal', className);

  const shouldModalOpen = modalVisible && modalName === 'ConfirmationDialog';

  if (!shouldModalOpen) {
    return null;
  }

  const onClick = () => {
    modalProps?.callback?.();
    confirmationDialog.close();
  };

  return (
    <StyledDialog
      data-testid="confirmation-dialog"
      className={`${customClass}`}
      onClose={confirmationDialog.close}
      open={shouldModalOpen}
    >
      <StyledDialogTitle disableTypography>
        <Typography variant="h6" component="h5">
          {modalProps.title || 'Are you sure?'}
        </Typography>
      </StyledDialogTitle>
      <Divider style={{ backgroundColor: config.colors.lightBlue, marginRight: '8px' }} />
      <StyledDialogContent>
        <DialogContentText color="textPrimary">
          <Typography>{modalProps.body || ''}</Typography>
          {typeof modalProps.body2 === 'function' && modalProps.body2()}
        </DialogContentText>

        {modalProps?.list && (
          <List dense={true}>
            {Array.isArray(modalProps?.list) &&
              modalProps.list.map((item: string, index: number) => (
                <ListItem key={index}>
                  <ListItemText secondary={item} />
                </ListItem>
              ))}
          </List>
        )}
      </StyledDialogContent>
      <StyledFooterContainer>
        <StyledDialogActions className="dialog-actions">
          {!modalProps?.hideCancel && (
            <Button
              data-testid="confirmation-dialog-cancel-button"
              color="secondary"
              onClick={confirmationDialog.close}
            >
              Cancel
            </Button>
          )}
          <Button
            data-testid="confirmation-dialog-confirm-button"
            color="secondary"
            variant="contained"
            onClick={onClick}
          >
            {modalProps.buttonConfirmText || 'Confirm'}
          </Button>
        </StyledDialogActions>
      </StyledFooterContainer>
    </StyledDialog>
  );
};
