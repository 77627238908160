import React from 'react';
import styled from 'styled-components';
import Done from '@material-ui/icons/Done';
import { theme } from '@evgo/react-material-components';
import { Stepper as MuiStepper, Step as MuiStep, StepLabel, Box } from '@material-ui/core';

const { palette } = theme;

export enum Step {
  Details = 'Pricing details',
  Prices = 'Prices',
  Summary = 'Summary',
}

export type ChargerPriceStepperProps = {
  currentStep: Step;
  mt?: number;
  mb?: number;
};

const steps: string[] = [Step.Details, Step.Prices, Step.Summary];

const Stepper = styled(MuiStepper)`
  &.MuiPaper-root {
    background-color: transparent;
  }

  &.MuiStepper-root {
    padding: 0;
    max-width: 900px;
  }

  .MuiStepLabel-label {
    color: ${palette.grey[600]};
    font-weight: 400;

    &.MuiStepLabel-completed {
      color: ${palette.text.primary};
      font-weight: 400;
    }

    &.MuiStepLabel-active {
      color: ${palette.secondary[700]};
      font-weight: 500;
    }
  }

  .MuiStep-root {
    .MuiSvgIcon-root {
      color: ${palette.grey[200]};
    }

    &.MuiStep-completed .MuiSvgIcon-root {
      color: ${palette.secondary[700]};
    }

    .MuiStepIcon-root .MuiStepIcon-text {
      fill: ${palette.grey[600]};
    }

    .MuiStepIcon-active {
      color: ${palette.secondary[700]};

      .MuiStepIcon-text {
        fill: ${palette.common.white};
      }
    }
  }
`;

export function ChargerPriceStepper({ currentStep, mt = 0, mb = 4 }: ChargerPriceStepperProps) {
  const activeStep = steps.indexOf(currentStep);

  return (
    <Box mt={mt} mb={mb}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const completed = index < activeStep;
          const props = completed ? { StepIconComponent: Done } : {};

          return (
            <MuiStep key={label}>
              <StepLabel {...props}>{label}</StepLabel>
            </MuiStep>
          );
        })}
      </Stepper>
    </Box>
  );
}
