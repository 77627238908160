import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { AddBox, Create, PlayCircleOutline, Refresh, CloudDownload } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Mutation } from 'src/@types';
import { resetDriverPassword } from 'src/apollo/queries/drivers';
import { Styled as StyledPaper } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';

export interface Props {
  startCharge: boolean;
  setStartCharge: (val: boolean) => void;
  driverData: Record<string, unknown>;
  className?: string;
}

/**
 * Driver actions component.
 */
export const DriverActions: React.FC<Props> = (props) => {
  const className = 'DriverActions';
  const { altId } = useParams<{ altId: string }>();
  const { values } = useFormikContext();

  const { driverData, startCharge, setStartCharge } = props;
  const actionBarRef = useRef<HTMLDivElement>(null);
  const { setModalState } = useModalContext();
  const snackbar = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const [onResetPassword] = useMutation<Mutation>(resetDriverPassword, {
    onCompleted() {
      snackbar.success('The password reset email has been sent.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });

  const sendPasswordResetEmail = () => {
    onResetPassword({
      variables: {
        driverInput: {
          altId,
        },
      },
    });
  };

  const showSyncAccountModal = () => {
    setModalState({
      modalName: 'AccountSyncModal',
      modalProps: {
        driver: {
          phone: driverData.phone,
          email: driverData.email,
        },
      },
      modalVisible: true,
    });
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (actionBarRef.current && !actionBarRef.current.contains(e.target as HTMLElement)) setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  if (expanded) customClass += ' open';

  return (
    <StyledPaper className={`${customClass} action-section`} component="section">
      <div className={className}>
        <div ref={actionBarRef} className={`${className} actions`} onClick={() => setExpanded(!expanded)}>
          <Button
            id="sync-user-button"
            data-testid="sync-user-button"
            className={`${className} ${!altId ? 'disabled' : ''} ${startCharge ? 'selected' : ''}`}
            size="large"
            disabled={!altId || !expanded}
            onClick={() => {
              showSyncAccountModal();
              setExpanded(!expanded);
            }}
          >
            <CloudDownload className={`${className} left`} />
            Pull Data From Driivz
          </Button>
          <Button
            id="change-status-button"
            data-testid="change-status-button"
            className={`${className} ${!altId ? 'disabled' : 'active'}`}
            size="large"
            disabled={!altId || !expanded}
            onClick={() => {}}
          >
            <Create className={`${className} left`} />
            Change Status
          </Button>

          <Button
            id="reset-password-button"
            className={`${className} ${!altId ? 'disabled' : 'active'}`}
            size="large"
            disabled={!altId || !expanded}
            onClick={sendPasswordResetEmail}
          >
            <Refresh className={`${className} left`} />
            Reset Password
          </Button>

          <Button
            id="start-charge-button"
            data-testid="start-charge-button"
            className={`${className} ${!altId || !_.get(values, 'deletedAt') ? 'disabled' : ''} ${
              startCharge ? 'selected' : ''
            }`}
            size="large"
            disabled={!altId || !expanded || !_.get(values, 'deletedAt')}
            onClick={() => {
              setStartCharge(!startCharge);
              setExpanded(!expanded);
            }}
          >
            <PlayCircleOutline className={`${className} left`} />
            Start Charge
          </Button>

          <Button
            id="resend-invite-button"
            data-testid="resend-invite-button"
            className={`${className} ${!altId ? 'disabled' : 'active'}`}
            size="large"
            disabled={!altId || !expanded}
          >
            <AddBox className={`${className} left`} />
            Resend Invite
          </Button>
        </div>
      </div>
    </StyledPaper>
  );
};
