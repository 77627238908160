import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Styled as StyledModal } from './styles';

interface Props {
  open: boolean;
}

export const StartChargeModal: React.FC<Props> = (props) => {
  const className = 'StartChargeModal';

  return (
    <StyledModal open={props.open} className={className}>
      <div className={clsx(className, 'modal-content')}>
        <CircularProgress className={className} />
        <Typography variant="h6" className={className}>
          Processing...
        </Typography>
      </div>
    </StyledModal>
  );
};
