import { gql } from '@apollo/client';
import { FullCharger } from './FullCharger';

export const getCharger = gql`
  query getCharger($chargerInput: GetChargerInput) {
    getCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullCharger}
`;
