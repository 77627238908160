import { useQuery } from '@apollo/client';
import { Dropdown, TextInfo } from '@evgo/react-material-components';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { DriversWithMeta, Query } from '../../../../../@types';
import { listVehicles } from '../../../../../apollo/queries/vehicles';
import { fuzzyFilterOption } from '../../../../../lib/helpers';
import { useBlurDropdown } from '../../../../../lib/hooks';
import { StyledModal } from '../../../../shared/StyledModal';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export const initialValues = {
  vehicle: null,
  vin: '',
  driver: '',
  nickName: '',
};

interface Props {
  open: boolean;
  drivers: DriversWithMeta;
  vehicle: Record<string, unknown> | undefined;
  driver: unknown;
  className?: string;
}

export const AccountVehicleModalForm: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountVehicleModal');
  const className = id;
  const { errors, handleBlur, handleChange, isValid, setValues, touched, values, submitForm } =
    useFormikContext<Record<string, unknown>>();
  const handleBlurDropdown = useBlurDropdown();
  const { open, drivers, vehicle: editVehicle, driver: editDriver } = props;
  const { setModalState } = useModalContext();

  const { data } = useQuery<Query>(listVehicles, {
    variables: {
      vehiclesInput: {
        pageSize: 999,
      },
    },
    skip: !open,
  });

  const vehicleTypes = data?.listVehicles?.edges || [];
  const vehicleOptions = vehicleTypes.map((vehicle) => ({
    label: vehicle?.makeModelYear,
    value: vehicle?.altId,
    connectors: vehicle?.vehicleConnectors,
  }));
  const driverOptions = (drivers?.edges || []).map((driver) => ({
    label: `${driver?.firstName} ${driver?.lastName}`,
    value: driver?.altId,
  }));

  useEffect(() => {
    if (!_.isEmpty(editVehicle)) {
      setValues({
        ...editVehicle,
        vehicle: _.get(editVehicle, 'vehicle.altId', {}),
        driver: _.get(editDriver, 'altId', ''),
      });
    }
  }, [editDriver, editVehicle, setValues]);

  const onCancelClick = () => {
    setModalState({
      modalName: '',
      modalProps: {},
      modalVisible: false,
    });

    setValues(initialValues);
  };

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledModal
      className={clsx(customClass, 'modal')}
      onClose={onCancelClick}
      title={_.isEmpty(editVehicle) ? 'Add Vehicle' : 'Edit Vehicle'}
      secondaryButtonText="Cancel"
      secondaryButtonClick={onCancelClick}
      open={open}
      onClick={submitForm}
      primaryButtonText={editVehicle ? 'SAVE' : 'ADD VEHICLE'}
      primaryButtonDisabled={!isValid || _.isEmpty(_.get(values, 'vehicle'))}
    >
      <Styled className={className}>
        <Form id={`${id}-form`} className={`${className} form`}>
          <div className={`${className} content`}>
            <div className={`${className} form`}>
              <Dropdown
                className={`${className} form-control`}
                data-testid="add-vehicle-modal-vehicle-type"
                displayValue={_.get(values, 'vehicle', '')}
                filterOption={fuzzyFilterOption}
                id="add-vehicle-modal-vehicle-type"
                label="Vehicle Type *"
                name="vehicle"
                options={vehicleOptions}
                onBlur={handleBlurDropdown}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setValues({
                    ...values,
                    vehicle: target.value || '',
                    connectors: _.find(vehicleOptions, { value: target.value })?.connectors,
                  })
                }
                value={_.get(values, 'vehicle')}
                variant="filled"
                isSearchable
              />

              <TextInfo
                className={className}
                label="Connector Type *"
                value={((values?.connectors as Record<string, string>[]) || []).map((c) => c.connectorName).join(', ')}
                id="add-vehicle-modal-connector-type"
                data-testid="add-vehicle-modal-connector-type"
                variant="filled"
                disabled
              />

              <TextInfo
                className={className}
                error={errors.vin && touched.vin}
                helpertext={errors.vin && touched.vin && _.get(errors, 'vin')}
                label="VIN"
                name="vin"
                value={_.get(values, 'vin')}
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                id="add-vehicle-modal-vin"
                data-testid="add-vehicle-modal-vin"
                variant="filled"
              />

              <Dropdown
                id="add-vehicle-modal-driver-dropdown"
                className={clsx(customClass, 'form-control', 'half')}
                data-testid="add-vehicle-modal-driver-dropdown"
                displayValue={_.get(values, 'driver')}
                label="Driver"
                labelProps={{ className }}
                menuProps={{ className }}
                name="driver"
                options={driverOptions || []}
                onBlur={handleBlurDropdown}
                onChange={handleChange}
                required
                value={_.get(values, 'driver')}
                isSearchable
              />

              <TextInfo
                id="add-vehicle-modal-nickname"
                className={className}
                data-testid="add-vehicle-modal-nickname"
                label="Nickname"
                name="nickName"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                value={_.get(values, 'nickName')}
                variant="filled"
              />
            </div>
          </div>
        </Form>
      </Styled>
    </StyledModal>
  );
};
