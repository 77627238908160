import { Box, TextField, Typography, Chip, Checkbox, InputLabel } from '@material-ui/core';
import { SearchField } from 'src/components/shared/SearchField';
import { Filters } from '../DriversList';
import { MultiSelectFilter } from 'src/components/shared/MultiSelectFilter';
import { useQuery } from '@apollo/client';
import { Query } from 'src/@types';
import { listFalconConstraints } from '../../../../apollo/queries/options';
import { geographicalRegionsInput, shapeGeographicalRegions } from '../../../../lib/helpers';
import { listGeographicalRegions } from '../../../../apollo/queries/geographicalRegions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import React from 'react';
import { theme } from '@evgo/react-material-components';

const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const labelStyle = {
  fontSize: `${theme.typography.fontSize}px`,
  'margin-bottom': '4px',
};
export type Props = {
  filters: Filters;
  onSearch: (val: string) => void;
  onFilter: (val: Filters) => void;
  totalResults?: number;
};

export function DriversListFilters({ filters, onSearch, onFilter, totalResults }: Props) {
  const statusList = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Closed', value: 'CLOSED' },
    { label: 'Registered', value: 'REGISTERED' },
    { label: 'Suspended', value: 'SUSPENDED' },
  ];
  const { data: filteredStatuses } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'drivers',
          },
          columnName: {
            eq: 'driver_status',
          },
        },
        sort: {
          id: 'ASC',
          columnText: 'ASC',
        },
      },
    },
  });
  const { data: geographicalRegions } = useQuery(listGeographicalRegions, {
    variables: geographicalRegionsInput,
  });
  const shapedStatusOptions = (filteredStatuses?.listFalconConstraints?.edges || []).map((status) => ({
    value: status?.id || '',
    label: status?.columnValue || '',
  }));
  const { shapedStateOptions, shapedProvinceOptions } = shapeGeographicalRegions(geographicalRegions);
  return (
    <div data-testid="DriversListFilters">
      <Box display="flex" width="100%" mb={2} alignItems="flex-start" justifyContent="space-between">
        <div>
          <Typography variant="h6" component="h2">
            Drivers
          </Typography>
          <Typography data-testid="TotalResults" variant="caption">
            Currently Viewing {totalResults}
          </Typography>
        </div>
        <SearchField
          helperText="Search email, name, VIN, account #"
          initialValue={filters.search}
          onChange={onSearch}
        />
      </Box>

      <Box display="flex" alignItems="flex-start">
        <Box pt={1} pr={2}>
          <MultiSelectFilter
            label="Account Status"
            id="AccountStatusInput"
            options={statusList}
            onChange={(type) =>
              onFilter({
                ...filters,
                account_accountStatus: type.length
                  ? type
                      .join(',')
                      .split(',')
                      .map((val) => String(val))
                  : [],
              })
            }
            debounce={500}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectFilter
            label="Status"
            id="StatusInput"
            options={shapedStatusOptions}
            onChange={(status) =>
              onFilter({
                ...filters,
                driverStatusId: status.length
                  ? status
                      .join(',')
                      .split(',')
                      .map((val) => String(val))
                  : [],
              })
            }
            debounce={500}
          />
        </Box>
        <Box pt={1} pl={2}>
          <div data-testid="drivers-list-filters">
            <InputLabel style={labelStyle}>State/Province</InputLabel>
            <Autocomplete
              multiple
              disablePortal
              limitTags={2}
              freeSolo
              className="select"
              id="multiple-limit-tags"
              style={{ width: 250 }}
              options={[...shapedStateOptions, ...shapedProvinceOptions] || []}
              onChange={(event, type) =>
                onFilter({
                  ...filters,
                  account_billingAddress_administrativeArea: type.length
                    ? type
                        .map((val) => String(val.value))
                        .join(',')
                        .split(',')
                    : [],
                })
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="default" size="small" label={option.value} {...getTagProps({ index })} />
                ))
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.label}
                </React.Fragment>
              )}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} placeholder="Start Searcing..." variant="standard" />}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
}
