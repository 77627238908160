import { storyblokInit, apiPlugin } from '@storyblok/react';
import config from './config';

export const init = () => {
  storyblokInit({
    accessToken: config.env.storyblok.accessToken,
    apiOptions: { region: 'us' },
    use: [apiPlugin],
    components: {},
  });
};

export const extendStoryPath = 'apps/extend-plus/';
export const extendCategoriesCdn = `cdn/stories?starts_with=${extendStoryPath}categories`;
export const extendArticlesCdn = `cdn/stories?starts_with=${extendStoryPath}articles`;

type StoryVersion = 'draft' | 'published' | undefined;

// @ts-ignore
export const version: StoryVersion = config.env.storyblok.version;
