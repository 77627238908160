import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const {
  palette,
  shape: { borderRadius },
  spacing,
} = theme;

export const Styled = styled.section`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${palette.text.disabled};
  border-radius: ${borderRadius}px;
  padding: ${spacing(3)}px;

  .list-title {
    flex-grow: 1;
  }

  .divider {
    margin-bottom: ${spacing(2)}px;
  }
`;
