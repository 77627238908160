import { gql } from '@apollo/client';

export const updateContract = gql`
  mutation updateContract($contractInput: UpdateContractInput!) {
    updateContract(input: $contractInput) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
