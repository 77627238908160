import { gql } from '@apollo/client';

export const unassignCards = gql`
  mutation unassignCards($input: UnassignCardsInput!) {
    unassignCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
