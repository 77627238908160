import styled from 'styled-components';

export const Styled = styled.div`
  .menu {
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    box-sizing: border-box;
    min-width: 200px;
    max-width: 250px;

    .select {
      margin: 0px 10px;
      font-size: 14px;
      min-width: 200px;
      max-width: 250px;

      &::before {
        content: '';
        border-bottom: none;
      }
    }
  }

  .clear-all {
    position: absolute;
    top: 100%;
    cursor: pointer;
  }
`;
