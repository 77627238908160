import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  .content {
    padding: 16px 16px 8px 16px;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .form-control {
    width: calc(50% - ${spacing(1)}px);
  }

  .full-width {
    width: 100%;
  }

  .search {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .search-input {
      margin-bottom: ${spacing(2)}px;
      display: grid;
      width: 100%;
      grid-template-columns: 5fr 2fr;

      button {
        margin: ${`${spacing()}px 0px ${spacing(3)}px ${spacing(2)}px`};
      }
    }

    .search-table {
      width: 100%;
    }
  }

  .modal-actions {
    background-color: ${config.colors.white};
    flex-direction: row;

    button {
      svg {
        &.left {
          margin-right: ${spacing()}px;
        }
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: ${`${spacing(2.5)}px ${spacing(3.25)}px`};

    h2 {
      color: ${config.colors.accentBlue};
    }

    button {
      margin: -${spacing()}px;
    }
  }

  .confirmation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${spacing(3)}px;

    .label {
      font-size: 12px;
      margin-bottom: 16px;
      font-weight: 300;
      padding: ${spacing() / 2}px;
    }

    .customer {
      border: 1px solid lightgrey;
      padding: ${spacing(3)}px;
      margin-top: ${spacing(6)}px;
      .email-address {
        padding: ${`${spacing(2)}px 0`};
      }
    }
  }

  .customer-header {
    font-size: ${spacing(1.5)}px;
    margin: ${`${spacing(2)}px 0px`};
    span {
      padding: 0px 38px;
      &.email {
        padding: 0px 78px;
      }
      &.phone {
        padding: 0px 0px 0 44px;
      }
    }
  }

  .confirm-header {
    font-size: ${spacing(1.5)}px;
    margin: ${`${spacing(2)}px 0px`};
    span {
      padding: 0px 136px 0 22px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: ${`${spacing(2.5)}px ${spacing(3.25)}px`};
    width: 795px;
    max-width: 795px;

    .search-table {
      max-height: 228px;
      overflow-y: auto;

      table {
        tbody {
          td {
            padding: ${`${spacing() / 2}px ${spacing(3)}px`};
          }
        }
      }
    }

    p {
      width: 100%;
    }

    hr {
      width: calc(100% + 48px);
      margin-left: -24px;
    }
  }

  .helper-text {
    display: none;
  }
`;
