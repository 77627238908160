import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { LabelValue } from 'src/@types/shared';
import { Styled } from './styles';

export interface Props {
  models: LabelValue<string>[];
  updateFilters: (
    { chargerModel_altId }: { chargerModel_altId?: string[]; fieldStationStatus?: string[] },
    p: string,
  ) => void;
  className?: string;
}

const statusList = [
  { label: 'Ordered', value: 'ORDERED' },
  { label: 'In Stock', value: 'IN_STOCK' },
  { label: 'Warehouse Testing', value: 'WAREHOUSE_TESTING' },
  { label: 'Ready for Installation', value: 'READY_FOR_INSTALLATION' },
  { label: 'Connected', value: 'CONNECTED' },
  { label: 'Site Testing', value: 'SITE_TESTING' },
  { label: 'Provisioned', value: 'PROVISIONED' },
];

/**
 * Returns a string value of selected markets.
 */
export const checkSelected = (selected: string[], list: LabelValue<string>[]): string => {
  if (!selected.length) {
    return 'All';
  }

  return selected?.map((item) => _.find(list, { value: item })?.label).join(', ');
};

/**
 * ChargersListFilters component.
 */
export const ChargersListFilters: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargersListFilters');
  const { models, updateFilters, className } = props;
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  return (
    <Styled className={className}>
      <FormControl className={className}>
        <InputLabel className={`${className} label`} htmlFor="charger-model">
          Charger Model
        </InputLabel>
        <Select
          className={`${className} select`}
          displayEmpty
          multiple
          onChange={(e) => {
            updateFilters({ chargerModel_altId: e.target.value as string[] }, 'chargerModel_altId');
            setSelectedModels(e.target.value as string[]);
          }}
          renderValue={(selected) => checkSelected(selected as string[], models)}
          value={selectedModels}
        >
          <MenuItem className={className} disabled value="">
            All
          </MenuItem>
          {models.map((model, i) => {
            return (
              <MenuItem key={model?.value} value={model?.value} disableRipple>
                <Checkbox
                  checked={selectedModels.includes(model?.value)}
                  checkedIcon={<CheckBox fontSize="small" />}
                  disableRipple
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  inputProps={{ id: `${id}-${i}-model-checkbox` }}
                />
                <ListItemText primary={model?.label} style={{ fontSize: 'small' }} />
              </MenuItem>
            );
          })}
        </Select>

        {!_.isEmpty(selectedModels) ? (
          <Typography
            className={`${className} clear-models`}
            variant="caption"
            color="secondary"
            onClick={() => {
              props.updateFilters({ chargerModel_altId: [] }, 'chargerModel_altId');
              setSelectedModels([]);
            }}
          >
            Clear
          </Typography>
        ) : null}
      </FormControl>

      <FormControl className={className}>
        <InputLabel className={`${className} label`} htmlFor="charger-status">
          Charger Status
        </InputLabel>
        <Select
          className={`${className} select`}
          displayEmpty
          input={<Input id="chargers-list-filters-status-multi-select" />}
          multiple
          onChange={({ target }) => {
            props.updateFilters({ fieldStationStatus: target.value as string[] }, 'fieldStationStatus');
            setSelectedStatuses(target.value as string[]);
          }}
          renderValue={(selected) => checkSelected(selected as string[], statusList)}
          value={selectedStatuses}
        >
          <MenuItem className={className} disabled value="">
            All
          </MenuItem>
          {statusList.map((status, i) => {
            return (
              <MenuItem key={status.value} value={status.value} disableRipple>
                <Checkbox
                  checked={selectedStatuses.includes(status.value)}
                  checkedIcon={<CheckBox fontSize="small" />}
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  inputProps={{ id: `${id}-${i}-status-checkbox` }}
                  disableRipple
                />
                <ListItemText primary={status.label} style={{ fontSize: 'small' }} />
              </MenuItem>
            );
          })}
        </Select>
        {!_.isEmpty(selectedStatuses) ? (
          <Typography
            className={`${className} clear-status`}
            variant="caption"
            color="secondary"
            onClick={() => {
              props.updateFilters({ fieldStationStatus: [] as string[] }, 'fieldStationStatus');
              setSelectedStatuses([]);
            }}
          >
            Clear
          </Typography>
        ) : null}
      </FormControl>
    </Styled>
  );
};
