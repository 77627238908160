import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.section`
  box-sizing: border-box;
  width: calc(100% - ${spacing(4)}px);
  margin: 32px 16px 0;
`;
