import Yup from '../../../../../../yup';

export default Yup.object().shape({
  address1: Yup.string().trim().required('Required'),
  address2: Yup.string().trim().nullable(),
  administrativeArea: Yup.string().trim().required('Required'),
  country: Yup.string().trim().nullable(),
  hid: Yup.string().trim().required('Required'),
  hostName: Yup.string().trim().required('Required'),
  locality: Yup.string().trim().required('Required'),
  postalCode: Yup.string()
    .trim()
    .matches(/^[0-9]{5}([\s-]{1}[0-9]{4})?$/, 'Invalid Zip')
    .required('Required'),
});
