/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryResult } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { ChargersWithMeta } from 'src/@types';
import { sanitizeSearch, updateQuery } from '../../../../../lib/helpers';
import { ListSearch as ChargersListSearch } from '../../../../shared/ListSearch';
import { Styled as StyledPaper } from './styles';

export interface Props {
  fetchMore: QueryResult<never, never>['fetchMore'];
  metadata: ChargersWithMeta;
  className?: string;
}

const searchFields = [
  'cid',
  'chargerName',
  'site_address1',
  'site_locality',
  'site_administrativeArea',
  'site_postalCode',
  'site_property_propertyName',
];

/**
 * Search hosts list
 */
export const onSearchChange = (
  fetchMore: QueryResult<never, never>['fetchMore'],
  metadata: ChargersWithMeta,
  target: EventTarget & HTMLInputElement,
): ReturnType<QueryResult<never, never>['fetchMore']> => {
  const { pageSize, sort } = metadata;

  let search: Record<string, unknown> | null = {};
  if (target.value.length) {
    searchFields.forEach((field) => {
      if (!search) {
        return;
      }
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      chargersInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        search,
      },
    },
  });
};

/**
 * Changes siteChargers list page
 */
export const onPageChange =
  (fetchMore: QueryResult<never, never>['fetchMore'], metadata: ChargersWithMeta, altId: string) =>
  (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ): ReturnType<QueryResult<never, never>['fetchMore']> => {
    const { filters, pageSize, search, sort } = metadata as ChargersWithMeta & { filters: unknown };

    return fetchMore({
      updateQuery,
      variables: {
        siteInput: { altId },
        chargersInput: {
          filters,
          search: sanitizeSearch(search, searchFields),
          sort: _.pickBy(
            _.pick(sort, ['chargerName', 'fieldStationStatus', 'cid', 'altId', 'propertyName']),
            (i) => i !== null,
          ),
          page,
          pageSize,
        },
      },
    });
  };

/**
 * Sorts hosts list
 */
export const onSortClick =
  (fetchMore: QueryResult<never, never>['fetchMore'], metadata: ChargersWithMeta, id: string) =>
  (): ReturnType<QueryResult<never, never>['fetchMore']> => {
    const { pageSize, search } = metadata;
    let sort = { [id]: 'ASC' };

    if (_.get(metadata, `sort.${id}`, 'ASC') === 'ASC') sort = { [id]: 'DESC' };
    return fetchMore({
      updateQuery,
      variables: {
        chargersInput: {
          page: 0,
          pageSize,
          search: sanitizeSearch(search, searchFields),
          sort,
        },
      },
    });
  };

/**
 * Changes siteChargers list page size
 */
export const onRowsPerPageChange =
  (fetchMore: QueryResult<never, never>['fetchMore'], metadata: ChargersWithMeta, altId: string) =>
  (event: React.ChangeEvent<HTMLInputElement>): ReturnType<QueryResult<never, never>['fetchMore']> => {
    const { filters, search, sort } = metadata as ChargersWithMeta & { filters: unknown };

    return fetchMore({
      updateQuery,
      variables: {
        siteInput: { altId },
        chargersInput: {
          filters,
          search: sanitizeSearch(search, searchFields),
          sort: _.pickBy(
            _.pick(sort, ['chargerName', 'fieldStationStatus', 'cid', 'altId', 'propertyName']),
            (i) => i !== null,
          ),
          page: 0,
          pageSize: event.target.value,
        },
      },
    });
  };

/**
 * Site Chargers component
 */
export const SiteChargers: React.FC<Props> = ({ className: parentClass, fetchMore, metadata }) => {
  const id = _.kebabCase('SiteChargers');
  const className = id;
  const { values } = useFormikContext();
  const altId = _.get(values, 'altId');
  const siteName = _.get(values, 'siteName');
  const debouncedSearchChange = typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : onSearchChange;

  const columns = [
    { id: 'chargerName', label: 'Name' },
    { id: 'cid', label: 'Charger ID' },
    { id: 'chargerModel', label: 'Model' },
    { id: 'fieldStationStatus', label: 'Status' },
    { id: 'address', label: 'Address' },
    { id: 'propertyName', label: 'Property Name' },
  ];

  const rows = _.get(values, 'chargers', []);

  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            Chargers
          </Typography>

          <Typography className={className} variant="caption" id={`${id}-charger-count`}>
            Currently viewing {metadata.total}
          </Typography>
        </div>

        <div className={className}>
          <Link to="/chargers/new" state={{ altId, siteName }}>
            <Button
              id={`${id}-add-charger-button`}
              className={className}
              variant="contained"
              color="secondary"
              size="large"
            >
              Add Charger
            </Button>
          </Link>

          <ChargersListSearch
            id={`${id}-chargers-list-search-input`}
            className={`${className} search`}
            type="charger"
            onSearchChange={(search) => debouncedSearchChange(fetchMore, metadata, search)}
          />
        </div>
      </header>

      <Divider />

      <Table className={className} id={`${id}-table-list`}>
        <TableHead className={className}>
          <TableRow className={className}>
            {columns.map((column) => (
              <TableCell key={column.id} className={className}>
                <TableSortLabel
                  id={`${id}-${column.label}-column-header-sort-label`}
                  active={_.isString(_.get(metadata, `sort.${column.id}`))}
                  direction={(_.toLower(_.get(metadata, `sort.${column.id}`, 'asc')) || 'asc') as 'asc'}
                  onClick={onSortClick(fetchMore, metadata, column.id)}
                  disabled={!['chargerName', 'cid', 'propertyName', 'fieldStationStatus'].includes(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={className}>
          {rows.map((row: any, key: number) => (
            <TableRow key={key} className={className}>
              {columns.map((column, i) => (
                <TableCell key={i} className={className} id={`${id}-${column.id}-row-${key}`}>
                  {row[column.id].to ? (
                    <Link className={className} to={row[column.id].to} id={`${id}-${column.id}-row-${key}-link`}>
                      {row[column.id].value}
                    </Link>
                  ) : (
                    row[column.id].value
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>

        <TableFooter className={className} id={`${id}-table-footer`}>
          <TableRow className={className}>
            <TablePagination
              backIconButtonProps={{ id: `${id}-table-footer-back-button` }}
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={metadata.total || 0}
              nextIconButtonProps={{ id: `${id}-table-footer-next-button` }}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ id: `${id}-table-footer-select`, inputProps: { className: `${className} select` } }}
              onPageChange={onPageChange(fetchMore, metadata, altId)}
              onRowsPerPageChange={onRowsPerPageChange(fetchMore, metadata, altId)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};

export default SiteChargers;
