import { gql } from '@apollo/client';

export const listCardOrders = gql`
  query listCardOrders($input: ListCardOrdersInput) {
    listCardOrders(input: $input) {
      total
      page
      pageSize
      filter {
        orderStatus {
          in
        }
      }
      search {
        driver_firstName {
          iLike
        }
        driver_lastName {
          iLike
        }
        account_billingAddress_address1 {
          iLike
        }
        account_billingAddress_locality {
          iLike
        }
        account_billingAddress_administrativeArea {
          iLike
        }
        account_billingAddress_postalCode {
          iLike
        }
        accountId {
          eq
        }
      }
      edges {
        altId
        orderStatus
        orderedAt
        fulfilledAt
        driver {
          firstName
          lastName
        }
        account {
          altId
          id
          billingAddress {
            address1
            address2
            locality
            administrativeArea
            postalCode
          }
        }
      }
    }
  }
`;
