import { Button, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { Site, SiteImage } from 'src/@types';
import { ImageGrid } from './ImageGrid';
import { Styled } from './styles';

export interface Props {
  className?: string;
}

/**
 * Site Photos component
 */
export const SitePhotos: React.FC<Props> = () => {
  const id = _.kebabCase('SitePhotos');
  const className = id;
  const { values, setValues } = useFormikContext<Site>();
  const [selected, setSelected] = useState<SiteImage[]>([]);
  const images = _.get(values, 'siteImages.edges', []);

  const deleteImages = () => {
    const newImages = _.filter(images, (img) => !_.includes(selected, _.get(img, 'media.altId')));

    setSelected([]);
    setValues({ ...values, siteImages: { edges: newImages } });
  };

  return (
    <Styled>
      <div className={`${className} site-photos`}>
        <div className={className}>
          <Typography className={className} variant="subtitle1" component="h3">
            Photos
          </Typography>

          <Typography className={className} variant="caption" component="h6">
            Up to six .JPG or .PNG
          </Typography>
        </div>

        <Button
          id={`${id}-delete-button`}
          className={className}
          onClick={deleteImages}
          disabled={_.isEmpty(selected)}
          color="default"
          variant="text"
        >
          <Delete
            className={`${className} left`}
            color="secondary"
            style={{ opacity: _.isEmpty(selected) ? '0.5' : '1' }}
          />
          Delete
        </Button>
      </div>

      <ImageGrid className={className} selected={selected} setSelected={setSelected} />
    </Styled>
  );
};
