import { gql } from '@apollo/client';

export const listOptions = gql`
  query listOptions($optionsInput: String!) {
    listOptions: __type(name: $optionsInput) {
      edges: enumValues {
        label: description
        value: name
      }
    }
  }
`;
