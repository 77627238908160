import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import { Styled as StyledPaper } from './styles';

export interface Props {
  onSearchChange: (target: EventTarget & HTMLInputElement) => void;
  type: string;
  isTextField?: boolean;
  id?: string;
  className?: string;
}

export const ListSearch: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');
  const className = 'list-search-component';
  const { onSearchChange, type, isTextField, id } = props;
  const dataTestId = id === undefined ? 'list-search' : id + '-list-search';

  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  if (isTextField) {
    return (
      <div className={`${customClass} search`}>
        <TextField
          className={className}
          margin="dense"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(target.value);
            onSearchChange(target);

            // TODO: is this even used?
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (onSearchChange as any)?.cancel();
          }}
          placeholder={`Search for a ${type}`}
          value={search}
          variant="outlined"
          data-testid={dataTestId}
          InputProps={{
            id,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }

  return (
    <StyledPaper className={`${customClass} search`}>
      <TextField
        className={className}
        margin="dense"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(target.value);
          onSearchChange(target);
        }}
        placeholder={`Search for a ${type}`}
        value={search}
        variant="outlined"
        data-testid={dataTestId}
        InputProps={{
          id,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </StyledPaper>
  );
};
