import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { palette, spacing } = theme;

export const Styled = styled(Accordion)`
  box-sizing: border-box;
  width: calc(100% - ${spacing(4)}px);

  &.site-details {
    margin: 32px 16px 80px !important;
  }

  header {
    padding: ${`0 ${spacing(3)}px`};

    h2 {
      color: ${config.colors.accentBlue};
    }
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      flex: 1;
      display: flex;

      .custom-hours {
        width: 100%;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: ${`${spacing()}px ${spacing(2)}px`};
        pointer-events: none;

        h3 {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }

        .map-container {
          padding: ${spacing()}px;
          .map {
            width: 100%;
            &.disabled {
              filter: saturate(0);
            }
          }
        }

        .img-placeholder {
          width: 33.33%;
          height: ${`${spacing(15)}px`};
          background: lightgrey;
          padding: ${`0 ${spacing()}px ${spacing(9)}px`};
          margin: ${`0 ${spacing()}px ${spacing(9)}px`};
        }
      }
    }
  }
`;
