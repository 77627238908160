import { SummaryBySiteName } from '.';
import { ListSessionsForMapQuery } from 'src/@types';

export const getSummaryBySite = (data?: ListSessionsForMapQuery): SummaryBySiteName => {
  return (
    data?.listSessionsV2?.edges?.reduce<SummaryBySiteName>((acc, cv) => {
      const site = cv?.connector?.evse?.charger?.site;
      const siteName = site?.siteName || '';

      acc[siteName] = {
        totalSessions: acc[siteName]?.totalSessions + 1 || 1,
        administrativeArea: site?.administrativeArea || '',
        longitude: site?.longitude || 0,
        latitude: site?.latitude || 0,
        siteName,
      };
      return acc;
    }, {}) || {}
  );
};
