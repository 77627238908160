/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, spacing } = theme;
const { brandColor, colors } = config;

export const Styled = styled.article`
  min-width: 225px;
  margin: 5px;
  background-color: ${palette.common.white};

  div {
    padding: 5px;
    color: ${palette.common.white};
  }

  > div {
    height: ${spacing(3)}px;
    display: flex;
    align-items: center;
    background-color: ${brandColor};
  }

  ul {
    li {
      display: flex;
      align-content: flex-start;
      padding: 5px;

      a {
        color: ${colors.accentBlue};
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        margin-left: auto;
        margin-right: 0;
        color: #008000;
      }
    }
  }
`;
