/* stylelint-disable color-no-hex */
import styled from 'styled-components';
import config from '../../../../../../config';

const { brandColor, colors } = config;

export const Styled = styled.article`
  margin: 5px;
  background-color: ${colors.white};

  div {
    padding: 5px;
    color: ${colors.white};
  }

  > div {
    background-color: ${brandColor};
  }

  ul {
    li {
      display: flex;
      align-content: flex-start;
      padding: 5px;

      a {
        color: ${colors.accentBlue};
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        margin-left: auto;
        margin-right: 0;
        color: #008000;
      }
    }
  }
`;
