/* stylelint-disable color-no-hex */
import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { ValueFormat } from '@nivo/core';
import { Tab, Box, CircularProgress, Typography } from '@material-ui/core';
import { Maybe } from 'src/@types';
import { CardHeader } from 'src/components/shared/Card';
import { BarChart, Props as ChartProps } from '../UtilizationBarGraph';
import { StyledTabs } from '../StyledTabs';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

export type DateRange = {
  startDate: string;
  endDate: string;
};

export enum UtilizationChartTab {
  kWh = 'kWh',
  Income = 'income',
}

const formats = new Map<UtilizationChartTab, ValueFormat<number>>([
  [UtilizationChartTab.kWh, (v) => `${v}kWh`],
  [UtilizationChartTab.Income, (v) => `$${v}`],
]);

export type Props = {
  title: string;
  className?: string;
  id?: string;
  caption?: string;
  loading?: boolean;
  'data-testid'?: string;
  siteName?: Maybe<string>;
  chargerName?: Maybe<string>;
  dateRange: DateRange;
  data: ChartProps['data'];
  selectedTab?: UtilizationChartTab;
  onTabChanged?: (tab: UtilizationChartTab) => void;
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .remove-width {
    width: unset;
  }

  .remove-transform {
    text-transform: none;
  }
`;

export const UtilizationBarChartCard: React.FC<Props> = ({
  title,
  className,
  id,
  caption,
  siteName,
  chargerName,
  dateRange,
  data,
  selectedTab = UtilizationChartTab.kWh,
  onTabChanged,
  loading = false,
  ...props
}) => {
  const { track } = useAnalytics();
  const handleChangeTab = (event: React.ChangeEvent<unknown>, newTab: string) =>
    onTabChanged?.(newTab as UtilizationChartTab);

  const noData = !data.length;
  const captionText = caption || 'Total consumption by day';

  // convert iso date string to MM/DD/YYYY format
  const dateString = (date: string) => {
    return moment(date).format('M/D/YYYY');
  };

  return (
    <div id={id} className={className} data-testid={props['data-testid'] || id}>
      <CardHeader
        title={title}
        subtitle={
          <Box component="span" display="flex" gridGap={16}>
            {siteName && (
              <>
                <span>{siteName}</span>
                <span>|</span>
              </>
            )}
            {chargerName && (
              <>
                <span>{chargerName}</span>
                <span>|</span>
              </>
            )}
            {noData ? (
              <Fragment />
            ) : (
              <span>
                {dateString(dateRange.startDate)} - {dateString(dateRange.endDate)}
              </span>
            )}
          </Box>
        }
      />

      <HeaderWrapper>
        <StyledTabs className="remove-width" tab={selectedTab} handleChangeTab={handleChangeTab}>
          <Tab
            className="remove-transform"
            label="kWh"
            value={UtilizationChartTab.kWh}
            data-testid={`${props['data-testid'] || id || className}-tab-button-kWh`}
            onClick={() => track(events.utilizationBarChartCard.CLICKED_KWH_TAB)}
          />
          <Tab
            className="remove-transform"
            label="Income"
            value={UtilizationChartTab.Income}
            data-testid={`${props['data-testid'] || id || className}-tab-button-Income`}
            onClick={() => track(events.utilizationBarChartCard.CLICKED_INCOME_TAB)}
          />
        </StyledTabs>
      </HeaderWrapper>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={400}>
          <CircularProgress />
        </Box>
      ) : noData ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={400} data-testid={'no-data'}>
          <Typography variant="body1">No data for this date range</Typography>
        </Box>
      ) : (
        <BarChart
          captionText={captionText}
          data={data}
          color={'#3F657C'}
          height={400}
          id={`${id || className}-util-bar`}
          data-testid={`${props['data-testid'] || id || className}-util-bar`}
          className={`${id || className}-util-bar`}
          valueFormat={formats.get(selectedTab)}
        />
      )}
    </div>
  );
};
