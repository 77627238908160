import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { ModalProps } from 'src/@types/client';
import { titleCase } from '../../../../lib/helpers';
import { StyledModal } from '../../../shared/StyledModal';
import { Styled } from './styles';

interface Props {
  open: boolean;
  className?: string;
  action?: string;
  closeModal?: () => void;
  updateCardsStatus?: (fn: (() => void) | undefined) => void;
  unassignCards?: () => void;
  activateCards?: () => void;
  deactivateCards?: () => void;
  reserveCards?: () => void;
  suspendCards?: () => void;
  markCardsAsSent?: () => void;
  cards?: unknown[];
  modalProps?: ModalProps;
}

export const ConfirmCardsModal: React.FC<Props> = (props) => {
  const className = 'ConfirmCardsModal';
  const {
    action,
    closeModal,
    updateCardsStatus,
    unassignCards,
    activateCards,
    deactivateCards,
    reserveCards,
    suspendCards,
    markCardsAsSent,
    cards,
  } = props;

  const onCancelClick = () => {
    closeModal?.();
  };
  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  let updateFunction: (() => void) | undefined = () => {};
  switch (action) {
    case 'sent':
      updateFunction = markCardsAsSent;
      break;
    case 'activate':
      updateFunction = activateCards;
      break;
    case 'deactivate':
      updateFunction = deactivateCards;
      break;
    case 'unassign':
      updateFunction = unassignCards;
      break;
    case 'reserve':
      updateFunction = reserveCards;
      break;
    case 'suspend':
      updateFunction = suspendCards;
      break;
    default:
      break;
  }

  return (
    <StyledModal
      className={clsx(customClass, 'modal')}
      onClose={onCancelClick}
      data-testid="confirm-cards-modal"
      open={props.open}
      title={`${titleCase(action as string)} Cards`}
      secondaryButtonText="Cancel"
      secondaryButtonClick={onCancelClick}
      onClick={() => {
        updateCardsStatus?.(updateFunction);
        onCancelClick();
      }}
      primaryButtonText="Confirm"
    >
      <Styled className={className}>
        <Typography className={className} component="p">
          {action === 'sent' && !!cards?.length
            ? `Are you sure you want mark the following ${cards.length} card(s) as sent?`
            : `Are you sure you want to ${action} the following cards?`}
        </Typography>
        {action !== 'sent' && action !== 'deactivate' && (
          <Fragment>
            <div className={clsx(className, 'confirm-header')}>
              <span className={className}>External</span>
              <span className={className}>Status</span>
            </div>
            <Paper className={clsx(className, 'confirm-table')}>
              <Table className={className} data-testid="confirm-cards-modal-cards-table">
                <TableBody>
                  {cards && !_.isEmpty(cards) ? (
                    cards.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell>{_.get(row, 'externalNumber', '')}</TableCell>
                        <TableCell>{titleCase(_.get(row, 'cardStatus', ''))}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography className={className} variant="body2">
                      No cards selected.
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Fragment>
        )}
        {action === 'deactivate' && (
          <Fragment>
            <div className={clsx(className, 'confirm-header')}>
              <span className={className}>External</span>
              <span className={className}>Name</span>
            </div>
            <Paper className={clsx(className, 'confirm-table')}>
              <Table className={className} data-testid="confirm-cards-modal-cards-table">
                <TableBody>
                  {cards && !_.isEmpty(cards) ? (
                    cards.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell>{_.get(row, 'externalNumber', '')}</TableCell>
                        <TableCell>{`${_.get(row, 'driver.firstName', '')} ${_.get(
                          row,
                          'driver.lastName',
                          '',
                        )}`}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography className={className} variant="body2">
                      No cards selected.
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </Paper>
            <Typography className={clsx(className, 'label')} variant="subtitle1" component="p">
              <sup>*</sup> Deactivating cards is a permanent action and cannot be undone.
            </Typography>
          </Fragment>
        )}
      </Styled>
    </StyledModal>
  );
};
