import { useLazyQuery } from '@apollo/client';
import { Map } from '@evgo/react-material-components';
import _ from 'lodash';
import React, { useState } from 'react';
import { Query, Site, SitesWithMeta } from 'src/@types';
import config from 'src/config';
import { listMappedSites } from '../../../../apollo/queries/sites';
import { ChargerMarkerInfo } from './ChargerMarkerInfo';
import { SiteClusterInfo } from './SiteClusterInfo';

export interface Props {
  center: {
    latitude: number;
    longitude: number;
  };
  listData: Query; // { listSites: SitesWithMeta };
  loading: boolean;
  onDrag: () => void;
  onMount: () => void;
  onZoom: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapRef: React.MutableRefObject<React.FC<any> | undefined>;
}

interface MapList {
  icon: string;
  infoComponent: JSX.Element;
  open: boolean;
  onClick: () => void;
  onClose: () => void;
  lat: number;
  lng: number;
}

/** Chargers map view component. */
export const ChargersMap: React.FC<Props> = (props) => {
  const { center } = props;
  const [mapSelected, setMapSelected] = useState<Site>();

  const [listSites, { data: clusterData }] = useLazyQuery<Query>(listMappedSites, {
    fetchPolicy: 'network-only',
  });

  const { edges } = props.listData?.listSites as SitesWithMeta;
  let mapList = [] as MapList[];
  if (!props.loading && !_.isEmpty(edges)) {
    mapList = edges
      ?.filter((site) => site?.chargers?.total)
      .map((marker) => {
        if (!marker) {
          return;
        }

        // const stationsAvailable = [];
        let onMarkerClick = () => setMapSelected(marker);
        const icon = 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-b.png';

        // marker.stations.forEach((station) => {
        //   if (!station.stationPorts.filter(port => port.stationPortStatus !== 'AVAILABLE').length) {
        //     stationsAvailable.push(station);
        //   }
        // });

        if (mapSelected?.altId === marker.altId) {
          onMarkerClick = () => {
            setMapSelected(undefined);
          };
        }
        // if (stationsAvailable.length) icon = 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-a.png';

        return {
          icon,
          infoComponent: <ChargerMarkerInfo site={mapSelected as Site} />,
          open: mapSelected?.altId === marker.altId,
          onClick: () => onMarkerClick(),
          onClose: () => onMarkerClick(),
          lat: marker.latitude,
          lng: marker.longitude,
        };
      }) as MapList[];
  }

  const sites = clusterData?.listSites?.edges;
  const clusterWindow = <SiteClusterInfo sites={sites as Site[]} />;

  return (
    <section>
      <Map
        googleMapURL={config.env.google.apiKey}
        markers={mapList || []}
        clusterInfoWindow={sites?.length ? clusterWindow : null}
        onOpenCluster={listSites}
        onDrag={props.onDrag}
        onMount={props.onMount}
        onZoom={props.onZoom}
        position={center}
        ref={props.mapRef}
        styles={{ display: 'flex', flexDirection: 'column', flex: '1', width: '100%', height: '594px' }}
        defaultZoom={12}
      />
    </section>
  );
};
