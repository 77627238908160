import { ApolloError } from '@apollo/client';

export interface ApolloErrorExtensions {
  code: string;
  message: string;
}

export type Handlers = {
  codes: string[];
  message: string;
};

export type Input = {
  error: ApolloError;
  handlers: Handlers[];
  defaultMessage: string;
};

export function errorMessageHandler({ error, handlers, defaultMessage }: Input): string {
  const errors = error.graphQLErrors.filter((test) => test.extensions).map((err) => err.extensions);
  for (const extension of errors) {
    const foundHandler: Handlers | undefined = handlers.find((handler) =>
      handler.codes.find((code) => code === extension.code),
    );
    if (foundHandler?.message) {
      return foundHandler.message;
    }
  }
  return defaultMessage;
}
