import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { FalconConstraint, Maybe, Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listFalconConstraints } from '../../../../../../apollo/queries/options';
import { useBlurDropdown } from '../../../../../../lib/hooks';
import { initialValues } from '../../initialValues';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  index: number;
  onDeletePlanProduct: (v: number) => void;
  id?: string;
  className?: string;
}

/**
 * Plan product component
 */
export const PlanProduct: React.FC<Props> = ({ className: parentClass, id: parentId, index, onDeletePlanProduct }) => {
  const id = _.kebabCase('PlanProduct');
  const className = id;
  const { errors, handleBlur, handleChange, isSubmitting, touched, values, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const { products } = values;
  const product = products[index];
  const { data: planProductTypeOptions, loading: planProductTypeOptionsLoading } = useQuery<Query>(
    listFalconConstraints,
    {
      variables: {
        optionsInput: {
          filter: {
            tableName: {
              eq: 'plans',
            },
            columnName: {
              eq: 'product_type',
            },
          },
        },
      },
    },
  );
  const { data: subscriptionReoccurrenceOptions, loading: subscriptionReoccurrenceOptionsLoading } = useQuery<Query>(
    listFalconConstraints,
    {
      variables: {
        optionsInput: {
          filter: {
            tableName: {
              eq: 'plans',
            },
            columnName: {
              eq: 'subscription_reoccurrence',
            },
          },
        },
      },
    },
  );
  const { data: creditReoccurrenceOptions, loading: creditReoccurrenceOptionsLoading } = useQuery<Query>(
    listFalconConstraints,
    {
      variables: {
        optionsInput: {
          filter: {
            tableName: {
              eq: 'plans',
            },
            columnName: {
              eq: 'credit_reoccurrence',
            },
          },
        },
      },
    },
  );
  const { data: planExpPolicyOptions, loading: planExpPolicyOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'plans',
          },
          columnName: {
            eq: 'credit_exp_policy',
          },
        },
      },
    },
  });

  let productTypeOptions: LabelValue<number>[] = [];
  let subReoccurrenceOptions: LabelValue<number>[] = [];
  let credReoccurrenceOptions: LabelValue<number>[] = [];
  let creditExpPolicyOptions: LabelValue<number>[] = [];

  const shape = (v: Maybe<FalconConstraint>): LabelValue<number> => ({
    label: v?.columnText || '',
    value: Number(v?.id),
  });

  if (!planProductTypeOptionsLoading)
    productTypeOptions = (planProductTypeOptions?.listFalconConstraints?.edges || []).map(shape);
  if (!subscriptionReoccurrenceOptionsLoading)
    subReoccurrenceOptions = (subscriptionReoccurrenceOptions?.listFalconConstraints?.edges || []).map(shape);
  if (!creditReoccurrenceOptionsLoading)
    credReoccurrenceOptions = (creditReoccurrenceOptions?.listFalconConstraints?.edges || []).map(shape);
  if (!planExpPolicyOptionsLoading)
    creditExpPolicyOptions = (planExpPolicyOptions?.listFalconConstraints?.edges || []).map(shape);

  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;

  const handleBlurDropdown = useBlurDropdown();

  return (
    <StyledAccordion id={parentId} className={customClass} defaultExpanded={true}>
      <Divider />

      <AccordionDetails className={`${className} product`}>
        <Dropdown
          className={`${className} half`}
          disabled={isSubmitting}
          error={
            !!(_.get(touched, `products[${index}].productTypeId`) && _.get(errors, `products[${index}].productTypeId`))
          }
          helpertext={
            _.get(touched, `products[${index}].productTypeId`) && _.get(errors, `products[${index}].productTypeId`)
          }
          id={`${id}-${index}-type-input`}
          label="Product Type *"
          labelProps={{ className }}
          name={`products[${index}].productTypeId`}
          onBlur={handleBlurDropdown}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              `products[${index}].productType`,
              _.upperCase((_.find(productTypeOptions, { value: target.value }) as LabelValue<number>)?.label),
            );
            setFieldValue(`products[${index}].productTypeId`, target.value);
          }}
          options={productTypeOptions}
          value={
            _.get(values, `products[${index}].productTypeId`) ||
            _.get(_.find(productTypeOptions, { label: 'Credit' }), 'value')
          }
          data-testid={`${id}-${index}-type-input`}
        />

        <div className={`${className} half`}>
          <Button
            className={className}
            id={`${id}-${index}-delete-plan-product-button`}
            onClick={() => onDeletePlanProduct(index)}
            data-testid={`${id}-${index}-delete-plan-product-button`}
          >
            <Delete color="primary" />
            DELETE
          </Button>
        </div>

        {_.get(product, 'productType') === 'SUBSCRIPTION' && (
          <Fragment>
            <TextInfo
              className={`${className} half`}
              disabled={isSubmitting}
              error={
                !!(
                  _.get(touched, `products[${index}].subscriptionFee`) &&
                  _.get(errors, `products[${index}].subscriptionFee`)
                )
              }
              helpertext={
                _.get(touched, `products[${index}].subscriptionFee`) &&
                _.get(errors, `products[${index}].subscriptionFee`)
              }
              id={`${id}-${index}-subscription-fee-input`}
              label="Subscription Fee *"
              labelProps={{ className }}
              name={`products[${index}].subscriptionFee`}
              onBlur={handleBlur}
              onChange={handleChange}
              adornmentStart="$"
              value={_.get(product, 'subscriptionFee')}
              data-testid={`${id}-${index}-subscription-fee-input`}
            />

            <Dropdown
              className={`${className} half`}
              disabled={isSubmitting}
              error={
                !!(
                  _.get(touched, `products[${index}].subscriptionReoccurrenceId`) &&
                  _.get(errors, `products[${index}].subscriptionReoccurrenceId`)
                )
              }
              helpertext={
                _.get(touched, `products[${index}].subscriptionReoccurrenceId`) &&
                _.get(errors, `products[${index}].subscriptionReoccurrenceId`)
              }
              id={`${id}-${index}-subscription-reoccurrence-input`}
              label="Subscription Recurrence *"
              labelProps={{ className }}
              name={`products[${index}].subscriptionReoccurrenceId`}
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={subReoccurrenceOptions}
              value={
                _.get(product, 'subscriptionReoccurrenceId') ||
                _.get(_.find(subReoccurrenceOptions, { label: 'One Time' }), 'value')
              }
              data-testid={`${id}-${index}-subscription-reoccurrence-input`}
            />
          </Fragment>
        )}

        {_.get(product, 'productType') === 'CREDIT' && (
          <Fragment>
            <TextInfo
              className={`${className} quarter`}
              disabled={isSubmitting}
              error={
                !!(
                  _.get(touched, `products[${index}].creditAmount`) && _.get(errors, `products[${index}].creditAmount`)
                )
              }
              helpertext={
                _.get(touched, `products[${index}].creditAmount`) && _.get(errors, `products[${index}].creditAmount`)
              }
              id={`${id}-${index}-credit-amount-input`}
              label="Credit Amount *"
              labelProps={{ className }}
              name={`products[${index}].creditAmount`}
              onBlur={handleBlur}
              onChange={handleChange}
              adornmentStart="$"
              value={_.get(product, 'creditAmount')}
              data-testid={`${id}-${index}-credit-amount-input`}
            />

            <Dropdown
              className={`${className} quarter`}
              disabled={isSubmitting}
              error={
                !!(
                  _.get(touched, `products[${index}].creditReoccurrenceId`) &&
                  _.get(errors, `products[${index}].creditReoccurrenceId`)
                )
              }
              helpertext={
                _.get(touched, `products[${index}].creditReoccurrenceId`) &&
                _.get(errors, `products[${index}].creditReoccurrenceId`)
              }
              id={`${id}-${index}-credit-reoccurrence-input`}
              label="Credit Recurrence *"
              labelProps={{ className }}
              name={`products[${index}].creditReoccurrenceId`}
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={credReoccurrenceOptions}
              value={
                _.get(product, 'creditReoccurrenceId') ||
                _.get(_.find(credReoccurrenceOptions, { label: 'One Time' }), 'value')
              }
              data-testid={`${id}-${index}-credit-reoccurrence-input`}
            />

            <Dropdown
              className={`${className} quarter`}
              disabled={isSubmitting}
              error={
                !!(
                  _.get(touched, `products[${index}].creditExpPolicyId`) &&
                  _.get(errors, `products[${index}].creditExpPolicyId`)
                )
              }
              helpertext={
                _.get(touched, `products[${index}].creditExpPolicyId`) &&
                _.get(errors, `products[${index}].creditExpPolicyId`)
              }
              id={`${id}-${index}-credit-exp-policy-input`}
              label="Credit Exp. Behavior *"
              labelProps={{ className }}
              name={`products[${index}].creditExpPolicyId`}
              onBlur={handleBlurDropdown}
              onChange={handleChange}
              options={creditExpPolicyOptions}
              value={
                _.get(product, 'creditExpPolicyId') ||
                _.get(_.find(creditExpPolicyOptions, { label: 'Carry Over' }), 'value')
              }
              data-testid={`${id}-${index}-credit-exp-policy-input`}
            />

            <TextInfo
              className={`${className} quarter`}
              disabled={isSubmitting}
              error={
                !!(
                  _.get(touched, `products[${index}].creditOneTimeAmount`) &&
                  _.get(errors, `products[${index}].creditOneTimeAmount`)
                )
              }
              helpertext={
                _.get(touched, `products[${index}].creditOneTimeAmount`) &&
                _.get(errors, `products[${index}].creditOneTimeAmount`)
              }
              id={`${id}-${index}-credit-one-time-amount-input`}
              label="Credit One-Time Amount *"
              labelProps={{ className }}
              name={`products[${index}].creditOneTimeAmount`}
              onBlur={handleBlur}
              onChange={handleChange}
              adornmentStart="$"
              value={_.get(product, 'creditOneTimeAmount')}
              data-testid={`${id}-${index}-credit-one-time-amount-input`}
            />
          </Fragment>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};
