import { gql } from '@apollo/client';

export const updateAccount = gql`
  mutation updateAccount($input: UpdateAccountInput) {
    account: updateAccount(input: $input) {
      altId
      evcId
      mailingAddress {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      billingAddress {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      enrolledOn
    }
  }
`;
