import { useApolloClient } from '@apollo/client';
import { Box, CircularProgress, useMediaQuery } from '@material-ui/core';
import { useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { OperatorPortalRole, RoleApp } from 'src/@types/shared';
import { Identify, identify, init } from 'src/amplitude';
import { AccessDenied } from 'src/components/shared/AccessDenied';
import { FLAG_OP_EXTEND, FLAG_OP_EXTEND_IN_MAINTENANCE, useFlag } from 'src/config/featureFlag';
import { useRoles } from 'src/lib/hooks/useRoles';
import { useGetExtendUser } from 'src/lib/hooks/useGetUserHosts';
import config from '../../../config';
import { auth, signOut } from '../../../firebase';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { Header } from '../Header';
import { Modals } from '../Modals';
import { Navigation } from '../Navigation';
import { AppRoutes } from '../Routes';
import { Snackbar } from '../Snackbar';
import { InMaintenanceView } from './InMaintenance';
import { OfflineView as ExtendOfflineView } from './Offline';
import { StyledMain } from './styles';
import { User } from '@firebase/auth';
import { ModalProvider } from 'src/contexts/ModalContext';
import { SnackbarProvider } from 'src/contexts/SnackBarContext';

export const drawerWidth = 200;

const useIdleTimeout = ({ logout = () => {}, user = {} as User | null | undefined }) => {
  const idleTimeout = 60000 * 30;
  const [isIdle, setIdle] = useState(false);
  const handleIdle = () => {
    setIdle(true);
    if (user) logout();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500,
    crossTab: true,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};

const OperatorPortalContainer: React.FC = () => {
  const [user] = useAuthState(auth);
  useEffect(() => {
    if (!user) return;

    init(config.env.amplitude.apiKey, user?.email || '');
    const identifyObj = new Identify();
    identifyObj.set('role', 'superAdmin');
    identify(identifyObj);
  }, [user]);
  return <AppRoutes />;
};

export const ExtendContainer: React.FC = () => {
  const { host, loading } = useGetExtendUser();
  const [user] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (!user || !host) return;

    init(config.env.amplitude.apiKey, user?.email || '');
    const identifyObj = new Identify();
    identifyObj.set('role', 'partnerAdmin');
    identifyObj.setOnce('host', host?.hostName || '');
    identify(identifyObj);
  }, [user, host, loading]);

  const enableExtend = useFlag(FLAG_OP_EXTEND);
  const extendInMaintenance = useFlag(FLAG_OP_EXTEND_IN_MAINTENANCE);

  if (loading) {
    return (
      <Box m="auto">
        <CircularProgress />
      </Box>
    );
  }

  return !host ? (
    <AccessDenied />
  ) : (
    <>{extendInMaintenance ? <InMaintenanceView /> : enableExtend ? <AppRoutes /> : <ExtendOfflineView />}</>
  );
};

export const Container: React.FC = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const client = useApolloClient();
  const isLargeScreen = useMediaQuery('(min-width:1400px)');
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const { flagsReady } = useFlagsStatus();
  const { hasRole, loading: loadingRoles } = useRoles();
  const isPartnerAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.PartnerAdmin);

  const loading = loadingRoles || !flagsReady;

  const updateUnleashContext = useUnleashContext();
  const prevUserRef = useRef<User | null>(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (!currentUser && prevUserRef.current) {
        client.clearStore();
        navigate('/login');
        window.location.reload();
      }

      prevUserRef.current = currentUser;
    });

    return () => unsubscribe();
  }, [client, navigate]);

  useEffect(() => {
    if (user?.email) {
      updateUnleashContext({ userId: user?.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email]);

  const onLogout = useCallback(async () => {
    try {
      await signOut();
    } catch (error) {
      console.log('Sign out error:', error); // eslint-disable-line no-console
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, navigate]);

  useIdleTimeout({ logout: onLogout, user });

  const handleDrawerToggle = () => setIsMobileOpen(!isMobileOpen);

  const enableExtend = useFlag(FLAG_OP_EXTEND);
  const extendInMaintenance = useFlag(FLAG_OP_EXTEND_IN_MAINTENANCE);
  const disableNavigation = isPartnerAdmin && (!enableExtend || extendInMaintenance);

  return (
    <>
      <ModalProvider>
        <SnackbarProvider>
          <Header
            user={user}
            onLogout={onLogout}
            isLargeScreen={isLargeScreen}
            handleDrawerToggle={handleDrawerToggle}
            hideMenuButton={disableNavigation}
          />
          {disableNavigation ? null : (
            <Navigation
              isLargeScreen={isLargeScreen}
              handleDrawerToggle={handleDrawerToggle}
              isMobileOpen={isMobileOpen}
            />
          )}
          {loading ? (
            <Box m="auto">
              <CircularProgress />
            </Box>
          ) : (
            <StyledMain $hasLeftNav={isLargeScreen && Boolean(user)}>
              {isPartnerAdmin ? <ExtendContainer /> : <OperatorPortalContainer />}
            </StyledMain>
          )}
          <Snackbar />
          <ConfirmationDialog />
          <Modals />
        </SnackbarProvider>
      </ModalProvider>
    </>
  );
};
