import { Divider, StatBlock, theme } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import config from '../../../../../config';
import { titleCase } from '../../../../../lib/helpers';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plans status component.
 */
export const PlanStatus: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanStatus');
  const className = id;
  const { status } = useFormikContext();

  let customClass = className;
  let statusColor = theme.palette.text.secondary;
  if (parentClass) customClass += ` ${parentClass}`;

  if (status && status.planId === 'ACTIVE') statusColor = '#44A047';
  else statusColor = '#44A047';

  return (
    <StyledAccordion className={`${customClass} panel-status`} id={parentId} defaultExpanded={true}>
      <AccordionSummary className={className} component="header">
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            Plan Status
          </Typography>
          <Typography className={className} variant="caption">
            Last updated 2 hours ago
          </Typography>
        </div>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={className}>
        <StatBlock id={`${id}-plan-status`} color={statusColor} title="Status">
          {titleCase(status && status.status) || 'Unknown'}
        </StatBlock>

        <Divider vertical />

        <StatBlock id={`${id}-customers-enrolled`} color={config.colors.accentBlue} title="Customers Enrolled">
          2,627
        </StatBlock>

        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} title="Chargers Applied">
          1,034
        </StatBlock>

        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} title="User Enrollment">
          43%
        </StatBlock>
      </AccordionDetails>
    </StyledAccordion>
  );
};
