import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { Box, CircularProgress } from '@material-ui/core';

export type PageContentProps = {
  pageHeader?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  hasFloatingPageActions?: boolean;
  className?: string;
  'data-testid'?: string;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${theme.spacing(5, 5)};
`;

export const PageContent: React.FC<PageContentProps> = ({
  className,
  breadcrumbs,
  pageHeader,
  loading,
  children,
  hasFloatingPageActions,
  ...props
}) => {
  if (loading)
    return (
      <Box m="auto">
        <CircularProgress />
      </Box>
    );

  return (
    <Container className={clsx('PageContent-root', className)} {...props}>
      {breadcrumbs && <Box mb={3}>{breadcrumbs}</Box>}
      {pageHeader}
      <Box mb={hasFloatingPageActions ? 16 : 0}>{children}</Box>
    </Container>
  );
};
