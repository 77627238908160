import React from 'react';
import { Box, Button, Stepper as MuiStepper, Step, StepLabel, Typography } from '@material-ui/core';
import { DataContext } from '../DataContext';
import { useDataContext } from '../DataContext/useDataContext';

type Props = {
  steps?: string[];
};

export const Stepper: React.FC<Props> = ({ steps = [] }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [dataRef, setData] = useDataContext();

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <DataContext context="stepper">
      <div ref={dataRef}>
        <MuiStepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: Record<string, unknown> = {};
            const labelProps: Record<string, unknown> = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </MuiStepper>
        {activeStep === steps.length ? (
          <Box sx={{ textAlign: 'right' }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset} {...setData('reset-button')}>
              Reset
            </Button>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'right' }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} {...setData('back-button')}>
              Back
            </Button>

            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} {...setData('skip-button')}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext} {...setData('next-button')}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        )}
      </div>
    </DataContext>
  );
};
