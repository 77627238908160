import { theme } from '@evgo/react-material-components';
import { Accordion } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  section {
    box-sizing: border-box;
    width: calc(100% - 32px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};
  }

  div.header {
    padding: ${`0 ${spacing(3)}px ${spacing()}px`};
  }

  div {
    &.container {
      display: flex;
      flex: 1 1 100%;

      .controls {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: ${spacing(4)}px;
      }
    }

    &.contract-list {
      max-height: 534px;
      overflow: auto;
    }
  }

  .contract-list {
    padding: 0;

    .edit-contract {
      color: ${colors.accentBlue};

      :hover {
        cursor: pointer;
      }
    }

    a {
      &.plan-link {
        color: ${colors.accentBlue};
      }
    }
  }

  .status-menu {
    position: absolute;
  }

  h2 {
    color: ${colors.accentBlue};
  }

  table {
    td,
    th {
      padding: ${`${spacing(2)}px ${spacing(0.5)}px`};

      &.checkbox {
        > span {
          padding: 0;
        }
      }

      &:first-child {
        padding-left: ${spacing(3)}px;
      }

      &:last-child {
        padding-right: ${spacing(3)}px;
      }
    }

    td {
      position: relative;
    }

    a {
      &.contract-link {
        color: ${colors.accentBlue};
      }
    }
  }
`;
