import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const {
  spacing,
  palette,
  shape: { borderRadius },
} = theme;

export const Styled = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin: ${`${spacing(3)}px ${spacing(3) / 2}px 0`};
  border: 1px solid ${palette.text.disabled};
  border-radius: ${borderRadius}px;
  padding: ${`0 ${spacing(3) / 2}px ${spacing(3)}px`};
  > header {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    margin: ${`${spacing(3)}px ${spacing(3) / 2}px 0`};

    > .form-control,
    > .select {
      flex-basis: 25%;
      margin: ${`0 ${spacing()}px -${spacing(2.5)}px`};
    }

    > .controls {
      width: ${`${spacing(40)}px`};
      display: flex;
      justify-content: flex-end;

      > button {
        margin-left: auto;
      }
    }

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }

  > label {
    margin: ${`${spacing(2.5)}px ${spacing(3) / 2}px 0`};

    > span {
      padding: ${`0 ${spacing(3) / 2}px 0 0`};
    }
  }
  plan-element-component: {
    margin-top: ${spacing(20)}px;
  }
  .customDropdown {
    max-height: 142px;

    overflow-y: auto;

    .MuiButton-root {
      text-transform: none;
    }
  }
`;
