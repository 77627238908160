import { gql } from '@apollo/client';

export const listTags = gql`
  query listTags($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      edges {
        altId
        tagName
        description
        chargers {
          total
        }
        filters {
          criteria
          exclude
          values {
            label
            valid
            value
          }
        }
      }
      page
      pageSize
      total
      sort {
        tagName
      }
      search {
        tagName {
          iLike
        }
      }
    }
  }
`;
