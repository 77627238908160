import { gql } from '@apollo/client';

export const listChargerModelOptions = gql`
  query listChargerModelOptions {
    listChargerModelOptions {
      altId
      modelName
    }
  }
`;
