import { EvsesWithMeta, Maybe } from 'src/@types';

export default (evses: Maybe<Partial<EvsesWithMeta>>) => {
  const connectorsReservable: boolean[] = [];
  evses?.edges?.forEach((evse) => {
    evse?.connectors?.edges?.forEach((connector) => {
      connectorsReservable.push(connector?.reservable || false);
    });
  });
  return Boolean(connectorsReservable.includes(true));
};
