import { AccountStatus } from 'src/@types';

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  altId: '',
  accountStatus: AccountStatus.Active,
};
