import { gql } from '@apollo/client';

export const listGeographicalRegions = gql`
  query listGeographicalRegions($usaInput: ListGeographicalRegionsInput!, $canadaInput: ListGeographicalRegionsInput!) {
    listGeographicalRegionsUsa: listGeographicalRegions(input: $usaInput) {
      total
      edges {
        value: abbreviation
        label: name
      }
    }
    listGeographicalRegionsCanada: listGeographicalRegions(input: $canadaInput) {
      total
      edges {
        value: abbreviation
        label: name
      }
    }
  }
`;
