import { gql } from '@apollo/client';

export const listSitesConsumptionForExtend = gql`
  query listSitesConsumptionForExtend($input: ListSitesConsumptionForExtendInput!) {
    listSitesConsumptionForExtend(input: $input) {
      edges {
        address
        siteId
        siteName
        siteStatus
        administrativeArea
        siteLatitude
        siteLongitude
        totalChargers
        totalSessions
        totalSessionsCost
        totalSessionsPower
        averageSessionPower
        totalSessionsTime
        averageSessionTime
      }
      page
      pageSize
    }
  }
`;
