import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  box-sizing: border-box;
  width: calc(100% - ${spacing(4)}px);
  margin: 32px 16px 0 !important;

  header {
    padding: ${`0 ${spacing(3)}px`};
  }

  h2 {
    color: ${colors.accentBlue};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: ${spacing()}px;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: 0 ${`${spacing()}`}px;

        .checkbox-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          h3 {
            font-weight: 300;
            padding: ${`${spacing() + 2}px 0`};
          }
          span {
            padding: ${`${spacing(1.5)}px ${spacing(0.5)}px ${spacing(1.5)}px ${spacing()}px`};
          }
        }

        label {
          width: 100%;
          margin-left: 0px;
        }

        h3 {
          width: calc(100% - ${spacing() * 2}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }

        hr {
          width: calc(100% + ${`${spacing(4)}px`});
          margin-left: ${`-${spacing(2)}px`};
        }

        .form-control,
        .select {
          width: calc(100% - ${spacing() * 2}px);
          padding: ${`${spacing(2)}px ${spacing() / 2}px 0`};

          &.half {
            width: calc(50% - ${spacing(2)}px);
          }

          &.third {
            width: calc(33.33% - ${spacing(2)}px);
          }

          &.fourth {
            width: calc(25% - ${spacing()}px);
          }

          &.dropdown {
            padding: ${`${spacing(2)}px ${spacing() / 2}px 0`};
          }

          label {
            transform: translate(${spacing(2)}px, ${spacing(3)}px) scale(1);

            &.prefilled,
            &[data-shrink='true'] {
              transform: translate(${spacing(1.2)}px, ${spacing(2)}px) scale(0.75);
            }
          }
        }

        table {
          border: 1px solid lightgrey;
          margin: ${`${spacing()}px ${spacing()}px ${spacing(3)}px`};
        }

        .select {
          padding: 0;
        }
      }
    }
  }
`;
