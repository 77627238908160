import { Checkbox, TableCell, Tooltip, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { NotificationPreferencesWithMeta } from 'src/@types';
import { InputPropsWithData } from 'src/@types/shared';
import { Styled as StyledTableRow } from './styles';

export interface Props {
  className?: string;
  id?: string;
  index: number;
  item: {
    id: string;
    onePrefRequired: boolean;
    notificationName: string;
    notificationDesc: string;
  };
}

export const DriverProfileNotification: React.FC<Props> = ({
  className: parentClass,
  id: parentId,
  index: i,
  item,
}) => {
  const id = _.kebabCase('DriverProfileNotification');
  const className = id;

  const { setFieldValue, values } = useFormikContext<{
    id: string;
    notificationPreferences: NotificationPreferencesWithMeta;
  }>();
  const notificationIndex = _.findIndex(_.get(values, 'notificationPreferences.edges', []), {
    driverId: values.id,
    notificationItemId: item.id,
  });
  const targetItem = (values?.notificationPreferences?.edges || []).find((x) => {
    return x?.notificationItemId === item.id;
  });

  // @TODO - what is logic to disable?
  const emailDisabled = false;
  const pushDisabled = false;
  const textDisabled = false;

  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;

  const handleChangeNotification = useCallback(
    (event) => {
      const { target } = event;
      const notification = target.getAttribute('data-notification');
      if (notificationIndex === -1) {
        setFieldValue(`notificationPreferences.edges`, [
          ...(values?.notificationPreferences?.edges || []),
          {
            driverId: values.id,
            emailOn: false,
            pushOn: false,
            smsOn: false,
            notificationItemId: item.id,
            [notification]: target.checked,
          },
        ]);
      } else {
        setFieldValue(`notificationPreferences.edges[${notificationIndex}].${notification}`, target.checked);
      }
    },
    [notificationIndex, setFieldValue, item, values],
  );

  return (
    <StyledTableRow id={parentId} key={item.id} className={customClass}>
      <TableCell className={className}>
        <Typography id={`${id}-${i}-item-name`} className={className} variant="h6">
          {_.get(item, 'notificationName', '')}
        </Typography>
        <Typography id={`${id}-${i}-item-description`} className={className}>
          {_.get(item, 'notificationDesc', '')}
        </Typography>
      </TableCell>

      {emailDisabled ? (
        <Tooltip
          className={className}
          title="This notification is required."
          enterDelay={200}
          leaveDelay={200}
          placement="top"
        >
          <TableCell className={`${className} checkbox-cell`}>
            <Checkbox
              checked={Boolean(targetItem?.emailOn)}
              className={className}
              disabled={emailDisabled}
              inputProps={
                { id: `${id}-${i}-preference-email-checkbox`, 'data-notification': 'emailOn' } as InputPropsWithData
              }
              onChange={handleChangeNotification}
            />
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell className={`${className} checkbox-cell`}>
          <Checkbox
            checked={Boolean(targetItem?.emailOn)}
            className={className}
            disabled={emailDisabled}
            inputProps={
              { id: `${id}-${i}-preference-email-checkbox`, 'data-notification': 'emailOn' } as InputPropsWithData
            }
            onChange={handleChangeNotification}
          />
        </TableCell>
      )}

      {textDisabled ? (
        <Tooltip
          className={className}
          title="This notification is required."
          enterDelay={200}
          leaveDelay={200}
          placement="top"
        >
          <TableCell className={`${className} checkbox-cell`}>
            <Checkbox
              checked={Boolean(targetItem?.smsOn)}
              className={className}
              disabled={textDisabled}
              inputProps={
                { id: `${id}-${i}-preference-text-checkbox`, 'data-notification': 'smsOn' } as InputPropsWithData
              }
              onChange={handleChangeNotification}
            />
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell className={`${className} checkbox-cell`}>
          <Checkbox
            checked={Boolean(targetItem?.smsOn)}
            className={className}
            disabled={textDisabled}
            inputProps={
              { id: `${id}-${i}-preference-text-checkbox`, 'data-notification': 'smsOn' } as InputPropsWithData
            }
            onChange={handleChangeNotification}
          />
        </TableCell>
      )}

      {pushDisabled ? (
        <Tooltip
          className={className}
          title="This notification is required."
          enterDelay={200}
          leaveDelay={200}
          placement="top"
        >
          <TableCell className={`${className} checkbox-cell`}>
            <Checkbox
              checked={Boolean(targetItem?.pushOn)}
              className={className}
              disabled={pushDisabled}
              inputProps={
                { id: `${id}-${i}-preference-push-checkbox`, 'data-notification': 'pushOn' } as InputPropsWithData
              }
              onChange={handleChangeNotification}
            />
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell className={`${className} checkbox-cell`}>
          <Checkbox
            checked={Boolean(targetItem?.pushOn)}
            className={className}
            disabled={pushDisabled}
            inputProps={
              { id: `${id}-${i}-preference-push-checkbox`, 'data-notification': 'pushOn' } as InputPropsWithData
            }
            onChange={handleChangeNotification}
          />
        </TableCell>
      )}
    </StyledTableRow>
  );
};
