import { gql } from '@apollo/client';

export const listChargersConsumptionForExtend = gql`
  query listChargersConsumptionForExtend($input: ListChargersConsumptionForExtendInput!) {
    listChargersConsumptionForExtend(input: $input) {
      chargerId
      chargerName
      connectorMaxOutput
      connectorStatus
      connectorType
      connectorId
      evseId
      totalSessions
      totalSessionsPower
      totalSessionsTime
      lastSessionVendorId
      reservable
    }
  }
`;
