import React from 'react';
import { colors, Tabs } from '@material-ui/core';
import { Styled } from './styles';
import clsx from 'clsx';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  tab?: string;
  handleChangeTab?: (event: React.ChangeEvent<unknown>, newTab: string) => void;
}

export const StyledTabs: React.FC<Props> = ({ children, className: parentClass, tab, handleChangeTab = () => {} }) => {
  const className = 'tabs-component app-bar';

  return (
    <Styled className={clsx(className, parentClass)} elevation={0}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        TabIndicatorProps={{ style: { backgroundColor: `${colors.yellow['700']}` } }}
      >
        {children}
      </Tabs>
    </Styled>
  );
};
