import { gql } from '@apollo/client';

export const listPlansForContracts = gql`
  query listPlansForContracts($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      total
      page
      pageSize
      edges {
        altId
        createdAt
        enrollmentClose
        enrollmentOpen
        planName
        planStatus
        intervalUnit
        intervalLength
        planDuration
        terminationBehavior
      }
    }
  }
`;
