import { AppBar, Toolbar, Box, IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User } from 'firebase/auth';
import evgoLogo from '../../../assets/images/evgo-logo.svg';
import { Styled } from './styles';
import { HeaderMenu } from './HeaderMenu';
import { auth } from 'src/firebase';

interface Props {
  user?: User | null;
  hideMenuButton?: boolean;
  onLogout?: () => void;
  isLargeScreen?: boolean;
  handleDrawerToggle?: () => void;
}

export const Header: React.FC<Props> = ({
  user,
  hideMenuButton = false,
  onLogout,
  handleDrawerToggle,
  isLargeScreen,
}) => {
  const id = _.kebabCase('Header');
  const className = `${id}-component`;
  const [currentUser] = useAuthState(auth);

  return (
    <Styled>
      <AppBar>
        <Toolbar>
          {!isLargeScreen && currentUser && !hideMenuButton && (
            <IconButton
              className={`${className} menu-btn`}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link className={className} to="/">
            <img height="inherit" className={className} src={evgoLogo} alt="EVgo Logo" />
          </Link>

          <Box flexGrow={1} />

          <HeaderMenu user={user} onLogout={onLogout} />
        </Toolbar>
      </AppBar>
    </Styled>
  );
};
