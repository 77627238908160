import { gql } from '@apollo/client';

export const chargerCommand = gql`
  mutation chargerCommand($input: ChargerCommandInput!) {
    chargerCommand(input: $input) {
      success
      message
      value
    }
  }
`;
