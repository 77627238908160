import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Account, Query } from 'src/@types';
import { getAccount } from 'src/apollo/queries/accounts';
import { DriverRow } from './DriverRow';
import { Styled as StyledAccordion } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import _ from 'lodash';

export interface Props {
  className?: string;
}

const columns = [
  { key: 'lastName', label: 'Name' },
  { key: 'createdAt', label: 'Creation Date' },
  { key: 'phone', label: 'Mobile Phone' },
  { key: 'email', label: 'Email Address' },
  { key: 'status', label: 'Status' },
  { key: 'hasCard', label: 'Has Card' },
  { key: 'actions', label: '' },
];

/** Drivers List view component. */
export const AccountDrivers: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountDrivers');
  const { altId } = useParams();

  const className = 'accountdrivers-component';
  const { data, loading, refetch } = useQuery<Query>(getAccount, {
    variables: {
      accountInput: { altId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999, paranoid: false },
      cardsInput: { pageSize: 999 },
    },
    skip: !altId,
  });
  const { drivers, cards, ...account } = data?.getAccount || ({} as Account);

  const { setModalState } = useModalContext();

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledAccordion className={customClass} data-testid={id}>
      <Accordion>
        <AccordionSummary className={className} expandIcon={<ExpandMore />} component="header">
          <div className={`${className} container`}>
            <div className={`${className} title`}>
              <Typography className={className} variant="h6" component="h2">
                Drivers
              </Typography>
              <Typography className={className} variant="caption">
                {!loading && drivers && drivers.total ? `Currently viewing ${drivers.total}` : '0 Drivers'}
              </Typography>
            </div>
            <div className={`${className} controls`}>
              <Button
                id="add-driver-button"
                data-testid="add-driver-button"
                className={className}
                color="secondary"
                disabled={loading}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalState({
                    modalName: 'AccountDriverModal',
                    modalProps: { drivers, onSaved: refetch },
                    modalVisible: true,
                  });
                }}
                variant="contained"
              >
                Add Driver
              </Button>
            </div>
          </div>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={`${className} driver-list`}>
          <Table className={className}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.key} className={`${className} ${column.key}`} component="th">
                    <TableSortLabel disabled={true}>{column.label}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {!loading &&
                drivers?.edges?.map((driver, i) => {
                  return (
                    <DriverRow
                      key={i}
                      className={className}
                      accountAltId={altId || ''}
                      driver={driver}
                      drivers={drivers}
                      account={account}
                      cards={cards}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </StyledAccordion>
  );
};
