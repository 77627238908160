import { Formik } from 'formik';
import React from 'react';
import { createExtendUser, updateExtendUserHosts } from 'src/apollo/queries/extendPlus';
import { updateQuery } from '../../../../../../lib/helpers';
import { useFormikSubmit } from '../../../../../../lib/hooks';
import { AssignUserForm, initialValues } from './AssignUserForm';
import { validations } from './AssignUserForm/validations';
import { useModalContext } from 'src/contexts/ModalContext';
import { ExtendUser } from 'src/@types';
interface Props {
  open: boolean;
  className?: string;
}

type ModalPropsType = {
  fetchMore?: () => void;
  data?: ExtendUser;
};

export const AssignUserModal: React.FC<Props> = (props) => {
  const className = 'ExtendUserModal-component';
  const { modalProps } = useModalContext();

  const { fetchMore: callback, data } = (modalProps as ModalPropsType) || {};
  const { altId } = data || {};
  const callbackArgs = {
    updateQuery,
    variables: {
      input: { pageSize: 9999 },
    },
  };

  const transformValues = (values: typeof initialValues) => {
    const username = values?.username;
    const firebaseId = values?.firebaseId;
    const hostIds = values?.hostIds;

    return {
      ...(!!altId && { altId }),
      username,
      firebaseId,
      hostIds: [hostIds],
    };
  };

  const handleSubmit = useFormikSubmit('ExtendUser', createExtendUser, updateExtendUserHosts, {
    noRedirect: true,
    transformValues,
    callback,
    callbackArgs,
    initialValues,
    altId,
    transformError: () => 'This user is already in the system.',
  }) as never;

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <AssignUserForm className={className} selected={data} open={props.open} />
    </Formik>
  );
};
