/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${`${spacing()}px ${spacing()}px 0`};

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: ${spacing(2)}px;
    width: 100%;
    text-align: center;

    div {
      &.image-placeholder {
        display: flex;
        justify-content: center;
        background-color: #ededed;
        border: 2px solid #ffffff;

        .progress-bar {
          z-index: 999;
          width: 80%;
          align-self: center;
        }
      }
    }
  }

  .site-images-footer {
    display: flex;
    padding: ${`${spacing(2)}px 0 0`};

    div {
      &.image-error {
        align-self: center;
      }

      &.add-image {
        margin-left: auto;

        button {
          color: #fff;
          background-color: ${palette.secondary[700]};
          padding: ${`${spacing()}px ${spacing(4)}px`};
        }
      }
    }
  }
`;
