import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'src/firebase';
import { track as AMPLITUDE_TRACK } from '@amplitude/analytics-browser';

const PROD_HOST = 'operator.evgo.com';

export default function useAnalytics() {
  const [user] = useAuthState(auth);

  const track = (eventName: string, eventDetails?: Record<string, unknown>) => {
    const isProd = location.host === PROD_HOST;
    const email = user?.email;
    const ignoreEvent = isProd && email?.endsWith('@evgo.com');
    if (ignoreEvent) return;
    AMPLITUDE_TRACK(eventName, eventDetails);
  };
  return {
    track,
  };
}
