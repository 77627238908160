import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import { drawerWidth } from '.';

interface Props {
  $hasLeftNav: boolean;
}

export const StyledMain = styled.main<Props>`
  overflow: auto;
  flex: 1 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  margin: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom)
    var(--safe-area-inset-left);
  margin-top: ${theme.spacing(11)}px;
  height: calc(100% - ${theme.spacing(11)}px);
  padding-left: ${(props) => (props.$hasLeftNav ? drawerWidth : 0)}px;
  background-color: ${theme.palette.background.default};
`;
