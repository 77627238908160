import { Divider } from '@evgo/react-material-components';
import {
  Button,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { Contract, ContractsWithMeta } from 'src/@types';
import { Styled as StyledAccordion } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

const columns = [
  { key: 'contractId', label: 'Contract ID' },
  { key: 'planName', label: 'Plan Name' },
  { key: 'startDate', label: 'Start Date' },
  { key: 'endDate', label: 'End Date' },
  { key: 'contractType', label: 'Contract Type' },
  { key: 'status', label: 'Status' },
  { key: 'closureDate', label: 'Closure Date' },
];

export interface Props {
  contracts: ContractsWithMeta;
  loading: boolean;
  type: string;
  accountId: string;
  className?: string;
}

export const ContractsList: React.FC<Props> = (props) => {
  const id = _.kebabCase('ContractsList');
  const { contracts, loading, type, accountId } = props;
  const { setModalState } = useModalContext();
  const handleOpenContractModal = (contract: Contract | null) => {
    setModalState({
      modalName: 'AccountContractModal',
      modalProps: {
        accountId,
        contract,
      },
      modalVisible: true,
    });
  };

  const { className } = props;

  return (
    <StyledAccordion className={className} id={`${id}-expand-panel-toggle`}>
      <AccordionSummary className={`header`} expandIcon={<ExpandMore />} id={`${id}-expand-panel-header`}>
        <div className={`container`}>
          <div className={`title`}>
            <Typography variant="h6" component="h2">
              Contracts
            </Typography>
            <Typography variant="caption" id={`${id}-list-count`}>
              {!loading ? `Currently viewing ${_.get(contracts, 'total', 0)}` : 'Loading...'}
            </Typography>
          </div>

          {type === 'account' && (
            <div className={`controls`}>
              <Button
                id="add-contract-button"
                data-testid="add-contract-button"
                disabled={loading || !accountId}
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenContractModal(null);
                }}
                variant="contained"
              >
                Add Contract
              </Button>
            </div>
          )}
        </div>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`contract-list`}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key} className={`${column.key}`} component="th">
                  <TableSortLabel disabled={true}>{column.label}</TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading &&
              (contracts?.edges || []).map((contract, i) => {
                const startDate = moment(contract?.startDate).tz('UTC').format('MM/DD/YYYY');
                const endDate = contract?.endDate ? moment(contract?.endDate).tz('UTC').format('MM/DD/YYYY') : null;
                const contractType = contract?.plan?.terminationBehavior || '';

                const planAltId = contract?.plan?.altId || '';
                const planLink = (
                  <Link id={`${id}-contract-planName-link-${i}`} className={`plan-link`} to={`/plans/${planAltId}`}>
                    {_.get(contract, 'plan.planName', '')}
                  </Link>
                );

                // TODO: fix this
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let contractId = (contract as any)?.id || '';
                if (type === 'account') {
                  contractId = (
                    <span
                      id={`${id}-edit-contract-button-${i}`}
                      className={`edit-contract`}
                      onClick={() => {
                        handleOpenContractModal(contract);
                      }}
                    >
                      {_.get(contract, 'id', '')}
                    </span>
                  );
                }

                return (
                  <TableRow key={i}>
                    <TableCell id={`${id}-contract-id-${i}`}>{contractId}</TableCell>
                    <TableCell id={`${id}-contract-planName-${i}`}>{planLink}</TableCell>
                    <TableCell id={`${id}-contract-startDate-${i}`}>{startDate}</TableCell>
                    <TableCell id={`${id}-contract-endDate-${i}`}>{endDate}</TableCell>
                    <TableCell id={`${id}-contract-type-${i}`}>{contractType}</TableCell>
                    <TableCell id={`${id}-contract-status-${i}`}>{_.get(contract, 'status.columnText', '')}</TableCell>
                    <TableCell id={`${id}-contract-closureDate-${i}`}>{_.get(contract, 'closureDate', '')}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </StyledAccordion>
  );
};
