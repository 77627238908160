import { useRef, useCallback } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { Query, ListSessionsV2FilterInput } from 'src/@types';
import { listSessionsV2 as LIST_SESSIONS } from 'src/apollo/queries/sessions';
import { formatDate } from 'src/lib/helpers/formatDate';
import { formatDuration } from 'src/lib/helpers/formatDuration';
import config from 'src/config';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';

type Props = {
  filter: ListSessionsV2FilterInput;
};

const Styled = styled.div`
  .MuiButton-label {
    color: ${config.colors.white};
  }
  .MuiBox-root {
    color: ${config.colors.white};
  }
`;

export const ExportButton = ({ filter }: Props) => {
  const ref = useRef<CSVLink>();
  const snackbar = useSnackbar();

  const [listSessions, { data: sessions, loading }] = useLazyQuery<Query>(LIST_SESSIONS, {
    fetchPolicy: 'no-cache',
  });

  const onClick = useCallback(async () => {
    const input = {
      filter,
      sort: 'ID_DESC',
      pageSize: config.maxPageSize,
    };

    const { data } = await listSessions({
      variables: {
        input,
      },
    });

    if (!data?.listSessionsV2?.edges?.length) {
      snackbar.error('There is no data to export.');
      return;
    }
    // @ts-ignore
    ref.current?.link?.click();
  }, [filter, listSessions, snackbar]);

  const today = moment(new Date()).format('YYYY-MM-DD');

  const rows = sessions?.listSessionsV2?.edges?.map((edge) => ({
    'Session ID': edge?.vendorId,
    Charger: edge?.connector?.evse?.charger?.chargerName,
    'Start Source': edge?.startSource?.columnValue,
    'Start Date': formatDate(edge?.startTime),
    'End Date': formatDate(edge?.endTime),
    'Total Time': edge?.endTime
      ? formatDuration(moment.duration(moment(edge?.endTime).diff(edge?.startTime)).asSeconds())
      : '-',
    'Total kWh': (edge?.energyDelivered || 0) / 1000,
    'Billing Plan': edge?.plan?.planName,
    'Total Cost': `$${Number(edge?.transaction?.amount || 0).toFixed(2)}`,
    Corrupted: edge?.corrupted,
  }));

  return (
    <Styled>
      <Button onClick={onClick} disabled={loading} color="secondary" variant="contained" data-testid={'export-button'}>
        {loading ? <CircularProgress size={24} /> : <GetApp />}
        <Box component="span" pl={1}>
          Export
        </Box>
      </Button>
      <CSVLink
        ref={ref as never}
        data={rows || []}
        filename={`${today}_${filter?.accountId?.eq}_sessions.csv`}
        data-testid={'csv-link'}
      />
    </Styled>
  );
};
