import Yup from 'src/yup';
import { convertTimeToNumber } from 'src/components/views/ExtendPlusView/ChargerPrices/Pricing/utils';

const { array, object, string } = Yup;
const tooManyDecimals = /^\d*\.\d{3,}$/;

export const pricingSchema = Yup.mixed().test('pricing', 'valid', function (pricing) {
  const values = Object.values(pricing || {});
  const unchecked = values.filter((value) => value === false);

  if (unchecked.length === values.length) {
    return this.createError({
      path: this.path,
      message: 'At least one price type is required.',
    });
  }
  for (const value of values) {
    if (value === undefined || value === true) {
      return this.createError({
        path: this.path,
        message: 'Please enter an amount.',
      });
    }
    if (Number(value) < 0) {
      return this.createError({
        path: this.path,
        message: 'Please enter a valid positive number.',
      });
    }
    if (tooManyDecimals.test(String(value))) {
      return this.createError({
        path: this.path,
        message: 'Please enter a valid value with no more than 2 decimals.',
      });
    }
  }

  return true;
});

export const reservationTermSchema = Yup.mixed().test('reservationTerm', 'valid', function (pricing) {
  const values = Object.values(pricing || {});
  for (const value of values) {
    if (value === undefined || value === null) {
      return this.createError({
        path: this.path,
        message: 'Please enter an amount.',
      });
    }
    if (Number(value) < 0) {
      return this.createError({
        path: this.path,
        message: 'Please enter a valid positive number.',
      });
    }
    if (tooManyDecimals.test(String(value))) {
      return this.createError({
        path: this.path,
        message: 'Please enter a valid value with no more than 2 decimals.',
      });
    }
  }
  return true;
});

export const tariffSchedulesSchema = array().of(
  object({
    startTime: string().required('Start time is required.'),
    endTime: string()
      .required('End time is required.')
      .test('after', 'End time should be greater than Start time', function (endTime?: string) {
        const { startTime } = this.parent;

        if (!startTime || !endTime) return true;
        if (convertTimeToNumber(endTime) === 0) return true;

        if (convertTimeToNumber(startTime) > convertTimeToNumber(endTime)) {
          return false;
        }

        return true;
      }),
    daysOfWeek: array().of(string()).required('Day of week is required.'),
  }),
);

export const customPricingSchema = object({
  tariffSchedules: tariffSchedulesSchema,
  pricing: pricingSchema,
  displayName: string().trim().nullable().max(10, 'Display name must be 10 characters or less'),
  displayDescription1: string().trim().nullable().max(10, 'Display description 1 must be 10 characters or less'),
  displayDescription2: string().trim().nullable().max(10, 'Display description 2 must be 10 characters or less'),
});

export const validationSchema = object({
  customPricing: customPricingSchema,
});
