import React from 'react';
import styled from 'styled-components';
import { CancelOutlined } from '@material-ui/icons';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const OfflineView: React.FC = () => {
  return (
    <StyledDiv data-testid="offline-view">
      <p>eXtend+ is currently offline</p>
      <br />
      <CancelOutlined fontSize="large" color="error" />
    </StyledDiv>
  );
};
