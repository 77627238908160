/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { FetchMore, MatOnChangePageEvent } from 'src/@types/shared';
import { listChargeSessions } from '../../../../../apollo/queries/chargers';
import { sanitizeSearch, updateQuery } from '../../../../../lib/helpers';
import { ListSearch as TransactionsListSearch } from '../../../../shared/ListSearch';
import { ChargeSession } from './ChargeSession';
import { Styled as StyledPaper } from './styles';
import { TransactionsListFilters } from './TransactionsListFilters';

export interface Props {
  className?: string;
}

const searchFields = ['chargerId', 'sessionId'];

/**
 * Gets list with up-to-date limit info
 */
export const sanitizeFilter = (filter: { startSource: any; sessionStartOn: any }): any => {
  const startSource = !_.isEmpty(filter) && filter.startSource ? _.omit(filter.startSource, '__typename') : null;
  const sessionStartOn =
    !_.isEmpty(filter) && filter.sessionStartOn ? _.omit(filter.sessionStartOn, '__typename') : null;
  let filters = {};
  if (!_.isEmpty(startSource) && !_.isEmpty(sessionStartOn)) {
    filters = { startSource, sessionStartOn };
  } else if (!_.isEmpty(startSource)) {
    filters = { startSource };
  } else if (!_.isEmpty(sessionStartOn)) {
    filters = { sessionStartOn };
  }
  return filters;
};

/**
 * Changes charge sessions page
 */
export const onPageChange =
  (fetchMore: FetchMore, metadata: any) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    const { filter, pageSize } = metadata;
    let search = sanitizeSearch(metadata.search, searchFields);

    if (_.isEmpty(_.get(search, 'sessionId', {}))) search = _.omit(search, 'sessionId');

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          filter: sanitizeFilter(filter),
          page,
          pageSize,
          search,
        },
      },
    });
  };

/**
 * Changes charge sessions page size
 
 */
export const onRowsPerPageChange =
  (fetchMore: FetchMore, metadata: any) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ReturnType<FetchMore> => {
    const { filter } = metadata;
    let search = sanitizeSearch(metadata.search, searchFields);

    if (_.isEmpty(_.get(search, 'sessionId', {}))) search = _.omit(search, 'sessionId');

    return fetchMore({
      updateQuery,
      variables: {
        input: {
          filter: sanitizeFilter(filter),
          page: 0,
          pageSize: event.target.value,
          search,
        },
      },
    });
  };

/**
 * Search charge sessions list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: any,
  target: EventTarget & HTMLInputElement,
  altId: string,
): ReturnType<FetchMore> => {
  const { filter, pageSize } = metadata;
  const search: any = { chargerId: { eq: altId } };
  if (target.value.length) {
    searchFields.forEach((field) => {
      if (field === 'sessionId') {
        search[field] = { iLike: target.value };
      }
    });
  }

  return fetchMore({
    updateQuery,
    variables: {
      input: {
        page: 0,
        pageSize,
        search,
        filter: sanitizeFilter(filter),
      },
    },
  });
};

/**
 * Filter Charger sessions list
 */
const updateFilters = (fetchMore: FetchMore, meta: any, newFilters: any, key: string): ReturnType<FetchMore> => {
  const { filter, search, pageSize } = meta;
  let sourceFilters: any = { startSource: { in: _.get(newFilters, 'startSource', []) } };
  let dateFilter: any = { sessionStartOn: { ...newFilters } };
  if (key === 'startSource') {
    sourceFilters = { startSource: { in: _.get(newFilters, 'startSource', []) } };
    dateFilter = {
      sessionStartOn: { from: _.get(filter, 'sessionStartOn.from', ''), to: _.get(filter, 'sessionStartOn.to', '') },
    };
  }
  if (key === 'sessionStartOn') {
    sourceFilters = { startSource: { in: filter?.startSource?.in || [] } };
    dateFilter = { sessionStartOn: { ...newFilters } };
  }

  if (_.isEmpty(sourceFilters.startSource.in)) sourceFilters = null;
  if (_.isNaN(dateFilter.sessionStartOn.to) || _.isNaN(dateFilter.sessionStartOn.from)) dateFilter = null;

  let sanitizedSearch = sanitizeSearch(search, searchFields);
  if (_.isNaN(_.get(sanitizedSearch, 'sessionId', '')) || _.isEmpty(_.get(sanitizedSearch, 'sessionId', '')))
    sanitizedSearch = _.omit(sanitizedSearch, 'sessionId');
  const shapedFilters = { ...sourceFilters, ...dateFilter };
  const inputs = {
    page: 0,
    pageSize: pageSize || 10,
    filter: shapedFilters,
    search: { ...sanitizedSearch },
  };
  return fetchMore({
    updateQuery,
    variables: {
      input: inputs,
    },
  });
};

/**
 * Converts and formats unix timestamps
 */
export const formattedTime = (timestamp: string | number, elapsed = false): string => {
  if (elapsed) return timestamp ? moment(timestamp).tz('UTC').utc().format('hh:mm:ss') : '00:00:00';
  return moment(timestamp).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a');
};

/**
 * Charger Transactions component
 */
export const ChargerTransactionsTab: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerTransactionsTab');
  const className = id;
  const { altId } = useParams<{ altId: string }>();
  const today = moment().tz('UTC').utc().endOf('day').valueOf();
  const thirtyDaysPrior = moment().tz('UTC').utc().subtract(30, 'days').startOf('day').valueOf();

  const { loading, fetchMore, ...sessions } = useQuery<Query>(listChargeSessions, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page: 0,
        pageSize: 10,
        search: {
          chargerId: {
            eq: altId,
          },
        },
        filter: {
          sessionStartOn: { from: thirtyDaysPrior, to: today },
          corrupted: { ne: true },
        },
      },
    },
    skip: !altId,
  });

  // TODO: listChargeSessions doesn't exist
  const { edges, ...metadata } = ((sessions?.data as any)?.listChargeSessions || {}) as {
    edges: any[];
    [key: string]: any;
  };

  const debouncedOnSearchChange = (
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : onSearchChange
  ) as typeof onSearchChange;

  const debouncedFetchMore = (
    typeof fetchMore === 'function' ? _.debounce(fetchMore, 500) : fetchMore
  ) as typeof fetchMore;

  const headers = [
    { id: 'sessionId', label: 'Session ID' },
    { id: 'connectorType', label: 'Connector Type' },
    { id: 'startSource', label: 'Start Source' },
    { id: 'stopSource', label: 'Stop Source' },
    { id: 'startTime', label: 'Start Time' },
    { id: 'endTime', label: 'End Time' },
    { id: 'totalTime', label: 'Total Time' },
    { id: 'totalKwh', label: 'Total kWh' },
    { id: 'billingPlan', label: 'Billing Plan' },
    { id: 'corrupt', label: 'Corrupt' },
    { id: 'extraData', label: 'Extra Data' },
    { id: 'expand', label: '' },
  ];

  const rows: any[] = !loading
    ? edges?.map((edge) => {
        const startTime = formattedTime(edge?.sessionStartOn);
        const endTime = formattedTime(edge?.sessionStoppedOn);
        const totalTime = formattedTime(edge?.sessionStoppedOn - edge?.sessionStartOn, true);

        const calculatekWh = (kw: string | number) => {
          const hms = totalTime.split(':');
          return ((+hms[0] + +hms[1] / 60 + +hms[2] / 3600) * Number(kw)).toFixed(3);
        };

        const startkWh = calculatekWh(edge?.meterStart);
        const endkWh = calculatekWh(edge?.meterEnd);
        const totalKwh = (+calculatekWh(edge?.meterEnd) - +calculatekWh(edge?.meterStart)).toFixed(2);

        return {
          sessionId: edge?.sessionId || '',
          connectorType: edge?.stationSocket || '',
          startSource: edge?.startSource || '',
          stopSource: edge?.stopSource || '',
          billingPlan: edge?.billingPlan || '',
          corrupt: edge?.corruptedSession || false,
          extraData: edge?.extraData || '',
          meterReadings: edge?.meterReadings || [],
          startTime,
          endTime,
          totalTime,
          startkWh,
          endkWh,
          totalKwh,
        };
      })
    : [];

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            Sessions
          </Typography>

          <Typography className={className} variant="caption" id={`${id}-sessions-total`}>
            Currently viewing {loading ? '...' : metadata.total}
          </Typography>
        </div>

        <div className={className}>
          <div className={className}>
            <TransactionsListSearch
              className={className}
              type="session"
              onSearchChange={(search) => debouncedOnSearchChange(fetchMore, metadata, search, altId || '')}
            />
          </div>
        </div>
      </header>

      <TransactionsListFilters
        metadata={metadata}
        fetchmore={debouncedFetchMore}
        updateFilters={(search, key) => updateFilters(debouncedFetchMore, metadata, search, key)}
        className={`${className} filters`}
      />

      <Divider />

      <Table className={className}>
        <TableHead>
          <TableRow className={className}>
            {headers?.map((header) => (
              <TableCell key={header.id} className={`${className} ${header.id}`}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, i) => (
            <ChargeSession className={className} key={i} index={i} row={row} metadata={metadata} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={headers.length}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={onPageChange(fetchMore, metadata)}
              onRowsPerPageChange={onRowsPerPageChange(fetchMore, metadata)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};
