import _ from 'lodash';
import React from 'react';
import { CcsIcon } from '../CcsIcon';
import { ChademoIcon } from '../ChademoIcon';
import { Styled } from './styles';

export interface ConnectorIconProps {
  id?: string;
  className?: string;
  name?: string;
  status?: string;
}

/**
 * Connector icon component
 */
export const ConnectorIcon: React.FC<ConnectorIconProps> = ({ className: parentClass, name, status, ...props }) => {
  const className = 'ConnectorIcon';
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  const iconProps = { className: `${customClass} ${_.kebabCase(status)}`, ...props };
  let icon = <ChademoIcon {...iconProps} />;

  if (name === 'CCS' || name === 'SAE J1772') icon = <CcsIcon {...iconProps} />;

  return <Styled>{icon}</Styled>;
};
