import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Site } from 'src/@types';
import { getSitesForExtend } from 'src/apollo/queries/extendPlus';

type UseGetSitesForUserOptions = {
  onCompleted?: (sites: Site[]) => void;
};

export function useGetSitesForUser(options?: UseGetSitesForUserOptions) {
  const [result, setResult] = useState<Site[]>([]);

  useQuery<{ getSitesForExtend: Site[] }>(getSitesForExtend, {
    onCompleted: (data) => {
      const value = data?.getSitesForExtend || [];
      setResult(value);
      options?.onCompleted?.(value);
    },
  });

  return result;
}
