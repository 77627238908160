import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  table {
    width: calc(100% - ${spacing(3)}px);
    margin: ${`${spacing(3)}px ${spacing(3) / 2}px 0`};

    thead {
      tr {
        height: auto;
      }
    }

    td,
    th {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      padding: ${spacing()}px;

      svg {
        margin-bottom: -5px;
        margin-left: ${spacing(1.5)}px;
      }
    }

    td {
      padding: ${`${spacing() / 2}px ${spacing()}px ${spacing() * 2}px`};

      button {
        margin: 0;
      }

      .form-control {
        width: 100%;
        margin-bottom: -${spacing(2.5)}px;

        div {
          margin-top: 0;
        }

        /* TODO: remove all unecessary css and use material ui standards */
        input {
          padding-top: ${spacing(1.25)}px !important;
        }

        /* TODO: remove all unecessary css and use material ui standards */
        .adornment {
          margin-top: -12px !important;
        }
      }

      > span {
        padding: ${`${spacing(1.5)}px 0`};
      }
    }
  }

  p {
    margin: ${`${spacing(0.5)}px ${spacing(1.5)}px`};
  }

  button {
    margin: ${`-${spacing(0.5)}px ${spacing() / 2}px -${spacing(0.75)}px`};
  }

  .hidden {
    visibility: hidden;
  }
`;
