/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing, palette } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  hr {
    height: 1px;
    width: calc(100% + ${spacing(4)}px);
    margin-left: -${spacing(2)}px;
  }
  div {
    &.power {
      display: block;
      width: 100%;
      .sub-power {
        display: flex;
      }
    }

    &.model-notes {
      width: 100%;
      padding-top: ${spacing()}px;
    }

    &.interface {
      display: flex;
      width: 100%;

      > div {
        box-sizing: border-box;
        width: 100%;
        display: block;
        padding: ${`${spacing()}px ${spacing(2)}px 0 0`};

        &.mechanical {
          padding: ${`${spacing()}px 0 0 ${spacing(2)}px`};
        }

        .switch {
          display: flex;
        }

        .form-control,
        .select {
          width: calc(50% - ${spacing(2)}px);
          display: inline-flex;
        }
      }
    }

    .checkbox-container {
      display: flex;
      flex-direction: row;
      margin-right: ${spacing(3)}px;
      h3 {
        font-weight: 300;
        padding: ${`${spacing() + 2}px 0`};
      }
      span {
        padding: ${`${spacing(1.5)}px ${spacing() / 2}px`};
      }
    }

    &.communications {
      width: 100%;

      .communications-inner {
        display: flex;
        justify-content: space-between;

        .communications-inner--panel {
          width: 49%;

          .column {
            width: calc(50% - ${spacing(2)}px);
            display: inline-flex;
          }

          .table-container {
            padding: ${spacing()}px;
          }

          table {
            border: 1px solid #e0e0e0;
            margin-top: ${`${spacing(2)}px`};

            th {
              font-size: ${`${spacing(2)}px`};
            }

            td,
            th {
              padding: ${`${spacing(2)}px`};

              font-size: ${`${spacing(1.5)}px`};
              color: #707070;
            }
          }
        }
      }
    }
  }

  h3 {
    width: calc(100% - ${spacing(2)}px);
    padding: ${`${spacing(2)}px ${spacing()}px 0`};
    color: ${palette.text.secondary};
  }

  .error {
    display: flex;
    align-self: center;
    position: absolute;
    top: 80px;
    margin-top: 0;
    z-index: 2000;
    background-color: #cb2e29;

    span {
      display: flex;
      align-items: center;
      font-weight: 200;

      svg {
        margin-right: ${spacing()}px;
      }
    }

    button {
      color: #ffffff;
    }
  }

  section,
  article,
  .connectors {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};

    section {
      box-sizing: border-box;
      width: calc(100% - ${spacing() * 4}px);
      margin: ${`${spacing(4)}px ${spacing(2)}px 0`};
    }
  }
`;
