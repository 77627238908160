import Yup from 'src/yup';

export const validationSchema = Yup.object().shape({
  chargerGroupName: Yup.string()
    .trim()
    .required('You must enter a pricing name')
    .max(250, 'Name may only contain a maximum of 250 characters'),
  description: Yup.string()
    .trim()
    .required('You must enter a description')
    .max(200, 'Description may only contain a maximum of 200 characters'),
  planDuration: Yup.number()
    .nullable(true)
    .when('hasDuration', (hasDuration) => {
      if (hasDuration)
        return Yup.number()
          .nullable(true)
          .required('You must inform a plan duration in months')
          .integer('Plan duration must be of integer value')
          .test('is positive', 'You must inform a plan duration in months', (value) => value > 0);
      return Yup.mixed().nullable(true);
    }),
});
