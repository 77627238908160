import clsx from 'clsx';
import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { PageActionsContainer, PageActionsContent } from './styles';

export interface PageActionsProps {
  id?: string;
  message?: string;
  className?: string;
  children?: React.ReactNode;
  sticky?: boolean;
}

export const PageActions: React.FC<PageActionsProps> = ({ children, className, id, message, sticky = true }) => {
  const isLargeScreen = useMediaQuery('(min-width:1400px)');

  return (
    <PageActionsContainer
      className={clsx('page-actions', className)}
      id={id}
      isLargeScreen={isLargeScreen}
      sticky={sticky}
    >
      <PageActionsContent>
        <Typography component="span" className="page-actions-message">
          {message}
        </Typography>
        {children}
      </PageActionsContent>
    </PageActionsContainer>
  );
};
