import { gql } from '@apollo/client';

export const searchFirebaseAndDriivzAndFalconAccounts = gql`
  query SearchFirebaseAndDriivzAndFalconAccounts($email: String, $phoneNumber: String) {
    searchFirebaseAndDriivzAndFalconAccounts(email: $email, phoneNumber: $phoneNumber) {
      driivz {
        firstName
        lastName
        billingAddress1
        billingAddress2
        billingRegion
        billingLocality
        email
        phone
      }
      falcon {
        firstName
        lastName
        accountId
        email
        phone
        altId
        driverType
        account {
          altId
          billingAddress {
            address1
            address2
            city
            postalCode
            locality
          }
        }
      }
      firebase {
        email
        phoneNumber
      }
    }
  }
`;
