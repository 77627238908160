/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Modal)`
  position: relative;
  width: auto;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    margin-top: 0 !important;
    position: absolute;
    width: 60%;
    z-index: 999;

    .deets {
      display: inline;
    }

    .steps {
      display: flex;
      align-items: center;
      margin: 0;

      div:first-child,
      div:last-child {
        flex: 1;
        text-align: right;
      }
      .stepper {
        flex: 4;

        .active {
          :global(svg) {
            color: #5d9d52;
          }
        }

        :global(text) {
          display: none;
        }
      }
    }

    header {
      display: flex;
      justify-content: space-between;
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      h2 {
        color: ${colors.accentBlue};
      }

      button {
        margin: -${spacing()}px;
      }
    }

    .search {
      width: calc(66.66% - ${spacing(2)}px);
      margin: 0 auto ${spacing(4)}px;
      justify-content: space-between;
      height: 320px;
      text-align: center;

      .search-input {
        display: flex;
        justify-content: center;

        > div {
          width: 50%;
          margin-right: ${spacing()}px;
        }

        button {
          height: ${spacing(5)}px;
          margin: ${spacing()}px;
        }
      }

      .search-dropdown {
        width: 50%;
      }

      .search-table {
        margin-top: ${spacing(2)}px;
        max-height: 225px;
        overflow-y: auto;
      }
    }

    .confirmation {
      width: 66.66%;
      margin: 0 auto ${spacing(4)}px;
      background-color: #f8f8f8;
      height: 320px;
      display: flex;

      > div {
        flex: 1;
        padding: ${spacing(3)}px;
        > div {
          width: 60px;
          margin: 0 auto ${spacing(2)}px;
          white-space: nowrap;
        }
      }
    }

    .panel-actions {
      background-color: #ffffff;
      padding: ${spacing(3)}px;

      button {
        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }
    }

    .modal {
      background-color: red;
    }
  }
`;
