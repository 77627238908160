import { useLazyQuery } from '@apollo/client';
import { Box, Divider, Typography, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Query, TransactionV2 } from 'src/@types';
import { listTransactionDetails as LIST_TRANSACTION_DETAILS } from 'src/apollo/queries/transactions';
import { BasicTable, SimpleColumn } from 'src/components/shared/BasicTable';
import moment from 'moment-timezone';
export interface Props {
  transaction?: TransactionV2;
}

type SessionDataProps = {
  data: {
    row: TransactionV2;
  };
};

const Tariff: React.FC<SessionDataProps> = ({ data }) => {
  return data?.row?.session?.plan?.chargerGroup?.tariff?.tariffName ? (
    <Box>
      <a data-testid="Tariff">{data?.row?.session?.plan?.chargerGroup?.tariff?.tariffName}</a>
    </Box>
  ) : (
    <Box>
      <p data-testid="Tariff">NA</p>
    </Box>
  );
};
const StartTime: React.FC<SessionDataProps> = ({ data }) => {
  return (
    <Box data-testid="StartTime">
      <p>{moment(data?.row?.session?.startTime).tz('UTC').format('MM/DD/YYYY')}</p>
      <p> {moment(data?.row?.session?.startTime).tz('UTC').format('hh:mm:ss')}</p>
    </Box>
  );
};
const EndTime: React.FC<SessionDataProps> = ({ data }) => {
  return (
    <Box data-testid="EndTime">
      <p>{moment(data?.row?.session?.endTime).tz('UTC').format('MM/DD/YYYY')}</p>
      <p> {moment(data?.row?.session?.endTime).tz('UTC').format('hh:mm:ss')}</p>
    </Box>
  );
};

const EnergyDelivered: React.FC<SessionDataProps> = ({ data }) => {
  return <p data-testid="EnergyDelivered">{(data?.row?.session?.energyDelivered || 0) / 1000}</p>;
};

const Charger: React.FC<SessionDataProps> = ({ data }) => {
  return (
    <a data-testid="ChargerName" target="_blank" href={`/chargers/${data?.row?.charger?.altId}/profile`}>
      {data?.row?.charger?.chargerName || null}
    </a>
  );
};

const transactionItemColumns: SimpleColumn[] = [
  { key: 'productType.columnText', label: 'Type' },
  { key: 'quantity', label: 'Quantity', formatWithRowData: ({ quantity }) => `${quantity || 'NA'}` },
  { key: 'unitPrice', label: 'Fees', formatWithRowData: ({ unitPrice }) => `$${unitPrice?.toFixed(2) || 0}` },
  { key: 'amount', label: 'Total Cost', formatWithRowData: ({ amount }) => `$${amount?.toFixed(2) || 0}` },
];

const activityColumns: SimpleColumn[] = [
  { key: 'session.vendorId', label: 'Session ID' },
  {
    key: 'session.startTime',
    label: 'Start Date',
    component: StartTime,
  },
  {
    key: 'session.endTime',
    label: 'End Date',
    component: EndTime,
  },
  {
    key: 'session.energyDelivered',
    label: 'Total kWh',
    component: EnergyDelivered,
  },
  {
    key: 'session.sessionDuration',
    label: 'Total Time',
    formatWithRowData: ({ session }) => `${session.sessionDuration} min`,
  },
  { key: 'session.plan', label: 'Tariff', component: Tariff },
];

const paymentColumns: SimpleColumn[] = [
  { key: 'charger.chargerName', label: 'Charger', component: Charger },
  { key: 'session.connector.connectorType.columnValue', label: 'Connector' },
];

export const TransactionDetails: React.FC<Props> = (props) => {
  const { transaction } = props;

  const [getTransactionItemsQuery, { data, loading, error }] = useLazyQuery<Query>(LIST_TRANSACTION_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getTransactionItemsQuery({
      variables: {
        input: {
          search: {
            altId: {
              eq: transaction?.altId,
            },
          },
        },
      },
    });
  }, [transaction, getTransactionItemsQuery]);

  const transactionItems = data?.listTransactionsV2?.edges?.[0]?.items || [];
  const transactionData = data?.listTransactionsV2?.edges?.[0];
  console.debug(transaction?.session);
  return (
    <Box px={4} py={4} pt={4} bgcolor="#EEF4F8">
      <span>
        <Box px={2}>
          <Grid item>
            <Typography variant="subtitle2">Transaction Details</Typography>
          </Grid>
          <Divider />
        </Box>
        <Box px={2}>
          <BasicTable
            label="transactions subtable"
            className="transactions-subtable"
            columns={transactionItemColumns}
            data={transactionItems}
            loading={loading}
            error={error}
          />
        </Box>
      </span>
      {transactionData?.session && transactionData?.session?.altId && (
        <span>
          <Box px={2} mt={4}>
            <Grid item>
              <Typography variant="subtitle2">
                <p>Charging Activity</p>
              </Typography>
            </Grid>
            <Divider />
          </Box>
          <Box mx={2}>
            <BasicTable
              label="transactions subtable"
              className="transactions-subtable"
              columns={activityColumns}
              data={[transactionData]}
              loading={loading}
              error={error}
            />
          </Box>

          <Box px={2} mt={4}>
            <Grid item>
              <Typography variant="subtitle2">Charging and Payment Details</Typography>
            </Grid>
            <Divider />
          </Box>
          <Box mx={2}>
            <BasicTable
              label="transactions subtable"
              className="transactions-subtable"
              columns={paymentColumns}
              data={[transactionData]}
              loading={loading}
              error={error}
            />
          </Box>
        </span>
      )}
    </Box>
  );
};
