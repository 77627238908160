import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { CardStatus, ReassignCardInput, Card } from 'src/@types';
import { ModalProps } from 'src/@types/client';
import { useFormikSubmit } from 'src/lib/hooks';
import { getAccount } from '../../../../apollo/queries/accounts';
import {
  assignCards,
  createCard,
  unassignCards,
  deactivateCards,
  suspendCards,
  reassignCard,
} from '../../../../apollo/queries/cards';
import { AccountCardModalForm, FormValues, initialValues } from './AccountCardModalForm';
import { validations as validate } from './AccountCardModalForm/validations';
import { useModalContext } from 'src/contexts/ModalContext';

interface Selected {
  altId: string;
  cardStatus: CardStatus | string;
  [key: string]: unknown;
}

interface ModalPropsExtras {
  accountId: string;
  selected: Selected;
}

interface ControllerProps extends Props {
  modalProps: ModalProps & ModalPropsExtras;
}

interface Props {
  open?: boolean;
  className?: string;
}

const getUpdateMutation = (updatedStatus: CardStatus) => {
  switch (updatedStatus) {
    case CardStatus.Unassigned:
      return unassignCards;
    case CardStatus.Active:
      return assignCards;
    case CardStatus.Deactivated:
      return deactivateCards;
    case CardStatus.Suspended:
      return suspendCards;
    default:
      return assignCards;
  }
};

export const AccountCardModalReassignController: React.FC<ControllerProps> = (props) => {
  const { accountId, fetchMore: callback, selected } = props?.modalProps || {};
  const [, setStatus] = useState(selected?.cardStatus || initialValues?.cardStatus);

  const callbackArgs = {
    getAccount,
    variables: {
      accountInput: { altId: accountId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999, paranoid: false },
      cardsInput: { pageSize: 999 },
    },
  };

  const transformValues = (values: FormValues): ReassignCardInput => ({
    altId: selected.altId,
    driverId: values.driverId,
  });

  const handleSubmit = useFormikSubmit('Card', createCard, reassignCard, {
    noRedirect: true,
    altId: accountId || '',
    transformValues,
    callback,
    callbackArgs,
    initialValues,
  }) as never;

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate(selected)}
      validateOnChange
    >
      <AccountCardModalForm open={props.open} setStatus={setStatus} />
    </Formik>
  );
};

export const AccountCardModalDefaultController: React.FC<ControllerProps> = (props) => {
  const { accountId, fetchMore: callback, selected } = props?.modalProps || {};
  const [status, setStatus] = useState(selected?.cardStatus || initialValues?.cardStatus);

  const callbackArgs = {
    getAccount,
    variables: {
      accountInput: { altId: accountId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999, paranoid: false },
      cardsInput: { pageSize: 999 },
    },
  };

  const omitFields = ['isSelected', 'internalNumber', 'externalNumber', 'cardStatus'];
  const transformValues = (values: FormValues) => ({
    ..._.omit(values, omitFields),
    accountId,
    cardIds: selected?.altId,
    driverId: values.driverId === 'unassigned' || !values.driverId ? undefined : values.driverId,
  });

  const updateCard = getUpdateMutation(status as CardStatus);
  const handleSubmit = useFormikSubmit('Card', createCard, updateCard, {
    noRedirect: true,
    altId: (accountId as string) || '',
    transformValues,
    callback,
    callbackArgs,
    initialValues,
  }) as never;

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate(selected as Card)}
      validateOnChange
    >
      <AccountCardModalForm open={props.open} setStatus={setStatus} />
    </Formik>
  );
};

export const AccountCardModal: React.FC<Props> = (props) => {
  const { modalProps } = useModalContext();
  const cardModalProps = {
    ...modalProps,
    accountId: modalProps?.accountId as string,
    selected: modalProps.selected as Selected,
  };
  if (modalProps?.editType === 'assign') {
    return <AccountCardModalReassignController {...props} modalProps={cardModalProps} />;
  } else {
    return <AccountCardModalDefaultController {...props} modalProps={cardModalProps} />;
  }
};
