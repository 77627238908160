import { gql } from '@apollo/client';

export const FullSite = gql`
  fragment FullSite on Site {
    address1
    address2
    locality
    administrativeArea
    postalCode
    country
    chargers {
      edges {
        cid
        altId
        chargerName
        fieldStationStatus
        chargerModel {
          altId
          modelName
        }
        site {
          altId
          address1
          address2
          locality
          administrativeArea
          country
          postalCode
          property {
            propertyName
          }
        }
      }
      page
      pageSize
      total
      search {
        cid {
          iLike
        }
        chargerName {
          iLike
        }
        site_address1 {
          iLike
        }
        site_locality {
          iLike
        }
        site_administrativeArea {
          iLike
        }
        site_postalCode {
          iLike
        }
        site_property_propertyName {
          iLike
        }
      }
      sort {
        cid
        chargerName
        propertyName
        fieldStationStatus
      }
    }
    displayName
    altId
    latitude
    locationNotes
    locationTypeId
    longitude
    siteName
    timeZone
    property {
      altId
      pid
      propertyName
      host {
        altId
        hid
      }
    }
    hoursOfOperation {
      custom
      from
      to
      monday {
        closed
        timeslots {
          from
          to
        }
      }
      tuesday {
        closed
        timeslots {
          from
          to
        }
      }
      wednesday {
        closed
        timeslots {
          from
          to
        }
      }
      thursday {
        closed
        timeslots {
          from
          to
        }
      }
      friday {
        closed
        timeslots {
          from
          to
        }
      }
      saturday {
        closed
        timeslots {
          from
          to
        }
      }
      sunday {
        closed
        timeslots {
          from
          to
        }
      }
    }
    access
    accessNotes
    locationMarket
    directions
    utilityCompany
    type
    sid
    siteAccessId
    siteImages {
      edges {
        media {
          createdAt
          altId
          url
        }
      }
    }
    demandLimitManagementEnabled
    retailDemandLimit
    siteStatusId
    meterNumber
    ratedVoltage
    maxCurrent
  }
`;
