import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { ViewNavigation } from '../../shared/ViewNavigation';
import { CreateHostModal } from './CreateHostModal';
import { HostsHeader } from './HostsHeader';
import { HostsList } from './HostsList';
import { PropertiesHeader } from './PropertiesHeader';
import { PropertiesList } from './PropertiesList';
import { SitesHeader } from './SitesHeader';
import { SitesList } from './SitesList';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export type Props = {
  className?: string;
};

export const HostsAndSitesView: React.FC<Props> = () => {
  const { pathname } = useLocation();

  const links = [
    {
      label: 'Hosts',
      to: '/hosts/list',
    },
    {
      label: 'Properties',
      to: '/properties/list',
    },
    {
      label: 'Sites',
      to: '/sites/list',
    },
  ];

  const { modalVisible } = useModalContext();
  const className = 'HostsAndSitesView';
  let headerComponent = null;
  let listComponent = null;

  switch (pathname) {
    case '/hosts/list': {
      headerComponent = <HostsHeader />;
      listComponent = (
        <Fragment>
          <HostsList className={className} />
          <CreateHostModal className={className} open={modalVisible} />
        </Fragment>
      );
      break;
    }
    case '/properties/list': {
      headerComponent = <PropertiesHeader />;
      listComponent = <PropertiesList className={className} />;
      break;
    }
    case '/sites/list': {
      headerComponent = <SitesHeader />;
      listComponent = <SitesList className={className} />;
      break;
    }
    default: {
      headerComponent = null;
      listComponent = null;
    }
  }

  return (
    <Styled className={className}>
      {headerComponent}
      <ViewNavigation links={links} />
      {listComponent}
    </Styled>
  );
};
