/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';
import { TimeSlot } from '../transformSiteResponse';
import { CustomHours } from 'src/components/shared/CustomPricingInput/CustomHoursInput';
import { daysOfWeek } from '../transformSiteResponse';

interface Day {
  closed: boolean;
  timeslots: TimeSlot[];
}

interface DayMap {
  [key: string]: Day;
}

const transformCustomHoursToHoursOfOperationDays = (customHours: CustomHours[], isCustom: boolean) => {
  if (!isCustom) {
    return daysOfWeek.reduce((acc: { [key: string]: null }, day: string) => {
      acc[day] = null;
      return acc;
    }, {});
  }

  const dayMap: DayMap = daysOfWeek.reduce((acc: DayMap, day: string) => {
    acc[day] = {
      closed: true,
      timeslots: [],
    };
    return acc;
  }, {});

  customHours?.forEach((customHour) => {
    customHour?.daysOfWeek?.forEach((day) => {
      dayMap[day.toLowerCase()].closed = false;
      dayMap[day.toLowerCase()]?.timeslots.push({
        from: customHour.startTime,
        to: customHour.endTime,
      });
    });
  });

  return dayMap;
};

/**
 * Transforms the Formik site values into Falcon format
 */

export const transformSiteValues = (values: any, altId?: string) => {
  const uploads = _.get(values, 'siteImages.edges', []);

  const siteInput = {
    demandLimitManagementEnabled: _.get(values, 'demandLimitManagementEnabled'),
    retailDemandLimit: _.get(values, 'retailDemandLimit') ? Number(_.get(values, 'retailDemandLimit')) : null,
    siteAccessId: _.get(values, 'siteAccessId', 108),
    accessNotes: _.get(values, 'accessNotes'),
    address1: _.get(values, 'address1'),
    address2: _.get(values, 'address2'),
    administrativeArea: _.get(values, 'administrativeArea'),
    description: _.get(values, 'description', ''),
    directions: _.get(values, 'directions'),
    displayName: _.get(values, 'displayName'),
    hoursOfOperation: {
      custom: _.get(values, 'hoursOfOperation.custom'),
      from: _.get(values, 'hoursOfOperation.from'),
      to: _.get(values, 'hoursOfOperation.to'),
      ...transformCustomHoursToHoursOfOperationDays(
        _.get(values, 'customHours'),
        _.get(values, 'hoursOfOperation.custom'),
      ),
    },
    hostId: _.get(values, 'property.host.altId'),
    latitude: Number(_.get(values, 'latitude')),
    locality: _.get(values, 'locality'),
    locationMarket: _.get(values, 'locationMarket'),
    locationNotes: _.get(values, 'locationNotes'),
    longitude: Number(_.get(values, 'longitude')),
    maxCurrent: _.get(values, 'maxCurrent'),
    meterNumber: _.get(values, 'meterNumber'),
    postalCode: _.get(values, 'postalCode'),
    property: {
      hostId: _.get(values, 'property.host.altId'),
      propertyName: _.get(values, 'property.propertyName'),
      pid: _.get(values, 'property.pid'),
    },
    ratedVoltage: _.get(values, 'ratedVoltage'),
    sid: _.get(values, 'sid'),
    siteName: _.get(values, 'siteName'),
    siteStatusId: _.get(values, 'siteStatusId', 89),
    locationTypeId: _.get(values, 'locationTypeId', 92),
    utilityCompany: _.get(values, 'utilityCompany'),
  } as any;

  if (altId) {
    siteInput.altId = altId;
    siteInput.hid = _.get(values, 'property.host.hid');
    siteInput.siteImages = uploads.map((image: any, i: number) => ({
      media: { altId: image.media.altId },
      mediaOrder: i + 1,
    }));
    siteInput.property = { pid: _.get(values, 'property.pid') };
  }

  return siteInput;
};
