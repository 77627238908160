import { gql } from '@apollo/client';

export const FullChargerModel = gql`
  fragment FullChargerModel on ChargerModel {
    altId
    additionalDetails
    autoChargeSupported
    breakerRating
    communicationProtocol
    currentThd
    dimensions
    display
    efficiency
    firmwareVersion
    ingressProtection
    inputAcVoltage
    interfaceVersion
    isoIec
    manufacturer
    modelName
    modelSku
    networkCommunication
    nfcReader
    powerFactor
    powerOutput
    simultaneousChargeSupported
    smartEnergyProfileSupport
    unitType
    weight
    evses {
      edges {
        altId
        connectors {
          edges {
            altId
            connectorMaxCurrent
            connectorType {
              id
              columnText
            }
            cableCooling
            cableLength
            cableManufacturer
            outputVoltageRange
          }
        }
      }
    }
  }
`;
