import { Box } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Maybe, Query, Site } from 'src/@types';
import { SearchField } from 'src/components/shared/SearchField';
import { listGeographicalRegions } from 'src/apollo/queries/geographicalRegions';
import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';
import { geographicalRegionsInput, getUniqueOptions, Options, shapeGeographicalRegions } from 'src/lib/helpers';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { Filters } from '../filterHelpers';
import { events } from 'src/lib/utils/analytics-events';

type ChargerSelectionFilterProps = {
  filters: Filters;
  onChange: (filters: Filters) => void;
  siteStatuses: Map<number, string>;
  sites: Maybe<Site>[];
};

export function SitesFilter({ filters, onChange, siteStatuses, sites }: ChargerSelectionFilterProps) {
  const { track } = useAnalytics();
  const searchEventType = events.sitesList.FILTERS_SEARCH_CHANGE;
  const filterEventType = events.sitesList.FILTERS_FILTER_CHANGE;

  const statusOptions = Array.from(siteStatuses).map(([id, label]) => ({
    value: id.toString(),
    label,
  }));

  const zipOptions: Options[] = getUniqueOptions(sites, {
    value: 'postalCode',
    label: 'postalCode',
  });

  const { data: geographicalRegions, loading: geoRegionsLoading } = useQuery<Query>(listGeographicalRegions, {
    variables: geographicalRegionsInput,
  });

  const { shapedStateOptions } = shapeGeographicalRegions(geographicalRegions);

  return (
    <Box display="flex" alignItems="flex-start">
      <Box pt={3}>
        <SearchField
          initialValue={filters.search}
          onChange={(value) => {
            onChange({ ...filters, search: value });
            track(searchEventType);
          }}
        />
      </Box>

      <Box pt={1} pl={2}>
        <MultiSelectSearchFilter
          label="Status"
          value={filters.status}
          options={statusOptions}
          onChange={(status) => {
            onChange({ ...filters, status });
            track(filterEventType, { filterType: 'status' });
          }}
          debounce={500}
        />
      </Box>

      <Box pt={1} pl={2}>
        <MultiSelectSearchFilter
          label="State"
          value={filters.states}
          options={shapedStateOptions?.map((state) => ({
            value: state.value,
            label: state.label,
          }))}
          onChange={(states) => {
            onChange({ ...filters, states });
            track(filterEventType, { filterType: 'states' });
          }}
          debounce={500}
          loading={geoRegionsLoading}
        />
      </Box>

      <Box pt={1} pl={2}>
        <MultiSelectSearchFilter
          label="Zip Code"
          value={filters.zips}
          options={zipOptions}
          onChange={(zips) => {
            onChange({ ...filters, zips });
            track(filterEventType, { filterType: 'zips' });
          }}
          debounce={500}
        />
      </Box>
    </Box>
  );
}
