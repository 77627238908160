import { useMutation } from '@apollo/client';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import _ from 'lodash';
import { getAccount, updateAccountOwner } from '../../../../apollo/queries/accounts';
import React, { useEffect, useState } from 'react';
import { Account } from 'src/@types';
import { ModalProps } from 'src/@types/client';
import { StyledModal } from 'src/components/shared/StyledModal';
import { Styled } from './styles';
import clsx from 'clsx';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
import { useModalContext } from 'src/contexts/ModalContext';
export interface Props {
  modalProps?: ModalProps | undefined | Partial<Account>;
  open: boolean;
  className?: string;
}

const statusOptions = [
  { label: 'Register', value: 'REGISTERED' },
  { label: 'Activate', value: 'ACTIVE' },
  { label: 'Suspend', value: 'SUSPENDED' },
  { label: 'Unsuspend', value: 'UNSUSPENDED' },
  { label: 'Close', value: 'CLOSED' },
];

/**
 * Account Status modal component
 */
export const AccountStatusModal: React.FC<Props> = (props) => {
  const className = 'accountstatusmodal-component';
  const selected = props.modalProps;
  const altId = _.get(selected, 'altId', '');
  const [status, setStatus] = useState<string | unknown>('');
  const snackbar = useSnackbar();
  const { setModalState } = useModalContext();
  const accountOwner = _.get(selected, 'drivers.edges', []).filter(
    (driver: { driverType: string }) => driver.driverType === 'OWNER',
  );
  const driverId = accountOwner.length ? accountOwner[0].altId : '';

  const [onUpdateAccountOwner] = useMutation(updateAccountOwner, {
    refetchQueries: () => [
      {
        query: getAccount,
        variables: {
          accountInput: { altId },
          driversInput: { pageSize: 999, paranoid: false },
          accountVehiclesInput: { pageSize: 999 },
          cardsInput: { pageSize: 999 },
        },
      },
    ],
    onCompleted: () => {
      snackbar.success('Successfully updated account status.');
    },
    onError: () => {
      snackbar.error('Failed to update account status.');
    },
  });

  useEffect(() => {
    if (selected?.accountStatus) {
      setStatus(selected?.accountStatus);
    }
  }, [selected, setStatus]);

  const saveAccountStatus = (accountStatus: string | unknown) => {
    onUpdateAccountOwner({ variables: { accountInput: { accountId: altId, accountStatus, driverId } } });
    setModalState({ modalName: '', modalProps: {}, modalVisible: false });
  };

  let customClass = className;
  let message = '';

  if (props.className) customClass += ` ${props.className}`;

  if (_.get(selected, 'accountStatus', '') === 'ACTIVE' && status === 'REGISTERED') {
    message =
      'This customer will no longer be able to start charges until they enter billing information, are you sure you want to deactivate this account?';
  }
  if (status === 'ACTIVE') {
    message =
      'This customer does not have any billing information on file. Are you sure you want to activate their account for charging?';
  }
  if (status === 'SUSPENDED') {
    message =
      'Suspending this customer will prevent them from charging until a billing issue is resolved. Are you sure you want to suspend this account?';
  }
  if (status === 'UNSUSPENDED') {
    message = 'This customer has a billing issue on file. Are you sure you want to unsuspend their account?';
  }
  if (status === 'CLOSED') {
    message = 'Are you sure you want to close this customer’s account?';
  }

  return (
    <StyledModal
      className={clsx(customClass, 'modal')}
      onClose={() => setModalState({ modalName: '', modalProps: {}, modalVisible: false })}
      open={props.open}
      title="Change account status"
      onClick={() => {
        if (status === 'UNSUSPENDED' && selected && selected.accountStatus === 'SUSPENDED') {
          saveAccountStatus('ACTIVE');
        } else {
          saveAccountStatus(status);
        }
      }}
      primaryButtonText="Yes"
      secondaryButtonClick={() => setModalState({ modalName: '', modalProps: {}, modalVisible: false })}
      secondaryButtonText="Cancel"
    >
      <Styled className={className}>
        <FormControl component="fieldset" className={className}>
          <RadioGroup
            aria-label="status"
            name="status"
            className={className}
            value={status || ''}
            onChange={({ target }) => setStatus(target.value)}
          >
            {statusOptions.map(({ label, value }) => (
              <FormControlLabel
                className={className}
                disabled={value === 'UNSUSPENDED' && selected && selected.accountStatus !== 'SUSPENDED'}
                key={value}
                value={value}
                control={<Radio color="secondary" />}
                label={
                  <Typography className={className} variant="h6" component="h5">
                    {label}
                  </Typography>
                }
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
        </FormControl>
        <div className={clsx(className, 'textContainer')}>
          <Typography className={className} color="textSecondary" variant="caption">
            {message}
          </Typography>
        </div>
      </Styled>
    </StyledModal>
  );
};
