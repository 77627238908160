import { CircularProgress, InputLabel, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Options } from 'src/lib/helpers';
import { StyledFormControl, StyledLoading, StyledMenuItem } from './styles';

export type SearchFilterDropdownProps = {
  id?: string;
  label: string;
  className?: string;
  options: Array<Options>;
  debounce?: number;
  onChange: (value: string) => void;
  onSearchChange: (arg0: string) => void;
  loading?: boolean;
  value: string;
};

export const SearchFilterDropdown: React.FC<SearchFilterDropdownProps> = (props) => {
  const {
    label,
    id = _.kebabCase(label),
    className = 'SearchFilterDropdown',
    options,
    debounce = 200,
    onChange,
    onSearchChange = () => {},
    value: selected,
    loading = false,
  } = props;

  const [typed, setTyped] = useState<string>('');
  const inputRef = useRef<null | HTMLInputElement>(null);
  const { run: debouncedOnChange } = useDebounceFn(onChange, { wait: debounce });
  const { run: debouncedOnSearchChange } = useDebounceFn(onSearchChange, { wait: debounce });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [options]);

  return (
    <StyledFormControl className={className}>
      <InputLabel className={clsx(className, 'label')}>{label}</InputLabel>
      <Select
        className={clsx(className, 'select')}
        displayEmpty
        onChange={(e) => {
          const values: string = e.target.value as string;
          debouncedOnChange(values);
        }}
        value={selected}
        data-testid={`${id}-select`}
        MenuProps={{
          variant: 'menu',
          getContentAnchorEl: null,
        }}
      >
        <StyledMenuItem value="" disabled disableRipple>
          <SearchIcon />
          <input
            data-testid="options-search"
            className={clsx(className, 'search')}
            onClickCapture={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onKeyDown={(e) => e.stopPropagation()}
            type="text"
            value={typed}
            placeholder="Search"
            onChange={(e) => {
              setTyped(e.target.value);
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 100);
              debouncedOnSearchChange(e.target.value);
            }}
            ref={inputRef}
          ></input>
        </StyledMenuItem>
        {options.map((option) => {
          return (
            <MenuItem
              key={option?.value}
              data-testid="option-menu-item"
              className={clsx(className, 'option')}
              value={option?.value}
              disableRipple
            >
              <ListItemText data-testid="option-label" primary={option?.label} style={{ fontSize: 'small' }} />
            </MenuItem>
          );
        })}
      </Select>

      {loading && (
        <StyledLoading>
          <CircularProgress size={20} />
        </StyledLoading>
      )}

      {!_.isEmpty(selected) && (
        <Typography
          className={clsx(className, 'clear-options')}
          variant="caption"
          color="secondary"
          onClick={() => {
            onChange('');
            setTyped('');
          }}
        >
          Clear
        </Typography>
      )}
    </StyledFormControl>
  );
};
