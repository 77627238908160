import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-control {
    width: calc(50% - ${spacing(1)}px);
    margin-bottom: ${spacing()}px;
    margin-right: ${spacing()}px;
  }

  .form-group {
    margin-top: ${spacing(2)}px;
  }

  .full-width {
    width: 100%;
  }
`;
