const config = {
  attribute: 'data',
  context: 'context',
  identifier: 'testid',
};

const DataContextPath = function ({
  attribute,
  context,
  identifier,
}: {
  attribute: string;
  context: string;
  identifier: string;
}) {
  return {
    attr: `${attribute}-${identifier}`,
    full: `${attribute}-${identifier}-${context}`,
    context: `${identifier}-${context}`,
    contextNormal: `${identifier}${context[0].toUpperCase()}${context.slice(1)}`,
    id: identifier,
  };
};

export const path = DataContextPath(config);

export const getParentElements = (node: HTMLElement, list: HTMLElement[] = []): HTMLElement[] =>
  node.parentElement ? getParentElements(node.parentElement, [...list, node]) : list;

export const buildContextPath = (list: HTMLElement[]): string =>
  list.reduce((acc: string, element: HTMLElement): string => {
    const data = element.dataset[path.contextNormal];
    return data ? (!!!acc ? data : `${acc}-${data}`) : acc;
  }, '');
