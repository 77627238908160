import { gql } from '@apollo/client';

export const listTransactionsV2 = gql`
  query listTransactionsV2($input: ListTransactionsV2Input) {
    listTransactionsV2(input: $input) {
      total
      edges {
        id
        amount
        createdAt
        tax
        vendorId
        type {
          columnValue
        }
        session {
          accepted
          energyDelivered
          corrupted
          startTime
          endTime
          plan {
            planName
            altId
          }
        }
        items {
          comments
        }
        altId
      }
    }
  }
`;
