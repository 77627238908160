import { Box, Typography } from '@material-ui/core';
import { MetricCardGroup } from 'src/components/shared/MetricCard';
import { ConsumptionMetricCards } from '../../shared/ConsumptionMetricCards';

type EnergyDispensedMetricsProps = {
  siteIds: string[];
};

export function EnergyDispensedMetrics({ siteIds }: EnergyDispensedMetricsProps) {
  return (
    <Box mb={4} mt={4}>
      <MetricCardGroup id="montly-energy-dispensed-metrics">
        <Box pt={4} pl={4}>
          <Typography color="textSecondary" variant="body2">
            Total Monthly Energy Dispensed
          </Typography>
        </Box>

        <ConsumptionMetricCards siteIds={siteIds} />
      </MetricCardGroup>
    </Box>
  );
}
