import React from 'react';

interface Props {
  link: string;
  'data-testid'?: string;
}

export const Video: React.FC<Props> = ({ link, ...props }) => {
  return (
    <video {...props} controls>
      <source src={link}></source>
    </video>
  );
};
