import { useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { PropertiesWithMeta, Query } from 'src/@types';
import { FetchMore, MatOnChangePageEvent } from 'src/@types/shared';
import { listProperties } from '../../../../apollo/queries/properties';
import { getFullAddress, sanitizeSearch, updateQuery } from '../../../../lib/helpers';
import { ListSearch as PropertiesListSearch } from '../../../shared/ListSearch';
import { PropertiesListFilters } from '../PropertiesListFilters';
import { Styled as StyledPaper } from './styles';

export interface Props {
  className?: string;
}

const searchFields = ['pid', 'propertyName', 'address1', 'locality', 'administrativeArea', 'postalCode'];

/**
 * Changes properties list page
 */
export const onChangePage =
  (fetchMore: FetchMore, metadata: PropertiesWithMeta) =>
  (event: MatOnChangePageEvent, page: number): ReturnType<FetchMore> => {
    const { pageSize, sort, filter, search } = metadata;
    return fetchMore({
      updateQuery,
      variables: {
        propertiesInput: {
          page,
          pageSize,
          sort: _.pickBy(_.pick(sort, ['pid', 'id', 'hostName', 'propertyName']), (i) => i !== null),
          filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Changes properties list page
 */
export const onChangeRowsPerPage =
  (fetchMore: FetchMore, metadata: PropertiesWithMeta) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ReturnType<FetchMore> => {
    const { sort, filter, search } = metadata;
    return fetchMore({
      updateQuery,
      variables: {
        propertiesInput: {
          page: 0,
          pageSize: event.target.value,
          sort: _.pickBy(_.pick(sort, ['pid', 'id', 'hostName', 'propertyName']), (i) => i !== null),
          filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Sorts properties list
 */
export const onSort =
  (fetchMore: FetchMore, metadata: PropertiesWithMeta, key: string) => (): ReturnType<FetchMore> => {
    const { pageSize, total, filter, search } = metadata;
    let sort = { [key]: 'ASC' };

    if (_.get(metadata, `sort.${key}`, 'ASC') === 'ASC') sort = { [key]: 'DESC' };

    return fetchMore({
      updateQuery,
      variables: {
        propertiesInput: {
          page: 0,
          pageSize,
          total,
          sort,
          filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
          search: sanitizeSearch(search, searchFields),
        },
      },
    });
  };

/**
 * Filters hosts list
 */
export const onFilterChange = (
  fetchMore: FetchMore,
  metadata: PropertiesWithMeta,
  updatedFilters: string[],
  type: string,
): ReturnType<FetchMore> => {
  const { pageSize, sort, search } = metadata;
  const filter = updatedFilters.length
    ? {
        [type]: {
          in: updatedFilters,
        },
      }
    : null;

  return fetchMore({
    updateQuery,
    variables: {
      propertiesInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        search: sanitizeSearch(search, searchFields),
        filter,
      },
    },
  });
};

/**
 * Search properties list
 */
export const onSearchChange = (
  fetchMore: FetchMore,
  metadata: PropertiesWithMeta,
  target: EventTarget & HTMLInputElement,
): ReturnType<FetchMore> => {
  const { pageSize, sort, filter } = metadata;

  let search: Record<string, Record<string, string>> | null = {};
  if (target.value.length) {
    searchFields.forEach((field) => {
      search = search || {};
      search[field] = { iLike: `%${target.value}%` };
    });
  } else {
    search = null;
  }

  return fetchMore({
    updateQuery,
    variables: {
      propertiesInput: {
        page: 0,
        pageSize,
        sort: _.pickBy(_.omit(sort, '__typename'), _.identity),
        filter: !_.isEmpty(filter) && filter?.status ? { status: _.omit(filter?.status, '__typename') } : null,
        search,
      },
    },
  });
};

/**
 * Properties List view component
 */
export const PropertiesList: React.FC<Props> = (props) => {
  const className = 'PropertiesList';
  const { data, fetchMore } = useQuery<Query>(listProperties, {
    fetchPolicy: 'network-only',
    variables: {
      propertiesInput: {
        page: 0,
        pageSize: 10,
        sort: { propertyName: 'ASC' },
      },
    },
  });
  const { edges, ...metadata } = data?.listProperties || {};
  const debouncedOnSearchChange = (
    typeof onSearchChange === 'function' ? _.debounce(onSearchChange, 500) : onSearchChange
  ) as typeof onSearchChange;
  const debouncedFetchMore = (
    typeof fetchMore === 'function' ? _.debounce(fetchMore, 500) : fetchMore
  ) as typeof fetchMore;
  const columns = [
    { id: 'propertyName', label: 'Property Name' },
    { id: 'pid', label: 'Property ID' },
    { id: 'totalSites', label: 'Sites' },
    { id: 'totalChargers', label: 'Chargers' },
    { id: 'hostName', label: 'Host' },
    { id: 'address', label: 'Address' },
  ];
  const rows = edges?.map((edge) => ({
    propertyName: {
      value: _.get(edge, 'propertyName', ''),
      to: `/hosts/${_.get(edge, 'host.altId')}/profile`,
    },
    pid: { value: _.toUpper(edge?.pid || '') },
    totalSites: { value: _.get(edge, 'sites.total', 0) },
    totalChargers: {
      value: _.reduce(
        _.get(edge, 'sites.edges', []),
        (chargerTotal, site) => {
          return chargerTotal + _.get(site, 'chargers.total', 0);
        },
        0,
      ),
    },
    hostName: {
      value: _.get(edge, 'host.salesforceHost.hostName', ''),
      to: `/hosts/${_.get(edge, 'host.altId')}/profile`,
    },
    address: {
      value: getFullAddress({
        streetAddress: _.get(edge, 'address1', ''),
        city: _.get(edge, 'locality', ''),
        state: _.get(edge, 'administrativeArea', ''),
        postalCode: _.get(edge, 'postalCode', ''),
      }),
    },
  }));
  let customClass = className;

  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            Properties
          </Typography>

          <Typography className={className} variant="caption">
            Currently viewing {metadata.total}
          </Typography>
        </div>

        <div className={className}>
          <PropertiesListSearch
            className={`${className} search`}
            type="property"
            onSearchChange={(search) => debouncedOnSearchChange(fetchMore, metadata, search)}
          />
        </div>
      </header>

      <PropertiesListFilters
        fetchMore={debouncedFetchMore}
        metadata={metadata}
        onFilterChange={(
          parentFetchMore: FetchMore,
          parentMetadata: PropertiesWithMeta,
          filters: string[],
          type: string,
        ) => onFilterChange(parentFetchMore, parentMetadata, filters, type)}
      />

      <Divider />

      <Table className={className} data-testid="property-management-table">
        <TableHead className={className}>
          <TableRow className={className}>
            {columns.map((column) => (
              <TableCell key={column.id} className={className}>
                <TableSortLabel
                  active={_.isString(_.get(metadata, `sort.${column.id}`))}
                  direction={(_.toLower(_.get(metadata, `sort.${column.id}`, 'asc')) || 'asc') as 'asc'}
                  onClick={
                    ['pid', 'propertyName', 'hostName'].includes(column.id)
                      ? onSort(fetchMore, metadata, column.id)
                      : () => {}
                  }
                  disabled={!['pid', 'propertyName', 'hostName'].includes(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={className}>
          {(rows || []).map((row, key) => (
            <TableRow key={key} className={className}>
              {columns.map((column, i) => {
                const rowCol = row[column.id as keyof typeof row] as unknown as { to: string; value: string };

                return (
                  <TableCell key={i} className={className}>
                    {rowCol?.to ? (
                      <Link className={className} to={rowCol?.to}>
                        {rowCol?.value}
                      </Link>
                    ) : (
                      rowCol?.value
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>

        <TableFooter className={className}>
          <TableRow className={className}>
            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={metadata.total || 0}
              rowsPerPage={metadata.pageSize || 10}
              page={metadata.page || 0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={onChangePage(fetchMore, metadata)}
              onRowsPerPageChange={onChangeRowsPerPage(fetchMore, metadata)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};
