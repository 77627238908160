import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${spacing(6)}px;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};
  padding-bottom: ${spacing(9)}px;

  > section {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};

    header {
      h2 {
        color: ${config.colors.accentBlue};
      }
    }
  }
`;
