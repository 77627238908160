import { TextInfo } from '@evgo/react-material-components';
import {
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Error as ErrorIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment, useCallback } from 'react';
import days from '../../../../lib/fixtures/days.json';
import defaultTariffHour from '../../../../lib/fixtures/defaultTariffHour.json';
import { Styled } from './styles';

export interface Props {
  className?: string;
  id?: string;
  path: string;
  readOnly: boolean;
}

type TableColumnKeys =
  | 'startTime'
  | 'endTime'
  | 'rate'
  | 'filler'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'deleteAction';

interface TableColumnsSettings {
  key: TableColumnKeys;
  label: string;
  width: string;
  align: TableCellProps['align'];
}

export const TariffHours: React.FC<Props> = ({ className: parentClass, id: parentId, path, readOnly }) => {
  const iConnector = Number(_.join(_.initial(_.head(_.reverse(_.split(_.head(_.split(path, '.')), '[')))), ''));
  const iRate = Number(_.join(_.initial(_.head(_.reverse(_.split(path, '[')))), ''));
  const i = `${iConnector}-${iRate}`;
  const id = _.kebabCase('TariffHours');
  const className = i;
  const { errors, handleBlur, handleChange, isSubmitting, setFieldValue, values } = useFormikContext();
  const columns = [
    { key: 'startTime', label: 'Start Time', width: '20%', align: 'left' },
    { key: 'endTime', label: 'End Time', width: '20%', align: 'left' },
    { key: 'rate', label: 'Rate', width: '20%', align: 'left' },
    { key: 'filler', label: '', width: '32%', align: 'center' },
    { key: 'monday', label: 'M', width: '1%', align: 'center' },
    { key: 'tuesday', label: 'Tu', width: '1%', align: 'center' },
    { key: 'wednesday', label: 'W', width: '1%', align: 'center' },
    { key: 'thursday', label: 'Th', width: '1%', align: 'center' },
    { key: 'friday', label: 'F', width: '1%', align: 'center' },
    { key: 'saturday', label: 'S', width: '1%', align: 'center' },
    { key: 'sunday', label: 'Su', width: '1%', align: 'center' },
    { key: 'deleteAction', label: '', width: '1%', align: 'center' },
  ] as TableColumnsSettings[];
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  // Handles adding a custom hour to the current rate
  const handleAddHour = useCallback(() => {
    const { hours } = _.get(values, path);
    const value = [...hours, defaultTariffHour];

    setFieldValue(`${path}.hours`, value);
  }, [values, path, setFieldValue]);

  // Handles changing end time
  const handleChangeEndTime = useCallback(
    (event) => {
      const {
        target: { name, value: oldValue },
      } = event;
      const value = `${oldValue}:59`;

      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  // Handles changing start time
  const handleChangeStartTime = useCallback(
    (event) => {
      const {
        target: { name, value: oldValue },
      } = event;
      const value = `${oldValue}:00`;

      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  // Handles removing the current custom from the current rate
  const handleRemoveHour = useCallback(
    (j) => () => {
      const { hours } = _.get(values, path);
      const value = [...hours];

      _.pullAt(value, [j]);
      setFieldValue(`${path}.hours`, value);
    },
    [path, setFieldValue, values],
  );

  type ColumnErrors = {
    [key in TableColumnKeys]: string;
  };

  const columnErrors = {} as ColumnErrors;

  if (errors && _.get(errors, `${path}.hours`)) {
    _.forEach(_.get(errors, `${path}.hours`), (error) => {
      if (error) {
        if (error.startTime) columnErrors.startTime = error.startTime;
        if (error.endTime) columnErrors.endTime = error.endTime;
        if (error.rate) columnErrors.rate = error.rate;
      }
    });
  }

  return (
    <Styled>
      <Table id={parentId} className={customClass}>
        <colgroup>
          {columns.map(({ key, width }) => (
            <col key={key} style={{ width }} />
          ))}
        </colgroup>

        <TableHead className={className}>
          <TableRow className={className}>
            {columns.map((column) => (
              <TableCell align={column.align} className={className} component="th" key={column.key}>
                {column.label}
                {columnErrors && columnErrors[column.key] && (
                  <Tooltip
                    className={className}
                    title={columnErrors[column.key]}
                    enterDelay={200}
                    leaveDelay={200}
                    placement="top"
                    id={`${id}-hour-${i}-column-${column.key}-error-tooltip`}
                  >
                    <ErrorIcon color="error" />
                  </Tooltip>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={className}>
          {_.get(values, `${path}.hours`, []).map((row: Record<string, unknown>, j: number) => (
            <TableRow className={className} key={j}>
              <TableCell className={className}>
                <TextInfo
                  className={className}
                  disabled={isSubmitting || readOnly}
                  id={`${id}-${i}-hour-${j}-start-time-input`}
                  inputProps={{ className, step: 60 }}
                  name={`${path}.hours[${j}].startTime`}
                  onBlur={handleBlur}
                  onChange={handleChangeStartTime}
                  type="time"
                  error={!!_.get(errors, `${path}.hours[${j}].startTime`)}
                  value={_.join(_.split(_.get(values, `${path}.hours[${j}].startTime`), ':', 2), ':')}
                />
              </TableCell>

              <TableCell className={className}>
                <TextInfo
                  className={className}
                  disabled={isSubmitting || readOnly}
                  id={`${id}-${i}-hour-${j}-end-time-input`}
                  inputProps={{ className, step: 60 }}
                  name={`${path}.hours[${j}].endTime`}
                  onBlur={handleBlur}
                  onChange={handleChangeEndTime}
                  type="time"
                  error={!!_.get(errors, `${path}.hours[${j}].endTime`)}
                  value={_.join(_.split(_.get(values, `${path}.hours[${j}].endTime`), ':', 2), ':')}
                />
              </TableCell>

              <TableCell className={className}>
                <TextInfo
                  className={`${className} rate`}
                  disabled={isSubmitting || readOnly}
                  id={`${id}-${i}-hour-${j}-rate-input`}
                  name={`${path}.hours[${j}].rate`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  adornmentStart={<>$</>}
                  error={!!_.get(errors, `${path}.hours[${j}].rate`)}
                  value={_.get(values, `${path}.hours[${j}].rate`)}
                />
              </TableCell>

              <TableCell className={className} />

              {days.map((day) => (
                <TableCell key={day} className={className}>
                  <Checkbox
                    checked={_.get(values, `${path}.hours[${j}].${day}`)}
                    className={className}
                    disabled={isSubmitting || readOnly}
                    inputProps={{ id: `${id}-${i}-hour-${j}-${day}-checkbox` }}
                    name={`${path}.hours[${j}].${day}`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </TableCell>
              ))}

              <TableCell className={className}>
                <IconButton
                  className={`${className}${readOnly || _.get(values, `${path}.hours.length`) <= 1 ? ' hidden' : ''}`}
                  disabled={isSubmitting || readOnly || _.get(values, `${path}.hours.length`) <= 1}
                  id={`${id}-${i}-hour-${j}-remove-button`}
                  onClick={handleRemoveHour(j)}
                >
                  <RemoveCircleOutlineIcon className={className} color={isSubmitting ? 'inherit' : 'primary'} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!readOnly && (
        <Fragment>
          <FormHelperText className={className}>
            {typeof _.get(errors, `${path}.hours`) === 'string' ? _.get(errors, `${path}.hours`) : null}
          </FormHelperText>

          <Button
            className={className}
            disabled={isSubmitting}
            id={`${id}-${i}-hours-add-button`}
            onClick={handleAddHour}
          >
            <AddCircleOutlineIcon className={className} color={isSubmitting ? 'inherit' : 'primary'} />
            &nbsp;Add
          </Button>
        </Fragment>
      )}
    </Styled>
  );
};
