/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import days from '../../fixtures/days.json';

/**
 * Transforms the Falcon plan response into Formik format
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transformPlanResponse = (planData: any) => {
  const translateElements = (elements: any) => {
    const newElements = _.reduce(
      elements,
      (reducedRates: any, element: any) => {
        const newRates = [...reducedRates];
        const connectorType = Number(_.get(element, 'connectorType.id')) || '';
        const { maxDuration, day, startTime, endTime, discount } = element;
        const itemData = { maxDuration, endTime, discount, startTime, [day.toLowerCase()]: true };
        const i = _.findIndex(reducedRates, { connectorType });

        if (i === -1) newRates.push({ connectorType, hours: [itemData] });
        else newRates[i].hours.push(itemData);

        return newRates;
      },
      [],
    );

    _.forEach(newElements, (parentRate, i) => {
      newElements[i].hours = _.reduce(
        parentRate.hours,
        (reducedHours: any, hour: any) => {
          const newHours = [...reducedHours];
          const { endTime, discount: numericalRate, maxDuration, startTime, ...hourDays } = hour;
          const discount = numeral(numericalRate).format('00.00');
          const j = _.findIndex(reducedHours, { endTime, discount, startTime });

          if (j === -1) {
            _.forEach(_.difference(days, _.keys(hourDays)), (day) => _.set(hourDays, day, false));
            newHours.push({
              endTime,
              maxDuration: !_.isNull(maxDuration) ? maxDuration.toString() : '',
              discount: numeral(discount).format('00.00'),
              startTime,
              ...hourDays,
            });
          } else {
            newHours[j] = { ...reducedHours[j], ...hourDays };
          }

          return newHours;
        },
        [],
      );
    });

    return newElements;
  };

  const values = {
    altId: _.get(planData, 'altId'),
    planName: _.get(planData, 'planName') || '',
    subheading: _.get(planData, 'subheading') || '',
    description: _.get(planData, 'description') || '',
    pageHeader: _.get(planData, 'pageHeader') || '',
    detailSections: _.get(planData, 'detailSections') || [],
    detailSessionTimes: _.get(planData, 'detailSessionTimes') || [],
    costFactor: _.get(planData, 'costFactor') || '',
    legalese: _.get(planData, 'legalese') || '',
    displayName: _.get(planData, 'displayName') || '',
    displayToCustomer: _.get(planData, 'displayToCustomer') || false,
    displayToLoggedOutUser: _.get(planData, 'displayToLoggedOutUser') || false,
    driverRank:
      _.get(planData, 'displayPlanCard') && !_.get(planData, 'driverRank') ? 0 : _.get(planData, 'driverRank'),
    displayPlanCard: _.get(planData, 'displayPlanCard') || false,
    planStatusId: _.get(planData, 'planStatusId') || '',
    planCode: _.get(planData, 'planCode') || '',
    financeCode: _.get(planData, 'financeCode') || '',
    intervalUnit: _.get(planData, 'intervalUnit') || '',
    intervalUnitId: _.get(planData, 'intervalUnitId') || '',
    intervalLength: _.get(planData, 'intervalLength') || '',
    badge: _.get(planData, 'badge') || '',
    balanceTypeId: _.get(planData, 'balanceTypeId') || '',
    ctaLabel: _.get(planData, 'ctaLabel') || '',
    discount: numeral(planData.discount).format('00.00'),
    templateId: _.get(planData, 'templateId') || null,
    isAutoEnrolledInRewards: _.get(planData, 'isAutoEnrolledInRewards'),
    duration: _.get(planData, 'duration') || '',
    enrollmentOpen:
      _.get(planData, 'enrollmentOpen') && _.get(planData, 'enrollmentOpen').split('T').length
        ? moment(_.get(planData, 'enrollmentOpen').split('T')[0]).format('YYYY-MM-DD')
        : '',
    enrollmentClosed:
      _.get(planData, 'enrollmentClosed') && _.get(planData, 'enrollmentClosed').split('T').length
        ? moment(_.get(planData, 'enrollmentClosed').split('T')[0]).format('YYYY-MM-DD')
        : '',
    terminationBehavior: _.get(planData, 'terminationBehavior') || '',
    terminationBehaviorId: _.get(planData, 'terminationBehaviorId') || '',
    rolloverPlanId: _.get(planData, 'rolloverPlan.altId') || '',
    cardBrandId: _.get(planData, 'cardBrandId') || '',
    createdAt: _.get(planData, 'createdAt') || '',
    requireVin: !!_.get(planData, 'requireVin'),
    elements: translateElements(_.get(planData, 'elements.edges', [])),
    products: _.flatten(
      planData?.products?.edges.map((product: any) => {
        let newProduct = {};
        const productCopy = { ..._.omit(_.cloneDeep(product), '__typename') };
        if (_.get(product, 'subscription', null) && !_.get(product, 'credit', null)) {
          const { subscription } = productCopy;
          newProduct = {
            productType: productCopy.productType,
            productTypeId: productCopy.productTypeId,
            ...subscription,
            subscriptionFee: numeral(subscription.subscriptionFee).format('00.00'),
          };
        } else if (_.get(product, 'credit', null) && !_.get(product, 'subscription', null)) {
          const { credit } = productCopy;
          newProduct = {
            productType: productCopy.productType,
            productTypeId: productCopy.productTypeId,
            ...credit,
            creditAmount: numeral(credit.creditAmount).format('00.00'),
            creditOneTimeAmount: numeral(credit.creditOneTimeAmount).format('00.00'),
          };
        }
        return newProduct;
      }),
    ),
  };

  return values;
};
