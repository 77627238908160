import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { ChargingLevel, Maybe, Query } from 'src/@types';
import { ListChargersV2Charger } from 'src/@types/shared';
import { MultiSelectFilter } from 'src/components/shared/MultiSelectFilter';
import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';
import { SearchField } from 'src/components/shared/SearchField';
import { Filters } from '../../filterHelpers';

import { listGeographicalRegions } from 'src/apollo/queries/geographicalRegions';
import { geographicalRegionsInput, getUniqueOptions, Options, shapeGeographicalRegions } from 'src/lib/helpers';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

type ChargerSelectionFilterProps = {
  filters: Filters;
  data?: Maybe<Maybe<ListChargersV2Charger>[]>;
  onChange: (filters: Filters) => void;
};

const chargerLevelsOptions: Options[] = [
  { value: ChargingLevel.L2, label: 'Level 2 Charging' },
  { value: ChargingLevel.Dcfc, label: 'DC Fast Charging' },
];

export function ChargerSelectionFilter({ filters, data, onChange }: ChargerSelectionFilterProps) {
  const { track } = useAnalytics();
  const zipOptions: Options[] = data
    ? getUniqueOptions(data, {
        value: 'postalCode',
        label: 'postalCode',
      })
    : [];

  const { data: geographicalRegions, loading: geoRegionsLoading } = useQuery<Query>(listGeographicalRegions, {
    variables: geographicalRegionsInput,
  });

  const { shapedStateOptions } = shapeGeographicalRegions(geographicalRegions);

  return (
    <Box display="flex" alignItems="flex-start">
      <Box pt={3} pb={4}>
        <SearchField
          initialValue={filters.search}
          onChange={(value) => {
            onChange({ ...filters, search: value });
            track(events.chargerSelectionModal.SEARCHED_CHARGER_LIST);
          }}
        />
      </Box>
      <Box pt={1} pb={4} pl={2}>
        <MultiSelectSearchFilter
          label="State"
          value={filters.states || []}
          options={shapedStateOptions?.map((state) => ({
            value: state.value,
            label: state.label,
          }))}
          onChange={(states) => {
            onChange({ ...filters, states });
            track(events.chargerSelectionModal.FILTERED_CHARGER_LIST, {
              filterType: 'state',
            });
          }}
          debounce={500}
          loading={geoRegionsLoading}
        />
      </Box>

      <Box pt={1} pb={4} pl={2}>
        <MultiSelectFilter
          label="Charger Types"
          options={chargerLevelsOptions}
          onChange={(chargerLevels) => {
            onChange({ ...filters, chargerLevels });
            track(events.chargerSelectionModal.FILTERED_CHARGER_LIST, {
              filterType: 'type',
            });
          }}
          debounce={500}
        />
      </Box>

      <Box pt={1} pb={4} pl={2}>
        <MultiSelectSearchFilter
          label="Zip Code"
          value={filters.zips || []}
          options={zipOptions}
          onChange={(zips) => {
            onChange({ ...filters, zips });
            track(events.chargerSelectionModal.FILTERED_CHARGER_LIST, {
              filterType: 'zip code',
            });
          }}
          debounce={500}
        />
      </Box>
    </Box>
  );
}
