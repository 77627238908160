/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ChargerOverview } from './ChargerOverview';
import { ChargerSiteInformation } from './ChargerSiteInformation';

/**
 * Charger profile component
 */
export const ChargerProfileTab: React.FC = () => {
  const { altId, url } = useParams<{ altId: string; url: string }>();

  if (url === '/chargers/new' && altId) {
    return <Navigate to={`/chargers/${altId}/profile`} />;
  }

  return (
    <Fragment>
      <ChargerOverview />
      <ChargerSiteInformation />
    </Fragment>
  );
};
