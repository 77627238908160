import { Button, InputLabel, Typography } from '@material-ui/core';
import { ArrowBack, Face } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatUSNumber, titleCase } from '../../../../../lib/helpers';
import { Styled } from './styles';

/** Driver Header component. */
export const DriverHeader: React.FC = () => {
  const id = _.kebabCase('DriverHeader');
  const className = id;
  const { status } = useFormikContext();

  const info = (label: string, value: string) => {
    return (
      <article className={className} id={`${id}-${_.kebabCase(label)}-info-section`}>
        <InputLabel className={className} shrink>
          {label}
        </InputLabel>

        <div className={`${className} ${_.kebabCase(label)}`}>
          {label === 'Account Number' ? (
            <Typography className={className}>
              <Link
                className={`${className} account-link`}
                id={`${id}-account-link`}
                data-testid="driver-edit-header-account-link"
                to={`/accounts/${_.get(status, 'account.altId', '')}/profile`}
              >
                {value}
              </Link>
            </Typography>
          ) : (
            <Typography className={className} id={`${id}-${_.kebabCase(label)}`}>
              {value}
            </Typography>
          )}
        </div>
      </article>
    );
  };

  const name = status ? `${_.get(status, 'firstName', '')} ${_.get(status, 'lastName', '')}` : 'Create Driver';

  return (
    <Styled className={className}>
      <div className={className}>
        <section className={`${className} back`}>
          <Button className={className} id={`${id}-back-button`} size="small" to="/drivers/list" component={Link}>
            <ArrowBack className={`${className} arrow`} /> All Drivers
          </Button>
        </section>

        <section className={`${className} title`}>
          <Face className={className} />
          <Typography className={className} id={`${id}-driver-name`} variant="h5">
            {name}
          </Typography>
        </section>

        <section className={`${className} info`}>
          {info('Account Number', _.get(status, 'account.id', ''))}
          {info('Address', _.get(status, 'account.fullAddress', ''))}
          {info('Email', _.get(status, 'email', ''))}
          {info('Phone', formatUSNumber(_.get(status, 'phone', '')))}
          {info('Account Type', titleCase(_.get(status, 'account.accountType', '')))}
          {info('Classification', 'Driver')}
        </section>
      </div>
    </Styled>
  );
};
