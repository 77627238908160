import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  margin-bottom: ${spacing(4)}px;

  header {
    padding: ${`0 ${spacing(3)}px`};
  }

  h2 {
    color: ${colors.accentBlue};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: ${`0 ${spacing(2)}px ${spacing(2)}px`};

        .disabled {
          pointer-events: none;
        }

        > button {
          position: relative;
          width: 92px;
          height: 92px;
          margin: ${`${spacing(2)}px ${spacing()}px 0`};
          border: ${`${spacing() / 4}px dashed ${palette.text.secondary}`};
          color: ${palette.text.secondary};

          input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        h3 {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }
      }
    }
  }

  svg {
    &.left {
      margin-right: ${spacing()}px;
    }
  }
`;
