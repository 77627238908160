import { gql } from '@apollo/client';

export const createUpload = gql`
  mutation createUpload($input: Upload!) {
    createUpload(input: $input) {
      altId
      url
      fileName
      mimeType
      encoding
      createdAt
    }
  }
`;
