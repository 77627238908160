/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';

const { palette, shape, spacing } = theme;

export const Styled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    position: absolute;
    width: 70%;
    border-radius: ${shape.borderRadius}px;
    background-color: ${palette.common.white};
    box-shadow: ${theme.shadows[5]};
    outline: none;

    .header {
      padding-right: ${spacing(3.125)}px;
      padding-left: ${spacing(3.125)}px;

      .close {
        margin-left: auto;
        justify-content: flex-end;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      padding: ${spacing(3.125)}px;

      .available {
        color: ${palette.secondary[700]};
      }

      .in-use {
        color: #44a047;
      }

      .unavailable {
        color: #666666;
      }

      p {
        margin-right: ${spacing(3)}px;
        margin-left: ${spacing()}px;
      }
    }
  }
`;
