import { ChargersConsumptionForExtendData, Maybe } from 'src/@types';
import { sumTimeStrings } from 'src/lib/helpers/sumTimeStrings';

export type Evse = {
  chargerId: string;
  evseId: string;
  chargerName: string;
  lastSessionVendorId: Maybe<number> | undefined;
  connectorMaxOutput: number;
  connectorStatus: string[];
  totalSessions: number;
  totalSessionsPower: number;
  totalSessionsTime: string;
  reservable: boolean;
};

export const groupByEvse = (listConnectors: ChargersConsumptionForExtendData[]) => {
  const listEvses: { [key: string]: Evse } = {};
  listConnectors.map(
    ({
      chargerId,
      evseId,
      chargerName,
      lastSessionVendorId,
      connectorMaxOutput,
      connectorStatus,
      totalSessions,
      totalSessionsPower,
      totalSessionsTime,
      reservable,
    }) => {
      if (!listEvses[evseId])
        listEvses[evseId] = {
          chargerId,
          evseId,
          chargerName,
          lastSessionVendorId,
          connectorMaxOutput,
          connectorStatus: [],
          reservable: reservable || false,

          totalSessions: 0,
          totalSessionsPower: 0,
          totalSessionsTime: '00:00:00',
        };

      listEvses[evseId].totalSessions += totalSessions;
      listEvses[evseId].totalSessionsPower += totalSessionsPower;
      listEvses[evseId].totalSessionsTime = sumTimeStrings(listEvses[evseId].totalSessionsTime, totalSessionsTime);

      if (connectorStatus) listEvses[evseId].connectorStatus.push(connectorStatus);
      if (lastSessionVendorId) listEvses[evseId].lastSessionVendorId = lastSessionVendorId;
    },
  );

  return Object.values(listEvses);
};
