import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { ExpandMore, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { kebabCase, get, debounce, find } from 'lodash';
import M from 'materialize-css';
import React, { Fragment, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FalconConstraint, Host, Mutation, Property, Query, Site } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import config from 'src/config';
import { validateAddress } from '../../../../../apollo/queries/addresses';
import { listGeographicalRegions } from '../../../../../apollo/queries/geographicalRegions';
import { listHosts } from '../../../../../apollo/queries/hosts';
import { listFalconConstraints } from '../../../../../apollo/queries/options';
import { geographicalRegionsInput, shapeGeographicalRegions } from '../../../../../lib/helpers';
import { useBlurDropdown } from '../../../../../lib/hooks';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  expanded?: boolean;
  onChange?: () => void;
  className?: string;
}

const siteUtilityCompanyOptions = [
  { value: 'HOST_POWER', label: 'Host Power' },
  { value: 'UTILITY_COMPANY_1', label: 'Utility Company 1' },
  { value: 'UTILITY_COMPANY_2', label: 'Utility Company 2' },
  { value: 'UTILITY_COMPANY_3', label: 'Utility Company 3' },
  { value: 'UTILITY_COMPANY_4', label: 'Utility Company 4' },
];

/**
 * Validate site address
 */
export const onUpdateAddress = (
  e: React.ChangeEvent<HTMLInputElement>,
  field: keyof Site,
  values: Site,
  mutate: (
    options?: MutationFunctionOptions<Mutation, OperationVariables, DefaultContext, ApolloCache<never>> | undefined,
  ) => Promise<FetchResult<Mutation, Record<string, never>, Record<string, never>>>,
): void => {
  const valuesCopy = { ...values };
  (valuesCopy[field] as Site) = e?.target?.value || values[field];
  const { address1, administrativeArea, locality, postalCode, latitude, longitude } = valuesCopy;

  if (!address1 || !administrativeArea || !locality || !postalCode || latitude || longitude) return;
  const addressInput = { address1, administrativeArea, locality, postalCode };

  mutate({ variables: { addressInput } });
};

/**
 * Site Profile component
 */
export const SiteProfile: React.FC<Props> = (props) => {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue, setValues } = useFormikContext<
    Site & { status: string }
  >();

  const { altId } = useParams<{ altId: string }>();
  const id = kebabCase('SiteProfile');
  const className = id;
  const { state: preset } = useLocation();

  useEffect(() => {
    if (preset) {
      setFieldValue('property.host.altId', get(preset, 'hostId', ''));
      setFieldValue('property.pid', get(preset, 'pid', ''));
      setFieldValue('property.propertyName', get(preset, 'propertyName', ''));
    }
  }, [preset, setFieldValue]);

  const { data: geographicalRegions } = useQuery<Query>(listGeographicalRegions, {
    variables: geographicalRegionsInput,
  });
  const { shapedStateOptions, shapedProvinceOptions } = shapeGeographicalRegions(geographicalRegions);
  const stateOptions = [...shapedStateOptions, ...shapedProvinceOptions];

  const { data: listHostsData } = useQuery<Query>(listHosts, { variables: { hostsInput: { pageSize: 1000 } } });
  const hostOptions = listHostsData?.listHosts?.edges || ([] as Host[]);

  const [onValidateAddress] = useMutation<Mutation>(validateAddress, {
    onCompleted: (result) => {
      const { latitude, longitude } = result.validateAddress;
      setValues({ ...values, latitude: Number(latitude), longitude: Number(longitude) });
    },
  });

  const debouncedOnValidateAddress =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (typeof onValidateAddress === 'function' ? debounce(onValidateAddress, 500) : onValidateAddress) as any;

  const siteHosts = hostOptions.map((item) => ({
    value: item?.altId,
    label: item?.hostName,
  }));

  const hostElement = hostOptions.find((element) => element?.altId === get(values, 'property.host.altId', '')) as Host;
  const siteProperties = (hostElement?.properties?.edges || ([{ pid: '', propertyName: '' }] as Property[])).map(
    (item) => ({
      value: item?.pid,
      label: item?.propertyName,
    }),
  );

  const { data: statusOptions, loading: statusOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'status',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });
  let shapedStatusOptions: LabelValue<number>[] = [];
  if (!statusOptionsLoading) {
    const statusOptionsArr = statusOptions?.listFalconConstraints?.edges || ([] as FalconConstraint[]);
    shapedStatusOptions = statusOptionsArr
      .map((option) => ({
        label: option?.columnText as string,
        value: Number.parseInt(option?.id as string, 10),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  const { data: locationTypes, loading: locationTypesLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'location_type',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });
  let locationTypeOptions: LabelValue<number>[] = [];
  if (!locationTypesLoading) {
    const locationTypesArr = locationTypes?.listFalconConstraints?.edges || ([] as FalconConstraint[]);
    locationTypeOptions = locationTypesArr.map((option) => ({
      label: option?.columnText as string,
      value: Number.parseInt(option?.id as string, 10),
    }));
  }

  const { data: siteAcessTypes, loading: siteAcessTypesLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'access',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });
  let siteAccessTypeOptions: LabelValue<number>[] = [];
  if (!siteAcessTypesLoading) {
    const siteAcessTypesArr = siteAcessTypes?.listFalconConstraints?.edges || ([] as FalconConstraint[]);
    siteAccessTypeOptions = siteAcessTypesArr.map((option) => ({
      label: option?.columnText as string,
      value: Number.parseInt(option?.id as string, 10),
    }));
  }

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  let carousel = null;
  let CarouselRef: Element | null = null;
  const siteImages = get(values, 'siteImages.edges', []);
  if (siteImages.length) {
    carousel = (values?.siteImages?.edges || []).map((edge, index) => {
      return (
        <a
          className={`${className} carousel-item`}
          id={`${id}-photo-carousel-photo-anchor-${index}`}
          key={index}
          href={`#${index}`}
        >
          <img
            className={className}
            id={`${id}-photo-carousel-photo-${index}`}
            src={get(edge, 'media.url')}
            height={300}
          />
        </a>
      );
    });
  }

  useEffect(() => {
    M.Carousel.init(CarouselRef as Element, { duration: 200, dist: -200 });
  }, [CarouselRef, siteImages]);

  const handleBlurDropdown = useBlurDropdown();

  const center = `${get(values, 'latitude', '')},${get(values, 'longitude', '')}`;
  const marker = `&markers=color:0x008000%7C${get(values, 'latitude', '')},${get(values, 'longitude', '')}`;
  const zoom = '&zoom=15';
  const size = '&size=1500x350';
  const key = `&key=${config.env.google.apiKey}`;
  const staticMap =
    get(values, 'latitude', '') && get(values, 'longitude', '') ? (
      <img
        id={`${id}-map`}
        className={`${className} map`}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${center}${zoom}${size}${marker}${key}`}
      />
    ) : (
      <img
        id={`${id}-map-disabled`}
        className={`${className} map disabled`}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=34.03334,-118.451664${zoom}${size}&markers=color:0x008000%7C34.03334,-118.451664${key}`}
      />
    );

  return (
    <StyledAccordion
      id={`${id}-expansion-panel`}
      className={customClass}
      expanded={props.expanded}
      defaultExpanded={true}
      onChange={props.onChange}
    >
      <AccordionSummary className={className} expandIcon={!!altId && <ExpandMore />} component="header">
        <Typography className={className} variant="h6" component="h2">
          Site Profile
        </Typography>
      </AccordionSummary>

      <Divider />
      {carousel && (
        <div className={`${className} carousel-container`} id={`${id}-photo-carousel-container`}>
          <div
            className={`${className} carousel carousel-slider`}
            ref={(Carousel) => {
              CarouselRef = Carousel;
            }}
            id={`${id}-photo-carousel`}
          >
            {carousel}
          </div>
          <IconButton
            className={className}
            id={`${id}-photo-carousel-previous-photo-button`}
            onClick={() => {
              const instance = M.Carousel.getInstance(CarouselRef as Element);
              instance.prev();
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            className={className}
            id={`${id}-photo-carousel-next-photo-button`}
            onClick={() => {
              const instance = M.Carousel.getInstance(CarouselRef as Element);
              instance.next();
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
      )}
      <Divider />

      <AccordionDetails className={`${className} panel-details`}>
        <Fragment>
          <div className={className}>
            <div className={className}>
              <Typography className={className} variant="subtitle1" component="h3">
                Site Details
              </Typography>

              <TextInfo
                id={`${id}-site-name`}
                className={className}
                error={touched?.siteName && errors?.siteName}
                helpertext={errors?.siteName}
                label="Site Name *"
                labelProps={{ className }}
                name="siteName"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values?.siteName}
              />

              <TextInfo
                id={`${id}-display-name`}
                className={`${className} half`}
                error={errors?.displayName && touched?.displayName}
                helpertext={errors?.displayName}
                label="Display Name *"
                labelProps={{ className }}
                name="displayName"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values?.displayName}
              />

              <TextInfo
                id={`${id}-sid`}
                className={`${className} half`}
                error={errors?.sid && touched?.sid}
                helpertext={errors?.sid}
                label="Site ID *"
                labelProps={{ className }}
                name="sid"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values?.sid}
              />

              <Dropdown
                id={`${id}-status`}
                label="Site Status *"
                className={className}
                labelProps={{ className }}
                menuProps={{ className }}
                options={shapedStatusOptions}
                value={values?.siteStatusId}
                name="site-status"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValues({ ...values, siteStatusId: +e.target.value })
                }
                error={errors?.siteStatusId && touched?.siteStatusId}
                helpertext={errors?.siteStatusId}
              />

              <Dropdown
                id={`${id}-host`}
                className={`${className} half`}
                error={get(errors, 'property.host.altId') && get(touched, 'property.host.altId')}
                helpertext={get(errors, 'property.host.altId')}
                label="Site Host *"
                name="property.host.altId"
                options={siteHosts}
                onBlur={handleBlurDropdown}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  return setValues({
                    ...values,
                    property: {
                      host: {
                        altId: target?.value,
                      },
                      propertyName: null,
                      pid: null,
                    },
                  });
                }}
                required
                value={get(values, 'property.host.altId')}
              />

              <Dropdown
                id={`${id}-property`}
                label="Site Property *"
                className={`${className} half`}
                disabled={!get(values, 'property.host.altId', false)}
                error={get(errors, 'property.pid') && get(touched, 'property.pid')}
                helpertext={get(errors, 'property.pid')}
                options={siteProperties}
                onBlur={handleBlurDropdown}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  const pid = get(target, 'value');
                  return setValues({
                    ...values,
                    property: {
                      ...values.property,
                      propertyName: get(find(siteProperties, { value: pid }), 'label'),
                      pid,
                    },
                  });
                }}
                required
                value={get(values, 'property.pid')}
              />
              {!altId && (
                <Fragment>
                  <Dropdown
                    id={`${id}-site-access`}
                    className={clsx(className, 'half')}
                    name="siteAccessId"
                    data-testid="siteAccessId"
                    error={errors?.siteAccessId && touched?.siteAccessId}
                    helpertext={errors?.siteAccessId}
                    label="Access Type *"
                    labelProps={{ className }}
                    menuProps={{ className }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    options={siteAccessTypeOptions}
                    value={values?.siteAccessId}
                  />

                  <Dropdown
                    id={`${id}-location-type`}
                    className={clsx(className, 'half')}
                    name="locationTypeId"
                    data-testid={`${id}-location-type`}
                    error={errors?.locationTypeId && touched?.locationTypeId}
                    helpertext={errors?.locationTypeId}
                    label="Location Type *"
                    labelProps={{ className }}
                    menuProps={{ className }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={locationTypeOptions}
                    value={values?.locationTypeId}
                  />
                </Fragment>
              )}
              <Divider className={className} />

              <Typography className={className} variant="subtitle1" component="h3">
                Meter Details
              </Typography>

              <TextInfo
                id={`${id}-meter-number`}
                className={`${className} half`}
                label="Site Meter Number"
                labelProps={{ className }}
                name="meterNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.meterNumber}
              />

              <TextInfo
                id={`${id}-rated-voltage`}
                className={`${className} half`}
                label="Site Meter Voltage"
                labelProps={{ className }}
                name="ratedVoltage"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.ratedVoltage}
              />

              <Dropdown
                id={`${id}-utility-company`}
                displayValue={get(
                  siteUtilityCompanyOptions.find((element) => element.value === values?.utilityCompany),
                  'label',
                  '',
                )}
                className={`${className}`}
                label="Utility Company Name"
                labelProps={{ className }}
                menuProps={{ className }}
                name="utilityCompany"
                onChange={handleChange}
                options={siteUtilityCompanyOptions}
                value={values?.utilityCompany}
              />

              <TextInfo
                id={`${id}-max-current`}
                className={className}
                label="Site Max Current (amps)"
                labelProps={{ className }}
                name="maxCurrent"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.maxCurrent}
              />

              <Typography className={className} variant="subtitle1" component="h3">
                Site Demand Limit
              </Typography>
              <FormControlLabel
                style={{ marginLeft: '0' }}
                control={
                  <Checkbox
                    checked={values?.demandLimitManagementEnabled || false}
                    onChange={handleChange}
                    data-testid={`${id}-demand-limit-management-enabled`}
                    name="demandLimitManagementEnabled"
                    id={`${id}-demand-limit-management-enabled`}
                  />
                }
                label="Enable demand limit"
              />
              <TextInfo
                id={`${id}-retail-demand-limit`}
                data-testid={`${id}-retail-demand-limit`}
                className={className}
                label="Set Demand Limit"
                labelProps={{ className }}
                name="retailDemandLimit"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={!values?.demandLimitManagementEnabled}
                value={values?.retailDemandLimit}
                error={errors?.retailDemandLimit && touched?.retailDemandLimit}
                helpertext={errors?.retailDemandLimit}
              />
            </div>

            <Divider vertical />

            <div className={className}>
              <Typography className={className} variant="subtitle1" component="h3">
                Site Address
              </Typography>

              <TextInfo
                id={`${id}-address-1`}
                className={className}
                error={errors?.address1 && touched?.address1}
                helpertext={errors?.address1}
                label="Address Line 1 *"
                labelProps={{ className }}
                name="address1"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onUpdateAddress(e, 'address1', values, debouncedOnValidateAddress);
                }}
                required
                value={values?.address1}
              />

              <TextInfo
                id={`${id}-address-2`}
                className={className}
                label="Address Line 2"
                labelProps={{ className }}
                name="address2"
                onChange={handleChange}
                value={values?.address2}
              />

              <TextInfo
                id={`${id}-city`}
                className={`${className} half`}
                error={errors?.locality && touched?.locality}
                helpertext={errors?.locality}
                label="City *"
                labelProps={{ className }}
                name="locality"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onUpdateAddress(e, 'locality', values, debouncedOnValidateAddress);
                }}
                required
                value={values?.locality}
              />

              <Dropdown
                id={`${id}-state`}
                name="administrativeArea"
                className={`${className} half`}
                label="State *"
                error={errors?.administrativeArea && touched?.administrativeArea}
                helpertext={errors?.administrativeArea}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onUpdateAddress(e, 'administrativeArea', values, debouncedOnValidateAddress);
                }}
                onBlur={handleBlurDropdown}
                options={stateOptions}
                value={values?.administrativeArea}
              />

              <TextInfo
                id={`${id}-postal-code`}
                className={`${className} half`}
                error={errors?.postalCode && touched?.postalCode}
                helpertext={errors?.postalCode}
                label="ZIP Code *"
                labelProps={{ className }}
                name="postalCode"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onUpdateAddress(e, 'postalCode', values, debouncedOnValidateAddress);
                }}
                required
                value={values?.postalCode}
              />

              <TextInfo
                id={`${id}-country`}
                className={`${className} half`}
                label="Country *"
                labelProps={{ className }}
                disabled
                value={values?.country}
              />

              <TextInfo
                id={`${id}-latitude`}
                className={`${className} half`}
                error={errors?.latitude && touched?.latitude}
                helpertext={errors?.latitude}
                label="Latitude *"
                labelProps={{ className }}
                name="latitude"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values?.latitude}
              />

              <TextInfo
                id={`${id}-longitude`}
                className={`${className} half`}
                error={errors?.longitude && touched?.longitude}
                helpertext={errors?.longitude}
                label="Longitude *"
                labelProps={{ className }}
                name="longitude"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values?.longitude}
              />

              <div className={`${className} map-container`}>{staticMap}</div>
            </div>
          </div>
        </Fragment>
      </AccordionDetails>
    </StyledAccordion>
  );
};
