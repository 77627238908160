/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Transforms the Formik charger values into Falcon format
 */
export const transformChargerValues = (values: any, altId?: string) => {
  let locationType = 'site';
  let locationId = _.get(values, 'site.altId');
  if (!_.isEmpty(_.get(values, 'warehouse'))) {
    locationType = 'warehouse';
    locationId = _.get(values, 'warehouse.altId');
  }

  const chargerInput = {
    chargerName: _.get(values, 'chargerName', ''),
    additionalEquipment: _.get(values, 'additionalEquipment', ''),
    additionalSerial: _.get(values, 'additionalSerial', ''),
    authenticationModeId: Number(_.get(values, 'authenticationModeId', 214)),
    chargerIsManaged: true,
    chargePointIdentity: _.get(values, 'chargePointIdentity', ''),
    chargerModelId: _.get(values, 'chargerModel.altId', ''),
    cid: _.get(values, 'cid', ''),
    coBranding: _.get(values, 'coBranding', ''),
    communicationMethod: _.get(values, 'communicationMethod', ''),
    connectionUri: _.get(values, 'connectionUri', ''),
    description: _.get(values, 'description', ''),
    disabled: _.get(values, 'disabled', false),
    equipmentId: _.get(values, 'equipmentId', ''),
    evseId: _.get(values, 'evseId', null),
    evses: values?.evses?.edges.map((rawEvse: any, evseIndex: number) => {
      const { __typename: a, ...evse } = rawEvse;
      if (!altId) evse.evseId = `${_.get(values, 'evseId')}-${evseIndex + 1}`;
      evse.connectors = rawEvse?.connectors?.edges.map((rawConnector: any, index: number) => {
        const { __typename: b, ...connector } = rawConnector;

        if (connector && connector.connectorType) {
          const { __typename: c, ...connectorType } = connector.connectorType;
          connector.connectorTypeId = connectorType.id;
        }
        connector.uniqueConnectorId =
          connector.uniqueConnectorId || `${_.get(values, 'evseId')}-${evseIndex + 1}-${index + 1}`;
        connector.identityKey = Number(connector.uniqueConnectorId);
        connector.format = 'CABLE';
        connector.outputAmperes = Number(connector.connectorMaxCurrent) || 0;
        connector.outputWatts = Number(connector.connectorMaxCurrent) || 0;
        connector.outputVolts = Number(connector.connectorMaxCurrent) || 0;
        connector.maxConnectorPower = Number(connector.maxConnectorPower);
        connector.legacyConnectorId = connector.legacyConnectorId || '0';

        return _.omit(connector, 'connectorType');
      });
      return { ...evse };
    }),
    excluded: _.get(values, 'excluded', false),
    excludeFromOcpi: _.get(values, 'excludeFromOcpi', false),
    fieldStationStatus: _.get(values, 'fieldStationStatus', ''),
    fundingSource: _.get(values, 'fundingSource', ''),
    hidden: _.get(values, 'hidden', false),
    identityKey: _.get(values, 'identityKey', ''),
    ignoreStatusNotifications: _.get(values, 'ignoreStatusNotifications', false),
    ipAddress: _.get(values, 'ipAddress', ''),
    localLoadBalancingEnabled: _.get(values, 'localLoadBalancingEnabled', false),
    locationType,
    locationId,
    macAddress: _.get(values, 'macAddress', ''),
    maintenanceComments: _.get(values, 'maintenanceComments', ''),
    maxPower: Number(_.get(values, 'maxPower', 0)),
    meterSigningSupported: _.get(values, 'meterSigningSupported', false),
    networkConnectionTypeId: _.get(values, 'networkConnectionTypeId', null),
    nonNetworked: _.get(values, 'nonNetworked', false),
    productIdentityKeyId: 263,
    serialNumber: _.get(values, 'serialNumber', ''),
    showInThirdPartyFilters: _.get(values, 'showInThirdPartyFilters', false),
    simultaneousChargingEnabled: _.get(values, 'simultaneousChargingEnabled', false),
    smartChargingEnabled: _.get(values, 'smartChargingEnabled', false),
    validateMeterSignature: _.get(values, 'validateMeterSignature', false),
    wirelessConnectionCarrierId: _.get(values, 'wirelessConnectionCarrierId', null),
  } as any;

  if (altId) {
    chargerInput.altId = altId;
    chargerInput.ccReaderType = _.get(values, 'ccReaderType', '');
    chargerInput.certified = _.get(values, 'certified', '');
    chargerInput.connectionDate = _.get(values, 'connectionDate') ? _.get(values, 'connectionDate') : null;
    chargerInput.installDate = _.get(values, 'installDate') ? _.get(values, 'installDate') : null;
    chargerInput.lastReportedFirmwareVersion = _.get(values, 'lastReportedFirmwareVersion', '');
    chargerInput.localNetworkMaster = _.get(values, 'localNetworkMaster', '');
    chargerInput.maintenanceVendorId = _.get(values, 'maintenanceVendorId', null);
    chargerInput.modelNotes = _.get(values, 'modelNotes', '');
    chargerInput.mountType = _.get(values, 'mountType', '');
    chargerInput.nextMaintenanceDate = _.get(values, 'nextMaintenanceDate')
      ? _.get(values, 'nextMaintenanceDate')
      : null;
    chargerInput.teslaAdapter = _.get(values, 'teslaAdapter', '');
    chargerInput.usageStartDate = _.get(values, 'usageStartDate') ? _.get(values, 'usageStartDate') : null;
    chargerInput.warrantyLengthId = _.get(values, 'warrantyLengthId', null);
    chargerInput.warrantyStatusId = _.get(values, 'warrantyStatusId', null);
    chargerInput.warrantyStartDate = _.get(values, 'warrantyStartDate') ? _.get(values, 'warrantyStartDate') : null;
    chargerInput.warrantyExpirationDate = _.get(values, 'warrantyExpirationDate')
      ? _.get(values, 'warrantyExpirationDate')
      : null;
  }

  return chargerInput;
};
