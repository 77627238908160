import { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { WeekView } from 'src/components/shared/WeekView';
import { FormValues } from '../index';

type Props = {
  tariffName?: string;
  values: {
    customPricing?: FormValues['customPricing'];
  };
};

export function ChargerPricingWeekView({ tariffName, values }: Props) {
  const weekViewData = useMemo(
    () =>
      values?.customPricing?.map((customPrice, index) => {
        return {
          index,
          name: `${tariffName || 'Group Name'} ${index + 1}`,
          altId: customPrice.altId,
          pricing: customPrice.pricing,
          tariffSchedules: customPrice.tariffSchedules,
        };
      }) || [],
    [tariffName, values],
  );

  return (
    <Box pt={4}>
      <WeekView
        data-testid={`custom-prices-week-view`}
        title="Calendar view of Prices"
        subTitle="This is a 24hr/7day week view of your charger prices. To adjust these prices, go to the Custom Price Details tab."
        data={weekViewData}
        loading={false}
      />
    </Box>
  );
}
