/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { ApolloError } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';

import { Column, MuiDirection, TableEmpty } from '.';

type EmptyProps = {
  loading: boolean;
  error: ApolloError | undefined;
  message: string | undefined;
};

export const EmptyRows: React.FC<EmptyProps> = ({ loading, error, message }) => {
  if (loading) {
    return (
      <TableEmpty>
        <Box py={3} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress data-testid="table-loading-indicator" />
        </Box>
      </TableEmpty>
    );
  }

  return <TableEmpty>{error ? 'Something went wrong!' : message}</TableEmpty>;
};

export const sortData = (rows: any[], sortBy: string, sortDirection: MuiDirection, columns: Column[]) => {
  if (!sortBy) return rows;
  if (columns.find((column) => column.key === sortBy)?.numeric) {
    return _.orderBy(rows, [(row) => _.get(row, sortBy)], sortDirection);
  }

  return _.orderBy(
    rows,
    [
      (row) => {
        const value = _.get(row, sortBy) ? _.get(row, sortBy) : '';
        return value.toString().toLowerCase();
      },
    ],
    sortDirection,
  );
};
