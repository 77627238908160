import { gql } from '@apollo/client';
export const listTransactionDetails = gql`
  query listTransactionV2Details($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      total
      edges {
        amount
        createdAt
        tax
        charger {
          altId
          chargerName
          brand
        }
        type {
          columnValue
        }
        session {
          sessionDuration
          energyDelivered
          altId
          vendorId
          connector {
            altId
            connectorType {
              columnValue
            }
          }
          plan {
            planName
            chargerGroup {
              altId
            }
            chargerGroup {
              tariff {
                altId
                tariffName
              }
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          meterStart
          meterEnd
        }
        altId
        items {
          unitPrice
          amount
          altId
          productType {
            columnValue
            columnText
          }
          quantity
        }
      }
    }
  }
`;
