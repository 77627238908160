// @ts-nocheck
import { createStaticRanges } from 'react-date-range';
import { subDays, endOfDay, startOfDay, subMonths } from 'date-fns';

const definedDates = {
  today: endOfDay(new Date()),
};

export const staticRanges = createStaticRanges([
  {
    label: 'Yesterday',
    range: () => ({
      startDate: startOfDay(subDays(new Date(), 1)),
      endDate: definedDates.today,
    }),
  },
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: endOfDay(subDays(new Date(), 7)),
      endDate: definedDates.today,
    }),
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: endOfDay(subDays(new Date(), 30)),
      endDate: definedDates.today,
    }),
  },
  {
    label: 'Last 6 months',
    range: () => ({
      startDate: subMonths(definedDates.today, 6),
      endDate: definedDates.today,
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: subMonths(definedDates.today, 12),
      endDate: definedDates.today,
    }),
  },
  {
    label: 'All Time',
    range: () => ({
      startDate: '',
      endDate: '',
    }),
  },
]);
