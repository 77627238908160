import { gql } from '@apollo/client';

export const listSalesforceHostContacts = gql`
  query listSalesforceHostContacts($input: ListSalesforceHostContactsInput) {
    listSalesforceHostContacts(input: $input) {
      page
      pageSize
      total
      edges {
        contactName
        phone
        email
        workTitle
      }
    }
  }
`;
