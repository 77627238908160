import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';

import { Card, CardHeader } from 'src/components/shared/Card';
import {
  ListSitesConsumptionForExtendData,
  ListSitesConsumptionForExtendInput,
  useListSitesConsumptionForExtendQuery,
} from 'src/@types';
import { Column, Table, Direction } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';

type SiteMetricsProps = {
  siteIds?: string[];
  chargerGroupIds?: string[];
  planIds?: string[];
  transactionTypeIds?: string[];
  startDate: Date;
  endDate: Date;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

function SiteName(props: { data: ListSitesConsumptionForExtendData }) {
  const { siteId, siteName } = props.data;
  return <TableLink to={`/extend-plus/sites/${siteId}`}>{siteName}</TableLink>;
}

const columns: Column<ListSitesConsumptionForExtendData>[] = [
  { key: 'siteName', label: 'Site Name', sortable: true, width: '10%', component: SiteName },
  { key: 'siteStatus', label: 'Site Status', sortable: true, width: '10%' },
  { key: 'totalSessions', label: 'Total Sessions', sortable: true, numeric: true, width: '10%' },
  {
    key: 'totalSessionsCost',
    label: 'Total Revenue',
    sortable: true,
    numeric: true,
    width: '10%',
    format: (value) => `$${(value as unknown as number).toFixed(2)}`,
  },
  { key: 'totalSessionsTime', label: 'Total Session Time', sortable: true, width: '10%' },
  {
    key: 'totalSessionsPower',
    label: 'Total kWh Dispensed',
    sortable: true,
    numeric: true,
    width: '10%',
    format: (value) => (value as unknown as number).toFixed(2),
  },
  { key: 'averageSessionTime', label: 'Average Session Time', sortable: true, width: '10%' },
  {
    key: 'averageSessionPower',
    label: 'Average kWh Dispensed',
    sortable: true,
    numeric: true,
    width: '10%',
    format: (value) => (value as unknown as number).toFixed(2),
  },
];

const defaultSort = {
  sortBy: 'siteName',
  sortDirection: Direction.Asc,
};

export function SiteMetrics({
  siteIds,
  chargerGroupIds,
  planIds,
  transactionTypeIds,
  startDate,
  endDate,
}: SiteMetricsProps) {
  const input: ListSitesConsumptionForExtendInput = {
    page: 0,
    pageSize: 9999,
    filter: {
      startDate: startDate,
      endDate: endDate,
      siteIds: siteIds?.length === 0 ? undefined : siteIds,
      chargerGroupIds: chargerGroupIds?.length === 0 ? undefined : chargerGroupIds,
      planIds: planIds?.length === 0 ? undefined : planIds,
      transactionTypeIds: transactionTypeIds?.length === 0 ? undefined : transactionTypeIds,
    },
  };

  const { data, loading, error } = useListSitesConsumptionForExtendQuery({
    variables: {
      input,
    },
  });

  const edges = data?.listSitesConsumptionForExtend.edges || [];

  return (
    <StyledCard data-testid="site-metrics-card">
      <CardHeader title="Site Metrics" />
      <Table
        columns={columns}
        width={`${theme.spacing(200)}px`}
        overflow="auto"
        data={edges}
        loading={loading}
        error={error}
        id={'reports-site-metrics'}
        data-testid="reports-site-metrics-table"
        sorting={defaultSort}
      ></Table>
    </StyledCard>
  );
}
