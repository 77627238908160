import { gql } from '@apollo/client';

export const reserveCards = gql`
  mutation reserveCards($input: ReserveCardsInput!) {
    reserveCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
