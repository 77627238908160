import { gql } from '@apollo/client';
import { FullPlan } from './FullPlan';

export const updatePlan = gql`
  mutation updatePlan($planInput: UpdatePlanInput!) {
    updatePlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlan}
`;
