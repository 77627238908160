import { useGetSitesEvseCountQuery } from 'src/@types';
import { MetricCard, MetricCardPlaceholder } from 'src/components/shared/MetricCard';

const testid = (value: string) => ({ 'data-testid': `metric-card-network-stats-${value}` });

export const NetworkStatsMetrics = () => {
  const { data, loading } = useGetSitesEvseCountQuery({});

  const edges = data?.getSitesForExtend || [];
  const totalEvses = edges.reduce((count, site) => count + (site?.evseCount || 0), 0);

  if (loading) {
    return <MetricCardPlaceholder {...testid('network-loading')} />;
  }

  return (
    <MetricCard
      primaryText=""
      label="Network Charger Stats"
      tertiaryText="Total Charger Stalls: "
      tertiaryFeedbackText={`${totalEvses}`}
      {...testid('total-evses')}
    />
  );
};
