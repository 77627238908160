import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useModalContext } from 'src/contexts/ModalContext';
import { updateAccountVehicle } from 'src/apollo/queries/accounts';
import { createAccountVehicle } from 'src/apollo/queries/vehicles';
import { updateQuery } from 'src/lib/helpers';
import { useFormikSubmit } from 'src/lib/hooks';
import { AccountVehicleModalForm, initialValues } from './AccountVehicleModalForm';
import validationSchema from './AccountVehicleModalForm/validations';
import { DriversWithMeta, Vehicle, Driver } from 'src/@types';
interface Props {
  open: boolean;
  className?: string;
}

interface ModalPropsType {
  accountId?: string;
  fetchMore?: () => void;
  drivers?: DriversWithMeta | undefined;
  vehicle?: Vehicle;
}

export const AccountVehicleModal: React.FC<Props> = (props) => {
  const className = 'accountvehicle-modal-component';
  const { modalProps } = useModalContext();
  const { accountId, fetchMore: callback, drivers } = modalProps as ModalPropsType;
  const callbackArgs = {
    updateQuery,
    variables: {
      accountInput: { altId: accountId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999 },
      cardsInput: { pageSize: 999 },
    },
  };

  const transformValues = (values: typeof initialValues) => {
    const primaryDriver = _.find(drivers?.edges || [], { driverType: 'OWNER' });
    const vehicleId = values?.vehicle;
    const driverId = values?.driver || (primaryDriver as Driver)?.altId;
    const nickName = values?.nickName;
    const vin = values?.vin;

    return {
      altId: (modalProps.vehicle as Vehicle)?.altId,
      accountId,
      driverId,
      vehicleId,
      ...(nickName ? { nickName } : {}),
      ...(vin ? { vin } : {}),
    };
  };

  const handleSubmit = useFormikSubmit('Vehicle', createAccountVehicle, updateAccountVehicle, {
    altId: (modalProps.vehicle as Vehicle)?.altId || undefined,
    noRedirect: true,
    transformValues,
    callback,
    callbackArgs,
    initialValues,
  }) as never;

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AccountVehicleModalForm
        className={className}
        open={props.open}
        drivers={drivers as DriversWithMeta}
        driver={modalProps?.driver}
        vehicle={modalProps?.vehicle as Vehicle}
      />
    </Formik>
  );
};
