import { gql } from '@apollo/client';

export const listProperties = gql`
  query listProperties($propertiesInput: ListPropertiesInput, $sitesInput: ListSitesInput) {
    listProperties(input: $propertiesInput) {
      edges {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
        status
        host {
          altId
        }
        altId
        pid
        postalCode
        propertyName
        sites(input: $sitesInput) {
          edges {
            chargers {
              total
            }
          }
          total
        }
      }
      page
      pageSize
      total
      sort {
        altId
        pid
        propertyName
        hostName
      }
      filter {
        status {
          in
        }
      }
      search {
        pid {
          iLike
        }
        propertyName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
    }
  }
`;
