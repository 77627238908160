import { gql } from '@apollo/client';

export const getFirebaseUserForExtend = gql`
  query getFirebaseUserForExtend($input: GetFirebaseUserForExtendInput!) {
    getFirebaseUserForExtend(input: $input) {
      username
      firebaseId
    }
  }
`;
