import { gql } from '@apollo/client';

export const getSalesforceHostByHid = gql`
  query getSalesforceHostById($input: GetSalesforceHostInput) {
    getSalesforceHost(input: $input) {
      address1
      address2
      locality
      hostName
      postalCode
      administrativeArea
      country
      hid
      hostName
      salesforceHostContacts {
        edges {
          contactName
          phone
          email
        }
      }
    }
  }
`;
