import { MutationTuple, OperationVariables, useMutation, useQuery } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccountVehicle, DriversWithMeta, Driver, Mutation, Query } from 'src/@types';
import { useConfirmationDialog } from 'src/lib/hooks';
import { deleteAccountVehicle, getAccount } from '../../../../apollo/queries/accounts';
import { updateQuery } from '../../../../lib/helpers';
import { Styled as StyledAccordion } from './styles';
import { VehicleRow } from './VehicleRow';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
import { useModalContext } from 'src/contexts/ModalContext';
export interface Props {
  className?: string;
}
const columns = [
  { key: 'vin', label: 'VIN' },
  { key: 'makeModelYear', label: 'Vehicle Type' },
  { key: 'connectorName', label: 'Connector(s)' },
  { key: 'mac', label: 'MAC' },
  { key: 'status', label: 'Status' },
  { key: 'driver', label: 'Driver' },
  { key: 'nickname', label: 'Nickname' },
  { key: 'autocharge', label: 'Autocharge' },
  { key: 'actions', label: '' },
];
export const onRemoveVehicle = (
  confirmationDialog: ReturnType<typeof useConfirmationDialog>,
  deleteVehicle: MutationTuple<Mutation, OperationVariables>[0],
  driverVehicle: AccountVehicle,
  accountAltId: string,
  driver: Driver,
): void => {
  const { altId } = driverVehicle;
  confirmationDialog.open({
    title: 'Remove Vehicle',
    body: 'Are you sure you want to disassociate this vehicle from this account? This action cannot be undone.',
    callback: () => {
      deleteVehicle({
        refetchQueries: () => [
          {
            query: getAccount,
            variables: {
              accountInput: { altId: accountAltId },
              driversInput: { pageSize: 999 },
              accountVehiclesInput: { pageSize: 999 },
              cardsInput: { pageSize: 999 },
            },
          },
        ],
        variables: {
          input: {
            altId: altId,
            driverId: driver.altId,
          },
        },
      });
    },
  });
};
/** Vehicle List view component. */
export const AccountVehicles: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountVehicles');
  const { altId } = useParams();
  const confirmationDialog = useConfirmationDialog();
  const className = 'accountvehicles-component';
  const [disableAddVehicle, setDisableAddVehicle] = useState(false);
  const { data, loading, fetchMore } = useQuery<Query>(getAccount, {
    variables: {
      accountInput: { altId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999 },
      cardsInput: { pageSize: 999 },
    },
    skip: !altId,
  });
  const { drivers } = data?.getAccount || { drivers: [] as DriversWithMeta };
  const snackbar = useSnackbar();
  const { setModalState } = useModalContext();
  const [onRemoveAccountVehicle] = useMutation(deleteAccountVehicle, {
    onCompleted() {
      snackbar.success('Vehicle successfully removed.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });
  let vehicleCount = 0;
  if (!loading && drivers) {
    vehicleCount =
      drivers.edges?.map((driver) => driver?.accountVehicles?.edges?.length).reduce((a = 0, b = 0) => a + b, 0) || 0;
  }
  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;
  return (
    <StyledAccordion className={customClass} data-testid={id}>
      <AccordionSummary className={className} expandIcon={<ExpandMore />} component="header">
        <div className={`${className} container`}>
          <div className={`${className} title`}>
            <Typography className={className} variant="h6" component="h2">
              Vehicles
            </Typography>
            <Typography className={className} variant="caption">
              {!loading && vehicleCount ? `Currently viewing ${vehicleCount}` : '0 Vehicles'}
            </Typography>
          </div>
          <div className={`${className} controls`}>
            <Button
              id="add-vehicle-button"
              data-testid="add-vehicle-button"
              disabled={loading || disableAddVehicle}
              className={className}
              color="secondary"
              onClick={async (e) => {
                e.stopPropagation();
                setDisableAddVehicle(true);
                const res = await fetchMore({
                  updateQuery,
                  variables: {
                    accountInput: { altId },
                    driversInput: { pageSize: 999 },
                    accountVehiclesInput: { pageSize: 999 },
                    cardsInput: { pageSize: 999 },
                  },
                });
                const { getAccount: updatedData } = _.get(res, 'data', {});
                setModalState({
                  modalName: 'AccountVehicleModal',
                  modalProps: {
                    drivers: _.get(updatedData, 'drivers', {}),
                    accountId: altId,
                    fetchMore,
                  },
                  modalVisible: true,
                });
                setDisableAddVehicle(false);
              }}
              variant="contained"
            >
              Add Vehicle
            </Button>
          </div>
        </div>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={`${className} vehicle-list`}>
        <Table className={className}>
          <TableHead className={className}>
            <TableRow className={className}>
              {columns.map((column) => (
                <TableCell key={column.key} className={`${className} ${column.key}`} component="th">
                  <TableSortLabel disabled={true}>{column.label}</TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={className}>
            {!loading &&
              drivers?.edges?.map((driver) => {
                return driver?.accountVehicles?.edges?.map((vehicle, j) => {
                  if (!vehicle) {
                    return null;
                  }

                  const formattedVehicleConnectors = (vehicle?.vehicle?.vehicleConnectors || [])
                    // @ts-ignore
                    // this is because the legacy code renaming the vehicleConnector.name into connectorName in queries and the autogenerated types doesn't have it
                    // we should take out the field renaming part to resoleve the typscript key not found error
                    .map((x) => x?.name || x?.connectorName)
                    .join(', ');

                  return (
                    <VehicleRow
                      key={j}
                      className={className}
                      accountAltId={altId || ''}
                      driver={driver}
                      drivers={drivers}
                      fetchMore={fetchMore}
                      vehicle={{ vehicle, formattedVehicleConnectors }}
                      onRemoveVehicle={() =>
                        onRemoveVehicle(confirmationDialog, onRemoveAccountVehicle, vehicle, altId || '', driver)
                      }
                    />
                  );
                });
              })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </StyledAccordion>
  );
};
