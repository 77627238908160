import { gql } from '@apollo/client';

export const getSessionSummaryByPlan = gql`
  query getCustomPlanBreakdown($input: GetSessionSummaryByPlanInput) {
    getSessionSummaryByPlan(input: $input) {
      planName
      powerCostValue
    }
  }
`;
