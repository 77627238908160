import { IdTokenResult } from 'firebase/auth';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { RoleApp, Role } from 'src/@types/shared';
import { auth } from 'src/firebase';

export function useRoles() {
  const [roles, setRoles] = useState<Map<string, string[]>>(new Map());
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const [user, isLoadingAuth] = useAuthState(auth);
  const isMounted = useRef(false);

  const hasRole = useCallback(
    (app: RoleApp, role: Role) => (roles.get(app) || []).includes(role.toLowerCase()),
    [roles],
  );

  useEffect(() => {
    isMounted.current = true;
    if (!user) {
      setRoles(new Map<string, string[]>());
      if (!isLoadingAuth) {
        setIsLoadingRoles(false);
      }
      return;
    }

    setIsLoadingRoles(true);

    user
      .getIdTokenResult()
      .then(({ claims = {} }: IdTokenResult) => {
        const keys = Object.entries(claims.roles || {}).map<[string, string[]]>(([key, value]) => {
          const rolesArray = value as string[];
          return [key, rolesArray.map((v: string) => v.toLowerCase())];
        });
        if (isMounted.current) {
          setRoles(new Map(keys));
        }
      })
      .finally(() => isMounted.current && setIsLoadingRoles(false));

    return () => {
      isMounted.current = false;
    };
  }, [user, isLoadingAuth, setRoles, setIsLoadingRoles]);

  return {
    roles,
    loading: isLoadingAuth || isLoadingRoles,
    hasRole,
  };
}
