import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';
import { Box, Typography } from '@material-ui/core';
import React from 'react';

export type TransactionType = {
  id: TransactionTypeId;
  name: string;
};

export enum TransactionTypeId {
  Charging = '426',
  Reservation = '427',
  NoShow = '607',
}

export const transactionTypes: TransactionType[] = [
  { id: TransactionTypeId.Charging, name: 'Charging' },
  { id: TransactionTypeId.Reservation, name: 'Reservation' },
  { id: TransactionTypeId.NoShow, name: 'No Show' },
];

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

export const TransactionFilter: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Box pt={1}>
      {transactionTypes.length ? (
        <MultiSelectSearchFilter
          id="transactionTypes"
          label="Transaction Type"
          value={value}
          options={transactionTypes.map((transactionType) => ({
            value: transactionType?.id || '',
            label: transactionType?.name || '',
          }))}
          onChange={(v) => {
            onChange(v);
          }}
        />
      ) : (
        <Typography gutterBottom variant="subtitle2">
          There are no Transaction Types for selection.
        </Typography>
      )}
    </Box>
  );
};
