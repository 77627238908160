import { Formik } from 'formik';
import React from 'react';
import { getSiteForExtend, updateSiteForExtend } from 'src/apollo/queries/extendPlus';
import { useFormikSubmit } from '../../../../../../lib/hooks';
import { initialValues, SiteDirectionsForm } from './SiteDirectionsForm';
import { useModalContext } from 'src/contexts/ModalContext';
import { Site } from 'src/@types';

interface Props {
  open: boolean;
  className?: string;
}

export const SiteDirectionsModal: React.FC<Props> = (props) => {
  const className = 'SiteDirectionsModal-component';
  const { modalProps } = useModalContext();

  const { data, fetchMore: callback } = modalProps || {};
  const { altId } = (data as Site) || {};
  const callbackArgs = {
    getSiteForExtend,
    variables: { altId },
  };

  const handleSubmit = useFormikSubmit('Site', updateSiteForExtend, updateSiteForExtend, {
    noRedirect: true,
    initialValues,
    altId,
    callback,
    callbackArgs,
    snackSuccessMessage: 'Directions to chargers have been updated',
  }) as never;

  return (
    <Formik initialStatus={initialValues} initialValues={initialValues} onSubmit={handleSubmit}>
      <SiteDirectionsForm className={className} open={props.open} selected={data as Site} />
    </Formik>
  );
};
