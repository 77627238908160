/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, QueryResult } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Host, Maybe, ListExtendHostsQuery } from 'src/@types';
import { Column, Table } from 'src/components/shared/Table';

interface Props {
  data?: ListExtendHostsQuery['listExtendHosts'];
  loading: boolean;
  error?: ApolloError | undefined;
  fetchMore?: QueryResult<never, never>['fetchMore'];
}

interface CellProps {
  data: {
    hosts: {
      edges?: Maybe<Maybe<Host>>[];
    };
    hostId: string;
    [key: string]: any;
  };
}

export const HostCell: React.FC<CellProps> = ({ data }) => {
  return <div>{_.get(data, data.key)}</div>;
};

export const ExtendHostsList: React.FC<Props> = (props) => {
  const columns: Column[] = [
    { key: 'host.hostName', label: 'Host Name', sortable: true, width: '18%', component: HostCell },
    { key: 'prefix', label: 'Prefix', sortable: true, width: '18%', component: HostCell },
    { key: 'host.vendorId', label: 'Host ID', sortable: true, width: '18%', component: HostCell },
    { key: 'host.status', label: 'Host Status', sortable: true, width: '18%', component: HostCell },
    {
      key: 'createdAt',
      label: 'Create Date',
      sortable: true,
      width: '18%',
      component: HostCell,
      format: (timestamp: string | null) =>
        !timestamp ? '' : moment(timestamp).tz('UTC').utc().format('YYYY-MM-DD HH:MM'),
    },
  ];

  const { data, loading, error } = props;

  const edges = data?.edges || [];
  return (
    <>
      <Table
        id="extend-hosts-table"
        data-testid="extend-hosts-table"
        columns={columns}
        data={edges}
        loading={loading}
        error={error}
        noDataMessage="You currently have no hosts for eXtend+"
      />
    </>
  );
};
