import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Typography, TextField, Chip } from '@material-ui/core';
import React from 'react';

export type TransactionType = {
  id: number;
  name: string;
};

export const transactionTypes: TransactionType[] = [
  { id: 424, name: 'Payment' },
  { id: 425, name: 'Credit' },
  { id: 426, name: 'Charging' },
  { id: 427, name: 'Reservation' },
  { id: 428, name: 'Late' },
  { id: 429, name: 'Cancellation' },
  { id: 430, name: 'Refund' },
  { id: 431, name: 'Miscellaneous' },
];

type Props = {
  onChange: (val: TransactionType[]) => void;
};

export const TransactionFilter: React.FC<Props> = ({ onChange }) => {
  const setFilters = (types: TransactionType[]) => {
    onChange(types);
  };
  return (
    <Box pt={1} mb={2}>
      {transactionTypes.length ? (
        <Autocomplete
          multiple
          disablePortal
          limitTags={2}
          className="select"
          id="multiple-limit-tags"
          options={transactionTypes.map((transactionType) => ({
            value: transactionType?.id || 0,
            label: transactionType?.name || '',
          }))}
          disableCloseOnSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="default"
                size="small"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option.label}
          onChange={(event, types) => [
            setFilters(
              types.map((type) => {
                return { id: type.value, name: type.label };
              }),
            ),
          ]}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="filled" label="Activity Type" />}
        />
      ) : (
        <Typography gutterBottom variant="subtitle2">
          There are no Transaction Types for selection.
        </Typography>
      )}
    </Box>
  );
};
