/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, spacing } = theme;

export const Styled = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    display: none;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    margin: ${spacing()}px;
    padding: 0;
    background-color: #ffffff;
    color: ${palette.secondary[700]};
    z-index: 999;

    &.selected {
      display: block;
    }
  }

  img {
    object-fit: cover;
    background-color: #ededed;
    border: 2px solid #ffffff;

    :hover {
      cursor: pointer;
    }

    &.selected {
      opacity: 0.75;
    }
  }

  .img-data {
    display: flex;
    flex-direction: row;
    margin-top: ${spacing()}px;

    span {
      text-align: left;
    }

    .form-control {
      &.mini {
        padding: 0;
        width: ${spacing(6)}px;
        margin-left: auto;
      }
    }
  }
`;
