import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Snackbar as MaterialSnackbar, SnackbarOrigin } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { Styled } from './styles';
import { useSnackbarContext } from 'src/contexts/SnackBarContext';

const StyledSnackbar = styled(MaterialSnackbar)`
  margin-right: ${theme.spacing(7)}px;
  margin-left: ${theme.spacing(7)}px;
`;

interface CustomAlertProps extends AlertProps {
  customtext?: string;
}

function Alert(props: CustomAlertProps) {
  const { severity } = props;

  if (severity?.toString() === 'system') {
    const action = (
      <Button color="inherit" size="small" onClick={props.onClose}>
        {props.customtext || 'DISMISS'}
      </Button>
    );
    props = { ...props, action };
  }

  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getPosition(snackType: string): SnackbarOrigin {
  return snackType === 'system'
    ? { vertical: 'bottom', horizontal: 'left' }
    : { vertical: 'top', horizontal: 'center' };
}

export type Props = {
  className?: string;
};

export const Snackbar: React.FC<Props> = () => {
  const [key, setKey] = useState<string>();
  const { snackbarState, setSnackbarState } = useSnackbarContext();

  useEffect(() => setKey(_.uniqueId('snackbar')), [snackbarState.snackMessage]);

  // Handles closing the snackbar
  const handleClose = useCallback(() => {
    setSnackbarState(() => ({ ...snackbarState, snackVisible: false }));
  }, [setSnackbarState, snackbarState]);

  return (
    <Styled>
      <StyledSnackbar
        anchorOrigin={getPosition(snackbarState.snackbarType)}
        autoHideDuration={6000}
        onClose={handleClose}
        open={snackbarState.snackVisible}
        key={key}
      >
        <Alert
          onClose={handleClose}
          severity="info"
          className={snackbarState.snackbarType}
          customtext={snackbarState.snackbarButtonText}
        >
          {snackbarState.snackMessage}
        </Alert>
      </StyledSnackbar>
    </Styled>
  );
};
