import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Styled } from './styles';
import { EditSection } from '../EditSection';
import { events } from 'src/lib/utils/analytics-events';
import { ReservationPricing } from 'src/lib/helpers/transformReservationPricingForExtend';
import { formatPrice } from '../helpers';

export interface Props {
  pricing?: ReservationPricing;
}

export const ReservationPriceSection: React.FC<Props> = ({ pricing }) => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  const hasPricing = pricing?.fee || pricing?.fee === 0 || pricing?.noShowFee || pricing?.noShowFee === 0;

  return (
    <Styled>
      <EditSection
        title="Reservation Fees"
        subtitle={'Applies to charger connectors that are marked as reservable in this charger group.'}
        route={`/extend-plus/charger-prices/${params.altId}/pricing#reservation-fees${isNew ? '?new=true' : ''}`}
        track={events.chargerPriceSummary.CLICKED_EDIT_RESERVATION_PRICE_BUTTON}
      >
        {hasPricing ? (
          <>
            {(Boolean(pricing?.fee) || pricing?.fee === 0) && <p>Reservation fee - ${formatPrice(pricing.fee)}</p>}
            {(Boolean(pricing?.noShowFee) || pricing?.noShowFee === 0) && (
              <p>No show fee - ${formatPrice(pricing.noShowFee)}</p>
            )}
          </>
        ) : (
          'N/A'
        )}
      </EditSection>
    </Styled>
  );
};
