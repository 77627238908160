import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { spacing } = theme;

export const Styled = styled(Accordion)`
  margin-bottom: 16px;
  margin-top: 0;

  &:first-child {
    margin-top: 0;
  }

  > header {
    padding: ${` 0 ${spacing(3)}px`};

    h2 {
      color: ${config.colors.accentBlue};
    }

    div {
      &.container {
        display: flex;
        align-items: center;
        flex: 1 1 100%;
      }
    }
  }

  div.details {
    display: flex;
    flex-wrap: wrap;
    padding: ${`${spacing(3)}px ${spacing(2)}px ${spacing() / 2}px`};

    .checkbox-container {
      display: flex;
      flex-direction: row;

      h3 {
        font-weight: 300;
        padding: ${`${spacing(3.5)}px 0`};
      }
      span {
        padding: ${`${spacing(1.5)}px ${spacing() / 2}px`};
      }
    }

    > div {
      box-sizing: border-box;
      padding: ${`0 ${spacing()}px`};

      label {
        transform: translate(calc(12px + ${spacing()}px), 20px) scale(1);

        &.prefilled,
        &[data-shrink='true'] {
          transform: translate(${spacing(2)}px, 10px) scale(0.75);
        }
      }

      &.three-qtr {
        flex-basis: 75%;
      }

      &.half {
        flex-basis: 50%;
      }

      &.fourth {
        flex-basis: 25%;
      }

      &.fifth {
        flex-basis: 20%;
      }

      &.sixth {
        flex-basis: calc(100% / 6);
      }
    }

    > label {
      flex-basis: 40%;
      box-sizing: border-box;
      margin: ${`0 ${spacing(1.75)}px ${spacing(2.5)}px -${spacing(1.75)}px`};
      padding: ${`0 ${spacing()}px`};
    }
  }
`;
