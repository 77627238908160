/* eslint-disable @typescript-eslint/no-explicit-any */
export * from './TableEmpty';
import {
  Table as MUITable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Checkbox,
  TableCellProps,
  Box,
  Collapse,
  IconButton,
} from '@material-ui/core';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import config from 'src/config';
import { EmptyRows, sortData } from './helpers';
import { ApolloError, QueryResult, MutationFunction } from '@apollo/client';
import { TableSortType as Direction } from 'src/@types';
import { Title } from 'src/components/shared/Title';
import { Tooltip } from 'src/components/shared/Tooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export { Direction };

export * from 'src/lib/hooks/usePagination';

export enum MuiDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type Pagination = {
  total: number;
  page: number;
  pageSize: number;
  rowsPerPageOptions?: number[];
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

export type SelectRows = {
  id: string;
  onSelectChange: (values: string[]) => void;
  values: string[];
};

export type Column<T = any> = {
  key: string;
  label?: string;
  sortable: boolean;
  sortName?: string;
  numeric?: boolean;
  disabled?: boolean;
  width: string;
  align?: TableCellProps['align'];
  component?: React.FC<{
    data: T;
    fetchMore?: QueryResult<never, never>['fetchMore'];
    parentData?: T;
    mutation?: MutationFunction<any, any>;
  }>;
  format?: (value: T) => string;
  formatWithRowData?: (data: T) => string | JSX.Element;
  tooltip?: NonNullable<React.ReactNode>;
};

export type Sorting = {
  sortBy: string;
  sortDirection: Direction;
  onSortByChange?: (value: string) => void;
  onSortDirectionChange?: (value: Direction) => void;
};

type Props<T = any> = {
  id?: string;
  'data-testid'?: string;
  className?: string;
  title?: string;
  stickyHeader?: boolean;
  width?: string;
  overflow?: string;
  data: unknown[];
  loading: boolean;
  error: ApolloError | undefined;
  fetchMore?: QueryResult<never, never>['fetchMore'];
  noDataMessage?: string;
  selectRows?: SelectRows;
  pagination?: Pagination;
  sorting?: Sorting;
  columns: Column[];
  parentData?: T;
  onCollapseClick?: (T: Record<string, unknown>) => void;
  onSelectAll?: () => void;
  componentMutation?: MutationFunction<any, any>;
  summaryRow?: ReactElement; // optional first row for things like data summaries
};

const { colors } = config;

export const StyledTable = styled(MUITable)<{ width: string }>`
  &.MuiTable-root {
    width: ${(p) => p.width};
  }
  tbody tr td {
    max-width: 250px;
    overflow-wrap: break-word;
    a {
      color: ${colors.accentBlue};
    }
  }
`;

const HeaderCellWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  flex-direction: inherit;
  justify-content: flex-start;
`;

export const Table: React.FC<Props> = ({
  id,
  className,
  title,
  width = '100%',
  overflow = 'unset',
  data,
  loading = false,
  error = undefined,
  columns,
  noDataMessage,
  pagination,
  selectRows,
  sorting,
  fetchMore,
  parentData,
  onCollapseClick,
  componentMutation,
  summaryRow,
  ...props
}) => {
  const [sortBy, setSortBy] = React.useState<string>(sorting?.sortBy || '');
  const [sortDirection, setSortDirection] = React.useState<Direction>(sorting?.sortDirection || Direction.Asc);

  const backEndSort = sorting?.onSortByChange !== undefined && sorting?.onSortDirectionChange !== undefined;
  const [openRow, setOpen] = React.useState(-1);

  useEffect(() => {
    if (!!props.children) setOpen(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSortChange = (key: string) => {
    if (!!props.children) setOpen(-1);
    let direction = Direction.Asc;
    if (key === sortBy) {
      direction = sortDirection === Direction.Asc ? Direction.Desc : Direction.Asc;
    } else {
      setSortBy(key);
    }
    setSortDirection(direction);
    if (pagination) {
      pagination.onPageChange(0);
    }
  };

  const onBackendSortChange = (column: Column) => {
    if (!!props.children) setOpen(-1);
    let direction = Direction.Asc;
    if (sorting?.onSortByChange && sorting?.onSortDirectionChange) {
      if (column.sortName === sorting?.sortBy || column.key === sorting?.sortBy) {
        direction = sorting?.sortDirection === Direction.Asc ? Direction.Desc : Direction.Asc;
      } else {
        sorting.onSortByChange(column.sortName || column.key);
      }
      sorting.onSortDirectionChange(direction);
      if (pagination) {
        pagination.onPageChange(0);
      }
    }
  };

  const selected = selectRows?.values || [];

  const selectable = selectRows ? data.map((row) => _.get(row, selectRows.id)) : [];

  const isSelectedAll = selectable.every((rowId) => selected.includes(rowId));

  const onSelect = (selectedId: string) => {
    if (selected.includes(selectedId)) {
      selectRows?.onSelectChange(selected.filter((rowId) => rowId !== selectedId));
      return;
    }

    selectRows?.onSelectChange([...selected, selectedId]);
  };

  const onSelectAll = (dataRows: any[]) => {
    if (dataRows.length === 0) return;

    if (isSelectedAll) {
      selectRows?.onSelectChange(selected.filter((rowId) => !selectable.includes(rowId)));
      return;
    }

    selectRows?.onSelectChange(Array.from(new Set([...selectable, ...selected])));

    if (props.onSelectAll) props.onSelectAll();
  };

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    pagination?.onPageChange(page);
  };

  const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = Number(event.target.value);
    pagination?.onPageSizeChange(pageSize);
  };

  const displaySort = (column: Column) => {
    const label = (
      <>
        {column.label || ''}
        {column.tooltip && <Tooltip content={column.tooltip} />}
      </>
    );

    if (column.sortable) {
      return (
        <TableSortLabel
          active={
            backEndSort ? sorting?.sortBy === column.key || sorting?.sortBy === column.sortName : sortBy === column.key
          }
          direction={
            backEndSort && sorting?.sortDirection ? MuiDirection[sorting?.sortDirection] : MuiDirection[sortDirection]
          }
          onClick={() => (backEndSort ? onBackendSortChange(column) : onSortChange(column.key))}
        >
          {label}
        </TableSortLabel>
      );
    }

    return label;
  };

  const sortedData = backEndSort ? data : sortData(data, sortBy, MuiDirection[sortDirection], columns);

  return (
    <>
      <Box overflow={overflow}>
        <StyledTable {...props} width={width} className={clsx('table', className)}>
          <TableHead>
            {title && <Title>{title}</Title>}
            <TableRow>
              {selectRows && (
                <TableCell padding="checkbox" data-testid={`${id}-table-header-cell-checkbox`}>
                  <Checkbox
                    checked={isSelectedAll}
                    onClick={() => {
                      onSelectAll(sortedData);
                    }}
                    data-testid="select-all"
                  />
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell
                  align={column.align}
                  key={`${column.key}-${index}`}
                  data-testid={`${id}-table-header-cell-${column.key}-${index}`}
                  className={clsx(column.key, className)}
                  component="th"
                  width={column.width}
                >
                  <HeaderCellWrapper>{displaySort(column)}</HeaderCellWrapper>
                </TableCell>
              ))}
              {props.children && <TableCell />}
            </TableRow>
          </TableHead>

          {sortedData.length > 0 ? (
            <TableBody>
              {summaryRow ? summaryRow : null}
              {sortedData.map((row: any, index: number) => (
                <React.Fragment key={index}>
                  <TableRow key={index} data-testid="base-row">
                    {selectRows && (
                      <TableCell padding="checkbox" data-testid={`${id}-table-cell-checkbox-${index}`}>
                        <Checkbox
                          checked={selectRows?.values.includes(_.get(row, selectRows?.id))}
                          onClick={() => onSelect(_.get(row, selectRows?.id))}
                        />
                      </TableCell>
                    )}
                    {columns.map(({ key, align, component: Component, format, formatWithRowData }) => {
                      return (
                        <TableCell
                          key={`${key}-${index}`}
                          className={clsx(key, className)}
                          data-testid={`${id}-table-cell-${key}-${index}`}
                          align={align}
                        >
                          {Component ? (
                            fetchMore ? (
                              componentMutation ? (
                                <Component
                                  data={{ ...row, key }}
                                  fetchMore={fetchMore}
                                  parentData={parentData}
                                  mutation={componentMutation}
                                />
                              ) : (
                                <Component data={{ ...row, key }} fetchMore={fetchMore} parentData={parentData} />
                              )
                            ) : componentMutation ? (
                              <Component
                                data={{ ...row, key }}
                                fetchMore={fetchMore}
                                parentData={parentData}
                                mutation={componentMutation}
                              />
                            ) : (
                              <Component data={{ ...row, key }} parentData={parentData} />
                            )
                          ) : format ? (
                            format(_.get(row, key))
                          ) : formatWithRowData ? (
                            formatWithRowData(row)
                          ) : (
                            _.get(row, key)
                          )}
                        </TableCell>
                      );
                    })}
                    {props.children ? (
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setOpen(openRow === index ? -1 : index);
                            if (openRow !== index && onCollapseClick) onCollapseClick(row);
                          }}
                          data-testid={`expand-row-button-${index}`}
                        >
                          {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>

                  <TableRow key={index + row.id} data-testid={`expandable-row-${index}`}>
                    <TableCell style={{ padding: `0 0 0 0` }} colSpan={12}>
                      <Collapse
                        in={openRow === index}
                        timeout="auto"
                        data-testid={`expandable-row-${index}-inner`}
                        unmountOnExit
                      >
                        {props.children}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          ) : (
            <EmptyRows loading={loading} error={error} message={noDataMessage} />
          )}
        </StyledTable>
      </Box>
      <>
        {pagination && (
          <TablePagination
            component="div"
            rowsPerPageOptions={pagination?.rowsPerPageOptions || [10, 25, 100]}
            count={pagination?.total}
            rowsPerPage={pagination?.pageSize}
            page={pagination?.page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onPageSizeChange}
          />
        )}
      </>
    </>
  );
};
