import { gql } from '@apollo/client';
import { FullChargerModel } from './FullChargerModel';

export const updateChargerModel = gql`
  mutation UpdateChargerModel($modelInput: UpdateChargerModelInput) {
    updateChargerModel(input: $modelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModel}
`;
