import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';

export * from './MetricCardGroup';
export * from './MetricCardPlaceholder';

const { typography, palette, spacing } = theme;

import { Card } from '../Card';
import { Box, Typography } from '@material-ui/core';

export type MetricCardTheming = {
  iconColor?: string;
  primaryTextColor?: string;
  labelTextColor?: string;
};

export type Props = {
  className?: string;
  id?: string;
  icon?: React.ReactNode;
  primaryText: string | number;
  secondaryText?: string;
  tertiaryText?: string;
  tertiaryFeedbackText?: string;
  label?: React.ReactNode;
  'data-testid'?: string;
  theming?: MetricCardTheming;
  loading?: boolean;
};

const Container = styled.div`
  display: flex;

  @media (max-width: 1600px) {
    .icon {
      display: none;
    }
  }

  @media (max-width: 1450px) {
    .primary-text {
      font-size: 1.8vw !important;
    }
    .secondary-text {
      font-size: 1.8vw !important;
    }
    .label {
      font-size: 1vw !important;
    }
  }

  .icon {
    max-width: 26px;
    margin-top: 6px;
    margin-right: 10px;
    color: ${(props: MetricCardTheming) => props?.iconColor || palette.text.primary};
  }

  .content-items {
    display: flex;
    align-items: flex-end;
  }

  .primary-text {
    font-size: 28px;
    font-weight: 500;
    color: ${(props: MetricCardTheming) => props?.primaryTextColor || palette.text.primary};
  }

  .secondary-text {
    color: ${palette.text.secondary};
    font-size: 28px;
    font-weight: 300;
  }

  .secondary-text,
  .feedback-text {
    margin-left: ${spacing(1)}px;
  }

  .label {
    font-size: ${typography.fontSize}px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: ${spacing(1)}px;
    color: ${(props: MetricCardTheming) => props?.labelTextColor || palette.text.primary};
  }

  .tertiary-text-items {
    padding-top: ${spacing(1)}px;
    font-size: 12px;
  }

  .tertiary-text {
    font-weight: 500;
    color: ${palette.text.primary};
  }

  .tertiary-feedback-text {
    font-weight: 300;
    color: ${palette.text.secondary};
  }
`;

export const MetricCard: React.FC<Props> = ({
  icon,
  primaryText,
  secondaryText,
  label,
  tertiaryText,
  tertiaryFeedbackText,
  theming = {},
  className,
  ...props
}) => {
  return (
    <Card className={clsx('MetricCard-root', className)} {...props}>
      <Container {...theming}>
        <Typography variant="h4">{icon && icon}</Typography>
        <Box pl="5px">
          <div className="content-items">
            <Typography variant="h4" color="textPrimary">
              {primaryText}
              {secondaryText && (
                <Typography variant="h4" component="span" color="textSecondary">
                  {' '}
                  {secondaryText}
                </Typography>
              )}
            </Typography>
          </div>
          <div className="label">{label}</div>
          {(tertiaryText || tertiaryFeedbackText) && (
            <div className="tertiary-text-items">
              {tertiaryFeedbackText && (
                <Typography variant="subtitle2" color="textSecondary">
                  {tertiaryText && (
                    <Typography variant="subtitle2" component="span" color="textPrimary">
                      {tertiaryText}{' '}
                    </Typography>
                  )}
                  {tertiaryFeedbackText}
                </Typography>
              )}
            </div>
          )}
        </Box>
      </Container>
    </Card>
  );
};
