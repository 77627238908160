import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Breadcrumbs as MuiBreadcrumbs, Box, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { theme } from '@evgo/react-material-components';

export interface Props {
  className?: string;
}

export const BreadcrumbLink = styled(Link)`
  color: inherit;
  font-size: ${theme.typography.fontSize}px;
  line-height: 1rem;
  :hover {
    text-decoration: underline;
  }
`;

export const BreadcrumbText = styled(Typography)`
  color: ${theme.palette.grey[900]};
  &.MuiTypography-body1 {
    font-weight: 500;
    font-size: ${theme.typography.fontSize}px;
    line-height: 1rem;
  }
`;

export const Breadcrumbs: React.FC<Props> = ({ className, children }) => {
  return (
    <Box>
      <MuiBreadcrumbs className={className} separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
        {children ? children : <li>Please add breadcrumbs!</li>}
      </MuiBreadcrumbs>
    </Box>
  );
};
