import { Button, Fab, IconButton, InputLabel, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack, FilterNone, FilterTiltShift, ZoomOutMap } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Connector } from 'src/@types';
import config from 'src/config';
import { getFullAddress, titleCase } from '../../../../../lib/helpers';
import { ConnectorIcon } from '../../../../svgs/ConnectorIcon';
import { ChargerMapModal } from '../ChargerMapModal';
import { initialValues } from '../initialValues';
import { Styled } from './styles';

export interface Props {
  connectors: Connector[];
  loading: boolean;
}

/**
 * Copies info value to clipboard.
 */
export const copyToClipBoard = (string: string): void => {
  const el = document.createElement('input');
  el.value = string;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/**
 * Charger Edit Header component
 */
export const ChargerEditHeader: React.FC<Props> = (props) => {
  const { altId } = useParams();

  const id = _.kebabCase('ChargerEditHeader');
  const className = id;
  const {
    values: { chargerName, cid, site, warehouse, maxPower },
  } = useFormikContext<typeof initialValues>();
  const { connectors, loading } = props;

  const [modal, setModal] = useState('');

  let address = '';
  let thumbnailPosition = { latitude: 0, longitude: 0 };
  let position = { latitude: 0, longitude: 0 };
  if (site) {
    address = getFullAddress({
      streetAddress: _.get(site, 'address1'),
      unit: _.get(site, 'address2'),
      city: _.get(site, 'locality'),
      state: _.get(site, 'administrativeArea'),
      postalCode: _.get(site, 'postalCode'),
      country: _.get(site, 'country'),
    });
    thumbnailPosition = { latitude: Number(site.latitude), longitude: Number(site.longitude) };
    position = { latitude: Number(site.latitude), longitude: Number(site.longitude) };
  }
  if (warehouse) {
    address = getFullAddress({
      streetAddress: _.get(warehouse, 'address1'),
      unit: _.get(warehouse, 'address2'),
      city: _.get(warehouse, 'locality'),
      state: _.get(warehouse, 'administrativeArea'),
      postalCode: _.get(warehouse, 'postalCode'),
      country: _.get(warehouse, 'country'),
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const latitude = (warehouse as any).latitude;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const longitude = (warehouse as any).longitude;
    thumbnailPosition = { latitude: Number(latitude), longitude: Number(longitude) };
    position = { latitude: Number(latitude), longitude: Number(longitude) };
  }

  const renderInfo = (infoClass: string, label: string, value: string, showIcon = true) => {
    return (
      <article className={className}>
        <InputLabel className={className} shrink>
          {label}
        </InputLabel>
        <div className={`${className} ${infoClass}`}>
          <Typography className={className}>{value}</Typography>
          {!_.isEmpty(value) && showIcon && (
            <IconButton
              className={className}
              aria-label={label}
              onClick={() => {
                copyToClipBoard(value);
              }}
            >
              <FilterNone className={className} />
            </IconButton>
          )}
        </div>
      </article>
    );
  };

  const center = `${thumbnailPosition.latitude},${thumbnailPosition.longitude}`;
  const marker = `&markers=color:0x008000%7C${thumbnailPosition.latitude},${thumbnailPosition.longitude}`;
  const zoom = '&zoom=13';
  const size = '&size=120x120';
  const key = `&key=${config.env.google.apiKey}`;
  const staticMap = (
    <img
      className={className}
      src={`https://maps.googleapis.com/maps/api/staticmap?center=${center}${zoom}${size}${marker}${key}`}
    />
  );

  const map = (
    <article className={`${className} map`}>
      <Fab
        className={`${className} open-map`}
        color="secondary"
        size="small"
        onClick={() => {
          setModal('ChargerProfileMap');
        }}
      >
        <ZoomOutMap className={className} />
      </Fab>

      <div className={`${className} border`}>{site && staticMap}</div>
    </article>
  );

  return (
    <Styled className={className}>
      <div className={className}>
        <section className={`${className} back`}>
          <Button className={className} size="small" to="/chargers" component={Link}>
            <ArrowBack className={`${className} arrow`} />
            All Chargers
          </Button>
        </section>

        <section className={`${className} title`}>
          <FilterTiltShift className={className} />
          <Typography className={className} variant="h5">
            {loading ? 'Loading...' : chargerName || 'Create Charger'}
          </Typography>
          {!loading &&
            connectors?.map((connector, index) => {
              return (
                <Tooltip
                  key={index}
                  title={titleCase(_.get(connector, 'status', ''))}
                  enterDelay={100}
                  leaveDelay={100}
                  id={`${id}-connector-icon-${index}-tooltip`}
                >
                  <div className={`${className} connector-icon-container`}>
                    <ConnectorIcon
                      id={`${id}-connector-icon-${index}`}
                      className={className}
                      name={_.get(connector, 'connectorType.connectorName')}
                      status={connector?.status as string}
                    />
                  </div>
                </Tooltip>
              );
            })}
        </section>

        {!_.isEmpty(altId) && (
          <section className={`${className} info`}>
            {cid ? renderInfo('charger-id', 'Charger ID', cid) : renderInfo('charger-id', 'Charger ID', '')}
            {address ? renderInfo('address', 'Address', address) : renderInfo('address', 'Address', '')}
            {maxPower
              ? renderInfo('max-power', 'Max Power', `${maxPower} kW`, false)
              : renderInfo('max-power', 'Max Power', '', false)}
          </section>
        )}
      </div>

      {altId ? map : null}

      {!!position && (
        <ChargerMapModal
          altId={altId || ''}
          open={modal === 'ChargerProfileMap'}
          hideModal={setModal}
          position={position}
        />
      )}
    </Styled>
  );
};
