/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(TableRow)`
  td {
    padding: ${`${spacing(2) / 2}px ${spacing(3) / 2}px`};

    h6,
    p {
      /* TODO: use theme colors */
      color: #707070;
    }

    h6 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }

    &:first-child {
      padding-left: ${spacing(3)}px;
    }
  }
`;
