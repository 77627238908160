import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function GreenChargingLeafIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M32 0H0V32H32V0Z" fill="white" />
      <path
        d="M16 4C9.6 4 4 9.14667 4 16C4 18.8267 4.98667 21.4267 6.62667 23.48L4 26.12L5.88 28L8.50667 25.3733C10.5733 27.0133 13.1733 28 16 28C19.0667 28 22.1467 26.8267 24.48 24.48C26.8267 22.1467 28 19.0667 28 16V4H16ZM21.1067 16.3467L14.2267 22.52C14.0133 22.72 13.68 22.7067 13.48 22.5067C13.2933 22.32 13.2667 22.0267 13.4267 21.8133L16.68 17.3733L11.28 16.84C10.6933 16.7867 10.44 16.0533 10.88 15.6533L17.76 9.48C17.9733 9.28 18.3067 9.29333 18.5067 9.49333C18.6933 9.68 18.72 9.97333 18.56 10.1867L15.3067 14.6267L20.7067 15.16C21.3067 15.2133 21.5467 15.9467 21.1067 16.3467Z"
        fill="#212121"
      />
    </SvgIcon>
  );
}
