import { ThemeOptions, Theme, createTheme } from '@material-ui/core/styles';
import { AlertClassKey } from '@material-ui/lab';
import { StyleRules } from '@material-ui/styles';
import _ from 'lodash';

type ExtendThemeOptions = ThemeOptions & {
  overrides: Theme['overrides'] & {
    MuiAlert: Partial<StyleRules<Record<string, unknown>, AlertClassKey>> | undefined;
  };
};

type CustomColorOptions = {
  [key: string]: string;
};

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    sequential: CustomColorOptions;
    categorical: CustomColorOptions;
    accent: SimplePaletteColorOptions;
  }
  interface Palette {
    sequential: CustomColorOptions;
    categorical: CustomColorOptions;
    accent: SimplePaletteColorOptions;
  }
}

export function innerExtendPlusTheme(parentTheme?: Theme): Theme {
  const extendPlusTheme: ExtendThemeOptions = {
    palette: {
      type: 'light',
      success: { main: '#44A047' },
      error: { main: '#D0011B' },
      warning: { main: '#ED6C02' },
      info: { main: '#0073E4' },
      accent: { main: '#FFC425' },
      background: { default: '#F8F8F8' },
      text: {
        primary: '#212121',
        secondary: '#707070',
        hint: '#9e9e9e',
        disabled: '#9e9e9e',
      },
      sequential: {
        900: '#002242',
        800: '#002E57',
        700: '#00396B',
        600: '#005299',
        500: '#0071C2',
        400: '#1192E8',
        300: '#33B1FF',
        200: '#80CEFF',
        100: '#B8E5FF',
        50: '#E6F6FF',
      },
      categorical: {
        50: '#3F657C',
        100: '#AE8CF8',
        200: '#46A4E7',
        300: '#A23965',
        400: '#EC716F',
        500: '#5E150D',
        600: '#438E51',
        700: '#1444A3',
        800: '#E477A6',
        900: '#BB9830',
        A100: '#47A9A9',
        A200: '#103456',
        A400: '#944E19',
        A700: '#754CC8',
      },
      primary: {
        main: '#002B39',
        800: '#134050',
        700: '#215265',
        600: '#2F667B',
        500: '#3B748B',
        400: '#58899E',
        300: '#729EB2',
        200: '#96BACB',
        100: '#B8D8E5',
        50: '#DAF0FE',
      },
    },
    typography: {
      // headlines
      h1: {
        fontSize: '96px',
        lineHeight: '112px',
        fontWeight: 300,
        padding: '0px 0px 0px !important',
      },
      h2: {
        fontSize: '60px',
        lineHeight: '72px',
        fontWeight: 300,
      },
      h3: {
        fontSize: '48px',
        lineHeight: '56px',
        fontWeight: 400,
      },
      h4: {
        fontSize: '34px',
        lineHeight: '42px',
        fontWeight: 400,
      },
      h5: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 400,
      },
      h6: {
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 500,
      },
      // texts
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '16px',
        lineHeight: '28px',
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 500,
      },
      caption: {
        fontSize: '12px',
        lineHeight: '20px',
        fontWeight: 400,
      },
      overline: {
        fontSize: '12px',
        lineHeight: '32px',
        fontWeight: 400,
      },
    },
    overrides: {
      // buttons
      MuiButton: {
        textSizeLarge: {
          fontSize: '15px',
          lineHeight: '26px',
          fontWeight: 500,
        },
        text: {
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: 500,
        },
        textSizeSmall: {
          fontSize: '13px',
          lineHeight: '22px',
          fontWeight: 500,
        },
      },
      // components
      MuiInputLabel: {
        root: {
          fontSize: '12px',
          lineHeight: '12px',
          fontWeight: 400,
        },
      },
      MuiInput: {
        input: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 400,
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '12px',
          lineHeight: '20px',
          fontWeight: 400,
        },
      },
      MuiChip: {
        label: {
          fontSize: '13px',
          lineHeight: '18px',
          fontWeight: 400,
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: parentTheme?.palette.grey['500'],
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: 500,
        },
      },
      MuiTableCell: {
        head: {
          '&:first-child': {
            borderTopLeftRadius: '6px',
          },
          '&:last-child': {
            borderTopRightRadius: '6px',
          },
          backgroundColor: '#F5F5F5',
          color: '#707070',
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: 500,
          borderBottom: `1px solid #DEE5EA`,
        },
        body: {
          '& a': {
            fontSize: 'inherit',
          },
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 400,
        },
      },
      MuiAlert: {
        root: {
          width: '60%',
        },
        filledError: {
          backgroundColor: parentTheme?.palette.error.dark,
        },
      },
      MuiStepIcon: {
        root: {
          '&$active': {
            color: parentTheme?.palette.secondary.main,
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '10px',
          lineHeight: '14px',
          fontWeight: 500,
          color: parentTheme?.palette.text.primary,
          backgroundColor: parentTheme?.palette.background.paper,
          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.16)',
          padding: '16px',
        },
      },
    },
  };

  if (!parentTheme) return createTheme(extendPlusTheme) as Theme;

  return _.merge(parentTheme, extendPlusTheme) as Theme;
}

export const extendPlusTheme: Theme = innerExtendPlusTheme();
