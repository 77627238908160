import { gql } from '@apollo/client';

export const updatePlanDriverRank = gql`
  mutation UpdatePlanDriverRank($input: [DriverRankInput!]!) {
    updatePlanDriverRank(input: $input) {
      success
      message
    }
  }
`;
