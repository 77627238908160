import { gql } from '@apollo/client';

export const listSessionsV2 = gql`
  query ListSessionsV2($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      total
      edges {
        altId
        vendorId
        startSource {
          columnValue
        }
        startTime
        endTime
        sessionDuration
        energyDelivered
        plan {
          altId
          planName
        }
        transaction {
          altId
          vendorId
          amount
          driver {
            altId
            firstName
            lastName
          }
        }
        meterStart
        meterEnd
        lastMeterValue
        connector {
          evse {
            charger {
              chargerName
            }
          }
        }
        cost
        corrupted
        corruptedReasons
        card {
          internalNumber
          externalNumber
          evcId
        }
        stopSource {
          columnValue
        }
      }
    }
  }
`;
