import { captureMessage } from '@sentry/react';
import { ApolloClient, InMemoryCache, gql, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import packageInfo from '../../package.json';
import config from '../config';
import { auth } from '../firebase';
import resolvers from './resolvers';

export const typeDefs = gql`
  input LoginInput {
    username: String!
    password: String!
  }

  extend type Mutation {
    login(input: LoginInput!): Void
  }
`;

export const cache = new InMemoryCache();

const authLink = setContext(async (req, { headers }) => {
  const currentUser = auth.currentUser || { getIdToken: async () => '' };
  const newHeaders = { ...headers };
  const token = await currentUser.getIdToken();

  if (token) newHeaders.authorization = `Bearer ${token}`;

  return { headers: newHeaders };
});

// @ts-ignore: apollo-upload-client uses a slightly older version of @apollo/client types
const uploadLink: ApolloLink = createUploadLink({
  uri: config.apiHost,
  headers: {
    'keep-alive': 'true',
  },
});

// network errors are caught by apollo client which prevents sentry from tracking them
// so we do that manually here
const errorLink = onError(({ networkError, operation }) => {
  if (networkError) {
    const errorMessage = `Network error: ${networkError}`;
    captureMessage(errorMessage, { extra: { operationName: operation?.operationName } });
  }
});

const { name, version } = packageInfo;
const client = new ApolloClient({
  cache,
  link: from([authLink, errorLink, uploadLink]),
  name,
  resolvers,
  version,
  typeDefs,
});

export const apolloClient = client;
