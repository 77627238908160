/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  section {
    min-width: 450px;
    max-width: ${spacing(90)}px;

    .panel-actions {
      background-color: #ffffff;
      flex-direction: row;
      padding: ${spacing(3)}px;

      button {
        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }
    }

    header {
      display: flex;
      justify-content: space-between;
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      h2 {
        color: ${config.colors.accentBlue};
      }

      button {
        margin: -${spacing()}px;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      .stepper {
        flex: 4;

        .active {
          :global(svg) {
            color: #5d9d52;
          }
        }

        :global(text) {
          display: none;
        }
      }

      p {
        width: 100%;
      }

      .contact {
        padding: ${`${spacing(2)}px 0px 0px `};
      }

      > div {
        display: flex;
        align-content: center;
        align-items: center;
        align-self: center;

        .snackbar {
          max-width: auto;
          min-width: auto;
        }

        > * {
          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &:first-of-type {
          margin-bottom: ${spacing(2)}px;
        }

        &:last-of-type {
          margin-top: ${spacing(2)}px;
          justify-content: flex-end;
        }

        &.step-1 {
          width: 100%;

          > div {
            width: 50%;

            &:first-of-type {
              align-self: start;
            }

            > div {
              &.full {
                width: calc(100% - 16px);
              }

              &.half {
                width: calc(50% - 16px);
              }
            }

            > * {
              margin: 0 ${spacing()}px;
            }
          }
        }
      }
    }
  }
`;
