import { Dashboard, Face, FilterTiltShift, Fingerprint, Layers } from '@material-ui/icons';

import { AddChartIcon } from 'src/components/svgs';

import { DrawerContent } from './DrawerContent';
import { startsWithOneOf } from './helpers';

export function SuperAdminNavItems() {
  return (
    <DrawerContent>
      {[
        {
          name: 'Chargers',
          icon: <FilterTiltShift />,
          subItems: [
            {
              name: 'Charger Management',
              to: '/chargers',
            },
            {
              name: 'Model Management',
              to: '/models',
            },
          ],
        },
        {
          name: 'Drivers',
          icon: <Face />,
          to: '/drivers',
        },
        {
          name: 'Authenticators',
          icon: <Fingerprint />,
          subItems: [
            {
              name: 'Cards',
              to: '/cards',
            },
          ],
        },
        {
          name: 'Plans',
          icon: <Dashboard />,
          subItems: [
            {
              name: 'Plan Management',
              to: '/plans/list',
            },
            {
              name: 'Plan Display Rank',
              to: '/plans/display-rank',
            },
          ],
        },
        {
          name: 'Hosts & Sites',
          icon: <Layers />,
          subItems: [
            {
              name: 'Host Management',
              to: '/hosts',
            },
            {
              name: 'Property Management',
              to: '/properties',
            },
            {
              name: 'Site Management',
              to: '/sites',
            },
          ],
        },
        {
          name: 'eXtend+',
          icon: <AddChartIcon />,
          isActive: (path: string) => startsWithOneOf(path, ['/extend-plus']),
          subItems: [
            {
              name: 'User Management',
              to: '/extend-plus/admin/user-management',
            },
            {
              name: 'Host Management',
              to: '/extend-plus/admin/host-management',
            },
          ],
        },
      ]}
    </DrawerContent>
  );
}
