import { gql } from '@apollo/client';

export const listSites = gql`
  query listSites($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
        chargers {
          total
        }
        altId
        latitude
        longitude
        siteName
        property {
          altId
          host {
            altId
            hostName
          }
        }
        sid
        siteStatusId
      }
      page
      pageSize
      sort {
        sid
        siteName
        hostName
        siteStatusId
      }
      filter {
        siteStatusId {
          in
        }
      }
      search {
        siteName {
          iLike
        }
        sid {
          iLike
        }
        property_host_hostName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
      total
    }
  }
`;
