/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';
import { getFullAddress, titleCase } from '..';
import { CustomHours } from 'src/components/shared/CustomPricingInput/CustomHoursInput';

export interface TimeSlot {
  to: string;
  from: string;
}

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * Transforms the Falcon site response into Formik format
 */
export const transformSiteResponse = (siteData: any) => {
  const defaultHours = {
    custom: false,
    from: '00:00',
    to: '00:00',
  };

  const defaultCustomHours = [
    {
      daysOfWeek: [],
      startTime: '',
      endTime: '',
    },
  ];

  /**
   * Sanitizes hours of operation data on site
   */
  const sanitizeHoursOfOperation = (hoursOfOperation: any) => {
    if (!hoursOfOperation) return defaultHours;
    const newHours = JSON.parse(JSON.stringify(_.pick(hoursOfOperation, ['custom', 'from', 'to'])));
    if (_.isEmpty(newHours)) return null;

    return newHours;
  };

  const transformHoursOfOperationToCustomHours = (hoursOfOperation: any): CustomHours[] => {
    if (!hoursOfOperation) return defaultCustomHours;
    const customHours: CustomHours[] = [];

    daysOfWeek.forEach((day) => {
      if (hoursOfOperation?.[day] && !hoursOfOperation?.[day]?.closed) {
        (hoursOfOperation[day]?.timeslots || []).forEach((timeslot: TimeSlot) => {
          const matchingTimeslotIndex = customHours.findIndex((customHour) => {
            const transformedCustomHourTimeslot = { from: customHour.startTime, to: customHour.endTime };
            return _.isEqual(transformedCustomHourTimeslot, timeslot);
          });
          if (matchingTimeslotIndex != -1) {
            customHours[matchingTimeslotIndex].daysOfWeek.push(day.toUpperCase());
          } else {
            customHours.push({ daysOfWeek: [day.toUpperCase()], startTime: timeslot.from, endTime: timeslot.to });
          }
        });
      }
    });

    return customHours;
  };

  const values = {
    altId: _.get(siteData, 'altId'),
    access: _.get(siteData, 'access', 'PUBLIC'),
    accessNotes: _.get(siteData, 'accessNotes'),
    address1: _.get(siteData, 'address1'),
    address2: _.get(siteData, 'address2'),
    administrativeArea: _.get(siteData, 'administrativeArea'),
    country: _.get(siteData, 'country'),
    chargers: (siteData?.chargers?.edges || []).map((edge: any) => ({
      chargerName: {
        value: _.get(edge, 'chargerName', ''),
        to: `/chargers/${_.get(edge, 'altId')}/profile`,
      },
      cid: { value: _.toUpper(_.get(edge, 'cid', '')) },
      chargerModel: { value: _.get(edge, 'chargerModel.modelName', '') },
      fieldStationStatus: { value: titleCase(_.get(edge, 'fieldStationStatus', 'Unknown')) },
      address: {
        value: getFullAddress({
          streetAddress: _.get(edge, 'site.address1', ''),
          unit: _.get(edge, 'site.address2', ''),
          city: _.get(edge, 'site.locality', ''),
          state: _.get(edge, 'site.administrativeArea', ''),
          postalCode: _.get(edge, 'site.postalCode', ''),
          country: _.get(edge, 'site.country', ''),
        }),
      },
      propertyName: {
        value: _.get(edge, 'site.property.propertyName', '') ? _.get(edge, 'site.property.propertyName', '') : '',
      },
    })),
    description: _.get(siteData, 'description', ''),
    directions: _.get(siteData, 'directions'),
    displayName: _.get(siteData, 'displayName'),
    demandLimitManagementEnabled: _.get(siteData, 'demandLimitManagementEnabled', false),
    retailDemandLimit: _.get(siteData, 'retailDemandLimit', null),
    hoursOfOperation: sanitizeHoursOfOperation(_.get(siteData, 'hoursOfOperation', {})),
    customHours: transformHoursOfOperationToCustomHours(_.get(siteData, 'hoursOfOperation', {})),
    latitude: Number(_.get(siteData, 'latitude')),
    locality: _.get(siteData, 'locality'),
    locationMarket: _.get(siteData, 'locationMarket'),
    locationNotes: _.get(siteData, 'locationNotes'),
    longitude: Number(_.get(siteData, 'longitude')),
    maxCurrent: _.get(siteData, 'maxCurrent'),
    meterNumber: _.get(siteData, 'meterNumber'),
    postalCode: _.get(siteData, 'postalCode'),
    property: {
      host: {
        altId: _.get(siteData, 'property.host.altId'),
      },
      propertyName: _.get(siteData, 'property.propertyName'),
      pid: _.get(siteData, 'property.pid'),
    },
    ratedVoltage: _.get(siteData, 'ratedVoltage'),
    sid: _.get(siteData, 'sid'),
    siteAccessId: _.get(siteData, 'siteAccessId', 108),
    siteName: _.get(siteData, 'siteName'),
    siteStatusId: _.get(siteData, 'siteStatusId', 89),
    siteImages: _.get(siteData, 'siteImages', {}),
    timeZone: _.get(siteData, 'timeZone'),
    locationTypeId: _.get(siteData, 'locationTypeId', 92),
    utilityCompany: _.get(siteData, 'utilityCompany'),
    fleets: _.get(siteData, 'fleets', []),
    costTariff: _.get(siteData, 'costTariff', {}),
    switchgear: _.get(siteData, 'switchgear', {}),
    utilizationHistory: _.get(siteData, 'utilizationHistory', []),
    scheduleViewTimeHorizon: _.get(siteData, 'scheduleViewTimeHorizon'),
  };

  return values;
};
