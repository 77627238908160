/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { path } from './methods';
import _ from 'lodash';
import styled from 'styled-components';

const NeutralWrapper = styled.div`
  width: 100%;
  display: contents;
`;

export interface Props {
  context: string;
  root?: boolean;
  children: React.ReactElement<any, any> | React.ReactElement<any, any>[];
}

export const DataContext: React.FC<Props> = ({ context, root = false, children }) => {
  if (!context) console.warn('A Data Context was created without an identification property.');
  const elDataObject = context
    ? { [path.full]: _.camelCase(context), ...(root && { [`${path.full}-root`]: true }) }
    : {};
  return <NeutralWrapper {...elDataObject}>{children}</NeutralWrapper>;
};
