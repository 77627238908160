import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled.div`
  section {
    header {
      padding: ${`0 ${spacing(3)}px`};
    }
  }
  h2 {
    color: ${colors.accentBlue};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: ${`0 ${spacing(2)}px ${spacing(2)}px`};

        > button {
          position: relative;
          width: 92px;
          height: 92px;
          margin: ${`${spacing(2)}px ${spacing()}px 0`};
          border: ${`${spacing() / 4}px dashed ${palette.text.secondary}`};
          color: ${palette.text.secondary};

          input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
        .detailRow {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        h3 {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }

        .charger-image {
          margin: ${`${spacing(2)}px ${spacing()}px 0`};

          button {
            position: absolute;
            top: 0;
            left: 0;
            width: ${spacing(2.5)}px;
            height: ${spacing(2.5)}px;
            border-radius: ${spacing(1.25)}px;
          }

          img {
            width: 92px;
            height: 92px;
            object-fit: cover;
          }

          svg {
            width: 0.75rem;
            height: 0.75rem;
          }
        }

        .form-control,
        .select {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};

          &.disabled {
            pointer-events: none;
          }

          &.half {
            width: calc(50% - ${spacing(2)}px);
          }
        }

        .form-control {
          label {
            transform: translate(${`${spacing(2.5)}px, ${spacing(3)}px`}) scale(1);

            &[data-shrink='true'] {
              transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
            }
          }
        }

        .menu {
          width: calc(100% - ${spacing(2)}px);
        }
      }
    }
  }
`;
