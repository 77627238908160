import React from 'react';
import { Styled as StyledAccordionDetails } from './styles';

export interface Props {
  connectorIndex: number;
  portIndex: number;
  id?: string;
  className?: string;
}

/**
 * Charger connector plans tab component
 */
export const ChargerConnectorPlansTab: React.FC<Props> = (props) => {
  const { className: parentClass, id: parentId } = props;
  const className = 'ChargerConnectorPlansTab';
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledAccordionDetails id={parentId} className={customClass}>
      TBD
    </StyledAccordionDetails>
  );
};
