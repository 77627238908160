import { Box } from '@material-ui/core';
import moment from 'moment';
import { theme } from '@evgo/react-material-components';
import { Card, CardHeader } from 'src/components/shared/Card';
import { useListSessionsV2ExtendQuery, SessionsV2Sort, ListSessionsV2ExtendQuery } from 'src/@types';
import config from 'src/config';
import { TableLink } from 'src/components/shared/TableLink';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { Table, Column } from 'src/components/shared/Table';
import { formatDate } from 'src/lib/helpers/formatDate';
import { formatTime } from 'src/lib/helpers/formatTime';
import { formatDuration } from 'src/lib/helpers/formatDuration';

type Props = {
  chargerId: string;
};

type SessionV2 = NonNullable<NonNullable<ListSessionsV2ExtendQuery['listSessionsV2']>['edges']>[0];

export const CustomPlanName: React.FC<{ data: SessionV2 }> = ({ data }) => {
  const { track } = useAnalytics();
  if (!data?.plan?.chargerGroup) return <>{'N/A'}</>;
  const to = `/extend-plus/custom-plans/${data?.plan?.chargerGroup?.altId}/details`;
  return (
    <TableLink to={to} onClick={() => track(events.chargerView.CLICKED_CUSTOM_PLAN_LINK)}>
      {data?.plan?.chargerGroup.chargerGroupName}
    </TableLink>
  );
};

const columns: Column<SessionV2>[] = [
  { key: 'vendorId', label: 'ID', sortable: false, width: '8%', numeric: true },
  {
    key: 'dateTime',
    label: 'Date Time',
    formatWithRowData: (session) => {
      const timeZone = session?.connector?.evse?.charger?.site?.timeZone || 'America/Los_Angeles';
      const startTime = new Date(session?.startTime)?.toLocaleString('en-US', { timeZone });
      return `${formatDate(startTime)} ${formatTime(startTime)}`;
    },
    sortable: true,
    width: '12%',
    sortName: 'startDate',
  },
  { key: 'connector.connectorType.columnText', label: 'Connector Type', sortable: false, width: '10%' },
  { key: 'startSource.columnText', label: 'Start Source', sortable: false, width: '8%' },
  {
    key: 'powerValue',
    label: 'Total kWh',
    sortable: true,
    width: '8%',
    numeric: true,
    formatWithRowData: (data) =>
      data?.lastMeterValue ? ((data?.lastMeterValue - (data?.meterStart || 0)) / 1000).toFixed(4) : '-',
  },
  {
    key: 'sessionDuration',
    label: 'Duration',
    sortable: true,
    numeric: true,
    width: '8%',
    formatWithRowData: (session) =>
      formatDuration(
        moment.duration(moment(session?.endTime ? session.endTime : undefined).diff(session?.startTime)).asSeconds(),
      ),
    sortName: 'duration',
  },
  {
    key: 'customPlan',
    label: 'Custom Plan',
    sortable: false,
    width: '8%',
    component: CustomPlanName,
  },
];

export default function Sessions({ chargerId }: Props) {
  const input = {
    filter: {
      endTime: { eq: null },
      chargerId: { eq: chargerId },
      corrupted: { ne: true },
    },
    sort: [SessionsV2Sort.IdDesc],
    page: 0,
    pageSize: config.maxPageSize,
  };
  const { data, loading, error } = useListSessionsV2ExtendQuery({
    variables: {
      input,
    },
    pollInterval: 5000,
  });

  const edges = data?.listSessionsV2?.edges || [];

  return (
    <Box data-testid="sessions-table-card" my={5}>
      <Card>
        <CardHeader title="Active Sessions" />
        <Table
          data-testid="sessions-table"
          id="sessions"
          data={edges}
          loading={loading}
          error={error}
          noDataMessage={'No active sessions at the moment'}
          columns={columns}
          width={`${theme.spacing(200)}px`}
          overflow="auto"
        ></Table>
      </Card>
    </Box>
  );
}
