/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';

const { spacing, typography } = theme;

export const Styled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: ${spacing(6)}px !important;
    color: #fff;
  }

  h6 {
    font-size: ${typography.title};
    padding-top: ${spacing(3)}px;
    color: #fff;
  }
`;
