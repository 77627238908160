import { useQuery } from '@apollo/client';
import { TextInfo } from '@evgo/react-material-components';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Query } from 'src/@types';
import { listOptions } from '../../../../../../../apollo/queries/options';
import { TariffRate } from '../../../../../../shared/TariffRate';
import { initialValues } from '../../../initialValues';
import { Styled as StyledAccordionDetails } from './styles';

export interface Props {
  connectorIndex: number;
  portIndex: number;
  id?: string;
  className?: string;
}

/**
 * Charger connector tariffs tab component
 */
export const ChargerConnectorTariffsTab: React.FC<Props> = (props) => {
  const { className: parentClass, id: parentId, connectorIndex: j, portIndex: i } = props;
  const className = 'ChargerConnectorTariffsTab';
  const { loading } = useQuery<Query>(listOptions, { variables: { optionsInput: 'TariffRateType' } });
  const { values } = useFormikContext<typeof initialValues>();
  const connectorName = values.evses?.edges[i]?.connectors?.edges[j]?.connectorType?.columnText;

  // TODO: API changed, this needs to be re-written
  const tariffIndex = _.findIndex(_.get(values, `evses.edges[${i}].connectors.edges[${j}].tariff.connectors`, []), {
    connectorName,
  });

  // TODO: API changed, this might be the new place where this info is located
  // need to check
  // const rates = values?.evses?.edges[i]?.connectors?.edges[j]?.connectorTariffs?.edges?.map((x) =>
  //   x?.connector?.connectorTariffs?.edges?.map((w) =>
  //     w?.tariff?.tariffItems?.map((z) => z?.tariffCost?.tariffRates?.map((ee) => ee?.rateAmount)),
  //   ),
  // );
  const rates = _.get(values, `evses.edges[${i}].connectors.edges[${j}].tariff.connectors[${tariffIndex}].rates`, []);
  let customClass = `${className} details`;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledAccordionDetails id={parentId} className={customClass}>
      {!_.isEmpty(rates) && (
        <TextInfo
          className={`${className} half`}
          disabled
          id={`${parentId}-connector-tariff-name-${j}`}
          label="Tariff *"
          value={_.get(values, `evses.edges[${i}].connectors.edges[${j}].tariff.tariffName`, '')}
        />
      )}

      {!loading &&
        rates.map((value: unknown, k: number) => (
          <TariffRate
            className={className}
            key={k}
            path={`evses.edges[${i}].connectors.edges[${j}].tariff.connectors[${tariffIndex}].rates[${k}]`}
            readOnly
          />
        ))}
    </StyledAccordionDetails>
  );
};
