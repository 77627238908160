import { gql } from '@apollo/client';
import { FullCharger } from './FullCharger';

export const createCharger = gql`
  mutation createCharger($chargerInput: CreateChargerInput) {
    createCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullCharger}
`;
