import { gql } from '@apollo/client';

export const getHostById = gql`
  query getHostById(
    $hostInput: GetHostInput
    $propertiesInput: ListPropertiesInput
    $sitesInput: ListSitesInput
    $contactsInput: ListContactsInput
  ) {
    getHost(input: $hostInput) {
      hid
      altId
      hostName
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      contacts(input: $contactsInput) {
        edges {
          altId
          contactName
          workTitle
          email
          phone
        }
      }
      properties(input: $propertiesInput) {
        edges {
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
          altId
          propertyName
          pid
          status
          sites(input: $sitesInput) {
            edges {
              chargers {
                total
              }
              altId
              siteName
              address1
              address2
              locality
              administrativeArea
              postalCode
              country
              sid
              displayName
              latitude
              longitude
              access
              siteStatusId
              type
            }
            total
          }
        }
        page
        pageSize
        sort {
          altId
          pid
          propertyName
          hostName
          status
        }
        total
        search {
          pid {
            iLike
          }
          propertyName {
            iLike
          }
          address1 {
            iLike
          }
          locality {
            iLike
          }
          administrativeArea {
            iLike
          }
          postalCode {
            iLike
          }
        }
      }
      status
    }
  }
`;
