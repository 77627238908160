import { MutationTuple, OperationVariables, QueryResult, useMutation } from '@apollo/client';
import { IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip, withStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useState } from 'react';
import { AccountStatus, Card, CardStatus, Driver, Mutation } from 'src/@types';
import { CardType } from 'src/@types/shared';
import { useModalContext } from 'src/contexts/ModalContext';
import { getAccount } from '../../../../../apollo/queries/accounts';
import { deactivateCards, deleteVehicleIdCard, unassignCards } from '../../../../../apollo/queries/cards';
import { useConfirmationDialog } from 'src/lib/hooks';
import { titleCase, updateQuery } from '../../../../../lib/helpers';
import { useSnackbar } from 'src/lib/hooks';

export interface Props {
  accountAltId: string;
  index: number;
  card: Card;
  fetchMore: QueryResult<never, never>['fetchMore'];
  className?: string;
}

interface Input {
  cardIds: string[];
  driverId: string | null;
  accountId: string | null;
}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    marginTop: '0px',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const onUnassign = (
  confirmationDialog: ReturnType<typeof useConfirmationDialog>,
  setAnchorState: ({ anchorElement }: { anchorElement: HTMLButtonElement | null }) => void,
  onUnassignCards: MutationTuple<Mutation, OperationVariables>[0],
  card: Props['card'],
): void => {
  const { cardStatus, altId } = card as Card;
  const input = { cardIds: [altId] } as Partial<Input>;

  if (cardStatus === 'DEACTIVATED') {
    input.driverId = null;
    input.accountId = null;
  }

  confirmationDialog.open({
    title: 'Unassign',
    body: 'Are you sure you want to unassign this card from the account?',
    callback: () => {
      onUnassignCards({
        variables: {
          input,
        },
      });
    },
  });
  setAnchorState({ anchorElement: null });
};

export const CardRow: React.FC<Props> = ({ accountAltId, index, className, card, fetchMore }) => {
  const id = _.kebabCase(`CardRow${index}`);
  const testId = `cards-table-cell`;

  const { setModalState } = useModalContext();
  const [anchorState, setAnchorState] = useState({ anchorElement: null as HTMLButtonElement | null });
  const snackbar = useSnackbar();

  const { anchorElement } = anchorState;
  const confirmationDialog = useConfirmationDialog();
  const refetchQuery = [
    {
      query: getAccount,
      variables: {
        accountInput: { altId: accountAltId },
        driversInput: { pageSize: 999 },
        accountVehiclesInput: { pageSize: 999 },
        cardsInput: { pageSize: 999 },
      },
    },
  ];
  const [deleteVehicleIdCardMutation] = useMutation<Mutation>(deleteVehicleIdCard, {
    refetchQueries: () => refetchQuery,
  });

  const [unassignCardsMutation] = useMutation<Mutation>(unassignCards, {
    refetchQueries: () => refetchQuery,
    onCompleted() {
      snackbar.success('The selected card has been updated.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });

  const [deactivateCardsMutation] = useMutation(deactivateCards, {
    refetchQueries: () => [
      {
        query: getAccount,
        variables: {
          accountInput: { altId: accountAltId },
          driversInput: { pageSize: 999 },
          accountVehiclesInput: { pageSize: 999 },
          cardsInput: { pageSize: 999 },
        },
      },
    ],
    onCompleted() {
      snackbar.success('The selected card has been updated.');
    },
    onError() {
      snackbar.error('Request unsuccessful, please try again.');
    },
  });

  const openEditCardsModal = async (editType: string) => {
    const res = await fetchMore({
      updateQuery,
      variables: {
        accountInput: { altId: accountAltId },
        driversInput: { pageSize: 999 },
        accountVehiclesInput: { pageSize: 999 },
        cardsInput: { pageSize: 999 },
      },
    });

    const { getAccount: updatedData } = res?.data || {};
    const { edges: updatedDrivers } = updatedData?.drivers || {};
    setModalState({
      modalName: 'AccountCardModal',
      modalProps: {
        editType,
        selected: { ...card, driverId: card?.driver?.altId || '' },
        drivers: updatedDrivers?.map((driver: Driver) => ({
          value: driver.altId,
          label: `${driver.firstName} ${driver.lastName}`,
        })),
        accountOwnerId: _.find(updatedDrivers, { driverType: 'OWNER' }).altId,
        accountId: accountAltId,
        fetchMore,
      },
      modalVisible: true,
    });
  };

  const handleOnDeleteCard = useCallback(() => {
    confirmationDialog.open({
      title: `Delete Card`,
      body: `Card ${card?.externalNumber} will be deleted and the related vehicle will be unenrolled from Autocharge+? Do you wish to continue?`,
      callback: () => {
        deleteVehicleIdCardMutation({
          variables: { input: { altId: card?.altId as string } },
        });
      },
    });
  }, [card?.externalNumber, confirmationDialog, deleteVehicleIdCardMutation, card?.altId]);
  const timezoneOffset = new Date().getTimezoneOffset() / 60;

  const assignDisabled: boolean =
    card.cardStatus !== CardStatus.Active ||
    card.account?.accountStatus !== AccountStatus.Active ||
    card.cardTypeId !== CardType.RFID;

  let tooltipMessage = '';
  if (assignDisabled) {
    tooltipMessage = 'Only active RFID cards on active accounts can be reassigned';
  }

  let unassignMutation = unassignCardsMutation;
  if (card && card.cardStatus === 'DEACTIVATED') {
    unassignMutation = deactivateCardsMutation;
  }

  return (
    <Fragment>
      <TableRow className={className} data-testid={`${id}`}>
        <TableCell className={className} id={`${id}-internal-number`} data-testid={`${testId}-internal-${index}`}>
          {card?.internalNumber || ''}
        </TableCell>
        <TableCell className={className} id={`${id}-external-number`} data-testid={`${testId}-external-${index}`}>
          {card?.externalNumber || ''}
        </TableCell>
        <TableCell className={className} id={`${id}-brand`} data-testid={`${testId}-brand-${index}`}>
          {card?.brand || ''}
        </TableCell>
        <TableCell className={className} id={`${id}-type`} data-testid={`${testId}-type-${index}`}>
          {card?.cardType || ''}
        </TableCell>
        <TableCell className={className} id={`${id}-driver-name`} data-testid={`${testId}-driver-name-${index}`}>
          {card?.driver?.firstName || ''} {card?.driver?.lastName || ''}
        </TableCell>
        <TableCell className={className} id={`${id}-status`} data-testid={`${testId}-status-${index}`}>
          {titleCase(card?.cardStatus || 'Unknown')}
        </TableCell>
        <TableCell
          className={className}
          id={`${id}-status-updated-time`}
          data-testid={`${testId}-status-updated-${index}`}
        >
          {_.get(card, 'updatedAt')
            ? moment(_.get(card, 'updatedAt')).subtract(timezoneOffset, 'hours').format('MM/DD/YY h:mm:ss A')
            : moment(_.get(card, 'createdAt')).subtract(timezoneOffset, 'hours').format('MM/DD/YY h:mm:ss A')}
        </TableCell>
        <TableCell className={className} id={`${id}-menu-button-cell`} data-testid={`${testId}-action-${index}`}>
          <IconButton
            data-testid={`${id}-vertical-menu`}
            onClick={(e) => setAnchorState({ anchorElement: e.currentTarget })}
            size="small"
            id={`${id}-menu-button-cell`}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        anchorEl={anchorElement}
        className={className}
        disableAutoFocusItem={true}
        open={Boolean(anchorElement)}
        onClose={() => setAnchorState({ anchorElement: null })}
        variant="menu"
      >
        <HtmlTooltip title={tooltipMessage}>
          <span>
            <MenuItem
              className={className}
              disabled={assignDisabled}
              id={`${id}-menu-assign-option`}
              data-testid={`${id}-menu-assign-option`}
              onClick={() => {
                setAnchorState({ anchorElement: null });
                openEditCardsModal('assign');
              }}
            >
              Assign
            </MenuItem>
          </span>
        </HtmlTooltip>
        <MenuItem
          className={className}
          disabled={card.cardTypeId !== CardType.RFID && card.account?.accountStatus === AccountStatus.Active}
          id={`${id}-menu-update-status-option`}
          data-testid={`${id}-menu-update-status-option`}
          onClick={() => {
            setAnchorState({ anchorElement: null });
            openEditCardsModal('status');
          }}
        >
          Update Status
        </MenuItem>
        <MenuItem
          className={className}
          id={`${id}-menu-unassign-option`}
          data-testid={`${id}-menu-unassign-option`}
          onClick={() => onUnassign(confirmationDialog, setAnchorState, unassignMutation, card)}
        >
          Unassign Card
        </MenuItem>
        {card?.cardTypeId === CardType.VehicleId && (
          <MenuItem
            className={className}
            id={`${id}-menu-delete-option`}
            data-testid={`${id}-menu-delete-option`}
            onClick={() => {
              setAnchorState({ anchorElement: null });
              handleOnDeleteCard();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};
