import { gql } from '@apollo/client';

export const updateAccountOwner = gql`
  mutation updateAccountOwner(
    $accountInput: UpdateAccountOwnerInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    updateAccountOwner(input: $accountInput) {
      altId
      firstName
      lastName
      phone
      email
      driverType
      driverStatus {
        id
        columnText
      }
      account {
        altId
        enrolledOn
        vendorId
        vendorAccountType {
          id
          columnText
        }
        optInPromotions
        mailingAddress {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
        }
        billingAddress {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
        }
      }
      createdAt
      updatedAt
      deletedAt
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
