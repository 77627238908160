import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  div {
    padding: ${`${spacing()}px ${spacing()}px 0`};
    div {
      min-width: 250px;
    }
  }
  .connector-specs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .form-control {
    label {
      transform: translate(${`${spacing(4)}px, ${spacing(5)}px`}) scale(1);
      &.prefilled,
      &[data-shrink='true'] {
        transform: translate(${`${spacing(4)}px, ${spacing(4)}px`}) scale(0.75);
      }
    }
  }
`;
