import { gql } from '@apollo/client';

export const PlanTariffTag = gql`
  fragment PlanTariffTag on PlanTariffTag {
    planChargerGroup {
      altId
      chargerGroupName
      status
      deletedAt
    }
  }
`;
