import { gql } from '@apollo/client';

export const createTag = gql`
  mutation createTag($input: CreateTagInput) {
    createTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers {
        edges {
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
            }
          }
        }
        page
        pageSize
        sort {
          # altId
          cid
          chargerName
          fieldStationStatus
        }
        total
      }
    }
  }
`;
