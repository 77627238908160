import { useLayoutEffect, useState } from 'react';

type UseAutofilledParams = {
  fieldName: string;
  delay?: number;
};

export default function useAutofilled({ fieldName, delay = 500 }: UseAutofilledParams): boolean {
  const [autofilled, setAutofilled] = useState(false);

  useLayoutEffect(() => {
    const check = () => {
      const field = document.getElementsByName(fieldName)?.[0];
      setAutofilled(!!field?.matches('*:-webkit-autofill'));
    };

    setTimeout(check, delay);
  }, [fieldName, delay]);

  return autofilled;
}
