/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const {
  palette,
  spacing,
  typography: { fontSize },
} = theme;

export const Styled = styled.div`
  @keyframes spin-clockwise {
    0% {
      transform: rotateZ(0);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  .app-bar {
    position: relative;
    width: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    align-items: center;
    color: black;
    background: white;
    z-index: 1;

    > div {
      margin: 0;
    }

    button {
      border: 1px solid #daf0fe;
      &[aria-selected='true'] {
        background-color: ${palette.primary[50]};
        transition: all 0.2s ease;
        border-bottom: none;
      }
    }
  }

  form {
    section {
      > header {
        display: flex;
        align-items: center;
        color: ${palette.text.secondary};
        font-size: ${fontSize * (6 / 7)}px;

        > * {
          margin: 0 ${spacing(3) / 2}px;
        }

        > div {
          display: flex;

          > * {
            margin: ${`${spacing()}px ${spacing()}px -${spacing() * 1.5}px`};
          }

          > .form-control {
            flex-basis: 25%;

            &:nth-child(2) {
              flex-basis: 40%;
            }
          }

          > :first-child {
            margin-left: 0;
          }

          > :last-child {
            margin-right: 0;
          }
        }

        > :first-child {
          flex: 1;
          margin-left: 0;
        }

        > :last-child {
          margin-right: 0;
        }
      }

      > div {
        margin: ${`0 ${spacing(3) / 2}px ${spacing(3)}px`};
      }
    }

    footer {
      > button {
        svg {
          position: absolute;
          animation: spin-clockwise 1s linear infinite;
        }
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
`;
