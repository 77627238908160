import { EvsesWithMeta, Maybe } from 'src/@types';
import { ListChargersV2Charger } from 'src/@types/shared';
import { convertSiteAddress } from 'src/lib/helpers/convertSiteAddress';
import getChargerReservableStatus from '../utils/getChargerReservableStatus';

export type Filters = {
  search?: string;
  zips?: string[];
  chargerLevels?: string[];
  states?: string[];
};

type FilterDefinition = {
  shouldRun: (filters: Filters) => boolean;
  run: (charger: Maybe<Partial<ListChargersV2Charger>>, filters: Filters) => boolean;
};

const definitions: FilterDefinition[] = [
  {
    shouldRun: (filters) => !!filters?.zips?.length,
    run: (charger, filters) => {
      return filters?.zips?.includes(charger?.site?.postalCode || '') || false;
    },
  },
  {
    shouldRun: (filters) => !!filters?.states?.length,
    run: (charger, filters) => {
      return filters?.states?.includes(charger?.site?.administrativeArea || '') || false;
    },
  },
  {
    shouldRun: (filters: Filters) => !!filters.search,
    run: (charger: Maybe<Partial<ListChargersV2Charger>>, filters: Filters) => {
      const search = filters?.search?.toLowerCase().trim() || '';
      const searchables: Array<string | undefined | null> = [
        charger?.chargerName,
        charger?.chargerModel?.modelName,
        charger?.chargerStatus?.columnText,
        charger?.chargerStatus as string,
        charger?.site?.siteName,
        convertSiteAddress(charger as ListChargersV2Charger),
      ];

      return searchables.some((searchable) => searchable?.toString().toLowerCase()?.includes(search));
    },
  },
  {
    shouldRun: (filters) => !!filters?.chargerLevels?.length,
    run: (charger, filters) => {
      //if one of the connectors has a charger level, the whole charger has the same charger level
      const chargingLevel = charger?.evses?.[0]?.connectors?.[0]?.chargingLevel;
      return (!!chargingLevel && filters.chargerLevels?.includes(chargingLevel)) || false;
    },
  },
];

export function runFilters(filters: Filters, data: Maybe<Partial<ListChargersV2Charger>>[]) {
  return definitions.reduce((rows, filter) => {
    rows = rows.map((row) => ({ ...row, reservable: getChargerReservableStatus(row?.evses as EvsesWithMeta) }));
    return filter.shouldRun(filters) ? rows.filter((row) => filter.run(row, filters)) : rows;
  }, data);
}
