import { gql } from '@apollo/client';

export const listChargers = gql`
  query listChargers($chargersInput: ListChargersInput) {
    listChargers(input: $chargersInput) {
      edges {
        altId
        chargerName
        cid
        fieldStationStatus
        chargerModel {
          altId
          modelName
        }
        site {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          latitude
          longitude
          property {
            propertyName
          }
        }
        warehouse {
          altId
          warehouseName
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
      }
      page
      pageSize
      total
      search {
        cid {
          iLike
        }
        chargerName {
          iLike
        }
        site_address1 {
          iLike
        }
        site_locality {
          iLike
        }
        site_administrativeArea {
          iLike
        }
        site_postalCode {
          iLike
        }
        site_property_propertyName {
          iLike
        }
      }
      sort {
        cid
        chargerName
        fieldStationStatus
        propertyName
        chargerModel_modelName
      }
      filter {
        chargerModel_altId {
          in
        }
        fieldStationStatus {
          in
        }
        tags {
          or {
            contains
          }
        }
      }
    }
  }
`;
