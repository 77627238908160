import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { Button, InputAdornment, SnackbarContent, TextField, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { Query } from '../../../../../@types';
import { HostError, Snackbar } from '../../../../../@types/shared';
import { getFullAddress } from '../../../../../lib/helpers';
import { Styled } from './styles';

export interface Props {
  host: HostError;
  setHost: (error: HostError) => void;
  inputRef: React.MutableRefObject<null>;
  snackbar: Snackbar;
  onFindHost: (
    findSalesforceHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void,
    findLocalHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void,
    inputRef: React.MutableRefObject<null>,
  ) => () => void;
  findSalesforceHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
  findLocalHost: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
  queryData: Query;
}

export const FindHostStep: React.FC<Props> = (props) => {
  const id = _.kebabCase('FindHost');
  const {
    host,
    setHost,
    inputRef,
    snackbar: { message, action },
    onFindHost,
    findSalesforceHost,
    findLocalHost,
    queryData,
  } = props;
  const className = id;

  const address = getFullAddress({
    streetAddress: queryData?.getSalesforceHost?.address1 || '',
    unit: queryData?.getSalesforceHost?.address2 || '',
    city: queryData?.getSalesforceHost?.locality || '',
    state: queryData?.getSalesforceHost?.administrativeArea || '',
    postalCode: queryData?.getSalesforceHost?.postalCode || '',
    country: queryData?.getSalesforceHost?.country || '',
  });

  const primaryContact = queryData?.getSalesforceHost?.salesforceHostContacts?.edges?.[0] || false;
  const visibility = { visibility: queryData?.getSalesforceHost?.hid ? 'visible' : 'hidden' } as React.CSSProperties;

  const hostInfo = (data: Record<string, unknown> | boolean, field: string, value: string, variant: Variant) =>
    data ? (
      <Typography id={`${id}-${_.kebabCase(field)}`} className={className} variant={variant}>
        {value}
      </Typography>
    ) : null;

  return (
    <Styled className={clsx(className, 'step-0')}>
      <div className={className}>
        <div className={className}>
          <TextField
            id={`${id}-host-search-input`}
            error={!!host.error || !!message}
            className={className}
            inputRef={inputRef}
            autoFocus
            margin="dense"
            placeholder="Enter the host ID..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (!new RegExp(/[Hh][0-9]/).test(_.get(inputRef, 'current.value'))) {
                  // TODO: remove never when parent component use optional chaining `?.`
                  onFindHost(findSalesforceHost, findLocalHost, { current: { value: '' } } as never)();
                  setHost({ error: { type: 'Invalid string' } });
                } else {
                  onFindHost(findSalesforceHost, findLocalHost, inputRef)();
                  setHost({ error: null });
                }
              }
            }}
          />

          <Button
            id={`${id}-find-host-button`}
            className={className}
            color="secondary"
            onClick={() => {
              if (!new RegExp(/[Hh][0-9]/).test(_.get(inputRef, 'current.value'))) {
                // TODO: remove never when parent component use optional chaining `?.`
                onFindHost(findSalesforceHost, findLocalHost, { current: { value: '' } } as never)();
                setHost({ error: { type: 'Invalid string' } });
              } else {
                onFindHost(findSalesforceHost, findLocalHost, inputRef)();
                setHost({ error: null });
              }
            }}
            variant="contained"
          >
            Find
          </Button>
        </div>

        <Typography className={className} color="textSecondary" variant="caption">
          Account Information
        </Typography>

        {hostInfo(queryData, 'hostName', _.get(queryData, 'getSalesforceHost.hostName', ''), 'subtitle1')}
        {hostInfo(queryData, 'address1', _.get(queryData, 'getSalesforceHost.address1', ''), 'caption')}
        {hostInfo(queryData, 'address2', _.get(queryData, 'getSalesforceHost.address2', ''), 'caption')}
        {hostInfo(queryData, 'cityStatePostalCode', address, 'caption')}

        <br />

        {queryData && primaryContact ? (
          <Typography className={clsx(className, 'contact')} style={visibility} variant="caption">
            Contact
          </Typography>
        ) : null}

        <br />

        {hostInfo(primaryContact, 'contactName', _.get(primaryContact, 'contactName', ''), 'caption')}
        {hostInfo(primaryContact, 'phone', _.get(primaryContact, 'phone', ''), 'caption')}
        {hostInfo(primaryContact, 'email', _.get(primaryContact, 'email', ''), 'caption')}

        <div className={className}>
          <SnackbarContent
            id={`${id}-snackbar`}
            className={clsx(className, 'snackbar')}
            style={{ visibility: message ? 'visible' : 'hidden' }}
            message={message || ''}
            action={action}
          />
        </div>
      </div>
    </Styled>
  );
};
