import { gql } from '@apollo/client';

export const listTagsOptions = gql`
  query listTagsOptions($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      edges {
        altId
        tagName
      }
      page
      pageSize
      total
    }
  }
`;
