import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  className?: string;
  text?: string;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
};

/**
 * It returns a SubTitle component that renders an <h6> element
 * @param {Props} - properties
 * @example <SubTitle>Your text here</SubTitle>
 * @returns A styled component for subtitle
 */
export const SubTitle: React.FC<Props> = ({ className, children, color = 'textSecondary' }) => {
  return (
    <Typography className={clsx('subtitle', className)} variant="subtitle1" color={color}>
      {children}
    </Typography>
  );
};
