import { useMutation, useQuery } from '@apollo/client';
import { TableCellDropdown } from '@evgo/react-material-components';
import { Button, IconButton, TableCell } from '@material-ui/core';
import { AddCircleOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FalconConstraint, Maybe, Mutation, PropertiesWithMeta, Query, Site } from 'src/@types';
import { LabelValue, MutationOp } from 'src/@types/shared';
import { useConfirmationDialog } from 'src/lib/hooks';
import { getHostById } from '../../../../../apollo/queries/hosts';
import { listFalconConstraints } from '../../../../../apollo/queries/options';
import { updateProperty } from '../../../../../apollo/queries/properties';
import { updateSite } from '../../../../../apollo/queries/sites';
import { titleCase } from '../../../../../lib/helpers';
import { Row } from '../types';
import { Styled } from './styles';

export interface Props {
  colSpan: number;
  row: Row;
  host: { altId: string; hid: string };
  metadata: PropertiesWithMeta;
  className?: string;
}

export const onChangePropertyStatus =
  (updatePropertyStatus: MutationOp, confirmationDialog: ReturnType<typeof useConfirmationDialog>, row: Row) =>
  (option: LabelValue<string>): void => {
    if (_.lowerCase(option.value) !== _.lowerCase(row.status as string)) {
      confirmationDialog.open({
        title: 'Property Status Change',
        body: 'Are you sure you want to change the status of this property?',
        callback: () =>
          updatePropertyStatus({
            variables: { propertyInput: { altId: row.altId, status: _.toUpper(option.value) } },
          }),
      });
    }
  };

export const onChangeSiteStatus =
  (updateSiteStatus: MutationOp, confirmationDialog: ReturnType<typeof useConfirmationDialog>, site: Site) =>
  (option: LabelValue<string>): void => {
    confirmationDialog.open({
      title: 'Site Status Change',
      body: 'Are you sure you want to change the status of this site?',
      callback: () =>
        updateSiteStatus({
          variables: {
            siteInput: { ...site, siteStatusId: Number(option.value) },
          },
        }),
    });
  };

export const PropertyRow: React.FC<Props> = (props) => {
  const className = 'PropertyRow';
  const confirmationDialog = useConfirmationDialog();
  const [updatePropertyStatus] = useMutation<Mutation>(updateProperty);
  const [updateSiteStatus] = useMutation<Mutation>(updateSite, {
    refetchQueries: () => [
      {
        query: getHostById,
        variables: { hostInput: { altId: props.host.altId } },
      },
    ],
  });

  const { colSpan, row } = props;
  const [metadata, setMetadata] = useState(props.metadata);
  const [open, setOpen] = useState(false);
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const { data: siteStatusOptions, loading: statusOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'status',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });

  let shapedSiteStatusOptions: Maybe<Maybe<FalconConstraint>[]> = [];
  if (!statusOptionsLoading) {
    shapedSiteStatusOptions = siteStatusOptions?.listFalconConstraints?.edges || [];
  }

  const newSiteButton = (
    <Styled className={`${className} filler-row`}>
      <TableCell className={className} colSpan={colSpan}>
        <Link
          to="/sites/new"
          state={{
            hid: props.host.hid,
            pid: row.pid,
            propertyName: row.propertyName,
          }}
        >
          <Button className={className} color="secondary">
            <AddCircleOutline className={`${className} left`} />
            New Site
          </Button>
        </Link>
      </TableCell>
    </Styled>
  );

  if (!_.isEqual(metadata, props.metadata)) {
    setMetadata(props.metadata);
    setOpen(false);
  }

  return (
    <>
      <Styled className={`${className} ${open ? 'open' : ''}`}>
        <TableCell className={className}>{row.propertyName}</TableCell>

        <TableCell className={className}>
          <TableCellDropdown
            className={`${className} status-menu`}
            options={statusOptions}
            onChange={onChangePropertyStatus(updatePropertyStatus, confirmationDialog, row)}
            value={row.status}
          />
        </TableCell>
        <TableCell className={className}>{row.address}</TableCell>
        <TableCell className={className}>{row.pid}</TableCell>
        <TableCell className={className}>{row.totalChargers}</TableCell>
        <TableCell className={className}>{row.totalSites}</TableCell>
        <TableCell className={className}>
          <IconButton size="small" onClick={() => setOpen((prevOpen) => !prevOpen)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
      </Styled>
      {open && row.sites?.length
        ? row.sites.map((site, j) => {
            const sitePayload = {
              ..._.omit(site, ['chargers', '__typename']),
              hid: props.host.hid,
              pid: row.pid,
            };
            return (
              <Styled className={`${className} ${open ? 'open' : ''}`} key={j}>
                <TableCell className={`${className} site`}>
                  <Link className={`${className} site-link`} to={`/sites/${site.altId}/profile`}>
                    {site.siteName}
                  </Link>
                </TableCell>

                <TableCell className={className}>
                  <TableCellDropdown
                    className={`${className} status-menu`}
                    options={shapedSiteStatusOptions}
                    onChange={onChangeSiteStatus(updateSiteStatus, confirmationDialog, sitePayload)}
                    value={titleCase(
                      _.find(
                        shapedSiteStatusOptions,
                        (option) => Number(option?.columnValue) === _.get(site, 'siteStatusId', ''),
                      )?.columnName || '',
                    )}
                  />
                </TableCell>
                <TableCell className={className}>{`${_.get(site, 'address1', '')}${
                  _.get(site, 'address2') ? ` ${_.get(site, 'address2')}` : ''
                }, ${_.get(site, 'locality', '')}, ${_.get(site, 'administrativeArea', '')} ${_.get(
                  site,
                  'postalCode',
                  '',
                )}`}</TableCell>
                <TableCell className={className}>{site.sid}</TableCell>
                <TableCell className={className}>{site.chargers ? site.chargers.total : 0}</TableCell>
                <TableCell className={className}>1</TableCell>
                <TableCell className={className}></TableCell>
              </Styled>
            );
          })
        : null}

      {open ? newSiteButton : null}
    </>
  );
};
