import { MockedProvider, MockedResponse } from '@apollo/client/testing';
import { ReactNode } from 'react';
import { MemoryRouter, Route, Routes } from 'react-router-dom';
import { ModalProvider } from 'src/contexts/ModalContext';
import { SnackbarProvider } from 'src/contexts/SnackBarContext';
import { render, RenderResult } from '@testing-library/react';

type Props = {
  mocks: ReadonlyArray<MockedResponse>;
  addTypename?: boolean;
};

type PropsWithRouter = {
  mocks: ReadonlyArray<MockedResponse>;
  path: string;
  initialEntries: string[];
  addTypename?: boolean;
};

export const renderWithMockedProvider = (Element: ReactNode, { mocks, addTypename = false }: Props) => {
  return render(
    <MockedProvider mocks={mocks} addTypename={addTypename}>
      <ModalProvider>
        <SnackbarProvider>{Element}</SnackbarProvider>
      </ModalProvider>
    </MockedProvider>,
  );
};

export const renderWithMockedProviderAndRouter = (
  Element: ReactNode,
  { mocks, initialEntries, path, addTypename = false }: PropsWithRouter,
) => {
  return render(
    <MockedProvider mocks={mocks} addTypename={addTypename}>
      <MemoryRouter initialEntries={initialEntries}>
        <ModalProvider>
          <SnackbarProvider>
            <Routes>
              <Route path={path} element={Element} />
            </Routes>
          </SnackbarProvider>
        </ModalProvider>
      </MemoryRouter>
    </MockedProvider>,
  );
};

export const customRender = (Element: ReactNode): RenderResult => {
  return render(
    <ModalProvider>
      <SnackbarProvider>{Element}</SnackbarProvider>
    </ModalProvider>,
  );
};

// re-export everything
export * from '@testing-library/react';

// override render method
export { customRender as render };
