import { TextInfo } from '@evgo/react-material-components';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon, Delete as DeleteIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { initialValues } from '../../../initialValues';
import { PlanDetailListItem } from './PlanDetailListItem';
import { Styled } from './styles';

export type Props = {
  path: string;
  index: number;
  id?: string;
  className?: string;
};

const type = 'DetailList';

export const PlanDetailListSection: React.FC<Props> = ({ id: parentId, path, index }) => {
  const id = _.kebabCase('PlanDetailListSection');
  const className = id;
  const ref = useRef(null);

  const { errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values } =
    useFormikContext<typeof initialValues>();

  const handleRemoveSection = useCallback(() => {
    const value = [...values.detailSections];

    _.pullAt(value, [index]);
    setFieldValue('detailSections', value);
  }, [index, setFieldValue, values]);

  const handleAddListItem = useCallback(() => {
    const listSection = _.get(values, path);
    const value = [...listSection.items, ''];

    setFieldValue(`${path}.items`, value);
  }, [path, setFieldValue, values]);

  const detailSections = _.get(values, 'detailSections');

  const moveDetailList = (dragIndex: number, hoverIndex: number): void => {
    const draggedList = detailSections[dragIndex];
    detailSections.splice(dragIndex, 1);
    detailSections.splice(hoverIndex, 0, draggedList);

    setFieldValue('detailSections', detailSections);
  };

  const [, drop] = useDrop<{ type: string; index: number }, unknown, unknown>(() => ({
    accept: type,
    hover(item) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveDetailList(dragIndex, hoverIndex);
      item.index = hoverIndex; // eslint-disable-line no-param-reassign
    },
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: () => ({ index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  drag(drop(ref));

  return (
    <Styled id={parentId} className={clsx(className)} ref={ref} style={{ opacity: isDragging ? '0.5' : '1' }}>
      <Box display="flex" className={className}>
        <TextInfo
          className={clsx(className, 'list-title')}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          error={!!(_.get(touched, `${path}.header`) && _.get(errors, `${path}.header`))}
          helpertext={_.get(touched, `${path}.header`) && _.get(errors, `${path}.header`)}
          id={`${id}-${index}-plan-list-title-input`}
          label="List Title *"
          labelProps={{ className }}
          name={`${path}.header`}
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, `${path}.header`)}
          data-testid={`${id}-${index}-plan-list-title-input`}
        />
        {detailSections.length && (
          <Button
            className={className}
            disabled={isSubmitting || !values.displayToCustomer}
            id={`${id}-${index}-list-remove-button`}
            onClick={handleRemoveSection}
            data-testid={`${id}-${index}-list-remove-button`}
          >
            <DeleteIcon className={className} color={isSubmitting ? 'inherit' : 'secondary'} />
            Delete
          </Button>
        )}
      </Box>

      <Typography component="h3">List Items</Typography>

      <Divider className={clsx(className, 'divider')} />

      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (values as any).detailSections[index]?.items?.map((value: any, i: number) => (
          <PlanDetailListItem className={className} key={i} index={i} section={index} path={`${path}.items`} />
        ))
      }

      <Button
        className={clsx(className, 'add-button')}
        disabled={isSubmitting || !values.displayToCustomer}
        id={`${id}-${index}-list-add-button`}
        onClick={handleAddListItem}
        data-testid={`${id}-${index}-list-add-button`}
      >
        <AddCircleOutlineIcon
          className={clsx(className, 'add-button-icon')}
          color={isSubmitting ? 'inherit' : 'primary'}
        />
        Add List Item
      </Button>
    </Styled>
  );
};
