import React from 'react';
import { Paper, Box, BoxProps } from '@material-ui/core';
export * from './CardHeader';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
  square?: boolean;
  px?: number;
  py?: number;
  mb?: number;
}

export const Card: React.FC<CardProps & BoxProps> = ({ py = 4, px = 4, square = true, ...rest }) => {
  const props = { ...rest, variant: 'outlined', square, px, py };
  return <Box component={Paper} {...props} />;
};
