import { theme } from '@evgo/react-material-components';
import {
  Avatar,
  colors,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ExitToApp } from '@material-ui/icons';
import { User } from 'firebase/auth';
import React from 'react';
import { OperatorPortalRole, RoleApp } from 'src/@types/shared';
import config from 'src/config';
import { useGetFirstUserHost, useRoles } from 'src/lib/hooks';
import styled from 'styled-components';

export const StyledDiv = styled.div`
  svg {
    margin-top: ${theme.spacing(0.5)}px;
  }

  #header-menu-avatar {
    background-color: ${colors.yellow['700']};
    color: ${theme.palette.grey['900']};
  }
`;

export const StyledIcon = styled(ListItemIcon)`
  color: inherit !important;
  min-width: ${theme.spacing(4)}px !important;
`;

export const StyledUserEmail = styled.li`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
`;

export const StyledUserHost = styled.li`
  padding: 0 ${theme.spacing(2)}px ${theme.spacing(2)}px;
`;

interface Props {
  user?: User | null;
  onLogout?: () => void;
}

const id = 'header-menu';

export const ExtendUserHostLabel: React.FC = () => {
  const host = useGetFirstUserHost();
  return (
    <>
      {host?.hostName && (
        <StyledUserHost data-testid={`${id}-user-host`}>
          <Typography variant="body2" color="textSecondary">
            {host.hostName}
          </Typography>
        </StyledUserHost>
      )}
    </>
  );
};

export const HeaderMenu: React.FC<Props> = ({ user, onLogout }) => {
  const className = `${id}-component`;
  const { hasRole } = useRoles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isSuperAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.SuperAdmin);
  const isPartnerAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.PartnerAdmin);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return null;
  }

  return (
    <StyledDiv>
      <ListItem id={`${id}-profile-menu-item`} className={className} button onClick={handleMenu}>
        <ListItemAvatar attr-id={`${id}-avatar`} className={className}>
          <Avatar id={`${id}-avatar`}>{user.email?.charAt(0).toUpperCase()}</Avatar>
        </ListItemAvatar>

        <div className={className}>{open ? <ArrowDropUp /> : <ArrowDropDown />}</div>
      </ListItem>
      <Menu
        style={{
          top: theme.spacing(5),
          left: `-${theme.spacing(2.5)}px`,
        }}
        id="menu-appbar"
        className={`${className} menu`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        data-testid={`${id}-select`}
        role="menu-list"
      >
        <StyledUserEmail data-testid={`${id}-user-email`}>
          <Typography variant="body1">{user.email}</Typography>
        </StyledUserEmail>
        {isPartnerAdmin && <ExtendUserHostLabel />}

        {isSuperAdmin && (
          <MenuItem
            component={'a'}
            href={config.env.reportals.url}
            role="option"
            data-testid={`${id}-select-option-switch-to-reportal`}
          >
            Switch to Reportal
          </MenuItem>
        )}

        <Divider />

        <MenuItem onClick={onLogout} data-testid={`${id}-select-option-logout`} role="option">
          <StyledIcon>
            <ExitToApp fontSize="small" />
          </StyledIcon>
          Log out
        </MenuItem>
      </Menu>
    </StyledDiv>
  );
};
