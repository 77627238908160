import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import CustomLoader, { CustomLoaderProps } from './CustomLoader';

export type CustomLoaderContainerProps = CustomLoaderProps & {
  loading?: boolean;
};

const CustomLoaderContainer: React.FC<CustomLoaderContainerProps> = ({ loading, children, ...props }) => {
  const { isSubmitting = false } = useFormikContext() || {};

  const isLoading = loading !== undefined ? loading : isSubmitting;

  return (
    <>
      {!isLoading && children}
      {isLoading && (
        <Box p={10}>
          <CustomLoader {...props} />
        </Box>
      )}
    </>
  );
};

export default CustomLoaderContainer;
