/* stylelint-disable color-no-hex */
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';

const { spacing, typography, palette } = theme;

export const Styled = styled.div`
  &.property-detail-root {
    font-style: normal;
    font-size: ${typography.fontSize}px;
    line-height: ${typography.fontSize + 2}px;
    color: ${palette.text.secondary};
  }

  .property-detail-title {
    font-weight: 700;
    letter-spacing: 0.004em;
  }

  .property-detail-content {
    font-weight: 400;
    letter-spacing: 0.0015em;
  }

  .property-detail-copy-button {
    background-color: ${palette.common.white};
    border: 1px solid #dcdcdc;

    .property-detail-copy-icon {
      font-size: ${typography.fontSize}px;
      padding: ${spacing(0.25)}px;
    }
  }
`;
