import { useListExtendUsersQuery } from 'src/@types';
import { Box, Button } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Card } from 'src/components/shared/Card';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { AssignUserModal } from './AssignUserModal';
import { Styled } from './styles';
import { ExtendUsersList } from './UsersList';
import { useModalContext } from 'src/contexts/ModalContext';

export const UserManagementView: React.FC = () => {
  const { modalName, modalProps, modalVisible, setModalState } = useModalContext();
  const { data, loading, error, fetchMore } = useListExtendUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pageSize: 9999,
      },
    },
  });

  let totalAssignedUsers = 0;
  _.forEach(_.get(data, 'listExtendUsers.edges'), (user) => {
    if (user.hosts.edges.length) {
      totalAssignedUsers += 1;
    }
  });

  return (
    <Styled>
      <Box className="title-wrapper">
        <Title>eXtend+ Users</Title>
        <SubTitle>Assign a Firebase user to an eXtend+ host account</SubTitle>
      </Box>
      <Card className="table-container">
        <Box pb={2} className="header-wrapper">
          <Button
            onClick={() => {
              setModalState({
                modalName: 'AssignUserModal',
                modalProps: {
                  fetchMore,
                },
                modalVisible: true,
              });
            }}
            variant="contained"
            color="secondary"
            size="large"
            data-testid="assign-user-button"
          >
            Assign User
          </Button>
          <SubTitle>Total # of users: {totalAssignedUsers}</SubTitle>
        </Box>
        <ExtendUsersList data={data?.listExtendUsers} loading={loading} error={error} fetchMore={fetchMore} />
      </Card>
      {modalName === 'AssignUserModal' && modalVisible && (
        <AssignUserModal {...modalProps} open={modalName === 'AssignUserModal' && modalVisible} />
      )}
    </Styled>
  );
};
