import { gql } from '@apollo/client';

export const listVehicles = gql`
  query listVehicles($vehiclesInput: ListVehiclesInput) {
    listVehicles(input: $vehiclesInput) {
      edges {
        altId
        makeModelYear
        vehicleConnectors {
          id
          connectorName: name
        }
      }
      pageSize
      total
    }
  }
`;
