import { Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDeactivateChargerGroupPlanForExtendMutation } from 'src/@types';
import { useConfirmationDialog, useSnackbar } from 'src/lib/hooks';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

type DeactivatePlanButtonProps = {
  altId: string;
  disabled?: boolean;
};

export function DeactivatePlanButton({ altId, disabled }: DeactivatePlanButtonProps) {
  const snackBar = useSnackbar();
  const navigate = useNavigate();
  const dialog = useConfirmationDialog();
  const { track } = useAnalytics();

  const [deactivateChargerGroupPlanForExtend] = useDeactivateChargerGroupPlanForExtendMutation({
    variables: {
      input: {
        altId,
      },
    },
    onCompleted() {
      snackBar.success('Your custom access plan has been deactivated');
      navigate('/extend-plus/custom-plans');
    },
    onError() {
      snackBar.error('Failed to deactivate the custom access plan');
    },
  });

  const onClick = () => {
    dialog.open({
      title: 'Deactivate plan',
      body: 'Are you sure you want to deactivate this plan? All users on this plan will no longer have access to it.',
      buttonConfirmText: 'Deactivate',
      callback: () => {
        deactivateChargerGroupPlanForExtend();
        track(events.customPlanSummary.DEACTIVATED_CUSTOM_PLAN_PRICE);
      },
    });
    track(events.customPlanSummary.CLICKED_DEACTIVATE_PLAN_BUTTON);
  };

  if (disabled) return null;

  return (
    <Button
      data-testid="deactivate-plan-button"
      color="secondary"
      variant="outlined"
      onClick={onClick}
      startIcon={<Delete />}
    >
      Deactivate Plan
    </Button>
  );
}
