import { Maybe, Site } from 'src/@types';
import { getSiteFullAddress } from './utils';

export type Filters = {
  search: string;
  status: string[];
  states: string[];
  zips: string[];
};

type FilterDefinition = {
  shouldRun: (filters: Filters) => boolean;
  run: (site: Maybe<Site>, filters: Filters) => boolean;
};

const definitions: FilterDefinition[] = [
  {
    shouldRun: (filters: Filters) => !!filters.search,
    run: (site, filters) => {
      const search = filters?.search?.toLowerCase().trim() || '';
      const searchables = [site?.siteName, getSiteFullAddress(site), site?.siteStatus];
      return searchables.some((searchable) => searchable?.toLowerCase()?.includes(search));
    },
  },
  {
    shouldRun: (filters) => !!filters.status.length,
    run: (site, filters) => {
      const value = site?.siteStatusId?.toString();
      return !!(value && filters.status.includes(value));
    },
  },
  {
    shouldRun: (filters) => !!filters?.states?.length,
    run: (site, filters) => {
      return filters?.states?.includes(site?.administrativeArea || '') || false;
    },
  },
  {
    shouldRun: (filters) => !!filters?.zips?.length,
    run: (site, filters) => {
      return filters?.zips?.includes(site?.postalCode || '') || false;
    },
  },
];

export function runFilters(filters: Filters, data: Maybe<Site>[]) {
  return definitions.reduce(
    (rows, filter) => (filter.shouldRun(filters) ? rows.filter((row) => filter.run(row, filters)) : rows),
    data,
  );
}
