import React, { Fragment } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DriverCards } from './DriverCards';
import { DriverNotifications } from './DriverNotifications';
import { DriverProfile } from './DriverProfile';

/**
 * Driver profile tab component
 */
export const DriverProfileTab: React.FC = () => {
  const { altId, url } = useParams<{ altId: string; url: string }>();

  if (url === '/drivers/new' && altId) {
    return <Navigate to={`/drivers/${altId}/profile`} />;
  }

  return (
    <Fragment>
      <DriverProfile />
      <DriverNotifications />
      <DriverCards />
    </Fragment>
  );
};
