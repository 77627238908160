import { gql } from '@apollo/client';

export const FullPlan = gql`
  fragment FullPlan on Plan {
    altId
    cardBrand
    cardBrandId
    createdAt
    costFactor
    description: planDescription
    displayName: planDisplayName
    subheading: planSubheading
    duration: planDuration
    displayToCustomer
    displayToLoggedOutUser
    displayPlanCard
    driverRank
    isAutoEnrolledInRewards
    elements(input: { pageSize: 9999 }) {
      edges {
        day
        discount
        endTime
        maxDuration
        startTime
        connectorType {
          id
        }
      }
    }
    enrollmentClosed: enrollmentClose
    enrollmentOpen
    financeCode
    intervalLength
    intervalUnit
    intervalUnitId
    planCode
    planName
    badge
    balanceTypeId
    ctaLabel
    discount: planDiscount
    templateId
    products(input: { pageSize: 9999 }) {
      edges {
        productType
        productTypeId
        credit {
          creditAmount
          creditExpPolicy
          creditExpPolicyId
          creditReoccurrence
          creditReoccurrenceId
          creditOneTimeAmount: oneTimeCreditAmount
        }
        subscription {
          subscriptionFee
          subscriptionReoccurrence
          subscriptionReoccurrenceId
        }
      }
    }
    requireVin: vinRequiredFlag
    rolloverPlan {
      altId
    }
    status: planStatus
    planStatusId
    pageHeader: detailHeading
    detailSections {
      header
      items
    }
    detailSessionTimes {
      duration
      interval
      startTime
      endTime
    }
    legalese
    terminationBehavior
    terminationBehaviorId
  }
`;
