import { ApolloError } from '@apollo/client';
import { TableContainer, Typography } from '@material-ui/core';
import React from 'react';
import { Maybe } from 'src/@types';
import { ListChargersV2Charger } from 'src/@types/shared';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { convertSiteAddress } from 'src/lib/helpers/convertSiteAddress';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';
import { Filters, runFilters } from '../../filterHelpers';

type Props = {
  selected: string[];
  onSelectChange: (chargers: string[]) => void;
  data?: Maybe<Maybe<ListChargersV2Charger>[]>;
  loading: boolean;
  error?: ApolloError;
  filters: Filters;
};

const StyledTableContainer = styled(TableContainer)`
  max-height: calc(100vh - 450px);
`;

const defaultSort = {
  sortBy: 'chargerName',
  sortDirection: Direction.Asc,
};

const columns: Column[] = [
  { key: 'chargerName', label: 'Charger Name', sortable: true, width: '20%' },
  {
    key: 'chargerModel.modelName',
    label: 'Model',
    sortable: true,
    width: '20%',
  },
  {
    key: 'chargerStatus.columnText',
    label: 'Status',
    sortable: true,
    width: '5%',
    formatWithRowData: (charger) => charger?.chargerStatus?.columnText?.toUpperCase(),
  },
  { key: 'site.siteName', label: 'Site', sortable: true, width: '15%' },
  { key: 'address', label: 'Address', sortable: false, width: '40%', formatWithRowData: convertSiteAddress },
];

export const ChargerSelectionList: React.FC<Props> = ({ selected, onSelectChange, data, loading, error, filters }) => {
  const { track } = useAnalytics();
  return (
    <>
      <Typography variant="subtitle2"># of chargers selected: {`${selected.length}`}</Typography>
      <StyledTableContainer>
        <Table
          id="charger-selection-list"
          data-testid="charger-selection-list"
          stickyHeader
          data={runFilters(filters, data || [])}
          columns={columns}
          loading={loading}
          error={error}
          selectRows={{
            id: 'altId',
            values: selected,
            onSelectChange,
          }}
          sorting={defaultSort}
          noDataMessage="Create a price group to easily apply prices to your chargers"
          onSelectAll={() => track(events.chargerSelectionModal.CLICKED_CHARGER_SELECT_ALL_CHECKBOX)}
        />
      </StyledTableContainer>
    </>
  );
};
