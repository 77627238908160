import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  table {
    tbody {
      td {
        padding: ${`${spacing() / 2}px ${spacing(3)}px`};
      }
    }
  }
`;
