import { TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Card } from 'src/@types';
import { titleCase } from '../../../../../../../lib/helpers';

export interface Props {
  card: Card;
  className?: string;
}

export const CardRow: React.FC<Props> = ({ className, card }) => {
  return (
    <Fragment>
      <TableRow className={className}>
        <TableCell className={className} data-testid="driver-cards-table-internalNumber-cell">
          {_.get(card, 'internalNumber', '')}
        </TableCell>
        <TableCell className={className} data-testid="driver-cards-table-externalNumber-cell">
          {_.get(card, 'externalNumber', '')}
        </TableCell>
        <TableCell className={className} data-testid="driver-cards-table-brand-cell">
          {_.get(card, 'brand', '')}
        </TableCell>
        <TableCell className={className} data-testid="driver-cards-table-name-cell">{`${_.get(
          card,
          'driver.firstName',
        )} ${_.get(card, 'driver.lastName')}`}</TableCell>
        <TableCell className={className} data-testid="driver-cards-table-cardStatus-cell">
          {titleCase(_.get(card, 'cardStatus', 'Unknown'))}
        </TableCell>
        <TableCell className={className} data-testid="driver-cards-table-updatedAt-cell">
          {_.get(card, 'updatedAt')
            ? moment(_.get(card, 'updatedAt')).format('MM/DD/YY h:mm:ss A')
            : moment(_.get(card, 'createdAt')).format('MM/DD/YY h:mm:ss A')}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
