import { Divider } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ChargerModelView } from '../../ChargerModelView';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  expanded?: boolean;
  onChange?: () => void;
  className?: string;
}

/**
 * Charger Network Charger Connector component
 */
export const ChargerConnectors: React.FC<Props> = (props) => {
  const className = 'ChargerConnectors';
  const { altId } = useParams<{ altId: string }>();

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledAccordion
      className={`${customClass} charger-connectors`}
      expanded={props.expanded}
      onChange={props.onChange}
      defaultExpanded={true}
    >
      <AccordionSummary className={className} expandIcon={altId ? <ExpandMore /> : null} component="header">
        <Typography className={className} variant="h6" component="h2">
          EVSEs
        </Typography>
      </AccordionSummary>

      <Divider className={className} />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <ChargerModelView connectorsOnly={true} />
          </div>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
