import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(Paper)`
  width: 100%;

  div {
    width: 100%;

    table {
      tbody {
        td {
          padding: ${`${spacing() / 2}px ${spacing(3)}px`};
        }
      }
    }
  }
`;
