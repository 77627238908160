import { useQuery } from '@apollo/client';
import { Button, Tab, useMediaQuery } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { listFalconConstraints } from '../../../../apollo/queries/options';
import { transformChargerResponse } from '../../../../lib/helpers';
import { Footer } from '../../../shared/Footer';
import { StyledTabs } from '../../../shared/StyledTabs';
import { ChargerActions } from './ChargerActions';
import { ChargerEditHeader } from './ChargerEditHeader';
import { ChargerMaintenanceTab } from './ChargerMaintenanceTab';
import { ChargerManageTab } from './ChargerManageTab';
import { ChargerNetworkHardwareTab } from './ChargerNetworkHardwareTab';
import { ChargerProfileTab } from './ChargerProfileTab';
import { ChargerStatus } from './ChargerStatus';
import { ChargerTransactionsTab } from './ChargerTransactionsTab';
import { initialValues } from './initialValues';
import { StartCharge } from './StartCharge';
import { StopCharge } from './StopCharge';
import { Styled as StyledForm } from './styles';
import { getCharger } from '../../../../apollo/queries/chargers';

export const ChargerForm: React.FC = () => {
  const id = _.kebabCase('ChargerForm');
  const className = id;
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery('(min-width:1400px)');
  const { altId, tab } = useParams<{ altId: string; tab: string }>();
  const { isSubmitting, isValid, resetForm, status, values } = useFormikContext<typeof initialValues>();
  const { data: chargerData, loading: chargerloading } = useQuery<Query>(getCharger, {
    fetchPolicy: 'network-only',
    skip: _.isEmpty(useParams()),
    variables: { chargerInput: { altId } },
  });
  const { data: chargerStatuses, loading: chargerStatusesLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'chargers',
          },
          columnName: {
            eq: 'charger_status',
          },
        },
        sort: {
          id: 'ASC',
          columnText: 'ASC',
        },
      },
    },
  });
  const [startCharge, setStartCharge] = useState(false);
  const [stopCharge, setStopCharge] = useState(false);

  // Handles populating the form with values from the server once everything is loaded
  useEffect(() => {
    if (!chargerloading && chargerData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chargerValues = transformChargerResponse(chargerData?.getCharger || {}) as any;
      const locationId = chargerValues?.site?.altId || chargerValues?.warehouse?.altId;

      resetForm({
        status: { ...chargerValues, locationId },
        values: { ...chargerValues, locationId },
      });
    }
  }, [chargerloading, resetForm, chargerData]);

  let activeTab: JSX.Element | null = <ChargerProfileTab />;
  if (tab === 'transactions') activeTab = <ChargerTransactionsTab />;
  if (tab === 'manage') activeTab = <ChargerManageTab />;
  if (tab === 'network-hardware') activeTab = <ChargerNetworkHardwareTab />;
  if (tab === 'issues') activeTab = <ChargerMaintenanceTab id={id} />;
  if (tab === 'logs') activeTab = null;

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newTab: string) => {
    navigate(`/chargers/${altId}/${newTab}`, { replace: true });
  };

  let chargerActionModal = startCharge && (
    <StartCharge open={startCharge} setStartCharge={setStartCharge} evses={values.evses} className={className} />
  );

  if (stopCharge) {
    chargerActionModal = (
      <StopCharge open={stopCharge} setStopCharge={setStopCharge} evses={values.evses} className={className} />
    );
  }

  let chargerStatusLabel = '';
  if (!_.isEmpty(_.get(chargerStatuses, 'listFalconConstraints.edges')) && !chargerStatusesLoading) {
    chargerStatusLabel = _.find(
      _.get(chargerStatuses, 'listFalconConstraints.edges'),
      (option) => Number(option.value) === _.get(values, 'chargerStatusId', ''),
    )
      ? _.find(
          _.get(chargerStatuses, 'listFalconConstraints.edges'),
          (option) => Number(option.value) === _.get(values, 'chargerStatusId', ''),
        ).label
      : '';
  }
  let chargerStatus = null;
  if (['profile', 'network-hardware', 'issues'].includes(tab || '')) {
    chargerStatus = <ChargerStatus className={`${className} charger-status`} status={chargerStatusLabel} />;
  }

  const connectors =
    values.evses && values.evses.edges ? _.flatten(values.evses.edges.map((evse) => evse.connectors.edges)) : [];

  return (
    <StyledForm className={className} $isLargeScreen={isLargeScreen}>
      <article className={className}>
        <div className={`${className} header-section`}>
          <ChargerEditHeader connectors={connectors} loading={chargerloading} />
        </div>
        {altId && (
          <StyledTabs className={className} tab={tab || 'profile'} handleChangeTab={handleChangeTab}>
            <Tab id={`${id}-profile-tab`} className={className} label="Profile" value="profile" />
            <Tab id={`${id}-transactions-tab`} className={className} label="Transactions" value="transactions" />
            <Tab id={`${id}-manage-tab`} className={className} label="Manage" value="manage" />
            <Tab
              id={`${id}-network-hardware-tab`}
              className={className}
              label="Network And Hardware"
              value="network-hardware"
            />
            <Tab id={`${id}-issues-tab`} className={className} label="Maintenance And Issues" value="issues" />
            <Tab id={`${id}-logs-tab`} className={className} label="Activity Log" value="logs" />
          </StyledTabs>
        )}

        <ChargerActions
          className={className}
          startCharge={startCharge}
          setStartCharge={setStartCharge}
          stopCharge={stopCharge}
          setStopCharge={setStopCharge}
        />

        {chargerActionModal}
        {chargerStatus}
        {activeTab}

        {tab !== 'transactions' && (
          <Footer
            className={className}
            message={
              _.get(chargerData, 'evcSyncedAt')
                ? `Last Synced: ${moment(_.get(chargerData, 'evcSyncedAt')).format('MM/DD/YY, h:mm:ss A')}`
                : 'This charger has not been synced.'
            }
          >
            <Button
              className={className}
              color="primary"
              disabled={isSubmitting}
              id={`${id}-cancel-button`}
              onClick={() => {
                resetForm();
                navigate(-1);
              }}
              size="large"
            >
              Cancel
            </Button>

            <Button
              className={className}
              color="secondary"
              disabled={!isValid || isSubmitting || _.isEqual(status, values)}
              id={`${id}-save-button`}
              size="large"
              type="submit"
              variant="contained"
            >
              <RefreshIcon className={`${className}${isSubmitting ? '' : ' hidden'}`} />
              <span className={`${className}${isSubmitting ? ' hidden' : ''}`}>Save</span>
            </Button>
          </Footer>
        )}
      </article>
    </StyledForm>
  );
};
