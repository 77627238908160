import _ from 'lodash';

interface GetFullAddressParams {
  streetAddress?: string | null;
  unit?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

/**
 * A helper to format full addresses correctly
 */
export const getFullAddress = ({
  streetAddress,
  unit,
  city,
  state,
  postalCode,
  country,
}: GetFullAddressParams): string => {
  let address = '';

  if (streetAddress) {
    address += streetAddress.trim();
    if (unit) {
      address += `, ${unit.trim()}`;
    }
    if (city || state || postalCode || country) {
      address += ', ';
    }
  }

  if (city) {
    address += city.trim();
    if (state || postalCode || country) {
      address += ', ';
    }
  }

  if (state) {
    address += state.trim();
    if (postalCode || country) {
      address += ' ';
    }
  }

  if (postalCode) {
    address += postalCode.trim();
    if (country) {
      address += ', ';
    }
  }

  if (country) {
    address += country === 'USA' ? country.trim() : _.capitalize(_.trim(country));
  }

  return address;
};
