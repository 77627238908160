import { gql } from '@apollo/client';

export const listContracts = gql`
  query ListContracts($input: ListContractsInput) {
    listContracts(input: $input) {
      total
      page
      pageSize
      filter {
        accountId {
          iLike
        }
      }
      sort {
        statusId
      }
      search {
        accountId {
          iLike
        }
      }
      edges {
        altId
        account {
          altId
        }
        plan {
          altId
        }
        startDate
        endDate
        closureDate
        status {
          columnText
        }
      }
    }
  }
`;
