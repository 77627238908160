import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChargerModel, Connector, Maybe, Query } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { getChargerModel, getModelOptions } from '../../../../../apollo/queries/models';
import { listFalconConstraints } from '../../../../../apollo/queries/options';
import { initialValues } from '../initialValues';
import { ConnectorRow } from './ConnectorRow';
import { Styled } from './styles';

export interface Props {
  connectorsOnly?: boolean;
  powerOnly?: boolean;
  interfaceOnly?: boolean;
  className?: string;
}

/** Charger Model View component */
export const ChargerModelView: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerModelView');
  const className = id;
  const { errors, handleBlur, handleChange, setValues, touched, values } = useFormikContext<typeof initialValues>();
  const { altId } = useParams<{ altId: string }>();

  const priorProtocolColumns = [
    { key: 'protocol', label: 'Protocol', width: '33%', align: 'left' },
    { key: 'firmwareVersion', label: 'Firmware Version', width: '33%', align: 'left' },
    { key: 'firmwareUpgradeDate', label: 'Firmware Upgrade Date', width: '33%', align: 'left' },
  ];

  let chargerModel: Maybe<ChargerModel> | undefined = undefined;
  const { data, loading } = useQuery<Query>(getChargerModel, {
    fetchPolicy: 'network-only',
    variables: {
      chargerModelInput: {
        altId: _.get(values, 'chargerModel.altId'),
      },
    },
    skip: !_.get(values, 'chargerModel.altId'),
  });

  if (!loading && data) {
    chargerModel = data.getChargerModel;
  }

  const modelEvses = chargerModel?.evses?.edges;
  const connectors = [];

  modelEvses?.forEach((evse) => {
    if (evse?.connectors?.edges) {
      evse.connectors?.edges?.forEach((connector) => {
        connectors.push(_.omit(connector, ['__typename', 'connectorType.__typename', 'altId']));
      });
    }
  });

  useEffect(() => {
    const evses = modelEvses?.map((evse) => {
      let updatedConnectors: Partial<Connector>[] = [];
      if (evse?.connectors?.edges) {
        updatedConnectors = evse.connectors?.edges.map((connector) => {
          return _.omit(connector, ['__typename', 'connectorType.__typename', 'altId']) as Partial<Connector>;
        });
      }
      return { connectors: { edges: updatedConnectors } };
    });

    if (
      !loading &&
      !_.isEmpty(data) &&
      !altId &&
      (!_.isEqual(_.get(values, 'evses.edges'), evses) || !_.get(values, 'evses.edges'))
    ) {
      const updatedEvses = {
        edges: evses,
      };

      if (_.isEqual(values.evses, updatedEvses)) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValues({ ...values, evses: updatedEvses as any });
    }
  }, [values, loading, modelEvses, chargerModel, data, setValues, altId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: modelOptions } = useQuery<Query>(getModelOptions) as any;
  const inputAcVoltageOptions = modelOptions?.inputAcVoltage?.enumValues || [];
  const networkCommunicationOptions = modelOptions?.networkCommunication?.enumValues || [];
  const interfaceVersionOptions = modelOptions?.interfaceVersion?.enumValues || [];
  const cableManufacturers = modelOptions?.cableManufacturers?.enumValues || [];
  const cableLength = modelOptions?.cableLength?.enumValues || [];
  const cableCooling = modelOptions?.cableCooling?.enumValues || [];

  let inputAcVoltageValue = '';
  if (!loading && _.get(chargerModel, 'inputAcVoltage', false)) {
    inputAcVoltageValue = inputAcVoltageOptions.find(
      ({ value }: { value: string }) => value === _.get(chargerModel, 'inputAcVoltage'),
    )
      ? inputAcVoltageOptions.find(({ value }: { value: string }) => value === _.get(chargerModel, 'inputAcVoltage'))
          .label
      : '';
  }
  let networkCommunicationValue = '';
  if (!loading && chargerModel?.networkCommunication) {
    networkCommunicationValue = networkCommunicationOptions.find(
      ({ value }: { value: string }) => value === _.get(chargerModel, 'networkCommunication'),
    )?.label;
  }
  let interfaceVersionValue = '';
  if (!loading && chargerModel?.interfaceVersion) {
    interfaceVersionValue = interfaceVersionOptions.find(
      ({ value }: { value: string }) => value === _.get(chargerModel, 'interfaceVersion'),
    ).label;
  }

  const { data: networkConnectionTypes, loading: networkConnectorOptionsLoading } = useQuery<Query>(
    listFalconConstraints,
    {
      variables: {
        optionsInput: {
          filter: {
            tableName: {
              eq: 'chargers',
            },
            columnName: {
              eq: 'network_connection_type_id',
            },
          },
          sort: {
            id: 'ASC',
            columnText: 'ASC',
          },
        },
      },
    },
  );

  let shapedNetworkConnectionTypeOptions: LabelValue<number>[] = [];
  if (!networkConnectorOptionsLoading) {
    shapedNetworkConnectionTypeOptions =
      networkConnectionTypes?.listFalconConstraints?.edges?.map((c) => ({
        label: c?.columnName as string,
        value: Number(c?.columnValue),
      })) || [];
  }

  const { data: wirelessConnectionCarriers, loading: wirelessConnectionCarrierOptionsLoading } = useQuery<Query>(
    listFalconConstraints,
    {
      variables: {
        optionsInput: {
          filter: {
            tableName: {
              eq: 'chargers',
            },
            columnName: {
              eq: 'wireless_connection_carrier_id',
            },
          },
          sort: {
            id: 'ASC',
            columnText: 'ASC',
          },
        },
      },
    },
  );

  let shapedWirelessConnectionCarrierOptions: LabelValue<number>[] = [];
  if (!wirelessConnectionCarrierOptionsLoading && wirelessConnectionCarriers?.listFalconConstraints?.edges) {
    shapedWirelessConnectionCarrierOptions = wirelessConnectionCarriers?.listFalconConstraints?.edges.map((c) => ({
      label: c?.columnName as string,
      value: Number(c?.columnValue),
    }));
  }

  const tempPriorProtocolsData = [
    {
      protocol: 'TEMPORARY1.6-J',
      firmwareVersion: '0.1.2.3.4',
      firmwareUpgradeDate: '05/18/20',
    },
    {
      protocol: 'TEMPORARY2.9-k',
      firmwareVersion: '0.9.2.3.4',
      firmwareUpgradeDate: '05/17/20',
    },
    {
      protocol: 'TEMPORARY3.P-J',
      firmwareVersion: '0.1.7.3.4',
      firmwareUpgradeDate: '05/16/20',
    },
    {
      protocol: 'TEMPORARY4.6-J',
      firmwareVersion: '0.1.2.5.4',
      firmwareUpgradeDate: '05/15/20',
    },
    {
      protocol: 'TEMPORARY1.6-J',
      firmwareVersion: '0.4.2.3.4',
      firmwareUpgradeDate: '05/14/20',
    },
    {
      protocol: 'TEMPORARY93.6-J',
      firmwareVersion: '5.1.42.3.4',
      firmwareUpgradeDate: '05/13/20',
    },
  ];

  const lastReceivedCommSignal = _.get(values, 'lastReceivedCommSignal')
    ? moment(_.get(values, 'lastReceivedCommSignal', '')).format('MM/DD/YY h:mm:ss A')
    : '';

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <Styled className={customClass}>
      <div className={`${className} row`}>
        {!props.connectorsOnly && (
          <Fragment>
            <Divider className={className} />
            <Typography className={className} variant="subtitle1" component="h3">
              Power
            </Typography>
            <div className={`${className} power`}>
              <div className={`${className} sub-power`}>
                <TextInfo
                  id={`${id}-power-output`}
                  className={`${className} column`}
                  disabled={true}
                  adornmentEnd="kW"
                  label="Power output"
                  value={_.get(chargerModel, 'powerOutput', '')}
                />
                <TextInfo
                  id={`${id}-breaker-rating`}
                  className={`${className} column`}
                  disabled={true}
                  adornmentEnd="A"
                  label="Breaker rating"
                  type="number"
                  value={_.get(chargerModel, 'breakerRating', '')}
                />
                <TextInfo
                  className={`${className} column`}
                  disabled={true}
                  id={`${id}-power-factor`}
                  label="Power Factor"
                  adornmentStart=">"
                  type="number"
                  value={_.get(chargerModel, 'powerFactor', '')}
                />
              </div>
              <div className={`${className} sub-power`}>
                <TextInfo
                  className={`${className} column third`}
                  disabled={true}
                  id={`${id}-input-ac-voltage`}
                  label="Input AC Voltage"
                  type="text"
                  value={inputAcVoltageValue}
                />
                <TextInfo
                  id={`${id}-efficiency`}
                  className={`${className} column third`}
                  disabled={true}
                  label="Efficiency"
                  adornmentEnd="%"
                  value={_.get(chargerModel, 'efficiency', '')}
                />
                <TextInfo
                  id={`${id}-current-thd`}
                  className={`${className} column third`}
                  disabled={true}
                  label="Current THD"
                  value={_.get(chargerModel, 'currentThd', '')}
                />
              </div>
            </div>
          </Fragment>
        )}
        {!networkConnectorOptionsLoading &&
          !props.powerOnly &&
          modelEvses?.map((evse, index) => {
            return (
              <div key={index}>
                <Typography className={className} variant="subtitle1" component="h3">
                  EVSE {index + 1}
                </Typography>
                {evse?.connectors?.edges?.map((connector, connectorIndex) => {
                  return (
                    <div key={index + connectorIndex}>
                      <Typography className={className} variant="subtitle1" component="h3">
                        Connector {connectorIndex + 1}
                      </Typography>
                      <ConnectorRow
                        className={className}
                        connector={connector as Connector}
                        cableManufacturers={cableManufacturers}
                        cableLength={cableLength}
                        cableCooling={cableCooling}
                        index={connectorIndex}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}

        <Divider className={className} />

        {props.interfaceOnly && (
          <Fragment>
            <div className={`${className} interface`}>
              <div className={className}>
                <Typography className={className} variant="subtitle1" component="h3">
                  Interface
                </Typography>
                <TextInfo
                  id={`${id}-display`}
                  className={`${className} column`}
                  disabled={true}
                  label="Display"
                  value={_.get(chargerModel, 'display', '')}
                />
                <TextInfo
                  id={`${id}-interface-version`}
                  className={`${className} column`}
                  disabled={true}
                  label="Interface Version"
                  value={interfaceVersionValue}
                />
              </div>
              <Divider vertical />
              <div className={`${className} mechanical`}>
                <Typography className={className} variant="subtitle1" component="h3">
                  Mechanical
                </Typography>
                <TextInfo
                  mask="99x99x99"
                  className={`${className} column form-control`}
                  id={`${id}-dimensions`}
                  label="Dimensions (W x H x D)"
                  adornmentEnd={_.get(chargerModel, 'dimensions', false) ? 'in' : null}
                  name="dimensions"
                  value={_.get(chargerModel, 'dimensions', '')}
                  disabled={true}
                />
                <TextInfo
                  id={`${id}-weight`}
                  className={`${className} column`}
                  adornmentEnd={_.get(chargerModel, 'weight', false) ? 'Lbs' : null}
                  disabled={true}
                  label="Weight"
                  value={_.get(chargerModel, 'weight', '')}
                />
                <TextInfo
                  id={`${id}-ingress-protection`}
                  className={`${className} column`}
                  disabled={true}
                  label="Ingress Protection"
                  value={_.get(chargerModel, 'ingressProtection', '')}
                />
                <TextInfo
                  id={`${id}-model-sku`}
                  className={`${className} column`}
                  disabled={true}
                  label="Model SKU"
                  value={_.get(chargerModel, 'modelSku', '')}
                />
              </div>
            </div>

            <Divider className={className} />

            <div className={`${className} communications`}>
              <Typography className={className} variant="subtitle1" component="h3">
                Communications
              </Typography>
              <div className={`${className} communications-inner`}>
                <div className={`${className} communications-inner--panel`}>
                  <Dropdown
                    className={`${className}`}
                    label="Network Connection Type"
                    labelProps={{ className }}
                    menuProps={{ className }}
                    options={shapedNetworkConnectionTypeOptions}
                    value={values && values.networkConnectionTypeId ? Number(values.networkConnectionTypeId) : null}
                    id={`${id}-charger-network-connection-type`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValues({ ...values, networkConnectionTypeId: e.target.value })
                    }
                  />

                  <TextInfo
                    id={`${id}-connection-uri`}
                    className={`${className}`}
                    label="Connection URI"
                    name="connectionUri"
                    value={_.get(values, 'connectionUri', '')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(_.get(touched, 'connectionUri') && _.get(errors, 'connectionUri'))}
                    helpertext={_.get(touched, 'connectionUri') && _.get(errors, 'connectionUri')}
                  />

                  <TextInfo
                    id={`${id}-network-communication`}
                    className={`${className} column`}
                    disabled={true}
                    label="Network Communication"
                    value={networkCommunicationValue}
                  />

                  <Dropdown
                    className={`${className} half`}
                    label="Wireless Connection Carrier"
                    labelProps={{ className }}
                    menuProps={{ className }}
                    options={shapedWirelessConnectionCarrierOptions}
                    value={
                      values && values.wirelessConnectionCarrierId ? Number(values.wirelessConnectionCarrierId) : null
                    }
                    id={`${id}-charger-network-connection-type`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValues({ ...values, wirelessConnectionCarrierId: e.target.value })
                    }
                  />

                  <TextInfo
                    id={`${id}-mac-address`}
                    className={`${className} half`}
                    label="MAC Address"
                    name="macAddress"
                    value={_.get(values, 'macAddress', '')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(_.get(touched, 'macAddress') && _.get(errors, 'macAddress'))}
                    helpertext={_.get(touched, 'macAddress') && _.get(errors, 'macAddress')}
                  />

                  <TextInfo
                    id={`${id}-last-received-comm-signal`}
                    className={`${className} column`}
                    disabled={true}
                    label="Last Received Comm. Signal"
                    value={lastReceivedCommSignal}
                  />

                  <Typography className={`${className} label`} variant="subtitle1" component="h3">
                    Program card system supported
                  </Typography>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      id={`${id}-iso-iec-checkbox`}
                      className={className}
                      checked={!!chargerModel?.isoIec}
                      color="secondary"
                      disabled={true}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      ISO/IEC 14443 Type A/B
                    </Typography>
                  </div>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      id={`${id}-nfc-reader-checkbox`}
                      className={className}
                      checked={!!chargerModel?.nfcReader}
                      color="secondary"
                      disabled={true}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      NFC Reader
                    </Typography>
                  </div>

                  <Typography className={`${className} label`} variant="subtitle1" component="h3">
                    Miscellaneous
                  </Typography>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      id={`${id}-smart-energy-profile-support-switch`}
                      className={className}
                      checked={!!chargerModel?.smartEnergyProfileSupport}
                      disabled={true}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Smart energy profile supported
                    </Typography>
                  </div>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      id={`${id}-auto-charge-supported-switch`}
                      className={className}
                      checked={!!chargerModel?.autoChargeSupported}
                      disabled={true}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Autocharge supported
                    </Typography>
                  </div>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      id={`${id}-auto-charge-supported-switch`}
                      className={className}
                      checked={chargerModel?.simultaneousChargeSupported || false}
                      disabled={true}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Simultaneous Charging supported
                    </Typography>
                  </div>
                </div>
                <div className={`${className} communications-inner--panel`}>
                  <TextInfo
                    id={`${id}-communication-protocol`}
                    className={`${className} column`}
                    disabled={true}
                    label="Communication Protocol"
                    value={_.get(chargerModel, 'communicationProtocol', '')}
                  />
                  <TextInfo
                    id={`${id}-firmware-version`}
                    className={`${className} column`}
                    disabled={true}
                    label="Firmware Version"
                    value={_.get(chargerModel, 'firmwareVersion', '')}
                  />
                  <div className={`${className} table-container`}>
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Prior Protocols
                    </Typography>
                    <Table id={`${id}-prior-protocols`} className={customClass}>
                      <colgroup>
                        {priorProtocolColumns.map(({ key, width }) => (
                          <col key={key} style={{ width }} />
                        ))}
                      </colgroup>

                      <TableHead className={className}>
                        <TableRow className={className}>
                          {priorProtocolColumns.map((column) => (
                            <TableCell
                              key={column.key}
                              align={column.align as 'left'}
                              className={className}
                              component="th"
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className={`${className} prior-protocols`}>
                        {tempPriorProtocolsData.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell className={className}>{row.protocol}</TableCell>

                            <TableCell className={className}>{row.firmwareVersion}</TableCell>

                            <TableCell className={className}>{row.firmwareUpgradeDate}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            <Divider className={className} />
            <div className={`${className} model-notes`}>
              <TextInfo
                id={`${id}-additional-details`}
                className={className}
                disabled={true}
                label="Model Notes"
                multiline
                rows="4"
                value={_.get(chargerModel, 'additionalDetails', '')}
              />
            </div>
          </Fragment>
        )}
      </div>
    </Styled>
  );
};
