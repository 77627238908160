import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import React from 'react';
import { Query } from 'src/@types';
import { listProperties } from '../../../../apollo/queries/properties';
import { Styled } from './styles';

/**
 * Properties Header component
 */
export const PropertiesHeader: React.FC = () => {
  const { data } = useQuery<Query>(listProperties, {
    fetchPolicy: 'network-only',
    variables: {
      propertiesInput: {
        page: 0,
        pageSize: 10,
      },
    },
  });

  const total = data?.listProperties?.total || 0;
  const s = total === 1 ? 'y' : 'ies';
  const className = 'PropertiesHeader';

  return (
    <Styled className={className}>
      <section className={`${className} title`}>
        <Typography className={className} variant="h5" component="h1">
          Property Management
        </Typography>
        <Typography className={`${className} subtitle`} variant="caption">
          {total || 0} Propert{s}
        </Typography>
      </section>
    </Styled>
  );
};
