import {
  customPricingSchema,
  reservationTermSchema,
  pricingSchema,
} from 'src/components/shared/CustomPricingInput/validation';
import Yup from 'src/yup';
import { ValidationError } from 'yup';
import { FormValues, getOverlappingCustomPrices } from './utils';

const { array, object, string } = Yup;

export const validationSchema = object({
  defaultPricing: object().shape({
    pricing: pricingSchema,
    displayName: string().trim().nullable().max(10, 'Display name must be 10 characters or less'),
    displayDescription1: string().trim().nullable().max(10, 'Display description 1 must be 10 characters or less'),
    displayDescription2: string().trim().nullable().max(10, 'Display description 2 must be 10 characters or less'),
  }),
  reservationTerm: reservationTermSchema,
  customPricing: array()
    .of(customPricingSchema)
    .test('customPricing', 'Time frames overlapping between prices.', function (values: FormValues['customPricing']) {
      if (!values) return true;

      const { path } = this;
      const errors: ValidationError[] = [];

      const overlaps = getOverlappingCustomPrices(values);

      overlaps.forEach((value, key) => {
        const [pricingIndex, scheduleIndex] = key.split('-');

        value.forEach((overlap) => {
          const [overlapPricingIndex, overlapScheduleIndex] = overlap.split('-');
          errors.push(
            this.createError({
              path: `${path}[${pricingIndex}].tariffSchedules[${scheduleIndex}].timeFrame`,
              message: 'This time frame overlaps with another. You can define only one unique price per timeframe.',
            }),
            this.createError({
              path: `${path}[${overlapPricingIndex}].tariffSchedules[${overlapScheduleIndex}].timeFrame`,
              message: 'This time frame overlaps with another. You can define only one unique price per timeframe.',
            }),
          );
        });
      });

      if (errors.length) {
        // @ts-ignore
        throw new Yup.ValidationError(errors, values, path);
      }

      return true;
    }),
});
