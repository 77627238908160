import Yup from '../../../../yup';
import { convertTimeToNumber, isOverlapping } from '../../ExtendPlusView/ChargerPrices/Pricing/utils';
import { tariffSchedulesSchema as customHoursSchema } from 'src/components/shared/CustomPricingInput/validation';
import { Day } from 'src/@types';
import { CustomHours } from 'src/components/shared/CustomPricingInput/CustomHoursInput';

interface MappedTimeFrame {
  startTime: number;
  endTime: number;
  index?: number;
}

const zipCodeValidation = (value: string) => /^[\d-]+$/.test(value);
const isValidDemandLimit = (val: number | null): boolean => {
  if (val === null || val === undefined) {
    return true;
  }
  return /^\d+(\.\d{0,2})?$/.test(val.toString()) && val > 0;
};

export const validations = Yup.object().shape({
  siteAccessId: Yup.number().required('Required').typeError('Required'),
  accessNotes: Yup.string().trim().nullable(),
  address1: Yup.string().trim().required('Required'),
  address2: Yup.string().trim().nullable(),
  administrativeArea: Yup.string().trim().required('Required'),
  description: Yup.string().trim().nullable(),
  directions: Yup.string().trim().nullable(),
  displayName: Yup.string().trim().required('Required'),
  hoursOfOperation: Yup.object().shape({
    custom: Yup.boolean(),
    to: Yup.string().required('To time is required and must be after From time'),
    from: Yup.string()
      .when('to', (val, schema) =>
        val
          ? schema.test(
              'validTimeRange',
              'From time is required and must be before To time',
              (value = '') => val >= value,
            )
          : schema,
      )
      .required('From time is required and must be before To time'),
  }),
  latitude: Yup.number()
    .min(-90)
    .max(90)
    .required('Required')
    .typeError('Must be a number within the range of -90 and 90'),
  longitude: Yup.number()
    .min(-180)
    .max(180)
    .required('Required')
    .typeError('Must be a number within the range of -180 and 180'),
  locality: Yup.string().trim().nullable().required('Required'),
  locationMarket: Yup.string().trim().nullable(),
  locationNotes: Yup.string().trim().nullable(),
  maxCurrent: Yup.string().trim().nullable(),
  meterNumber: Yup.string().nullable(),
  demandLimitManagementEnabled: Yup.boolean(),
  retailDemandLimit: Yup.number().when('demandLimitManagementEnabled', {
    is: true,
    then: Yup.number()
      .required('Required when demand limit management is enabled')
      .test('is-valid-demand-limit', 'Must be a number with maximum two digits after decimal', isValidDemandLimit)
      .typeError('Must be a number with maximum two digits after decimal'),
    otherwise: Yup.number()
      .positive()
      .test(
        'is-valid-demand-limit',
        'Must be a number with maximum two digits after decimal',
        (value) => value === null || isValidDemandLimit(value),
      )
      .typeError('Must be a number with maximum two digits after decimal')
      .nullable(),
  }),
  postalCode: Yup.string()
    .trim()
    .test('Zip Code validation', 'Zip code should have digits or dash (-) only ', zipCodeValidation)
    .required('Required'),
  property: Yup.object().shape({
    host: Yup.object().shape({
      altId: Yup.string().trim().required('Required'),
    }),
    propertyName: Yup.string().trim().nullable(),
    pid: Yup.string().trim().typeError('Required').required('Required'),
  }),
  ratedVoltage: Yup.string().trim().nullable(),
  sid: Yup.string().trim().typeError('Required').required('Required'),
  siteName: Yup.string().trim().required('Required'),
  siteStatusId: Yup.number().required('Required'),
  siteImages: Yup.array(Yup.object()).nullable(),
  locationTypeId: Yup.number().required('Required').typeError('Required'),
  utilityCompany: Yup.string().trim().nullable(),
  customHours: Yup.array()
    .when('hoursOfOperation.custom', {
      is: true,
      then: customHoursSchema,
    })
    .test('customHours', 'Time frames overlapping between schedules.', function (values: CustomHours[]) {
      if (!values) return true;
      const { path } = this;
      const errors: Yup.ValidationError[] = [];
      interface DayMap {
        [key: string]: MappedTimeFrame[];
      }

      const map: DayMap = {
        [Day.Monday]: [] as MappedTimeFrame[],
        [Day.Tuesday]: [] as MappedTimeFrame[],
        [Day.Wednesday]: [] as MappedTimeFrame[],
        [Day.Thursday]: [] as MappedTimeFrame[],
        [Day.Friday]: [] as MappedTimeFrame[],
        [Day.Saturday]: [] as MappedTimeFrame[],
        [Day.Sunday]: [] as MappedTimeFrame[],
      };

      values.forEach((value: CustomHours, customHoursIdx: number) => {
        const startTime = value.startTime && convertTimeToNumber(value.startTime);
        const endTime = value.endTime && convertTimeToNumber(value.endTime);
        if (!startTime || !endTime) return true;

        (value.daysOfWeek || []).forEach((day: string) => {
          map[day].forEach((existingSchedule: MappedTimeFrame) => {
            if (isOverlapping(existingSchedule, { startTime, endTime })) {
              errors.push(
                this.createError({
                  path: `${path}[${customHoursIdx}].timeFrame`,
                  message: 'This time frame overlaps with another.',
                }),
                this.createError({
                  path: `${path}[${existingSchedule.index}].timeFrame`,
                  message: 'This time frame overlaps with another.',
                }),
              );
            }
          });
          map[day].push({ startTime, endTime, index: customHoursIdx });
        });
      });

      if (errors.length) {
        // @ts-ignore
        throw new Yup.ValidationError(errors, values, path);
      }

      return true;
    }),
});
