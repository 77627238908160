import { theme } from '@evgo/react-material-components';
import { CircularProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import { Maybe, GetSitesForExtendQuery, Site, useGetSitesForExtendQuery } from 'src/@types';
import { Card, CardHeader } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import { Title } from 'src/components/shared/Title';
import { useMappedFalconConstraints } from 'src/lib/hooks/useMappedFalconConstraints';
import useAnalytics from 'src/lib/hooks/useAnalytics';

import { Filters, runFilters } from './filterHelpers';
import { SitesFilter } from './SitesFilter';
import { getSiteFullAddress } from './utils';

function extractSitesFromExtendUser(
  data: GetSitesForExtendQuery | undefined,
  siteStatuses: Map<string | number, string>,
) {
  return (data?.getSitesForExtend || []).map(
    (site) =>
      ({
        ...site,
        siteStatus: site?.siteStatusId ? siteStatuses.get(site.siteStatusId) : '',
      } as Maybe<Site>),
  );
}

function SiteName(props: { data: Site }) {
  const { track } = useAnalytics();
  const { altId, siteName } = props.data;
  return (
    <TableLink to={`/extend-plus/sites/${altId}`} onClick={() => track('Clicked Site Name Link')}>
      {siteName}
    </TableLink>
  );
}
function TotalChargers(props: { data: Site }) {
  const { chargers } = props.data;
  return <p>{chargers ? chargers?.edges?.length : 0}</p>;
}
export const SitesListView: React.FC = () => {
  const { constraints: siteStatuses } = useMappedFalconConstraints({
    tableName: { eq: 'sites' },
    columnName: { eq: 'status' },
    map: (row) => row.columnText as string,
  });

  const [filters, setFilters] = useState<Filters>({
    search: '',
    status: [],
    states: [],
    zips: [],
  });

  const defaultSort = {
    sortBy: 'siteName',
    sortDirection: Direction.Asc,
  };

  const columns: Column[] = [
    { key: 'siteName', label: 'Site Name', sortable: true, width: '20%', component: SiteName },
    {
      key: 'siteStatus',
      label: 'Status',
      sortable: true,
      width: '20%',
    },
    {
      key: 'chargers',
      label: '# Of Chargers',
      sortable: true,
      numeric: true,
      width: '20%',
      component: TotalChargers,
    },
    {
      key: 'evseCount',
      label: 'Charger Stalls',
      sortable: true,
      numeric: true,
      width: '20%',
      tooltip:
        'Stalls are equivalent to the number of vehicles that can charge at the same time. This is also referred to as EVSE.',
    },
    {
      key: 'address',
      label: 'Address',
      formatWithRowData: getSiteFullAddress,
      sortName: 'address1',
      sortable: false,
      width: '20%',
    },
  ];

  const { data, loading, error } = useGetSitesForExtendQuery();

  const sites = extractSitesFromExtendUser(data, siteStatuses);

  const edges = runFilters(filters, sites);

  return (
    <PageContent pageHeader={<PageHeader childrenLeft={<Title>Sites</Title>} />}>
      <Card sx={{ pb: 3 }} mb={4}>
        <Typography paragraph>
          Total # of sites: {loading ? <CircularProgress size={theme.spacing(2)} /> : sites.length}
        </Typography>

        <SitesFilter
          filters={filters}
          onChange={setFilters}
          siteStatuses={siteStatuses as Map<number, string>}
          sites={sites}
        />
      </Card>

      <Card>
        <CardHeader title="Site List" />
        <Table
          id="charger-selection-list"
          data-testid="charger-selection-list"
          stickyHeader
          data={edges}
          columns={columns}
          loading={loading}
          sorting={defaultSort}
          error={error}
          noDataMessage="You currently have no sites"
        />
      </Card>
    </PageContent>
  );
};
