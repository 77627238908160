/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';

const { palette, shape, spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};
  padding-bottom: ${spacing(9)}px;

  > section:not(:first-child) {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};

    header {
      padding: ${`${spacing(2)}px ${spacing(3)}px`};

      h2 {
        color: ${config.colors.accentBlue};
      }

      div {
        display: flex;

        &.container {
          flex: 1;
          flex-direction: row;

          .title {
            flex-direction: column;
          }

          .controls {
            flex: 1;
            justify-content: flex-end;
            height: ${spacing(5.75)}px;

            a {
              background-color: ${palette.grey[100]};
              width: ${spacing(6)}px;
              height: ${spacing(5.75)}px;
              min-width: 0;

              :first-of-type {
                border-top-left-radius: ${shape.borderRadius * 6.25}px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: ${shape.borderRadius * 6.25}px;
                margin-left: ${spacing(2.75)}px;
              }

              :last-of-type {
                border-top-left-radius: 0;
                border-top-right-radius: ${shape.borderRadius * 6.25}px;
                border-bottom-right-radius: ${shape.borderRadius * 6.25}px;
                border-bottom-left-radius: 0;
              }

              &.active {
                background-color: ${palette.primary[50]};
              }

              svg {
                color: #666666;

                &.active {
                  color: ${palette.secondary[700]};
                }
              }
            }
          }
        }
      }
    }
  }
`;
