/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { palette, spacing } = theme;
const { brandColor, colors } = config;

export const Styled = styled.article`
  min-width: 225px;
  min-height: 120px;
  margin: 5px;
  background-color: ${palette.common.white};

  div {
    padding: 5px;
  }

  > div {
    &.site-header {
      height: ${spacing(3)}px;
      display: flex;
      align-items: center;
      color: ${palette.common.white};
      background-color: ${brandColor};

      button {
        padding: 0;
        margin-right: ${spacing()}px;

        svg {
          color: ${palette.common.white};
        }
      }

      a {
        padding-right: ${spacing()}px;
      }
    }
  }

  ul {
    li {
      display: flex;
      align-content: flex-start;
      padding: 5px;

      a {
        color: ${colors.accentBlue};
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        margin-left: auto;
        margin-right: 0;

        &.available {
          color: #800000;
        }
      }
    }
  }
`;
