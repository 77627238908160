import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { Button, Checkbox, Paper, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { FieldArray, Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LabelValue } from 'src/@types/shared';
import { getChargerModel, getModelOptions } from '../../../../../apollo/queries/models';
import { transformChargerModelResponse } from '../../../../../lib/helpers';
import { useBlurDropdown } from '../../../../../lib/hooks';
import { Footer } from '../../../../shared/Footer';
import { DataContext } from '../../../../shared/DataContext';
import { useDataContext } from '../../../../shared/DataContext/useDataContext';
import { ConnectorRow } from './ConnectorRow';
import { DetailPageHeader } from './DetailPageHeader';
import { initialValues, LocalEvses } from './initialValues';
import { Styled } from './styles';

export interface Props {
  shapedConnectorTypeOptions: LabelValue<number>[];
  connectorTypeOptionsLoading: boolean;
}

/** Charger Model Form component */
export const ChargerModelForm: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerModelForm');
  const className = id;
  const location = useLocation();
  const [dataRef, setData] = useDataContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chargerModelId = location.state ? (location.state as any)?.chargerModelId : '';
  let { altId } = useParams<{ altId: string }>();
  const navigate = useNavigate();

  if (chargerModelId) altId = chargerModelId;

  const { values, touched, errors, status, handleBlur, handleChange, isValid, validateForm, resetForm, setValues } =
    useFormikContext<typeof initialValues>();
  const { shapedConnectorTypeOptions, connectorTypeOptionsLoading } = props;
  const handleBlurDropdown = useBlurDropdown();
  const { data: connectorOptions, loading } = useQuery(getModelOptions);
  const { manufacturers, unitTypes, inputAcVoltage, interfaceVersion, networkCommunication } = connectorOptions || {};

  useQuery(getChargerModel, {
    fetchPolicy: 'network-only',
    variables: { chargerModelInput: { altId } },
    skip: !altId,
    onCompleted: ({ getChargerModel: { __typename: modelType, ...model } }) => {
      const newModel = { ...model, altId: undefined };
      const transformedModel = transformChargerModelResponse(newModel, shapedConnectorTypeOptions);

      if (chargerModelId) {
        transformedModel.modelName = `Copy of ${newModel.modelName}`;
        if (transformedModel.evses) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const evses = transformedModel.evses.map(({ altId: evseId, ...rest }: { altId: string }) => rest) as any;
          const { connectors } = transformedModel.evses[0];
          const newConnectors = connectors.map(({ altId: connectorId, ...rest }: { altId: string }) => rest);
          evses[0].connectors = newConnectors;
          transformedModel.evses = evses;
        }
      }
      validateForm(transformedModel);
      resetForm({ values: { ...values, ...transformedModel }, status: { ...values, ...transformedModel } });
    },
  });

  const filteredManufacturers = _.filter(
    _.get(manufacturers, 'enumValues', []),
    (manufacturer) => !!manufacturer.label,
  );

  const {
    evses: [{ altId: evseId }],
  } = values;

  const handleAddEvse = useCallback(() => {
    const value = [...values.evses, { connectors: [{}] } as LocalEvses];

    setValues({ ...values, evses: value });
  }, [setValues, values]);
  const handleRemoveEvse = useCallback(
    (index) => {
      const value = [...values.evses];
      _.pullAt(value, [index]);
      setValues({ ...values, evses: value });
    },
    [setValues, values],
  );

  // TODO: editing
  return (
    <DataContext context="charger-model-form">
      <Styled editing={false} ref={dataRef}>
        <DetailPageHeader {...status} />
        <Form className={className}>
          <Paper className={className} component="section">
            <header className={className}>
              <Typography className={className} variant="h6" component="h2">
                Model Name
              </Typography>
            </header>

            <Divider />

            <div className={`${className} panel-details`}>
              <div className={className}>
                <div className={`${className} row`}>
                  <Dropdown
                    className={`${className} column`}
                    id={`${id}-manufacturer`}
                    error={touched.manufacturer && errors.manufacturer}
                    label="Manufacturer*"
                    options={filteredManufacturers}
                    name="manufacturer"
                    onChange={handleChange}
                    onBlur={handleBlurDropdown}
                    value={values.manufacturer}
                    {...setData('manufacturer')}
                  />
                  <Dropdown
                    className={`${className} column`}
                    id={`${id}-unitType`}
                    error={touched.unitType && errors.unitType}
                    label="Unit Type*"
                    options={_.get(unitTypes, 'enumValues', [])}
                    name="unitType"
                    onChange={handleChange}
                    onBlur={handleBlurDropdown}
                    value={values.unitType}
                    data-testid="getitgurl"
                    {...setData('unit-type')}
                  />
                  <TextInfo
                    id={`${id}-modelName`}
                    error={errors.modelName && touched.modelName}
                    helpertext={errors.modelName && touched.modelName && errors.modelName}
                    className={`${className} column`}
                    onBlur={handleBlur}
                    label="Model Name*"
                    name="modelName"
                    onChange={handleChange}
                    value={values.modelName}
                    {...setData('model-name')}
                  />
                  <TextInfo
                    id={`${id}-modelSku`}
                    error={errors.modelSku && touched.modelSku}
                    helpertext={errors.modelSku && touched.modelSku && errors.modelSku}
                    className={`${className} column`}
                    onBlur={handleBlur}
                    label="Model SKU*"
                    name="modelSku"
                    onChange={handleChange}
                    value={values.modelSku}
                    {...setData('model-sku')}
                  />
                </div>
              </div>
            </div>
          </Paper>

          <Paper className={className} component="section">
            <header className={className}>
              <Typography className={className} variant="h6" component="h2">
                Power
              </Typography>
            </header>

            <Divider />

            <div className={`${className} panel-details`}>
              <div className={className}>
                <div className={`${className} row power`}>
                  <div className={`${className} sub-power`}>
                    <TextInfo
                      id={`${id}-powerOutput`}
                      className={`${className} column`}
                      adornmentEnd={values.powerOutput ? 'kW' : null}
                      label="Power Output *"
                      name="powerOutput"
                      onChange={handleChange}
                      value={values.powerOutput}
                      error={errors.powerOutput && touched.powerOutput}
                      helpertext={errors.powerOutput && touched.powerOutput && errors.powerOutput}
                      onBlur={handleBlur}
                      {...setData('power-output')}
                    />
                    <TextInfo
                      id={`${id}-breakerRating`}
                      className={`${className} column`}
                      adornmentEnd={values.breakerRating ? 'A' : null}
                      label="Breaker Rating"
                      name="breakerRating"
                      onChange={handleChange}
                      value={values.breakerRating}
                      error={errors.breakerRating && touched.breakerRating}
                      helpertext={errors.breakerRating && touched.breakerRating && errors.breakerRating}
                      onBlur={handleBlur}
                      {...setData('breaker-rating')}
                    />
                    <TextInfo
                      id={`${id}-powerFactor`}
                      className={`${className} column`}
                      label="Power Factor"
                      adornmentStart={values.powerFactor ? '>' : null}
                      name="powerFactor"
                      onChange={handleChange}
                      value={values.powerFactor}
                      error={errors.powerFactor && touched.powerFactor}
                      helpertext={errors.powerFactor && touched.powerFactor && errors.powerFactor}
                      onBlur={handleBlur}
                      {...setData('power-factor')}
                    />
                  </div>
                  <div className={`${className} sub-power`}>
                    <Dropdown
                      className={`${className} column`}
                      error={touched.inputAcVoltage && errors.inputAcVoltage}
                      label="Input AC Voltage *"
                      options={_.get(inputAcVoltage, 'enumValues', [])}
                      name="inputAcVoltage"
                      onChange={handleChange}
                      value={values.inputAcVoltage}
                      id={`${id}-inputAcVoltage`}
                      {...setData('input-ac-voltage')}
                    />
                    <TextInfo
                      className={`${className} column`}
                      label="Efficiency"
                      adornmentEnd={values.efficiency ? '%' : null}
                      name="efficiency"
                      onChange={handleChange}
                      value={values.efficiency}
                      id={`${id}-efficiency`}
                      error={errors.efficiency && touched.efficiency}
                      helpertext={errors.efficiency && touched.efficiency && errors.efficiency}
                      onBlur={handleBlur}
                      {...setData('efficiency')}
                    />
                    <TextInfo
                      className={`${className} column`}
                      label="Current THD"
                      name="currentThd"
                      onChange={handleChange}
                      value={values.currentThd}
                      id={`${id}-currentThd`}
                      {...setData('current-thd')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Paper>

          {!loading && (
            <Paper className={`${className} connectors`} component="section">
              <header className={className}>
                <Typography variant="h6" component="h2" className={className}>
                  EVSEs
                </Typography>
                <Button
                  className={className}
                  size="small"
                  color="secondary"
                  variant="contained"
                  id={`${id}-add-evse-button`}
                  onClick={() => handleAddEvse()}
                  {...setData('add-evse-button')}
                >
                  Add EVSE
                </Button>
              </header>

              <Divider />
              {values.evses.map((evse, index) => {
                return (
                  <FieldArray
                    name={`evses[${index}].connectors`}
                    key={index}
                    render={(evseConnectorsArrayHelpers) => {
                      return (
                        <Paper className={`${className} connectors`} component="section">
                          <header className={className}>
                            <Typography variant="h6" component="h2" className={className}>
                              EVSE {index + 1}
                            </Typography>
                            <Button
                              className={className}
                              size="small"
                              color="secondary"
                              variant="contained"
                              id={`${id}-add-connector-button`}
                              onClick={() => {
                                evseConnectorsArrayHelpers.push({ evseId, outputVoltageRange: '' });
                              }}
                              {...setData('add-to-connector-button')}
                            >
                              Add connector
                            </Button>
                            {values.evses.length > 1 && (
                              <div className={className}>
                                <Button
                                  className={className}
                                  id={`${id}-remove-evse-${index}-button`}
                                  onClick={() => handleRemoveEvse(index)}
                                  {...setData(`remove-evse-${index}-button`)}
                                >
                                  <DeleteIcon className={className} color={'secondary'} />
                                  &nbsp;Delete Evse
                                </Button>
                              </div>
                            )}
                          </header>
                          {!connectorTypeOptionsLoading &&
                            evse.connectors.map((connector, connectorIndex) => {
                              return (
                                <ConnectorRow
                                  {...evseConnectorsArrayHelpers}
                                  key={connectorIndex}
                                  index={connectorIndex}
                                  evseIndex={index}
                                  options={connectorOptions}
                                  connectorTypeOptions={shapedConnectorTypeOptions}
                                  values={connector}
                                  touched={touched}
                                  id={id}
                                />
                              );
                            })}
                        </Paper>
                      );
                    }}
                  />
                );
              })}
            </Paper>
          )}

          <Paper className={className} component="section">
            <header className={className}>
              <Typography className={className} variant="h6" component="h2">
                Interface & Communications
              </Typography>
            </header>

            <Divider />

            <div className={`${className} panel-details`}>
              <div className={className}>
                <div className={`${className} row`}>
                  <TextInfo
                    className={`${className} column`}
                    label="Display size"
                    name="display"
                    onChange={handleChange}
                    value={values.display}
                    id={`${id}-display`}
                    {...setData('display')}
                  />
                  <TextInfo
                    className={`${className} column`}
                    label="Communication Protocol"
                    name="communicationProtocol"
                    onChange={handleChange}
                    value={values.communicationProtocol}
                    id={`${id}-communicationProtocol`}
                    {...setData('communication-protocol')}
                  />
                  <Dropdown
                    className={`${className} column`}
                    label="Network Communication"
                    options={_.get(networkCommunication, 'enumValues', [])}
                    name="networkCommunication"
                    onChange={handleChange}
                    value={values.networkCommunication}
                    id={`${id}-networkCommunication`}
                    {...setData('network-communication')}
                  />
                  <TextInfo
                    className={`${className} column`}
                    label="Firmware version"
                    name="firmwareVersion"
                    onChange={handleChange}
                    value={values.firmwareVersion}
                    id={`${id}-firmwareVersion`}
                    {...setData('firmware-version')}
                  />
                  <Dropdown
                    className={`${className} column`}
                    label="Interface version"
                    options={_.get(interfaceVersion, 'enumValues', [])}
                    name="interfaceVersion"
                    onChange={handleChange}
                    value={values.interfaceVersion}
                    id={`${id}-interfaceVersion`}
                    {...setData('interface-version')}
                  />

                  <Typography className={`${className} label`} variant="subtitle1" component="h3">
                    Program card system supported
                  </Typography>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      color="secondary"
                      name="isoIec"
                      id={`${id}-isoIec`}
                      className={className}
                      onChange={handleChange}
                      checked={values.isoIec}
                      {...setData('iso-iec')}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      ISO/IEC 14443 Type A/B
                    </Typography>
                  </div>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      color="secondary"
                      name="nfcReader"
                      id={`${id}-nfcReader`}
                      className={className}
                      onChange={handleChange}
                      checked={values.nfcReader}
                      {...setData('nfc-reader')}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      NFC Reader
                    </Typography>
                  </div>

                  <Typography className={`${className} label`} variant="subtitle1" component="h3">
                    Miscellaneous
                  </Typography>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      className={className}
                      color="secondary"
                      name="smartEnergyProfileSupport"
                      id={`${id}-smartEnergyProfileSupport`}
                      onChange={handleChange}
                      checked={values.smartEnergyProfileSupport}
                      {...setData('smart-energy-profile-support')}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Smart energy profile supported
                    </Typography>
                  </div>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      className={className}
                      color="secondary"
                      name="autoChargeSupported"
                      id={`${id}-autoChargeSupported`}
                      onChange={handleChange}
                      checked={values.autoChargeSupported}
                      {...setData('auto-charge-supported')}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Autocharge supported
                    </Typography>
                  </div>
                  <div className={`${className} checkbox-container`}>
                    <Checkbox
                      className={className}
                      color="secondary"
                      name="simultaneousChargeSupported"
                      id={`${id}-simultaneousChargeSupported`}
                      onChange={handleChange}
                      checked={values.simultaneousChargeSupported}
                      {...setData('simulatneous-charge-supported')}
                    />
                    <Typography className={`${className} label`} variant="subtitle1" component="h3">
                      Simultaneous Charging Supported
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Paper>

          <Paper className={className} component="section">
            <header className={className}>
              <Typography className={className} variant="h6" component="h2">
                Mechanical
              </Typography>
            </header>

            <Divider />

            <div className={`${className} panel-details`}>
              <div className={className}>
                <div className={`${className} row`}>
                  <TextInfo
                    mask="99x99x99"
                    className={`${className} column`}
                    label="Dimensions (W x H x D)"
                    adornmentEnd={values.dimensions ? 'in' : null}
                    name="dimensions"
                    id={`${id}-dimensions`}
                    onChange={handleChange}
                    value={values.dimensions}
                    error={errors.dimensions && touched.dimensions}
                    helpertext={errors.dimensions && touched.dimensions && errors.dimensions}
                    onBlur={handleBlur}
                    {...setData('dimensions')}
                  />
                  <TextInfo
                    className={`${className} column`}
                    label="Weight"
                    adornmentEnd={values.weight ? 'Lbs' : null}
                    name="weight"
                    id={`${id}-weight`}
                    onChange={handleChange}
                    value={values.weight}
                    error={errors.weight && touched.weight}
                    helpertext={errors.weight && touched.weight && errors.weight}
                    onBlur={handleBlur}
                    {...setData('weight')}
                  />
                  <TextInfo
                    className={`${className} column`}
                    label="Ingress protection"
                    name="ingressProtection"
                    id={`${id}-ingressProtection`}
                    onChange={handleChange}
                    value={values.ingressProtection}
                    {...setData('ingress-protection')}
                  />
                </div>
              </div>
            </div>
          </Paper>

          <Paper className={className} component="section">
            <header className={className}>
              <Typography className={className} variant="h6" component="h2">
                Additional details
              </Typography>
            </header>

            <Divider />

            <div className={`${className} panel-details`}>
              <div className={className}>
                <div className={`${className} row`}>
                  <TextInfo
                    label="Model Notes"
                    multiline
                    rows="4"
                    className={className}
                    name="additionalDetails"
                    id={`${id}-additionalDetails`}
                    onChange={handleChange}
                    value={values.additionalDetails}
                    {...setData('additional-details')}
                  />
                </div>
              </div>
            </div>

            <Divider key="divider" />
            <Footer message="This model has not been synced.">
              <Button
                id={`${id}-cancel`}
                color="primary"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
                size="large"
                {...setData('cancel-button')}
              >
                Cancel
              </Button>

              <Button
                id={`${id}-save`}
                color="secondary"
                type="submit"
                disabled={_.isEqual(values, status) || !isValid}
                size="large"
                variant="contained"
                {...setData('save-button')}
              >
                Save
              </Button>
            </Footer>
          </Paper>
        </Form>
      </Styled>
    </DataContext>
  );
};
