import { ApolloProvider } from '@apollo/client';
import { theme } from '@evgo/react-material-components';
import { ThemeProvider } from '@material-ui/core/styles';
import FlagProvider from '@unleash/proxy-client-react';
import 'core-js';
import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { apolloClient } from './apollo';
import { Container } from './components/layout/Container';
import { DataContext } from './components/shared/DataContext';
import config from './config';
import GlobalStyle from './theme/globalStyles';
import { init as storyblokInit } from './storyblok';

storyblokInit();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV || 'development',
});

render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <FlagProvider config={config.env.unleash}>
        <BrowserRouter basename={config.basename}>
          <ThemeProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
              <DataContext context="operator-portal" root>
                <Container />
              </DataContext>
            </DndProvider>
          </ThemeProvider>
        </BrowserRouter>
      </FlagProvider>
    </ApolloProvider>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root'),
);

if (module.hot) module.hot.accept();
