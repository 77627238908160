import { IconButton, List, ListItem } from '@material-ui/core';
import { NavigateBefore, Place } from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Site } from 'src/@types';
import { Styled } from './styles';

export interface Props {
  sites: Site[];
}

/**
 * Site cluster info component.
 */
export const SiteClusterInfo: React.FC<Props> = (props) => {
  const className = 'siteclusterinfo-component';
  const { sites } = props;
  const status = 'available';
  const [selectedSite, setSelectedSite] = useState<Site>();

  if (!sites) {
    return (
      <article className={className}>
        <div className={className}>This site could not be found.</div>
      </article>
    );
  }

  const sitesList = (
    <Fragment>
      <div className={`${className} site-header`}>
        <strong>{`${sites.length} Sites`}</strong>
      </div>

      <List className={className} dense={true}>
        {sites.map((site) => (
          <ListItem className={className} key={site.altId}>
            <a className={className} onClick={() => setSelectedSite(_.find(sites, { altId: site.altId }) as Site)}>
              <strong>{site.siteName}</strong>
            </a>
          </ListItem>
        ))}
      </List>
    </Fragment>
  );

  const chargers = selectedSite?.chargers?.edges;
  const chargersList = (
    <Fragment>
      <div className={`${className} site-header`}>
        <IconButton className={className} size="small" onClick={() => setSelectedSite({} as Site)}>
          <NavigateBefore className={className} />
        </IconButton>

        <Link className={className} to={`/sites/${selectedSite?.altId}`}>
          <strong>{selectedSite?.siteName}</strong>
        </Link>
      </div>

      <List className={className} dense={true}>
        {!chargers?.length && <ListItem className={className}>This site has no chargers.</ListItem>}

        {chargers?.map((charger) => (
          <ListItem key={charger?.altId} className={className}>
            <Link className={className} to={`/chargers/${charger?.altId}/profile`}>
              {charger?.chargerName}
            </Link>

            <Place color="primary" className={`${className} ${status}`} />
          </ListItem>
        ))}
      </List>
    </Fragment>
  );

  return <Styled className={className}>{_.isEmpty(selectedSite) ? sitesList : chargersList}</Styled>;
};
