import moment from 'moment-timezone';

export const dateRange = (timePeriod: number) => {
  if (timePeriod === 1) {
    return {
      startDate: moment().startOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
    };
  }
  // don't include today in any timeframes but 1 (today)
  return {
    startDate: moment().subtract(timePeriod, 'days').startOf('day').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
  };
};
