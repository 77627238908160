import React from 'react';
import { IconButton, Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader } from 'src/components/shared/Card';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';
import _ from 'lodash';

type Props = {
  title: string;
  subtitle?: string;
  route: string;
  track?: string;
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const EditSection: React.FC<Props> = ({ title, subtitle, route, children, track: trackEvent }) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  return (
    <Card data-testid={`${_.kebabCase(title)}-edit-section`}>
      <HeaderWrapper>
        <CardHeader title={title} subtitle={subtitle} />
        <IconButton
          data-testid="edit-link-btn"
          onClick={() => {
            navigate(route);
            if (trackEvent) track(trackEvent);
          }}
        >
          <Edit />
        </IconButton>
      </HeaderWrapper>
      <Box>{children}</Box>
    </Card>
  );
};
