import React from 'react';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { Box, Typography } from '@material-ui/core';

type Props = {
  title?: string;
  value?: string;
};

const StyledBox = styled(Box)`
  padding: ${theme.spacing(3)}px;

  p:first-child {
    font-weight: ${theme.typography.fontWeightBold};
  }

  span {
    color: ${theme.palette.text.disabled};
  }
`;

export const BoxItem: React.FC<Props> = ({ title, value }) => {
  return (
    <StyledBox>
      <Typography>{title}</Typography>
      <Typography>{value ? value : <span>N/A</span>}</Typography>
    </StyledBox>
  );
};
