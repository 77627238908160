import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;

export const Styled = styled(Accordion)`
  margin-top: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;

  &:first-child {
    margin-top: 0;
  }

  > header {
    padding: ${`0 ${spacing(3)}px`};

    h2 {
      color: ${config.colors.accentBlue};
    }
  }

  div.details {
    flex-wrap: wrap;
    padding: ${`${spacing(3)}px ${spacing(2)}px ${spacing() / 2}px`};
    .divider {
      display: flex;
      justify-content: flex-end;
      flex-basis: 100%;
      box-sizing: border-box;
      height: 1px;
      background: rgba(245, 248, 250);
      margin-bottom: ${spacing(2)}px;
    }
    .MuiInputBase-root {
      background: rgba(245, 248, 250) !important;
    }
    .MuiFormControl-root {
      margin-bottom: ${spacing(2)}px;
    }
    .MuiFormHelperText-root {
      margin-left: ${spacing(0)}px;
    }
    .checkbox-container {
      display: flex;
      flex-direction: row;
      margin-top: -10px;
      margin-right: ${spacing(3)}px;
      h3 {
        font-weight: 300;
        padding: ${`${spacing(3.5)}px 0`};
      }
      span {
        padding: ${`${spacing(1.5)}px ${spacing() / 2}px`};
      }
    }

    > div {
      flex-basis: 20%;
      box-sizing: border-box;
      padding: ${`0 ${spacing()}px`};

      label {
        transform: translate(calc(12px + ${spacing()}px), 20px) scale(1);

        &.prefilled,
        &[data-shrink='true'] {
          transform: translate(${spacing(2)}px, 10px) scale(0.75);
        }
      }

      &.half {
        flex-basis: 50%;
      }

      &.fourth {
        flex-basis: 25%;
      }

      &.fifth {
        flex-basis: 20%;
      }

      &.sixth {
        flex-basis: calc(100% / 6);
      }
    }

    > label {
      flex-basis: 40%;
      box-sizing: border-box;
      margin: ${`0 ${spacing(1.75)}px ${spacing(2.5)}px -${spacing(1.75)}px`};
      padding: ${`0 ${spacing()}px`};
    }
  }

  .customDropdown {
    max-height: 142px;
    overflow-y: auto;

    .MuiButton-root {
      text-transform: none;
    }
  }
`;
