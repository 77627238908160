import { gql } from '@apollo/client';

export const createdHost = gql`
  subscription createdHost {
    createdHost {
      altId
      evcId
    }
  }
`;
