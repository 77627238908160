import { Typography } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ViewNavigation } from '../../shared/ViewNavigation';
import { CardsTable } from './CardsTable';
import { ImportTable } from './ImportTable';
import { RequestsTable } from './RequestsTable';
import { Styled } from './styles';

export interface Props {
  className?: string;
}

/**
 * Authenticators view component
 */
export const AuthenticatorsView: React.FC<Props> = (props) => {
  const location = useLocation();

  const links = [
    {
      label: 'Cards',
      to: '/cards/all',
    },
    {
      label: 'Requests',
      to: '/cards/requested',
    },
    {
      label: 'Import',
      to: '/cards/import',
    },
  ];

  const pathname = location?.pathname || '';
  const className = 'authenticators-view';
  let listComponent = null;

  if (pathname === '/cards/all') {
    listComponent = <CardsTable title="Cards" className={className} />;
  }

  if (pathname === '/cards/requested') {
    listComponent = <div className={className} />;
    listComponent = <RequestsTable title="Requests" buttonAction="Mark Sent" className={className} />;
  }

  if (pathname === '/cards/import') {
    listComponent = <div className={className} />;
    listComponent = <ImportTable title="Import" buttonAction="Import" className={className} />;
  }

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <Styled className={customClass}>
      <header className={className}>
        <Typography className={className} variant="h5" component="h1">
          Card Management
        </Typography>
        {pathname === '/cards/all' && (
          <Typography className={`${className} subtitle`} variant="caption">
            All Cards
          </Typography>
        )}
      </header>
      <ViewNavigation links={links} />
      {listComponent}
    </Styled>
  );
};
