import styled from 'styled-components';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';

import { TableLink } from 'src/components/shared/TableLink';
import { Column, Table, usePagination } from 'src/components/shared/Table';
import { Card, CardHeader } from 'src/components/shared/Card';
import {
  useListSessionForDashboardLiveSessionsQuery,
  SessionsV2Sort,
  ListSessionForDashboardLiveSessionsQuery,
  useGetActiveSessionsTotalsQuery,
} from 'src/@types';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { Fragment } from 'react';

type LiveSessionProps = {
  siteIds?: string[];
};

const StyledCard = styled(Card)`
  margin-bottom: ${theme.spacing(4)}px;
  min-height: 495px;
`;

type Session = NonNullable<NonNullable<ListSessionForDashboardLiveSessionsQuery['listSessionsV2']>['edges']>[0];

function VendorIdAndSiteLink(props: { data: Session }) {
  const { track } = useAnalytics();
  const charger = props?.data?.connector?.evse?.charger;
  const site = charger?.site;
  const { siteName } = site || {};
  const { altId } = charger || {};
  return (
    <Fragment>
      <TableLink
        to={`/extend-plus/chargers/${altId}/transactions`}
        onClick={() => {
          track(events.siteDetails.CLICKED_CHARGERS_LIST_SESSION_ID);
        }}
      >
        {props?.data?.vendorId}
      </TableLink>
      <br></br>
      {siteName}
    </Fragment>
  );
}

function ActiveSessionsSummaryRow(props: { sessionCount: number; totalKwh: number }) {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2" color="textSecondary">
          {`Total #: ${props.sessionCount}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textSecondary">
          {props.totalKwh.toPrecision(4)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

const columns: Column<Session>[] = [
  {
    key: 'vendorIdAndSiteLink',
    label: 'Session ID',
    sortable: false,
    width: '70%',
    component: VendorIdAndSiteLink,
  },
  {
    key: 'powerValue',
    label: 'Total kWh',
    sortable: true,
    width: '30%',
    numeric: true,
    formatWithRowData: (data) =>
      data?.lastMeterValue ? ((data?.lastMeterValue - (data?.meterStart || 0)) / 1000).toFixed(4) : '-',
  },
];

export function LiveSessions({ siteIds }: LiveSessionProps) {
  const pagination = usePagination({ pageSize: 4 });
  const {
    data: listData,
    loading,
    error,
  } = useListSessionForDashboardLiveSessionsQuery({
    variables: {
      input: {
        filter: {
          endTime: { eq: null },
          siteId: { in: siteIds },
          corrupted: { ne: true },
        },
        sort: [SessionsV2Sort.IdDesc],
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    },
    skip: !siteIds?.length,
    pollInterval: 5000,
  });

  const { data: totalData } = useGetActiveSessionsTotalsQuery({
    variables: {
      input: { siteIds: siteIds || [] },
    },
    skip: !siteIds?.length,
    pollInterval: 5000,
  });

  const edges = listData?.listSessionsV2?.edges || [];
  const total = listData?.listSessionsV2?.total || 0;

  const totalKwh = totalData?.getActiveSessionsTotals?.totalKwh || 0;

  return (
    <StyledCard data-testid="live-sessions">
      <CardHeader
        subtitle={
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1" color="textSecondary">
              Active Sessions
            </Typography>
          </Box>
        }
      />
      <div style={{ overflowX: 'scroll' }}>
        <div style={{ minWidth: '225px' }}>
          <Table
            columns={columns}
            data={edges}
            loading={loading}
            error={error}
            id="live-sessions"
            data-testid="live-sessions-table"
            pagination={{ total, ...pagination, rowsPerPageOptions: [] }}
            summaryRow={ActiveSessionsSummaryRow({ sessionCount: total, totalKwh })}
          />
        </div>
      </div>
    </StyledCard>
  );
}
