import { gql } from '@apollo/client';

export const getSitesShallowForExtend = gql`
  query GetSitesShallowForExtend {
    getSitesForExtend {
      siteName
      postalCode
    }
  }
`;
