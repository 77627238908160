/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, QueryResult } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Host, Maybe, Query } from 'src/@types';
import { Column, Table } from 'src/components/shared/Table';
import { EditButton } from './EditButton';

interface Props {
  data?: Query['listExtendUsers'];
  loading: boolean;
  error?: ApolloError | undefined;
  fetchMore?: QueryResult<never, never>['fetchMore'];
}

interface CellProps {
  data: {
    hosts: {
      edges?: Maybe<Maybe<Host>>[];
    };
    username: string;
    firebaseId: string;
    createdAt: string;
    [key: string]: any;
  };
}

export const UserCell: React.FC<CellProps> = ({ data }) => {
  if (data?.hosts?.edges?.length) {
    return <div>{_.get(data, data.key)}</div>;
  }
  return <div style={{ color: 'lightgrey' }}>{_.get(data, data.key)}</div>;
};

export const ExtendUsersList: React.FC<Props> = (props) => {
  const columns: Column[] = [
    { key: 'username', label: 'Email', sortable: true, width: '20%', component: UserCell },
    { key: 'firebaseId', label: 'Firebase ID', sortable: true, width: '20%', component: UserCell },
    { key: 'hosts.edges[0].hostName', label: 'Assigned Host', sortable: true, width: '20%', component: UserCell },
    {
      key: 'createdAt',
      label: 'Create Date',
      sortable: true,
      width: '25%',
      component: UserCell,
      format: (timestamp: string | null) =>
        !timestamp ? '' : moment(timestamp).tz('UTC').utc().format('YYYY-MM-DD HH:MM'),
    },
    { key: 'actions', label: '', sortable: false, width: '15%', component: EditButton },
  ];

  const { data, loading, error, fetchMore } = props;
  const edges = data?.edges || [];

  const activeUsers = _.filter(edges, (e) => e?.hosts?.edges && e?.hosts?.edges.length);
  const sortedActiveUsers = _.orderBy(activeUsers, ['createdAt'], ['desc']);
  const inactiveUsers = _.filter(edges, (e) => e?.hosts?.edges && !e?.hosts?.edges.length);
  const sortedInactiveUsers = _.orderBy(inactiveUsers, ['createdAt'], ['desc']);

  return (
    <Table
      id="extend-users-table"
      data-testid="extend-users-table"
      columns={columns}
      data={[...sortedActiveUsers, ...sortedInactiveUsers]}
      loading={loading}
      error={error}
      fetchMore={fetchMore}
      noDataMessage="You currently have no assigned users"
    />
  );
};
