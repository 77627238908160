import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import { Box, Typography } from '@material-ui/core';
import { Tooltip } from '../../Tooltip';

type Props = {
  title?: string;
  value?: string;
  icon?: ReactElement;
  tip?: string;
};

const StyledFeaturedItemBox = styled(Box)`
  padding: ${theme.spacing(3)}px;
  position: relative;

  h4 {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 0.75rem;
    font-weight: ${theme.typography.fontWeightBold};
    letter-spacing: 0.16em;
  }

  span {
    color: ${theme.palette.text.disabled};
  }

  svg {
    margin-right: ${theme.spacing(1)}px;
    &.TableTooltipIcon {
      position: absolute;
      bottom: ${theme.spacing(3)}px;
      right: -${theme.spacing()}px;
    }
  }
`;

export const ConnectorFeaturedItem: React.FC<Props> = ({ icon, title, value, tip }) => {
  return (
    <StyledFeaturedItemBox>
      <Typography variant="h4">
        {Boolean(icon) && icon}
        {value ? value : <span>N/A</span>}
      </Typography>
      <Typography>{title}</Typography>
      {tip && <Tooltip content={tip} />}
    </StyledFeaturedItemBox>
  );
};
