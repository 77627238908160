import { gql } from '@apollo/client';

export const importProperty = gql`
  mutation importProperty($propertyInput: ImportPropertyInput) {
    importProperty(input: $propertyInput) {
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      status
      host {
        altId
      }
      altId
      pid
      postalCode
      propertyName
    }
  }
`;
