/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, TextInfo } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import _ from 'lodash';
import React from 'react';
import { DataContext } from '../../../../../shared/DataContext';
import { useDataContext } from '../../../../../shared/DataContext/useDataContext';
import { Styled } from './styles';

export interface Props {
  form: {
    handleBlur: any;
    errors: any;
    values: { evses: any };
  };
  id: string;
  remove: any;
  replace: any;
  index: number;
  evseIndex: any;
  options: any;
  connectorTypeOptions: any;
  values: any;
  touched: any;
}

export const ConnectorRow: React.FC<Props> = ({
  form: {
    handleBlur,
    errors,
    values: { evses },
  },
  id: parentId,
  remove,
  replace,
  index,
  evseIndex,
  options,
  connectorTypeOptions,
  values,
  touched,
}) => {
  const id = `${parentId}-${_.kebabCase('ConnectorRow')}`;
  const className = id;
  const [dataRef, setData] = useDataContext();
  const error = (errors && errors.evses && errors.evses[evseIndex] && errors.evses[evseIndex].connectors[index]) || {};
  const { connectors } = evses[evseIndex];
  const handleDelete = () => remove(index);
  const handleChange =
    (key: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      replace(index, { ...values, [key]: Number(value) || value });

  // TODO: editing
  return (
    <DataContext context={`connector-${index + 1}-row`}>
      <Styled className={`${className} panel-details`} editing={false} ref={dataRef}>
        <div className={className}>
          <div className={`${className} row`}>
            <div className={`${className} row-header`}>
              <Typography className={`${className} label`} variant="subtitle1" component="h3">
                Connector {index + 1}
              </Typography>
              {connectors.length > 1 ? (
                <IconButton
                  onClick={handleDelete}
                  id={`${id}-delete-connector-${index}-button`}
                  className={className}
                  {...setData(`delete-connector-${index}-button`)}
                >
                  <Cancel />
                </IconButton>
              ) : null}
            </div>
            <div className={`${className} connector-opts`}>
              <Dropdown
                className={`${className} column`}
                label="Connector type *"
                options={connectorTypeOptions}
                value={values.connectorType}
                id={`evse-${evseIndex}-${id}-${index}-connectorType`}
                onChange={handleChange('connectorType')}
                error={touched.evses && error.connectorType}
                helpertext={error.connectorType}
                {...setData('connector-type')}
              />
              <Dropdown
                className={`${className} column`}
                label="Cable Manufacturer"
                options={options && options.cableManufacturers.enumValues}
                value={values.cableManufacturer}
                id={`evse-${evseIndex}-${id}-${index}-cableManufacturer`}
                onChange={handleChange('cableManufacturer')}
                {...setData('cable-manufacturer')}
              />
              <Dropdown
                className={`${className} column`}
                label="Cable length"
                options={options && options.cableLength.enumValues}
                value={values.cableLength}
                id={`evse-${evseIndex}-${id}-${index}-cableLength`}
                onChange={handleChange('cableLength')}
                {...setData('cable-length')}
              />
              <TextInfo
                className={`${className} column`}
                label="Connector max. current *"
                adornmentEnd={values.connectorMaxCurrent ? 'A' : null}
                value={values.connectorMaxCurrent}
                id={`evse-${evseIndex}-${id}-${index}-connectorMaxCurrent`}
                onChange={handleChange('connectorMaxCurrent')}
                onBlur={handleBlur}
                error={touched[`evse-${evseIndex}-${id}-${index}-connectorMaxCurrent`] && error.connectorMaxCurrent}
                helpertext={
                  touched[`evse-${evseIndex}-${id}-${index}-connectorMaxCurrent`] && error.connectorMaxCurrent
                    ? error.connectorMaxCurrent
                    : ''
                }
                {...setData('connector-max-current')}
              />
              <TextInfo
                mask="999-999"
                className={`${className} column`}
                label="Output Voltage Range"
                adornmentEnd={values.outputVoltageRange ? 'V' : null}
                value={values.outputVoltageRange}
                id={`evse-${evseIndex}-${id}-${index}-outputVoltageRange`}
                onChange={handleChange('outputVoltageRange')}
                onBlur={handleBlur}
                error={!!error.outputVoltageRange}
                helpertext={error.outputVoltageRange}
                {...setData('output-voltage')}
              />
              <Dropdown
                className={`${className} column`}
                label="Cable cooling"
                options={options && options.cableCooling.enumValues}
                value={values.cableCooling}
                id={`evse-${evseIndex}-${id}-${index}-cableCooling`}
                onChange={handleChange('cableCooling')}
                {...setData('cable-cooling')}
              />
            </div>
          </div>
        </div>
      </Styled>
    </DataContext>
  );
};
