import { gql } from '@apollo/client';

export const assignCards = gql`
  mutation assignCards($input: AssignCardsInput!) {
    assignCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
