/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
  FilledInput,
  FormControl,
  InputLabel,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Account, NotificationPreferencesWithMeta, Query } from 'src/@types';
import { useSnackbar } from 'src/lib/hooks';
import { listGeographicalRegions } from '../../../../../apollo/queries/geographicalRegions';
import { listNotificationGroups } from '../../../../../apollo/queries/notifications';
import { geographicalRegionsInput, shapeGeographicalRegions } from '../../../../../lib/helpers';
import { ContractsList } from '../../../../shared/ContractsList';
import { DriverProfileNotificationGroup } from '../../../../shared/DriverProfileNotificationGroup';
import { Footer } from '../../../../shared/Footer';
import { Styled } from './styles';

export type Props = {
  loading: boolean;
  account: Account;
  className?: string;
};

type Address = {
  altId: string;
  address1: string;
  address2: string;
  locality: string;
  postalCode: string;
  administrativeArea: string;
  country: string;
};

export type FormValues = {
  altId: string;
  contracts: string;
  billingAddress: Address;
  mailingAddress: Address;
  email: string;
  enrolledOn: string;
  firstName: string;
  lastName: string;
  phone: string;
  isBillingAddressSameAsMailing: boolean;
  notificationPreferences?: NotificationPreferencesWithMeta;
  id?: string;
  driverAltId?: string;
};

const countryOptions = [
  { label: 'USA', value: 'USA' },
  { label: 'Canada', value: 'CAN' },
];

const notificationGroupsVariables = {
  notificationGroupsInput: {
    page: 0,
    pageSize: 999,
    sort: {
      sortOrder: 'ASC',
    },
  },
  notificationItemsInput: {
    page: 0,
    pageSize: 999,
    sort: {
      sortOrder: 'ASC',
    },
  },
};

export const AccountProfileForm: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountProfileForm');
  const className = id;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm,
    isSubmitting,
    values,
    setValues,
    setFieldValue,
    setTouched,
  } = useFormikContext<FormValues>();

  useEffect(() => {
    if (typeof values.isBillingAddressSameAsMailing === 'undefined' && !!values.mailingAddress) {
      setFieldValue(
        'isBillingAddressSameAsMailing',
        _.isEqual(_.omit(values.mailingAddress, 'altId'), _.omit(values.billingAddress, 'altId')),
      );
    }
  });

  const { loading, account } = props;
  const firebaseId = (account?.drivers?.edges || []).find((x) => x?.driverType === 'OWNER')?.firebaseId || '';
  const partnerSource = account?.partnerSource || '';

  const navigate = useNavigate();
  const { altId } = useParams();
  const snackbar = useSnackbar();

  const { data: geographicalRegions, loading: geoRegionsLoading } = useQuery<Query>(listGeographicalRegions, {
    variables: geographicalRegionsInput,
  });
  const { shapedStateOptions, shapedProvinceOptions } = shapeGeographicalRegions(geographicalRegions);

  const { data: { listNotificationGroups: notificationGroups } = {}, loading: notificationGroupsLoading } =
    useQuery<Query>(listNotificationGroups, {
      variables: notificationGroupsVariables,
    });

  if (values.isBillingAddressSameAsMailing) {
    _.omit(errors, 'billingAddress');
  }

  const handleCheckSameAddress = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked;

    if (checked && errors.mailingAddress) {
      setTouched({
        mailingAddress: { address1: true, locality: true, postalCode: true, administrativeArea: true, country: true },
      });

      snackbar.warning('You must enter a valid mailing address before selecting this option');
      return;
    }

    const { mailingAddress, billingAddress } = values;

    let newBillingAddress: Address = {
      altId: billingAddress && billingAddress.altId,
      address1: '',
      address2: '',
      locality: '',
      postalCode: '',
      administrativeArea: '',
      country: 'USA',
    };

    if (checked && mailingAddress) {
      newBillingAddress = { ...mailingAddress, altId: billingAddress?.altId };
    }

    setValues({ ...values, billingAddress: newBillingAddress });

    handleChange(e);
  };

  if (loading || geoRegionsLoading || notificationGroupsLoading) {
    return <CircularProgress />;
  }

  return (
    <Styled>
      <Form id={id}>
        <Accordion defaultExpanded>
          <AccordionSummary className={className} expandIcon={altId ? <ExpandMore /> : null} component="header">
            <Typography className={className} variant="h6" component="h2">
              Account Profile
            </Typography>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={`${className} panel-details`}>
            <div className={className}>
              <div className={className}>
                <Typography className={className} variant="subtitle1" component="h3">
                  General Information
                </Typography>
                <TextInfo
                  error={errors.firstName && touched.firstName}
                  helpertext={touched.firstName && errors.firstName}
                  className={clsx(className, 'half')}
                  id={`${id}-first-name`}
                  name="firstName"
                  label="First Name *"
                  labelProps={{ className }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  data-testid={`${id}-first-name`}
                />

                <TextInfo
                  error={errors.lastName && touched.lastName}
                  helpertext={touched.lastName && errors.lastName}
                  className={clsx(className, 'half')}
                  id={`${id}-last-name`}
                  name="lastName"
                  label="Last Name *"
                  labelProps={{ className }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  data-testid={`${id}-last-name`}
                />

                <TextInfo
                  error={errors.email && touched.email}
                  helpertext={touched.email && errors.email}
                  className={className}
                  id={`${id}-email`}
                  name="email"
                  label="E-mail Address *"
                  labelProps={{ className }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  data-testid={`${id}-email`}
                />

                <TextInfo
                  error={errors.phone && touched.phone}
                  helpertext={touched.phone && errors.phone}
                  className={className}
                  id={`${id}-phone`}
                  name="phone"
                  label="Phone Number *"
                  labelProps={{ className }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  data-testid={`${id}-phone`}
                />
              </div>

              <Divider vertical />

              <div className={className}>
                <Typography className={className} variant="subtitle1" component="h3">
                  Mailing Address
                </Typography>

                <TextInfo
                  error={errors.mailingAddress?.address1 && touched.mailingAddress?.address1}
                  helpertext={touched.mailingAddress?.address1 && errors.mailingAddress?.address1}
                  className={className}
                  id={`${id}-mailing-address-street`}
                  label="Address Line 1 *"
                  labelProps={{ className }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="mailingAddress.address1"
                  value={values.mailingAddress?.address1}
                  data-testid={`${id}-mailing-address-street`}
                />
                <TextInfo
                  className={className}
                  id={`${id}-mailing-address-2`}
                  name="mailingAddress.address2"
                  label="Address Line 2"
                  labelProps={{ className }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mailingAddress?.address2}
                  data-testid={`${id}-mailing-address-2`}
                />

                <TextInfo
                  error={errors.mailingAddress?.locality && touched.mailingAddress?.locality}
                  helpertext={touched.mailingAddress?.locality && errors.mailingAddress?.locality}
                  className={clsx(className, 'half')}
                  id={`${id}-mailing-address-city`}
                  label="City *"
                  labelProps={{ className }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="mailingAddress.locality"
                  value={values.mailingAddress?.locality}
                  data-testid={`${id}-mailing-address-city`}
                />

                <Dropdown
                  className={clsx(className, 'half')}
                  error={errors.mailingAddress?.administrativeArea && touched.mailingAddress?.administrativeArea}
                  helpertext={touched.mailingAddress?.administrativeArea && errors.mailingAddress?.administrativeArea}
                  id={`${id}-mailing-address-state`}
                  name="mailingAddress.administrativeArea"
                  label={values.mailingAddress?.country === 'USA' ? 'State *' : 'Province *'}
                  onChange={handleChange}
                  options={values.mailingAddress?.country === 'USA' ? shapedStateOptions : shapedProvinceOptions}
                  value={values.mailingAddress?.administrativeArea}
                  data-testid={`${id}-mailing-address-state`}
                />

                <TextInfo
                  error={errors.mailingAddress?.postalCode && touched.mailingAddress?.postalCode}
                  helpertext={touched.mailingAddress?.postalCode && errors.mailingAddress?.postalCode}
                  className={clsx(className, 'half')}
                  id={`${id}-mailing-address-postal-code`}
                  label={values.mailingAddress?.country === 'USA' ? 'ZIP Code *' : 'Postal Code *'}
                  labelProps={{ className }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="mailingAddress.postalCode"
                  value={values.mailingAddress?.postalCode}
                  data-testid={`${id}-mailing-address-postal-code`}
                />

                <Dropdown
                  className={clsx(className, 'half')}
                  error={touched.mailingAddress?.country && errors.mailingAddress?.country}
                  id={`${id}-mailing-address-country`}
                  name="mailingAddress.country"
                  label="Country *"
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setFieldValue('mailingAddress.administrativeArea', '');
                  }}
                  options={countryOptions}
                  value={values.mailingAddress?.country}
                  data-testid={`${id}-mailing-address-country`}
                />
              </div>
            </div>

            <div className={className}>
              <div className={className} />

              <Divider vertical />

              <div className={className}>
                <Typography className={className} variant="subtitle1" component="h3">
                  Billing Address
                </Typography>

                <div className={`${className} checkbox-container`}>
                  <FormGroup className={className}>
                    <FormControlLabel
                      label="Same as Mailing Address"
                      control={
                        <Checkbox
                          name="isBillingAddressSameAsMailing"
                          checked={values.isBillingAddressSameAsMailing}
                          inputProps={{ id: `${id}-billing-same-as-mailing-checkbox` }}
                          onChange={handleCheckSameAddress}
                          data-testid={`${id}-billing-same-as-mailing-checkbox`}
                        />
                      }
                    />
                  </FormGroup>
                </div>
                {!values.isBillingAddressSameAsMailing ? (
                  <>
                    <TextInfo
                      error={errors.billingAddress?.address1 && touched.billingAddress?.address1}
                      helpertext={touched.billingAddress?.address1 && errors.billingAddress?.address1}
                      className={className}
                      id={`${id}-billing-address-street`}
                      name="billingAddress.address1"
                      label="Address Line 1 *"
                      labelProps={{ className }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billingAddress?.address1}
                      data-testid={`${id}-billing-address-street`}
                    />

                    <TextInfo
                      className={className}
                      id={`${id}-billing-address-street-2`}
                      name="billingAddress.address2"
                      label="Address Line 2"
                      labelProps={{ className }}
                      onChange={handleChange}
                      value={values.billingAddress?.address2}
                      data-testid={`${id}-billing-address-street-2`}
                    />

                    <TextInfo
                      error={errors.billingAddress?.locality && touched.billingAddress?.locality}
                      helpertext={touched.billingAddress?.locality && errors.billingAddress?.locality}
                      className={clsx(className, 'half')}
                      id={`${id}-billing-address-city`}
                      name="billingAddress.locality"
                      label="City *"
                      labelProps={{ className }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billingAddress?.locality}
                      data-testid={`${id}-billing-address-city`}
                    />

                    <Dropdown
                      className={clsx(className, 'half')}
                      id={`${id}-billing-address-state`}
                      name="billingAddress.administrativeArea"
                      error={errors.billingAddress?.administrativeArea && touched.billingAddress?.administrativeArea}
                      helpertext={
                        touched.billingAddress?.administrativeArea && errors.billingAddress?.administrativeArea
                      }
                      label={values.billingAddress?.country === 'USA' ? 'State *' : 'Province *'}
                      onChange={handleChange}
                      options={values.billingAddress?.country === 'USA' ? shapedStateOptions : shapedProvinceOptions}
                      value={values.billingAddress?.administrativeArea}
                      data-testid={`${id}-billing-address-state`}
                    />

                    <TextInfo
                      error={errors.billingAddress?.postalCode && touched.billingAddress?.postalCode}
                      helpertext={touched.billingAddress?.postalCode && errors.billingAddress?.postalCode}
                      className={clsx(className, 'half')}
                      id={`${id}-billing-address-postal-code`}
                      name="billingAddress.postalCode"
                      label={values.billingAddress?.country === 'USA' ? 'ZIP Code *' : 'Postal Code *'}
                      labelProps={{ className }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billingAddress?.postalCode}
                      data-testid={`${id}-billing-address-postal-code`}
                    />

                    <Dropdown
                      className={clsx(className, 'half')}
                      error={touched.billingAddress?.country && errors.billingAddress?.country}
                      id={`${id}-billing-address-country`}
                      name="billingAddress.country"
                      label="Country *"
                      onBlur={handleBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setFieldValue('billingAddress.administrativeArea', '');
                      }}
                      options={countryOptions}
                      value={values.billingAddress?.country}
                      data-testid={`${id}-billing-address-country`}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Footer message="This account has not been synced.">
          <Button
            color="primary"
            onClick={() => {
              resetForm();
              navigate(-1);
            }}
            size="large"
            id={`${id}-cancel-button`}
            data-testid={`${id}-cancel-button`}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            type="submit"
            disabled={isSubmitting}
            size="large"
            variant="contained"
            id={`${id}-save-button`}
            data-testid={`${id}-save-button`}
          >
            Save
          </Button>
        </Footer>
      </Form>

      <Accordion className={clsx(className, props.className)}>
        <AccordionSummary expandIcon={altId ? <ExpandMore /> : null} component="header">
          <Typography className={className} variant="h6" component="h2">
            Additional Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={`${className} additional-details-container`}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel htmlFor="component-filled">Partner Source</InputLabel>
                <FilledInput
                  id={`${id}-partnerSource`}
                  name="Partner Source"
                  defaultValue={partnerSource}
                  readOnly={true}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel htmlFor="component-filled">Firebase ID</InputLabel>
                <FilledInput
                  id={`${id}-firebaseId`}
                  name="Firebase ID"
                  defaultValue={firebaseId}
                  readOnly={true}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {!altId || !_.get(values, 'notificationPreferences') ? null : (
        <Accordion className={clsx(className, props.className)}>
          <AccordionSummary expandIcon={altId ? <ExpandMore /> : null} component="header">
            <Typography className={className} variant="h6" component="h2">
              Notifications
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={`${className} notifications-container`}>
            {(notificationGroups?.edges || []).map((notificationGroup, i) => (
              <DriverProfileNotificationGroup
                key={i}
                index={i}
                label={notificationGroup?.groupName}
                items={_.get(notificationGroup, 'notificationItems.edges', [])}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      )}

      {!_.isEmpty(altId) && (
        <ContractsList
          className={`${className} contracts-list`}
          contracts={account?.contracts || {}}
          loading={loading}
          type="account"
          accountId={altId as string}
        />
      )}
    </Styled>
  );
};
