import { useQuery } from '@apollo/client';
import { Checkbox, Input, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { CheckBoxOutlineBlank, LocationOn } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import { Maybe, Query, SitesWithMeta } from 'src/@types';
import { FetchMore } from 'src/@types/shared';
import { listFalconConstraints } from '../../../../apollo/queries/options';
import { Styled as StyledPaper } from './styles';

export type Props = {
  onFilterChange: (fetchMore: FetchMore, metadata: SitesWithMeta, value: number[], status: string) => void;
  fetchMore: FetchMore;
  metadata: SitesWithMeta;
};

export const checkSelected = (
  selected: number[],
  statusOptions?: {
    name: Maybe<string> | undefined;
    value: Maybe<number> | undefined;
  }[],
): string => {
  if (!selected.length) return 'Sites: All';

  const selectedToRender: string[] = [];

  selected.forEach((value) => {
    selectedToRender.push(statusOptions?.find((option) => option.value === value)?.name || '');
  });

  return `Sites: ${selectedToRender.join(', ')}`;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 350,
    },
  },
};

export const SitesListFilters: React.FC<Props> = memo(({ fetchMore, metadata, onFilterChange }) => {
  const id = 'sites-list-filters';
  const className = id;
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);

  const { data: statusOptions, loading: statusOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'status',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });

  const shapedStatusOptions = statusOptionsLoading
    ? []
    : statusOptions?.listFalconConstraints?.edges?.map((c) => ({
        name: c?.columnText,
        value: Number(c?.id),
      }));

  const statuses = shapedStatusOptions?.map(({ value, name }, i) => (
    <MenuItem key={name} value={value} className={className} disableRipple>
      <Checkbox
        checked={selectedStatuses.indexOf(value) > -1}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        inputProps={{ id: `${id}-${i}-status-checkbox` }}
        icon={<CheckBoxOutlineBlank fontSize="small" />}
        disableRipple
      />
      <ListItemText primary={name} style={{ fontSize: 'small' }} />
    </MenuItem>
  ));

  const clearAll = Boolean(selectedStatuses.length) && (
    <Typography
      className={clsx(className, 'clear-all')}
      variant="caption"
      color="secondary"
      onClick={() => {
        setSelectedStatuses([]);
        onFilterChange(fetchMore, metadata, [], 'siteStatusId');
      }}
    >
      Clear
    </Typography>
  );

  return (
    <StyledPaper className={clsx(className, 'menu')}>
      <LocationOn fontSize="small" />
      <Select
        className={clsx(className, 'select')}
        displayEmpty
        input={<Input id="select-multiple-checkbox" />}
        MenuProps={MenuProps}
        multiple
        onChange={({ target }: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
          setSelectedStatuses(target.value as number[]);
          onFilterChange(fetchMore, metadata, target.value as number[], 'siteStatusId');
        }}
        renderValue={(selected) => checkSelected(selected as number[], shapedStatusOptions)}
        value={selectedStatuses}
      >
        {statuses}
      </Select>
      {clearAll}
    </StyledPaper>
  );
});
