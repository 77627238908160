import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Query } from 'src/@types';
import { listFalconConstraints } from '../../../../../../apollo/queries/options';
import { useBlurDropdown } from '../../../../../../lib/hooks';
import { initialValues } from '../../initialValues';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plan card component
 */
export const PlanCard: React.FC<Props> = ({ className: parentClass, id: parentId }) => {
  const id = _.kebabCase('PlanCard');
  const className = id;
  const { errors, handleBlur, handleChange, isSubmitting, touched, values } = useFormikContext<typeof initialValues>();
  const { data: planTemplateOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'plans',
          },
          columnName: {
            eq: 'template',
          },
        },
      },
    },
  });

  const templateOptions = (planTemplateOptions?.listFalconConstraints?.edges || []).map((option) => ({
    label: option?.columnText,
    value: Number(option?.id),
  }));
  const handleBlurDropdown = useBlurDropdown();
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledAccordion id={parentId} className={`${customClass} plan-details`} defaultExpanded={true}>
      <AccordionSummary className={className} component="header" expandIcon={<ExpandMoreIcon />} id={`${id}-header`}>
        <div className={`${className} container`}>
          <div className={`${className} title`}>
            <Typography className={className} variant="h6" component="h2">
              Plan Card
            </Typography>
          </div>
        </div>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} details`}>
        <TextInfo
          className={`${className} three-qtr`}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          error={!!(_.get(touched, 'subheading') && _.get(errors, 'subheading'))}
          helpertext={_.get(touched, 'subheading') && _.get(errors, 'subheading')}
          id={`${id}-plan-subheading-input`}
          label="Description *"
          labelProps={{ className }}
          name="subheading"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'subheading')}
          data-testid={`${id}-plan-subheading-input`}
        />

        <div className={`${className} fourth`} />

        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          error={!!(_.get(touched, 'discount') && _.get(errors, 'discount'))}
          helpertext={_.get(touched, 'discount') && _.get(errors, 'discount')}
          id={`${id}-discount-input`}
          label="Discount *"
          labelProps={{ className }}
          name="discount"
          adornmentEnd="%"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'discount')}
          data-testid={`${id}-discount-input`}
        />

        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          error={!!(_.get(touched, 'ctaLabel') && _.get(errors, 'ctaLabel'))}
          helpertext={_.get(touched, 'ctaLabel') && _.get(errors, 'ctaLabel')}
          id={`${id}-cta-label-input`}
          label="CTA *"
          labelProps={{ className }}
          name="ctaLabel"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'ctaLabel')}
          data-testid={`${id}-cta-label-input`}
        />

        <TextInfo
          className={`${className} fourth`}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          error={!!(_.get(touched, 'badge') && _.get(errors, 'badge'))}
          helpertext={_.get(touched, 'badge') && _.get(errors, 'badge')}
          id={`${id}-badge-input`}
          label="Badge"
          labelProps={{ className }}
          name="badge"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, 'badge')}
          data-testid={`${id}-badge-input`}
        />

        <Dropdown
          className={`${className} fourth`}
          disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
          error={!!(_.get(touched, 'templateId') && _.get(errors, 'templateId'))}
          helpertext={_.get(touched, 'templateId') && _.get(errors, 'templateId')}
          id={`${id}-template-input`}
          label="Template *"
          labelProps={{ className }}
          name="templateId"
          onBlur={handleBlurDropdown}
          onChange={handleChange}
          options={templateOptions}
          value={_.get(values, 'templateId')}
          data-testid={`${id}-template-input`}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};
