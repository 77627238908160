import { gql } from '@apollo/client';

export const listHosts = gql`
  query listHosts($hostsInput: ListHostsInput, $propertiesInput: ListPropertiesInput, $sitesInput: ListSitesInput) {
    listHosts(input: $hostsInput) {
      page
      pageSize
      total
      edges {
        hid
        altId
        hostName
        status
        properties(input: $propertiesInput) {
          edges {
            pid
            altId
            propertyName
            sites(input: $sitesInput) {
              edges {
                chargers {
                  total
                }
              }
              total
            }
          }
        }
      }
      sort {
        altId
        hid
        hostName
        status
      }
      filter {
        status {
          in
        }
      }
      search {
        hid {
          iLike
        }
      }
    }
  }
`;
