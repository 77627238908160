import { useQuery } from '@apollo/client';
import { Button, IconButton, InputLabel, Typography } from '@material-ui/core';
import { ArrowBack, FilterNone, Layers } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'src/@types';
import { getHostById } from '../../../../apollo/queries/hosts';
import { copyToClipBoard } from '../../../../lib/helpers';
import { Styled } from './styles';

export interface Props {
  className?: string;
  altId?: string;
}

/**
 * Host Header component
 */
export const HostHeader: React.FC<Props> = (props) => {
  const { altId } = props;
  const className = 'HostHeader';
  const { fetchMore, ...host } = useQuery<Query>(getHostById, { variables: { hostInput: { altId } }, skip: !altId });
  const selected = _.get(host, 'data.getHost', {});

  const fields = [
    {
      label: 'Address',
      value: `${_.get(selected, 'address1', '')}, ${_.get(selected, 'locality', '')}, ${_.get(
        selected,
        'administrativeArea',
        '',
      )} ${_.get(selected, 'postalCode', '')}`,
    },
    {
      label: 'Host ID',
      value: _.get(selected, 'hid', ''),
    },
    {
      label: 'Contact',
      value: _.get(selected, 'contacts.edges[0].contactName', ''),
    },
    {
      label: 'Contact Email',
      value: _.get(selected, 'contacts.edges[0].email', ''),
    },
  ];

  return (
    <Styled className={className}>
      <nav className={className}>
        <Button className={className} component={Link} size="small" to="/hosts/list">
          <ArrowBack className={className} /> All Hosts
        </Button>
      </nav>

      <div className={className}>
        <Typography className={className} component="h1" variant="h6">
          <Layers className={className} /> {_.get(selected, 'hostName', '')}
        </Typography>
      </div>

      <div className={className}>
        {fields.map((field, i) => (
          <div key={i} className={className}>
            <InputLabel className={className} shrink>
              {field.label}
            </InputLabel>

            <div className={className}>
              <Typography className={className}>{field.value}</Typography>

              <IconButton className={className} onClick={copyToClipBoard(field.value)}>
                <FilterNone className={className} />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </Styled>
  );
};
