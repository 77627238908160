import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from '@evgo/react-material-components';

export const TableLink = styled(Link)`
  color: ${theme.palette.primary[500]};
  font-size: ${theme.typography.fontSize + 2}px;
  text-decoration: underline;
  font-weight: 500;
`;
