import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, spacing } = theme;

export const Styled = styled.div`
  table {
    width: calc(100% - ${spacing(3)}px);
    margin: ${`${spacing()}px ${spacing(3) / 2}px 0`};

    thead {
      tr {
        height: auto;

        td,
        th {
          svg {
            margin-bottom: -5px;
            margin-left: ${spacing(1.5)}px;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        th {
          padding: ${spacing()}px;
        }
      }
    }

    tbody {
      tr {
        height: auto;

        td {
          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          padding: ${`${spacing() / 2}px ${spacing()}px`};

          button {
            margin: 0;
          }

          .form-control {
            width: 100%;
            margin-bottom: 0;

            &.rate {
              :global(input) {
                padding: ${`${spacing(3)}px ${spacing(1.875)}px ${spacing(1.5)}px 0`};
              }

              .form-group {
                div {
                  &:first-child {
                    margin-right: 0;
                  }
                }
              }
            }

            div {
              margin-top: 0;
            }

            :global(input) {
              padding: ${`${spacing(3)}px ${spacing(1.875)}px ${spacing(1.5)}px`};
            }

            :global(.helper-text) {
              display: none;
            }
          }

          > span {
            padding: ${`${spacing(1.5)}px 0`};
          }
        }
      }
    }
  }

  p {
    margin: ${`${spacing(0.5)}px ${spacing(1.5)}px`};
    color: ${palette.error.main};
  }

  button {
    margin: ${`-${spacing(0.5)}px ${spacing() / 2}px -${spacing(0.75)}px`};
  }

  .hidden {
    visibility: hidden;
  }
`;
