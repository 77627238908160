import _ from 'lodash';
import * as Yup from 'yup';
import days from './lib/fixtures/days.json';

interface Hour {
  startTime: string;
  endTime: string;
}

Yup.addMethod(Yup.array, 'allMatch', function allMatch(message, fields, ref, when) {
  return this.when(
    when.map((value: Record<number, unknown>) => value[0]),
    (...args) => {
      _.reverse(args);
      const [, schema, ...deps] = args;
      _.reverse(deps);

      if (!_.every(deps, (dep, i) => dep === when[i][1])) return schema;

      return schema.test({
        message,
        name: 'allMatch',
        test: (arr: string[]) =>
          _.every(
            arr.map((obj) =>
              _.every(
                _.filter(obj, (value, key) => _.find(fields, (field) => field === key)),
                (value = '') => value.match(ref),
              ),
            ),
            (field) => !!field,
          ),
      });
    },
  );
});

Yup.addMethod(Yup.array, 'compareTimes', function compareTimes(message, when) {
  return this.when(
    when.map((value: Record<number, unknown>) => value[0]),
    (...args) => {
      _.reverse(args);
      const [, schema, ...deps] = args;
      _.reverse(deps);

      if (!_.every(deps, (dep, i) => dep === when[i][1])) return schema;

      return schema.test({
        message,
        name: 'compareTimes',
        test: (arr: { to: string; from: string }[]) => _.every(arr, ({ to, from }) => to > from),
      });
    },
  );
});

Yup.addMethod(Yup.array, 'invalidTimes', function invalidTimes(message, when) {
  return this.when(
    when.map((value: Record<number, unknown>) => value[0]),
    (...args) => {
      _.reverse(args);
      const [, schema, ...deps] = args;
      _.reverse(deps);

      if (!_.every(deps, (dep, i) => dep === when[i][1])) return schema;

      return schema.test({
        message,
        name: 'invalidTimes',
        test: (arr: string[]) =>
          _.every(days, (day: keyof Hour) =>
            _.every(
              _.sortBy(
                _.filter(arr, (hour: Hour) => hour[day]),
                'startTime',
              ),
              (hour: Hour, i, hours) => i === 0 || hour.startTime > (hours[i - 1] as unknown as Hour).endTime,
            ),
          ),
      });
    },
  );
});

Yup.addMethod(Yup.array, 'invalidOperationHours', function invalidOperationHours(message, when) {
  return this.when(
    when.map((value: Record<number, unknown>) => value[0]),
    (...args) => {
      _.reverse(args);
      const [, schema, ...deps] = args;
      _.reverse(deps);

      if (!_.every(deps, (dep, i) => dep === when[i][1])) return schema;

      return schema.test({
        message,
        name: 'invalidOperationHours',
        test: (arr: { to: string; from: string }[]) =>
          !_.includes(
            arr.map((item, i) => {
              if (arr[i + 1]) return arr[i].to < arr[i + 1].from;
              return true;
            }),
            false,
          ),
      });
    },
  );
});

Yup.addMethod(Yup.array, 'noneAre', function noneAre(message, fields, ref, when) {
  return this.when(
    when.map((value: Record<number, unknown>) => value[0]),
    (...args) => {
      _.reverse(args);
      const [, schema, ...deps] = args;
      _.reverse(deps);

      if (!_.every(deps, (dep, i) => dep === when[i][1])) return schema;

      return schema.test({
        message,
        name: 'noneAre',
        test: (arr: string[]) =>
          _.every(
            arr.map(
              (obj) =>
                !_.every(
                  _.filter(obj, (value, key) => _.find(fields, (field) => field === key)),
                  (value) => value === ref,
                ),
            ),
            (field) => !!field,
          ),
      });
    },
  );
});

Yup.addMethod(Yup.array, 'someAre', function someAre(message, fields, ref, when) {
  return this.when(
    when.map((value: Record<number, unknown>) => value[0]),
    (...args) => {
      _.reverse(args);
      const [, schema, ...deps] = args;
      _.reverse(deps);

      if (!_.every(deps, (dep, i) => dep === when[i][1])) return schema;

      return schema.test({
        message,
        name: 'someAre',
        test: (arr: string[]) =>
          _.every(
            arr.map((obj) =>
              _.some(
                _.filter(obj, (value, key) => _.find(fields, (field) => field === key)),
                (value) => value === ref,
              ),
            ),
            (field) => !!field,
          ),
      });
    },
  );
});

Yup.addMethod(Yup.object, 'uniqueProperty', function uniqueProperty(message, property) {
  return this.test('unique', message, function testUniqueProperty(value) {
    const siblings = _.filter(this.parent, (obj) => obj !== value);
    const hasDuplicates = _.some(siblings, (obj) => obj[property] === value[property]);

    if (hasDuplicates) throw this.createError({ path: `${this.path}.${property}` });

    return true;
  });
});

export default Yup;
