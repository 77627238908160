import { Dispatch } from 'react';
import { Box } from '@material-ui/core';

import { MultiSelectFilter } from 'src/components/shared/MultiSelectFilter';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

import { Action, State } from '../../filterHelpers';

export type ChargerGroupsListFilterProps = {
  state: State;
  dispatch: Dispatch<Action>;
};

export function ChargerGroupsListFilter({ state, dispatch }: ChargerGroupsListFilterProps) {
  const { track } = useAnalytics();
  return (
    <Box pt={1} pl={2}>
      <MultiSelectFilter
        id="charger-group-type"
        label="Price Type"
        options={state.initial.chargerGroupTypes.map(({ chargerGroupType, chargerGroupTypeName }) => ({
          value: chargerGroupType || '',
          label: chargerGroupTypeName || '',
        }))}
        onChange={(value) => {
          track(events.chargerGroupsList.FILTERED_CHARGER_GROUP_LIST);
          dispatch({ type: 'setChargerGroupTypes', chargerGroupTypes: value });
        }}
      />
    </Box>
  );
}
