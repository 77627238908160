import { Box, Divider, useTheme } from '@material-ui/core';

const DrawerDivider = () => {
  const theme = useTheme();
  const spacing = `${theme.spacing(3)}px`;
  return (
    <Box mt={spacing} mb={spacing}>
      <Divider />
    </Box>
  );
};

export default DrawerDivider;
