import { MutationFunction } from '@apollo/client';
import { Button } from '@material-ui/core';
import { Charger } from 'src/@types';
import { useConfirmationDialog } from 'src/lib/hooks';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

type ParentData = {
  altId: string;
};

type ColumnDataProps = {
  data: Charger;
  parentData?: ParentData;
  mutation?: MutationFunction;
};

export const RemoveChargerButton: React.FC<ColumnDataProps> = ({ data, parentData, mutation: removeCharger }) => {
  const confirmationDialog = useConfirmationDialog();
  const { track } = useAnalytics();

  const openConFirmationDialog = () => {
    confirmationDialog.open({
      title: 'Remove Charger',
      body: 'Are you sure you want to remove this charger from the group?',
      buttonConfirmText: 'Remove Charger',
      callback: () => {
        if (removeCharger) removeCharger({ variables: { input: { ...parentData, chargerIds: [data.altId] } } });
      },
    });
    track(events.chargerPriceChargersList.REMOVED_CHARGER_FROM_GROUP);
  };

  return (
    <Button data-testid="remove-charger-btn" onClick={openConFirmationDialog} variant="outlined">
      Remove
    </Button>
  );
};
