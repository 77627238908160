import { gql } from '@apollo/client';

export const resetDriverPassword = gql`
  mutation resetDriverPassword($driverInput: ResetDriverPasswordInput) {
    resetDriverPassword(input: $driverInput) {
      mandrillId
      recipientEmail
      status
    }
  }
`;
