import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  box-sizing: border-box;
  width: calc(100% - ${spacing(4)}px);

  margin: 32px 16px 0 !important;

  header {
    padding: ${`0 ${spacing(3)}px`};
    div {
      margin: 0;
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  .panel-details {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        padding: 0 ${`${spacing(2)}`}px;
        gap: 1em;

        > button {
          position: relative;
          width: 92px;
          height: 92px;
          margin: ${`${spacing(2)}px ${spacing()}px 0`};
          border: ${`${spacing() / 4}px dashed ${palette.text.secondary}`};
          color: ${palette.text.secondary};

          input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        h3 {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`${spacing(2)}px ${spacing()}px 0`};
          color: ${palette.text.secondary};
        }

        .form-control,
        .select {
          width: calc(100% - ${spacing(2)}px);

          &.half {
            width: calc(50% - ${spacing(2)}px);
          }

          &.thirds {
            width: calc(33.33% - ${spacing(2)}px);
          }

          &.fourth {
            width: calc(25% - ${spacing()}px);
          }
        }

        .form-control {
          label {
            transform: translate(${`${spacing(2)}px, ${spacing(3)}px`}) scale(1);

            &.prefilled,
            &[data-shrink='true'] {
              transform: translate(${`${spacing(2)}px, ${spacing(2)}px`}) scale(0.75);
            }
          }
        }

        .menu {
          width: 100%;
        }

        .flags {
          width: calc(100% - ${spacing(2)}px);
          padding: ${`0px ${spacing()}px 0`};
          color: ${palette.text.secondary};

          display: flex;
          flex-direction: row;

          > div {
            margin-right: ${`${spacing(2)}px`};
          }
        }

        h2 {
          width: 100%;
          padding: ${`${spacing(3)}px ${spacing()}px ${spacing(2)}px`};
        }
        hr {
          width: calc(100% + ${`${spacing(4)}px`});
          margin-left: ${`-${spacing(2)}px`};
        }
      }
    }

    .additionalDetails {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      width: 100%;
      padding: ${`0 ${spacing(2)}px ${spacing(2)}px`};

      h3 {
        width: calc(100% - ${spacing(2)}px);
        padding: ${`${spacing(2)}px ${spacing()}px 0`};
        color: ${palette.text.secondary};
      }

      .additionalCheckboxes {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: ${`0px ${spacing()}px 0`};
        color: ${palette.text.secondary};
        width: calc(100% - ${spacing(2)}px);
        div {
          display: flex;
          h3 {
            font-weight: 300;
            padding: ${`${spacing() + 2}px 0`};
          }
        }
        span {
          padding: ${`${spacing(1.5)}px ${spacing() / 2}px`};
        }
        label {
          width: calc(33% - ${spacing(2)}px);
        }
      }

      .fundingContainer,
      .equipmentClass {
        width: calc(100% - ${spacing(2)}px);
        padding: 0px;
      }

      .form-control,
      .select {
        width: calc(100% - ${spacing(2)}px);

        &.third {
          width: calc(33.33% - ${spacing(2)}px);
        }

        &.description {
          width: calc(60.66% - ${spacing(2)}px);
        }
      }
    }
  }

  svg {
    &.left {
      margin-right: ${spacing()}px;
    }
  }

  .charger-tags {
    padding: ${`0 ${spacing(2)}px ${spacing(2)}px`};

    h3 {
      width: calc(100% - ${spacing(2)}px);
      padding: ${`${spacing(2)}px ${spacing()}px 0`};
      color: ${palette.text.secondary};
    }

    .tag-container {
      max-height: 175px;
      overflow-y: auto;
      margin: 0;
      padding: ${`0 ${spacing()}px`};
      width: 100%;

      .tag {
        padding: ${`${spacing(2)}px ${spacing(4)}px ${spacing(2)}px 0`};
      }
    }

    .tag-paper {
      padding: ${`${spacing()}px ${spacing(1.5)}px`};
      background-color: ${colors.botticelli};
      margin: ${`${spacing(0.75)}px 0`} auto;
    }

    h4 {
      width: calc(100% - ${spacing(2)}px);
      color: ${palette.text.secondary};
    }
  }
`;
