import { gql } from '@apollo/client';

export const updateProperty = gql`
  mutation updateProperty($propertyInput: UpdatePropertyInput) {
    updateProperty(input: $propertyInput) {
      altId
      status
    }
  }
`;
