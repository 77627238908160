import styled from 'styled-components';

export const Styled = styled.div`
  width: 100%;
  div {
    .row {
      padding: 0;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      width: 100%;
    }
  }
`;
