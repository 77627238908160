import { TableCell, TableRow } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Contact } from 'src/@types';
import { titleCase } from '../../../../../lib/helpers';

export interface Props {
  contact: Contact;
  index: number;
  className?: string;
}

export const ContactRow: React.FC<Props> = ({ className, contact, index }) => {
  return (
    <Fragment>
      <TableRow className={className}>
        <TableCell className={className} id={`host-contacts-table-contactName-cell-${index}`}>
          {contact?.contactName || ''}
        </TableCell>
        <TableCell className={className} id={`host-contacts-table-workTitle-cell-${index}`}>
          {contact?.workTitle || ''}
        </TableCell>
        <TableCell className={className} id={`host-contacts-table-email-cell-${index}`}>
          {contact?.email || ''}
        </TableCell>
        <TableCell className={className} id={`host-contacts-table-phone-cell-${index}`}>
          {titleCase(contact?.phone || '')}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
