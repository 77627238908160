import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import React from 'react';
import { Query } from 'src/@types';
import { getHostsTotal } from '../../../../apollo/queries/hosts';
import { Styled } from './styles';

/**
 * Hosts Header component
 */
export const HostsHeader: React.FC = () => {
  const className = 'HostsHeader';
  const { data } = useQuery<Query>(getHostsTotal);
  const total = data?.listHosts?.total || 0;
  const s = total === 1 ? '' : 's';

  return (
    <Styled className={className}>
      <section className={`${className} title`}>
        <Typography className={className} variant="h5" component="h1">
          Host Management
        </Typography>
        <Typography className={`${className} subtitle`} variant="caption">
          {total || 0} Host{s}
        </Typography>
      </section>
    </Styled>
  );
};
