import { useLazyQuery } from '@apollo/client';
import { Box, Card, Divider, Typography, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Query, Account, TransactionV2 } from 'src/@types';
import { Column, Table, usePagination, Direction } from 'src/components/shared/Table';
import { Message as MessageIcon, SpeakerNotesOff as SpeakerNotesOffIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Styled as StyledTable } from './styles';
import { listTransactionsV2 as LIST_TRANSACTIONS } from 'src/apollo/queries/transactions';
import { TransactionDetails } from './TransactionDetails';
import moment from 'moment';
import { camelToSnakeCase } from 'src/lib/helpers/camelToSnakeCase';
import { TransactionFilter } from 'src/components/shared/TransactionFilter';
import { DateRangeField, DateRangeValue } from 'src/components/shared/DateRangeField';
import { startOfDay, endOfDay } from 'date-fns';
import { theme } from '@evgo/react-material-components';
const { spacing } = theme;
import styled from 'styled-components';
import { staticRanges } from './utils';
import { SearchField } from 'src/components/shared/SearchField';

const StyledDateRangeField = styled(DateRangeField)`
  margin-left: ${spacing(2)}px;
  .MuiFormControl-root {
    min-width: 230px;
    background-color: ${theme.palette.grey['200']};
    border-radius: 4px;
  }
  .MuiInput-root {
    padding: 0px 4px;
  }
  .MuiFormLabel-root {
    margin-top: 4px;
    margin-left: 4px;
    color: ${theme.palette.grey['600']};
  }
`;

export interface Props {
  account?: Account;
}
export type TransactionFilters = {
  startTime?: Date;
  endTime?: Date;
  transactionTypeIds?: string[];
};
type ColumnDataProps = {
  data: TransactionV2;
};

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Amount: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <Box>
      <p data-testid="Amount">${data.amount}</p>
      <p data-testid="Amount">Tax: ${data.tax}</p>
    </Box>
  );
};
const StartedAt: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <Box>
      <p data-testid="CreatedAt">{moment(data?.createdAt).format('MM/DD/YYYY')}</p>
      <p data-testid="CreatedAt">{moment(data?.createdAt).format('hh:mm:ss')}</p>
    </Box>
  );
};
const Comment: React.FC<ColumnDataProps> = ({ data }) => {
  const comments =
    data?.items?.map((item) => item?.comments).filter((comment) => comment && comment.trim().length > 0) || [];
  return comments.length > 0 ? (
    <HtmlTooltip
      title={
        <React.Fragment>
          {comments.map((comment, index) => (
            <React.Fragment key={index}>
              <p>{comment}</p>
            </React.Fragment>
          ))}
        </React.Fragment>
      }
    >
      <MessageIcon />
    </HtmlTooltip>
  ) : (
    <SpeakerNotesOffIcon style={{ color: theme.palette.grey['200'] }} />
  );
};
const TotalBilledTime: React.FC<ColumnDataProps> = ({ data }) => {
  const start = moment(data?.session?.startTime);
  const end = moment(data?.session?.endTime);
  const timeDiff = moment.duration(end.diff(start, 'milliseconds', true));
  const totalBilled = data?.session?.startTime
    ? moment.utc(moment.duration(timeDiff, 'seconds').asMilliseconds()).format('mm:ss')
    : 'NA';
  return <p data-testid="TotalBilledTime">{totalBilled}</p>;
};
const PlanName: React.FC<ColumnDataProps> = ({ data }) => {
  return data?.session?.plan?.planName ? (
    <a target="_blank" href={`/plans/${data?.session?.plan?.altId}/`}>
      {data?.session?.plan?.planName}
    </a>
  ) : (
    <p>NA</p>
  );
};
const EnergyDelivered: React.FC<ColumnDataProps> = ({ data }) => {
  const energyDelivered = data?.session?.energyDelivered;
  const totalKWH = (data?.session?.energyDelivered || 0) / 1000;
  return <p data-testid="PlanName">{energyDelivered ? totalKWH : 'NA'}</p>;
};
const columns: Column[] = [
  {
    key: 'vendorId',
    label: 'Transaction ID',
    align: 'left',
    sortable: true,
    width: '20%',
  },
  {
    key: 'startDate',
    label: 'Date',
    align: 'left',
    sortable: true,
    sortName: 'startDate',
    width: '15%',
    component: StartedAt,
  },
  {
    key: 'type.columnValue',
    label: 'Type',
    align: 'left',
    sortable: false,
    width: '15%',
  },
  {
    key: 'session.energyDelivered',
    sortName: 'kwh',
    label: 'Total kWh',
    align: 'center',
    sortable: true,
    width: '10%',
    component: EnergyDelivered,
  },
  {
    key: 'session.startTime',
    label: 'Total Billed Time',
    align: 'center',
    sortable: false,
    width: '10%',
    component: TotalBilledTime,
  },
  {
    key: 'amount',
    label: 'Total Cost',
    align: 'left',
    sortable: true,
    sortName: 'amount',
    width: '10%',
    component: Amount,
  },
  {
    key: 'session.plan.planName',
    label: 'Plan Name',
    align: 'left',
    sortable: false,
    width: '10%',
    component: PlanName,
  },
  {
    key: 'items.comments',
    label: 'Comments',
    align: 'center',
    sortable: false,
    width: '10%',
    component: Comment,
  },
];

export const AccountTransactions: React.FC<Props> = (props) => {
  const { account } = props;
  const drivers = account?.drivers?.edges;
  const ownerDriver = drivers?.find((driver) => driver?.accountId === account?.id)?.id || 0;
  const pagination = usePagination();
  const [tableSortBy, setTableSortBy] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRangeValue>();
  const [tableSortDirection, setTableSortDirection] = useState<Direction>(Direction.Desc);
  const [tableFilters, setTableFilters] = useState<TransactionFilters>({});
  const [activeTransaction, setActiveTransaction] = useState<TransactionV2 | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [listTransactionsQuery, { data, loading, error }] = useLazyQuery<Query>(LIST_TRANSACTIONS, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    const vendorId = searchTerm && searchTerm.length >= 3 ? { eq: parseFloat(searchTerm) } : null;
    listTransactionsQuery({
      variables: {
        input: {
          ...pagination,
          search: {
            vendorId,
          },
          filter: {
            transactionTypeId:
              tableFilters?.transactionTypeIds?.length === 0 ? undefined : { in: tableFilters?.transactionTypeIds },
            ...(dateRange?.startDate ? { startTime: { ge: startOfDay(dateRange.startDate) } } : {}),
            ...(dateRange?.endDate ? { endTime: { le: endOfDay(dateRange.endDate) } } : {}),
            driverId: {
              eq: +ownerDriver,
            },
          },
          sort: tableSortBy ? `${camelToSnakeCase(tableSortBy)}_${tableSortDirection}` : 'END_DATE_DESC',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.page,
    pagination.pageSize,
    tableSortBy,
    tableSortDirection,
    ownerDriver,
    tableFilters,
    dateRange,
    searchTerm,
  ]);

  const transactions = data?.listTransactionsV2?.edges || [];
  const handleChange = (value: string | unknown) => {
    const clickedTransaction = transactions.find((transaction) => transaction?.altId === value);
    setActiveTransaction(clickedTransaction || undefined);
  };

  return (
    <StyledTable data-testid="TransactionsList">
      <Box mx={4} my={4} mt={4}>
        <Card>
          <Box display="flex" width="100%" p={2} alignItems="flex-start" justifyContent="space-between">
            <div>
              <Typography variant="h6" component="h2">
                Transactions
              </Typography>
            </div>
            <SearchField onChange={setSearchTerm} placeholder="Enter Transaction ID" />
          </Box>
          <Divider />

          <Box display="flex" p={2} alignItems="flex-start">
            <Box>
              <TransactionFilter
                onChange={(filters) => {
                  setTableFilters({
                    transactionTypeIds: filters.map((appliedFilter) => appliedFilter.id.toString()),
                  });
                }}
              />
            </Box>
            <Box mt={1}>
              <StyledDateRangeField
                placeholder="Select date range"
                value={dateRange}
                onChange={(dateRangeValue) => setDateRange(dateRangeValue)}
                DateRangePickerProps={{
                  inputRanges: [],
                  staticRanges,
                  startDatePlaceholder: 'Start Date',
                  endDatePlaceholder: 'End Date',
                }}
              />
            </Box>
          </Box>
          <Table
            id="TransactionsTable"
            data-testid="trnsactions-table"
            columns={columns}
            data={transactions || []}
            loading={loading}
            error={error}
            noDataMessage="No matching transactions were found. Please check the Transaction ID and try again."
            pagination={{ ...pagination, total: data?.listTransactionsV2?.total || 0 }}
            onCollapseClick={(row) => handleChange(row?.altId)}
            sorting={{
              sortBy: tableSortBy,
              sortDirection: tableSortDirection,
              onSortByChange: setTableSortBy,
              onSortDirectionChange: setTableSortDirection,
            }}
            key="base-table"
          >
            <TransactionDetails transaction={activeTransaction} />
          </Table>
        </Card>
      </Box>
    </StyledTable>
  );
};
