import { gql } from '@apollo/client';

export const listChargeSessions = gql`
  query listChargeSessions($input: ListChargeSessionsInput) {
    listChargeSessions(input: $input) {
      page
      pageSize
      total
      search {
        sessionId {
          iLike
        }
        chargerId {
          eq
        }
      }
      filter {
        sessionStartOn {
          from
          to
        }
        startSource {
          in
        }
      }
      edges {
        sessionId
        siteId
        stationSocket
        customerId
        cardId
        corruptedSession
        meterEnd
        meterStart
        sessionStartOn
        sessionStoppedOn
        startSource
        stopSource
        meterReadings {
          timeStamp
          kW
        }
      }
    }
  }
`;
