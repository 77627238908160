import {
  Box,
  Button,
  Chip,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  TableHead,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Tooltip } from 'src/components/shared/Tooltip';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';
import ExampleViewer from './ExampleViewer';

const InfoBlock: React.FC<{ info?: string; source?: React.CSSProperties }> = ({ info, source, children }) => {
  const getInfoText = (): string | boolean => {
    if (info) return info;
    if (!source) return false;
    return `Size: ${source.fontSize},  Line Height: ${source.lineHeight},  Weight: ${source.fontWeight}`;
  };
  const infoText = getInfoText();
  return (
    <Box>
      <Box display="flex" alignItems="center" minHeight="50px">
        {children}
      </Box>
      {Boolean(infoText) && (
        <Typography variant="overline" style={{ color: extendPlusTheme.palette.grey['600'] }}>
          {infoText}
        </Typography>
      )}
    </Box>
  );
};

export default function ExtendPlusTypography() {
  return (
    <ExampleViewer title="Extend+ Typography">
      <ThemeProvider theme={extendPlusTheme}>
        <Box padding="10px">
          <Grid container spacing={4}>
            <Grid item xs={6} md={4} lg={3}>
              <Box mb="30px" mt="20px">
                <Typography variant="subtitle2">HEADLINES</Typography>
              </Box>
              <InfoBlock source={extendPlusTheme.typography.h1}>
                <Typography variant="h1">H1</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.h2}>
                <Typography variant="h2">H2</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.h3}>
                <Typography variant="h3">H3</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.h4}>
                <Typography variant="h4">H4</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.h5}>
                <Typography variant="h5">H5</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.h6}>
                <Typography variant="h6">H6</Typography>
              </InfoBlock>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Box mb="30px" mt="20px">
                <Typography variant="subtitle2">TEXTS</Typography>
              </Box>
              <InfoBlock source={extendPlusTheme.typography.body1}>
                <Typography variant="body1">Body 1</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.body2}>
                <Typography variant="body2">Body 2</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.subtitle1}>
                <Typography variant="subtitle1">Subtitle 1</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.subtitle2}>
                <Typography variant="subtitle2">Subtitle 2</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.caption}>
                <Typography variant="caption">Caption</Typography>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.typography.overline}>
                <Typography variant="overline">overline</Typography>
              </InfoBlock>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Box mb="30px" mt="20px">
                <Typography variant="subtitle2">BUTTONS</Typography>
              </Box>
              <InfoBlock source={extendPlusTheme.overrides?.MuiButton?.textSizeLarge as React.CSSProperties}>
                <Button size="large">Button Large</Button>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiButton?.text as React.CSSProperties}>
                <Button size="medium">Button Medium</Button>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiButton?.textSizeSmall as React.CSSProperties}>
                <Button size="small">Button Small</Button>
              </InfoBlock>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Box mb="30px" mt="20px">
                <Typography variant="subtitle2">COMPONENTS</Typography>
              </Box>
              <InfoBlock source={extendPlusTheme.overrides?.MuiInputLabel?.root as React.CSSProperties}>
                <InputLabel>Input Label</InputLabel>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiInput?.input as React.CSSProperties}>
                <Input value="Input Text" />
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiFormHelperText?.root as React.CSSProperties}>
                <FormHelperText>Helper Text</FormHelperText>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiChip?.label as React.CSSProperties}>
                <Chip label="Chip" />
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiTableHead?.root as React.CSSProperties}>
                <TableHead>Table Header</TableHead>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiMenuItem?.root as React.CSSProperties}>
                <MenuItem>Menu Item</MenuItem>
              </InfoBlock>
              <InfoBlock source={extendPlusTheme.overrides?.MuiTooltip?.tooltip as React.CSSProperties}>
                <Tooltip content={'Tooltip'} />
              </InfoBlock>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </ExampleViewer>
  );
}
