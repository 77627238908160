/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Transforms the Formik host values into Falcon format
 */
export const transformHostValues = (values: any) => {
  const hostInput = {
    address1: _.get(values, 'address1'),
    address2: _.get(values, 'address2'),
    address3: _.get(values, 'address3'),
    address4: _.get(values, 'address4'),
    administrativeArea: _.get(values, 'administrativeArea'),
    country: _.get(values, 'country', 'USA'),
    hid: _.get(values, 'hid'),
    hostName: _.get(values, 'hostName'),
    locality: _.get(values, 'locality'),
    postalCode: _.get(values, 'postalCode'),
    status: _.get(values, 'status', 'ACTIVE'),
  };

  return hostInput;
};
