import moment from 'moment-timezone';

/**
 * A helper to format only the time from a timestamp
 */
export const formatTime = (timestamp: string | null, format = 'LT', tz: string | undefined = undefined) => {
  let localTime = '';
  if (tz) {
    localTime = moment(timestamp)
      .tz(tz)
      .format(format || 'LT');
  }
  return localTime ? localTime : !timestamp ? '' : moment.utc(timestamp).format(format);
};
