import { Dropdown, TextInfo } from '@evgo/react-material-components';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useState } from 'react';
import { ModalProps } from 'src/@types/client';
import { AddCardParams, LabelValue } from 'src/@types/shared';
import { StyledModal } from '../../../shared/StyledModal';
import { Styled } from './styles';

interface Props {
  closeModal?: () => void;
  addCard?: (p: AddCardParams) => void;
  assignCardsUpdate?: (input: unknown, updateFunction: unknown) => void;
  brands?: LabelValue<string>[];
  open?: boolean;
  modalProps?: ModalProps;
  className?: string;
}

export const AddCardsModal: React.FC<Props> = (props) => {
  const className = 'addcardsmodal-component';
  const { closeModal, addCard, brands, open } = props;

  const [internalNumber, setInternalNumber] = useState('');
  const [externalNumber, setExternalNumber] = useState('');
  const [brand, setBrand] = useState('');

  const onCancelClick = () => {
    closeModal?.();
  };

  return (
    <StyledModal
      className={clsx(className, 'modal')}
      onClose={onCancelClick}
      open={!!open}
      title="Add Cards"
      onClick={() => {
        addCard?.({ internalNumber, externalNumber, brand });
        onCancelClick();
      }}
      primaryButtonText="Create Cards"
      primaryButtonDisabled={_.isEmpty(internalNumber) || _.isEmpty(externalNumber) || _.isEmpty(brand)}
      secondaryButtonClick={onCancelClick}
      secondaryButtonText="Cancel"
    >
      <Styled className={clsx(className, 'content')}>
        <div className={className}>
          <TextInfo
            className={clsx(className, 'form-control')}
            label="Internal Number *"
            value={internalNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInternalNumber(e.target.value)}
            data-testid="add-cards-modal-internal-number-input"
            variant="filled"
          />
          <TextInfo
            className={clsx(className, 'form-control')}
            label="External Number *"
            value={externalNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setExternalNumber(e.target.value)}
            data-testid="add-cards-modal-external-number-input"
            variant="filled"
          />
          <Dropdown
            className={clsx(className, 'content', 'demo')}
            displayValue={brand}
            id="chargerModel"
            label="Model *"
            labelProps={{ className }}
            menuProps={{ className }}
            options={brands || []}
            value={brand}
            required
            data-testid="add-cards-modal-brand-dropdown"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBrand(e.target.value)}
          />
        </div>
      </Styled>
    </StyledModal>
  );
};
