import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';

import useAnalytics from 'src/lib/hooks/useAnalytics';
import { FlatNavigationItem } from '../types';
import { NavLink, IconContainer } from './styles';
import { generateMenuItemTestId } from '../helpers';

export type FlatItemProps = FlatNavigationItem & {
  handleDrawerToggle?: () => void;
};

export function FlatItem({ name, icon, to, isActive, analyticsEventName, handleDrawerToggle }: FlatItemProps) {
  const { pathname } = useLocation();
  const { track } = useAnalytics();
  const className = clsx({ active: isActive?.(pathname) });
  const onClick = () => {
    if (analyticsEventName) {
      track(analyticsEventName);
    }
    if (handleDrawerToggle) handleDrawerToggle();
  };
  return (
    <ListItem
      button
      component={NavLink}
      className={className}
      onClick={onClick}
      to={to}
      data-testid={generateMenuItemTestId(name)}
    >
      <IconContainer>{icon}</IconContainer>
      <ListItemText primary={name} />
    </ListItem>
  );
}
