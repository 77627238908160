import { useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChargersWithMeta, Query, Site } from 'src/@types';
import { getSite } from '../../../../apollo/queries/sites';
import { transformSiteResponse } from '../../../../lib/helpers';
import { Footer } from '../../../shared/Footer';
import { SiteChargers } from './SiteChargers';
import { SiteEditHeader } from './SiteEditHeader';
import { SiteLocation } from './SiteLocation';
import { SiteProfile } from './SiteProfile';
import { Styled as StyledForm } from './styles';
import { Values } from './types';

export interface Props {
  className?: string;
}

/** Initial Formik values */
export const initialValues: Values = {
  siteName: '',
  displayName: '',
  demandLimitManagementEnabled: false,
  retailDemandLimit: '',
  sid: '',
  status: '',
  property: {
    host: {
      altId: '',
    },
    pid: '',
  },
  siteAccess: 'PUBLIC',
  address1: '',
  locality: '',
  postalCode: '',
  administrativeArea: '',
  latitude: '',
  longitude: '',
  hoursOfOperation: {
    custom: false,
    from: '00:00',
    to: '00:00',
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  },
  siteStatusId: 89,
  maxCurrent: '',
  locationTypeId: null,
  siteAccessId: null,
  utilityCompany: 'HOST_POWER',
  ratedVoltage: '',
  meterNumber: '',
  country: 'United States',
};

export const SiteForm: React.FC<Props> = () => {
  const id = _.kebabCase('SiteForm');
  const className = id;
  const { altId } = useParams<{ altId: string }>();
  const navigate = useNavigate();
  const { isSubmitting, resetForm, handleSubmit } = useFormikContext<Site>();

  const {
    data: siteData,
    loading: siteloading,
    fetchMore,
  } = useQuery<Query>(getSite, {
    fetchPolicy: 'network-only',
    skip: !altId,
    variables: { siteInput: { altId } },
  });

  const { edges, ...metadata } = siteData?.getSite?.chargers || ({} as ChargersWithMeta);

  // Handles populating the form with values from the server once everyting is loaded
  useEffect(() => {
    if (!siteloading && siteData) {
      const siteValues = transformSiteResponse(siteData?.getSite || {}) as Site;

      resetForm({ status: siteValues, values: siteValues });
    }
  }, [siteloading, resetForm, siteData]);

  return (
    <StyledForm className={className}>
      <article className={className}>
        <div className={`${className} header-section`}>
          <SiteEditHeader className={className} loading={siteloading} />
        </div>

        <Form id={id}>
          <SiteProfile />

          {altId && (
            <Fragment>
              <SiteLocation className={className} />
              <SiteChargers className={className} metadata={metadata} fetchMore={fetchMore} />
            </Fragment>
          )}

          <Footer className={className}>
            <Button
              className={className}
              color="primary"
              disabled={isSubmitting}
              id={`${id}-cancel-button`}
              onClick={() => {
                resetForm();
                navigate(-1);
              }}
              size="large"
            >
              Cancel
            </Button>

            <Button
              className={className}
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={isSubmitting}
              id={`${id}-save-button`}
              size="large"
              variant="contained"
            >
              <RefreshIcon className={`${className}${isSubmitting ? '' : ' hidden'}`} />
              <span className={`${className}${isSubmitting ? ' hidden' : ''}`}>Save</span>
            </Button>
          </Footer>
        </Form>
      </article>
    </StyledForm>
  );
};
