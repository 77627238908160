import { gql } from '@apollo/client';

export const listPlanDetails = gql`
  query listPlanDetails($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      total
      page
      pageSize
      edges {
        altId
        products(input: { pageSize: 9999 }) {
          edges {
            altId
            credit {
              creditAmount
              creditReoccurrence
            }
            subscription {
              subscriptionFee
              subscriptionReoccurrence
            }
          }
        }
      }
    }
  }
`;
