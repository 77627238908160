import { gql } from '@apollo/client';

export const getModelOptions = gql`
  query getModelOptions {
    manufacturers: __type(name: "Manufacturer") {
      enumValues {
        value: name
        label: description
      }
    }
    unitTypes: __type(name: "UnitType") {
      enumValues {
        value: name
        label: description
      }
    }
    cableManufacturers: __type(name: "CableManufacturer") {
      enumValues {
        value: name
        label: description
      }
    }
    connectorTypes: __type(name: "ConnectorType") {
      enumValues {
        value: name
        label: description
      }
    }
    inputAcVoltage: __type(name: "InputAcVoltage") {
      enumValues {
        value: name
        label: description
      }
    }
    cableLength: __type(name: "CableLength") {
      enumValues {
        value: name
        label: description
      }
    }
    cableCooling: __type(name: "CableCooling") {
      enumValues {
        value: name
        label: description
      }
    }
    interfaceVersion: __type(name: "InterfaceVersion") {
      enumValues {
        value: name
        label: description
      }
    }
    networkCommunication: __type(name: "NetworkCommunication") {
      enumValues {
        value: name
        label: description
      }
    }
  }
`;
