import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, List, ListItem, IconButton, Chip, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Divider } from '@material-ui/core';
import config from 'src/config';
import { theme } from '@evgo/react-material-components';

export interface Item {
  id: number | string;
  label: string;
  name: string;
  description?: string;
  order: number;
}

interface Props {
  items?: Item[];
  activeItem?: Item;
  disabled?: boolean;
  onOrderChange: (val: Item[]) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: theme.palette.grey[100],
    overflow: 'hidden',
  },
  listHeader: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[600],
  },
  listHeaderItem: {
    paddingBottom: '20px',
    paddingTop: '20px',
    fontWeight: 800,
  },
  listItems: {
    background: config.colors.white,
    fontSize: '14px',
    paddingTop: '20px',
  },
  linkText: {
    color: config.colors.accentBlue,
  },
}));

const ReorderableList: React.FC<Props> = ({ items = [], onOrderChange, activeItem, disabled }) => {
  const classes = useStyles();
  const [itemList, setItemList] = useState(items);

  const moveUp = (id: number | string) => {
    const index = itemList.findIndex((item) => item.id === id);
    if (index > 0) {
      [itemList[index - 1], itemList[index]] = [itemList[index], itemList[index - 1]];
      setItemList([...itemList]);
      onOrderChange(itemList.map((item, itemIndex) => ({ ...item, order: itemIndex })));
    }
  };

  const moveDown = (id: number | string) => {
    const index = itemList.findIndex((item) => item.id === id);
    if (index < itemList.length - 1) {
      [itemList[index], itemList[index + 1]] = [itemList[index + 1], itemList[index]];
      setItemList([...itemList]);
      onOrderChange(itemList.map((item, itemIndex) => ({ ...item, order: itemIndex })));
    }
  };

  return (
    <Box
      className={classes.root}
      display="flex"
      width="100%"
      mb={2}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <List className={classes.root}>
        <ListItem divider={true} className={classes.listHeader}>
          <Grid container>
            <Grid item spacing={2} xs={1}>
              <Typography color="initial" className={classes.listHeaderItem}>
                Rank
              </Typography>
            </Grid>
            <Grid item spacing={2} xs={3}>
              <Typography color="initial" className={classes.listHeaderItem}>
                Plan Name
              </Typography>{' '}
            </Grid>
            <Grid item spacing={2} xs={3}>
              <Typography color="initial" className={classes.listHeaderItem}>
                Plan Display Name
              </Typography>
            </Grid>
            <Grid item spacing={2} xs={3}>
              <Typography color="initial" className={classes.listHeaderItem}>
                Description
              </Typography>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid item spacing={2} xs={1}></Grid>
          </Grid>
        </ListItem>
        {itemList.map((item, index) => (
          <ListItem key={index} divider={true} className={classes.listItems}>
            <Grid container>
              <Grid item xs={1}>
                <Chip label={index + 1} color={activeItem && activeItem?.id === item.id ? 'primary' : 'default'} />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" className={classes.linkText}>
                  <a target="_blank" href={`/plans/${item.id}`}>
                    {item.name}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="initial">
                  {item.label}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption" color="initial">
                  {item.description}
                </Typography>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="Move item up"
                  disabled={disabled || index === 0 ? true : false}
                  size="small"
                  key={index}
                  data-testid={`move-up-${item.id}`}
                  onClick={() => moveUp(item.id)}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  aria-label="Move item down"
                  key={index}
                  disabled={disabled || index === itemList.length - 1 ? true : false}
                  size="small"
                  onClick={() => moveDown(item.id)}
                  data-testid={`move-down-${item.id}`}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ReorderableList;
