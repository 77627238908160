/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

export const transformChargerModelValues = (values: any, altId: string): any => {
  const { evses, breakerRating, powerFactor, powerOutput } = values;
  let transformedEvses = [];
  if (evses && evses.length) {
    transformedEvses = evses.map((evse: any, index: number) => {
      const filteredConnectors = evse.connectors
        .filter((c: any) => !_.isEqual(_.omit(c, 'evseId'), { outputVoltageRange: '' }))
        .map((connector: any) => {
          const newConnector = connector;
          newConnector.lastMeterValue = Math.ceil(Math.random() * 10);
          newConnector.connectorTypeId = connector.connectorType;
          newConnector.format = 'CABLE';
          newConnector.connectorMaxCurrent = Number(connector.connectorMaxCurrent) || null;
          return _.omit(newConnector, ['connectorType', 'evseId']);
        });
      return { ...evse, connectors: filteredConnectors, evseId: `${index + 1}` };
    });
  }
  const numFields = {
    breakerRating: Number(breakerRating) || null,
    powerFactor: Number(powerFactor) || null,
    powerOutput: Number(powerOutput) || null,
  };
  const chargerModelInput = { ...values, ...numFields, evses: transformedEvses, ...(altId ? { altId } : {}) };
  return chargerModelInput;
};
