import { gql } from '@apollo/client';

export const listSalesforceProperties = gql`
  query listSalesforceProperties($input: ListSalesforcePropertiesInput) {
    listSalesforceProperties(input: $input) {
      edges {
        hid
        pid
        evcId
        propertyName
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
      }
      page
      pageSize
      total
    }
  }
`;
