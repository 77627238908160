import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Styled } from './styles';

export interface Props {
  total: number;
  loading: boolean;
  className?: string;
}

/**
 * Plans Header component
 */
export const PlansHeader: React.FC<Props> = (props) => {
  const id = _.kebabCase('PlansHeader');
  const className = id;
  const { total, loading } = props;

  const s = total === 1 ? '' : 's';
  let caption: JSX.Element | string = <br />;

  if (loading) caption = 'Loading ...';
  if (total) caption = `${total} Plan${s}`;

  return (
    <Styled className={className}>
      <section className={`${className} title`}>
        <Typography className={className} variant="h5" component="h1">
          Plan Management
        </Typography>
        <Typography className={`${className} subtitle`} variant="caption" id={`${id}-header-subtitle-card-total`}>
          {caption}
        </Typography>
      </section>
    </Styled>
  );
};
