import { useLazyQuery } from '@apollo/client';
import { Box, Card, Divider } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useDebounceFn } from 'ahooks';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Driver, Query } from 'src/@types';
import { listDrivers as LIST_DRIVERS } from 'src/apollo/queries/drivers';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import { isEmail } from 'src/lib/helpers';
import { useGetFirstUserHost } from 'src/lib/hooks';
import { DriversHeader } from '../DriversHeader';
import { DriversListFilters } from '../DriversListFilters';
import { Styled as StyledTable } from './styles';

export type Filters = {
  search: string;
  account_accountStatus: string[];
  driverStatusId: string[];
  account_billingAddress_administrativeArea: string[];
};

type ColumnDataProps = {
  data: Driver;
};

const AccountId: React.FC<ColumnDataProps> = ({ data }) => {
  const to = `/accounts/${data?.account?.altId}`;
  return (
    <TableLink to={to}>
      <p data-testid="AccountId">{data?.account?.id}</p>
    </TableLink>
  );
};

const BillingAddress: React.FC<ColumnDataProps> = ({ data }) => {
  const billingAddress = `${data?.account?.billingAddress?.address1 || ''} ${
    data?.account?.billingAddress?.locality ? data?.account?.billingAddress?.locality + ',' : ''
  }  
  ${data?.account?.billingAddress?.administrativeArea || ''}`;
  return <p data-testid="BillingAddress">{billingAddress}</p>;
};

const AccountType: React.FC<ColumnDataProps> = ({ data }) => {
  return data?.driverType === 'OWNER' ? <Done /> : null;
};

const DriverName: React.FC<ColumnDataProps> = ({ data }) => {
  const to = data?.driverType === 'OWNER' ? `/accounts/${data?.account?.altId}` : `/drivers/${data?.altId}`;
  const driverDisplayName =
    data?.firstName || data?.lastName
      ? `${data?.firstName ? data?.firstName : ''} ${data?.lastName ? data?.lastName : ''}`
      : 'N/A';
  return (
    <TableLink to={to}>
      <p data-testid="DriverName">{driverDisplayName}</p>
    </TableLink>
  );
};
const DriverStatus: React.FC<ColumnDataProps> = ({ data }) => {
  return <p>{data?.driverStatus?.columnValue}</p>;
};
const searchFields = ['email', 'firstName', 'lastName', 'accountVehicles_vin'];

const columns: Column[] = [
  { key: 'firstName', label: 'Driver Name', sortable: true, width: '20%', component: DriverName },
  {
    key: 'accountType',
    label: 'Account Owner',
    align: 'center',
    sortable: false,
    width: '20%',
    component: AccountType,
  },
  {
    key: 'account_id',
    label: 'Account Number',
    sortable: true,
    numeric: true,
    width: '10%',
    component: AccountId,
  },
  { key: 'account.accountStatus', label: 'Account Status', sortable: true, numeric: true, width: '10%' },
  {
    key: 'driverStatusId',
    label: 'Driver Status',
    sortable: true,
    numeric: true,
    width: '10%',
    component: DriverStatus,
  },
  { key: 'email', label: 'Email', sortable: true, numeric: true, width: '10%' },
  { key: 'account.billingAddress.address1', label: 'Address', sortable: true, width: '20%', component: BillingAddress },
];

export const DriversList: React.FC = () => {
  const driverOptions = {
    groups: [],
    billingPlan: 'All',
    status: 'All',
    issueType: 'All',
    state: 'All',
  };
  const host = useGetFirstUserHost();
  const [queryParams] = useSearchParams();
  const statusFilterParam = queryParams.get('statusFilter') ?? '';
  const [tableSortBy, setTableSortBy] = React.useState<string>('firstName');
  const [tableSortDirection, setTableSortDirection] = React.useState<Direction>(Direction.Asc);
  const [listDriversQuery, { data, loading, error }] = useLazyQuery<Query>(LIST_DRIVERS, {
    fetchPolicy: 'cache-and-network',
  });
  const [search, setTableSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilters] = useState<Filters>({
    search: '',
    account_accountStatus: [],
    driverStatusId: String(statusFilterParam) ? [String(statusFilterParam)] : [],
    account_billingAddress_administrativeArea: [],
  });
  const { edges } = data?.listDrivers || { edges: [] };

  useEffect(() => {
    const updatedSearch: Record<string, unknown> = {};
    const searchValue = filter.search.trim();
    if (searchValue.length) {
      if (!Number.isNaN(Number(searchValue))) {
        updatedSearch.account_id = { eq: Number(searchValue) };
      } else if (isEmail(searchValue)) {
        updatedSearch.email = { iLike: `%${searchValue}%` };
      } else {
        searchFields.forEach((field) => {
          updatedSearch[field] = { iLike: `%${searchValue}%` };
        });
      }
    }
    const updatedFilter: Record<string, unknown> = {};
    if (filter) {
      Object.keys(filter).forEach((criteria) => {
        if (filter[criteria as keyof Filters].length && criteria !== 'search')
          updatedFilter[criteria] = { in: filter[criteria as keyof Filters] };
      });
    }
    const updatedSort: Record<string, unknown> = {};
    if (tableSortBy) {
      const sortBy = tableSortBy.replace(/\./g, '_');
      updatedSort[sortBy] = tableSortDirection;
    }

    const variables = {
      driversInput: {
        hostId: host?.altId,
        pageSize,
        page,
        search: updatedSearch,
        filter: updatedFilter,
        sort: updatedSort,
        searchAndFilter: updatedFilter && Object.keys(updatedFilter).length > 0,
      },
    };

    listDriversQuery({
      variables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    // we deliberatley exclude `filter.search` here, and trigger via a seperate `search` state,
    // so that we can debounce while still updating the UI real time.
    filter.account_accountStatus,
    filter.account_billingAddress_administrativeArea,
    filter.driverStatusId,
    tableSortBy,
    tableSortDirection,
    host,
    page,
    pageSize,
  ]);

  const { run: debouncedOnFuzzySearchChange } = useDebounceFn(setTableSearch, { wait: 500 });

  return (
    <StyledTable data-testid="DriversList">
      <Box mx={4}>
        <DriversHeader {...driverOptions} data={data} loading={loading} />
        <Box my={4}>
          <Divider />
        </Box>
        <Card>
          <Box p={2}>
            <DriversListFilters
              filters={filter}
              totalResults={data?.listDrivers?.total || 0}
              onSearch={(value: string) => {
                setFilters({ ...filter, search: value });
                if (value.length === 0 || value.length >= 2) {
                  debouncedOnFuzzySearchChange(value);
                }
              }}
              onFilter={(type) => {
                setFilters(type);
              }}
            />
          </Box>
          <Table
            id="DriversTable"
            data-testid="drivers-table"
            columns={columns}
            data={edges || []}
            loading={loading}
            error={error}
            noDataMessage="There are no drivers matching these filters"
            pagination={{
              total: data?.listDrivers?.total || 0,
              page: data?.listDrivers?.page || 0,
              pageSize: data?.listDrivers?.pageSize || 0,
              rowsPerPageOptions: [10, 25, 100],
              onPageChange: (p: number) => setPage(p),
              onPageSizeChange: (pS: number) => setPageSize(pS),
            }}
            sorting={{
              sortBy: tableSortBy,
              sortDirection: tableSortDirection,
              onSortByChange: setTableSortBy,
              onSortDirectionChange: setTableSortDirection,
            }}
          />
        </Card>
      </Box>
    </StyledTable>
  );
};
