import { Divider, StatBlock, theme } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Account, AccountStatus as AccStatus } from 'src/@types';
import config from '../../../../config';
import { formatCurrency, titleCase } from '../../../../lib/helpers';
import { Styled as StyledPaper } from './styles';
import moment from 'moment-timezone';
export interface Props {
  selected: Account;
  className?: string;
}

export const AccountStatus: React.FC<Props> = (props) => {
  const className: Props['className'] = 'accountstatus-component';
  const { selected } = props;
  const driverStatus = _.get(selected, 'drivers.edges[0].driverStatus.columnText', 'Unknown');
  let customClass = className;
  let accountStatusColor = theme.palette.text.secondary;
  const driverStatusColor =
    _.get(selected, 'drivers.edges[0].driverStatus.columnText', 'Unknown') === 'Removed'
      ? theme.palette.error.main
      : '#44A047';

  if (props.className) customClass += ` ${props.className}`;
  if (selected && selected.accountStatus === AccStatus.Active) accountStatusColor = '#44A047';
  else if (selected && selected.accountStatus === AccStatus.Registered) accountStatusColor = '#44A047';
  else if (selected && selected.accountStatus === AccStatus.Suspended) accountStatusColor = theme.palette.error.main;

  return (
    <StyledPaper className={customClass} component="section">
      <header className={`${className} panel-summary`}>
        <Typography className={className} variant="h6" component="h2">
          Account Status
        </Typography>
        <Typography className={className} variant="caption">
          Last updated {moment(selected.updatedAt).fromNow()}
        </Typography>
      </header>

      <Divider />

      <div className={className}>
        <div data-testid="account-status">
          <StatBlock color={accountStatusColor} title="Account Status">
            {titleCase(selected && selected.accountStatus) || 'Unknown'}
          </StatBlock>
        </div>

        <Divider vertical />

        <StatBlock color={theme.palette.error.main} title="Issue(s)">
          2
        </StatBlock>

        <Divider vertical />
        <span data-testid="driver-status">
          <StatBlock color={driverStatusColor} title="Driver Status">
            {titleCase(driverStatus)}
          </StatBlock>
        </span>
        <Divider vertical />

        <StatBlock color={config.colors.accentBlue} title="Account Balance">
          {selected?.balance ? `$${formatCurrency(selected?.balance)}` : '$0.00'}
        </StatBlock>

        <Divider vertical />
        <span data-testid="enrolled-from">
          <StatBlock color={config.colors.accentBlue} title="Enrolled From">
            {selected?.enrolledOn ? moment(selected?.enrolledOn).format('MMM DD/YY') : 'Unknown'}
          </StatBlock>
        </span>
      </div>
    </StyledPaper>
  );
};
