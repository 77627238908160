import { Button } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Styled } from './styles';

export interface Props {
  className?: string;
  links: {
    to: string;
    label: string;
  }[];
}

export const ViewNavigation: React.FC<Props> = (props) => {
  const className = `vienavigation-component ${props.className}`;

  return (
    <Styled className={className}>
      {props.links?.map((link, i) => (
        <Button key={i} className={className} to={link.to} component={NavLink} variant="outlined" color="primary">
          {link.label}
        </Button>
      ))}
    </Styled>
  );
};
