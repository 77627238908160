import { gql } from '@apollo/client';

export const createContract = gql`
  mutation createContract($contractInput: CreateContractInput!) {
    createContract(input: $contractInput) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
