import { gql } from '@apollo/client';

export const getCharger = gql`
  query getChargerExtend($chargerInput: GetChargerInput) {
    getCharger(input: $chargerInput) {
      altId
      chargerName
      serialNumber
      chargerId
      setTariffSynced
      displayTariffSynced
      tariffSyncStatus
      chargerModel {
        modelName
        firmwareVersion
        breakerRating
        simultaneousChargeSupported
      }
      site {
        altId
        siteName
      }
      evses {
        edges {
          connectors {
            edges {
              altId
              connectorType {
                columnText
              }
              cableLength
              connectorMaxCurrent
              maxConnectorPower
              energyUtilization
              connectorStatusConstraint {
                columnValue
                columnText
              }
              outputVoltageRange
              reservable
            }
          }
          evseId
        }
      }
    }
  }
`;
