import { Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Styled } from './styles';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  message?: string;
}

export const Footer: React.FC<Props> = ({ children, className: parentClass, id: parentId, message }) => {
  const className = 'footer-component';
  let customClass = className;

  const isLargeScreen = useMediaQuery('(min-width:1400px)');

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <Styled className={customClass} id={parentId} isLargeScreen={isLargeScreen}>
      <Typography className={className} component="span">
        {message}
      </Typography>
      {children}
    </Styled>
  );
};
