import { useState, useCallback } from 'react';

export type UsePaginationParams = {
  page?: number;
  pageSize?: number;
};

export function usePagination(params?: UsePaginationParams) {
  const [pagination, setPagination] = useState(() => ({
    page: params?.page || 0,
    pageSize: params?.pageSize || 10,
  }));

  const onPageChange = useCallback((page: number) => setPagination((state) => ({ ...state, page })), []);

  const onPageSizeChange = useCallback((pageSize: number) => setPagination({ pageSize, page: 0 }), []);

  return {
    page: pagination.page,
    pageSize: pagination.pageSize,
    onPageChange,
    onPageSizeChange,
  };
}
