import React from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';

const Section = ({ section }: { section: string }) => {
  // @ts-ignore
  const value = extendPlusTheme.palette[section];
  const groups = _.chunk(Object.keys(value), 6) as string[][];

  return (
    <Box py={1} key={section}>
      <Box pb={1}>
        <Typography variant="h6">{section}</Typography>
      </Box>
      {groups.map((keys, index) => (
        <div key={index}>
          <Box display="flex">
            {keys.map((key, i) => (
              <Box key={key} pl={i > 0 ? 2 : 0}>
                <Box component="div" width={150} height={50} bgcolor={value[key]} border={1} />
                <Box display="flex" justifyContent="space-between" pt={0.4} pb={2}>
                  <Box component="span" fontSize={14}>
                    <b>{key}</b>
                  </Box>
                  <Box component="span" fontSize={12}>
                    {value[key]}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      ))}
    </Box>
  );
};

export const ThemingColors: React.FC = () => {
  const keys = [
    'success',
    'error',
    'warning',
    'info',
    'accent',
    'background',
    'text',
    'sequential',
    'categorical',
    'primary',
    'grey',
  ];

  return (
    <div>
      {keys.map((section) => (
        <Section section={section} key={section} />
      ))}
    </div>
  );
};
