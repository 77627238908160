/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, typography, spacing } = theme;

export const Styled = styled.header`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: calc(100% + ${spacing(12)}px);
  margin-bottom: ${spacing() * 4}px;
  padding-bottom: ${spacing(4)}px;

  section {
    display: flex;
    padding-top: ${spacing(1.5)}px;

    &.title {
      flex: 1;
      flex-direction: column;
      align-items: flex-start;

      .subtitle {
        margin-top: 4px;
        font-size: 12px;
        color: #627d87;
      }

      h5 {
        font-weight: 200;
        font-size: ${typography.fontSize + 8};
      }
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    width: calc(100% - ${spacing(12.25)}px);
    border-bottom: 1px solid ${palette.divider};
    content: '';
  }
`;
