/**
 * A helper to sum time strings in the format -> '00:00:10' + '00:01:10' = '00:01:20'
 */
export const sumTimeStrings = (timeString1: string, timeString2: string) => {
  let timeSummed = '00:00:00';

  if (timeString1 && timeString2) {
    const splitTime1 = timeString1.split(':');
    const splitTime2 = timeString2.split(':');
    const dt = new Date(0, 0, 0, 0, 0, 0, 0);
    dt.setHours(dt.getHours() + parseInt(splitTime1[0], 10));
    dt.setHours(dt.getHours() + parseInt(splitTime2[0], 10));
    dt.setMinutes(dt.getMinutes() + parseInt(splitTime1[1], 10));
    dt.setMinutes(dt.getMinutes() + parseInt(splitTime2[1], 10));
    dt.setSeconds(dt.getSeconds() + parseInt(splitTime1[2], 10));
    dt.setSeconds(dt.getSeconds() + parseInt(splitTime2[2], 10));
    timeSummed = `${String(dt.getHours()).padStart(2, '0')}:${String(dt.getMinutes()).padStart(2, '0')}:${String(
      dt.getSeconds(),
    ).padStart(2, '0')}`;
  } else {
    timeSummed = timeString1 || timeString2 || '00:00:00';
  }

  return timeSummed;
};
