import React from 'react';
import { Typography } from '@material-ui/core';

export type Props = {
  className?: string;
  id?: string;
};

/**
 * It returns a Title component that renders a <h2> element
 * @param {Props} - properties
 * @example <Title>Your title here</Title>
 * @returns A styled component for title
 */
export const Title: React.FC<Props> = ({ id: parentId, children }) => {
  return (
    <Typography variant="h4" data-testid="page-title" id={parentId}>
      {children}
    </Typography>
  );
};
