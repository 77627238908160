import { gql } from '@apollo/client';

export const ChargersWithMeta = gql`
  fragment ChargersWithMeta on ChargersWithMeta {
    edges {
      altId
      chargerName
      chargerStatus
      cid
      fieldStationStatus
      displayTariffSynced
      chargerModel {
        altId
        modelName
      }
      evses {
        edges {
          connectors {
            edges {
              chargingLevel
              connectorStatusConstraint {
                columnValue
                columnText
              }
              reservable
            }
          }
        }
      }
      setTariffSynced
      site {
        altId
        siteName
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
        latitude
        longitude
        property {
          propertyName
        }
      }
      tariffSyncStatus
    }
    page
    pageSize
    total
  }
`;
