import { gql } from '@apollo/client';

export const validateTag = gql`
  query validateTag($input: ValidateTagInput, $chargersInput: ListChargersInput) {
    validateTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
              host {
                altId
              }
            }
          }
        }
        page
        pageSize
        sort {
          # altId
          cid
          chargerName
          fieldStationStatus
          hostName
          propertyName
        }
        total
      }
    }
  }
`;
