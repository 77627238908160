import { theme } from '@evgo/react-material-components';
import { Box, FormControl, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const StyledFormControl = styled(FormControl)`
  min-height: ${spacing(10)}px;
  width: ${spacing(26)}px;

  label {
    font-size: ${theme.typography.fontSize}px;
    transform: scale(1);
  }

  .select {
    font-size: ${theme.typography.fontSize}px;
    margin-top: ${spacing(2.5)}px;
    cursor: pointer;
    div {
      align-items: center;
      display: flex;
      padding-right: ${spacing(2.5)}px;
    }
  }

  .clear-options {
    margin-top: ${spacing(0.5)}px;
    cursor: pointer;
  }
`;

export const StyledLoading = styled(Box)`
  position: absolute;
  top: 26px;
  background-color: ${theme.palette.common.white};
`;

export const StyledMenuItem = styled(MenuItem)`
  opacity: 1;
  cursor: default !important;
  pointer-events: auto !important;

  input {
    padding: ${spacing(2)}px;
    font-size: ${theme.typography.fontSize}px;
  }

  svg {
    padding-right: ${spacing(1)}px;
    color: ${theme.palette.grey[600]};
  }
`;
