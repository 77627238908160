import { ChangeEvent, Dispatch } from 'react';
import { theme } from '@evgo/react-material-components';
import { InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

const { spacing } = theme;

export type ReportFilterProps = {
  state: {
    initial: {
      timePeriod: Required<number>;
    };
    filter: {
      timePeriod: Required<number>;
    };
  };
  dispatch: Dispatch<{
    type: 'setTimePeriod';
    timePeriod: number | unknown;
  }>;
};

export function ReportFilter({ state, dispatch }: ReportFilterProps) {
  const { track } = useAnalytics();
  const timePeriodFilterOptions = [
    {
      value: 1,
      label: 'Today',
    },
    {
      value: 7,
      label: 'Prior 7 Days',
    },
    {
      value: 30,
      label: 'Prior 30 Days',
    },
    {
      value: 90,
      label: 'Prior 3 Months',
    },
    {
      value: 180,
      label: 'Prior 6 Months',
    },
  ];

  return (
    <>
      <InputLabel className="label">Dashboard graph timeframe</InputLabel>
      <Select
        className="select"
        onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
          dispatch({ type: 'setTimePeriod', timePeriod: e.target.value });
          track(events.dashboardView.CHANGED_TIMEFRAME, {
            timeframe: timePeriodFilterOptions.find((option) => option.value == e.target.value)?.label,
          });
        }}
        value={state.filter.timePeriod}
        data-testid="time-period-filter-select"
        MenuProps={{
          variant: 'menu',
          getContentAnchorEl: null,
        }}
        style={{ marginBottom: `${spacing(4)}px`, width: `${spacing(26)}px` }}
      >
        {timePeriodFilterOptions?.map((timeperiod, i) => {
          return (
            <MenuItem data-testid="time-period-filter-option" key={timeperiod.value} value={timeperiod.value}>
              <ListItemText
                data-testid={`time-period-filter-option-${i}`}
                primary={timeperiod.label}
                style={{ fontSize: 'small' }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
