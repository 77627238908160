import { gql } from '@apollo/client';

export const createExtendHost = gql`
  mutation createExtendHost($input: CreateExtendHostInput!) {
    createExtendHost(input: $input) {
      altId
      createdAt
      host {
        altId
        hostName
        hid
        status
        vendorId
      }
    }
  }
`;
