import { gql } from '@apollo/client';
import { FullChargerModel } from './FullChargerModel';

export const createChargerModel = gql`
  mutation createChargerModel($modelInput: CreateChargerModelInput) {
    createChargerModel(input: $modelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModel}
`;
