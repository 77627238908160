import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${`0 calc(${spacing() * 6}px + var(--safe-area-inset-right)) ${spacing() * 12}px ${spacing() * 6}px`};
`;
