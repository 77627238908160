import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { palette, spacing, typography, text } = theme;

export const Styled = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  width: calc(100% + ${spacing(12)}px);
  box-sizing: border-box;
  z-index: 999;
  background-color: ${palette.primary[50]};
  margin: 0 ${spacing(-6)}px;
  margin: ${`0 calc(${spacing(-6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    -6,
  )}px + var(--safe-area-inset-left))`};
  padding: ${`${spacing(4)}px ${spacing(8)}px`};

  nav {
    margin-top: -${spacing(3)}px;

    a {
      text-transform: none;
      font-weight: 200;
      color: ${palette.text.secondary};
    }

    svg {
      margin-right: ${spacing()}px;
      font-size: ${typography.fontSize}px;
      color: ${palette.secondary.main};
    }
  }

  > div {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;

    h1 {
      display: flex;
      align-content: center;
      align-items: center;

      svg {
        margin-right: ${spacing()}px;
        color: ${palette.secondary.main};
      }
    }

    > div {
      margin: 0 ${spacing()}px;

      label {
        color: ${text.labelEmphasis.medium};
        font-size: ${typography.fontSize * (7 / 8)}px;
      }

      > div {
        display: flex;
        align-content: center;
        align-items: center;
        margin-top: -${spacing()}px;

        p {
          color: ${palette.text.secondary};
          font-size: ${typography.fontSize}px;
        }

        svg {
          font-size: ${typography.fontSize}px;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:not(:first-of-type) {
      margin-top: ${spacing()}px;
    }

    &:last-of-type {
      margin-bottom: -${spacing(3)}px;
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    width: calc(100% - ${spacing(16)}px);
    border-bottom: 1px solid ${palette.divider};
    content: '';
  }
`;
