export type TableData = {
  altId: string;
  tagName: string;
  description: string;
  chargers: { total: number };
};

export const TableComponentFactory = (): TableData[] => {
  return [
    {
      altId: 'ce0e49b9-69ff-4312-93a1-6e4ee0166150',
      tagName: 'Radical',
      description: 'really cool tag',
      chargers: {
        total: 5,
      },
    },
    {
      altId: 'f7798352-99b7-414a-b483-0f3be8959bf1',
      tagName: 'test sg',
      description: 'testing console errors',
      chargers: {
        total: 16,
      },
    },
    {
      altId: '10ef48b9-5d26-4202-af7c-99358eeaa262',
      tagName: 'pricing name [updated 3:35 PM]',
      description: 'pricing description',
      chargers: {
        total: 7,
      },
    },
    {
      altId: 'a21e9411-9bf9-4435-822d-bc8e3be92e1a',
      tagName: 'Pricing schema',
      description: 'Description of pricing',
      chargers: {
        total: 2,
      },
    },
    {
      altId: 'c40e6461-3d30-4bb5-a2a3-28d4361f18dc',
      tagName: 'pricing namef',
      description: 'pricing description',
      chargers: {
        total: 45,
      },
    },
    {
      altId: 'de0563b2-3d49-44f5-8b76-26cd7ab138bc',
      tagName: 'VB Tag',
      description: '',
      chargers: {
        total: 66,
      },
    },
    {
      altId: 'fc6a7fc2-5229-47ac-a464-23498a00af63',
      tagName: 'ZUB04',
      description: '',
      chargers: {
        total: 55,
      },
    },
    {
      altId: 'a3e57557-80ec-4797-b269-458e663a94c7',
      tagName: 'T9YTR42',
      description: '',
      chargers: {
        total: 90,
      },
    },
    {
      altId: '645f8a4c-682e-473e-87a0-bab3a650ac36',
      tagName: 'VB Tag2',
      description: 'desc',
      chargers: {
        total: 2,
      },
    },
    {
      altId: 'acf49428-dc55-4889-8787-760562943da5',
      tagName: 'VB Tag3',
      description: '',
      chargers: {
        total: 480,
      },
    },
  ];
};
