import { gql } from '@apollo/client';

export const listWarehouseOptions = gql`
  query listWarehouseOptions {
    listWarehouseOptions {
      altId
      warehouseName
      address1
      address2
      address3
      address4
      locality
      administrativeArea
      postalCode
      country
    }
  }
`;
