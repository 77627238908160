import { Grid, Button, Box, Typography, TextField, Card } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Divider } from '@evgo/react-material-components';
import { useFormikContext } from 'formik';
import { AccountStatus } from 'src/@types';

export type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  altId: string;
  accountStatus: AccountStatus;
};

export const AccountCreateForm: React.FC = () => {
  const { handleChange, handleBlur, errors, touched, values, isValid, submitForm, isSubmitting } =
    useFormikContext<FormValues>() || {};
  const className = 'account-create-form';
  const id = className;

  return (
    <Card className="form-card" data-test-id="AccountCreateForm">
      <Box p={2} pl={4}>
        <Typography className={className} variant="h6" component="h2">
          Create Account
        </Typography>
      </Box>
      <Divider />
      <Box my={2} px={4}>
        <Typography className="form-title" variant="subtitle1" component="h3">
          General Information
        </Typography>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={6}>
            <TextField
              error={touched?.firstName && errors?.firstName ? true : false}
              helperText={touched?.firstName && errors?.firstName}
              id={`${id}-first-name`}
              name="firstName"
              variant="filled"
              fullWidth={true}
              label="First Name *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.firstName}
              data-testid={`${id}-first-name`}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={touched?.lastName && errors?.lastName ? true : false}
              helperText={touched?.lastName && errors?.lastName}
              id={`${id}-last-name`}
              name="lastName"
              label="Last Name *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.lastName}
              data-testid={`${id}-last-name`}
              variant="filled"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={touched?.email && errors?.email ? true : false}
              helperText={errors?.email}
              id={`${id}-email`}
              name="email"
              label="E-mail Address *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.email}
              data-testid={`${id}-email`}
              variant="filled"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={touched?.phone && errors?.phone ? true : false}
              helperText={touched?.phone && errors?.phone}
              id={`${id}-phone`}
              name="phone"
              label="Phone Number *"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.phone}
              data-testid={`${id}-phone`}
              variant="filled"
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs={1} sm={1}>
            <Box display="flex" justifyContent="flex-end" my={4} mr={4}>
              <Link to={`/drivers/list`}>
                <Button variant="text" color="secondary">
                  Cancel
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Box display="flex" justifyContent="flex-end" my={4}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!touched || !isValid || isSubmitting}
                onClick={() => submitForm()}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
