import styled from 'styled-components';
import { useMemo } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { useGetSessionsConsumptionForExtend } from 'src/lib/hooks/useGetSessionsConsumptionForExtend/';
import { SessionAggregation } from 'src/@types';
import { Card, CardHeader } from 'src/components/shared/Card';
import { BarChart } from 'src/components/shared/UtilizationBarGraph';
import { getGraphData } from './helpers';
import { dateRange as weekDateRange } from '../helpers';

type SessionByWeekdayGraphProps = {
  timePeriod: number;
  timeFrame: string;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

export const SessionByWeekdayGraph = ({ timePeriod, timeFrame }: SessionByWeekdayGraphProps) => {
  const dateRange = weekDateRange(timePeriod);

  const input = {
    aggregateBy: SessionAggregation.Day,
    ...dateRange,
  };

  const { data, loading } = useGetSessionsConsumptionForExtend({ input });

  const graphData = useMemo(() => getGraphData(data), [data]);

  return (
    <StyledCard data-testid="sessions-by-week">
      <CardHeader
        title="Average Sessions by Day of Week"
        subtitle={
          <Box display="flex" flexDirection="column">
            Average number of sessions by day of week for {timeFrame}
          </Box>
        }
      />

      {loading ? (
        <Box display="flex" justifyContent="center" data-testid="session-by-week-graph-loading">
          <CircularProgress />
        </Box>
      ) : (
        <BarChart
          id="session-by-week-graph"
          data-testid="session-by-week-graph"
          className="session-by-week-graph"
          data={graphData}
          color={'#3F657C'}
          height={400}
          verticalCaptionText="# of sessions"
        />
      )}
    </StyledCard>
  );
};
