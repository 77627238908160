import styled from 'styled-components';
import { NavLink as BaseNavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import config from 'src/config';

export const NestedListItem = styled(ListItem)`
  &.MuiListItem-root {
    border-left: 2px solid transparent;
  }

  &[role='button']:hover,
  &.active {
    border-left-color: ${config.brandColor};
  }
`;

export const NavLink = styled(BaseNavLink)`
  &.MuiListItem-root {
    border-left: 2px solid transparent;
  }

  &[role='button']:hover,
  &.active {
    background-color: rgba(0, 0, 0, 0.04);
    border-left-color: ${config.brandColor};
  }
`;

export const SubItemText = styled(ListItemText)`
  padding-left: 24px;

  .MuiTypography-body1 {
    font-size: 14px;
  }
`;

export const IconContainer = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 24px;
  }

  svg {
    width: 18px;
    height: 18px;
    color: black;
  }
`;
