import React from 'react';
import { Snackbar, SnackbarProps } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const StyledSnackbar = styled(Snackbar)`
  margin-right: ${theme.spacing(7)}px;
  margin-left: ${theme.spacing(7)}px;
`;

type ScreenSnackbarProps = Omit<SnackbarProps, 'children'> & {
  severity?: AlertProps['severity'];
  alertProps?: AlertProps;
  children?: React.ReactNode;
  closable?: boolean;
  closeOnClickAway?: boolean;
};

export default function ScreenSnackbar({
  children,
  severity = 'info',
  alertProps = {},
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  closable = true,
  closeOnClickAway = false,
}: ScreenSnackbarProps) {
  const [isOpen, setIsOpen] = React.useState(true);
  const handleClose = (_: unknown, reason?: string) => {
    if (reason === 'clickaway' && !closeOnClickAway) return;
    setIsOpen(false);
  };
  const setClosable = closable ? { onClose: handleClose } : {};
  return (
    <StyledSnackbar
      open={isOpen}
      anchorOrigin={anchorOrigin}
      classes={{
        anchorOriginTopCenter: 'topCenter',
      }}
      {...setClosable}
    >
      <Alert severity={severity} variant="filled" {...setClosable} {...alertProps}>
        {children}
      </Alert>
    </StyledSnackbar>
  );
}
