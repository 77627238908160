import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing, text } = theme;
const { colors } = config;

export const Styled = styled(Paper)`
  width: calc(100% - ${spacing(4)}px);
  .panel-summary {
    display: flex;
    flex-direction: column;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};
  }

  div {
    display: flex;

    .actions {
      flex-basis: 100%;
      background: ${palette.secondary[700]};

      button {
        &.active {
          color: ${text.primaryEmphasis.high};
        }

        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }
    }

    .status {
      flex-basis: 100%;
      padding: ${`${spacing(2.5)}px ${spacing(10)}px`};
      label {
        padding: ${`0px ${spacing(2)}px`};
      }
    }

    .buttonContainer {
      .cancel {
        color: ${colors.scienceBlue};
      }
      button {
        padding: ${`${spacing(0.6)}px ${spacing(3)}px`};
        margin: ${`0px ${spacing(1)}px`};
      }
      margin: ${`${spacing(0.5)}px ${spacing(2)}px`};
      width: 100%;
      justify-content: flex-end;
      display: flex;
      align-content: center;
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  h5 {
    font-size: 16px;
    color: ${colors.sherpaBlue};
  }

  span {
    color: ${palette.text.secondary};
  }
`;
