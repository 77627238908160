import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const {
  palette,
  shape: { borderRadius },
  spacing,
} = theme;

export const Styled = styled.section`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${palette.text.disabled};
  border-radius: ${borderRadius}px;
  margin: ${`${spacing(2)}px ${spacing(3) / 2}px 0`};
  padding: ${`0 ${spacing(3) / 2}px ${spacing(3)}px`};

  table {
    td,
    th {
      border-bottom: none;
      padding: ${`${spacing(2.25)}px ${spacing(1.25)}px 0 ${spacing()}px`};

      h3 {
        display: flex;
        color: ${palette.text.secondary};
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: ${spacing(2)}px;
      }
    }

    td {
      padding: ${`${spacing(0.5)}px ${spacing()}px`};
      font-size: 16px;

      > div {
        width: 100%;
        margin-bottom: 0px;
      }
    }

    th {
      &.remove-button {
        width: 1%;
      }
    }

    .select {
      margin-bottom: 0;
    }
  }

  button {
    color: ${palette.text.secondary};

    .add-button-icon {
      margin-right: ${spacing(0.5)}px;
    }

    &.disabled {
      opacity: 38%;
    }
  }
`;
