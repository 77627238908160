import { theme } from '@evgo/react-material-components';
import { Form } from 'formik';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled(Form)`
  article {
    display: flex;

    > div {
      width: 100%;

      &:first-of-type {
        align-self: start;
      }

      > div {
        label {
          margin-left: 0;
        }

        &.full {
          width: calc(100% - 16px);
        }

        &.half {
          width: calc(50% - 16px);
        }

        &.disabled {
          pointer-events: none;
        }
      }

      > * {
        margin: 0 ${spacing()}px;
        margin-bottom: ${spacing(2)}px;
      }
    }
  }
`;
