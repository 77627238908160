import { theme } from '@evgo/react-material-components';
import { colors } from '@material-ui/core';
import styled from 'styled-components';

export const Styled = styled.div`
  .MuiAlert-root {
    width: 65%;
  }

  div {
    border-radius: 0;

    button {
      svg {
        fill: ${theme.palette.common.white};
      }
    }

    &.error {
      background-color: ${theme.palette.error.dark};
    }

    &.info {
      background-color: ${theme.palette.secondary.main};
    }

    &.success {
      background-color: ${colors.green[600]};
    }

    &.warning {
      background-color: ${colors.amber[700]};
    }

    &.system {
      background-color: ${theme.palette.grey.A400};
      color: ${theme.palette.common.white};
    }
  }
`;
