import { gql } from '@apollo/client';

export const updateSiteForExtend = gql`
  mutation updateSiteForExtend($siteInput: UpdateSiteForExtendInput) {
    updateSiteForExtend(input: $siteInput) {
      altId
      directions
    }
  }
`;
