import { gql } from '@apollo/client';

export const listSitesForCharger = gql`
  query listSitesForCharger($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        administrativeArea
        altId
        country
        hoursOfOperation {
          custom
          from
          to
          monday {
            closed
            timeslots {
              from
              to
            }
          }
          tuesday {
            closed
            timeslots {
              from
              to
            }
          }
          wednesday {
            closed
            timeslots {
              from
              to
            }
          }
          thursday {
            closed
            timeslots {
              from
              to
            }
          }
          friday {
            closed
            timeslots {
              from
              to
            }
          }
          saturday {
            closed
            timeslots {
              from
              to
            }
          }
          sunday {
            closed
            timeslots {
              from
              to
            }
          }
        }
        latitude
        locality
        longitude
        postalCode
        property {
          altId
          host {
            altId
            hostName
          }
        }
        sid
        siteName
        siteStatusId
      }
    }
  }
`;
