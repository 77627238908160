import { gql } from '@apollo/client';

export const listTariffOptions = gql`
  query listTariffOptions {
    listConnectorOptions: listVehicleConnectors(input: { pageSize: 9999 }) {
      edges {
        label: name
        value: id
      }
    }

    listRateTypeOptions: __type(name: "TariffRateType") {
      edges: enumValues {
        label: description
        value: name
      }
    }

    # listRateTypeOptions: listFalconConstraints(input: {
    #   filter: {
    #     tableName: {
    #       eq: "tariff_rates"
    #     },
    #     columnName: {
    #       eq: "rate_type"
    #     }
    #   },
    #   sort: {
    #     columnText: ASC
    #   }
    # }) {
    #   edges {
    #     label: columnText
    #     value: id
    #   }
    # }
  }
`;
