import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing, text } = theme;
const { colors } = config;

export const Styled = styled(Paper)`
  margin: 0;

  div {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .actions {
      flex-basis: 100%;
      background: ${colors.accentBlue};
      border-radius: 0;

      button {
        color: ${text.primaryEmphasis.high};
        border-radius: 0;
        padding-left: ${spacing()}px;
        width: 100%;
        justify-content: start;

        &.selected {
          background-color: ${palette.secondary[900]};
        }

        &.disabled {
          opacity: 0.5;
        }

        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }
    }
  }
`;
