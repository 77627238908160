import * as Yup from 'yup';

export default Yup.object().shape({
  planId: Yup.string().required('Required'),
  statusId: Yup.number().required('Required'),
  startDate: Yup.date().min('0000-01-01', 'Invalid date').max('9999-12-31', 'Invalid date').required('Invalid date'),
  endDate: Yup.date()
    .min('0000-01-01', 'Invalid date')
    .max('9999-12-31', 'Invalid date')
    .when('startDate', (val, schema) =>
      val
        ? schema.test('validateDateRange', 'End date must not be before start date', (value = '') => val <= value)
        : schema,
    )
    .required('Invalid date'),
});
