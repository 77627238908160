/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

/**
 * Removes __typename from all metadata search sub objects
 */
export const sanitizeSearch = (search: any, searchFields: string[]): any => {
  if (!_.isEmpty(search) && !_.isEmpty(search[searchFields[0]])) {
    const newSearch = _.omit(search, '__typename');

    Object.keys(newSearch).forEach((key) => {
      newSearch[key] = _.omit(search[key], '__typename');
    });

    return newSearch;
  }

  return null;
};
