interface Params {
  from: number;
  to: number;
  count: number;
}

/**
 * A helper to ID the material table pagination label
 */
export const labelDisplayedRows =
  (id: string) =>
  ({ from, to, count }: Params): JSX.Element =>
    <span id={id}>{`${from}-${to} of ${count}`}</span>;
