import { useLazyQuery } from '@apollo/client';
import { Box, Card, Divider, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Query, Account, SessionV2, TableSortType } from 'src/@types';
import { ApiIcon, BoltIcon } from 'src/components/svgs';
import { Column, Table, usePagination, Direction } from 'src/components/shared/Table';
import {
  Error as ErrorIcon,
  PhoneIphone,
  EvStation as EvStationIcon,
  DirectionsCar as DirectionsCarIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { Styled as StyledTable } from './styles';
import { listSessionsV2 as LIST_SESSIONS } from 'src/apollo/queries/sessions';
import moment from 'moment';
import { camelToSnakeCase } from 'src/lib/helpers/camelToSnakeCase';
import { startOfDay, endOfDay } from 'date-fns';
import { formatDuration } from 'src/lib/helpers/formatDuration';
import { ChargeLogFilters } from './Filters';
import { createClientTableFilters } from 'src/lib/hooks/useClientTableFilters';
import { TableFilters, TableFilterValue } from 'src/@types/client';
export interface ChargeLogFilterOptions {
  startDate?: Date;
  endDate?: Date;
  corrupted?: boolean;
  plans?: PlanOption[];
  drivers?: DriverOption[];
  vendorId?: string;
  callback?: () => void;
}

export interface Props {
  account?: Partial<Account>;
}

export type PlanOption = {
  label: string;
  value: string | number;
};

export type DriverOption = {
  label: string;
  value: string | number;
};

type ColumnDataProps = {
  data: SessionV2;
};

const Amount: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <Box>
      <p data-testid="Amount">{data?.transaction?.amount ? `$${data?.transaction?.amount}` : 'NA'}</p>
    </Box>
  );
};
const ChargerName: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <Box>
      <p data-testid="ChargerName">
        {data?.connector?.evse?.charger?.chargerName ? (
          <a target="_blank" href={`/chargers/${data?.connector?.evse?.charger?.chargerName}/`}>
            {data?.connector?.evse?.charger?.chargerName}
          </a>
        ) : (
          'NA'
        )}
      </p>
    </Box>
  );
};
const StatusIcon: React.FC<ColumnDataProps> = ({ data }) => {
  let status = null;
  if (data?.corrupted) {
    status = (
      <p>
        <ErrorIcon color="error" />
      </p>
    );
  } else if (!data?.endTime) {
    status = (
      <p>
        <BoltIcon style={{ color: '#AFBE00' }} />
      </p>
    );
  }
  return status;
};
const StartSource: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <p data-testid="StartSource">
      {data?.startSource?.columnValue === 'CHARGER' ? <EvStationIcon /> : null}
      {data?.startSource?.columnValue === 'OPERATOR' ? <PersonIcon /> : null}
      {data?.startSource?.columnValue === 'API' ? <ApiIcon /> : null}
      {data?.startSource?.columnValue === 'RFID' ? <PhoneIphone /> : null}
      {data?.startSource?.columnValue === 'AUTOCHARGE' ? <DirectionsCarIcon /> : null}
    </p>
  );
};
const StartTime: React.FC<ColumnDataProps> = ({ data }) => {
  return data?.startTime ? <p data-testid="StartTime">{moment(data?.startTime).format('MM/DD/YYYY')}</p> : null;
};
const EndTime: React.FC<ColumnDataProps> = ({ data }) => {
  return <p data-testid="EndTime">{data?.endTime ? moment(data?.endTime).format('MM/DD/YYYY') : 'NA'}</p>;
};
const TotalBilledTime: React.FC<ColumnDataProps> = ({ data }) => {
  const totalBilled = data?.endTime
    ? formatDuration(moment.duration(moment(data?.endTime).diff(data?.startTime)).asSeconds())
    : 'NA';
  return <p data-testid="TotalBilledTime">{totalBilled}</p>;
};
const PlanName: React.FC<ColumnDataProps> = ({ data }) => {
  return data.plan?.planName ? (
    <a target="_blank" href={`/plans/${data.plan?.altId}/`}>
      {data.plan?.planName}
    </a>
  ) : (
    <p>NA</p>
  );
};
const EnergyDelivered: React.FC<ColumnDataProps> = ({ data }) => {
  const totalKWH = (data?.energyDelivered || 0) / 1000;
  return <p data-testid="PlanName">{totalKWH >= 0 ? `${totalKWH} kWh` : 'NA'}</p>;
};
const columns: Column[] = [
  {
    key: 'session.corrupted',
    label: '',
    align: 'center',
    sortable: false,
    width: '5%',
    component: StatusIcon,
  },
  {
    key: 'vendorId',
    label: 'Session ID',
    sortName: 'id',
    align: 'left',
    sortable: true,
    width: '10%',
  },
  {
    key: 'connector.evse.charger.chargerName',
    label: 'Charger',
    align: 'left',
    sortable: false,
    width: '15%',
    component: ChargerName,
  },
  {
    key: 'type.startSource',
    label: 'Start Source',
    align: 'center',
    sortable: false,
    width: '10%',
    component: StartSource,
  },
  {
    key: 'session.startTime',
    label: 'Start Date',
    align: 'center',
    sortable: true,
    sortName: 'startDate',
    width: '10%',
    component: StartTime,
  },
  {
    key: 'session.endTime',
    label: 'End Date',
    align: 'center',
    sortable: true,
    sortName: 'endDate',
    width: '10%',
    component: EndTime,
  },
  {
    key: 'session.endTime',
    label: 'Total Time',
    align: 'center',
    sortable: false,
    width: '10%',
    component: TotalBilledTime,
  },
  {
    key: 'session.energyDelivered',
    sortName: 'kwh',
    label: 'Total kWh',
    align: 'center',
    sortable: true,
    width: '10%',
    component: EnergyDelivered,
  },
  {
    key: 'session.plan.planName',
    label: 'Billing Plan',
    align: 'left',
    sortable: false,
    width: '15%',
    component: PlanName,
  },
  {
    key: 'transaction.amount',
    label: 'Total Cost',
    align: 'left',
    sortable: false,
    sortName: 'amount',
    width: '10%',
    component: Amount,
  },
];
export interface TableComponentFilters extends TableFilters<Record<string, TableFilterValue>> {
  startDate: Date;
  endDate: Date;
  corrupted?: boolean;
  plans?: PlanOption[];
  drivers?: DriverOption[];
  sortBy: string;
  sortDirection: Direction;
  page?: number;
  vendorId?: string;
}

const useTableComponentFilters = createClientTableFilters<TableComponentFilters>({
  queryName: 'sessionsV2',
  defaultFilters: {
    startDate: moment().subtract(1, 'year').toDate(),
    endDate: moment().toDate(),
    sortBy: 'id',
    sortDirection: TableSortType.Desc,
    page: 0,
    vendorId: '',
  },
});
export const AccountChargeLog: React.FC<Props> = (props) => {
  const { account } = props;
  const accountId = account?.id;
  const pagination = usePagination();
  const [activeSession, setActiveSession] = useState<SessionV2 | undefined>(undefined);
  const { tableFilters, updateTableFilters } = useTableComponentFilters();

  const corrupted = tableFilters?.corrupted ? { eq: tableFilters?.corrupted } : undefined;

  const inputFilters = {
    accountId: {
      eq: accountId,
    },
    ...(tableFilters?.startDate ? { startTime: { ge: startOfDay(tableFilters.startDate).toISOString() } } : {}),
    ...(tableFilters?.endDate ? { endTime: { le: endOfDay(tableFilters.endDate).toISOString() } } : {}),
    corrupted,
    ...(tableFilters?.vendorId ? { vendorIdStartsWith: tableFilters?.vendorId } : {}),
    ...(tableFilters?.plans && tableFilters.plans?.length > 0
      ? { planName: { in: tableFilters.plans.map((plan) => plan.label) } }
      : {}),
    ...(tableFilters?.drivers?.length || 0 > 0
      ? { driverId: { in: tableFilters?.drivers?.map((driver) => driver.value) } }
      : {}),
  };

  const input = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    filter: inputFilters,
    sort: tableFilters?.sortBy ? `${camelToSnakeCase(tableFilters.sortBy)}_${tableFilters.sortDirection}` : 'ID_DESC',
  };

  const [listSessions, { data, loading, error }] = useLazyQuery<Query>(LIST_SESSIONS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
    variables: {
      input,
    },
  });
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listSessions();
  }, [
    pagination.page,
    pagination.pageSize,
    tableFilters?.sortBy,
    tableFilters?.sortDirection,
    tableFilters?.corrupted,
    tableFilters?.plans,
    tableFilters?.drivers,
    tableFilters?.startDate,
    tableFilters?.endDate,
    listSessions,
  ]);

  const sessions = data?.listSessionsV2?.edges || [];
  const handleChange = (value: Record<string, unknown>) => {
    const clickedSession = sessions.find((session) => session?.altId === value?.altId);
    console.debug(clickedSession);
    setActiveSession(clickedSession || undefined);
  };

  return (
    <StyledTable data-testid="SessionsList">
      <Box mx={4} my={4} mt={4}>
        <Card>
          <ChargeLogFilters
            account={account}
            tableFilters={tableFilters}
            queryFilters={inputFilters}
            onFilter={(filter) => {
              updateTableFilters(filter);
            }}
          />
          <Divider />
          <Table
            id="SessionsTable"
            data-testid="trnsactions-table"
            columns={columns}
            data={sessions || []}
            loading={loading}
            error={error}
            noDataMessage="No matching sessions were found. Please check the Session ID and try again."
            pagination={{ ...pagination, total: data?.listSessionsV2?.total || 0 }}
            onCollapseClick={(row) => handleChange(row)}
            sorting={{
              sortBy: tableFilters?.sortBy,
              sortDirection: tableFilters?.sortDirection,
              onSortByChange: (sortBy: string) => {
                updateTableFilters({ sortBy: sortBy });
              },
              onSortDirectionChange: (sortDirection: string) => {
                updateTableFilters({
                  sortDirection: sortDirection === 'ASC' ? TableSortType.Asc : TableSortType.Desc,
                });
              },
            }}
          >
            <Box bgcolor="#EEF4F8">
              <Box px={8} py={2}>
                <Grid container>
                  <Grid className="detail-item" item xs={2}>
                    <b>Transaction:</b> {activeSession?.transaction?.vendorId || 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={2}>
                    <b>Start Card:</b> {activeSession?.card?.internalNumber || 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={2}>
                    <b>Start kWh:</b> {activeSession?.meterStart || 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={2}>
                    <b>Billed Time:</b> {`${activeSession?.sessionDuration} min` || 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={3}>
                    <b>Corrupt Reason:</b> {activeSession?.corruptedReasons || 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={2}>
                    <b>Driver Name:</b>{' '}
                    {activeSession?.transaction?.driver?.firstName
                      ? `${activeSession?.transaction?.driver?.firstName} ${activeSession?.transaction?.driver?.lastName}`
                      : 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={2}>
                    <b>Stop Source:</b> {activeSession?.stopSource?.columnValue || 'NA'}
                  </Grid>
                  <Grid className="detail-item" item xs={2}>
                    <b>End kWh:</b> {activeSession?.lastMeterValue || 'NA'}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Table>
        </Card>
      </Box>
    </StyledTable>
  );
};
