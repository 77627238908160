import { Divider, TextInfo } from '@evgo/react-material-components';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import config from 'src/config';
import { TimePickers } from '../../../../../shared/TimePickers';
import { initialValues } from '../../initialValues';
import { Styled as StyledAccordion } from './styles';

export interface Props {
  className?: string;
}

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * Site Profile component
 */
export const ChargerSiteInformation: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerSiteInformation');
  const className = id;
  const { values } = useFormikContext<typeof initialValues>();
  const { pathname } = useLocation();
  if (!_.get(values, 'site') || pathname === '/chargers/new') return null;

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;
  const stateId = _.get(values, 'site.administrativeArea', '');
  const photos: { src: string; alt: string }[] = [];

  const timeslots: Record<string, { from: string; to: string }[]> = {};
  daysOfWeek.forEach((day) => {
    if (
      _.get(values, `site.hoursOfOperation[${day}].timeslots`, []) &&
      _.get(values, `site.hoursOfOperation[${day}].timeslots`, []).length
    ) {
      timeslots[day] = _.get(values, `site.hoursOfOperation[${day}].timeslots`);
    } else {
      timeslots[day] = [{ from: '09:00', to: '17:00' }];
    }
  });
  const timeslot = (day: string, index: number, timeslotValues: { from: string; to: string }) => (
    <Fragment key={index}>
      <TimePickers
        data-testid="charger-site-time-picker"
        disabled={
          !_.isEmpty(_.get(values, `site.hoursOfOperation.${day}`)) &&
          _.get(values, `site.hoursOfOperation.${day}`).closed
        }
        values={_.omit(timeslotValues, '__typename')}
      />
    </Fragment>
  );
  const timeslotsToRender: Record<string, JSX.Element[]> = {};
  daysOfWeek.forEach((day) => {
    if (!_.isEmpty(timeslots) && timeslots[day]) {
      timeslotsToRender[day] = timeslots[day].map((time, index) => {
        return timeslot(day, index, time);
      });
    }
  });

  const center = `${_.get(values, 'site.latitude', '')},${_.get(values, 'site.longitude', '')}`;
  const marker = `&markers=color:0x008000%7C${_.get(values, 'site.latitude', '')},${_.get(
    values,
    'site.longitude',
    '',
  )}`;
  const zoom = '&zoom=12';
  const size = '&size=1500x350';
  const key = `&key=${config.env.google.apiKey}`;
  const staticMap =
    _.get(values, 'site.latitude', '') && _.get(values, 'site.longitude', '') ? (
      <img
        className={`${className} map`}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${center}${zoom}${size}${marker}${key}`}
        data-testid="charger-site-map-image"
      />
    ) : (
      <img
        id={`${id}-map-image-disabled`}
        className={`${className} map disabled`}
        data-testid="charger-site-map-image-disabled"
        src={`https://maps.googleapis.com/maps/api/staticmap?center=34.03334,-118.451664${zoom}${size}&markers=color:0x008000%7C34.03334,-118.451664${key}`}
      />
    );
  return (
    <StyledAccordion className={`${customClass} site-details`} defaultExpanded={true}>
      <AccordionSummary className={className} expandIcon={<ExpandMore />} component="header">
        <Typography className={className} variant="h6" component="h2">
          Location Information
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} panel-details`}>
        <div className={className}>
          <div className={className}>
            <TextInfo
              id={`${id}-address-1`}
              className={`${className}`}
              disabled={true}
              data-testid="charger-site-address-1"
              label="Address Line 1*"
              name="address1"
              value={_.get(values, 'site.address1', '')}
            />
            <TextInfo
              id={`${id}-address-2`}
              className={`${className}`}
              disabled={true}
              data-testid="charger-site-address-2"
              label="Address Line 2"
              name="address2"
              value={_.get(values, 'site.address2', '')}
            />
            <TextInfo
              id={`${id}-locality`}
              className={`${className} thirds`}
              disabled={true}
              data-testid="charger-site-locality"
              label="City*"
              name="locality"
              value={_.get(values, 'site.locality', '')}
            />
            <TextInfo
              id={`${id}-administrative-area`}
              className={`${className} thirds`}
              disabled={true}
              data-testid="charger-site-administrative-area"
              label="State*"
              name="administrativeArea"
              value={stateId}
            />
            <TextInfo
              id={`${id}-postal-code`}
              className={`${className} thirds`}
              disabled={true}
              data-testid="charger-site-postal-code"
              label="ZIP Code*"
              name="postalCode"
              value={_.get(values, 'site.postalCode', '')}
            />
            <TextInfo
              id="site-profile-time-zone"
              className={`${className} two-thirds`}
              disabled={true}
              label="Time Zone"
              labelProps={{ className }}
              name="timeZone"
              value={_.get(values, 'site.timeZone', '')}
            />
          </div>
          <Divider vertical />
          <div className={className}>
            <TextInfo
              id={`${id}-site-latitude`}
              className={`${className} half`}
              disabled={true}
              data-testid="charger-site-latitude"
              label="Latitude*"
              name="latitude"
              value={_.get(values, 'site.latitude', '')}
            />
            <TextInfo
              id={`${id}-site-longitude`}
              className={`${className} half`}
              disabled={true}
              data-testid="charger-site-longitude"
              label="Longitude*"
              name="longitude"
              value={_.get(values, 'site.longitude', '')}
            />
            <div className={`${className} map-container`} data-testid="charger-site-map-container">
              {staticMap}
            </div>
          </div>
        </div>

        <Divider className={className} />

        <div className={className}>
          <div className={`${className}`}>
            <Typography className={className} variant="subtitle1" component="h3">
              Site Hours
            </Typography>
            {!_.get(values, 'site.hoursOfOperation.custom') ? (
              <div className={`${className} custom-hours`}>
                <TimePickers
                  disabled={true}
                  data-testid="charger-site-time-picker"
                  values={_.get(values, 'site.hoursOfOperation', null)}
                />
              </div>
            ) : (
              <Fragment>
                {daysOfWeek.map((day, index) => (
                  <Fragment key={index}>
                    <div key={index} className={`${className} custom-hours`}>
                      <div className={`${className} sublabel-container`}>
                        <Typography
                          id={`${id}-time-picker-label-${index}`}
                          className={className}
                          component="h3"
                          data-testid="charger-site-time-picker-label"
                          variant="subtitle1"
                        >
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </Typography>
                      </div>
                      <div className={`${className} hours-container`}>{timeslotsToRender[day]}</div>
                    </div>
                  </Fragment>
                ))}
              </Fragment>
            )}
          </div>

          <Divider vertical />

          <div className={className}>
            <Typography className={className} component="h3" variant="subtitle1">
              Access
            </Typography>
            <TextInfo
              id={`${id}-access-type`}
              className={`${className} half`}
              disabled={true}
              data-testid="charger-site-access-type"
              label="Access Type*"
              value={_.startCase(_.get(values, 'site.access', '') || 'PUBLIC')}
            />
            <TextInfo
              id={`${id}-site-access-notes`}
              className={`${className}`}
              disabled={true}
              data-testid="charger-site-access-notes"
              label="Access Notes"
              multiline={true}
              name="accessNotes"
              value={_.get(values, 'site.accessNotes', '')}
            />

            <Divider className={className} />

            <Typography className={className} variant="subtitle1" component="h3">
              Photos
            </Typography>
            {!!photos?.length ? (
              photos.map((photo) => (
                <img className={className} src={photo.src} alt={photo.alt} data-testid="charger-site-photo" />
              ))
            ) : (
              <div className={`${className} img-placeholder`} data-testid="charger-site-photo-placeholder" />
            )}
          </div>
        </div>
        <Divider />
        <div className={className}>
          <div className={className}>
            <Typography className={className} variant="subtitle1" component="h3">
              Additional Notes
            </Typography>
            <TextInfo
              id={`${id}-site-type`}
              className={`${className} half`}
              disabled={true}
              data-testid="charger-site-type"
              label="Location Type*"
              value={_.startCase(_.lowerCase(_.get(values, 'site.type', '') || 'COMMERCIAL'))}
            />
            <TextInfo
              id={`${id}-site-market`}
              className={`${className} half`}
              disabled={true}
              data-testid="charger-site-location-market"
              label="Location Market*"
              value={_.startCase(_.lowerCase(_.get(values, 'site.locationMarket', '') || 'LOCATION_MARKET_1'))}
            />
            <TextInfo
              id={`${id}-site-location-notes`}
              className={`${className} half`}
              disabled={true}
              data-testid="charger-site-location-notes"
              label="Location Notes"
              multiline={true}
              value={_.get(values, 'site.locationNotes', '')}
            />
            <TextInfo
              id={`${id}-site-directions`}
              disabled={true}
              className={`${className} half`}
              data-testid="charger-site-directions"
              label="Site Directions"
              value={_.get(values, 'site.directions', '')}
            />
          </div>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};
