import { useQuery, useLazyQuery } from '@apollo/client';
import { Dropdown, TextInfo } from '@evgo/react-material-components';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Query, FalconConstraint, Maybe } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { listFalconConstraints } from 'src/apollo/queries/options';
import { formatCurrency, titleCase } from 'src/lib/helpers';
import { StyledModal } from 'src/components/shared/StyledModal';
import { Styled } from './styles';
import { listPlansForContracts, listPlanDetails } from 'src/apollo/queries/plans';
import { Form, useFormikContext } from 'formik';
import { useModalContext } from 'src/contexts/ModalContext';

/** Initial formik values  */
export const initialValues = {
  accountId: '',
  planId: '',
  statusId: 210,
  startDate: '',
  endDate: '',
};

interface Props {
  open: boolean;
  className?: string;
  contract?: string;
  accountId?: string;
}

const plansVariables = {
  plansInput: {
    pageSize: 9999,
    sort: { planName: 'ASC' },
  },
};

export const AccountContractModalForm: React.FC<Props> = (props) => {
  const id = 'AccountContractModalForm';
  const className = id;
  const {
    isValid,
    isSubmitting,
    handleBlur,
    values,
    status,
    setFieldValue,
    handleChange,
    resetForm,
    errors,
    touched,
    submitForm,
  } = useFormikContext<Record<string, unknown>>();

  const { modalName, modalProps, modalVisible } = useModalContext();
  const [editContract, setEditContract] = useState<{
    planId: string;
    statusId: string;
    startDate: string;
    endDate: string;
  } | null>(null);

  const { setModalState } = useModalContext();

  const onCancelClick = () => {
    setModalState({
      modalName: '',
      modalProps: {},
      modalVisible: false,
    });
    resetForm({ status: initialValues, values: initialValues });
  };

  const { data: plansData, loading: plansLoading } = useQuery<Query>(listPlansForContracts, {
    variables: plansVariables,
  });
  const [listDetails, { data: productData, loading: productsLoading }] = useLazyQuery<Query>(listPlanDetails, {
    variables: {
      plansInput: {
        pageSize: 10,
        sort: {
          planName: 'ASC',
        },
        filter: {
          altId: {
            eq: values.planId,
          },
        },
      },
    },
  });
  const plans = plansData?.listPlans?.edges;
  const planOptions = [] as Array<LabelValue<string>>;
  if (!plansLoading) {
    plans?.forEach((plan) => planOptions.push({ label: plan?.planName, value: plan?.altId || '' }));
  }

  const { data: filteredStatuses } = useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'contracts',
          },
          columnName: {
            eq: 'status_id',
          },
        },
        sort: {
          columnText: 'ASC',
        },
      },
    },
    skip: !props.open,
  });

  const shapedStatusOptions = (filteredStatuses?.listFalconConstraints?.edges || []).map(
    (shapedStatus: Maybe<FalconConstraint>) => ({
      value: shapedStatus?.id || '',
      label: shapedStatus?.columnValue || '',
    }),
  );

  const columns = [
    { key: 'productType', label: 'Product Type' },
    { key: 'amount', label: 'Amount' },
    { key: 'reoccurrence', label: 'Payment Recurrence' },
  ];

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;
  const shouldModalOpen = (modalProps && modalVisible && modalName === 'AccountContractModal') || false;

  useEffect(() => {
    listDetails();
  }, [values.planId, listDetails]);

  useEffect(() => {
    if (modalProps && modalProps.contract) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { contract } = modalProps as any;
      const contractValues = {
        planId: contract?.plan?.id,
        statusId: contract?.status?.id,
        startDate: contract.startDate ? moment(contract?.startDate).tz('UTC').format('YYYY-MM-DD') : '',
        endDate: contract?.endDate ? moment(contract?.endDate).tz('UTC').format('YYYY-MM-DD') : '',
      };

      if (contractValues !== status) {
        resetForm({ status: contractValues, values: contractValues });
        setEditContract(contractValues);
      }
    } else if ((modalProps && modalProps.accountId && modalProps.accountId !== values.accountId) || '') {
      const { accountId } = modalProps;
      resetForm({ status: { ...initialValues, accountId }, values: { ...initialValues, accountId } });
      setEditContract(null);
    } else {
      const contractStartDate = (values?.startDate || moment(new Date()).format('YYYY-MM-DD')) as string;

      if (values?.startDate) {
        setFieldValue('startDate', contractStartDate);
      }

      if (values?.startDate && values?.planId && !values?.endDate) {
        const selectedPlan = plans?.find((plan) => plan?.altId === values?.planId);
        if (selectedPlan && selectedPlan !== null) {
          const timeUnit = `${selectedPlan?.intervalUnit?.toLowerCase()}s` as moment.DurationInputArg2;
          const timeDuration = (selectedPlan?.intervalLength || 0) * (selectedPlan?.planDuration || 0);
          const defaultContractEndDate = moment(contractStartDate).add(timeDuration, timeUnit).format('YYYY-MM-DD');

          setFieldValue('endDate', defaultContractEndDate);
        }
      }
    }
  }, [status, resetForm, setFieldValue, modalProps, values, plans]);

  if (!shouldModalOpen) {
    return null;
  }

  return (
    <StyledModal
      className={clsx(customClass, 'modal')}
      onClose={onCancelClick}
      onClick={submitForm}
      open={props.open}
      title={editContract ? 'Edit Contract' : 'Add Contract'}
      primaryButtonText={editContract ? 'SAVE' : 'ADD CONTRACT'}
      primaryButtonDisabled={!isValid || values === status}
      secondaryButtonText="Cancel"
      secondaryButtonClick={onCancelClick}
    >
      <Styled className={className}>
        <Form id={`${id}-form`} className={className} data-testid="account-contract-modal-form">
          <div className={clsx(className, 'content')}>
            <div className={className}>
              <Dropdown
                className={clsx(className, 'form-control  full-width')}
                labelProps={{ className }}
                menuProps={{ className }}
                options={planOptions}
                value={values && values.planId ? values.planId : ''}
                required
                id={`${id}-planId`}
                name="planId"
                onChange={handleChange}
                data-testid="plans-autocomplete"
                label={plansLoading ? 'Loading Plans' : 'Plan Name *'}
                disabled={!!editContract || plansLoading}
              />

              <Dropdown
                className={clsx(className, 'form-control')}
                label="Contract Status"
                disabled={!editContract}
                labelProps={{ className }}
                menuProps={{ className }}
                options={shapedStatusOptions}
                value={values && values.statusId ? Number(values.statusId) : 210}
                required
                id={`${id}-status`}
                name="statusId"
                onChange={handleChange}
              />

              <TextInfo
                className={className}
                label="Plan Termination Behavior"
                disabled={true}
                value={titleCase(plans?.find((plan) => plan?.altId === values?.planId)?.terminationBehavior || '')}
                id={`${id}-plan-termination-behavior`}
                name="planTerminationBehavior"
                variant="filled"
              />

              <TextInfo
                className={className}
                disabled={isSubmitting}
                error={!!(touched?.startdate && errors?.startDate)}
                helpertext={touched?.startdate && errors?.startDate}
                id={`${id}-start-date-input`}
                label="Start Date *"
                labelProps={{ className: `${className} prefilled`, shrink: true }}
                name="startDate"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                type="date"
                value={values?.startDate}
              />

              <TextInfo
                className={className}
                disabled={isSubmitting}
                error={!!(touched?.endDate || touched?.startdate) && errors?.endDate}
                helpertext={(touched?.endDate || touched?.startdate) && errors?.endDate}
                id={`${id}-enrollment-closed-input`}
                label="End Date *"
                labelProps={{ className: `${className} prefilled`, shrink: true }}
                name="endDate"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                type="date"
                value={values?.endDate}
              />
            </div>

            {productData?.listPlans && !productsLoading && (
              <Table className={className}>
                <TableHead className={className}>
                  <TableRow className={className}>
                    {columns.map((column) => (
                      <TableCell key={column.key} className={`${className} ${column.key}`} component="th">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody className={className}>
                  {productData?.listPlans?.edges?.[0]?.products?.edges?.map((product, index) => {
                    return (
                      <TableRow className={className} key={index}>
                        <TableCell className={className} id={`${id}-product-${index}-type`} component="th">
                          {product?.credit ? 'Usage Credit' : 'Subscription Fee'}
                        </TableCell>
                        <TableCell className={className} id={`${id}-product-${index}-amount`}>
                          {product?.credit?.creditAmount
                            ? `$${formatCurrency(product?.credit?.creditAmount)}`
                            : `$${formatCurrency(product?.subscription?.subscriptionFee || 0)}`}
                        </TableCell>
                        <TableCell className={className} id={`${id}-product-${index}-recurrence`}>
                          {titleCase(
                            product?.credit
                              ? product?.credit?.creditReoccurrence
                              : product?.subscription?.subscriptionReoccurrence,
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </Form>
      </Styled>
    </StyledModal>
  );
};
