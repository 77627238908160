import {
  Checkbox,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Charger, Maybe, Query } from 'src/@types';
import { checkAll, checkOne, getFullAddress, titleCase } from '../../../../lib/helpers';
import { Styled as StyledTable } from './styles';

export interface Props {
  loading: boolean;
  checked: string[];
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
  allChecked: boolean;
  setAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
  listData: Query;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSort: (key: string) => void;
}

/** Chargers List view component. */
export const ChargersList: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargersList');
  const className = id;
  const { checked, setChecked, allChecked, setAllChecked, listData } = props;
  const { onChangePage, onChangeRowsPerPage } = props;

  const { edges, ...metadata } = listData?.listChargers || { edges: [] };
  const columns = [
    { key: 'chargerName', label: 'Name' },
    { key: 'cid', label: 'Charger ID' },
    { key: 'chargerModel_modelName', label: 'Model' },
    { key: 'fieldStationStatus', label: 'Status' },
    { key: 'address', label: 'Address' },
    { key: 'propertyName', label: 'Property Name' },
  ];

  return (
    <StyledTable className={className} data-testid="charger-management-table">
      <TableHead>
        <TableRow>
          <TableCell className={`${className} checkbox`}>
            <Checkbox
              className={className}
              indeterminate={checked.length > 0 && checked.length < (edges?.length || 0)}
              checked={checked.length > 0 && allChecked}
              onChange={() => checkAll(setChecked, allChecked, setAllChecked, edges)}
              id={`${id}-checkbox-check-all-in-list`}
            />
          </TableCell>

          {columns.map((column) => (
            <TableCell key={column.key} className={`${className} ${column.key}`} component="th">
              <TableSortLabel
                active={_.isString(_.get(metadata, `sort.${column.key}`))}
                direction={(_.toLower(_.get(metadata, `sort.${column.key}`, 'asc')) || 'asc') as 'asc'}
                onClick={() => {
                  props.onSort(column.key);
                }}
                disabled={
                  !['chargerName', 'cid', 'fieldStationStatus', 'propertyName', 'chargerModel_modelName'].includes(
                    column.key,
                  )
                }
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {!props.loading &&
          edges?.map((charger, index) => {
            const checkboxClass = `${className} checkbox`;

            const formatAddress = (chargerItem: Maybe<Charger>) => {
              if (!chargerItem) {
                return '';
              }

              let location = '';
              if (_.get(chargerItem, 'site.address1')) location = 'site';
              else location = 'warehouse';
              return getFullAddress({
                streetAddress: _.get(chargerItem, `${location}.address1`, ''),
                unit: _.get(chargerItem, `${location}.address2`, ''),
                city: _.get(chargerItem, `${location}.locality`, ''),
                state: _.get(chargerItem, `${location}.administrativeArea`, ''),
                postalCode: _.get(chargerItem, `${location}.postalCode`, ''),
                country: _.get(chargerItem, `${location}.country`, ''),
              });
            };

            return (
              <TableRow key={charger?.altId}>
                <TableCell className={checkboxClass}>
                  <Checkbox
                    className={`${className} checkbox`}
                    checked={checked.indexOf(charger?.altId || '') !== -1}
                    inputProps={{
                      // @ts-ignore
                      'edge-altid': charger?.altId,
                    }}
                    onChange={(e) => checkOne(e, checked, setChecked, setAllChecked, edges)}
                    id={`${id}-checkbox-check-charger-${index}`}
                  />
                </TableCell>

                <TableCell className={className}>
                  <Link className={className} to={`/chargers/${charger?.altId}/profile`}>
                    {_.get(charger, 'chargerName', '')}
                  </Link>
                </TableCell>
                <TableCell className={className}>{_.get(charger, 'cid', '')}</TableCell>
                <TableCell className={className}>{_.get(charger, 'chargerModel.modelName', '')}</TableCell>
                <TableCell className={className}>
                  {titleCase(_.get(charger, 'fieldStationStatus', 'Unknown'))}
                </TableCell>
                <TableCell className={className}>{formatAddress(charger)}</TableCell>
                <TableCell className={className}>{charger?.site?.property?.propertyName || ''}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>

      <TableFooter>
        <TableRow>
          <TablePagination
            className={className}
            count={metadata.total || 0}
            onPageChange={(e, page) => onChangePage(e, page)}
            onRowsPerPageChange={(e) => onChangeRowsPerPage(e)}
            page={metadata.page || 0}
            rowsPerPage={metadata.pageSize || 10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TableRow>
      </TableFooter>
    </StyledTable>
  );
};
