import React from 'react';
import { StyledDiv } from './styles';

export type Props = {
  childrenLeft?: React.ReactNode;
  childrenRight?: React.ReactNode;
  className?: string;
  id?: string;
  marginBottom?: number;
  'data-testid'?: string;
};

export const PageHeader: React.FC<Props> = ({
  childrenLeft,
  childrenRight,
  className: parentClass,
  id: parentId,
  marginBottom = 0,
  ...props
}) => {
  const className = 'page-header-component';
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  return (
    <StyledDiv className={customClass} id={parentId} marginBottom={marginBottom} {...props}>
      <div>{childrenLeft}</div>
      <div>{childrenRight}</div>
    </StyledDiv>
  );
};
