import React, { useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useDebounceFn } from 'ahooks';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';

export type SearchFieldProps = {
  initialValue?: string;
  placeholder?: string;
  helperText?: string;
  label?: string;
  onChange?: (value: string) => void;
  id?: string;
  className?: string;
  debounce?: number;
  value?: string;
  'data-testid'?: string;
};

const StyledTextField = styled(TextField)`
  [class*='MuiInputBase-root'] {
    height: 40px;
    background-color: ${theme.palette.common.white};

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
    }
  }

  .MuiOutlinedInput-input {
    padding: 0 14px 0 0;
  }
`;

const StyledIcon = styled(Search)`
  color: ${theme.palette.grey[600]};
`;

export const SearchField: React.FC<SearchFieldProps> = ({
  placeholder = 'Search',
  helperText = '',
  label = '',
  initialValue = '',
  debounce = 250,
  onChange = () => {},
  value: outerValue,
  ...props
}) => {
  const [useInnerValue] = useState(() => typeof outerValue === 'undefined');

  const [innerValue, setInnerValue] = useState(initialValue);

  const { run: debouncedOnChange } = useDebounceFn(onChange, { wait: debounce });

  const onValueChange = ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
    if (useInnerValue) {
      setInnerValue(newValue);
    }

    debouncedOnChange(newValue);
  };

  return (
    <StyledTextField
      data-testid="search-field"
      {...props}
      margin="none"
      onChange={onValueChange}
      value={useInnerValue ? innerValue : outerValue}
      variant="outlined"
      placeholder={placeholder}
      helperText={helperText}
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
