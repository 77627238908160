import styled from 'styled-components';

export const LabelDiv = styled.div`
  display: flex;
  align-items: center;

  svg:first-child {
    margin-right: 8px;
  }

  svg:last-child {
    margin-left: 8px;
  }
`;
