import React from 'react';
import styled from 'styled-components';
import { Typography, TypographyProps } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';

type CardHeaderProps = {
  title?: string;
  titleVariant?: TypographyProps['variant'];
  subtitle?: React.ReactChild;
};

const Container = styled.div`
  margin-bottom: ${theme.spacing(2)}px;
`;

export const CardHeader: React.FC<CardHeaderProps> = ({ title, titleVariant = 'h5', subtitle, ...rest }) => {
  return (
    <Container {...rest}>
      {title && <Typography variant={titleVariant}>{title}</Typography>}
      {subtitle && (
        <Typography color="textSecondary" variant="subtitle1">
          {subtitle}
        </Typography>
      )}
    </Container>
  );
};
