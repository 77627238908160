import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Accordion)`
  margin-top: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;

  header {
    padding: ${`0 ${spacing(3)}px`};

    > div {
      display: flex;
      flex-direction: column;
    }

    h2 {
      color: ${colors.accentBlue};
    }
  }
`;
