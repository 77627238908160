import Yup from '../../../../yup';

export const validations = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z\u00C0-\u017F ]+(([',. -][A-Za-z\u00C0-\u017F ])?[A-Za-z\u00C0-\u017F ]*)*$/,
      'The first name must contain only letters',
    )
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z\u00C0-\u017F ]+(([',. -][A-Za-z\u00C0-\u017F ])?[A-Za-z\u00C0-\u017F ]*)*$/,
      'The last name must contain only letters',
    )
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string()
    .matches(/^(((\([2-9]\d{2}\) ?)|([2-9]\d{2}-))?\d{3}-\d{4})|(^[2-9]\d{2}\d{3}\d{4})$/, 'Invalid phone number')
    .required('Required'),
});
