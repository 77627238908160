import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;

export const Styled = styled.div`
  min-width: 550px;
  display: flex;
  flex-direction: column;

  .content {
    padding: 16px 16px 8px 16px;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .form-control {
    width: calc(50% - ${spacing(1)}px);
  }

  .full-width {
    width: 100%;
  }

  .modal-actions {
    background-color: ${config.colors.white};
    flex-direction: row;

    button {
      svg {
        &.left {
          margin-right: ${spacing()}px;
        }
      }
    }
  }

  .helper-text {
    display: none;
  }
`;
