/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${spacing(6)}px;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) ${spacing(9)}px; calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};

  header {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    height: 110px;
    box-sizing: border-box;
    padding: 18px;
    padding: 18px calc(18px + var(--safe-area-inset-right)) 0 calc(18px + var(--safe-area-inset-left));
    padding-bottom: ${spacing(9)}px;

    section {
      &.title {
        display: flex;
        flex-direction: column;
        margin: 0px 40px 0 0;

        .subtitle {
          margin-top: 4px;
          font-size: 12px;
          color: #627d87;
        }
      }
    }
  }
  hr {
    margin: 0px 16px;
  }

  table {
    td,
    th {
      padding: ${`${spacing(2.25)}px ${spacing()}px`};

      &:first-child {
        padding-left: ${spacing(2)}px;
      }

      &:last-child {
        padding-right: ${spacing(2)}px;
      }
    }

    td {
      padding: ${`${spacing() / 2}px ${spacing()}px`};
      font-size: 12px;
      a {
        color: ${config.colors.accentBlue};
      }
    }
  }

  section,
  article {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px 0`};

    header {
      display: flex;
      height: 110px;
      padding-bottom: 0;

      h2 {
        color: ${config.colors.accentBlue};
      }

      a {
        height: 40px;
        margin-right: 15px;
      }

      div {
        display: flex;

        &.actions {
          align-items: center;
          > div {
            margin-left: 15px;
          }
        }

        &.container {
          flex: 1;
          flex-direction: row;

          .title {
            flex-direction: column;
          }
        }
      }
    }
  }
`;
