import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;

  div {
    &:first-of-type {
      align-self: start;
    }

    > div {
      margin: 0 ${spacing() / 4}px ${spacing()}px;
      &.full {
        width: calc(100% - 16px);
        .form-group {
          width: 100%;
          div {
            width: 100%;
          }
        }
      }

      &.half {
        width: calc(50% - 16px);
        .form-group {
          width: 100%;
          > div {
            width: 100%;
          }
        }
      }

      &.disabled {
        pointer-events: none;
      }
    }

    > * {
      margin: 0 ${spacing()}px;
      margin-bottom: ${spacing(2)};
    }
  }
`;
