/* stylelint-disable color-no-hex */
import styled from 'styled-components';

export const Styled = styled.svg`
  &.active,
  &.available {
    fill: #009f05; /* green */
  }

  &.charging,
  &.finishing,
  &.preparing,
  &.suspended-ev,
  &.suspended-evse {
    fill: #0073e4; /* blue */
  }

  &.faulted,
  &.unavailable {
    fill: #d0011b; /* red */
  }

  &.reserved {
    fill: #e4af00; /* yellow */
  }

  &.unknown {
    fill: #728084; /* grey */
  }
`;
