import { theme } from '@evgo/react-material-components';
import { Table } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing } = theme;
const { colors } = config;

export const Styled = styled(Table)`
  td,
  th {
    padding: ${`${spacing(2.25)}px ${spacing()}px`};

    &.checkbox {
      > span {
        padding: 0;
      }
    }

    &:first-child {
      padding-left: ${spacing(2)}px;
    }

    &:last-child {
      padding-right: ${spacing(2)}px;
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  h5 {
    font-size: 16px;
    color: ${colors.sherpaBlue};
  }
  .detail-item {
    margin-bottom: ${spacing(1)}px;
  }
  span {
    color: ${palette.text.secondary};
  }
  td {
    font-size: 12px;
    a {
      font-size: 12px;
      color: ${config.colors.accentBlue};
      text-decoration: none;
      font-weight: 400;
    }
  }
`;
