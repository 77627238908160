import _ from 'lodash';
import days from '../../../../lib/fixtures/days.json';
import Yup from '../../../../yup';

export const validations = Yup.object().shape({
  badge: Yup.string().trim().max(20, 'Badge must be 20 characters or less').nullable(),
  balanceTypeId: Yup.number().typeError('Required').required('Required'),
  cardBrandId: Yup.number().typeError('Required').required('Required'),
  ctaLabel: Yup.string()
    .trim()
    .max(20, 'CTA must be 20 characters or less')
    .nullable()
    .when(['displayToCustomer'], (val, schema) => (val === true ? schema.required('Required') : schema)),
  description: Yup.string().trim().required('Required'),
  subheading: Yup.string()
    .trim()
    .max(120, 'Description must be 120 characters or less')
    .when(['displayToCustomer'], (val, schema) => (val === true ? schema.required('Required') : schema)),
  pageHeader: Yup.string()
    .trim()
    .nullable()
    .max(180, 'Page Header must be 180 characters or less')
    .when(['displayToCustomer'], (val, schema) => (val === true ? schema.required('Required') : schema)),
  detailSections: Yup.array(
    Yup.object().shape({
      header: Yup.string().trim().max(100, 'List Title must be 100 characters or less').required('Required'),
      items: Yup.array(Yup.string().trim().max(120, 'List  Items must be 120 characters or less').required('Required')),
    }),
  ),
  costFactor: Yup.string().matches(
    /^\s*(?=.*[.01-9])\d*(?:\.\d{1,2})?\s*$/,
    'CostFactor must be a number with max 2 decimal places',
  ),
  detailSessionTimes: Yup.array(
    Yup.object().shape({
      duration: Yup.number().typeError('Must be a number').required('Required'),
      interval: Yup.string().required('Required'),
      startTime: Yup.string()
        .when('endTime', (val, schema) =>
          val
            ? schema.test('validTimeRange', 'Start time must be before end time', (value = '') => val >= value)
            : schema,
        )
        .nullable(),
      ..._.reduce(days, (obj, day) => ({ ...obj, [day]: Yup.boolean() }), {}),
      endTime: Yup.string().nullable(),
    }),
  ),
  legalese: Yup.string().trim(),
  displayName: Yup.string().max(100, 'Display Name must be 100 characters or less').trim(),
  displayToCustomer: Yup.boolean(),
  displayToLoggedOutUser: Yup.boolean(),
  elements: Yup.array(
    Yup.object()
      .shape({
        connectorType: Yup.string().required(''),
        hours: Yup.array(
          Yup.object().shape({
            discount: Yup.string().matches(
              /^(100(?:\.00?)?|\d?\d?(?:\.\d?\d?)?)$/,
              'Discount must be a number between 0 and 100',
            ),
            endTime: Yup.string().required('End time is required and must be after start time'),
            maxDuration: Yup.string().matches(
              /^(0?[0-9]|[1-5][0-9]|6[0])$/,
              'Max duration must be a number between 0 and 60',
            ),
            startTime: Yup.string()
              .when('endTime', (val, schema) =>
                val
                  ? schema.test(
                      'validTimeRange',
                      'Start time is required and must be before end time',
                      (value = '') => val >= value,
                    )
                  : schema,
              )
              .required('Start time is required and must be before end time'),
            ..._.reduce(days, (obj, day) => ({ ...obj, [day]: Yup.boolean() }), {}),
          }),
        )
          .min(1)
          .someAre('At least one day must be selected', days, true, [])
          .invalidTimes('Overlapping times are not allowed', [])
          .required('Required'),
      })
      .uniqueProperty('Duplicate connector types are not allowed', 'connectorType'),
  )
    .min(1)
    .required('Required'),
  enrollmentClosed: Yup.date()
    .min('0000-01-01', 'Invalid date')
    .max('9999-12-31', 'Invalid date')
    .when('enrollmentOpen', (val, schema) =>
      val
        ? schema.test('validateDateRange', 'End date must not be before start date', (value = '') => val < value)
        : schema,
    ),
  enrollmentOpen: Yup.date().min('0000-01-01', 'Invalid date').max('9999-12-31', 'Invalid date'),
  financeCode: Yup.string().trim(),
  planCode: Yup.string().trim().required('Required'),
  planName: Yup.string().trim().max(100, 'Plan Name must be 100 characters or less').required('Required'),
  products: Yup.array(
    Yup.object().shape({
      creditAmount: Yup.string().when(['productType'], (val, schema) =>
        val === 'CREDIT'
          ? schema
              .required('Required')
              .test('isNumeric', 'Amount must be a number', (value = '') => value.match(/^[0-9]+(?:\.[0-9]+)*$/))
              .test('hasTwoDecimals', 'Amount must have two decimal places', (value = '') =>
                value.match(/^[0-9]+\.[0-9]{2}$/),
              )
          : schema,
      ),
      creditExpPolicyId: Yup.string().when(['productType'], (val, schema) =>
        val === 'CREDIT' ? schema.trim().required('Required') : schema,
      ),
      creditOneTimeAmount: Yup.string().when(['productType'], (val, schema) =>
        val === 'CREDIT'
          ? schema
              .required('Required')
              .test('isNumeric', 'Amount must be a number', (value = '') => value.match(/^[0-9]+(?:\.[0-9]+)*$/))
              .test('hasTwoDecimals', 'Amount must have two decimal places', (value = '') =>
                value.match(/^[0-9]+\.[0-9]{2}$/),
              )
          : schema,
      ),
      creditReoccurrenceId: Yup.string().when(['productType'], (val, schema) =>
        val === 'CREDIT' ? schema.trim().required('Required') : schema,
      ),
      subscriptionFee: Yup.string().when(['productType'], (val, schema) =>
        val === 'SUBSCRIPTION'
          ? schema
              .required('Required')
              .test('isNumeric', 'Fee must be a number', (value = '') => value.match(/^[0-9]+(?:\.[0-9]+)*$/))
              .test('hasTwoDecimals', 'Fee must have two decimal places', (value = '') =>
                value.match(/^[0-9]+\.[0-9]{2}$/),
              )
          : schema,
      ),
      subscriptionReoccurrenceId: Yup.string().when(['productType'], (val, schema) =>
        val === 'SUBSCRIPTION' ? schema.trim().required('Required') : schema,
      ),
      productTypeId: Yup.string().required('Required'),
    }),
  ),
  requireVin: Yup.boolean(),
  rolloverPlanId: Yup.string()
    .trim()
    .when(['terminationBehavior'], (val, schema) => (val === 'ROLLOVER' ? schema.required('Required') : schema)),
  planStatusId: Yup.number().typeError('Required').required('Required'),
  templateId: Yup.string()
    .nullable()
    .when(['displayToCustomer'], (val, schema) => (val === true ? schema.required('Required') : schema)),
  terminationBehaviorId: Yup.number().typeError('Required').required('Required'),
  duration: Yup.number()
    .positive()
    .when(['terminationBehavior'], (val, schema) => {
      return val !== 'REOCCURS' ? schema.required('Required') : schema;
    })
    .nullable(),
});
