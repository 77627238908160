import React, { Fragment } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { PlanCard } from './PlanCard';
import { PlanDetail } from './PlanDetail';
import { PlanDisplaySettings } from './PlanDisplaySettings';

/**
 * Plan display tab component
 */
export const PlanDisplayTab: React.FC = () => {
  const { altId, url } = useParams<{ altId: string; url: string }>();

  if (url === '/plans/new' && altId) {
    return <Navigate to={`/plans/${altId}/display`} />;
  }

  return (
    <Fragment>
      <PlanDisplaySettings />
      <PlanCard />
      <PlanDetail />
    </Fragment>
  );
};
