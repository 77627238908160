import { Maybe, GetSessionsConsumptionForExtendResponse } from 'src/@types';

export const getMetricData = (data: Maybe<GetSessionsConsumptionForExtendResponse> | undefined) => {
  const metrics = {
    totalSessions: 0,
    totalIncome: 0,
    totalEnergy: 0,
  };
  const timeSeries = data?.timeSeries || [];

  timeSeries?.forEach((value) => {
    metrics.totalSessions += value?.totalSessions || 0;
    metrics.totalIncome += value?.powerCostValue || 0;
    metrics.totalEnergy += value?.powerValue || 0;
  });

  return metrics;
};
