import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../config';

const { palette, spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${spacing(6)}px;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};
  padding-bottom: ${spacing(9)}px;

  &.authenticators-view > header {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: calc(100% + ${spacing(12)}px);
    margin: 0 -${spacing(6)}px;
    padding: ${`${spacing(4)}px ${spacing(8)}px ${spacing(8)}px`};

    h1,
    span {
      color: ${config.colors.accentBlue};
    }

    &::after {
      position: absolute;
      bottom: 0;
      width: calc(100% - ${spacing(16)}px);
      border-bottom: 1px solid ${palette.divider};
      content: '';
    }
  }

  > section {
    box-sizing: border-box;
    width: calc(100% - ${spacing(4)}px);
    margin: ${`${spacing(4)}px ${spacing(2)}px ${spacing(4)}px`};

    header {
      h2 {
        color: ${config.colors.accentBlue};
      }

      span {
        color: ${palette.text.secondary};
      }

      div {
        display: flex;
      }
    }
  }
`;
