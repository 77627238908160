import Yup from '../../../../yup';

export const validations = Yup.object().shape({
  additionalEquipment: Yup.string().trim().nullable(),
  additionalSerial: Yup.string().trim().nullable(),
  authenticationModeId: Yup.number(),
  ccReaderType: Yup.string().trim().nullable(),
  certified: Yup.boolean(),
  chargerIsManaged: Yup.boolean(),
  chargePointIdentity: Yup.string().trim().nullable(),
  chargerModel: Yup.object().shape({
    modelName: Yup.string().trim().required('Required'),
  }),
  chargerName: Yup.string().trim().required('Required'),
  cid: Yup.string()
    .required('Required')
    .matches(/^([C][\d]+)$/i, { message: 'Invalid Charger ID. Must be “C” followed by numbers' }),
  coBranding: Yup.string().trim().nullable(),
  communicationMethod: Yup.string().trim().nullable(),
  connectionUri: Yup.string().trim().nullable(),
  connectionDate: Yup.date().min('0000-01-01', 'Invalid date').max('9999-12-31', 'Invalid date').nullable(),
  description: Yup.string().trim().nullable(),
  disabled: Yup.boolean(),
  equipmentId: Yup.string().trim().nullable(),
  evseId: Yup.string().trim().label('EVSE ID').required(),
  evcSyncedAt: Yup.string().trim().nullable(),
  excluded: Yup.boolean(),
  excludeFromOcpi: Yup.boolean(),
  fieldStationStatus: Yup.string().trim().required('Required'),
  fundingSource: Yup.string().trim().nullable(),
  hidden: Yup.boolean(),
  identityKey: Yup.string().trim().nullable(),
  ignoreStatusNotifications: Yup.boolean(),
  installDate: Yup.date().min('0000-01-01', 'Invalid date').max('9999-12-31', 'Invalid date').nullable(),
  ipAddress: Yup.string().trim().nullable(),
  lastReceivedCommSignal: Yup.string().trim().nullable(),
  lastReportedFirmwareVersion: Yup.string().trim().nullable(),
  localLoadBalancingEnabled: Yup.boolean(),
  localNetworkMaster: Yup.boolean(),
  locationType: Yup.string().trim(),
  locationId: Yup.string().trim().required('Required'),
  macAddress: Yup.string().trim().nullable(),
  maxPower: Yup.string()
    .nullable()
    .matches(/^(?!0*[.]0*$|[.]0*$|0*$)\d+[.]?\d{0,2}$|0/, 'Max Power must be a number'),
  meterSigningSupported: Yup.boolean(),
  modelNotes: Yup.string().trim().nullable(),
  mountType: Yup.string().trim().nullable(),
  networkConnectionTypeId: Yup.number().nullable(),
  nonNetworked: Yup.boolean(),
  nextMaintenanceDate: Yup.date()
    .min('0000-01-01', 'Invalid date')
    .max('9999-12-31', 'Invalid date')
    .typeError('Invalid Date')
    .nullable(),
  evses: Yup.object()
    .shape({
      edges: Yup.array(
        Yup.object()
          .shape({
            connectors: Yup.object()
              .shape({
                edges: Yup.array(
                  Yup.object().shape({
                    connectorType: Yup.object().shape({
                      connectorName: Yup.string().trim(),
                    }),
                    evseConnectorNumber: Yup.string().trim().nullable(),
                    uniqueConnectorId: Yup.string().trim().nullable(),
                  }),
                ).nullable(),
              })
              .nullable(),
          })
          .nullable(),
      ),
    })
    .nullable(),
  serialNumber: Yup.string().trim().required('Required'),
  showInThirdPartyFilters: Yup.boolean(),
  smartChargingEnabled: Yup.boolean(),
  teslaAdapter: Yup.boolean(),
  timeZone: Yup.string().trim(),
  usageStartDate: Yup.date().min('0000-01-01', 'Invalid date').max('9999-12-31', 'Invalid date').nullable(),
  validateMeterSignature: Yup.boolean(),
  warrantyStartDate: Yup.date()
    .min('0000-01-01', 'Invalid date')
    .max('9999-12-31', 'Invalid date')
    .typeError('Invalid Date')
    .nullable(),
  warrantyExpirationDate: Yup.date()
    .min('0000-01-01', 'Invalid date')
    .max('9999-12-31', 'Invalid date')
    .typeError('Invalid Date')
    .when('warrantyStartDate', (val, schema) =>
      val
        ? schema.test('validateDateRange', 'End date must not be before start date', (value = '') => val <= value)
        : schema,
    )
    .nullable(),
  wirelessConnectionCarrierId: Yup.number().nullable(),
});
