import { TextInfo } from '@evgo/react-material-components';
import { IconButton } from '@material-ui/core';
import { DragHandle as DragHandleIcon, RemoveCircleOutline as RemoveCircleOutlineIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { initialValues } from '../../../../initialValues';
import { Styled as StyledBox } from './styles';

export type Props = {
  id?: string;
  path: string;
  index: number;
  section: number;
  className?: string;
};

export const PlanDetailListItem: React.FC<Props> = ({ className: parentClass, id: parentId, path, index, section }) => {
  const id = _.kebabCase('PlanDetailListItem');
  const className = id;
  const ref = useRef(null);
  const type = `DetailItem${section}`;

  const { errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values } =
    useFormikContext<typeof initialValues>();

  const detailItemsCopy = _.get(values, path);

  const handleRemoveListItem = useCallback(() => {
    const detailSections = _.get(values, path);
    const value = [...detailSections];

    _.pullAt(value, [index]);
    setFieldValue(path, value, true);
  }, [index, path, setFieldValue, values]);

  const moveDetailItem = useCallback(
    (dragIndex: number, hoverIndex: number): void => {
      const draggedItem = detailItemsCopy[dragIndex];

      detailItemsCopy.splice(dragIndex, 1);
      detailItemsCopy.splice(hoverIndex, 0, draggedItem);

      setFieldValue(path, detailItemsCopy, true);
    },
    [detailItemsCopy, setFieldValue, path],
  );

  const [, drop] = useDrop<{ type: string; index: number }, unknown, unknown>(() => ({
    accept: type,
    hover(item) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveDetailItem(dragIndex, hoverIndex);
      item.index = hoverIndex; // eslint-disable-line no-param-reassign
    },
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: () => ({ index, section }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  drag(drop(ref));

  return (
    <StyledBox
      id={parentId}
      className={clsx(className, parentClass)}
      ref={ref}
      style={{ opacity: isDragging ? '0.5' : '1' }}
    >
      <TextInfo
        className={className}
        disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
        error={!!(_.get(touched, `${path}[${index}]`) && _.get(errors, `${path}[${index}]`))}
        helpertext={_.get(touched, `${path}[${index}]`) && _.get(errors, `${path}[${index}]`)}
        id={`${id}-plan-list-item-input`}
        name={`${path}[${index}]`}
        adornmentEnd={<DragHandleIcon />}
        onBlur={handleBlur}
        onChange={handleChange}
        value={_.get(values, `${path}[${index}]`)}
        data-testid={`${id}-plan-list-item-input`}
      />

      <IconButton
        className={`${className} remove-button`}
        disabled={isSubmitting || !_.get(values, 'displayToCustomer')}
        id={`${id}-${index}-item-add-button`}
        onClick={handleRemoveListItem}
        data-testid={`${id}-${index}-item-add-button`}
      >
        <RemoveCircleOutlineIcon className={className} color={isSubmitting ? 'inherit' : 'primary'} />
      </IconButton>
    </StyledBox>
  );
};
