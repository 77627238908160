import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { Contact, CreatePropertyInput, Host } from '../../../../../@types';
import { getFullAddress } from '../../../../../lib/helpers';
import { Styled } from './styles';

export interface Props {
  selectedProperties: CreatePropertyInput[];
  selectedContacts: Contact[];
  values: Host;
}

export const CreateHostConfirmation: React.FC<Props> = (props) => {
  const id = _.kebabCase('CreateHostConfirmation');
  const className = id;
  const { selectedProperties, selectedContacts, values } = props;

  const formatAddress = (data: Host) =>
    getFullAddress({
      streetAddress: _.get(data, 'address1', ''),
      unit: _.get(data, 'address2', ''),
      city: _.get(data, 'locality', ''),
      state: _.get(data, 'administrativeArea', ''),
      postalCode: _.get(data, 'postalCode', ''),
      country: _.get(data, 'country', ''),
    });

  const visibility = { visibility: values?.hid ? 'visible' : 'hidden' } as React.CSSProperties;

  const hostInfo = (field: string, value: string, variant: Variant) => (
    <Typography id={`${id}-${_.kebabCase(field)}`} className={className} style={visibility} variant={variant}>
      {value}
    </Typography>
  );

  return (
    <Styled className={clsx(className, 'step-0')}>
      <div className={className}>
        <Typography className={className} variant="subtitle1">
          Host
        </Typography>

        {values ? hostInfo('hostName', values?.hostName || '', 'caption') : null}
        <br />
        {values ? hostInfo('address', formatAddress(values), 'caption') : null}
      </div>

      <div className={className}>
        <Typography className={className} variant="subtitle1">
          Properties
        </Typography>

        {!_.isEmpty(selectedProperties) ? (
          selectedProperties.map(({ propertyName, pid, address1, locality, administrativeArea, postalCode }, key) => (
            <div id={`${id}-property-${key}`} key={key}>
              {values ? hostInfo('propertyName', `${propertyName} – ${pid}`, 'caption') : null}
              <br />
              {values
                ? hostInfo('address', formatAddress({ address1, locality, administrativeArea, postalCode }), 'caption')
                : null}
            </div>
          ))
        ) : (
          <Typography className={className} variant="caption">
            No selected properties
          </Typography>
        )}
      </div>

      <div className={className}>
        <Typography className={className} variant="subtitle1">
          Contacts
        </Typography>

        {!_.isEmpty(selectedContacts) ? (
          selectedContacts.map(({ contactName, email, phone }, key) => (
            <Typography id={`${id}-contact-${key}`} key={key} className={className} variant="caption">
              {contactName} {email} {phone}
            </Typography>
          ))
        ) : (
          <Typography className={className} variant="caption">
            No selected contacts
          </Typography>
        )}
      </div>
    </Styled>
  );
};
