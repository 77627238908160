import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { createCharger, updateCharger } from '../../../apollo/queries/chargers';
import { transformChargerResponse, transformChargerValues } from '../../../lib/helpers';
import { useFormikSubmit } from '../../../lib/hooks';
import { initialValues } from '../DriverView/DriverForm';
import { ChargerForm } from './ChargerForm';
import { validations as validationSchema } from './ChargerForm/validations';
import { Styled } from './styles';

/**
 * Charger view component
 */
export const ChargerView: React.FC = () => {
  const { altId } = useParams<{ altId: string }>();
  const className = 'chargerview-component';
  const handleSubmit = useFormikSubmit('Charger', createCharger, updateCharger, {
    transformValues: transformChargerValues,
    transformResponse: transformChargerResponse,
    altId,
  }) as never;

  return (
    <Styled className={className}>
      <Formik
        initialStatus={initialValues}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <ChargerForm />
      </Formik>
    </Styled>
  );
};
