import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { LabelValue } from 'src/@types/shared';
import { Styled } from './styles';

export interface Props {
  brands: LabelValue<string>[];
  selectedBrands: string[];
  setSelectedBrands: React.Dispatch<React.SetStateAction<string[]>>;
  selectedStatuses: string[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>;
  updateFilters: (obj: unknown, key: string) => void;
  className?: string;
}

const statusList = [
  { label: 'Unassigned', value: 'UNASSIGNED' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Deactivated', value: 'DEACTIVATED' },
  { label: 'Reserved', value: 'RESERVED' },
  { label: 'Suspended', value: 'SUSPENDED' },
];

/**
 * Returns a string value of selected markets.
 */
export const checkSelected = (selected: string[], list: LabelValue<string>[]): string => {
  if (selected.length === 0) {
    return 'All';
  }

  return selected?.map((item) => _.find(list, { value: item })?.label).join(', ');
};

/**
 * CardsListFilters component.
 */
export const CardsListFilters: React.FC<Props> = (props) => {
  const id = _.kebabCase('CardsListFilters');
  const { brands, selectedBrands, setSelectedBrands, selectedStatuses, setSelectedStatuses, updateFilters } = props;
  const className = id;

  return (
    <Styled className={className}>
      <FormControl className={className}>
        <InputLabel className={`${className} label`} htmlFor="card-brand">
          Card Brand
        </InputLabel>
        <Select
          className={`${className} select`}
          displayEmpty
          multiple
          onChange={(e) => {
            updateFilters({ brand: e.target.value }, 'brand');
            setSelectedBrands(e.target.value as unknown as string[]);
          }}
          renderValue={(selected) => checkSelected(selected as string[], brands)}
          data-testid="cards-list-filters-brand-select"
          value={selectedBrands}
        >
          <MenuItem className={className} disabled value="">
            All
          </MenuItem>
          {brands?.map((model, i) => {
            return (
              <MenuItem key={model.value} value={model.value} disableRipple>
                <Checkbox
                  checked={selectedBrands.includes(model.value)}
                  checkedIcon={<CheckBox fontSize="small" />}
                  disableRipple
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  inputProps={{ id: `${id}-${i}-card-filter-card-brand-checkbox` }}
                />
                <ListItemText primary={model.label} style={{ fontSize: 'small' }} />
              </MenuItem>
            );
          })}
        </Select>

        {!_.isEmpty(selectedBrands) ? (
          <Typography
            className={`${className} clear-brands`}
            variant="caption"
            color="secondary"
            data-testid="cards-list-filters-brand-clear"
            onClick={() => {
              updateFilters({ brand: [] }, 'brand');
              setSelectedBrands([]);
            }}
          >
            Clear
          </Typography>
        ) : null}
      </FormControl>

      <FormControl className={className}>
        <InputLabel className={`${className} label`} htmlFor="card-status">
          Card Status
        </InputLabel>
        <Select
          className={`${className} select`}
          displayEmpty
          input={<Input id="select-multiple-checkbox" />}
          multiple
          onChange={({ target }: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
            updateFilters({ cardStatus: target.value }, 'cardStatus');
            setSelectedStatuses(target.value as unknown as string[]);
          }}
          renderValue={(selected) => checkSelected(selected as string[], statusList)}
          data-testid="cards-list-filters-card-status-select"
          value={selectedStatuses}
        >
          <MenuItem className={className} disabled value="">
            All
          </MenuItem>
          {statusList.map((status, i) => {
            return (
              <MenuItem key={status.value} value={status.value} disableRipple>
                <Checkbox
                  checked={selectedStatuses.includes(status.value)}
                  checkedIcon={<CheckBox fontSize="small" />}
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  inputProps={{ id: `${id}-${i}-card-filter-card-status-checkbox` }}
                  disableRipple
                />
                <ListItemText primary={status.label} style={{ fontSize: 'small' }} />
              </MenuItem>
            );
          })}
        </Select>
        {!_.isEmpty(selectedStatuses) ? (
          <Typography
            className={`${className} clear-status`}
            variant="caption"
            color="secondary"
            data-testid="cards-list-filters-card-status-clear"
            onClick={() => {
              updateFilters({ cardStatus: [] }, 'cardStatus');
              setSelectedStatuses([]);
            }}
          >
            Clear
          </Typography>
        ) : null}
      </FormControl>
    </Styled>
  );
};
