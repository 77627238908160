import { Formik } from 'formik';
import React from 'react';
import { Query } from '../../../../../@types';
import { CreateHostForm } from './CreateHostForm';
import validationSchema from './CreateHostForm/validations';
import { Styled } from './styles';

export interface Props {
  setHostFormIsValid: (valid: boolean) => void;
  queryData: Query;
  isLoadedLazyQuery: boolean;
  setValues: (values: Record<string, unknown>) => void;
}

export const ValidateHostStep: React.FC<Props> = (props) => {
  const { setHostFormIsValid, queryData, isLoadedLazyQuery, setValues } = props;
  const initialValues = queryData?.getSalesforceHost || {};

  const fn = () => {};

  return (
    <Styled>
      <Formik
        initialStatus={initialValues}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={fn}
      >
        <CreateHostForm
          hostData={queryData}
          hostLoading={isLoadedLazyQuery}
          setHostFormIsValid={setHostFormIsValid}
          setValues={setValues}
        />
      </Formik>
    </Styled>
  );
};
