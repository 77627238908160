/**
 * This file aims to concentrate all the feature flags available for operator-client
 * please find the available values at https://gitlab.com/evgo-it/operator/-/feature_flags
 *
 * Every flag should have a constant for each flag value e.g:
 *
 * const FLAG_FEATURE_NAME = 'feature-name';
 *
 * Usage: const isFeatureEnable = useFlag(FLAG_FEATURE_NAME);
 *
 * Ref.: https://github.com/Unleash/proxy-client-react
 */

import { useFlag } from '@unleash/proxy-client-react';

const FLAG_OP_EXTEND = 'op-extend';

const FLAG_OP_EXTEND_IN_MAINTENANCE = 'op-extend-in-maintenance';

const FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS = 'op-extend-hmi-display-fields';

const FLAG_OP_CREATE_ACCOUNTS = 'op-create-accounts';

export {
  useFlag,
  FLAG_OP_EXTEND,
  FLAG_OP_EXTEND_IN_MAINTENANCE,
  FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS,
  FLAG_OP_CREATE_ACCOUNTS,
};
