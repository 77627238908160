import { Box, Grid, Button, Typography, Paper, Divider } from '@material-ui/core';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReorderableList, { Item } from 'src/components/shared/ReorderableList';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { listPlans as LIST_PLANS, updatePlanDriverRank as UPDATE_PLAN_DRIVER_RANK } from 'src/apollo/queries/plans';
import { Query } from 'src/@types';
import { PageContent } from 'src/components/shared/PageContent';
import { Footer } from 'src/components/shared/Footer';
import { listFalconConstraints } from 'src/apollo/queries/options';
import { useSnackbar } from 'src/lib/hooks';
export interface Props {
  id?: string;
  className?: string;
}

/**
 * Plan Display Rank View
 */
export const PlanDriverRankView: React.FC<Props> = ({ className: parentClass }) => {
  const id = _.kebabCase('PlanDriverRankView');
  const className = id;
  const snackbar = useSnackbar();
  const [itemList, setItemList] = useState<Item[]>([]);
  const [orderChanged, setOrderChanged] = useState(false);
  const { data: statusOptions } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'plans',
          },
          columnName: {
            eq: 'plan_status',
          },
          columnValue: {
            eq: 'ACTIVE',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });
  let customClass = className;
  if (parentClass) customClass += ` ${parentClass}`;
  const planStatus = statusOptions?.listFalconConstraints?.edges?.[0] || null;
  const planStatusId = planStatus?.id ? { eq: parseInt(planStatus?.id) } : '';
  const input = {
    pageSize: 999,
    filter: {
      driverRank: {
        gte: 0,
      },
      displayToCustomer: {
        eq: true,
      },
      displayPlanCard: {
        eq: true,
      },
      planStatusId,
    },
    sort: {
      driverRank: 'ASC',
    },
  };

  const [listPlanOptions, { data: planOptions, loading: planOptionsLoading }] = useLazyQuery<Query>(LIST_PLANS, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (planStatus?.id) {
      listPlanOptions({
        variables: {
          input,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPlanOptions, planStatus]);

  const [updatePlanDriverRank, { loading: updatePlanDriverRankLoading }] = useMutation(UPDATE_PLAN_DRIVER_RANK, {
    onCompleted: (data) => {
      if (data && data.updatePlanDriverRank && data.updatePlanDriverRank.success) {
        snackbar.success(data.updatePlanDriverRank.message);
      } else {
        snackbar.error(data.updatePlanDriverRank.message);
      }
    },
  });
  const handleDriverRankUpdated = useCallback(() => {
    updatePlanDriverRank({
      variables: {
        input: itemList.map((item) => ({
          altId: item.id,
          driverRank: item.order,
        })),
      },
    });
    setOrderChanged(false);
  }, [updatePlanDriverRank, itemList, setOrderChanged]);

  const { edges } = planOptions?.listPlans || { edges: [] };
  const formattedItems = edges
    ?.map((plan) => {
      const { altId, planDisplayName, driverRank, planDescription, planName } = plan || {};
      return {
        id: altId as string,
        name: planName as string,
        label: planDisplayName as string,
        description: planDescription as string,
        order: driverRank as number,
      };
    })
    .sort((a, b) => a.order - b.order) as Item[];

  return (
    <PageContent data-testid="PlanDriverRankView">
      <Grid item>
        <Box pt={2}>
          <Typography variant="h5">Plan Display Rank Management</Typography>
          <small> Reorder plans by clicking the arrows up or down then saving that order.</small>
        </Box>
        <Box mt={2} mb={4}>
          <Divider />
        </Box>
      </Grid>
      <Paper className={customClass}>
        <Box display="flex" width={'100%'} mb={4} mr={2} flexDirection="row-reverse">
          {planOptionsLoading ? (
            ''
          ) : (
            <Grid container>
              <ReorderableList
                items={itemList.length > 0 ? itemList : formattedItems}
                onOrderChange={(items) => {
                  setItemList(items);
                  setOrderChanged(true);
                }}
              />
            </Grid>
          )}
        </Box>
      </Paper>
      <Footer message={orderChanged ? 'The rank has not been saved.' : ''}>
        <Button
          className={className}
          color="secondary"
          onClick={handleDriverRankUpdated}
          disabled={updatePlanDriverRankLoading || !orderChanged}
          variant="contained"
        >
          Save Rank
        </Button>
      </Footer>
    </PageContent>
  );
};
