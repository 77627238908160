import { gql } from '@apollo/client';

export const listSessionsForMap = gql`
  query listSessionsForMap($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        connector {
          evse {
            charger {
              site {
                latitude
                longitude
                administrativeArea
                siteName
              }
            }
          }
        }
      }
    }
  }
`;
