import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { theme } from '@evgo/react-material-components';
import clsx from 'clsx';
import styled from 'styled-components';
import React, { useState, MouseEvent } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { PopoverOrigin } from '@material-ui/core/Popover/Popover';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { InputAdornment, Popover, TextField } from '@material-ui/core';
import { DateRangePicker as BaseDateRangePicker, DateRangePickerProps, Range, RangeKeyDict } from 'react-date-range';
import { toDisplayFormat } from './utils';

const { spacing } = theme;
export type DateRangeValue = {
  startDate?: Date;
  endDate?: Date;
};

export type DateRangeFieldProps = {
  id?: string;
  className?: string;
  value?: DateRangeValue;
  onChange?: (range: DateRangeValue) => void;
  // See date-fns format
  dateDisplayFormat?: string;
  label?: React.ReactNode;
  placeholder?: string;
  PopoverProps?: {
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
  };
  TextFieldProps?: Partial<TextFieldProps>;
  DateRangePickerProps?: Partial<DateRangePickerProps>;
};

const DateRangeFieldContainer = styled.div`
  &.DateRangeField-root {
    display: inline-flex;
  }
`;

const StyledTextField = styled(TextField)`
  label {
    font-size: ${theme.typography.fontSize}px;
    transform: scale(1);
  }
  input {
    margin-top: ${spacing(1)}px;
  }
`;

export function DateRangeField(props: DateRangeFieldProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { dateDisplayFormat = 'MM/dd/yyyy', label = 'Date Range', placeholder } = props;

  const range: Range = {
    key: 'selection',
    startDate: props?.value?.startDate,
    endDate: props?.value?.endDate,
  };

  const onClose = () => setAnchorEl(null);

  const onChange = ({ selection }: RangeKeyDict) =>
    props.onChange?.({
      startDate: selection.startDate,
      endDate: selection.endDate,
    });

  const onClick = (e: MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget);

  const displayValue = toDisplayFormat(range, dateDisplayFormat);

  const anchorOrigin = props.PopoverProps?.anchorOrigin || {
    vertical: 'bottom',
    horizontal: 'center',
  };

  const transformOrigin = props.PopoverProps?.transformOrigin || {
    vertical: 'top',
    horizontal: 'center',
  };

  return (
    <DateRangeFieldContainer className={clsx('DateRangeField-root', props.className)}>
      <div date-testid={`${props.id || props.className}-date`}>
        <StyledTextField
          label={label}
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon />
              </InputAdornment>
            ),
          }}
          {...props.TextFieldProps}
          value={displayValue}
          onClick={onClick}
        />

        <Popover
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <BaseDateRangePicker {...props.DateRangePickerProps} ranges={[range]} onChange={onChange} />
        </Popover>
      </div>
    </DateRangeFieldContainer>
  );
}
