import { theme } from '@evgo/react-material-components';
import { FormControl, Box } from '@material-ui/core';
import styled from 'styled-components';

const { spacing } = theme;

export const StyledFormControl = styled(FormControl)`
  min-height: ${spacing(10)}px;
  width: ${spacing(20)}px;

  label {
    font-size: ${theme.typography.fontSize}px;
    transform: scale(1);
  }

  .select {
    font-size: ${theme.typography.fontSize}px;
    margin-top: ${spacing(2.5)}px;
    cursor: pointer;
    div {
      padding-right: ${spacing(2.5)}px;
      display: block;
    }
  }

  .clear-options {
    margin-top: ${spacing(0.5)}px;
    cursor: pointer;
  }
`;

export const StyledBox = styled(Box)`
  position: absolute;
  right: 0;
  top: 20px;
  background-color: ${theme.palette.common.white};
`;
