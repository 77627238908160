import styled from 'styled-components';
import config from '../../../../config';
import { theme } from '@evgo/react-material-components';
const { palette, spacing } = theme;

export const Styled = styled.div`
  h2 {
    color: ${config.colors.accentBlue};
  }
  h3 {
    width: calc(100% - ${spacing() * 2}px);
    padding: ${`${spacing(2)}px ${spacing()}px 0`};
    color: ${palette.text.secondary};
  }

  .stepper {
    background: none;
  }
  .stepper .active .MuiStepLabel-label,
  .stepper .active .MuiStepIcon-active {
    color: ${theme.palette.secondary.main};
  }
  .form-card {
    padding: 0;
  }
`;
