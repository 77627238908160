import { TextInfo } from '@evgo/react-material-components';
import { FormikProps, useFormik } from 'formik';
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { Styled } from './styles';

export interface Props {
  className?: string;
  values: Values;
  onChange?: (values: Values) => void;
  disabled?: boolean;
}

interface Values {
  from: string;
  to: string;
}

const validationSchema = Yup.object().shape({
  from: Yup.string(),
  to: Yup.string(),
});

export const update = (
  field: keyof Values,
  target: React.ChangeEvent<HTMLInputElement>['target'],
  values: Values,
  formik: FormikProps<Values>,
  onChange: (vals: Values) => void,
): void => {
  const value = target?.value || values[field];
  const newEditing = { ...values, [field]: value };

  if (!value) {
    _.unset(newEditing, field);
  }

  formik.setValues(newEditing);
  onChange?.(newEditing);
};

export const TimePickers: React.FC<Props> = (props) => {
  const { disabled } = props;
  const className = 'timepickers-component';

  const formik = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      ...(!props?.values?.to && !props?.values?.from
        ? {
            from: '09:00',
            to: '17:00',
          }
        : props.values),
    },
    validationSchema,
    validate: ({ from, to }: Values) => {
      const errs = {} as Values;

      if (from > to) {
        errs.to = 'Must be greater than From time.';
      }
      if (!from || from === '0') {
        errs.from = 'From time must be set.';
      }
      if (!to || to === '0') {
        errs.to = 'To time must be set.';
      }

      return errs;
    },
    onSubmit: () => {},
  });

  let customClass = className;

  if (props.className) {
    customClass += ` ${props.className}`;
  }

  return (
    <Styled className={customClass}>
      <TextInfo
        label="From"
        name="from"
        className={className}
        disabled={disabled}
        error={!!formik?.errors?.from}
        helpertext={formik?.errors?.from}
        type="time"
        onChange={formik.handleChange}
        value={formik?.values?.from}
        inputProps={{
          step: 300, // 5 min
          style: { color: disabled ? 'rgba(0, 0, 0, 0.3)' : 'currentColor' },
          disabled,
        }}
        labelProps={{ className }}
      />

      <TextInfo
        label="To"
        className={className}
        disabled={disabled}
        name="to"
        error={!!formik?.errors?.to}
        helpertext={formik?.errors?.to}
        type="time"
        onChange={formik.handleChange}
        value={formik?.values?.to}
        inputProps={{
          step: 300, // 5 min
          style: { color: disabled ? 'rgba(0, 0, 0, 0.3)' : 'currentColor' },
          disabled,
        }}
        labelProps={{ className }}
      />
    </Styled>
  );
};
