import { Button } from '@material-ui/core';
import { AddBox, BorderColor, PlayCircleOutline, Replay } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Maybe } from 'src/@types';
import { AddCardParams, LabelValue } from 'src/@types/shared';
import { AddCardsModal } from '../AddCardsModal';
import { AssignCardsModal } from '../AssignCardsModal';
import { ConfirmCardsModal } from '../ConfirmCardsModal';
import { Styled as StyledPaper } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

// const { edges, ...metadata } = _.get(data, 'listCards', {});
export interface Props {
  selected: string[];
  updateCardsStatus: (fn: (() => void) | undefined) => void;
  assignCardsUpdate: (input: unknown, updateFunction: unknown) => void;
  data: Maybe<Array<Maybe<Card>>>;
  assignCards: () => void;
  unassignCards: () => void;
  activateCards: () => void;
  deactivateCards: () => void;
  reserveCards: () => void;
  suspendCards: () => void;
  brands: LabelValue<string>[];
  addCard: (p: AddCardParams) => void;
  className?: string;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (ref: React.RefObject<HTMLDivElement>, setExpanded: (e: boolean) => void): void => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

/**
 * Chargers actions component.
 */
export const CardActions: React.FC<Props> = (props) => {
  const {
    selected,
    updateCardsStatus,
    assignCardsUpdate,
    data,
    assignCards,
    unassignCards,
    activateCards,
    deactivateCards,
    reserveCards,
    suspendCards,
    brands,
    addCard,
  } = props;
  const className = 'cardactions-component' as string;
  const [showAssignedModal, setShowAssignedModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAddCardsModal, setShowAddCardsModal] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [expanded, setExpanded] = useState(false);

  let assignDisabled = false;
  let activateDisabled = false;
  let deactivateDisabled = false;
  let unassignDisabled = false;
  let suspendDisabled = false;
  let reserveDisabled = false;
  const cards = [] as Maybe<Card>[];

  if (data && !_.isEmpty(data) && !_.isEmpty(selected)) {
    data.forEach((card) => {
      if (!card) {
        return;
      }
      if (selected.includes(card.altId)) {
        if (card.cardStatus === 'ACTIVE') {
          assignDisabled = true;
          activateDisabled = true;
          reserveDisabled = true;
        }
        if (card.cardStatus === 'DEACTIVATED') {
          assignDisabled = true;
          activateDisabled = true;
          deactivateDisabled = true;
          unassignDisabled = true;
          suspendDisabled = true;
          reserveDisabled = true;
        }
        if (card.cardStatus === 'RESERVED') {
          activateDisabled = true;
          reserveDisabled = true;
        }
        if (card.cardStatus === 'SUSPENDED') {
          assignDisabled = true;
          suspendDisabled = true;
          reserveDisabled = true;
        }
        if (card.cardStatus === 'UNASSIGNED') {
          activateDisabled = true;
          unassignDisabled = true;
          suspendDisabled = true;
        }
      }
    });
    data.forEach((card) => {
      if (!card) {
        return;
      }
      if (selected.includes(card.altId)) {
        cards.push(card);
      }
    });
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setExpanded);

  const showModal = () => {
    setShowAssignedModal(true);
  };
  // updateCardsStatus(assignCards);
  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  if (expanded) customClass += ' open';

  const { modalProps } = useModalContext();

  return (
    <StyledPaper className={`${customClass} card-action-section`} component="section">
      <div className={className}>
        <div ref={wrapperRef} className={`${className} actions`} onClick={() => setExpanded(!expanded)}>
          <Button
            className={`${className} ${_.isEmpty(selected) || assignDisabled ? 'disabled' : ''}`}
            size="large"
            // disabled={_.isEmpty(selected) || !expanded || assignDisabled}
            onClick={() => {
              showModal();
              setExpanded(!expanded);
            }}
            data-testid="card-actions-assign-button"
          >
            <BorderColor className={`${className} left`} />
            Assign
          </Button>

          <Button
            className={`${className} ${_.isEmpty(selected) || activateDisabled ? 'disabled' : ''}`}
            size="large"
            disabled={_.isEmpty(selected) || !expanded || activateDisabled}
            onClick={() => {
              setShowConfirmationModal(true);
              setCurrentAction('activate');
            }}
            data-testid="card-actions-activate-button"
          >
            <BorderColor className={`${className} left`} />
            Activate
          </Button>

          <Button
            className={`${className} ${_.isEmpty(selected) || deactivateDisabled ? 'disabled' : ''}`}
            size="large"
            disabled={_.isEmpty(selected) || !expanded || deactivateDisabled}
            onClick={() => {
              setShowConfirmationModal(true);
              setCurrentAction('deactivate');
            }}
            data-testid="card-actions-deactivate-button"
          >
            <Replay className={`${className} left`} />
            Deactivate
          </Button>

          <Button
            className={`${className} ${_.isEmpty(selected) || unassignDisabled ? 'disabled' : ''}`}
            size="large"
            disabled={_.isEmpty(selected) || !expanded || unassignDisabled}
            onClick={() => {
              setShowConfirmationModal(true);
              setCurrentAction('unassign');
            }}
            data-testid="card-actions-unassign-button"
          >
            <AddBox className={`${className} left`} />
            Unassign
          </Button>

          <Button
            className={`${className} ${_.isEmpty(selected) || suspendDisabled ? 'disabled' : ''}`}
            size="large"
            disabled={_.isEmpty(selected) || !expanded || suspendDisabled}
            onClick={() => {
              setShowConfirmationModal(true);
              setCurrentAction('suspend');
            }}
            data-testid="card-actions-suspend-button"
          >
            <PlayCircleOutline className={`${className} left`} />
            Suspend
          </Button>

          <Button
            className={`${className} ${_.isEmpty(selected) || reserveDisabled ? 'disabled' : ''}`}
            size="large"
            disabled={_.isEmpty(selected) || !expanded || reserveDisabled}
            onClick={() => {
              setShowConfirmationModal(true);
              setCurrentAction('reserve');
            }}
            data-testid="card-actions-reserve-button"
          >
            <BorderColor className={`${className} left`} />
            Reserve
          </Button>

          <Button
            className={className}
            size="large"
            onClick={() => {
              setShowAddCardsModal(true);
            }}
            data-testid="card-actions-add-card-button"
          >
            <AddBox className={`${className} left`} />
            Add Card
          </Button>
        </div>
        <AssignCardsModal
          className={className}
          open={showAssignedModal}
          assignCardsUpdate={assignCardsUpdate}
          modalProps={modalProps}
          cards={cards}
          selected={selected}
          assignCards={assignCards}
          closeModal={() => {
            setShowAssignedModal(false);
          }}
        />
        <ConfirmCardsModal
          className={className}
          open={showConfirmationModal}
          updateCardsStatus={updateCardsStatus}
          unassignCards={unassignCards}
          activateCards={activateCards}
          deactivateCards={deactivateCards}
          reserveCards={reserveCards}
          suspendCards={suspendCards}
          modalProps={modalProps}
          cards={cards}
          action={currentAction}
          closeModal={() => {
            setShowConfirmationModal(false);
            setCurrentAction('');
          }}
        />
        <AddCardsModal
          className={className}
          open={showAddCardsModal}
          assignCardsUpdate={assignCardsUpdate}
          modalProps={modalProps}
          addCard={(input) => addCard(input)}
          brands={brands}
          closeModal={() => {
            setShowAddCardsModal(false);
          }}
        />
      </div>
    </StyledPaper>
  );
};
