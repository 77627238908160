import styled from 'styled-components';
import { useMemo } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { SessionAggregation } from 'src/@types';
import { useGetSessionsConsumptionForExtend } from 'src/lib/hooks/useGetSessionsConsumptionForExtend';
import { Card, CardHeader } from 'src/components/shared/Card';
import { BarChart } from 'src/components/shared/UtilizationBarGraph';
import { getGraphData } from './helpers';
import { dateRange } from '../helpers';

type AverageSessionByHourGraphProps = {
  timePeriod: number;
  timeFrame: string;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

export const AverageSessionByHourGraph = ({ timePeriod, timeFrame }: AverageSessionByHourGraphProps) => {
  const input = {
    aggregateBy: SessionAggregation.Hour,
    ...dateRange(timePeriod),
  };

  const { data, loading } = useGetSessionsConsumptionForExtend({ input });

  // this is a slight perf optimization as data changes when time period changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const graphData = useMemo(() => getGraphData(data, timePeriod), [data]);
  return (
    <StyledCard data-testid="sessions-by-hour">
      <CardHeader
        title="Average Sessions by Hour"
        subtitle={
          <Box display="flex" flexDirection="column">
            Average number of sessions by hour for {timeFrame}
          </Box>
        }
      />

      {loading ? (
        <Box display="flex" justifyContent="center" data-testid="average-session-by-hour-graph-loading">
          <CircularProgress />
        </Box>
      ) : (
        <BarChart
          id="average-session-by-hour-graph"
          data-testid="average-session-by-hour-graph"
          className="average-session-by-hour-graph"
          captionText=""
          data={graphData}
          color={'#3F657C'}
          height={400}
          verticalCaptionText="# of sessions"
        />
      )}
    </StyledCard>
  );
};
