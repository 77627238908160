import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function EnvironmentIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M32 0H0V32H32V0Z" fill="white" />
      <path
        d="M17.1587 15.7467C16.852 15.24 16.6654 14.64 16.6654 14C16.6654 12.16 18.1587 10.6667 19.9987 10.6667H21.332C23.3454 10.6667 23.9987 9.33333 23.9987 9.33333C23.9987 9.33333 24.732 17.3333 19.9987 17.3333C19.3454 17.3333 18.7454 17.1467 18.2387 16.8267C17.9187 17.68 17.452 19.1733 17.2254 20.7733C18.9054 21.0667 20.2654 21.96 20.9187 23.1333C23.172 21.5733 24.6654 18.96 24.6654 16H28.6654C28.6654 22.9867 22.9854 28.6667 15.9987 28.6667C9.01203 28.6667 3.33203 22.9867 3.33203 16C3.33203 9.01333 9.01203 3.33333 15.9987 3.33333V0L21.332 5.33333L15.9987 10.6667V7.33333C11.2254 7.33333 7.33203 11.2267 7.33203 16C7.33203 18.9467 8.81203 21.56 11.0787 23.1333C11.7587 21.9067 13.252 20.9733 15.052 20.7333C14.932 19.8133 14.7054 18.84 14.3987 18.0267C13.932 18.4267 13.332 18.6667 12.6654 18.6667C11.1987 18.6667 9.9987 17.4667 9.9987 16V14.68C9.9987 13.9333 9.74536 13.2267 9.33203 12.6667C9.33203 12.6667 15.2654 12.36 15.332 16C15.332 16.3867 15.252 16.7467 15.1054 17.0667C14.5454 16.64 13.9587 16.2667 13.332 16C14.1054 16.5733 15.1587 17.8267 15.9987 19.4667C16.892 17.3067 18.2387 15.1067 19.9987 13.3333C18.9854 14.0267 18.0387 14.8267 17.1587 15.7467Z"
        fill="#212121"
      />
    </SvgIcon>
  );
}
