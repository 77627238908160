/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { AppBar, Button, Tab, Tabs } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Query } from 'src/@types';
import { listPlanOptions } from '../../../../apollo/queries/options';
import { getPlan } from '../../../../apollo/queries/plans';
import { transformPlanResponse } from '../../../../lib/helpers';
import { Footer } from '../../../shared/Footer';
import { PlanDetailsTab } from './PlanDetailsTab';
import { PlanDisplayTab } from './PlanDisplayTab';
import { PlanHeader } from './PlanHeader';
import { PlanStatus } from './PlanStatus';
import { Styled } from './styles';

export interface Props {
  className?: string;
}

/** Formik status fields */
export const statusFields = ['createdAt'];

/**
 * Plans form component
 */
export const PlanForm: React.FC<Props> = ({ className: parentClass }) => {
  const id = _.kebabCase('PlanForm');
  const className = id;
  const location = useLocation();
  const planId = (location.state as any)?.planId;
  const { tab } = useParams<{ tab: string }>();
  let { altId } = useParams<{ altId: string }>();

  if (planId) altId = planId;

  const { data: planData, loading: planloading } = useQuery<Query>(getPlan, {
    fetchPolicy: 'network-only',
    skip: !altId,
    variables: { planInput: { altId } },
  });
  const { loading: planOptionsloading } = useQuery<Query>(listPlanOptions);
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const { isSubmitting, resetForm, status } = useFormikContext();

  const loading = planloading || planOptionsloading;
  let customClass = className;

  if (parentClass) customClass += ` ${parentClass}`;

  let activeTab = <PlanDetailsTab />;
  if (tab === 'details') activeTab = <PlanDetailsTab />;
  if (tab === 'display') activeTab = <PlanDisplayTab />;

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newTab: string) => {
    navigate(`/plans/${altId}/${newTab}`, { replace: true });
  };

  // Handles populating the form with values from the server once everyting is loaded
  useEffect(() => {
    if (!loading && planData) {
      const planValues = transformPlanResponse(planData?.getPlan || {});

      if (planId) {
        planValues.planName = `Copy of ${planValues.planName}`;
        planValues.planCode = '';
      }

      resetForm({ status: planValues, values: _.omit(planValues, statusFields) });
    }
  }, [loading, resetForm, planData, planId]);

  return (
    <Styled>
      <PlanHeader id={`${id}-header`} className={className} loading={loading} />
      {altId && (
        <AppBar className={`${className} app-bar`}>
          <Tabs className={className} value={tab || 'details'} onChange={handleChangeTab}>
            <Tab id={`${id}-detail-tab`} className={className} label="Details" value="details" />
            <Tab id={`${id}-display-tab`} className={className} label="Display" value="display" />
          </Tabs>
        </AppBar>
      )}

      <Form id={`${id}-form`} className={customClass}>
        {!loading && (
          <Fragment>
            {tab === 'details' && !_.isEmpty(_.get(status, 'altId', '')) && <PlanStatus className={className} />}
            {activeTab}
          </Fragment>
        )}

        <Footer className={className}>
          <Button
            className={className}
            color="primary"
            disabled={isSubmitting}
            id={`${id}-cancel-button`}
            onClick={goBack}
            size="large"
            data-testid="plans-cancel-button"
          >
            Cancel
          </Button>

          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-save-button`}
            size="large"
            type="submit"
            variant="contained"
            data-testid="plans-save-button"
          >
            <RefreshIcon className={`${className}${isSubmitting ? '' : ' hidden'}`} />
            <span className={`${className}${isSubmitting ? ' hidden' : ''}`}>Save</span>
          </Button>
        </Footer>
      </Form>
    </Styled>
  );
};
