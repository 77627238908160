import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { createSite, updateSite } from '../../../apollo/queries/sites';
import { transformSiteResponse, transformSiteValues } from '../../../lib/helpers';
import { useFormikSubmit } from '../../../lib/hooks';
import { initialValues, SiteForm } from './SiteForm';
import { validations } from './SiteForm/validations';
import { Styled } from './styles';

export interface Props {
  className?: string;
}

/**
 * Site view component
 */
export const SiteView: React.FC<Props> = () => {
  const { altId } = useParams<{ altId: string }>();
  const className = 'SiteView';
  const handleSubmit = useFormikSubmit('Site', createSite, updateSite, {
    transformValues: transformSiteValues,
    transformResponse: transformSiteResponse,
    altId,
  }) as never;

  return (
    <Styled className={className}>
      <Formik
        initialStatus={initialValues}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        <SiteForm />
      </Formik>
    </Styled>
  );
};
