import { gql } from '@apollo/client';

export const listNotificationGroups = gql`
  query listNotificationGroups(
    $notificationGroupsInput: ListNotificationGroupsInput
    $notificationItemsInput: ListNotificationItemsInput
  ) {
    listNotificationGroups(input: $notificationGroupsInput) {
      edges {
        groupName
        sortOrder
        notificationItems(input: $notificationItemsInput) {
          edges {
            id
            notificationName
            notificationDesc
            onePrefRequired
          }
        }
      }
    }
  }
`;
