import { Connector } from 'src/@types';

export interface LocalEvses {
  altId?: string;
  connectors: Connector[];
}

export const initialValues = {
  isoIec: false,
  modelSku: '',
  nfcReader: false,
  smartEnergyProfileSupport: false,
  autoChargeSupported: false,
  simultaneousChargeSupported: false,
  evses: [
    {
      connectors: [{}],
    } as LocalEvses,
  ],
  additionalDetails: '',
  breakerRating: '',
  communicationProtocol: '',
  currentThd: '',
  dimensions: '',
  display: '',
  efficiency: '',
  firmwareVersion: '',
  ingressProtection: '',
  inputAcVoltage: '',
  interfaceVersion: '',
  manufacturer: '',
  modelName: '',
  networkCommunication: '',
  powerFactor: '',
  powerOutput: '',
  unitType: '',
  weight: '',
};
