import { Day } from 'src/@types';

export const WeekViewComponentFactory = () => {
  return [
    {
      index: 0,
      name: 'CustomPrice1',
      pricing: {
        altId: '123',
        perMinute: 5.5,
        perSession: 5,
        perKwh: 10.25,
      },
      tariffSchedules: [
        {
          startTime: '05:30',
          endTime: '12:30',
          daysOfWeek: [Day.Monday, Day.Tuesday, Day.Friday, Day.Saturday],
        },
      ],
      altId: '123',
    },
    {
      index: 1,
      name: 'CustomPrice2WithLongText',
      pricing: {
        altId: '123',
        perMinute: 5.5,
        perSession: 5,
        perKwh: 10.25,
      },
      tariffSchedules: [
        {
          startTime: '03:30',
          endTime: '04:30',
          daysOfWeek: [Day.Monday, Day.Thursday, Day.Friday, Day.Saturday],
        },
      ],
      altId: '123',
    },
    {
      index: 2,
      name: 'CustomPrice3',
      pricing: {
        altId: '123',
        perMinute: 5.5,
        perSession: 5,
        perKwh: 10.25,
      },
      tariffSchedules: [
        {
          startTime: '10:30',
          endTime: '18:30',
          daysOfWeek: [Day.Monday, Day.Tuesday, Day.Thursday],
        },
        {
          startTime: '20:30',
          endTime: '22:30',
          daysOfWeek: [Day.Tuesday, Day.Thursday],
        },
      ],
      altId: '123',
    },
  ];
};
