import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../config';

const { palette, spacing } = theme;

export const Styled = styled(Paper)`
  header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};

    div {
      &:first-child {
        flex: 1;
      }

      &:last-child {
        display: flex;
        align-items: center;
        align-content: center;

        * {
          margin: 0 ${spacing()}px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    h2 {
      color: ${config.colors.accentBlue};
    }

    span {
      color: ${palette.text.secondary};
    }
  }

  table {
    td,
    th {
      &.name {
        width: 15%;
      }

      &.status {
        width: 15%;
      }

      &.address {
        width: 20%;
      }

      &.pid {
        width: 5%;
      }

      &.totalChargers {
        width: 1%;
      }

      &.totalSites {
        width: 1%;
      }

      &.expand {
        width: 1%;
      }
    }

    .select {
      padding-right: 24px;
      text-align: right;
    }
  }
`;
