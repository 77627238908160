import { gql } from '@apollo/client';

export const listFalconConstraints = gql`
  query listFalconConstraints($optionsInput: ListFalconConstraintsInput) {
    listFalconConstraints(input: $optionsInput) {
      edges {
        id
        columnName
        columnText
        columnValue
      }
    }
  }
`;
