import React from 'react';
import { PageContent } from 'src/components/shared/PageContent';
import { Styled } from './styles';
import { Box, Typography, Stepper, Step, StepLabel, Button, Snackbar } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import { searchFirebaseAndDriivzAndFalconAccounts as searchServices } from 'src/apollo/queries/accounts';
import { useLazyQuery } from '@apollo/client';
import { AccountStatus, Driver } from 'src/@types';
import { AccountCreateForm } from './AccountCreateForm';
import { validations as validationSchema } from './validations';
import { initialValues } from './initialValues';
import { Alert } from '@material-ui/lab';
const steps = ['Enter Driver Data', 'Review and Save Driver Data'];

export type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  altId: string;
  accountStatus: AccountStatus;
};
export type SearchResults = {
  falcon: Driver[];
  driivz: Driver[];
  firebase: Driver[];
};

export const AccountCreate: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeAccount, setActiveAccount] = React.useState<Driver>();
  const [snackbarInfo, setSnackbarInfo] = React.useState({
    snackMessage: '',
    snackVisible: false,
    snackbarType: 'success',
  });

  const [listSearchResults] = useLazyQuery(searchServices, {
    fetchPolicy: 'network-only',
    onError() {
      setSnackbarInfo({
        snackMessage: 'An error occurred while searching for accounts. Please try again.',
        snackVisible: true,
        snackbarType: 'error',
      });
    },
  });

  const handleNextStep = async (formValues: FormValues, results: SearchResults) => {
    const falconResults = results?.falcon || [];
    const driivzResults = results?.driivz || [];
    const firebaseResults = results?.firebase || [];

    let snackMessage = '';
    let snackbarType = '';
    let fieldDiffValue = 'Email Address and Phone Number';
    if (
      falconResults[0]?.phone === formValues?.phone &&
      falconResults[0]?.email?.toLowerCase() !== formValues?.email?.toLowerCase()
    ) {
      fieldDiffValue = 'Phone Number';
    }
    if (
      falconResults[0]?.email?.toLowerCase() == formValues?.email?.toLowerCase() &&
      falconResults[0]?.phone !== formValues?.phone
    ) {
      fieldDiffValue = 'Email Address';
    }

    switch (true) {
      case falconResults.length < 1 && driivzResults.length < 1 && firebaseResults.length < 1:
        setActiveStep(1);
        snackMessage = 'Driver data does not match any account. Please create an account.';
        snackbarType = 'success';
        break;
      case falconResults.length === 1:
        if (driivzResults.length < 1 && firebaseResults.length < 1) {
          snackMessage = `An account with this ${fieldDiffValue} already exists, but the account may not be synced to Driivz or Firebase. Would you like to view the Driver Profile?`;
          snackbarType = 'success';
          setActiveAccount(falconResults[0]);
        } else {
          snackMessage = `An account with this ${fieldDiffValue} already exists. Would you like to view the Driver Profile?`;
          snackbarType = 'success';
          setActiveAccount(falconResults[0]);
        }
        break;
      case falconResults.length < 1 && (driivzResults.length === 1 || firebaseResults.length === 1):
        setActiveStep(1);
        snackMessage =
          'Driver data matches account information, needs to be consolidated. Please update the Consolidate Account Information.';
        snackbarType = 'warning';
        break;
      case falconResults.length > 1 || driivzResults.length > 1 || firebaseResults.length > 1:
        setActiveStep(1);
        snackMessage =
          'Driver data matches multiple accounts. Please reconcile duplicate or inaccurate information in Driivz or Firebase.';
        snackbarType = 'error';
        break;
      default:
        snackMessage = 'Something';
        snackbarType = 'warning';
        break;
    }
    setSnackbarInfo({
      snackMessage: snackMessage,
      snackVisible: true,
      snackbarType: snackbarType,
    });
  };

  const handleSubmit = async (formValues: FormValues, {}: FormikHelpers<FormValues>) => {
    try {
      const results = await listSearchResults({
        variables: {
          email: formValues?.email,
          phoneNumber: formValues?.phone,
        },
      });
      handleNextStep(formValues, results?.data?.searchFirebaseAndDriivzAndFalconAccounts);
    } catch (error) {
      setActiveAccount(undefined);
      await setSnackbarInfo({
        snackMessage: 'An error occurred while searching for accounts. Please try again.',
        snackVisible: true,
        snackbarType: 'error',
      });
    }
  };

  return (
    <PageContent data-testid="AccountCreateForm">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnMount>
        <Styled>
          <Box marginBottom={4}>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={snackbarInfo?.snackVisible}
              autoHideDuration={6000}
              onClose={() => setSnackbarInfo({ snackMessage: '', snackbarType: 'success', snackVisible: false })}
            >
              <Alert severity={snackbarInfo.snackbarType === 'success' ? 'success' : 'error'}>
                {snackbarInfo?.snackMessage}
                {activeAccount && (
                  <Button
                    color="secondary"
                    size="small"
                    target="_blank"
                    href={
                      activeAccount?.driverType === 'OWNER'
                        ? `/accounts/${activeAccount?.account?.altId}`
                        : `/drivers/${activeAccount?.altId}`
                    }
                  >
                    View Profile
                  </Button>
                )}
              </Alert>
            </Snackbar>
            <Stepper activeStep={activeStep} className="stepper">
              {steps.map((label, step) => {
                return (
                  <Step key={label} className={`stepper ${step > activeStep ? '' : 'active'}`}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep === 0 && <AccountCreateForm />}

          {activeStep === 1 && (
            <Box textAlign="left" my={4}>
              <Typography variant="h6" component="h2">
                Review Driver Data
              </Typography>
            </Box>
          )}
        </Styled>
      </Formik>
    </PageContent>
  );
};
