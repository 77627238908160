import { defaultPlanElement } from '../../../shared/PlanElement';

export const initialValues = {
  cardBrand: 'EVgo',
  constFactor: null,
  description: '',
  displayName: '',
  displayToCustomer: false,
  duration: '',
  pageHeader: '',
  detailSections: [],
  detailSessionTimes: [],
  elements: [defaultPlanElement],
  enrollmentClosed: '',
  enrollmentOpen: '',
  financeCode: '',
  planCode: '',
  planName: '',
  products: [],
  requireVin: false,
  rolloverPlanId: '',
  terminationBehavior: 'Expires',
  templateId: null,
  planStatusId: undefined as number | undefined,
  cardBrandId: undefined as number | undefined,
  intervalUnitId: undefined as number | undefined,
  terminationBehaviorId: undefined as number | undefined,
  isAutoEnrolledInRewards: true,
  balanceTypeId: undefined as number | undefined,
};
