import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Styled = styled(Paper)`
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  box-sizing: border-box;
  margin: 0 20px 20px;
  min-width: 200px;
  max-width: 250px;
  position: relative;

  .select {
    margin: 0px 10px;
    font-size: 14px;
    min-width: 200px;
    max-width: 250px;

    &::before {
      content: '';
      border-bottom: none;
    }
  }

  .clear-all {
    position: absolute;
    cursor: pointer;
    top: 40px;
  }
`;
