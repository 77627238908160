/* stylelint-disable color-no-hex */
import React from 'react';
import styled from 'styled-components';
import { ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import { theme } from '@evgo/react-material-components';
import { Box } from '@material-ui/core';

import { TableLink } from 'src/components/shared/TableLink';
import { Table, Column } from 'src/components/shared/Table';
import { Card, CardHeader } from 'src/components/shared/Card';
import { ConnectorStatusIcon } from 'src/components/shared/ConnectorStatusIcon';

import { ChargerConnector } from './ChargerConnector';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { Evse } from '../helpers';
import { Check } from '@material-ui/icons';

type ChargersListProps = {
  data: Array<Evse>;
  loading: boolean;
  error: ApolloError | undefined;
};

const getColumns = (args: { track: (eventName: string) => void }): Column<Evse>[] => {
  return [
    {
      key: 'evseId',
      label: 'Stall ID',
      sortable: false,
      width: '12%',
      tooltip: (
        <>
          <p>Stalls are equivalent to the number of vehicles that can charge at the same time.</p>
          <p>This is also referred to as EVSE ID.</p>
        </>
      ),
    },
    {
      key: 'chargerConnector',
      label: 'Charger Name',
      component: ({ data }) => <ChargerConnector {...data}></ChargerConnector>,
      sortable: false,
      width: '28%',
    },
    { key: 'connectorMaxOutput', label: 'Max kW', sortable: false, width: '12%' },
    {
      key: 'connectorStatus',
      label: 'Connector Status',
      component: ({ data }) => (
        <Box>
          {data?.connectorStatus?.map((conStatus: string, i: number) => (
            <ConnectorStatusIcon
              key={`evse-metric-${data.evseId}-connector-${i}`}
              data-testid={`evse-metric-${data.evseId}-connector-${i}`}
              connectorStatus={conStatus || 'Unknown'}
              displayStatus={false}
              displayTooltip={true}
            />
          ))}
        </Box>
      ),
      sortable: false,
      width: '10%',
      tooltip: (
        <>
          <p>Charger stalls can have more than one connector. </p>
          <p>
            Keep in mind that if a charger stall does not support simultaneous charging, and one of these connectors is
            in use, the other will be marked as Unavailable.
          </p>
        </>
      ),
    },
    {
      key: 'totalSessions',
      label: 'Total Sessions',
      sortable: false,
      width: '10%',
    },
    {
      key: 'totalSessionsTime',
      label: 'Total Session Time',
      sortable: false,
      width: '10%',
      tooltip: 'Total session duration for the day.',
    },
    {
      key: 'totalSessionsPower',
      label: 'Total kWh dispensed',
      sortable: false,
      width: '10%',
      tooltip: 'Total kilowatt-hours of energy dispensed for the day.',
      formatWithRowData: (data) => (data.totalSessionsPower == 0 ? '0' : data.totalSessionsPower.toFixed(4)),
    },
    {
      key: 'sessionVendorId',
      label: 'Active Session ID',
      component: ({ data: { lastSessionVendorId, chargerId, connectorStatus } }) =>
        connectorStatus?.every((connStatus) => connStatus !== 'Charging') ? (
          <>N/A</>
        ) : (
          <TableLink
            to={`/extend-plus/chargers/${chargerId}/transactions`}
            onClick={() => args.track(events.siteDetails.CLICKED_CHARGERS_LIST_SESSION_ID)}
          >
            {lastSessionVendorId}
          </TableLink>
        ),
      sortable: false,
      width: '10%',
      tooltip: 'If a connector is currently charging, the session id will be displayed here.',
    },
    {
      key: 'reservable',
      label: 'Reservable',
      component: ({ data: { reservable } }) => (reservable ? <Check /> : null),
      sortable: false,
      width: '10%',
      tooltip: (
        <div>
          Reservable connectors allow your users to reserve this connector in advance (20 mins max) by paying a specific
          fee.
          <br />
          Set reservation fees on the <Link to={`/extend-plus/charger-prices`}>pricing tab.</Link>
        </div>
      ),
    },
  ];
};

const Styled = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

export const ChargersList: React.FC<ChargersListProps> = (props) => {
  const { track } = useAnalytics();
  const columns = getColumns({ track });

  return (
    <Styled data-testid="todays-charger-metrics-card">
      <CardHeader title="Today’s Charger Metrics" />
      <Table columns={columns} {...props} data-testid="todays-charger-metrics-table" />
    </Styled>
  );
};
