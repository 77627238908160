import React from 'react';
import { auth } from 'src/firebase';
import styled from 'styled-components';
import { Drawer } from '@material-ui/core';
import { useAuthState } from 'react-firebase-hooks/auth';
import { theme } from '@evgo/react-material-components';

import { useRoles } from 'src/lib/hooks';
import { OperatorPortalRole, RoleApp } from 'src/@types/shared';

import { SuperAdminNavItems } from './SuperAdminNavItems';
import { PartnerAdminNavItems } from './PartnerAdminNavItems';

const { spacing } = theme;

interface NavigationProps {
  window?: () => Window;
  isLargeScreen?: boolean;
  isMobileOpen?: boolean;
  handleDrawerToggle?: () => void;
}

const Paper = styled.div`
  margin-top: ${spacing(11)}px;
  width: 200px;

  &.MuiDrawer-paper {
    height: calc(100% - ${theme.spacing(11)}px);
  }
`;

export function Navigation(props: NavigationProps) {
  const [currentUser] = useAuthState(auth);
  const { isLargeScreen, isMobileOpen, handleDrawerToggle } = props;
  const { hasRole } = useRoles();

  const isSuperAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.SuperAdmin);
  const isPartnerAdmin = hasRole(RoleApp.OperatorPortal, OperatorPortalRole.PartnerAdmin);

  const navItems = isSuperAdmin ? (
    <SuperAdminNavItems />
  ) : isPartnerAdmin ? (
    <PartnerAdminNavItems handleDrawerToggle={handleDrawerToggle} />
  ) : (
    <></>
  );

  if (!currentUser) {
    return <></>;
  }

  return (
    <>
      {isLargeScreen ? (
        <Drawer open variant="permanent" PaperProps={{ component: Paper }}>
          {navItems}
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          open={isMobileOpen}
          onClose={handleDrawerToggle}
          PaperProps={{ component: Paper }}
          ModalProps={{ keepMounted: true }}
        >
          {navItems}
        </Drawer>
      )}
    </>
  );
}
