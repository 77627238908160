import { gql } from '@apollo/client';

export const updateDriver = gql`
  mutation updateDriver(
    $driverInput: UpdateDriverInput
    $cardsInput: ListCardsInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    updateDriver(input: $driverInput) {
      id
      altId
      firstName
      lastName
      phone
      email
      cards(input: $cardsInput) {
        edges {
          altId
          brand
          internalNumber
          externalNumber
          cardStatus
          driver {
            altId
            firstName
            lastName
          }
        }
        total
      }
      account {
        id
        altId
        mailingAddress {
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
        accountStatus
      }
      accountVehicles {
        edges {
          altId
          nickName
          vin
          vehicle {
            makeModelYear
          }
        }
      }
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
