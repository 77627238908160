import { initializeApp } from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';
import config from './config';

const firebaseApp = initializeApp(config.env.firebase);

export const app = firebaseApp;
export const auth = firebaseAuth.getAuth(firebaseApp);

if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === '1') {
  firebaseAuth.connectAuthEmulator(auth, 'http://localhost:9099');
}
export const signInWithEmailAndPassword = (email: string, password: string) =>
  firebaseAuth.signInWithEmailAndPassword(auth, email, password);
export const signOut = () => firebaseAuth.signOut(auth);
