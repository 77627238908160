import { gql } from '@apollo/client';

export const importHost = gql`
  mutation importHost($hostInput: ImportHostInput) {
    importHost(input: $hostInput) {
      hid
      altId
      address1
      address2
      locality
      administrativeArea
      hostName
      postalCode
      status
    }
  }
`;
