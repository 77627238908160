/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation } from '@apollo/client';
import { Divider } from '@evgo/react-material-components';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Account, Maybe, Mutation } from 'src/@types';
import { fileUpload } from '../../../../apollo/queries/fileUpload';
import { ListSearch as ImportsListSearch } from '../../../shared/ListSearch';
import { Styled as StyledPaper } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';

export interface Props {
  title: string;
  buttonAction: string;
  className?: string;
}

/**
 * Prompts file import
 */
export const importFile = (fileImport: any, files: Maybe<FileList>): void => {
  if (!files) {
    return;
  }

  const file = files[0];
  if (file) {
    fileImport({ variables: { file } });
  }
};

/**
 * Import Table component
 */
export const ImportTable: React.FC<Props> = (props) => {
  const className = 'ImportTable';

  const { title, buttonAction } = props;

  const columns = [
    { id: 'fileName', label: 'File Name', sortable: true },
    { id: 'dateUploaded', label: 'Time Uploaded', sortable: true },
    { id: 'user', label: 'Uploaded By', sortable: true },
    { id: 'count', label: 'Count', sortable: true },
    { id: 'cardStatus', label: 'Status', sortable: true },
  ];

  const loading = true;
  const accounts = [] as Account[];

  const [fileImport] = useMutation<Mutation>(fileUpload);
  const { setModalState } = useModalContext();

  return (
    <StyledPaper className={className} data-testid="cards-import-table-parent" component="section">
      <header className={className}>
        <div className={className}>
          <Typography className={className} variant="h6" component="h2">
            {title}
          </Typography>
        </div>

        <div className={className}>
          <form encType={'multipart/form-data'} data-testid="cards-import-card-import-form">
            <input
              name={'document'}
              type={'file'}
              accept=".csv"
              onChange={({ target: { files } }) => importFile(fileImport, files)}
            />
            {loading && <p>Loading.....</p>}
          </form>
          <Button
            className={className}
            color="secondary"
            variant="contained"
            data-testid="cards-import-table-import-button"
            onClick={() => setModalState({ modalName: 'importCards', modalVisible: true, modalProps: {} })}
          >
            {buttonAction}
          </Button>
          <ImportsListSearch
            className={`${className} search`}
            type="customer"
            data-testid="cards-import-table-search"
            onSearchChange={() => {}}
          />
        </div>
      </header>

      <Divider />

      <Table className={className} data-testid="cards-import-table">
        <TableHead>
          <TableRow className={className}>
            <TableCell className={`${className} checkbox`}>
              <Checkbox
                className={className}
                // indeterminate={ checked.length > 0 && checked.length < accounts.length }
                // checked={ (checked.length > 0 && checkAll) || (checked.length === accounts.length && checked.length > 0) }
                // onChange={ () => onCheckAll(setChecked, checkAll, setCheckAll, accounts) }
              />
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.id} className={className}>
                <TableSortLabel
                  // active={ !_.isEmpty(_.get(metadata, `sort.${column.id}`)) }
                  // direction={ _.toLower(_.get(metadata, `sort.${column.id}`) || 'asc') }
                  // onClick={
                  //   column.sortable
                  //     ? onSortChange(fetchMore, metadata, column.id)
                  //     : null
                  // }
                  disabled={!column.sortable}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading &&
            accounts?.map((account) => {
              if (!account) {
                return null;
              }

              return (
                <TableRow key={account.altId}>
                  <TableCell className={`${className} checkbox`}>
                    <Checkbox
                      className={`${className} checkbox`}
                      // checked={ checked.indexOf(account.altId) !== -1 }
                      // inputProps={ { 'account-altid': account.altId } }
                      // onChange={ e => onCheck(e, checked, setChecked, setCheckAll, accounts) }
                    />
                  </TableCell>

                  <TableCell className={`${className} customerName`}>{_.get(account, 'customerName')}</TableCell>
                  <TableCell className={`${className} dateRequested`}>{_.get(account, 'dateRequested')}</TableCell>
                  <TableCell className={`${className} accountNumber`}>{_.get(account, 'description')}</TableCell>
                  <TableCell className={`${className} plan`}>Membership</TableCell>
                  <TableCell className={`${className} address`}>{_.get(account, 'address')}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              className={className}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={5}
              rowsPerPage={5}
              page={0}
              SelectProps={{ inputProps: { className: `${className} select` } }}
              onPageChange={() => {}}
              data-testid="cards-import-table-pagination"
              onRowsPerPageChange={() => {}}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledPaper>
  );
};
