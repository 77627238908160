import { theme } from '@evgo/react-material-components';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;

export const Styled = styled(TableRow)`
  tr {
    &.open {
      td {
        border-bottom: transparent;
      }
    }

    td {
      &.site {
        padding-left: ${spacing(5.25)}px;
      }

      a {
        &.property-link,
        &.site-link {
          color: ${config.colors.accentBlue};
        }
      }
      button {
        svg {
          &.left {
            margin-right: ${spacing()}px;
          }
        }
      }
    }
  }
`;
