import { useQuery } from '@apollo/client';
import { Button, IconButton, InputLabel, Typography } from '@material-ui/core';
import { ArrowBack, Face, FilterNone } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Address, Driver, DriversWithMeta, Maybe, Query } from 'src/@types';
import { getAccount } from '../../../../apollo/queries/accounts';
import { formatUSNumber, getFullAddress, titleCase } from '../../../../lib/helpers';
import { Styled } from './styles';

export interface Props {
  className?: string;
  account?: {
    accountStatus: string;
    accountType: string;
    altId: string;
    drivers: {
      edges: {
        firstName: string;
        lastName: string;
        driverType: string;
      }[];
    };
    id: string;
    mailingAddress: {
      address1: string;
      address2: string;
      administrativeArea: string;
      altId: string;
      country: string;
      locality: string;
      postalCode: string;
    };
  };
}

export const copyToClipBoard = (value: string): void => {
  const el = document.createElement('input');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/** Account Edit Header component. */
export const AccountEditHeader: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountEditHeader');
  const className = id;
  const altId = _.get(props, 'params.altId');
  const { loading } = useQuery<Query>(getAccount, {
    variables: {
      accountInput: { altId },
      driversInput: { pageSize: 999, paranoid: false },
      accountVehiclesInput: { pageSize: 999 },
      cardsInput: { pageSize: 999 },
    },
    skip: !altId,
  });

  const { ...drivers } = (props?.account?.drivers || {}) as DriversWithMeta;
  const { ...mailing } = (props?.account?.mailingAddress || {}) as Address;

  let address = '';
  if (!loading && mailing) {
    address = getFullAddress({
      streetAddress: mailing.address1,
      unit: mailing.address2,
      city: mailing.locality,
      state: mailing.administrativeArea,
      postalCode: mailing.postalCode,
      country: mailing.country,
    });
  }

  const accountOwner = (!loading ? _.find(drivers?.edges, { driverType: 'OWNER' }) : null) as Maybe<Driver>;

  const info = (infoClass: string, label: string, value?: Maybe<string>, noCopy = false) => {
    return (
      <article className={className} id={`${id}-${infoClass}-info-section`}>
        <InputLabel className={className} shrink>
          {label}
        </InputLabel>

        <div className={`${className} ${infoClass}`} id={`${id}-${infoClass}`}>
          <Typography className={className}>{value}</Typography>

          {!_.isEmpty(value) && !noCopy && (
            <IconButton
              className={className}
              aria-label={label}
              onClick={() => {
                copyToClipBoard(value || '');
              }}
              id={`${id}-${infoClass}-copy`}
            >
              <FilterNone className={className} />
            </IconButton>
          )}
        </div>
      </article>
    );
  };

  const name = accountOwner ? `${accountOwner?.firstName} ${accountOwner?.lastName}` : 'Create Account';

  return (
    <Styled>
      <header className={className}>
        <div className={className}>
          <section className={`${className} back`}>
            <Button
              className={className}
              id={`${id}-back-button`}
              size="small"
              to="/drivers/list"
              component={Link}
              data-testid={`${id}-all-accounts-back-button`}
            >
              <ArrowBack className={`${className} arrow`} />
              All Accounts
            </Button>
          </section>

          <section className={`${className} title`}>
            <Face className={className} />
            <Typography className={className} id={`${id}-account-name`} data-testid={`${id}-account-name`} variant="h5">
              {name}
            </Typography>
          </section>

          <section className={`${className} info`}>
            {info('account-id', 'Account Number', props?.account?.id)}
            {info('address', 'Address', address)}
            {info('email', 'Email', accountOwner?.email)}
            {info('phoneNumber', 'Phone', formatUSNumber(accountOwner?.phone))}
            {info('account-type', 'Account Type', titleCase(props?.account?.accountType), true)}
            {info('classification', 'Classification', !!props?.account ? 'Account Owner' : '', true)}
          </section>
        </div>
      </header>
    </Styled>
  );
};
