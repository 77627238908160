import { useQuery } from '@apollo/client';
import { Dropdown, TextInfo } from '@evgo/react-material-components';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { listCards } from 'src/apollo/queries/cards';
import { StyledModal } from 'src/components/shared/StyledModal';
import { Styled } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import { Query } from 'src/@types';
import { listFalconConstraints } from 'src/apollo/queries/options';

interface Props {
  open?: boolean;
  className?: string;
  setStatus: Dispatch<SetStateAction<string>>;
}

interface Card {
  altId: string;
  internalNumber: string;
  externalNumber: string;
}

export interface FormValues {
  isSelected: boolean;
  internalNumber: string;
  externalNumber: string;
  cardIds: string[];
  driverId: string;
  cardStatus: string;
}

export const initialValues = {
  isSelected: false,
  internalNumber: '',
  externalNumber: '',
  cardIds: [] as string[],
  driverId: '',
  cardStatus: '',
};

type StatusKey = 'ACTIVE' | 'DEACTIVATED' | 'SUSPENDED';

export const AccountCardModalForm: React.FC<Props> = (props) => {
  const id = _.kebabCase('AccountCardModal');
  const className = id;
  const { isValid, dirty, values, setFieldValue, handleChange, isSubmitting, resetForm, errors, touched, submitForm } =
    useFormikContext<FormValues>();

  const { modalName, modalProps, modalVisible, setModalState } = useModalContext();

  const { data } = useQuery(listCards, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page: 0,
        pageSize: 9999,
        sort: { internalNumber: 'ASC' },
        filter: {
          cardStatus: { in: ['DEACTIVATED', 'UNASSIGNED', 'ACTIVE', 'SUSPENDED'] },
        },
      },
    },
    skip: !props.open || ((modalProps && modalProps.selected) as boolean),
  });

  const cards = (data && _.get(data, 'listCards.edges', [])) as Card[];

  const { data: filteredStatuses, loading: statusOptionsLoading } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          columnName: {
            eq: 'card_status',
          },
          tableName: {
            eq: 'cards',
          },
        },
      },
    },
  });

  let shapedStatusOptions = statusOptionsLoading
    ? []
    : filteredStatuses?.listFalconConstraints?.edges?.map((c) => ({
        label: c?.columnText,
        value: c?.columnValue,
      }));

  const selectedCardStatus = _.get(modalProps, 'selected.cardStatus') as StatusKey;

  if (selectedCardStatus) {
    let availableStatuses: StatusKey[] = [];

    switch (selectedCardStatus) {
      case 'ACTIVE':
        availableStatuses = ['DEACTIVATED', 'SUSPENDED', 'ACTIVE'];
        break;
      case 'DEACTIVATED':
        availableStatuses = ['ACTIVE', 'DEACTIVATED'];
        break;
      case 'SUSPENDED':
        availableStatuses = ['ACTIVE', 'SUSPENDED'];
        break;
    }

    shapedStatusOptions = shapedStatusOptions?.filter((option) =>
      option.value ? availableStatuses.includes(option.value as StatusKey) : false,
    );
  }

  const internalOptions = cards?.map((card) => ({ value: card.altId, label: card.internalNumber })) || [];
  const externalOptions = cards?.map((card) => ({ value: card.altId, label: card.externalNumber })) || [];

  const onCancelClick = () => {
    setModalState({ modalName: '', modalProps: {}, modalVisible: false });
    resetForm({ status: initialValues, values: initialValues });
  };

  const shouldModalOpen = modalProps && modalVisible && modalName === 'AccountCardModal';
  if (!shouldModalOpen) {
    return null;
  }

  const modalTitle = _.get(modalProps, 'selected')
    ? modalProps.editType === 'assign'
      ? 'Assign Card'
      : 'Update Card Status'
    : 'Add Card';
  return (
    <Fragment>
      <StyledModal
        className={clsx(className, 'modal')}
        onClose={onCancelClick}
        open={!!props.open}
        onClick={submitForm}
        id={id}
        primaryButtonText={_.get(modalProps, 'selected') ? 'SAVE' : 'ADD CARD'}
        primaryButtonDisabled={isSubmitting || !isValid || !dirty}
        title={modalTitle}
        secondaryButtonClick={onCancelClick}
        secondaryButtonText="Cancel"
      >
        <Styled className={className}>
          <Form id={`${id}-form`} className={className}>
            <div className={className}>
              <div className={className}>
                {_.get(modalProps, 'selected') ? (
                  <Fragment>
                    <TextInfo
                      className={clsx(className, 'half', 'disabled')}
                      id={`${id}-edit-card-internal-number`}
                      disabled
                      value={_.get(modalProps, 'selected.internalNumber')}
                      label="Internal Card Number"
                      labelProps={{ className }}
                    />
                    <TextInfo
                      className={clsx(className, 'half', 'disabled')}
                      id={`${id}-edit-card-external-number`}
                      disabled
                      value={_.get(modalProps, 'selected.externalNumber')}
                      label="External Card Number"
                      labelProps={{ className }}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Dropdown
                      style={{ marginTop: '16px' }}
                      className={clsx(className, 'half')}
                      data-testid="add-account-card-modal-internal-number-input"
                      name="internalNumber"
                      id={`${id}-add-card-internal-number`}
                      label="Internal Card Number *"
                      error={errors.internalNumber && touched.internalNumber}
                      helpertext={touched.internalNumber && errors.internalNumber}
                      labelProps={{ className }}
                      menuProps={{ className }}
                      options={internalOptions}
                      value={values.internalNumber}
                      required
                      disabled={isSubmitting}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cardIds', [e.target.value]);
                        handleChange(e);
                      }}
                    />
                    <Dropdown
                      style={{ marginTop: '16px' }}
                      className={clsx(className, 'half')}
                      data-testid="add-account-card-modal-external-number-input"
                      name="externalNumber"
                      id={`${id}-add-card-external-number`}
                      label="External Card Number *"
                      error={errors.externalNumber && touched.externalNumber}
                      helpertext={touched.externalNumber && errors.externalNumber}
                      labelProps={{ className }}
                      menuProps={{ className }}
                      options={externalOptions}
                      value={values.externalNumber}
                      disabled={isSubmitting}
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cardIds', [e.target.value]);
                        handleChange(e);
                      }}
                    />
                  </Fragment>
                )}
                {modalProps.selected && modalProps.editType === 'assign' && (
                  <Dropdown
                    className={clsx(className, 'half')}
                    name="driverId"
                    id={`${id}-assginee`}
                    label="Assignee"
                    labelProps={{ className }}
                    menuProps={{ className }}
                    options={modalProps.drivers || []}
                    value={values.driverId || _.get(modalProps, 'selected.driverId', '')}
                    required
                    disabled={isSubmitting}
                    data-testid="add-cards-modal-brand-dropdown"
                    onChange={handleChange}
                  />
                )}
                {modalProps.selected && modalProps.editType === 'status' && (
                  <Dropdown
                    className={clsx(className, 'half')}
                    name="cardStatus"
                    id={`${id}-card-status`}
                    label="Status"
                    labelProps={{ className }}
                    menuProps={{ className }}
                    options={shapedStatusOptions}
                    value={values.cardStatus || _.get(modalProps, 'selected.cardStatus', '')}
                    required
                    disabled={isSubmitting}
                    data-testid="add-cards-modal-brand-dropdown"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (props.setStatus) props.setStatus(e.target.value);
                      handleChange(e);
                    }}
                  />
                )}
              </div>
            </div>
          </Form>
        </Styled>
      </StyledModal>
    </Fragment>
  );
};
