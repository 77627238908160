import { useMutation, useQuery } from '@apollo/client';
import { Button, AccordionDetails } from '@material-ui/core';
import { Cached, FullscreenExit, PauseCircleOutline, PlayCircleOutline } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Query } from 'src/@types';
import { chargerCommand, getCharger } from 'src/apollo/queries/chargers';
import { StopCircleOutline } from '../../../../svgs';
import { Styled as StyledPaper } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
export interface Props {
  startCharge: boolean;
  setStartCharge: (val: boolean) => void;
  stopCharge: boolean;
  setStopCharge: (val: boolean) => void;
  className?: string;
}

export const sendChargerCommand = (action: string, obj: unknown): void => {
  // TODO: there was no implementation for this
  console.log(action, obj);
};

/**
 * Chargers actions component.
 */
export const ChargerActions: React.FC<Props> = (props) => {
  const id = _.kebabCase('ChargerActions');
  const className = id;
  const actionBarRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const altId = _.get(props, 'params.altId', 0);
  const { data: chargerData } = useQuery<Query>(getCharger, {
    skip: !altId,
    variables: { chargerInput: { altId } },
  });
  const selected = chargerData?.getCharger;

  // TODO: none of these properties exist
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const actionIds = {
    organizationId: (selected as any)?.organizationId || '',
    locationId: (selected as any)?.locationId || '',
    stationId: (selected as any)?.externalId || '',
    evseId: (selected as any)?.evses?.[0]?.identityKey || '',
  };
  /* eslint-enable @typescript-eslint/no-explicit-any */

  const snackbar = useSnackbar();
  const { setModalState } = useModalContext();
  const [chargeCommandMutation, { data: chargerCommandData }] = useMutation(chargerCommand, {
    onError: (errors) => {
      setModalState({
        modalName: '',
        modalProps: {},
        modalVisible: false,
      });
      snackbar.error(_.get(errors, 'graphQLErrors')[0].message || 'Failed to ping the charger.');
    },
  });

  const pingChargeCommand = () => {
    setModalState({
      modalName: 'StartChargeModal',
      modalProps: {},
      modalVisible: true,
    });
    chargeCommandMutation({
      variables: {
        input: {
          command: 'ping',
          connectorId: 1,
        },
      },
    });
  };

  useEffect(() => {
    if (chargerCommandData) {
      setModalState({
        modalName: '',
        modalProps: {},
        modalVisible: false,
      });
      if (_.get(chargerCommandData, 'chargerCommand.success') === 'true') {
        snackbar.success('Successfully pinged the charger.');
      } else {
        snackbar.error('Failed to ping the charger.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerCommandData]);

  const handleClickOutside = (e: MouseEvent) => {
    if (actionBarRef.current && !actionBarRef.current.contains(e.target as Node)) setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  if (expanded) customClass += ' open';

  return (
    <StyledPaper className={`${customClass} action-section`} component="section">
      <AccordionDetails className={className}>
        <div
          id={`${id}-action-bar`}
          className={`${className} actions`}
          onClick={() => setExpanded(!expanded)}
          ref={actionBarRef}
        >
          <Button
            id={`${id}-ping-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''}`}
            // disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              pingChargeCommand();
              setExpanded(!expanded);
            }}
            size="large"
          >
            <FullscreenExit className={`${className} left`} />
            Ping
          </Button>

          <Button
            id={`${id}-start-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''} ${props.startCharge ? 'selected' : ''}`}
            disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              props.setStartCharge(!props.startCharge);
              setExpanded(!expanded);
            }}
            size="large"
          >
            <PlayCircleOutline className={`${className} left`} />
            Start
          </Button>

          <Button
            id={`${id}-stop-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''} ${props.stopCharge ? 'selected' : ''}`}
            disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              props.setStopCharge(!props.stopCharge);
              setExpanded(!expanded);
            }}
            size="large"
          >
            <StopCircleOutline id="StopCircleOutline" className={`${className} stop-charge left`} />
            Stop
          </Button>

          <Button
            id={`${id}-deactivate-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''}`}
            disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              sendChargerCommand('CHANGE_AVAILABILITY', { ...actionIds, available: false });
              setExpanded(!expanded);
            }}
            size="large"
          >
            <PauseCircleOutline className={`${className} left`} />
            Deactivate
          </Button>

          <Button
            id={`${id}-soft-restart-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''}`}
            disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              sendChargerCommand('RESET_STATION', { ...actionIds, hard: false });
              setExpanded(!expanded);
            }}
            size="large"
          >
            <PlayCircleOutline className={`${className} left`} />
            Soft Restart
          </Button>

          <Button
            id={`${id}-hard-restart-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : ''}`}
            disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              sendChargerCommand('RESET_STATION', { ...actionIds, hard: true });
              setExpanded(!expanded);
            }}
            size="large"
          >
            <PlayCircleOutline className={`${className} left`} />
            Hard Restart
          </Button>

          <Button
            id={`${id}-reboot-cradlepoint-charger-button`}
            className={`${className} ${_.isEmpty(selected) ? 'disabled' : 'active'}`}
            disabled={_.isEmpty(selected) || !expanded}
            onClick={() => {
              setExpanded(!expanded);
            }}
            size="large"
          >
            <Cached className={`${className} left`} />
            Reboot Cradlepoint
          </Button>
        </div>
      </AccordionDetails>
    </StyledPaper>
  );
};
