import { useMutation } from '@apollo/client';
import { Divider, AccordionActions } from '@evgo/react-material-components';
import {
  Button,
  IconButton,
  Paper,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Mutation } from 'src/@types';
import { LabelValue } from 'src/@types/shared';
import { stopCharge } from 'src/apollo/queries/chargers/stopCharge';
import { initialValues } from '../initialValues';
import { Styled as StyledModal } from './styles';
import { useModalContext } from 'src/contexts/ModalContext';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';

export interface Props {
  open: boolean;
  setStopCharge: React.Dispatch<React.SetStateAction<boolean>>;
  evses: (typeof initialValues)['evses'];
  className?: string;
}

const steps = ['Connector/Session', 'Confirmation'];

const stepContents = ['Select Session', 'Confirmation'];

/**
 * Stop Charger component.
 */
export const StopCharge: React.FC<Props> = (props) => {
  const id = _.kebabCase('StopCharge');
  const className = id;

  const { setStopCharge, evses } = props;

  let connectorOptions: LabelValue<string>[] = [];
  if (evses?.edges) {
    connectorOptions = _.flatten(evses.edges.map((evse) => evse.connectors.edges)).map((c) => ({
      value: c.altId,
      label: c.connectorType?.columnText as string,
    }));
  }

  const snackbar = useSnackbar();
  const { setModalState } = useModalContext();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedConnector, setSelectedConnector] = React.useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stopChargeMutation, { data: chargerCommandData, error: chargerCommandErrors }] = useMutation<Mutation>(
    stopCharge,
    {
      onError: (errors) => {
        setModalState({ modalName: '', modalProps: {}, modalVisible: false });

        snackbar.error(_.get(errors, 'graphQLErrors')[0].message || 'The session failed to stop.');
      },
    },
  );

  const onCancelClick = () => {
    setActiveStep(0);
    setSelectedConnector(null);
    setStopCharge(false);
  };

  const stopChargeCommand = () => {
    const connectorId = selectedConnector;
    setModalState({ modalName: 'StartChargeModal', modalProps: {}, modalVisible: true });

    stopChargeMutation({ variables: { input: { connectorId } } });
  };

  useEffect(() => {
    if (chargerCommandData && chargerCommandData.stopCharge === null) {
      setModalState({ modalName: '', modalProps: {}, modalVisible: false });

      if (_.get(chargerCommandErrors, '[0]')) {
        snackbar.error('The session failed to stop.');
      } else {
        onCancelClick();
        snackbar.success('The session has been successfully stopped.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerCommandData]);

  const shouldNextButtonDisabled = () => {
    if (activeStep === 0 && selectedConnector) return false;
    if (activeStep === 1) return false;

    return true;
  };

  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  return (
    <StyledModal open={props.open} onClose={() => props.setStopCharge(false)} className={className}>
      <div className={`${className} modal-content`}>
        <Paper className={`${customClass} stop-charge`} component="section">
          <header className={className}>
            <Typography className={className} variant="h6" component="h2">
              Stop Charge
            </Typography>
            <IconButton className={className} onClick={() => onCancelClick()}>
              <Close />
            </IconButton>
          </header>
          <Divider className={className} />

          <div className={`${className} steps`}>
            <div className={className}>
              <Typography className={className} variant="body1">
                {stepContents[activeStep]}
              </Typography>
            </div>
            <Stepper activeStep={activeStep} alternativeLabel className={`${className} stepper`}>
              {steps.map((label, step) => {
                return (
                  <Step key={label} className={`${className} ${step > activeStep ? '' : 'active'}`}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          {activeStep === 0 ? (
            <div className={`${className} connector-list`}>
              <Table className={className}>
                <TableHead className={className}>
                  <TableRow className={className}>
                    <TableCell className={`${className} connector-button`}></TableCell>
                    <TableCell className={className}>Connector</TableCell>
                    <TableCell className={className}>Session ID & User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!connectorOptions && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography className={className} variant="body2">
                          No connectors found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {!!connectorOptions &&
                    connectorOptions.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell className={className}>
                          <Radio
                            id={`${id}-connector-${row.value}`}
                            checked={selectedConnector === row.value}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedConnector(e.target.value)}
                            value={row.value}
                            color="secondary"
                          />
                        </TableCell>
                        <TableCell className={className}>{row.label}</TableCell>
                        <TableCell className={className}>
                          <div className={className}>{`ID: 000${key + 1}`}</div>
                          <div className={className}>Driver: driver@email.com</div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          ) : null}

          {activeStep === 1 ? (
            <div className={`${className} confirmation`}>
              <div className={className}>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Connector Type
                  </Typography>
                  <Typography className={className} variant="body1">
                    {`${(_.find(connectorOptions, { value: selectedConnector }) as LabelValue<string>)?.label}`}
                  </Typography>
                </div>
                <div className={className}>
                  <Typography className={className} variant="caption">
                    Active Session
                  </Typography>
                  <Typography className={className} variant="body1">
                    <div className={className}>ID: 0001</div>
                    <div className={className}>Driver: driver@email.com</div>
                  </Typography>
                </div>
              </div>
            </div>
          ) : null}

          <Divider />

          <AccordionActions className={`${className} panel-actions`}>
            <Button className={className} size="large" color="primary" variant="text" onClick={onCancelClick}>
              CANCEL
            </Button>

            <Button
              className={className}
              size="large"
              color="secondary"
              variant="contained"
              onClick={activeStep === 1 ? stopChargeCommand : () => setActiveStep(activeStep + 1)}
              disabled={shouldNextButtonDisabled()}
            >
              {activeStep === 1 ? 'STOP' : 'NEXT'}
            </Button>
          </AccordionActions>
        </Paper>
      </div>
    </StyledModal>
  );
};
