import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.div`
  .error {
    height: ${spacing(2.03125)}px;
    padding: ${`0 ${spacing(3)}px ${spacing(2)}px`};

    span {
      color: ${theme.palette.error.main};
    }
  }

  section {
    display: flex;
    flex-wrap: wrap;
    padding: ${`0 ${spacing(3)}px ${spacing(2)}px`};

    div {
      &:not(.select) {
        flex: 1 1 15%;
      }

      label {
        font-size: ${theme.typography.fontSize}px;
        transform: scale(1);
      }

      .select,
      .date-picker {
        font-size: ${theme.typography.fontSize}px;
        margin-top: ${spacing(2.5)}px;
        margin-right: ${spacing(1.625)}px;

        div {
          > input {
            font-size: ${spacing(1.75)}px;
          }
        }
      }

      .clear-models,
      .clear-status {
        position: absolute;
        top: 45px;
        padding: 4px;
        cursor: pointer;
      }
    }
  }
`;
