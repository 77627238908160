import { Button, InputLabel, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, Face as FaceIcon } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { initialValues } from '../initialValues';
import { Styled } from './styles';

export interface Props {
  loading: boolean;
  id?: string;
  className?: string;
}

/**
 * Plan header component
 */
export const PlanHeader: React.FC<Props> = ({ className: parentClass, id: parentId, loading }) => {
  const id = _.kebabCase('PlanHeader');
  const className = id;
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { planId } = (location.state || {}) as any;
  const { status } = useFormikContext<typeof initialValues>();
  let customClass = className;
  let title = 'New Plan';
  let displayName = '';
  let createdAt = '';

  if (loading) title = 'Loading ...';
  if (parentClass) customClass += ` ${parentClass}`;
  if (_.get(status, 'planName') && !planId) title = _.get(status, 'planName');

  if (!planId) {
    displayName = _.get(status, 'displayName', '');
    createdAt = _.get(status, 'createdAt') ? moment(_.get(status, 'createdAt')).format('MMMM DD, YYYY') : '';
  }

  // TODO: Make this an actual sub component, possibly shared
  const info = (infoClass: string, label: string, value: string) => {
    return (
      <article className={className}>
        <InputLabel className={className} shrink>
          {label}
        </InputLabel>
        <div className={`${className} ${infoClass}`}>
          <Typography className={className} id={`${id}-${infoClass}`}>
            {value}
          </Typography>
        </div>
      </article>
    );
  };

  return (
    <Styled id={parentId} className={customClass}>
      <Button className={className} component={Link} id={`${id}-header-back-button`} size="small" to="/plans/list">
        <ArrowBackIcon className={className} />
        &nbsp;All Plans
      </Button>

      <Typography id={`${id}-header-name`} className={className} variant="h5">
        <FaceIcon className={className} />
        &nbsp;{title}
      </Typography>

      <section className={`${className} info`}>
        {info('plan-code', 'Plan ID', _.get(status, 'planCode', ''))}
        {info('plan-display-name', 'Plan Display Name', displayName)}
        {info('plan-creation-dates', 'Plan Creation Date', createdAt)}
      </section>
      <span className={className}>&nbsp;</span>
    </Styled>
  );
};
