import { Accordion, theme } from '@evgo/react-material-components';
import styled from 'styled-components';
import config from '../../../../../../config';

const { spacing } = theme;

export const Styled = styled(Accordion)`
  margin-top: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;

  > header {
    padding: ${`0 ${spacing(3)}px`};

    h2 {
      color: ${config.colors.accentBlue};
    }
  }

  div.details {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    > div {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      padding: ${`${spacing(2)}px ${spacing()}px`};

      &:last-child {
        padding: ${`${spacing(3)}px ${spacing()}px`};
      }

      > div {
        display: flex;
        justify-content: flex-end;
        flex-basis: 100%;
        box-sizing: border-box;
        margin: ${`${spacing()}px ${spacing(3) / 2}px`};

        > label {
          transform: translate(calc(12px + ${spacing()}px), 20px) scale(1);

          &.prefilled,
          &[data-shrink='true'] {
            transform: translate(${spacing(2)}px, 10px) scale(0.75);
          }
        }

        &.full {
          flex-basis: 100%;
        }

        &.half {
          flex-basis: 50%;
        }

        &.sixth {
          flex-basis: calc(100% / 6);
        }
      }

      > label {
        flex-basis: 40%;
        box-sizing: border-box;
        margin: ${`0 ${spacing(1.75)}px ${spacing(2.5)}px -${spacing(1.75)}px`};
        padding: ${`0 ${spacing()}px`};
      }
    }

    hr {
      width: 100%;
    }
  }
`;
