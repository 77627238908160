import _ from 'lodash';
import { NavigationItem, NavigationSubItem, NestedNavigationItem } from './types';

export function startsWithOneOf(searchString: string, options: string[]) {
  return options.some((value) => searchString.startsWith(value));
}

export function isNested(item: NavigationItem): item is NestedNavigationItem {
  return (item as NestedNavigationItem).subItems !== undefined;
}

export function hasActiveSubItem(pathname: string, items: NavigationSubItem[]) {
  return startsWithOneOf(
    pathname,
    items.map(({ to }) => to),
  );
}

type GenerateMenuItemTestIdOptions = {
  parent?: string;
};

export function generateMenuItemTestId(name: string, options?: GenerateMenuItemTestIdOptions) {
  const format = (str: string) => _.snakeCase(str.toLowerCase());
  const type = options?.parent ? 'sub-menu' : 'menu';
  const prefix = options?.parent ? `-${format(options.parent)}-` : '-';
  return `Sidenav${prefix}${format(name)}-${type}-item`;
}

export function generateMenuContainerTestId(name: string) {
  return `Sidenav-${_.snakeCase(name.toLowerCase())}-sub-menu-items`;
}
