/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import { Form } from 'formik';
import styled from 'styled-components';
import config from '../../../../config';

const { spacing } = theme;
const { colors } = config;

interface Props {
  $isLargeScreen: boolean;
}

export const Styled = styled(Form)<Props>`
  @keyframes spin-clockwise {
    0% {
      transform: rotateZ(0);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  article {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;

    .header-section {
      position: absolute;
      left: ${(props) => (props.$isLargeScreen ? '200px' : '0')};
      width: ${(props) => (props.$isLargeScreen ? 'calc(100% + -200px)' : '100%')};
      z-index: 10;
    }

    .action-section {
      position: fixed;
      z-index: 1000;
      overflow: hidden;
      top: calc(276px + ${spacing(4)}px);
      left: ${(props) => (props.$isLargeScreen ? '200px' : '0')};
      width: 40px;
      margin: 0;
      border-radius: 0px 4px 4px 0px;
      white-space: nowrap;
      transition: width 0.5s ease;

      &.open {
        width: 250px;
      }
    }

    .charger-status {
      margin: 32px 16px 0;
    }

    > div {
      position: relative;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
    }
  }

  h2 {
    color: ${colors.accentBlue};
  }

  footer {
    > button {
      svg {
        position: absolute;
        animation: spin-clockwise 1s linear infinite;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
`;
