import { theme } from '@evgo/react-material-components';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../../../../config';

const { spacing } = theme;
const { colors } = config;

export const Styled = styled(Paper)`
  header {
    display: flex;
    flex-direction: column;
    padding: ${`${spacing(2)}px ${spacing(3)}px`};
  }

  div {
    display: flex;
  }

  h2 {
    color: ${colors.accentBlue};
  }
`;
