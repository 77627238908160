/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';
const { spacing } = theme;
export const Styled = styled.article`
  margin-bottom: ${spacing(3)}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  h2 {
    font-size: ${spacing(3)}px;
    font-weight: 700;
  }
  p {
    padding: ${spacing()}px 0;
  }
`;
export const Container = styled.div`
  .content-items {
    margin-bottom: 10px;
  }
  .content-items-left {
    margin-top: 9px;
  }
`;
