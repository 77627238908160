import { Site, Maybe } from 'src/@types';
import { getFullAddress } from 'src/lib/helpers';

export function getSiteFullAddress(site: Maybe<Site>) {
  return getFullAddress({
    streetAddress: site?.address1 || '',
    unit: site?.address2 || '',
    city: site?.locality || '',
    state: site?.administrativeArea || '',
    postalCode: site?.postalCode || '',
    country: site?.country || '',
  });
}
